const Cross = ({ size = 18, className = '' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    className={className}
  >
    <path
      d="M17.4004 0.613233C16.8804 0.093233 16.0404 0.093233 15.5204 0.613233L9.00045 7.1199L2.48045 0.5999C1.96045 0.0798999 1.12045 0.0798999 0.600449 0.5999C0.0804492 1.1199 0.0804492 1.9599 0.600449 2.4799L7.12045 8.9999L0.600449 15.5199C0.0804492 16.0399 0.0804492 16.8799 0.600449 17.3999C1.12045 17.9199 1.96045 17.9199 2.48045 17.3999L9.00045 10.8799L15.5204 17.3999C16.0404 17.9199 16.8804 17.9199 17.4004 17.3999C17.9204 16.8799 17.9204 16.0399 17.4004 15.5199L10.8805 8.9999L17.4004 2.4799C17.9071 1.97323 17.9071 1.1199 17.4004 0.613233Z"
      fill="currentColor"
    />
  </svg>
);

export default Cross;
