import Button from 'components/Button';
import Modal from 'components/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const IMCModal = ({ onClose, onSubmit }) => {
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const { t } = useTranslation();

  const submit = () => onSubmit({ height, weight });

  return (
    <Modal width="medium" onClose={onClose}>
      <Modal.Header onClose={onClose}>
        {t('sidebar__patient_add_imc_title')}
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <label className="pb-2 font-medium">
            {t('sidebar__patient_add_imc_height')}
          </label>
          <input
            autoFocus
            placeholder={t('sidebar__patient_add_imc_height_placeholder')}
            onChange={(e) => setHeight(e.target.value)}
            value={height}
            className="w-full p-2 border rounded-md border-separators"
          />
        </div>
        <div>
          <label className="pb-2 font-medium">
            {t('sidebar__patient_add_imc_weight')}
          </label>
          <input
            placeholder={t('sidebar__patient_add_imc_weight_placeholder')}
            onChange={(e) => setWeight(e.target.value)}
            value={weight}
            className="w-full p-2 border rounded-md border-separators"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={!height || !weight} onClick={submit}>
          {t('sidebar__patient_add_imc_accept')}
        </Button>
        <Button variant="reverse" onClick={onClose}>
          {t('sidebar__patient_add_imc_cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IMCModal;
