import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

export const BASE_URL = config.services.mediquo.chat_url;
export const FLASH = 'flash/v1';

const getAll = () =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/${FLASH}/waiting-room`,
    headers: getHeaders(),
  });

export default {
  getAll
};
