import classNames from 'classnames';
import Button from 'components/Button';
import Cross from 'components/icons/outline/Cross';
import Check from 'components/icons/outline/Check';
import Modal from 'components/Modal';
import { ModalBody } from 'containers/Shared/components/Modal/Modal';
import { useTranslation } from 'react-i18next';

const ValidationStatusModal = ({ onClose, status, continueLink }: any) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} width="medium">
      <ModalBody>
        <div className="flex flex-col items-center justify-center text-center">
          <div className="my-4 text-center flex items-center justify-between w-full text-xs text-gray-dark">
            <p>{t('prescription__validation_status_modal_sent')}</p>
            <p>{t('prescription__validation_status_modal_received')}</p>
            {status === 'pending' ? (
              <p>{t('prescription__validation_status_modal_validated')}</p>
            ) : (
              <p>{t('prescription__validation_status_modal_error')}</p>
            )}
          </div>
          <div className="mb-14 flex items-center justify-between w-full">
            <Check className="w-4 h-4 text-white bg-positive flex-shrink-0 rounded-full flex items-center justify-center" />
            <div className="h-1 flex-1 bg-positive-light flex-shrink-0"></div>
            <Check className="w-4 h-4 text-white flex-shrink-0 bg-positive rounded-full flex items-center justify-center" />
            <div
              className={classNames('h-1 flex-1 flex-shrink-0', {
                'bg-background': status === 'pending',
                'bg-negative-light': status === 'failed',
              })}
            >
              <span className="text-xs italic text-gray-dark animate-pulse pt-1">
                {t('prescription__validation_status_modal_processing')}
              </span>
            </div>
            {status === 'pending' ? (
              <div className="w-5 h-5 flex items-center justify-center rounded-full bg-gray-light">
                <div className="w-2 h-2 flex-shrink-0 rounded-full bg-gray-dark"></div>
              </div>
            ) : (
              <div className="w-6 h-6 flex items-center justify-center shadow-lg rounded-full text-white bg-negative">
                <Cross className="w-3 h-3 flex-shrink-0 " />
              </div>
            )}
          </div>
          <h1 className="text-primary font-medium text-2xl mb-6">
            {status === 'pending'
              ? t('prescription__validation_status_modal_pending_title')
              : t('prescription__validation_status_modal_failed_title')}
          </h1>
          <p className="mb-14">
            {status === 'pending'
              ? t('prescription__validation_status_modal_pending_body')
              : t('prescription__validation_status_modal_failed_body')}
          </p>
          {status === 'pending' ? (
            <Button className="mb-4" onClick={onClose}>
              {t('prescription__validation_status_modal_pending_button')}
            </Button>
          ) : (
            <Button
              className="mb-4"
              type="link"
              href={continueLink}
              target="_blank"
            >
              {t('prescription__validation_status_modal_failed_button')}
            </Button>
          )}
          {status === 'pending' && (
            <p className="text-gray-dark text-xs">
              {t('prescription__validation_status_modal_pending_contact')}
            </p>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ValidationStatusModal;
