export default (props: any) => (
  <svg
    width="173"
    height="116"
    viewBox="0 0 173 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4158_39696)">
      <path
        d="M147.724 0.332031H82.9435C51.0946 0.332031 25.2759 26.1507 25.2759 57.9997V115.667H90.0565C121.905 115.667 147.724 89.8486 147.724 57.9996V0.332031Z"
        fill="#4B4B4B"
      />
      <path
        d="M129.206 28.0226L127.46 32.7244C127.377 32.8894 127.294 32.9719 127.128 33.0544L123.553 34.0442C123.137 34.2092 123.054 34.7866 123.553 34.9516L127.46 36.4363C127.627 36.5188 127.71 36.6013 127.71 36.6838L129.373 41.3031C129.539 41.7155 130.204 41.7155 130.287 41.2206L131.368 36.3538C131.368 36.1889 131.534 36.0239 131.7 36.0239L135.359 34.7866C135.774 34.6216 135.774 33.9617 135.359 33.8792L131.867 32.8894C131.7 32.8069 131.617 32.7244 131.534 32.5594L130.038 27.9402C130.038 27.6102 129.373 27.6102 129.206 28.0226Z"
        fill="white"
      />
      <circle cx="116.673" cy="19.5775" r="2.70248" fill="#ECEFF1" />
      <circle cx="130.784" cy="54.1481" r="1.3444" fill="#ECEFF1" />
      <rect
        width="4.06059"
        height="4.06059"
        transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 37.0974 107.544)"
        fill="#ECEFF1"
      />
      <path
        d="M55.1899 94.4293V51.8603C55.1899 50.1986 56.537 48.8516 58.1987 48.8516H67.1521C68.0544 48.8516 68.9304 49.1558 69.6385 49.715L71.4785 51.1682C72.5281 51.9971 73.824 52.4528 75.1615 52.4631L111.787 52.7465L110.268 94.4293H55.1899Z"
        fill="#CACACA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.145 15.6684L96.6093 23.1799C96.4127 23.0611 96.2137 22.946 96.0125 22.8346L100.264 15.1586C100.561 15.3231 100.855 15.4931 101.145 15.6684ZM87.6056 11.7713L87.4441 20.5445C87.328 20.5424 87.2115 20.5413 87.0947 20.5413C86.9778 20.5413 86.8614 20.5424 86.7453 20.5445L86.5838 11.7713C86.7537 11.7682 86.924 11.7666 87.0947 11.7666C87.2653 11.7666 87.4356 11.7682 87.6056 11.7713ZM73.9255 15.1586L78.1768 22.8346C77.9756 22.946 77.7767 23.0611 77.58 23.1799L73.0443 15.6684C73.3346 15.4931 73.6283 15.3231 73.9255 15.1586ZM63.776 24.9367L71.2875 29.4724C71.1688 29.669 71.0537 29.868 70.9422 30.0691L63.2662 25.8178C63.4308 25.5206 63.6008 25.2269 63.776 24.9367ZM59.879 38.4761C59.8758 38.646 59.8743 38.8163 59.8743 38.987C59.8743 39.1577 59.8758 39.328 59.879 39.4979L68.6522 39.3364C68.65 39.2203 68.6489 39.1038 68.6489 38.987C68.6489 38.8702 68.65 38.7537 68.6522 38.6376L59.879 38.4761ZM86.5838 66.2027L86.7453 57.4295C86.8614 57.4316 86.9778 57.4327 87.0947 57.4327C87.2115 57.4327 87.328 57.4316 87.4441 57.4295L87.6056 66.2027C87.4356 66.2058 87.2653 66.2074 87.0947 66.2074C86.924 66.2074 86.7537 66.2058 86.5838 66.2027ZM100.264 62.8154L96.0125 55.1394C96.2137 55.028 96.4127 54.9129 96.6093 54.7941L101.145 62.3056C100.855 62.4809 100.561 62.6509 100.264 62.8154ZM110.413 53.0373L102.902 48.5017C103.021 48.305 103.136 48.1061 103.247 47.9049L110.923 52.1562C110.759 52.4534 110.589 52.7471 110.413 53.0373Z"
        fill="#CACACA"
      />
      <path
        d="M81.3831 32.3232H107.762L114.086 39.0076V78.1074H81.3831V32.3232Z"
        fill="white"
      />
      <path
        d="M114.087 38.9862H107.759L107.759 32.3232L114.087 38.9862Z"
        fill="#E0E0E0"
      />
      <path
        d="M55.1899 94.4292L64.3749 61.7871L122.824 61.7871L110.298 94.4292H55.1899Z"
        fill="#ECECEC"
      />
      <path
        d="M94.4297 40.6934H101.114L99.8302 53.0109H95.7135L94.4297 40.6934Z"
        fill="#CACACA"
      />
      <rect
        x="95.6394"
        y="54.5156"
        width="4.19002"
        height="4.19002"
        fill="#CACACA"
      />
      <path
        d="M42.9014 97.1749C43.0199 97.4686 43.3751 97.5274 43.5527 97.2337L44.5 95.7651L45.8617 96.8812C46.0985 97.0574 46.4538 96.8812 46.4538 96.5875V94.8253L48.1707 95.0015C48.4667 95.0602 48.7035 94.7078 48.5259 94.4728L47.5786 93.0043L49.1772 92.2406C49.4732 92.1232 49.414 91.712 49.1772 91.5945L47.5786 90.8896L48.4667 89.4211C48.6443 89.1861 48.4075 88.8336 48.1115 88.8924L46.3945 89.1273L46.3353 87.4238C46.3353 87.1301 45.9801 86.9539 45.7433 87.1889L44.7368 88.07C44.5592 88.1875 44.3224 88.1875 44.204 88.0113L43.4343 86.8952C43.2567 86.6602 42.9014 86.719 42.783 87.0127L42.3094 88.6574L40.7109 87.9525C40.4148 87.835 40.1188 88.07 40.2372 88.3637L40.7701 90.0085L39.0531 90.3022C38.7571 90.3609 38.6387 90.7134 38.8755 90.8896L40.178 92.0057L38.8755 93.1805C38.6387 93.3567 38.7571 93.7679 39.0531 93.7679L40.7701 94.0029L40.2964 95.7064C40.2372 96.0001 40.5332 96.2351 40.7701 96.1176L42.3686 95.4127L42.9014 97.1749Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4158_39696">
        <rect
          width="172"
          height="115.335"
          fill="white"
          transform="translate(0.5 0.332031)"
        />
      </clipPath>
    </defs>
  </svg>
);
