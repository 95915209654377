import React, { useEffect } from 'react';
import { ModalHeader, Modal } from 'containers/Shared/components/Modal/Modal';
import UserUnknown from 'containers/Room/icons/UserUnknown';
import ChatError from 'containers/Room/icons/ChatError';
import ChatOk from 'containers/Room/icons/ChatOk';
import UserError from 'containers/Room/icons/UserError';
import Tracker from 'utils/Tracking';
import { useTranslation } from 'react-i18next';

const BlockRoomType = {
  patient: Symbol(),
  professional: Symbol(),
};

const Reasons = {
  [BlockRoomType.patient]: [
    {
      id: 'unknown_patient',
      text: 'room__block_modal_unknown_patient',
      icon: <UserUnknown />,
    },
    {
      id: 'bad_reason',
      text: 'room__block_modal_bad_reason',
      icon: <ChatError />,
    },
    {
      id: 'finished_assistance',
      text: 'room__block_modal_finished_assistance',
      icon: <ChatOk />,
    },
  ],
  [BlockRoomType.professional]: [
    {
      id: 'unknown_professional',
      text: 'room__block_modal_unknown_professional',
      icon: <UserUnknown />,
    },
    {
      id: 'finished_professional_consultation',
      text: 'room__block_modal_finished_professional_consultation',
      icon: <UserError />,
    },
    {
      id: 'disrespect_or_spam',
      text: 'room__block_modal_disrespect_or_spam',
      icon: <ChatError />,
    },
  ],
};

const BlockRoomModal = ({ room, open, onClose, reject }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      Tracker.pageView('chat block reason view', {
        event_room_id: room?.room_id,
      });
    }
  }, [open]);

  if (!open) {
    return null;
  }

  const type =
    room?.type === 'professional'
      ? BlockRoomType.professional
      : BlockRoomType.patient;

  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose}>
        {t('room__block_modal_title')}
      </ModalHeader>
      <div className="py-4">
        <div className="px-6 mb-4 text-xs text-primary">
          {t('room__block_modal_subtitle')}
        </div>
        <div>
          {Reasons[type].map(({ id, text, icon }, index) => (
            <div
              key={id}
              onClick={() => reject(id)}
              className="flex items-center cursor-pointer hover:bg-gray-light focus:outline-none"
            >
              <div className="flex items-center w-full py-5 mx-5 border-t first:border-t-0 border-separators">
                <div className="p-3 mr-5 rounded-full bg-secundary">{icon}</div>
                <div className="font-medium">{t(text)}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default BlockRoomModal;
