import { useEffect, useState } from 'react';

export interface Props {
  timeout?: number;
  onCloseFinish?: (value?: string) => void;
}

const useAnimation = ({
  timeout = 150,
  onCloseFinish = () => {},
}: Props = {}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsOpen(true), 0);
  }, []);

  const close = (value?: string) => {
    setIsOpen(false);
    setTimeout(() => onCloseFinish(value), timeout);
  };

  return { isOpen, close };
};

export default useAnimation;
