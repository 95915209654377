import ProfileCard from 'components/icons/ProfileCard';
import { FC } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const Empty: FC<{ className?: string }> = ({ className = '', children }) => {
  const { t } = useTranslation();
  return (
    <div className={classnames('inset-0 overflow-auto', className)}>
      <div className="max-w-[614px] p-12 bg-white rounded-2xl m-auto mt-24 mb-4 text-center">
        <div className="p-8">
          <ProfileCard className="m-auto" />
        </div>
        <h5 className="pb-4 text-lg font-medium text-primary">
          {t('invitations__pending_empty_title')}
        </h5>
        <p
          className="pb-12 text-sm text-dark"
          dangerouslySetInnerHTML={{
            __html: t('invitations__pending_empty_description'),
          }}
        />
        {children}
      </div>
    </div>
  );
};

export default Empty;
