const DoublePaper = (props: any) => (
  <svg
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.3965 3.3053C13.6727 3.3053 13.8965 3.52916 13.8965 3.8053V11.2231C13.8965 11.4992 13.6727 11.7231 13.3965 11.7231H5.97878C5.70264 11.7231 5.47878 11.4992 5.47878 11.2231V3.8053C5.47878 3.52916 5.70264 3.3053 5.97878 3.3053H13.3965ZM13.8965 1.90234H5.47878C4.70715 1.90234 4.07582 2.53368 4.07582 3.3053V11.7231C4.07582 12.4947 4.70715 13.126 5.47878 13.126H13.8965C14.6682 13.126 15.2995 12.4947 15.2995 11.7231V3.3053C15.2995 2.53368 14.6682 1.90234 13.8965 1.90234ZM1.2699 4.70826V14.529C1.2699 15.3006 1.90123 15.9319 2.67286 15.9319H12.4936V14.529H3.67286C3.12057 14.529 2.67286 14.0813 2.67286 13.529V4.70826H1.2699Z"
      fill="currentColor"
    />
  </svg>
);

export default DoublePaper;
