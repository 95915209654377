export default () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none">
    <path
      d="M14.5385 0C15.0462 0 15.4615 0.410127 15.4615 0.911392V1.82278L15.6284 1.82818C16.9526 1.91413 18 3.01537 18 4.36125V15C18 17.2091 16.2091 19 14 19H4C1.79086 19 0 17.2091 0 15V4.36125C0 2.95929 1.13651 1.82278 2.53846 1.82278V0.911392C2.53846 0.410127 2.95385 0 3.46154 0C3.96923 0 4.38462 0.410127 4.38462 0.911392V1.82278H13.6154V0.911392C13.6154 0.410127 14.0308 0 14.5385 0ZM16 5H2V15C2 16.1046 2.89543 17 4 17H14C15.1046 17 16 16.1046 16 15V5Z"
      fill="#ECEFF1"
    />
    <path
      d="M12.1503 7.85407L12.3262 7.67821C12.6138 7.96584 12.6138 8.44413 12.3262 8.73176L10.0579 11L12.3262 13.2682C12.6188 13.5608 12.6188 14.0341 12.3262 14.3268C12.0335 14.6194 11.5602 14.6194 11.2676 14.3268L8.99938 12.0585L6.73115 14.3268C6.43852 14.6194 5.96523 14.6194 5.6726 14.3268C5.37997 14.0341 5.37997 13.5608 5.6726 13.2682L5.8477 13.4433L5.6726 13.2682L7.94082 11L5.6726 8.73176C5.37997 8.43913 5.37997 7.96584 5.6726 7.67321C5.96523 7.38058 6.43852 7.38058 6.73115 7.67321L8.99956 9.94161L11.2676 7.67821L11.2678 7.67803M12.1503 7.85407L12.1494 8.55499C12.3394 8.36499 12.3394 8.04499 12.1494 7.85499C11.9544 7.65999 11.6394 7.65999 11.4444 7.85499L11.2678 7.67803M12.1503 7.85407L12.3262 7.67821C12.0336 7.38564 11.5604 7.38558 11.2678 7.67803M12.1503 7.85407L11.2678 7.67803"
      fill="#ECEFF1"
      stroke="#ECEFF1"
      strokeWidth="0.5"
    />
  </svg>
);
