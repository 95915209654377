import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  medicationCreated,
  medicationDeleted,
  unknownMedicationDeleted,
  unknownMedicationCreated,
} from 'containers/DetailBar/PatientBar/actions/MedicalHistory.actions';
import ConsultationsApiClient from 'legacy/Services/Consultations';
import CRMTracker from 'utils/CRMTracker';

export const useMedications = (hash) => {
  const dispatch = useDispatch();
  const [medications, setMedications] = useState([]);

  useEffect(() => {
    ConsultationsApiClient.medications
      .index({ customer_hash: hash })
      .then(({ data }) => setMedications(data));
  }, [hash]);

  const destroy = (id) => {
    return ConsultationsApiClient.medications.delete(id).then(() => {
      setMedications(medications.filter((n) => n.id !== id));
      dispatch(medicationDeleted());
    });
  };

  const store = (medication, hash) => {
    if (medications.length === 0) {
      removeUnknown(hash);
    }

    return ConsultationsApiClient.medications
      .store({ ...medication, customer_hash: hash })
      .then(({ data }) => {
        setMedications([...medications, { ...data }]);
        dispatch(medicationCreated());
        CRMTracker.event('MedicalReportAdded');
      });
  };

  const markAsUnknown = (hash) => {
    return ConsultationsApiClient.unknownMedicalHistory
      .createUnknownMedication(hash)
      .then(() => dispatch(unknownMedicationCreated()));
  };

  const removeUnknown = (hash) => {
    return ConsultationsApiClient.unknownMedicalHistory
      .destroyUnknownMedication(hash)
      .then(() => dispatch(unknownMedicationDeleted()));
  };

  return [medications, store, destroy, markAsUnknown, removeUnknown];
};
