import {
  appointmentAccepted,
  appointmentDeclined,
  appointmentExpired,
  appointmentFinished,
  appointmentOwed,
} from 'state/appointments.slice';

export const onAppointmentFinished = (dispatch) => (data) =>
  dispatch(appointmentFinished({ id: data.id }));

export const onAppointmentAccepted = (dispatch) => (data) =>
  dispatch(appointmentAccepted({ id: data.id }));

export const onAppointmentDeclined = (dispatch) => (data) =>
  dispatch(appointmentDeclined({ id: data.id }));

export const onAppointmentExpired = (dispatch) => (data) =>
  dispatch(appointmentExpired({ id: data.id }));

export const onAppointmentOwed = (dispatch) => (data) =>
  dispatch(appointmentOwed({ id: data.id }));
