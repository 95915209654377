import { useTranslation } from 'react-i18next';
import useDiagnosticTest from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/useDiagnosticTest';
import { useEffect, useState } from 'react';
import {
  TabNavigation,
  TabNavigationMenu,
  TabNavigationItem,
} from 'components/TabNavigation';
import DiagnosticTestBody from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/DiagnosticTestBody';
import Button from 'components/Button';
import {
  ContentTitle,
  DiagnosticTestMenuCategory,
  SidebarTitle,
} from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/DiagnosticTestPrescriptionsPanel';
import Caret from 'components/icons/outline/Caret';
import { usePortal } from 'containers/Shared/components/Portal';
import Listing from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/Listing';
import classNames from 'classnames';
import { config } from 'config';
import SquareGrid from 'components/icons/solid/SquareGrid';

const SearchContainer = ({ setCurrentStatus }) => {
  const { t } = useTranslation();
  const {
    fetchCategories,
    categories,
    activeRootCategoryId,
    setRootCategory,
    activeRootCategoryName,
    activeNavId,
    setActiveNavId,
    items,
    petitions,
  } = useDiagnosticTest();
  const portal = usePortal();
  const [showListingDrawer, setShowListingDrawer] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleOnSelectRootCategory = (rootId, name) => {
    setRootCategory(rootId, name);
  };

  const showListing = () => {
    if (petitions.length) {
      setShowListingDrawer(true);
      portal.open(
        <Listing
          onClose={() => {
            setShowListingDrawer(false);
            portal.close('show_listing_drawer');
          }}
        />,
        'show_listing_drawer'
      );
    }
  };

  return (
    <div className="h-full flex flex-col justify-between">
      <div className="flex flex-row w-full h-[78vh] max-h-[78vh]">
        <div className="w-[320px] min-w-[320px] max-w-[320px] h-full ">
          <SidebarTitle>
            {t('prescription_modal__search_petitions_sidebar_title')}
          </SidebarTitle>
          <div className="flex flex-col h-full min-h-full justify-between">
            <div>
              {categories.map(({ root }, index) => {
                return (
                  <DiagnosticTestMenuCategory
                    key={index}
                    isActive={activeRootCategoryId === root.id}
                    category={root}
                    onClick={() =>
                      handleOnSelectRootCategory(root.id, root.value.name)
                    }
                  />
                );
              })}
            </div>
          </div>
        </div>

        <div className="flex flex-col px-4 pt-10 h-full w-full max-w-full overflow-x-hidden">
          <ContentTitle>{activeRootCategoryName}</ContentTitle>
          <TabNavigationMenu>
            {categories.map(({ root }) => {
              return root.children.map((children, index) => {
                return (
                  children.parent.id === activeRootCategoryId && (
                    <TabNavigationItem
                      key={index}
                      name={children.value.name}
                      setActiveTab={setActiveNavId}
                      isActive={activeNavId === children.id}
                      id={children.id}
                      type="categories"
                    />
                  )
                );
              });
            })}
          </TabNavigationMenu>
          <TabNavigation className="overflow-y-auto -translate-x-4 max-w-full">
            <DiagnosticTestBody
              categories={categories}
              activeNavId={activeNavId}
              activeRootCategoryId={activeRootCategoryId}
              items={items}
            />
          </TabNavigation>
        </div>
      </div>
      <div className="bottom-0 fixed w-full">
        <div className="w-[320px] min-w-[320px] max-w-[320px] bg-white w-full px-4">
          <button
            className="flex items-center bg-secundary text-primary uppercase text-sm gap-2 justify-center py-2 font-medium tracking-wider rounded-lg w-full mb-4"
            onClick={() => setCurrentStatus('templates')}
          >
            <SquareGrid className="w-4" />
            {t('prescription_modal__search_templates_button')}
          </button>
        </div>

        <div className="flex flex-row justify-between items-center px-6 h-20 max-h-20 border-t border-blue-light shrink-0">
          <div className="text-gray-dark flex flex-row items-center text-sm">
            {t('prescription_modal__search_petitions_selected_count', {
              count: petitions.length,
            })}
            <button
              className="ml-5 flex flex-row items-center"
              onClick={showListing}
            >
              <span className="mr-2 text-sm">
                {showListingDrawer
                  ? t('prescription_modal__search_petitions_hide_listing')
                  : t('prescription_modal__search_petitions_show_listing')}
              </span>
              <Caret
                className={classNames('h-2 w-2.5', {
                  'rotate-180': !showListingDrawer,
                })}
              />
            </button>
          </div>
          <div>
            <Button
              onClick={() => {
                setCurrentStatus('review');
              }}
              disabled={!petitions.length}
            >
              {t('prescription_modal__search_petitions_next_button')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchContainer;
