import { actionTypes } from 'containers/Room/actions/JoinRoom.actions';
import { actionTypes as contactTypes } from 'containers/Contacts/actions/Contact.actions';
import { actionTypes as newMessageActions } from 'containers/Room/actions/NewMessage.actions';
import { actionTypes as messageActions } from 'containers/Room/actions/GetMessages.actions';
import { actionTypes as optionTypes } from 'containers/Inbox/actions/RoomListOptions.actions';
import { actionTypes as medicalHistoryTypes } from 'containers/DetailBar/PatientBar/actions/MedicalHistory.actions';
import { MESSAGE_SENT } from 'containers/Room/actions/SendMessage.actions';
import { actionTypes as pendingPatientActions } from 'containers/Room/actions/PendingPatient.actions';
import { createSelector } from 'reselect';
import { actionTypes as messageTypes } from 'containers/Room/actions/GetMessages.actions';
import { actionTypes as deleteMessageTypes } from 'containers/Room/actions/DeleteMessage.actions';
import dayjs from 'dayjs';

export const LOADED_MESSAGES_CHUNK_SIZE = 30;

const initialState = {
  isLoading: false,
  isLoadingMessages: false,
  isPrescriptionDropdownOpen: false,
  current: undefined,
  lastLoadedMessageIndex: 0,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case pendingPatientActions.ROOM_ACCEPTED:
      return {
        ...state,
        current: {
          ...state.current,
          blocked_by: null,
          status: 'accepted',
        },
      };
    case pendingPatientActions.ROOM_REJECTED:
      return {
        ...state,
        current: {
          ...state.current,
          blocked_by: action.payload?.hash,
          status: 'blocked',
        },
      };
    case messageActions.FETCHING_MESSAGES:
      return {
        ...state,
        isLoadingMessages: true,
      };
    case actionTypes.FETCHING_ROOM:
      return {
        ...state,
        isLoading: true,
      };
    case optionTypes.MARK_ROOMS_AS_UNREAD:
      if (!action.payload.includes(state.current?.room_id)) return state;
      return {
        ...state,
        current: { ...state.current, need_to_read: true },
      };
    case optionTypes.MARK_ROOMS_AS_READ:
      if (!action.payload.includes(state.current?.room_id)) return state;
      return {
        ...state,
        current: { ...state.current, need_to_read: false },
      };
    case actionTypes.ROOM_FETCHED:
      return {
        ...state,
        isLoading: false,
        isPrescriptionDropdownOpen: false,
        current: { ...action.payload, messages: [] },
      };
    case actionTypes.CHAT_CONSULTATION_STARTED:
      return {
        ...state,
        current: {
          ...state.current,
          current_chat_consultation: {
            ...state.current.current_chat_consultation,
            status: action.payload,
          },
        },
      };
    case actionTypes.CLEAR_CHAT_CONSULTATION:
      return {
        ...state,
        current: {
          ...state.current,
          current_chat_consultation: null,
        },
      };
    case messageActions.MESSAGES_FETCHED:
      return {
        ...state,
        isLoadingMessages: false,
        isPrescriptionDropdownOpen: false,
        current: { ...state.current, messages: action.payload.messages },
        lastLoadedMessageIndex: LOADED_MESSAGES_CHUNK_SIZE,
      };
    case MESSAGE_SENT:
    case newMessageActions.MESSAGE_RECEIVED:
      const isCurrentRoom =
        parseInt(state.current?.room_id) === parseInt(action.payload.roomId);
      const isDuplicatedMessage = state.current?.messages.some(
        ({ messageId }) => messageId === action.payload.messageId
      );

      if (!isCurrentRoom || isDuplicatedMessage) {
        return state;
      }

      return {
        ...state,
        current: {
          ...state.current,
          last_message: {
            ...action.payload,
            auto: 0,
            createdAt: action.payload.time,
          },
          total_tracking_messages:
            action.payload.auto === true
              ? state.current.total_tracking_messages - 1
              : state.current.total_tracking_messages,
          messages: [
            ...state.current.messages,
            {
              ...action.payload,
              createdAt: dayjs(action.payload.time).format(
                'YYYY-MM-DD HH:mm ZZ'
              ),
            },
          ],
        },
        lastLoadedMessageIndex: state.lastLoadedMessageIndex + 1,
      };
    case messageActions.LOAD_MORE_MESSAGES:
      return {
        ...state,
        current: {
          ...state.current,
        },
        lastLoadedMessageIndex:
          state.lastLoadedMessageIndex + LOADED_MESSAGES_CHUNK_SIZE,
      };
    case optionTypes.PIN_ROOM:
      if (state.current === undefined) return state;
      if (!action.payload.roomId === state.current.room_id) return state;

      return {
        ...state,
        current: {
          ...state.current,
          pinned_at: action.payload.pinned_at,
        },
      };
    case optionTypes.UNPIN_ROOM:
      if (state.current === undefined) return state;
      if (!action.payload.roomId === state.current.room_id) return state;

      return {
        ...state,
        current: {
          ...state.current,
          pinned_at: null,
        },
      };
    case optionTypes.TRACKING_MESSAGE_SCHEDULED:
      if (state.current === undefined) return state;
      if (!action.payload.includes(state.current.room_id)) return state;

      return {
        ...state,
        current: {
          ...state.current,
          total_tracking_messages: state.current.total_tracking_messages + 1,
        },
      };
    case medicalHistoryTypes.TRACKING_DELETED:
      if (state.current === undefined) return state;
      if (action.payload !== state.current.room_id) return state;

      return {
        ...state,
        current: {
          ...state.current,
          total_tracking_messages: state.current.total_tracking_messages - 1,
        },
      };

    case messageTypes.MESSAGE_STATUS_CHANGED:
      const isMessageFromCurrentRoom =
        state.current?.room_id?.toString() ===
        action.payload?.room_id?.toString();
      if (!isMessageFromCurrentRoom) {
        return state;
      }

      return {
        ...state,
        current: {
          ...state.current,
          messages: state.current.messages.map((message) => {
            if (message.messageId !== action.payload.message_id) {
              return message;
            }
            return { ...message, status: action.payload.status };
          }),
        },
      };
    case contactTypes.CONTACT_NAME_UPDATED:
      return {
        ...state,
        current: {
          ...state.current,
          title: action.payload.name,
        },
      };
    case deleteMessageTypes.DELETE_MESSAGE:
      if (action.payload.isMessageFromCurrentRoom) {
        return {
          ...state,
          current: {
            ...state.current,
            messages: state.current.messages.map((message) => {
              if (
                message.messageId !== action.payload.message_id ||
                message.deletedAt
              ) {
                return message;
              }
              return {
                ...message,
                deletedAt: new Date(),
                string: '',
              };
            }),
          },
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

const getMessages = (state) => state.console.current?.messages;

export const getCanPatientDiagnosticTestPrescription = (state) =>
  state.console.current?.meta?.permissions?.diagnostic_test_prescriptions
    ?.available;

const getLastLoadedMessageIndex = (state) =>
  state.console.lastLoadedMessageIndex;

export const getCanCloseChatConsultation = (state) =>
  state.console.current?.meta?.permissions?.chat?.can_close_consultation;

export const loadedReversedMessages = createSelector(
  [getMessages, getLastLoadedMessageIndex],
  (consoleMessages, lastLoadedMessageIndex) => {
    const reversedMessages = consoleMessages
      ? [...consoleMessages].reverse()
      : [];
    const messages = reversedMessages.slice(0, lastLoadedMessageIndex);
    const hasMore = messages.length < consoleMessages?.length;
    return { messages, hasMore, totalMessages: reversedMessages };
  }
);

export const getCanBeAppointed = (state) =>
  state.console.current?.meta?.permissions?.appointments?.available;

export const getCanBeCharged = (state) =>
  state.console.current?.meta?.permissions?.payments?.can_be_charged;

export const getCanBeChatConsulted = (state) =>
  state.console.current?.meta?.permissions?.chat?.can_close_consultation;

export const getCanStartPhoneCall = (state) =>
  state.console.current?.meta?.permissions?.phone_calls?.available;

export default reducer;
