import React from 'react';

import { Trans } from 'react-i18next';
import IconSchedule from 'containers/Inbox/icons/IconSchedule';

const ScheduleTrackingMessage = ({ rooms, edited = false }) => (
  <div className="flex items-center justify-center">
    <IconSchedule />
    <div className="ml-3">
      <Trans
        i18nKey={
          edited
            ? 'toast__schedule_tracking_text_edited'
            : 'toast__schedule_tracking_text'
        }
        count={rooms.length}
      >
        Has realizado un seguimiento masivo a
        <span className="font-medium lowercase">
          {` ${rooms.length} consultas.`}
        </span>
      </Trans>
    </div>
  </div>
);

export default ScheduleTrackingMessage;
