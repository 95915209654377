import Form from 'components/Form';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Checkbox from 'containers/Inbox/components/Checkbox';
import SessionSelect from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/SessionBox';
import File from 'components/Form/File';
import useUploadFile from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/useAttachment';

const ReviewTestBody = ({
  activePetition,
  setActivePetition,
  setDraftPetitions,
  draftPetitions,
  setSavedPetitions,
  savedPetitions,
  isUniquePetition,
}) => {
  const { selected_items } = useSelector((state) => state.diagnosticTests);
  const { register, watch, getValues } = useForm({
    mode: 'onChange',
  });
  const [observations, setObservations] = useState('');
  const { t } = useTranslation();
  const { name } = activePetition;
  const watchObservations = watch('observations', true);
  const [laterality, setLaterality] = useState([]);
  const [sessionNumber, setSessionNumber] = useState(null);
  const uploadedAttachment = useUploadFile();
  const [file, setFile] = useState(null);
  const fileRef = useRef();

  useEffect(() => {
    setObservations(activePetition.observations);
    document.getElementById('textarea').value = '';
    document.getElementById('textarea').value = activePetition.observations;
    activePetition.laterality
      ? setLaterality(activePetition.laterality)
      : setLaterality([]);
    activePetition.sessions
      ? setSessionNumber(activePetition.sessions)
      : setSessionNumber(0);
    activePetition.file ? setFile(activePetition.file) : setFile(null);
  }, [name]);

  useEffect(() => {
    const values = getValues();
    const value = document.getElementById('textarea')?.value;

    if (value !== observations) {
      setObservations(values.observations);
      setActivePetition({
        ...activePetition,
        observations: value,
      });
    }
  }, [watchObservations]);

  const handleCheckLaterality = (option) => {
    laterality.includes(option)
      ? setLaterality([...laterality].filter((side) => side !== option))
      : setLaterality([...laterality, option]);
  };

  const handleSelectSession = (option) => {
    setSessionNumber(option);
  };

  useEffect(() => {
    uploadChanges();
  }, [laterality, observations, sessionNumber, file]);

  const uploadChanges = () => {
    const newDraftPetitions = draftPetitions.map((petition) => {
      if (petition.name === activePetition.name) {
        return {
          ...petition,
          laterality,
          sessions: sessionNumber ? sessionNumber : null,
          observations,
          file,
        };
      } else {
        return petition;
      }
    });
    setDraftPetitions(newDraftPetitions);

    setSavedPetitions(
      savedPetitions.filter((petition) => petition !== activePetition.name)
    );
  };

  const handleAttachFile = async (event) => {
    event.preventDefault();
    const file = fileRef.current.files[0];
    const url = await uploadedAttachment({ attachment: file });
    setFile({ name: file.name, url });
  };

  return (
    <div className="inset-0 h-fit">
      <div className="mb-2">
        {selected_items
          ?.filter((item) => item.meta.component_name === activePetition.name)
          .map((item) => (
            <div key={item.id}>
              {item.meta.laterality && (
                <LateralitySelect
                  handleCheckLaterality={handleCheckLaterality}
                  laterality={laterality}
                />
              )}
              {!isUniquePetition && (
                <div className="flex py-2 text-sm text-dark">{item.name}</div>
              )}
              {item.meta.sessions && (
                <SessionSelect
                  handleSelectSession={handleSelectSession}
                  sessionNumber={sessionNumber}
                  hasSessions={item.meta.sessions}
                />
              )}
            </div>
          ))}
        <div className="flex w-full border-t-2 border-blue-light py-2" />
        <Form>
          <div className="text-gray-dark uppercase text-xs font-medium mb-1">
            {t('prescription_modal__textarea__label')}
          </div>
          <Form.Field
            type="textarea"
            name="observations"
            register={register()}
            label={''}
            placeholder={'Escribe aqui...'}
            className="p-1"
            optional={t('prescription_modal__textarea__optional')}
            maxLength={100}
            defaultValue={activePetition.observations}
          />
        </Form>
        {selected_items?.map(
          (item) =>
            item.meta.component_name === activePetition.name &&
            item.meta.requires_report && (
              <AttachmentFileContainer
                key={item.id}
                handleAttachFile={handleAttachFile}
                file={file}
                setFile={setFile}
                fileRef={fileRef}
              />
            )
        )}
      </div>
    </div>
  );
};

const LateralitySelect = ({ handleCheckLaterality, laterality }) => {
  const { t } = useTranslation();
  return (
    <div className="pb-2">
      <div className="text-gray-dark uppercase text-xs font-medium py-2">
        {t('prescription_modal__laterality_label')}
      </div>
      <div className="flex flex-row grid grid-cols-5 py-2">
        <CheckboxOption
          option="left"
          col={1}
          handleCheckItem={handleCheckLaterality}
          laterality={laterality}
        />
        <CheckboxOption
          option="right"
          col={4}
          handleCheckItem={handleCheckLaterality}
          laterality={laterality}
        />
      </div>
    </div>
  );
};

const CheckboxOption = ({ option, handleCheckItem, col, laterality }) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        `flex flex-row py-2 text-dark relative col-span-${col}`
      )}
    >
      <Checkbox
        isChecked={laterality.includes(option)}
        onChange={() => {
          handleCheckItem(option);
        }}
      />
      <div
        className={classNames(
          'ml-2 cursor-default capitalize text-dark text-sm'
        )}
      >
        {t(`prescription_modal__laterality_option_${option}`)}
      </div>
    </div>
  );
};

const AttachmentFileContainer = ({
  handleAttachFile,
  file,
  setFile,
  fileRef,
}) => {
  const { t } = useTranslation();

  return (
    <div className="pb-2 mb-4">
      <div className="text-gray-dark uppercase text-xs font-medium pb-4">
        {t('prescription_modal__attachment_label')}
      </div>
      <File
        label={t('prescription_modal__attachment_button')}
        name="file-input"
        handleChange={handleAttachFile}
        file={file}
        setFile={setFile}
        placeholder={t('prescription_modal__attachment_placeholder')}
        fileRef={fileRef}
      />
    </div>
  );
};

export default ReviewTestBody;
