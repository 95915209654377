import Axios from 'axios';
import RestApi from 'legacy/Services/RestApi';
import ChatApi from 'legacy/modules/chat/chat.Api';
import { UnknownMedicalHistory } from 'legacy/modules/chat/CustomerDetails/unknownMedicalHistory';
import i18n from 'i18n/index';
import { config } from 'config';

class ConsultationsSingleton {
  constructor() {
    const httpConfig = {
      baseURL: config.services.mediquo.chat_url,
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        'X-Mdq-Origin': 'mdq-console',
        'X-Mdq-Trace-Id': localStorage.getItem('sessionId'),
      },
    };

    this.client = Axios.create(httpConfig);

    // IMPLEMENTATIONS
    this.chat = new ChatApi(this.client);

    this.sentence = new RestApi(this.client, 'professionals/v1/sentences');
    this.prescription_provider = new RestApi(
      this.client,
      'professionals/v1/prescription-provider'
    );
    this.automaticTrackings = new RestApi(
      this.client,
      'professionals/v1/tracking-messages'
    );
    this.medicalSummary = new RestApi(
      this.client,
      'professionals/v1/medical-history/summary'
    );
    this.allergies = new RestApi(
      this.client,
      'professionals/v1/medical-history/allergies'
    );
    this.diseases = new RestApi(
      this.client,
      'professionals/v1/medical-history/diseases'
    );
    this.medications = new RestApi(
      this.client,
      'professionals/v1/medical-history/medications'
    );
    this.notes = new RestApi(this.client, 'professionals/v1/notes');
    this.imc = new RestApi(
      this.client,
      'professionals/v1/medical-history/imcs'
    );
    this.unknownMedicalHistory = new UnknownMedicalHistory(
      this.client,
      'professionals/v1/medical-history'
    );

    this.prescriptionProviders = new RestApi(
      this.client,
      'professionals/v1/prescription-provider'
    );
  }

  static get instance() {
    return this._instance || (this._instance = new this());
  }

  updateHeaders(headerLines) {
    this.client.defaults.headers.common = {
      ...this.client.defaults.headers.common,
      ...headerLines,
    };
  }
}

const ConsultationsApiClient = ConsultationsSingleton.instance;
export default ConsultationsApiClient;
