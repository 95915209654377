import classnames from 'classnames';

export const Modal = ({ children, onClose, narrow }) => (
  <div className="fixed inset-x-0 bottom-0 z-10 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
    <div className="fixed inset-0 transition-opacity">
      <div
        onClick={onClose}
        className="absolute inset-0 opacity-75 bg-gray-medium"
      ></div>
    </div>
    <div
      className={`bg-white text-dark rounded-lg overflow-hidden shadow-xl transform transition-all ${classnames(
        {
          'sm:max-w-lg': narrow,
          'sm:max-w-2xl': !narrow,
        }
      )} sm:w-full`}
    >
      {children}
    </div>
  </div>
);

export const ModalBody = ({ children }) => (
  <div className="px-6 py-4 mb-4 bg-white">{children}</div>
);

export const ModalFooter = ({ children }) => (
  <div className="px-4 py-5 border-t bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse border-separators">
    {children}
  </div>
);

export const ModalHeader = ({ children, onClose }) => (
  <div
    id="handler"
    className="flex items-center px-6 py-5 text-xl font-medium bg-white border-b text-dark border-separators"
  >
    {children}
    <div
      onClick={onClose}
      className="flex items-center justify-center w-8 h-8 ml-auto rounded-lg cursor-pointer hover:bg-gray-light hover:text-gray-dark text-gray-medium"
    >
      <svg
        className="fill-current"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
      </svg>
    </div>
  </div>
);

export const ModalDismiss = ({ children, onClose }) => (
  <span className="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto">
    <button
      type="button"
      onClick={onClose}
      className="inline-flex items-center justify-center w-full h-12 px-4 py-2 font-medium tracking-widest uppercase bg-white border rounded-lg shadow-sm leading-button border-primary text-primary hover:text-primary-dark hover:bg-background focus:outline-none focus:border-primary-dark"
    >
      {children}
    </button>
  </span>
);

export const ModalConfirm = ({ children, onClick, isDisabled }) => (
  <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
    <button
      type="submit"
      disabled={isDisabled}
      onClick={onClick}
      className={`items-center h-12 tracking-widest min-h-0 uppercase inline-flex justify-center leading-button w-full rounded-lg border border-transparent px-4 py-2 bg-primary font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:border-primary-dark ${
        isDisabled ? 'cursor-not-allowed opacity-50' : ''
      }`}
    >
      {children}
    </button>
  </span>
);
