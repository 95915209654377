import { cn } from 'components/utils';
import AlertIcon from 'containers/Login/icons/AlertIcon';
import Header from 'containers/Prescription/components/Header';
import { useTranslation } from 'react-i18next';

export interface ValidationFailedViewProps {
  onClose: () => void;
}

export const Title = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => (
  <h1 className={cn('text-primary font-medium text-2xl', className)}>
    {children}
  </h1>
);

export const Paragraph = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => <p className={cn('text-primary-darker', className)}>{children}</p>;

export const ListItem = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <li className={cn('text-[#6D658B] list-disc ml-4', className)}>{children}</li>
);

const ValidationFailedView = ({ onClose }: ValidationFailedViewProps) => {
  const { t } = useTranslation();

  return (
    <div className="h-full flex flex-col gap-5 overflow-y-auto pb-10">
      <Header
        title={t('prescription__registration_modal_title')}
        onClose={onClose}
      />
      <div className="flex-1 flex flex-col justify-center">
        <div className="container mx-auto max-w-[440px] space-y-6">
          <AlertIcon className="mx-auto w-11 h-11 text-negative" />
          <Title className="mt-1">
            {t('prescription__rempe_validation_failed__title')}
          </Title>

          <div className="space-y-6">
            <Paragraph>
              {t('prescription__rempe_validation_failed__content.0')}
            </Paragraph>
            <ul>
              {[0, 1, 2, 3].map((index) => (
                <ListItem key={index}>
                  {t(`prescription__rempe_validation_failed__fields.${index}`)}
                </ListItem>
              ))}
            </ul>
            <Paragraph>
              {t('prescription__rempe_validation_failed__content.1')}
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidationFailedView;
