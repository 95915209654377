import Spinner from 'components/Spinner';
import { FC } from 'react';

export interface Props {
  shadow?: boolean;
}

const Loading: FC<Props> = ({ shadow = false }) => (
  <div className="grid w-full h-full place-content-center">
    {shadow ? (
      <ShadowWrapper>
        <Spinner />
      </ShadowWrapper>
    ) : (
      <Spinner />
    )}
  </div>
);

const ShadowWrapper: FC = ({ children }) => (
  <div className="grid w-12 h-12 bg-white shadow-md place-content-center rounded-2xl">
    {children}
  </div>
);

export default Loading;
