import Api from 'api';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { appointmentDocumented } from 'state/appointments.slice';

const useDocumentation = ({
  id,
  type,
  isAppointmentAction = false,
}: {
  id: string;
  type: string;
  isAppointmentAction?: boolean;
}) => {
  const [documentation, setDocumentation] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const dispatch = useDispatch();

  const get = () => {
    if (!id || !type) return;
    Api.documentation.get({ id, type }).then((data) => {
      const documentation = data.data.data;
      documentation.length &&
        isAppointmentAction &&
        dispatch(appointmentDocumented({ id, reports: documentation }));
      setDocumentation(documentation);
      documentation.length ? setIsEmpty(false) : setIsEmpty(true);
    });
  };

  useEffect(() => {
    get();
  }, [id, type]);

  return {
    get,
    documentation,
    isEmpty,
  };
};

export default useDocumentation;
