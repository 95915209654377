import { FC, useRef } from 'react';
import classnames from 'classnames';
import LogoMediquoProWhite from 'components/icons/LogoMediquoProWhite';
import useResizeObserver from 'utils/useResizeObserver';
import {
  Props,
  Nested,
  SidebarProps,
  SidebarNested,
  SectionProps,
  WaitingRoomItemProps,
  ContentNested,
  ItemProps,
  DividerProps,
  HeaderNested,
  FooterProps,
} from 'components/Layout/index.d';
import Caret from 'components/icons/outline/Caret';
import { useSelector } from 'react-redux';
import { usePortal } from 'containers/Shared/components/Portal';
import Confirmation from 'components/Modal/Confirmation';
import { useTranslation } from 'react-i18next';

const Layout: FC<Props> & Nested = ({ children, isSidebarOpen = true }) => (
  <div className="h-full bg-gray-light">
    <div
      className={classnames(
        'grid mx-auto h-full bg-white layout max-w-[1680px] shadow-lg',
        {
          open: isSidebarOpen,
          close: !isSidebarOpen,
        }
      )}
    >
      {children}
    </div>
  </div>
);

const Area: FC<{ area: string }> = ({ children, area }) => (
  <div className={classnames('flex flex-col overflow-hidden', area)}>
    {children}
  </div>
);

const Notifications: FC = ({ children }) => (
  <Area area="notifications">{children}</Area>
);

const Sidebar: FC<SidebarProps> & SidebarNested = ({ children, onToggle }) => {
  const sectionRef = useRef(null);
  const { width } = useResizeObserver({ ref: sectionRef });
  const isClosed = width < 180;

  return (
    <Area area="sidebar">
      <section
        ref={sectionRef}
        className="flex flex-col flex-shrink-0 h-full overflow-x-hidden overflow-y-auto text-white bg-dark"
      >
        <LogoMediquoProWhite
          className={classnames('mb-2', {
            'mt-4 mx-auto': isClosed,
            'mx-4 mt-5': !isClosed,
          })}
          icon={isClosed}
        />
        <div>
          <div
            className={classnames({
              'my-2': isClosed,
              'mt-4': !isClosed,
            })}
          >
            <div>
              <Divider className="w-72" />
            </div>
            <div className="flex justify-center">
              <button
                className={classnames(
                  'lg:block absolute shadow-lg rounded-full w-8 h-8 bg-white text-primary-dark border border-gray z-20',
                  {
                    'lg:mx-auto -translate-y-4 translate-x-7 rotate-[270deg]':
                      isClosed,
                    'lg:ml-auto -translate-y-4 translate-x-32 rotate-90':
                      !isClosed,
                  }
                )}
                onClick={onToggle}
              >
                <Caret className="w-3 m-auto" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-grow">
          {typeof children === 'function' ? children({ isClosed }) : children}
        </div>
      </section>
    </Area>
  );
};

const Section: FC<SectionProps> = ({ children, minimized, className = '' }) => (
  <ul
    className={classnames(
      'flex flex-col gap-2',
      {
        'py-2': minimized,
        'px-2 py-4': !minimized,
      },
      className
    )}
  >
    {children}
  </ul>
);

const Footer: FC<FooterProps> = ({ children, minimized, isHidden }) => (
  <div
    className={classnames('flex shrink-0 items-center', {
      'h-[76px] max-h-[76px]': minimized,
      'h-[60px] max-h-[60px]': !minimized,
      hidden: isHidden,
    })}
  >
    {children}
  </div>
);

const Divider: FC<DividerProps> = ({ className = '' }) => (
  <div className={classnames('mx-4 h-1px bg-primary', className)} />
);

const Item: FC<ItemProps> = ({
  label,
  isActive,
  onClick,
  icon,
  open,
  badge,
  className = '',
  isProfile = false,
  isOnline = false,
}) => {
  const buttonRef = useRef(null);
  const { width } = useResizeObserver({ ref: buttonRef });
  const inCall = useSelector((state: any) => state.waitingRoom.inCall);
  const portal = usePortal();
  const { t } = useTranslation();

  const openNavigationDeniedModal = () => {
    portal.open(
      <Confirmation
        title={t('videocall__navigation_modal_title')}
        content={t('videocall__navigation_modal_content')}
        confirmText={t('videocall__navigation_modal_confirm_text')}
        onConfirm={() => {
          portal.close('confirmation');
        }}
      />,
      'confirmation'
    );
  };

  return (
    <button
      ref={buttonRef}
      data-tip={width < 60 ? label : ''}
      data-place="right"
      onClick={() => {
        !inCall ? onClick() : openNavigationDeniedModal();
      }}
      className={classnames(
        'relative flex items-center text-sm justify-between mx-auto text-left rounded-lg hover:bg-white/10 focus:outline-none',
        {
          'bg-white/10 text-white': isActive,
          'text-gray-light': !isActive,
          'w-full': open,
          'py-2': isProfile,
          'bg-dark/100 hover:bg-dark/100': isProfile && !open && isActive,
        },
        className
      )}
    >
      <div
        className={classnames(
          'flex items-center justify-center flex-shrink-0 h-8 p-2 space-x-2 overflow-hidden',
          { 'w-8': !open }
        )}
      >
        {icon}
        {open && (
          <div className="flex flex-col">
            <span className="truncate">{label}</span>
            {isProfile && (
              <span className="text-[10px] -translate-y-1">
                {isOnline
                  ? t('sidebar__account_online')
                  : t('sidebar__account_offline')}
              </span>
            )}
          </div>
        )}
      </div>
      {!!badge && badge}
    </button>
  );
};

const WaitingRoomItem: FC<WaitingRoomItemProps> = ({
  label,
  onClick,
  icon,
  open,
  badge,
  isActive,
  className = '',
}) => {
  const buttonRef = useRef(null);
  //const { width } = useResizeObserver({ ref: buttonRef });
  const inCall = useSelector((state: any) => state.waitingRoom.inCall);
  const portal = usePortal();
  const { t } = useTranslation();

  const openNavigationDeniedModal = () => {
    portal.open(
      <Confirmation
        title={t('videocall__navigation_modal_title')}
        content={t('videocall__navigation_modal_content')}
        confirmText={t('videocall__navigation_modal_confirm_text')}
        onConfirm={() => {
          portal.close('confirmation');
        }}
      />,
      'confirmation'
    );
  };

  return (
    <button
      ref={buttonRef}
      // data-tip={width < 60 ? label : ''}
      data-place="right"
      onClick={() => {
        !inCall ? onClick() : openNavigationDeniedModal();
      }}
      className={classnames(
        'relative flex items-center text-sm justify-between mx-auto text-left rounded-lg hover:bg-white/10 focus:outline-none',
        {
          'bg-white/10 text-white': isActive,
          'text-gray-light': !isActive,
          'w-full': open,
        },
        className
      )}
    >
      <div
        className={classnames(
          'flex items-center justify-center flex-shrink-0 h-8 p-2 space-x-2 overflow-hidden',
          { 'w-8': !open }
        )}
      >
        <span
          className={classnames('rounded-lg', {
            'bg-online': isActive,
          })}
        >
          {icon}
        </span>
        {open && <span className="truncate">{label}</span>}
      </div>
      {!!badge && !open && badge}
      {open && <Caret className="w-2.5 -rotate-90 m-1 mx-2" />}
    </button>
  );
};

Sidebar.Section = Section;
Sidebar.Item = Item;
Sidebar.WaitingRoomItem = WaitingRoomItem;
Sidebar.Footer = Footer;

const Content: FC & ContentNested = ({ children }) => (
  <Area area="content">{children}</Area>
);

const Header: FC & HeaderNested = ({ children }) => (
  <div className="flex items-center min-h-[4rem] h-16 gap-4 px-4 bg-white border-b border-b-separators">
    {children}
  </div>
);

const Main: FC = ({ children }) => (
  <div className="flex items-center flex-grow">{children}</div>
);

const Actions: FC = ({ children }) => (
  <>
    <div className="w-[1px] bg-separators h-12" />
    <div className="flex items-center gap-2">{children}</div>
  </>
);

Header.Main = Main;
Header.Actions = Actions;
Content.Header = Header;

Layout.Notifications = Notifications;
Layout.Sidebar = Sidebar;
Layout.Content = Content;
Layout.Divider = Divider;

export default Layout;
