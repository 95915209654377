import classnames from 'classnames';
import useAnimation from 'utils/useAnimation';
import { FC } from 'react';
import { CloseIcon } from 'components/Drawer';

export interface Props {
  heightClassName?: string;
  onClose: (value?: unknown) => void;
  className?: string;
  onBackdrop?: ({ close }: { close: (value?: string) => void }) => void;
  isTransparent?: boolean;
  isBackgroundTransparent?: boolean;
}

const BottomDrawer: FC<Props> = ({
  children,
  heightClassName,
  onClose,
  onBackdrop,
  className,
  isTransparent = false,
  isBackgroundTransparent = false,
}) => {
  const { isOpen, close } = useAnimation({ onCloseFinish: onClose });

  return (
    <div className="fixed inset-0 z-20 ">
      <div
        className={classnames(
          'bg-black absolute inset-0 transition-all flex-grow',
          {
            'opacity-75': isOpen && !isBackgroundTransparent,
            'opacity-0': !isOpen || isBackgroundTransparent,
          }
        )}
        onClick={() => (onBackdrop ? onBackdrop({ close }) : close())}
      />
      <div
        className={classnames(`flex p-4`, {
          hidden: isBackgroundTransparent,
        })}
      >
        <button
          className="flex ml-auto items-center bg-background-dark text-white z-40 p-2.5 rounded-lg"
          onClick={() => (onBackdrop ? onBackdrop({ close }) : close())}
        >
          <CloseIcon className="h-3 w-3" />
        </button>
      </div>
      <div
        className={classnames(
          'absolute bottom-0 w-full flex transition-all transform overflow-hidden',
          heightClassName ?? 'h-[91vh]',

          {
            'h-0': !isOpen,
            'translate-h-full': isOpen,
            'bg-transparent': isTransparent,
            'bg-white': !isTransparent,
            'shadow-[0px_-20px_40px_-20px_#00000024]': isBackgroundTransparent,
          },
          className
        )}
      >
        {typeof children === 'function' ? children({ close }) : children}
      </div>
    </div>
  );
};

export default BottomDrawer;
