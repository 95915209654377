import { actionTypes as waitingRoomTypes } from 'containers/WaitingRoom/actions/GetWaitingRoom.actions';

const initialState = {
  isLoading: false,
  inCall: false,
  summary: 0,
  data: [],
  accessData: [],
  exitData: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case waitingRoomTypes.IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case waitingRoomTypes.GET_ALL_FETCHED:
      let prevData = state.data;
      let newData = action.payload.data;
      let accessedElements = [];
      let exitElements = [];

      const prevDataConsultationIds = prevData.map(prevDataElement => prevDataElement.consultation_id);
      const newDataConsultationIds = newData.map(newDataElement => newDataElement.consultation_id);

      newData.forEach(newDataElement => {
        if (!prevDataConsultationIds.includes(newDataElement.consultation_id)) {
          accessedElements.push(newDataElement)
        }
      })

      prevData.forEach(prevDataElement => {
        if (!newDataConsultationIds.includes(prevDataElement.consultation_id)) {
          exitElements.push(prevDataElement)
        }
      })

      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        accessData: accessedElements,
        exitData: exitElements,
        summary: action.payload.data.length,
      };
    case waitingRoomTypes.IN_CALL:
      return {
        ...state,
        inCall: true,
      };
    case waitingRoomTypes.NOT_IN_CALL:
      return {
        ...state,
        inCall: false,
      };
    default:
      return state;
  }
};

export default reducer;
