const Check = (props) => (
  <svg
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5334 4.6368L5.9467 11.2235L3.6267 8.91013C3.3667 8.65014 2.9467 8.65014 2.6867 8.91013C2.4267 9.17013 2.4267 9.59014 2.6867 9.85014L5.47337 12.6368C5.73337 12.8968 6.15337 12.8968 6.41337 12.6368L13.4734 5.58347C13.7334 5.32347 13.7334 4.90347 13.4734 4.64347H13.4667C13.2134 4.3768 12.7934 4.3768 12.5334 4.6368Z"
      fill="currentColor"
    />
  </svg>
);

export default Check;
