import axios, { AxiosPromise } from 'axios';
import getHeaders from 'utils/getHeaders';
import {
  BillingDocumentsAddData,
  BillingStorageGetData,
  BillingStorageUploadData,
  BillingUpdateData,
  ProfileBilling,
} from 'api/profile.d';
import { config } from 'config';

const PRO = 'pro/v1';
const BASE_URL = config.services.mediquo.api_url;
const CHAT_URL = config.services.mediquo.chat_url;

const get = () =>
  axios({
    method: 'GET',
    url: `${BASE_URL}${PRO}/profile`,
    headers: getHeaders(),
  });

const update = (data: any) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}${PRO}/profile`,
    data,
    headers: getHeaders(),
  });

const status = {
  chat: ({ status }: any) =>
    axios({
      method: 'PUT',
      url: `${CHAT_URL}/professionals/v1/profile/status`,
      data: {
        status,
      },
      headers: getHeaders(),
    }),
  videocall: ({ status }: any) =>
    axios({
      method: 'PUT',
      url: `${CHAT_URL}/professionals/v1/profile/immediate-video-call/status`,
      data: {
        status,
      },
      headers: getHeaders(),
    }),
};

const avatar = {
  upload: async ({ file }: any) => {
    const res = await axios({
      method: 'get',
      url: `${CHAT_URL}/professionals/v1/profile/storage`,
      headers: getHeaders(),
    });

    await axios.put(res.data.data.presigned_storage_url, file, {
      headers: {
        'Content-Type': 'image/jpeg',
        'Content-Encoding': 'base64',
      },
    });

    return res.data.data.presigned_storage_url.split('?')[0];
  },
};

const chat = {
  get: () =>
    axios({
      method: 'GET',
      url: `${CHAT_URL}/professionals/v1/profile`,
      headers: getHeaders(),
    }),
};

const billing = {
  get: (): AxiosPromise<ProfileBilling> =>
    axios({
      method: 'GET',
      url: `${BASE_URL}${PRO}/profile/billing`,
      headers: getHeaders(),
    }),
  validate: () =>
    axios({
      method: 'PUT',
      url: `${CHAT_URL}/professionals/v1/profile/billing/validate`,
      headers: getHeaders(),
    }),
  storage: {
    get: ({ name }: BillingStorageGetData) =>
      axios({
        method: 'GET',
        url: `${BASE_URL}${PRO}/profile/billing/storage`,
        params: { filename: name ? window.btoa(name) : undefined },
        headers: getHeaders(),
      }),
    upload: ({ url, file: data }: BillingStorageUploadData) =>
      axios({
        method: 'PUT',
        url,
        data,
        headers: { 'Content-Type': data.type },
      }),
  },
  update: ({
    name: billing_fiscal_name,
    lastName: billing_fiscal_last_name,
    address: billing_address,
    birthDate: billing_birth_date,
    postalCode: billing_postal_code,
    iban: billing_iban,
    city: billing_city,
    country: billing_country,
    idDocumentFrontUrl: id_document_front_url,
    idDocumentReverseUrl: id_document_reverse_url,
    selfEmploymentDocumentUrl: self_employment_front_url,
  }: BillingUpdateData): AxiosPromise<any> =>
    axios({
      method: 'PUT',
      url: `${BASE_URL}${PRO}/profile/billing`,
      data: {
        billing_fiscal_name,
        billing_fiscal_last_name,
        billing_address,
        billing_birth_date,
        billing_postal_code,
        billing_iban,
        billing_city,
        billing_country,
        id_document_front_url,
        id_document_reverse_url,
        self_employment_front_url,
      },
      headers: getHeaders(),
    }),
  documents: {
    add: ({
      idDocumentFrontUrl: id_document_front_url,
      idDocumentReverseUrl: id_document_reverse_url,
      selfEmploymentDocumentUrl: self_employment_front_url,
    }: BillingDocumentsAddData) =>
      axios({
        method: 'PATCH',
        url: `${BASE_URL}${PRO}/profile/billing/documents`,
        data: {
          id_document_front_url,
          id_document_reverse_url,
          self_employment_front_url,
        },
        headers: getHeaders(),
      }),
  },
};

export default { get, update, status, billing, chat, avatar };
