const Dollar = (props: any) => (
  <svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M8.6709 13.5666V13.2492C9.12933 13.1698 11.3333 12.729 11.3333 10.4104C11.3333 9.1762 10.7955 8.10065 8.67971 7.36893C7.11047 6.84879 6.3523 6.53141 6.3523 5.69389C6.3523 4.79466 7.33087 4.46847 7.94799 4.46847C8.6709 4.46847 9.1117 4.8123 9.35854 5.1473C9.57013 5.4206 9.9404 5.52639 10.2578 5.39415C10.7162 5.2002 10.8749 4.63598 10.5751 4.24807C10.2137 3.77201 9.61421 3.2695 8.6709 3.102V2.77581C8.6709 2.34382 8.31826 2 7.89509 2C7.47193 2 7.11929 2.34382 7.11929 2.77581V3.09318C4.81832 3.58688 4.8095 5.61455 4.8095 5.70271C4.8095 7.71275 6.7931 8.27697 7.76285 8.6208C9.15578 9.12331 9.7729 9.56411 9.7729 10.4104C9.7729 11.4066 8.84722 11.8298 8.02733 11.8298C6.99586 11.8298 6.44046 11.1598 6.15834 10.622C5.97321 10.2782 5.56767 10.1283 5.20622 10.2782L5.14451 10.3046C4.73897 10.4722 4.54502 10.957 4.74779 11.3449C5.4178 12.6585 6.56388 13.064 7.11929 13.1963V13.5666C7.11929 13.9985 7.47193 14.3424 7.89509 14.3424C8.32708 14.3424 8.6709 13.9897 8.6709 13.5666Z"
      fill="currentColor"
    />
  </svg>
);

export default Dollar;
