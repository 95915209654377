import ConsultationsApiClient from 'legacy/Services/Consultations';
import { clearRoom } from 'containers/Inbox/actions/GetRoom.actions';
import { getRoomMessages } from 'containers/Room/actions/GetMessages.actions';
import { getSummaryRoomsList } from 'containers/Inbox/actions/GetRoomList.actions';
import { appointmentPartialFetched } from 'state/appointments.slice';
import api from 'api';
import { socket } from 'socket';

const FETCHING_ROOM = 'ROOM/FETCHING_ROOM';
const ROOM_FETCHED = 'ROOM/ROOM_FETCHED';
const CHAT_CONSULTATION_STARTED = 'ROOM/CHAT_CONSULTATION_STARTED';
const CLEAR_CHAT_CONSULTATION = 'ROOM/CLEAR_CHAT_CONSULTATION';

export const actionTypes = {
  FETCHING_ROOM,
  ROOM_FETCHED,
  CHAT_CONSULTATION_STARTED,
  CLEAR_CHAT_CONSULTATION,
};

export const reconnectCurrentRoom = () => {
  return (_, getState) => {
    const room_id = getState().console?.current?.room_id;
    if (room_id !== undefined) {
      socket.emit('joinRoom', room_id);
    }
  };
};

export const joinRoom = (roomId, signal) => {
  return (dispatch) => {
    dispatch(fetchingRoom(roomId));
    return ConsultationsApiClient.chat
      .fetchRoom(roomId, signal)
      .then(({ data: room }) => {
        dispatch(getRoomMessages(roomId));
        dispatch(roomFetched(room));
        !!room.current_appointment &&
          dispatch(
            appointmentPartialFetched({
              room_id: roomId,
              ...room.current_appointment,
            })
          );
        socket.emit('joinRoom', roomId);
        dispatch(readMessages(room));
        readRoom(room);
      })
      .catch((e) => {
        // if message is 'canceled', req was aborted, so no need to throw error
        if (e.message === 'canceled') {
          return;
        }

        throw e;
      });
  };
};

export const readMessages = (room) => {
  return (dispatch) => {
    return ConsultationsApiClient.chat
      .readMessagesOfRoom(room.room_id)
      .then(() => {
        dispatch(getSummaryRoomsList());
        dispatch(clearRoom(room));
      });
  };
};

export const readRoom = (room) => api.rooms.read({ roomId: room.room_id });

export const fetchingRoom = (roomId) => {
  return {
    type: FETCHING_ROOM,
    payload: roomId,
  };
};

export const roomFetched = (room) => {
  return {
    type: ROOM_FETCHED,
    payload: room,
  };
};

export const chatConsultationStarted = (consultationId) => {
  return (dispatch, getState) => {
    const room = getState().console?.current;
    if (room?.current_chat_consultation?.id === consultationId) {
      dispatch(activateConsultation());
    }
  };
};

const activateConsultation = () => {
  return {
    type: CHAT_CONSULTATION_STARTED,
    payload: 'activated',
  };
};

export const consultationFinished = () => {
  return (dispatch) => dispatch(clearConsultation());
};

export const clearConsultation = () => {
  return {
    type: CLEAR_CHAT_CONSULTATION,
  };
};
