import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  ModalBody,
  ModalFooter,
} from 'containers/Shared/components/Modal/Modal';
import ProfileContact from 'containers/Invitations/img/ProfileContact';
import Button from 'components/Button';
import Form from 'components/Form';
import useQuery from 'utils/useQuery';
import api from 'api';
import { number } from 'utils/regexp';
import useLazyQuery from 'utils/useLazyQuery';
import Api from 'api';
import { Room } from 'api/invitations.d';
import { timeAgoUnits } from 'utils/date';
import classnames from 'classnames';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';

export type Fields = {
  nhc: string;
  center_code: string;
};

type Patient = {
  birth_date: string;
  first_name: string;
  last_name: string;
};

export type SearchError = {
  type: 'patient_with_duplicated_nhc' | 'not_found';
};

export interface Props {
  onSearchError: (error: Fields & SearchError) => void;
  onInvited: (room: Room) => void;
}

const InviteForm: FC<Props> = ({ onSearchError, onInvited }) => {
  const { t } = useTranslation();
  const { data, isLoading: isLoadingCenters }: any = useQuery(
    api.license.organization.centers.get
  );
  const [search, isSearchLoading] = useLazyQuery(Api.invitations.vithas.search);
  const [invite, isInviteLoading] = useLazyQuery(Api.invitations.vithas.create);
  const [patients, setPatients] = useState<{ [key: string]: Patient | null }>(
    {}
  );

  const Fields = {
    nhc: {
      name: 'nhc',
      label: t('invite__vithas_contact_form_nhc_label'),
      type: 'text',
      required: t('shared__errors_required'),
      pattern: {
        value: number,
        message: t('shared__errors_only_numbers'),
      },
    },
    center_code: {
      name: 'center_code',
      label: t('invite__vithas_contact_form_center_code_label'),
      type: 'text',
      defaultValue: '',
      required: t('shared__errors_required'),
    },
  };

  const {
    register,
    watch,
    handleSubmit,
    errors,
    formState: { dirtyFields },
    control,
  } = useForm({ mode: 'onChange' });
  const watchFields = watch();

  const key = `${watchFields[Fields.nhc.name]}-${
    watchFields[Fields.center_code.name]
  }`;

  const addPatient = (patient: Patient | null) => {
    setPatients((patients) => ({ ...patients, [key]: patient }));
  };

  const searchPatient = ({ nhc, center_code }: Fields) =>
    (search as any)({ nhc, center_code })
      .then(({ data: { data: patient } }: AxiosResponse<{ data: Patient }>) =>
        addPatient(patient)
      )
      .catch((error: AxiosError) => {
        const data = error?.response?.data as { error: { type: string } };

        onSearchError({
          nhc,
          center_code,
          type:
            data?.error?.type === 'patient_with_duplicated_nhc'
              ? 'patient_with_duplicated_nhc'
              : 'not_found',
        })
      }
      );

  const sendInvitation = (fields: Fields) =>
    (invite as any)(fields)
      .then(({ data: { data: room } }: AxiosResponse<{ data: Room }>) =>
        onInvited(room)
      )
      .catch(() =>
        toast.error(
          <ErrorToast>{t('shared__errors_generic_message')}</ErrorToast>
        )
      );

  const patient = key ? patients[key] : undefined;
  const isLoading = !!isSearchLoading || !!isInviteLoading;

  return (
    <Form onSubmit={(event: any) => event.preventDefault()}>
      <ModalBody>
        <div className="flex flex-col items-center">
          <Header patient={patient} isLoading={isLoading} />
          <Form.Field
            type={Fields.nhc.type}
            name={Fields.nhc.name}
            label={Fields.nhc.label}
            placeholder={Fields.nhc.label}
            errors={errors[Fields.nhc.name]}
            isDirty={dirtyFields[Fields.nhc.name]}
            readOnly={isLoading}
            register={register({
              required: Fields.nhc.required,
              pattern: Fields.nhc.pattern,
            })}
          />
          <Form.Dropdown
            isLoading={isLoadingCenters}
            control={control}
            name={Fields.center_code.name}
            label={Fields.center_code.label}
            placeholder={Fields.center_code.label}
            required={Fields.center_code.required}
            defaultValue={Fields.center_code.defaultValue}
            disabled={isLoading}
            options={data?.data?.data
              ?.filter((center: any) => center.external_code)
              .map((center: any) => ({
                value: center.external_code,
                label: center.name,
              }))}
            errors={errors[Fields.center_code.name]}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex gap-4">
          <Buttons
            patient={patient}
            isLoading={isLoading}
            onClickSearch={handleSubmit(searchPatient)}
            onClickInvite={handleSubmit(sendInvitation)}
          />
        </div>
      </ModalFooter>
    </Form>
  );
};

const Header = ({
  patient,
  isLoading,
}: {
  patient?: Patient | null;
  isLoading: boolean;
}) => (
  <div
    className={classnames('flex flex-col items-center gap-4 m-8', {
      'animate animate-pulse': isLoading,
    })}
  >
    <HeaderImage text={patient?.first_name} />
    <HeaderPatient patient={patient} />
  </div>
);

const HeaderImage = ({ text }: { text?: string }) => {
  if (!text) {
    return <ProfileContact />;
  }

  return (
    <div className="flex items-center justify-center text-4xl font-medium uppercase rounded-full h-80px w-80px bg-blue-light text-primary">
      {text.charAt(0)}
    </div>
  );
};

const HeaderPatient = ({ patient }: { patient?: Patient | null }) => {
  const { t } = useTranslation();

  if (!patient) {
    return (
      <div className="text-gray-medium opacity-70">
        {t('invite__vithas_contact_form_patient')}
      </div>
    );
  }

  return (
    <div className="flex gap-4">
      <strong className="capitalize">
        {`${patient.first_name.toLowerCase()} ${patient.last_name.toLowerCase()}`}
      </strong>
      <div>
        {t('shared__years', {
          count: timeAgoUnits(patient.birth_date, {
            unit: 'year',
            filter: 'strict',
          })?.value,
        })}{' '}
        <span className="text-gray-medium">{`(${patient.birth_date})`}</span>
      </div>
    </div>
  );
};

const Buttons = ({
  patient,
  isLoading,
  onClickSearch,
  onClickInvite,
}: {
  patient?: Patient | null;
  isLoading: boolean;
  onClickSearch: () => void;
  onClickInvite: () => void;
}) => {
  const { t } = useTranslation();

  if (!patient) {
    return (
      <Button
        type="submit"
        disabled={isLoading}
        loading={isLoading}
        onClick={onClickSearch}
      >
        {t(
          isLoading
            ? 'invite__vithas_contact_form_button_search_loading'
            : 'invite__vithas_contact_form_button_search'
        )}
      </Button>
    );
  }

  return (
    <Button
      type="submit"
      disabled={isLoading}
      loading={isLoading}
      onClick={onClickInvite}
    >
      {t(
        isLoading
          ? 'invite__vithas_contact_form_button_invite_loading'
          : 'invite__vithas_contact_form_button_invite'
      )}
    </Button>
  );
};

export default InviteForm;
