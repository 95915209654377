import { useState, useEffect } from 'react';
import classnames from 'classnames';
import Api from 'api';
import { useTranslation } from 'react-i18next';
import getSymbolFromCurrency from 'currency-symbol-map';
import calculateTotalPrice from 'containers/Appointments/calculateTotalPrice';

export const FieldPrice = ({
  name,
  errors,
  value,
  type,
  placeholder,
  register,
  disabled,
  step,
  ref,
  currency,
}) => {
  const { t } = useTranslation();
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    Api.appointments
      .fees((value ?? 0) * 100)
      .then(({ data }) =>
        setTotalPrice(
          calculateTotalPrice({ priceInCents: value * 100, fees: data.data })
        )
      );
  }, [value]);

  const handleOnKeyPress = (event) =>
    ['e', '+', '-'].includes(event.key) && event.preventDefault();

  return (
    <div className="relative h-56px flex flex-row">
      <span className="absolute text-gray-medium self-center ml-4">
        {t('appointments__payment_form_appointment_price')}
      </span>
      <input
        id={name}
        step={step}
        name={name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        onKeyPress={handleOnKeyPress}
        className={classnames(
          'w-full py-4 pl-16 text-right min-h-0 rounded-lg focus:outline-none focus-visible:ring-1 pr-7 bg-background',
          {
            'text-negative focus-visible:ring-negative':
              !!value && errors.price,
            'text-primary focus-visible:ring-primary': !value || !errors.price,
            'bg-gray-light cursor-not-allowed': disabled,
          }
        )}
        ref={(element) => {
          if (ref) {
            ref.current = element;
          }
          register(element);
        }}
      />
      <div
        className={classnames(
          'absolute flex h-full items-end pt-4 pb-4 pr-4 right-0 top-0',
          {
            'text-gray-medium': !value,
            'text-primary': !!value && !errors.price,
            'text-negative': !!value && errors.price,
          }
        )}
      >
        <span>{getSymbolFromCurrency(currency)}</span>
      </div>
      <small
        className={classnames(
          'absolute bottom-0 pt-2 transform translate-y-full',
          {
            'text-negative right-0': !!errors.price,
            'text-gray-medium left-0 font-medium text-xs': !errors.price,
            hidden: !value,
          }
        )}
      >
        {errors.price?.message ||
          t('appointments__payment_form_price_info', {
            price: `${totalPrice}${getSymbolFromCurrency(currency)}`,
          })}
      </small>
    </div>
  );
};
