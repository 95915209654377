import axios from 'axios';
import getHeaders from 'utils/getHeaders';
import { JoinRQ } from 'api/calls/index.d';
import { config } from 'config';

const API = 'professionals/v1';
const BASE_URL = config.services.mediquo.chat_url;

const join = ({callId}: JoinRQ) =>
    axios({
        method: 'GET',
        url: `${BASE_URL}/${API}/calls/${callId}/join`,
        headers: getHeaders(),
    });

export default {
    join
};
