import { Room } from 'api/rooms';
import Drawer from 'components/Drawer';

import OsiguCreatePrescription from 'containers/Prescription/Osigu/OsiguCreatePrescription';
import OsiguRegisterDoctor from 'containers/Prescription/Osigu/OsiguRegisterDoctor';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Contact } from 'services/contacts';

import useConfirm from 'utils/useConfirm';
import { useCredentials, LoadingDrawer } from 'containers/Prescription/index';

export interface Props {
  room?: Room;
  contact?: Partial<Contact>;
  consultationType?: string;
  consultationId?: string;
  onClose: () => void;
  widgetCredentials: any;
}

type Screen = 'idle' | 'register' | 'prescription';

const OsiguPrescriptionDrawer: FC<Props> = ({
  room,
  contact,
  consultationType,
  consultationId,
  onClose,
  widgetCredentials,
}) => {
  const { credentials, setCredentials, isLoading } = useCredentials({
    widgetCredentials,
  });

  const [screen, setScreen] = useState<Screen>('idle');

  useEffect(() => {
    const nextScreen = () => {
      if (isLoading) {
        return 'idle';
      }

      if (!credentials) {
        return 'register';
      }

      return 'prescription';
    };
    setScreen(nextScreen());
  }, [credentials, isLoading, room]);

  const confirm = useConfirm({
    when: ['register', 'prescription'].includes(screen),
  });

  const closeSafe = ({ onClose }: { onClose: () => void }) =>
    confirm().then(onClose);

  return (
    <div>
      {
        {
          idle: <LoadingDrawer />,
          register: (
            <Register
              onRegister={(credentials: any) => {
                setCredentials(credentials);
                setScreen('prescription');
              }}
              providerCredentials={widgetCredentials}
              onClose={() => closeSafe({ onClose })}
            />
          ),
          prescription: (
            <Prescription
              room={room!}
              contact={contact!}
              credentials={credentials}
              providerCredentials={widgetCredentials}
              consultationType={consultationType}
              consultationId={consultationId}
              onClose={() => closeSafe({ onClose })}
              onFinish={() => setTimeout(onClose, 500)}
              onBack={() => closeSafe({ onClose })}
            />
          ),
        }[screen]
      }
    </div>
  );
};

const Register: FC<{
  onRegister: (event: any) => void;
  providerCredentials: any;
  onClose: () => void;
}> = ({ providerCredentials, onClose, onRegister }) => {
  const { t } = useTranslation();

  return (
    <div className="h-full">
      <Drawer.Header
        title={t('prescription__register_title')}
        onClose={onClose}
      />
      <div className="px-4 pt-8 pb-4 overflow-y-scroll h-[90vh]">
        <OsiguRegisterDoctor
          onRegister={onRegister}
          providerCredentials={providerCredentials}
        />
      </div>
    </div>
  );
};

const Prescription: FC<{
  room?: Room;
  contact: Partial<Contact>;
  credentials: any;
  providerCredentials: any;
  consultationType?: any;
  consultationId?: any;
  onBack: () => void;
  onClose: () => void;
  onFinish: () => void;
}> = ({
  room,
  contact,
  credentials,
  providerCredentials,
  consultationType,
  consultationId,
  onBack,
  onClose,
  onFinish,
}) => {
  const { t } = useTranslation();
  const { patient } = useSelector((state: any) => state.patientBar);

  return (
    <div className="h-full">
      <Drawer.Header
        title={t('prescription__create_title')}
        onClose={onClose}
      />
      <div className="px-4 pt-8 pb-4 overflow-y-scroll h-[90vh]">
        <OsiguCreatePrescription
          onFinish={onFinish}
          credentials={credentials}
          roomId={room?.room_id}
          hash={contact?.id ?? room?.meta?.contact_id}
          userHash={room?.meta?.hash}
          patient={
            room
              ? {
                  ...patient,
                  template: room.meta?.permissions.prescriptions.template,
                }
              : contact
          }
          providerCredentials={providerCredentials}
          consultationType={consultationType}
          consultationId={consultationId}
        />
      </div>
    </div>
  );
};

export default OsiguPrescriptionDrawer;
