const Filter = (props: any) => (
  <svg
    {...props}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 8.94267V8.73556L6.35355 8.58911L2.74222 4.97778C2.58925 4.82481 2.5 4.6093 2.5 4.39333V2.66667C2.5 2.62246 2.51756 2.58007 2.54882 2.54882C2.58007 2.51756 2.62246 2.5 2.66667 2.5H13.3333C13.3775 2.5 13.4199 2.51756 13.4512 2.54881C13.4824 2.58007 13.5 2.62246 13.5 2.66667V4.39333C13.5 4.6093 13.4107 4.82482 13.2578 4.97778L9.64645 8.58911L9.5 8.73556V8.94267V12.6667C9.5 12.7297 9.46452 12.7876 9.4072 12.8164L6.74189 14.149C6.71639 14.1617 6.68808 14.1677 6.65963 14.1664C6.63117 14.1651 6.60353 14.1565 6.57931 14.1415C6.55509 14.1266 6.53509 14.1056 6.52121 14.0808C6.50737 14.056 6.50008 14.0281 6.5 13.9997C6.5 13.9996 6.5 13.9995 6.5 13.9994L6.5 8.94267Z"
      stroke="currentColor"
    />
  </svg>
);

export default Filter;
