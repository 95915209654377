import { stopTyping, typing } from 'containers/Room/actions/Typing.actions';

export const onTyping = (dispatch, profile) => {
  return (data) => {
    if (data.fromUserHash !== profile.hash) {
      dispatch(typing(data.roomId));
    }
  };
};

export const onStopTyping = (dispatch, profile) => {
  return (data) => {
    if (data.fromUserHash !== profile.hash) {
      dispatch(stopTyping(data.roomId));
    }
  };
};
