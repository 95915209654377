import { useIMCs } from 'containers/DetailBar/PatientBar/hooks/imcs.hook';
import AccordeonHandler from 'containers/Shared/components/Accordeon/AccordeonHandler';
import {
  AccordeonHeader,
  AccordeonBody,
  AccordeonButton,
} from 'containers/Shared/components/Accordeon/AccordeonPanel';
import IMCModal from 'containers/DetailBar/PatientBar/components/IMCs/IMCModal';
import IMCItem from 'containers/DetailBar/PatientBar/components/IMCs/IMCItem';
import { useTranslation } from 'react-i18next';
import { usePortal } from 'containers/Shared/components/Portal';

const IMCContainer = ({ hash, badge, onClose }) => {
  const [imcs, store, destroy] = useIMCs(hash);
  const { t } = useTranslation();
  const portal = usePortal();

  const openIMCModal = () =>
    portal.open(
      <IMCModal
        onClose={() => portal.close('imc')}
        onSubmit={(imc) => {
          store(imc, hash);
          portal.close('imc');
        }}
      />,
      'imc'
    );

  return (
    <div>
      <div className="p-4">
        <AccordeonHeader onClose={onClose} badge={badge}>
          {t('sidebar__patient_imc_title')}
        </AccordeonHeader>
        <AccordeonBody show={badge === 0}>
          {t('sidebar__patient_imc_empty_text')}
        </AccordeonBody>
        <AccordeonButton onClick={openIMCModal}>
          {t('sidebar__patient_imc_add')}
        </AccordeonButton>
      </div>
      <div className="border-b border-separators">
        {imcs.map((imc) => (
          <IMCItem key={imc.id} imc={imc} onDelete={() => destroy(imc.id)} />
        ))}
      </div>
    </div>
  );
};

const IMCsPanel = ({ badge, open, onOpen, onClose, hash }) => {
  const { t } = useTranslation();
  if (!open) {
    return (
      <AccordeonHandler onOpen={onOpen} badge={badge}>
        {t('sidebar__patient_imc_title')}
      </AccordeonHandler>
    );
  }

  return <IMCContainer hash={hash} onClose={onClose} badge={badge} />;
};

export default IMCsPanel;
