import { actionTypes as profileActions } from 'containers/DetailBar/PatientBar/actions/GetMedicalHistory.actions';
import { actionTypes as medicalHistoryActions } from 'containers/DetailBar/PatientBar/actions/MedicalHistory.actions';
import { actionTypes as prescriptionActions } from 'containers/Prescription/actions/Prescription.actions';
import { actionTypes as reportsActions } from 'containers/MedicalReport/actions';
import { actionTypes } from 'containers/DetailBar/PatientBar/actions/UpdateBillingInformation';
import { actionTypes as contactActions } from 'containers/Contacts/actions/Contact.actions';

const initialState = {
  isLoading: true,
  patient: undefined,
  summary: undefined,
  screen: 'profile',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PATIENT_BILLING_PROFILE_UPDATED:
      return {
        ...state,
        patient: {
          ...state.patient,
          billing: action.payload,
        },
      };
    case profileActions.FETCHING_PATIENT_PROFILE:
      return {
        ...state,
        isLoading: true,
      };
    case profileActions.PATIENT_PROFILE_FETCHED:
      return {
        ...state,
        patient: action.payload,
        isLoading: false,
      };
    case profileActions.FETCHING_MEDICAL_HISTORY:
      return {
        ...state,
        isLoading: true,
      };
    case profileActions.MEDICAL_HISTORY_FETCHED:
      return {
        ...state,
        isLoading: false,
        summary: action.payload,
      };
    case medicalHistoryActions.IMC_CREATED:
      return {
        ...state,
        summary: {
          ...state.summary,
          imcs_count: state.summary.imcs_count + 1,
        },
      };
    case medicalHistoryActions.IMC_DELETED:
      return {
        ...state,
        summary: {
          ...state.summary,
          imcs_count: state.summary.imcs_count - 1,
        },
      };
    case medicalHistoryActions.NOTE_CREATED:
      return {
        ...state,
        summary: {
          ...state.summary,
          notes_count: state.summary.notes_count + 1,
        },
      };
    case medicalHistoryActions.NOTE_DELETED:
      return {
        ...state,
        summary: {
          ...state.summary,
          notes_count: state.summary.notes_count - 1,
        },
      };
    case medicalHistoryActions.ALLERGY_CREATED:
      return {
        ...state,
        summary: {
          ...state.summary,
          allergies_count: state.summary.allergies_count + 1,
        },
      };
    case medicalHistoryActions.ALLERGY_DELETED:
      return {
        ...state,
        summary: {
          ...state.summary,
          allergies_count: state.summary.allergies_count - 1,
        },
      };
    case medicalHistoryActions.CLINICAL_COURSE_CREATED:
      return {
        ...state,
        summary: {
          ...state.summary,
          clinical_courses_count: state.summary.clinical_courses_count + 1,
        },
      };
    case medicalHistoryActions.CLINICAL_COURSE_DELETED:
      return {
        ...state,
        summary: {
          ...state.summary,
          clinical_courses_count: state.summary.clinical_courses_count - 1,
        },
      };
    case medicalHistoryActions.UNKNOWN_ALLERGY_CREATED:
      return {
        ...state,
        summary: {
          ...state.summary,
          unknown_allergies_at: true,
        },
      };
    case medicalHistoryActions.UNKNOWN_ALLERGY_DELETED:
      return {
        ...state,
        summary: {
          ...state.summary,
          unknown_allergies_at: null,
        },
      };
    case medicalHistoryActions.MEDICATION_CREATED:
      return {
        ...state,
        summary: {
          ...state.summary,
          medications_count: state.summary.medications_count + 1,
        },
      };
    case medicalHistoryActions.MEDICATION_DELETED:
      return {
        ...state,
        summary: {
          ...state.summary,
          medications_count: state.summary.medications_count - 1,
        },
      };
    case medicalHistoryActions.UNKNOWN_MEDICATION_CREATED:
      return {
        ...state,
        summary: {
          ...state.summary,
          unknown_medications_at: true,
        },
      };
    case medicalHistoryActions.UNKNOWN_MEDICATION_DELETED:
      return {
        ...state,
        summary: {
          ...state.summary,
          unknown_medications_at: null,
        },
      };
    case medicalHistoryActions.DISEASE_CREATED:
      return {
        ...state,
        summary: {
          ...state.summary,
          diseases_count: state.summary.diseases_count + 1,
        },
      };
    case medicalHistoryActions.DISEASE_DELETED:
      return {
        ...state,
        summary: {
          ...state.summary,
          diseases_count: state.summary.diseases_count - 1,
        },
      };
    case medicalHistoryActions.UNKNOWN_DISEASE_CREATED:
      return {
        ...state,
        summary: {
          ...state.summary,
          unknown_diseases_at: true,
        },
      };
    case medicalHistoryActions.UNKNOWN_DISEASE_DELETED:
      return {
        ...state,
        summary: {
          ...state.summary,
          unknown_diseases_at: null,
        },
      };
    case prescriptionActions.PRESCRIPTION_CREATED:
      return {
        ...state,
        summary: {
          ...state.summary,
          prescriptions_count: state.summary.prescriptions_count + 1,
        },
      };
    case prescriptionActions.PRESCRIPTION_ADDED:
      return {
        ...state,
        summary: {
          ...state.summary,
          prescriptions_count: state.summary.prescriptions_count + 1,
          prescriptions_added: state.summary?.prescriptions_count + 1,
        },
      };
    case reportsActions.REPORT_CREATED:
      return {
        ...state,
        summary: {
          ...state.summary,
          reports_count: state.summary.reports_count + 1,
        },
      };
    case medicalHistoryActions.DIAGNOSTIC_TESTS_FETCHED:
      return {
        ...state,
        summary: {
          ...state.summary,
          diagnostic_test_prescriptions_count: action.payload,
        },
      };
    case medicalHistoryActions.SCREEN_CHANGED:
      return {
        ...state,
        screen: action.payload,
      };
    case contactActions.CONTACT_NAME_UPDATED:
      return {
        ...state,
        patient: {
          ...state.patient,
          name: action.payload.name,
          tag_id: action.payload.external_id,
          email: action.payload.email,
        },
      };
    default:
      return state;
  }
};

export default reducer;
