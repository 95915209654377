const ThreeStars = (props: any) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.12"
      d="M13.75 3L15.4842 7.50886C15.7662 8.24209 15.9072 8.60871 16.1265 8.91709C16.3208 9.1904 16.5596 9.42919 16.8329 9.62353C17.1413 9.8428 17.5079 9.98381 18.2411 10.2658L22.75 12L18.2411 13.7342C17.5079 14.0162 17.1413 14.1572 16.8329 14.3765C16.5596 14.5708 16.3208 14.8096 16.1265 15.0829C15.9072 15.3913 15.7662 15.7579 15.4842 16.4911L13.75 21L12.0158 16.4911C11.7338 15.7579 11.5928 15.3913 11.3735 15.0829C11.1792 14.8096 10.9404 14.5708 10.6671 14.3765C10.3587 14.1572 9.99209 14.0162 9.25886 13.7342L4.75 12L9.25886 10.2658C9.99209 9.98381 10.3587 9.8428 10.6671 9.62353C10.9404 9.42919 11.1792 9.1904 11.3735 8.91709C11.5928 8.60871 11.7338 8.24209 12.0158 7.50886L13.75 3Z"
      fill="currentColor"
    />
    <path
      d="M5.25 22V17M5.25 7V2M2.75 4.5H7.75M2.75 19.5H7.75M13.75 3L12.0158 7.50886C11.7338 8.24209 11.5928 8.60871 11.3735 8.91709C11.1792 9.1904 10.9404 9.42919 10.6671 9.62353C10.3587 9.84281 9.99209 9.98381 9.25886 10.2658L4.75 12L9.25886 13.7342C9.99209 14.0162 10.3587 14.1572 10.6671 14.3765C10.9404 14.5708 11.1792 14.8096 11.3735 15.0829C11.5928 15.3913 11.7338 15.7579 12.0158 16.4911L13.75 21L15.4842 16.4911C15.7662 15.7579 15.9072 15.3913 16.1265 15.0829C16.3208 14.8096 16.5596 14.5708 16.8329 14.3765C17.1413 14.1572 17.5079 14.0162 18.2411 13.7342L22.75 12L18.2411 10.2658C17.5079 9.98381 17.1413 9.8428 16.8329 9.62353C16.5596 9.42919 16.3208 9.1904 16.1265 8.91709C15.9072 8.60871 15.7662 8.24209 15.4842 7.50886L13.75 3Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ThreeStars;
