import { FC } from 'react';
import classnames from 'classnames';

export interface Props {
  strokeWidth?: number;
  sizeClassName?: string;
  className?: string;
}
const Spinner: FC<Props> = ({
  sizeClassName = 'w-6 h-6',
  className = '',
  strokeWidth = 7,
}) => (
  <div className={classnames('relative spinner', sizeClassName, className)}>
    <svg className="background" viewBox="0 0 50 50">
      <circle
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth={strokeWidth}
      ></circle>
    </svg>
    <svg className="foreground" viewBox="0 0 50 50">
      <circle
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth={strokeWidth}
      ></circle>
    </svg>
  </div>
);

export default Spinner;
