const PaperPlane = (props: any) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0717 15.2189L12.7062 20.1698L18.1725 5.84302L3.8316 11.2747L8.76819 13.9312L11.5603 11.1504C11.9159 10.7948 12.4934 10.7948 12.8499 11.1504C13.0277 11.3291 13.1171 11.5621 13.1171 11.7961C13.1171 12.0291 13.0277 12.2631 12.8499 12.4408L10.0717 15.2189ZM12.7108 22C12.031 22 11.4212 21.6352 11.0969 21.0282L8.4596 16.0902C8.39235 15.9631 8.34077 15.8305 8.30484 15.696C8.16943 15.6601 8.0377 15.6076 7.90965 15.5403L2.97122 12.9032C2.32824 12.5597 1.957 11.8946 2.00398 11.1679C2.05004 10.4411 2.50234 9.82861 3.18309 9.56978L17.5102 4.12425C18.1946 3.86081 18.9417 4.02385 19.4594 4.54151C19.9771 5.05916 20.1374 5.80617 19.8776 6.49055L14.4307 20.8164C14.1718 21.498 13.5592 21.9503 12.8324 21.9954C12.7919 21.9982 12.7514 22 12.7108 22Z"
      fill="currentColor"
    />
  </svg>
);
export default PaperPlane;
