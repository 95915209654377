import { FC, FormEvent, useEffect, useState } from 'react';
import classnames from 'classnames';
import AlertIcon from 'containers/Login/icons/AlertIcon';
import Info from 'components/icons/outline/Info';
import Dropdown from 'components/Form/Dropdown';
import ReactTooltip from 'react-tooltip';
import { cn } from 'components/utils';
interface Props {
  onSubmit?: (event: FormEvent) => void;
  className?: string;
}

type FieldProps = Partial<{
  // Hatch to allow any string
  type:
    | 'text'
    | 'select'
    | 'email'
    | 'textarea'
    | 'password'
    | 'number'
    | (string & {});
  disabled: boolean;
  options: any[];
  name: string;
  autoFocus: boolean;
  autoComplete: string;
  placeholder: string;
  defaultValue: string | number | readonly string[];
  required: boolean;
  label: string;
  register: any;
  readOnly: boolean;
  isDirty: boolean;
  isLoading: boolean;
  errors: any;
  className: string;
  onBlur: any;
  info: string;
  optional: string;
  maxLength: number;
  classNames: Partial<{
    root: string;
    field: string;
    label: string;
  }>;
  [key: string]: any;
}>;

interface Nested {
  Dropdown: FC<any>;
  Group: FC<any>;
  Field: FC<FieldProps>;
  Date: FC<any>;
  Time: FC<any>;
}

const Form: FC<Props> & Nested = ({ onSubmit, children, className }) => {
  return (
    <form className={className} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

const Field: FC<FieldProps> = ({
  type = 'text',
  disabled,
  options,
  name,
  autoFocus,
  autoComplete,
  placeholder = '',
  defaultValue,
  required,
  label,
  register,
  readOnly,
  isDirty,
  isLoading,
  errors,
  className,
  classNames,
  onBlur,
  info,
  optional,
  maxLength,
}) => {
  useEffect(() => {
    info && ReactTooltip.rebuild();
  }, [info]);
  return (
    <div
      className={cn(
        'w-full relative mb-2 font-normal',
        { 'opacity-50': disabled },
        className,
        classNames?.root
      )}
    >
      <Label
        readOnly={readOnly}
        hidden={!isDirty && !defaultValue}
        className={classNames?.label ?? ''}
      >
        {`${label} ${required ? '*' : ''}`}
      </Label>
      {isLoading ? (
        <Skeleton />
      ) : (
        {
          select: (
            <Select
              options={options}
              name={name}
              defaultValue={defaultValue}
              register={register}
              readOnly={readOnly}
              disabled={disabled}
              isDirty={isDirty}
              errors={errors}
              className={classNames?.field}
            />
          ),
          email: (
            <Text
              type="email"
              disabled={disabled}
              register={register}
              name={name}
              required={required}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              placeholder={placeholder}
              defaultValue={defaultValue}
              readOnly={readOnly}
              onBlur={onBlur}
              isDirty={isDirty}
              errors={errors}
              className={classNames?.field}
            />
          ),
          password: (
            <Text
              type="password"
              disabled={disabled}
              register={register}
              required={required}
              name={name}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              placeholder={placeholder}
              defaultValue={defaultValue}
              readOnly={readOnly}
              onBlur={onBlur}
              isDirty={isDirty}
              errors={errors}
              className={classNames?.field}
            />
          ),
          text: (
            <Text
              type="text"
              disabled={disabled}
              register={register}
              required={required}
              name={name}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              placeholder={placeholder}
              defaultValue={defaultValue}
              readOnly={readOnly}
              onBlur={onBlur}
              isDirty={isDirty}
              errors={errors}
              className={classNames?.field}
            />
          ),
          number: (
            <Text
              type="number"
              disabled={disabled}
              register={register}
              required={required}
              name={name}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              placeholder={placeholder}
              defaultValue={defaultValue}
              readOnly={readOnly}
              onBlur={onBlur}
              isDirty={isDirty}
              errors={errors}
              className={classNames?.field}
            />
          ),
          tel: (
            <Text
              type="tel"
              disabled={disabled}
              register={register}
              required={required}
              name={name}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              placeholder={placeholder}
              defaultValue={defaultValue}
              readOnly={readOnly}
              onBlur={onBlur}
              isDirty={isDirty}
              errors={errors}
              className={classNames?.field}
            />
          ),
          textarea: (
            <TextArea
              register={register}
              disabled={disabled}
              name={name}
              required={required}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              placeholder={placeholder}
              defaultValue={defaultValue}
              readOnly={readOnly}
              onBlur={onBlur}
              isDirty={isDirty}
              errors={errors}
              optional={optional}
              maxLength={maxLength}
              className={classNames?.field}
            />
          ),
        }[
          type as
            | 'text'
            | 'select'
            | 'email'
            | 'textarea'
            | 'password'
            | 'number'
        ]
      )}
      {!errors && info && (
        <Info
          data-tip={info}
          className="absolute w-5 h-5 cursor-pointer right-4 top-4 text-gray-medium"
        />
      )}
      {errors && <Error>{errors.message}</Error>}
    </div>
  );
};
const Select: FC<any> = ({
  options,
  name,
  defaultValue,
  register,
  readOnly,
  isDirty,
  isLoading,
  errors,
  className,
}) =>
  isLoading ? (
    <div
      className={cn(
        'flex items-center w-full px-4 pt-4 mb-6 border-none rounded-lg text-gray-medium min-h-input focus:ring-2 bg-background focus:outline-none',
        className
      )}
    >
      Loading
    </div>
  ) : (
    <select
      name={name}
      disabled={readOnly}
      defaultValue={defaultValue}
      ref={register}
      className={cn(
        'w-full px-3 mb-2 sm:mb-6 bg-background rounded-lg focus:outline-none',
        {
          'focus:ring-2 focus:ring-offset-primary-dark': !errors && !readOnly,
          'text-gray-medium': readOnly,
          'border border-negative': !!errors,
          'pt-4': isDirty || defaultValue,
        },
        className
      )}
    >
      <option className="hidden" value="" />
      {options?.map((option: any) => (
        <option key={option.key ?? option} value={option.key ?? option}>
          {option.name ?? option}
        </option>
      ))}
    </select>
  );

const Text: FC<any> = ({
  type,
  register,
  required,
  name,
  autoFocus,
  autoComplete,
  placeholder,
  defaultValue,
  readOnly,
  isDirty,
  value,
  onBlur,
  errors,
  className,
}) => (
  <input
    tabIndex={readOnly ? -1 : 0}
    name={name}
    type={type}
    readOnly={readOnly}
    defaultValue={defaultValue}
    autoFocus={autoFocus}
    placeholder={`${placeholder}${required ? '*' : ''}`}
    autoComplete={autoComplete}
    onBlur={onBlur}
    ref={register}
    className={cn(
      'w-full px-4 mb-2 sm:mb-6 bg-background rounded-lg focus:outline-none',
      {
        error: !!errors,
        'text-gray-medium opacity-70': readOnly,
        'border border-negative': !!errors,
        'rounded-l-none': type === 'tel',
        'pt-4': isDirty || defaultValue,
      },
      className
    )}
  />
);

const TextArea: FC<any> = ({
  register,
  name,
  autoFocus,
  autoComplete,
  placeholder,
  defaultValue,
  readOnly,
  isDirty,
  onBlur,
  required,
  errors,
  optional,
  maxLength,
  className,
}) => {
  const [charCounter, setCharCounter] = useState(0);
  const countChar = () => {
    const count = (document.getElementById('textarea') as HTMLInputElement)
      ?.value.length;
    setCharCounter(count);
  };
  useEffect(() => {
    countChar();
  }, [register]);

  return (
    <div>
      <textarea
        name={name}
        readOnly={readOnly}
        defaultValue={defaultValue}
        autoFocus={autoFocus}
        placeholder={`${placeholder}${required ? '*' : ''}`}
        autoComplete={autoComplete}
        onBlur={onBlur}
        ref={register}
        rows={5}
        className={cn(
          'w-full px-4 pt-2 bg-background rounded-lg focus:outline-none',
          {
            'focus:outline-none focus-visible:ring-1 focus-visible:ring-primary':
              !errors,
            'text-gray-medium opacity-70': readOnly,
            'border border-negative': !!errors,
            'pt-6': isDirty || defaultValue,
            'mb-0': optional,
            'mb-2 sm:mb-6': !optional,
          },
          className
        )}
        maxLength={maxLength}
        onKeyUp={() => countChar()}
        id="textarea"
      />
      {optional && (
        <div className="flex justify-end text-gray-medium text-sm">
          {optional}
        </div>
      )}
      {maxLength && (
        <span className="flex h-full justify-end items-end text-gray-medium -translate-y-14 mr-2">{`${charCounter} / ${maxLength.toLocaleString()}`}</span>
      )}
    </div>
  );
};

const Error: FC = ({ children }) => (
  <>
    <small className="absolute bottom-0 right-0 text-negative">
      {children}
    </small>
    <AlertIcon className="absolute right-4 top-4" />
  </>
);

const Skeleton: FC = () => (
  <div className="w-full mb-2 rounded-lg h-14 bg-background sm:mb-6 animate-pulse" />
);

export const Label: FC<{
  hidden?: boolean;
  readOnly?: boolean;
  className?: string;
}> = ({ children, hidden, readOnly = false, className }) => (
  <label
    className={cn(
      'absolute left-4 top-2 text-sm z-10',
      readOnly ? 'text-gray-medium' : 'text-primary',
      {
        hidden,
      },
      className
    )}
  >
    {children}
  </label>
);

const Group: FC<any> = ({ children }) => (
  <div className="flex w-full gap-4">{children}</div>
);

const Date: FC<any> = ({
  label,
  max,
  min,
  name,
  defaultValue,
  register,
  readOnly,
  errors,
}) => (
  <div className={classnames('w-full relative mb-2 font-normal')}>
    <Label>{label}</Label>
    <input
      name={name}
      defaultValue={defaultValue}
      ref={register}
      type="date"
      max={max}
      min={min}
      className={classnames(
        'w-full pt-4 pl-4 pr-10 mb-2 sm:mb-6 bg-background rounded-lg focus:outline-none',
        {
          'focus:ring-2 focus:ring-offset-primary-dark': !errors && !readOnly,
          'text-gray-medium opacity-70': readOnly,
          'border border-negative': !!errors,
        }
      )}
    />
    {errors && <Error>{errors.message}</Error>}
  </div>
);

const Time: FC<any> = ({
  label,
  name,
  max,
  min,
  defaultValue,
  register,
  readOnly,
  errors,
}) => (
  <div className={classnames('w-full relative mb-2 font-normal')}>
    <Label>{label}</Label>
    <input
      name={name}
      defaultValue={defaultValue}
      max={max}
      min={min}
      ref={register}
      type="time"
      className={classnames(
        'w-full pt-4 pl-4 pr-10 mb-2 sm:mb-6 bg-background rounded-lg focus:outline-none',
        {
          'focus:ring-2 focus:ring-offset-primary-dark': !errors && !readOnly,
          'text-gray-medium opacity-70': readOnly,
          'border border-negative': !!errors,
        }
      )}
    />
    {errors && <Error>{errors.message}</Error>}
  </div>
);

Form.Dropdown = Dropdown;
Form.Date = Date;
Form.Time = Time;
Form.Group = Group;
Form.Field = Field;

export default Form;
