const Caret = (props: any) => (
  <svg
    viewBox="0 0 10 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.200059 0.400059C0.53143 -0.0417689 1.15823 -0.131312 1.60006 0.200059L5.00006 2.75006L8.40006 0.200059C8.84189 -0.131312 9.46869 -0.0417689 9.80006 0.400059C10.1314 0.841887 10.0419 1.46869 9.60006 1.80006L5.60006 4.80006C5.2445 5.06673 4.75561 5.06673 4.40006 4.80006L0.400059 1.80006C-0.0417689 1.46869 -0.131312 0.841887 0.200059 0.400059Z"
      fill="currentColor"
    />
  </svg>
);

export default Caret;
