const RefreshTwoArrows = (props: any) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.1916 7.33334C14.0285 6.16015 13.4843 5.07311 12.6427 4.23967C11.801 3.40623 10.7087 2.87262 9.53402 2.72104C8.35929 2.56947 7.16732 2.80833 6.1417 3.40084C5.11609 3.99335 4.31374 4.90663 3.85824 6.00001M3.5249 3.33334V6.00001H6.19157M3.5249 8.66667C3.68794 9.83986 4.23219 10.9269 5.07381 11.7603C5.91543 12.5938 7.00773 13.1274 8.18246 13.279C9.35718 13.4306 10.5492 13.1917 11.5748 12.5992C12.6004 12.0067 13.4027 11.0934 13.8582 10M14.1916 12.6667V10H11.5249"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RefreshTwoArrows;
