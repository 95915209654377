import { usePortal } from 'containers/Shared/components/Portal';
import CreateAppointmentForm from 'containers/Appointments/CreateAppointmentForm';
import CreatePaymentForm from 'containers/Appointments/CreatePaymentForm';
import PatientUnavailableModal from 'containers/Shared/components/Patient/PatientUnavailableModal';

type Props = {
  hasCurrentAppointment?: boolean;
  isAllowed?: boolean;
  roomId?: any;
  user?: any;
};

type PaymentProps = {
  roomId?: any;
  user?: any;
  cannotBeCharged?: boolean;
};

export const useAppointmentDrawer = () => {
  const portal = usePortal();

  const openAppointment = ({
    hasCurrentAppointment,
    isAllowed = true,
    roomId,
    user,
  }: Props = {}) => {
    if (hasCurrentAppointment || !isAllowed) {
      portal.open(<PatientUnavailableModal show onClose={portal.close} />);
      return;
    }

    portal.open(
      <CreateAppointmentForm
        roomId={roomId}
        user={user}
        onClose={() => portal.close('appointment')}
      />,
      'appointment'
    );
  };

  const openPayment = ({
    roomId,
    user,
    cannotBeCharged = false,
  }: PaymentProps = {}) => {
    if (cannotBeCharged) {
      // portal.open(<PatientUnavailableModal show onClose={portal.close} />);
      return;
    }

    portal.open(
      <CreatePaymentForm
        roomId={roomId}
        user={user}
        onClose={() => portal.close('payment')}
      />,
      'payment'
    );
  };

  return { openAppointment, openPayment };
};
