import ConsultationsApiClient from 'legacy/Services/Consultations';

const NOTE_CREATED = 'PATIENT_BAR/NOTE_CREATED';
const NOTE_DELETED = 'PATIENT_BAR/NOTE_DELETED';

const IMC_CREATED = 'PATIENT_BAR/IMC_CREATED';
const IMC_DELETED = 'PATIENT_BAR/IMC_DELETED';

const ALLERGY_CREATED = 'PATIENT_BAR/ALLERGY_CREATED';
const ALLERGY_DELETED = 'PATIENT_BAR/ALLERGY_DELETED';
const UNKNOWN_ALLERGY_CREATED = 'PATIENT_BAR/UNKNOWN_ALLERGY_CREATED';
const UNKNOWN_ALLERGY_DELETED = 'PATIENT_BAR/UNKNOWN_ALLERGY_DELETED';

const DISEASE_CREATED = 'PATIENT_BAR/DISEASE_CREATED';
const DISEASE_DELETED = 'PATIENT_BAR/DISEASE_DELETED';
const UNKNOWN_DISEASE_CREATED = 'PATIENT_BAR/UNKNOWN_DISEASE_CREATED';
const UNKNOWN_DISEASE_DELETED = 'PATIENT_BAR/UNKNOWN_DISEASE_DELETED';

const MEDICATION_CREATED = 'PATIENT_BAR/MEDICATION_CREATED';
const MEDICATION_DELETED = 'PATIENT_BAR/MEDICATION_DELETED';
const UNKNOWN_MEDICATION_CREATED = 'PATIENT_BAR/UNKNOWN_MEDICATION_CREATED';

const CLINICAL_COURSE_DELETED = 'PATIENT_BAR/CLINICAL_COURSE_DELETED';
const CLINICAL_COURSE_CREATED = 'PATIENT_BAR/CLINICAL_COURSE_CREATED';

const UNKNOWN_MEDICATION_DELETED = 'PATIENT_BAR/UNKNOWN_MEDICATION_DELETED';
const TRACKING_DELETED = 'PATIENT_BAR/TRACKING_DELETED';

const DIAGNOSTIC_TESTS_FETCHED = 'PATIENT_BAR/DIAGNOSTIC_TESTS_FETCHED';

const SCREEN_CHANGED = 'PATIENT_BAR/SCREEN_CHANGED';

export const actionTypes = {
  ALLERGY_CREATED,
  ALLERGY_DELETED,
  UNKNOWN_ALLERGY_CREATED,
  UNKNOWN_ALLERGY_DELETED,

  DISEASE_CREATED,
  DISEASE_DELETED,
  UNKNOWN_DISEASE_CREATED,
  UNKNOWN_DISEASE_DELETED,

  MEDICATION_CREATED,
  MEDICATION_DELETED,
  UNKNOWN_MEDICATION_CREATED,
  UNKNOWN_MEDICATION_DELETED,

  NOTE_CREATED,
  NOTE_DELETED,

  IMC_CREATED,
  IMC_DELETED,

  CLINICAL_COURSE_CREATED,
  CLINICAL_COURSE_DELETED,

  TRACKING_DELETED,

  DIAGNOSTIC_TESTS_FETCHED,

  SCREEN_CHANGED,
};

export const setEmptyRecord = (hash) => {
  return (dispatch) => {
    return ConsultationsApiClient.unknownMedicalHistory
      .setEmptyRecord(hash)
      .then(() => {
        dispatch(unknownAllergyCreated());
        dispatch(unknownMedicationCreated());
        dispatch(unknownDiseaseCreated());
      });
  };
};

export const noteCreated = () => ({
  type: NOTE_CREATED,
});

export const noteDeleted = () => ({
  type: NOTE_DELETED,
});

export const imcCreated = () => ({
  type: IMC_CREATED,
});

export const imcDeleted = () => ({
  type: IMC_DELETED,
});

export const allergyCreated = () => ({
  type: ALLERGY_CREATED,
});

export const allergyDeleted = () => ({
  type: ALLERGY_DELETED,
});

export const unknownAllergyCreated = () => ({
  type: UNKNOWN_ALLERGY_CREATED,
});

export const unknownAllergyDeleted = () => ({
  type: UNKNOWN_ALLERGY_DELETED,
});

export const diseaseCreated = () => ({
  type: DISEASE_CREATED,
});

export const clinicalCourseCreated = () => ({
  type: CLINICAL_COURSE_CREATED,
});

export const clinicalCourseDeleted = () => ({
  type: CLINICAL_COURSE_DELETED,
});

export const diseaseDeleted = () => ({
  type: DISEASE_DELETED,
});

export const unknownDiseaseCreated = () => ({
  type: UNKNOWN_DISEASE_CREATED,
});

export const unknownDiseaseDeleted = () => ({
  type: UNKNOWN_DISEASE_DELETED,
});

export const medicationCreated = () => ({
  type: MEDICATION_CREATED,
});

export const medicationDeleted = () => ({
  type: MEDICATION_DELETED,
});

export const unknownMedicationCreated = () => ({
  type: UNKNOWN_MEDICATION_CREATED,
});

export const unknownMedicationDeleted = () => ({
  type: UNKNOWN_MEDICATION_DELETED,
});

export const trackingDeleted = (roomId) => ({
  type: TRACKING_DELETED,
  payload: roomId,
});

export const diagnosticTestsFetched = (count) => ({
  type: DIAGNOSTIC_TESTS_FETCHED,
  payload: count,
});

export const changeScreen = (screen) => ({
  type: SCREEN_CHANGED,
  payload: screen,
});
