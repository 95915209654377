const ProfilePlus = (props: any) => {
  return (
    <svg fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.994 2.639H8c1.626 0 2.934 1.283 2.934 2.857 0 1.532-1.24 2.788-2.807 2.854h-.005l-.116.002H8c-1.625 0-2.933-1.283-2.933-2.857 0-1.532 1.24-2.788 2.806-2.854h.006l.115-.002zm.012 1.03l-.092.002c-.987.044-1.78.84-1.78 1.825 0 1.012.837 1.823 1.86 1.826l.093-.002c.986-.044 1.78-.84 1.78-1.825 0-1.012-.837-1.823-1.861-1.826zM8 8.727c1.655 0 2.994.31 3.928 1.015.96.723 1.405 1.797 1.405 3.122a1.12 1.12 0 01-1.016 1.102l-.014.002-.089.004H3.8a1.122 1.122 0 01-1.133-1.108c0-1.3.43-2.36 1.355-3.084.904-.707 2.2-1.03 3.8-1.052h.005L8 8.727zm.002 1.03l-.166.001c-1.485.021-2.503.323-3.143.823-.617.482-.96 1.213-.96 2.283 0 .024.01.044.025.059a.06.06 0 00.042.019h8.386l.026-.002a.066.066 0 00.033-.02.081.081 0 00.022-.056c0-1.09-.354-1.827-.995-2.31-.666-.503-1.726-.797-3.27-.797zM13.882 1.215a.55.55 0 00-.548-.548.55.55 0 00-.549.548v.903h-.903a.55.55 0 00-.548.549.55.55 0 00.548.548h.903v.903a.55.55 0 00.549.549.55.55 0 00.548-.549v-.903h.903a.55.55 0 00.549-.548.55.55 0 00-.549-.549h-.903v-.903z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ProfilePlus;
