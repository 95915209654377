import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import api from 'api';
import {
  getRoomsList,
  getSummaryRoomsList,
} from 'containers/Inbox/actions/GetRoomList.actions';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InvitationSent from 'containers/Invitations/Vithas/InvitationSent';
import InviteForm, {
  Fields as InviteFields,
  SearchError,
} from 'containers/Invitations/Vithas/InviteForm';
import SearchForm, {
  Fields as SearchFields,
} from 'containers/Invitations/Vithas/SearchForm';
import { usePortal } from 'containers/Shared/components/Portal';
import InvitationError from 'containers/Invitations/Vithas/InvitationError';
import { Room } from 'api/invitations.d';

export interface Props {
  onClose: () => void;
}

const CreateContact: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const portal = usePortal();
  const [roomInvited, setRoomInvited] = useState<Room | null>(null);
  const [formType, setFormType] = useState<'simple' | 'extended'>('simple');
  const [contactDetails, setContactDetails] = useState<InviteFields>();
  const dispatch = useDispatch();
  const history = useHistory();

  const searchInvitation = (fields: SearchFields) =>
    api.invitations.vithas
      .advancedSearch(fields)
      .then(({ data: { data: room } }) => setRoomInvited(room))
      .catch(showInvitationError);

  const viewChat = () => {
    handleOnClose();
    history.push(`/chat/${roomInvited?.room_id}`);
  };

  const handleOnClose = () => {
    dispatch(getRoomsList());
    dispatch(getSummaryRoomsList());
    onClose && onClose();
  };

  const handleOnSearchError = (error: InviteFields & SearchError) => {
    if (error.type === 'patient_with_duplicated_nhc') {
      showInvitationError();
    } else {
      setContactDetails({ nhc: error.nhc, center_code: error.center_code });
      setFormType('extended');
    }
  };

  const showInvitationError = () => {
    onClose();
    portal.open(<InvitationError onClose={portal.close} />);
  };

  return (
    <Modal onClose={handleOnClose} width="narrow">
      <Modal.Header onClose={handleOnClose}>
        {t('invite__vithas_contact_form_title')}
      </Modal.Header>
      {!roomInvited ? (
        {
          simple: (
            <InviteForm
              onSearchError={handleOnSearchError}
              onInvited={setRoomInvited}
            />
          ),
          extended: (
            <SearchForm
              onSubmit={searchInvitation}
              contactDetails={contactDetails}
            />
          ),
        }[formType]
      ) : (
        <InvitationSent name={roomInvited.title} onConfirm={viewChat} />
      )}
    </Modal>
  );
};

export default CreateContact;
