import Button from 'components/Button';
import Warning from 'components/icons/Warning';
import Modal from 'components/Modal';
import { FC } from 'react';

export interface Props {
  onClose: (value?: unknown) => void;
  title: string;
  text: string;
  button?: string;
  icon?: JSX.Element;
}

const WarningModal: FC<Props> = ({
  onClose,
  title,
  text,
  button,
  icon = <Warning />,
  children,
}) => {
  return (
    <Modal onClose={onClose} rounded="large" width="narrow">
      {({ onClose }: { onClose: () => void }) => (
        <Modal.Body type="full">
          <div className="flex flex-col items-center text-center">
            <div className="flex items-center justify-center w-20 h-20 mb-8 rounded-lg">
              {icon}
            </div>
            <div className="mb-6 text-2xl font-medium text-primary">
              {title}
            </div>
            <div className="mb-12">{text}</div>
            {!!button && <Button onClick={onClose}>{button}</Button>}
            {typeof children === 'function' ? children({ onClose }) : children}
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default WarningModal;
