import Tracker from 'utils/Tracking';
import CRMTracker from 'utils/CRMTracker';
import GoogleTagManager from 'utils/GoogleTagManager';
import { socket } from 'socket';

export const MESSAGE_SENT = 'ROOM/MESSAGE_SENT';

export const sendMessage = (message, room) => {
  return (dispatch, getState) => {
    if (socket.disconnected) {
      alert(
        'Message not sent due to a problem in the service, please try again in a few minutes'
      );
      return;
    }

    switch (message.type) {
      case 'file':
      case 'image':
        socket.emit(
          'message',
          message.base64,
          message.roomId,
          message.messageId,
          message.type,
          message.attachment.name,
          0,
          'ordinary',
          message.attachment.audioType?.includes('voice_note') &&
            message.attachment.audioType
        );
        message = {
          ...message,
          attachment: undefined,
          string: JSON.stringify({
            fileName: message.attachment.name,
            fileSize: message.attachment.size,
            fileType:
              message.attachment.audioType?.includes('voice_note') &&
              message.attachment.audioType,
          }),
          url: URL.createObjectURL(message.attachment),
        };
        break;
      default:
        socket.emit(
          'message',
          message.string,
          message.roomId,
          message.messageId,
          message.type
        );
        break;
    }

    Tracker.event('chat message sent', {
      event_room_id: message.room_id,
      event_message_patient_hash: room?.meta?.hash,
      event_message_content: message.string,
    });

    if (room?.type === 'patient') {
      GoogleTagManager.conversion('QqIHCM3ixb8CEPKinbUC');
    }

    const profile = getState()?.session?.profile;
    CRMTracker.event('MessageSent', {
      id_speciality: profile?.speciality_id,
      hash_professional: profile?.hash,
    });

    // dispatch(messageSent({ ...message, time: moment().valueOf() }));
  };
};

export const messageSent = (message) => {
  return {
    type: MESSAGE_SENT,
    payload: message,
  };
};
