import AccordeonHandler from 'containers/Shared/components/Accordeon/AccordeonHandler';
import {
  AccordeonButton,
  AccordeonBody,
  AccordeonHeader,
} from 'containers/Shared/components/Accordeon/AccordeonPanel';
import PrescriptionItem from 'containers/DetailBar/PatientBar/components/Prescriptions/PrescriptionItem';
import { useDispatch, useSelector } from 'react-redux';
import { download } from 'containers/Prescription/actions/Prescription.actions';
import { sortedPrescriptions } from 'containers/Prescription/reducers/prescription.reducer';
import { useTranslation } from 'react-i18next';
import Info from 'components/icons/outline/Info';
import classNames from 'classnames';
import { LinkItUrl } from 'react-linkify-it';
import { usePrescriptionDrawer } from 'containers/Prescription/index';
import { usePortal } from 'containers/Shared/components/Portal';
import StartConsultationModal from 'containers/Consultations/StartConsultationModal';
import api from 'api';
import { chatConsultationStarted } from 'containers/Room/actions/JoinRoom.actions';

const PrescriptionsPanel = ({
  open,
  room,
  onOpen,
  onClose,
  badge,
  consultationType,
  consultationId,
}) => {
  const { t } = useTranslation();

  if (!open) {
    return (
      <AccordeonHandler onOpen={onOpen} badge={badge}>
        {t('sidebar__patient_prescriptions_title')}
      </AccordeonHandler>
    );
  }

  return (
    <PrescriptionContainer
      room={room}
      onClose={onClose}
      badge={badge}
      consultationType={consultationType}
      consultationId={consultationId}
    />
  );
};

const PrescriptionContainer = ({
  room,
  onClose,
  badge,
  consultationType,
  consultationId,
}) => {
  const { openPrescriptionDrawer } = usePrescriptionDrawer();
  const { profile } = useSelector((state) => state.session);
  const prescriptions = useSelector(sortedPrescriptions);
  const canCreatePrescriptions =
    useSelector(
      (state) =>
        state.session.profile.features?.prescriptions?.can_create_prescriptions
    ) && profile.prescription_validation_status === 'validated';
  const current = useSelector((state) => state.console?.current);
  const { patient } = useSelector((state) => state.patientBar);
  const { t } = useTranslation();
  const portal = usePortal();
  const dispatch = useDispatch();

  const handleStartConsultation = () => {
    api.chat
      .start({ consultation_id: current?.current_chat_consultation?.id })
      .then(
        dispatch(
          chatConsultationStarted(current?.current_chat_consultation?.id)
        )
      );
  };

  const open = () => {
    current?.current_chat_consultation?.status === 'pending'
      ? portal.open(
          <StartConsultationModal
            title={t('chat__consultation_start_prescription_modal_title')}
            description={t(
              'chat__consultation_start_prescription_modal_description'
            )}
            onClose={() => portal.close('start_consultation')}
            consultation={current?.current_chat_consultation}
            costumerName={current?.customer?.name}
            onConfirm={() => {
              handleStartConsultation();
              portal.close('start_consultation');
            }}
          />,
          'start_consultation'
        )
      : openPrescriptionDrawer({ room, consultationType, consultationId });
  };

  return (
    <div>
      <div className="p-4">
        <AccordeonHeader onClose={onClose} badge={badge}>
          {t('sidebar__patient_prescriptions_title')}
        </AccordeonHeader>

        {profile.prescription_validation_status === 'pending' && (
          <Alert type="warning">
            {t('sidebar__patient_prescriptions_pending_alert')}
          </Alert>
        )}

        {profile.prescription_validation_status === 'failed' && (
          <Alert type="error">
            <LinkItUrl className="hover:underline text-blue">
              {t('sidebar__patient_prescriptions_failed_alert')}
            </LinkItUrl>
          </Alert>
        )}

        {profile.prescription_validation_status === 'deprecated' && (
          <>
            <AccordeonBody show={true}>
              {t('sidebar__patient_prescriptions_reactivate_text')}
            </AccordeonBody>
            <AccordeonButton onClick={open}>
              {t('sidebar__patient_prescriptions_reactivate')}
            </AccordeonButton>
          </>
        )}

        {!profile.prescription_validation_status &&
        //patch fix for asisa
        (patient.organizations.includes('SDK-asisa') ||
          patient.organizations.includes('SDK-asisa sandbox')) ? (
          <AccordeonButton onClick={open}>
            {t('sidebar__patient_prescriptions_add')}
          </AccordeonButton>
        ) : (
          //end patch
          !profile.prescription_validation_status && (
            <>
              <AccordeonBody show={true}>
                {t('sidebar__patient_prescriptions_activate_text')}
              </AccordeonBody>
              <AccordeonButton onClick={open}>
                {t('sidebar__patient_prescriptions_activate')}
              </AccordeonButton>
            </>
          )
        )}

        {canCreatePrescriptions && (
          <AccordeonButton onClick={open}>
            {t('sidebar__patient_prescriptions_add')}
          </AccordeonButton>
        )}
      </div>
      <div className="border-b border-separators">
        {prescriptions.map((prescription) => (
          <PrescriptionItem
            key={prescription.uuid}
            prescription={prescription}
            onDownload={() => {
              download(prescription.uuid);
            }}
          />
        ))}
      </div>
    </div>
  );
};

const Alert = ({ children, type = 'warning' }) => (
  <div
    className={classNames('items-start rounded flex gap-2 p-2', {
      'bg-negative-light': type === 'error',
      'bg-orange-light': type === 'warning',
    })}
  >
    <div
      className={classNames(
        'w-6 h-6 bg-white rounded flex items-center justify-center flex-shrink-0',
        {
          'text-negative': type === 'error',
          'text-orange': type === 'warning',
        }
      )}
    >
      <Info className="w-3 h-3" />
    </div>
    <div className="text-xs">{children}</div>
  </div>
);

export default PrescriptionsPanel;
