import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clinical_courses: [],
};

const slice = createSlice({
  name: 'clinicalCourses',
  initialState,
  reducers: {
    fetchCourses(state, action) {
      state.clinical_courses = action.payload;
    },
    addCourses(state, action) {
      state.clinical_courses.push(action.payload);
    },
    updateCourses(state, action) {
      state.clinical_courses = state.clinical_courses.filter(
        ({ id }) => id !== action.payload.id
      );
      state.clinical_courses.push(action.payload);
    },
    removeCourses(state, action) {
      state.clinical_courses = state.clinical_courses.filter(
        ({ id }) => id !== action.payload.id
      );
    },
  },
});

export const { fetchCourses, addCourses, updateCourses, removeCourses } =
  slice.actions;
export default slice.reducer;
