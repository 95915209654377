import Api from 'api';

const FETCHING_GET_ALL = 'WAITING_ROOM/FETCHING_GET_ALL';
const GET_ALL_FETCHED = 'WAITING_ROOM/GET_ALL_FETCHED';
const IN_CALL = 'IN_CALL';
const NOT_IN_CALL = 'NOT_IN_CALL';
const IS_LOADING = 'IS_LOADING';

export const actionTypes = {
  FETCHING_GET_ALL,
  GET_ALL_FETCHED,
  IN_CALL,
  NOT_IN_CALL,
  IS_LOADING,
};

export const getAllWaitingRoom = (originRequest = 'debug') => {
  return (dispatch) => {
    dispatch(setIsLoading());
    return Api.waitingRoom
      .getAll()
      .then(({ data: { data } }) => {
        data.forEach(waitingRoomItem => {
          waitingRoomItem.originRequest = originRequest
        })

        dispatch(getAllWaitingRoomFetched({data, originRequest}))
      });
  };
};

const getAllWaitingRoomFetched = ({ data, originRequest }) => {
  return {
    type: GET_ALL_FETCHED,
    payload: { data, originRequest }
  };
};

export const setInCall = () => {
  return {
    type: IN_CALL,
  };
};

export const setNotInCall = () => {
  return {
    type: NOT_IN_CALL,
  };
};

export const setIsLoading = () => {
  return {
    type: IS_LOADING,
  };
};
