import axios, { AxiosPromise } from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

export type Notification = {
  created_at: string;
  deeplink_web: '/contacts';
  icon_src: string;
  icon_type: 'info';
  id: string;
  payload?: { name?: string };
  read_at: string | null;
  text: string;
  type: 'patient_invitation_accepted';
  user_hash: string;
};

export const BASE_URL = config.services.mediquo.chat_url;
export const PROFESSIONALS = 'professionals/v1';
const API = 'api/v1';

export const invite = (data: {
  name: string;
  target: 'patient' | 'professional';
  phone?: string;
  email?: string;
  tag?: string;
  billing?: {
    name?: string;
    tax_id?: string;
    address?: string;
    postal_code?: string;
    region?: string;
    country_code?: string;
  };
}) =>
  axios({
    method: 'post',
    url: `${BASE_URL}/${PROFESSIONALS}/contacts/invite`,
    data,
    headers: getHeaders(),
  });

export const sendInvitation = ({ id }: { id: string }) =>
  axios({
    method: 'post',
    url: `${BASE_URL}/${PROFESSIONALS}/contacts/${id}/invitations/send`,
    headers: getHeaders(),
  });

export const INVITATIONS_URL = `${BASE_URL}/${PROFESSIONALS}/contacts/invitations`;
export const getInvitations = (
  { status = 'pending', target }: any = { status: 'pending' }
) =>
  axios({
    method: 'get',
    url: INVITATIONS_URL,
    params: { status, target },
    headers: getHeaders(),
  });

export const NOTIFICATIONS_URL = `${BASE_URL}/${PROFESSIONALS}/notifications`;
export const readNotification = ({
  id,
}: {
  id: string;
}): AxiosPromise<{ data: Notification[] }> =>
  axios({
    method: 'put',
    url: `${NOTIFICATIONS_URL}/${id}/read`,
    headers: getHeaders(),
  });

export const deleteSentence = (id: string) =>
  axios({
    method: 'delete',
    url: `${BASE_URL}/${PROFESSIONALS}/sentences/${id}`,
    headers: getHeaders(),
  });

export const createSentence = (string: string) =>
  axios({
    method: 'post',
    url: `${BASE_URL}/${PROFESSIONALS}/sentences`,
    data: {
      string,
    },
    headers: getHeaders(),
  });

export const editSentence = ({ id, string }: { id: string; string: string }) =>
  axios({
    method: 'put',
    url: `${BASE_URL}/${PROFESSIONALS}/sentences/${id}`,
    data: {
      string,
    },
    headers: getHeaders(),
  });

const PER_PAGE = 60;

export const searchRooms = ({ term, page = 1, perPage = PER_PAGE }: any) =>
  axios({
    method: 'get',
    url: `${BASE_URL}/${PROFESSIONALS}/rooms/search`,
    params: { term, page, per_page: perPage },
    headers: getHeaders(),
  });

export const getRoom = ({ roomId }: { roomId: string }) =>
  axios({
    method: 'get',
    url: `${BASE_URL}/${PROFESSIONALS}/rooms/${roomId}`,
    headers: getHeaders(),
  });

export const getRooms = ({ filter, page = 1, perPage = PER_PAGE }: any) =>
  axios({
    method: 'get',
    url: `${BASE_URL}/${PROFESSIONALS}/rooms`,
    params: { [filter]: true, page, per_page: perPage, with_pinned: true },
    headers: getHeaders(),
  });

export const getMessages = ({ roomId }: { roomId: string }) =>
  axios({
    method: 'get',
    url: `${BASE_URL}/${API}/rooms/${roomId}/messages`,
    headers: getHeaders(),
  });

export const roomsSummary = () =>
  axios({
    method: 'get',
    url: `${BASE_URL}/${PROFESSIONALS}/rooms/summary`,
    headers: getHeaders(),
  });

export const getProfile = () =>
  axios({
    method: 'get',
    url: `${BASE_URL}/${API}/profile`,
    headers: getHeaders(),
  });
