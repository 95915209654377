const CalendarDate = (props: any) => (
  <svg fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      d="M11.692 1.333c.339 0 .616.274.616.608v.608l.111.003A1.692 1.692 0 0114 4.241v7.092A2.667 2.667 0 0111.333 14H4.667A2.667 2.667 0 012 11.333V4.241c0-.935.758-1.692 1.692-1.692V1.94c0-.334.277-.608.616-.608.338 0 .615.274.615.608v.608h6.154V1.94c0-.334.277-.608.615-.608zm1.141 2.908H3.167V11.5c0 .736.597 1.333 1.333 1.333h7c.736 0 1.333-.597 1.333-1.333V4.24z"
    ></path>
    <rect
      width="2.667"
      height="2.667"
      x="8.667"
      y="6"
      fill="currentColor"
      rx="1"
    ></rect>
  </svg>
);

export default CalendarDate;
