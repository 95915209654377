export default (props) => (
  <svg width="48" height="48" fill="none" viewBox="0 0 48 48" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24 0c18.294 0 24 6.582 24 24s-5.706 24-24 24S0 41.418 0 24 5.706 0 24 0z"
      clipRule="evenodd"
      opacity="0.05"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 18a3 3 0 013-3h11a3 3 0 013 3v8a3 3 0 01-3 3h-2.833a1 1 0 110-2H31a1 1 0 001-1v-5.206l-4.922 3.043a3 3 0 01-3.155 0l-5.975-3.693A2 2 0 0117 18.443V18zm15 .442V18a1 1 0 00-1-1H20a1 1 0 00-1 1v.442l5.974 3.694a1 1 0 001.052 0L32 18.442zM23.037 28.061a2.962 2.962 0 00-2.303.725l-.002.001-1.519-1.519a2.963 2.963 0 00.732-2.312l-.176-1.529a2.97 2.97 0 00-2.95-2.627h-1.05c-1.685 0-3.07 1.412-2.963 3.119.376 6.058 5.218 10.9 11.275 11.275 1.707.106 3.119-1.278 3.119-2.964v-1.048c.01-1.115-.653-1.933-1.675-2.564a2.37 2.37 0 00-.681-.287 7.934 7.934 0 00-.937-.167l-.87-.103zm-1.052 2.231c.192-.192.451-.297.722-.295l.113.008.755.086c.505.057.978.178 1.318.48.226.201.327.382.347.54l.004.076v1.043c0 .577-.48 1.047-1.042 1.013a10.1 10.1 0 01-9.445-9.445c-.033-.531.385-.99.92-1.038l.097-.004h1.045c.482 0 .89.335.99.797l.018.103.175 1.523c.031.273-.048.541-.216.75l-.097.104c-.646.65-.68 1.68-.1 2.368l.097.106 1.8 1.8.106.098.006.005c.652.549 1.609.55 2.26 0l.122-.113.005-.005z"
      clipRule="evenodd"
    ></path>
  </svg>
);
