import StepBallons from 'components/images/StepBallons';
import { useTranslation } from 'react-i18next';

const WelcomeMessage = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white rounded-lg flex p-6 pr-9 items-center mx-auto my-6 shadow-sm">
      <StepBallons className="mr-7" />
      <div className="text-dark">
        <h3 className="mb-6 font-medium">{t('room__welcome_message_title')}</h3>
        <ol>
          <ListItem step={1}>{t('room__welcome_message_step_1')}</ListItem>
          <ListItem step={2}>{t('room__welcome_message_step_2')}</ListItem>
          <ListItem step={3}>{t('room__welcome_message_step_3')}</ListItem>
        </ol>
      </div>
    </div>
  );
};

const ListItem = ({ step, children }: any) => (
  <li className="flex items-center mb-2 max-w-[400px] text-sm">
    <div className="h-4 w-4 flex items-center flex-shrink-0 justify-center text-2xs bg-primary text-white rounded-full mr-2">
      {step}
    </div>
    {children}
  </li>
);

export default WelcomeMessage;
