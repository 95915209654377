import { FC } from 'react';
import classnames from 'classnames';
import Plus from 'components/icons/Plus';
import Button from 'components/Button';
import useInvitationModal from 'containers/Invitations/useInvitationModal';
import {
  DropdownHeader,
  DropdownMenu,
  DropdownOption,
  useDropdown,
} from 'containers/Shared/components/Dropdown';
import { useTranslation } from 'react-i18next';
import CalendarDate from 'components/icons/outline/CalendarDate';
import Dollar from 'components/icons/outline/Dollar';
import SuitecasePlus from 'components/icons/outline/SuitecasePlus';
import ProfilePlus from 'components/icons/outline/ProfilePlus';
import { useSelector } from 'react-redux';
import FeatureFlag from 'containers/Shared/components/FeatureFlag';
import {
  canChargeConsultations,
  getCanPrescribe,
} from 'containers/Inbox/reducers/session.reducer';
import FileAdd from 'components/icons/outline/FileAdd';
import { usePrescriptionDrawer } from 'containers/Prescription/index';
import { getProfile } from 'state/profile/index.selectors';
import { useAppointmentDrawer } from 'containers/Appointments/useAppointmentDrawer';
import { usePortal } from 'containers/Shared/components/Portal';
import Confirmation from 'components/Modal/Confirmation';

export interface Props {
  className?: string;
}

const ActionsButton: FC<Props> = ({ className = '' }) => {
  const { t } = useTranslation();
  const invitationModal = useInvitationModal();
  const dropdown = useDropdown();
  const canCharge = useSelector(canChargeConsultations);
  const canPrescribe = useSelector(getCanPrescribe);
  const { openPrescriptionDrawer } = usePrescriptionDrawer();
  const profile = useSelector(getProfile);
  const { openAppointment, openPayment } = useAppointmentDrawer();
  const inCall = useSelector((state: any) => state.waitingRoom.inCall);
  const portal = usePortal();

  const openNavigationDeniedModal = () => {
    portal.open(
      <Confirmation
        title={t('videocall__navigation_modal_title')}
        content={t('videocall__navigation_modal_content')}
        confirmText={t('videocall__navigation_modal_confirm_text')}
        onConfirm={() => {
          portal.close('confirmation');
        }}
      />,
      'confirmation'
    );
  };

  const openInvite = (targetInvite: 'patient' | 'professional') => {
    dropdown.close();
    invitationModal.open({ screen: 'target', target: targetInvite });
  };

  return (
    <div className={classnames('relative', className)}>
      <Button
        size="small"
        className="flex space-x-2 whitespace-nowrap"
        onClick={() => {
          inCall ? openNavigationDeniedModal() : dropdown.open();
        }}
      >
        <Plus />
      </Button>
      <DropdownMenu
        open={dropdown.isOpen}
        onClose={() => dropdown.close()}
        className="right-0 w-56 pt-2 text-sm"
      >
        <DropdownHeader>{t('actions__dropdown_header')}</DropdownHeader>
        <DropdownOption
          onClick={() => openInvite('patient')}
          className="flex items-center gap-2"
        >
          <ProfilePlus className="w-4 h-4 text-primary" />
          <span>{t('actions__dropdown_option_invite_patient')}</span>
        </DropdownOption>
        <DropdownOption
          className="flex items-center gap-2"
          onClick={() => openInvite('professional')}
        >
          <SuitecasePlus className="w-4 h-4 text-primary" />
          <span>{t('actions__dropdown_option_invite_professional')}</span>
        </DropdownOption>
        <DropdownOption
          className="flex items-center gap-2"
          onClick={openAppointment}
        >
          <CalendarDate className="w-4 h-4 text-primary" />
          <span>{t('actions__dropdown_option_new_appointment')}</span>
        </DropdownOption>
        <FeatureFlag enabled={canCharge}>
          <DropdownOption
            disabled={
              profile.workPracticeCountry !== 'es' &&
              profile.kyc_status !== 'success'
            }
            className="flex items-center gap-2"
            onClick={openPayment}
          >
            <Dollar className="w-4 h-4 text-primary" />
            <span>{t('actions__dropdown_option_new_payment')}</span>
          </DropdownOption>
        </FeatureFlag>
        <FeatureFlag enabled={canPrescribe}>
          <DropdownOption
            className="flex items-center gap-2"
            onClick={openPrescriptionDrawer}
          >
            <FileAdd className="w-4 h-4 text-primary" />
            <span>{t('actions__dropdown_option_new_prescription')}</span>
          </DropdownOption>
        </FeatureFlag>
      </DropdownMenu>
    </div>
  );
};

export default ActionsButton;
