import AccordeonHandler from 'containers/Shared/components/Accordeon/AccordeonHandler';
import {
  AccordeonHeader,
  AccordeonBody,
} from 'containers/Shared/components/Accordeon/AccordeonPanel';
import { useTranslation } from 'react-i18next';
import { usePortal } from 'containers/Shared/components/Portal';
import ClinicalCourseModal from 'containers/ClinicalCourse/CreateClinicalCourseModal';
import Trash from 'components/icons/outline/Trash';
import dayjs from 'dayjs';
import Button from 'components/Button';
import useClinicalCourses from 'containers/ClinicalCourse/useClinicalCourses';
import uuid from 'uuid/v4';
import Loading from 'components/Loading';
import ClinicalCoursesModal from 'containers/ClinicalCourse/ClinicalCoursesModal';
import Tracker from 'utils/Tracking';
import { useEffect } from 'react';

const ClinicalCoursesContainer = ({
  badge,
  onClose,
  roomId,
  patient,
  consultationType,
  consultationId,
}) => {
  const { t } = useTranslation();
  const portal = usePortal();
  const {
    isLoading,
    fetch,
    deleteDraft,
    saveDraft,
    create,
    draft,
    current: lastClinicalCourse,
  } = useClinicalCourses({ roomId });

  useEffect(() => {
    fetch();
    return () => {
      portal.close('clinical_course');
    };
  }, []);

  const onSubmit = (data) => {
    create({
      id: draft?.id ?? uuid(),
      ...data,
      room_id: roomId,
      consultation_type: consultationType,
      consultation_id: consultationId,
    });
    setTimeout(() => portal.close('clinical_course'), 200);
  };

  const onSaveDraft = (data) =>
    saveDraft({
      id: draft?.id ?? uuid(),
      ...data,
      room_id: roomId,
      consultation_type: consultationType,
      consultation_id: consultationId,
    });

  const handleOnClose = (data) => {
    if (data.briefing) {
      onSaveDraft(data);
    }

    portal.close('clinical_course');
  };

  const onDeleteDraft = ({ id }) => deleteDraft({ id });

  const openCreateModal = ({ draft }) =>
    portal.open(
      <ClinicalCourseModal
        patient={patient}
        clinicalCourse={draft}
        onClose={handleOnClose}
        onSave={onSubmit}
        onAutoSave={onSaveDraft}
      />,
      'clinical_course'
    );

  return (
    <div>
      <div className="p-4">
        <AccordeonHeader onClose={onClose} badge={badge}>
          {t('sidebar__patient_clinical_courses_title')}
        </AccordeonHeader>
        <AccordeonBody show={!isLoading && !lastClinicalCourse}>
          {t('sidebar__patient_clinical_courses_empty')}
        </AccordeonBody>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {draft && <DraftItem draft={draft} onDelete={onDeleteDraft} />}

            <div className="flex gap-2">
              <Button
                size="small"
                variant="reverse"
                className="w-full"
                onClick={() => openCreateModal({ draft })}
              >
                {draft
                  ? t('sidebar__patient_clinical_courses_panel_continue')
                  : lastClinicalCourse
                  ? t('sidebar__patient_clinical_courses_panel_create')
                  : t('sidebar__patient_clinical_courses_create')}
              </Button>
              {lastClinicalCourse && (
                <Button
                  className="w-full"
                  size="small"
                  variant="reverse"
                  onClick={() => {
                    Tracker.event('clinical courses see more button click', {
                      event_room_id: roomId,
                    });
                    portal.open(
                      <ClinicalCoursesModal
                        roomId={roomId}
                        patient={patient}
                        onClose={() => portal.close('see_more_modal')}
                      />,
                      'see_more_modal'
                    );
                  }}
                >
                  {t('sidebar__patient_clinical_courses_see_more')}
                </Button>
              )}
            </div>

            {lastClinicalCourse && (
              <ClinicalCourseItem clinicalCourse={lastClinicalCourse} />
            )}
          </>
        )}
      </div>
      <div className="border-b border-separators"></div>
    </div>
  );
};

const ClinicalCoursesPanel = ({
  badge,
  open,
  onOpen,
  onClose,
  room,
  consultationType,
  consultationId,
}) => {
  const { t } = useTranslation();

  const patient = {
    name: room.title,
  };

  if (!open) {
    return (
      <AccordeonHandler onOpen={onOpen} badge={badge}>
        {t('sidebar__patient_clinical_courses_title')}
      </AccordeonHandler>
    );
  }

  return (
    <ClinicalCoursesContainer
      badge={badge}
      onClose={onClose}
      roomId={room.room_id}
      patient={patient}
      consultationType={consultationType}
      consultationId={consultationId}
    />
  );
};

const DraftItem = ({ draft, onDelete }) => {
  const { t } = useTranslation();

  return (
    <div className="pb-4 border-b border-separators mb-4">
      <div className="flex items-center mb-2">
        <h3 className="text-negative text-xs font-medium">
          {t('sidebar__patient_clinical_courses_draft')}
        </h3>
        <button
          onClick={() => onDelete({ id: draft.id })}
          className="w-6 h-6 flex items-center ml-auto justify-center rounded hover:bg-gray-light"
        >
          <Trash className="w-4 h-4 text-gray-dark" />
        </button>
      </div>
      <div className="text-gray-dark text-xs">
        {draft.reason && <h4 className="font-medium">{draft.reason}</h4>}
        <p className="mb-2">{draft.briefing}</p>
        <span>{dayjs(draft.created_at).format('DD/MM/YYYY HH:mm')}</span>
      </div>
    </div>
  );
};

const ClinicalCourseItem = ({ clinicalCourse }) => (
  <div className="mb-4 mt-6">
    <div className="mb-2">
      <h3 className="text-dark text-xs font-medium">
        {clinicalCourse?.professional?.name}
      </h3>
      <p className="text-blue text-xs">
        {clinicalCourse?.professional?.speciality}
      </p>
    </div>
    <div className="text-gray-dark text-xs">
      {clinicalCourse.reason && (
        <h4 className="font-medium">{clinicalCourse.reason}</h4>
      )}
      <p className="mb-2">{clinicalCourse.briefing}</p>
      <span>{dayjs(clinicalCourse.dated_at).format('DD/MM/YYYY HH:mm')}</span>
    </div>
  </div>
);

export default ClinicalCoursesPanel;
