import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

const API = 'pro/v1/license';
const BASE_URL = config.services.mediquo.api_url;

const organization = {
  centers: {
    get: () =>
      axios({
        method: 'GET',
        url: `${BASE_URL}${API}/organization/centers`,
        headers: getHeaders(),
      }),
  },
};

export default {
  organization,
};
