const CHECK_ROOM = 'INBOX/CHECK_ROOM';
const UNCHECK_ROOM = 'INBOX/UNCHECK_ROOM';
const CHECK_ALL = 'INBOX/CHECK_ALL';
const UNCHECK_ALL = 'INBOX/UNCHECK_ALL';
const CHECK_MULTIPLE_ROOMS = 'INBOX/CHECK_MULTIPLE_ROOMS';

export const actionTypes = {
  CHECK_ROOM,
  UNCHECK_ROOM,
  CHECK_ALL,
  UNCHECK_ALL,
  CHECK_MULTIPLE_ROOMS,
};

export const checkRoom = (id) => {
  return {
    type: CHECK_ROOM,
    payload: id,
  };
};

export const uncheckRoom = (id) => {
  return {
    type: UNCHECK_ROOM,
    payload: id,
  };
};

export const checkAll = (room) => {
  return {
    type: CHECK_ALL,
    payload: room,
  };
};

export const uncheckAll = () => {
  return {
    type: UNCHECK_ALL,
  };
};

export const checkMultipleRooms = (rooms) => {
  return {
    type: CHECK_MULTIPLE_ROOMS,
    payload: rooms,
  };
};
