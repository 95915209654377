const CLEAR_ROOM = 'ROOM/CLEAR_ROOM';

export const actionTypes = { CLEAR_ROOM };

export const clearRoom = (room) => {
  return {
    type: CLEAR_ROOM,
    payload: room,
  };
};
