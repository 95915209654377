import Button from 'components/Button';
import Form from 'components/Form';
import DownloadBox from 'components/icons/outline/DownloadBox';
import Modal from 'components/Modal';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const ClinicalCourseModal = ({
  patient,
  clinicalCourse,
  onClose,
  onSave,
  onAutoSave,
}) => {
  const { t } = useTranslation();
  const [draftSaved] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState: { isValid, isSubmitted, dirtyFields },
  } = useForm({ mode: 'onChange' });
  const formData = watch();

  const onSubmit = (formData) =>
    onSave({ ...formData, wasDraft: !!clinicalCourse });

  return (
    <Modal onClose={() => onClose({ ...formData, isDraft: true })}>
      {({ onClose }) => (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header onClose={onClose}>
            {t('clinical__course_create_modal_title', { name: patient.name })}
          </Modal.Header>
          <Modal.Body>
            <h3 className="text-dark font-medium mb-4 text-sm">
              {t('clinical__course_create_modal_subtitle')}
            </h3>
            <Form.Group>
              <Form.Date
                max={dayjs().format('YYYY-MM-DD')}
                defaultValue={
                  clinicalCourse?.date ?? dayjs().format('YYYY-MM-DD')
                }
                name="date"
                label={t('clinical__course_create_modal_date_label')}
                placeholder={t(
                  'clinical__course_create_modal_date_placeholder'
                )}
                register={register({
                  required: t('clinical__course_create_modal_date_error'),
                })}
                errors={errors.date}
              />
              <Form.Time
                max={
                  formData.date === dayjs().format('YYYY-MM-DD')
                    ? dayjs().format('HH:mm')
                    : null
                }
                defaultValue={clinicalCourse?.time ?? dayjs().format('HH:mm')}
                name="time"
                label={t('clinical__course_create_modal_time_label')}
                placeholder={t(
                  'clinical__course_create_modal_time_placeholder'
                )}
                register={register({
                  required: t('clinical__course_create_modal_time_error'),
                })}
                errors={errors.time}
              />
            </Form.Group>

            <Form.Field
              type="text"
              defaultValue={clinicalCourse?.reason}
              name="reason"
              isDirty={dirtyFields.reason}
              label={t('clinical__course_create_modal_reason_label')}
              placeholder={t(
                'clinical__course_create_modal_reason_placeholder'
              )}
              register={register()}
            />

            <Form.Field
              type="textarea"
              defaultValue={clinicalCourse?.briefing}
              isDirty={dirtyFields.briefing}
              name="briefing"
              autoFocus
              label={t('clinical__course_create_modal_brief_label')}
              placeholder={t('clinical__course_create_modal_brief_placeholder')}
              register={register({
                required: t('clinical__course_create_modal_brief_error'),
              })}
              errors={errors.briefing}
            />
          </Modal.Body>
          <Modal.Footer
            disclamer={t('clinical__course_create_modal_help_text')}
          >
            <>
              <Button type="submit" disabled={!isValid || isSubmitted}>
                {t('clinical__course_create_modal_button')}
              </Button>
              {draftSaved && (
                <div className="font-medium text-gray-dark text-xs flex items-center">
                  <DownloadBox className="w-4 h-4 mr-2" />
                  {t('clinical__course_create_modal_draft_saved')}
                </div>
              )}
            </>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
};

export default ClinicalCourseModal;
