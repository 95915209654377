import {
  DropdownOption,
  DropdownMenu,
  useDropdown,
} from 'containers/Shared/components/Dropdown';
import Trash from 'components/icons/outline/Trash';
import { usePortal } from 'containers/Shared/components/Portal';
import DeleteMessageModal from 'containers/Room/components/RoomBody/DeleteMessageModal';
import DoublePaper from 'components/icons/outline/DoublePaper';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';

import { useEffect, useRef, useState } from 'react';
import FeatureFlag from 'containers/Shared/components/FeatureFlag';
import Caret from 'components/icons/outline/Caret';
import { toast } from 'react-toastify';
import InfoToast from 'containers/Shared/components/Toasts/InfoToast';
import { config } from 'config';

export default function MessageOptions({ message, isMine }) {
  const dropdown = useDropdown();
  const portal = usePortal();
  const { t } = useTranslation();
  const [isImage, setIsImage] = useState(false);
  const canEliminate = config.features.delete_messages;
  const buttonRef = useRef();
  const actionsRef = useRef();
  const [positionTop, setPositionTop] = useState(false);

  useEffect(() => {
    if (message.type === 'image') return setIsImage(true);
  }, []);

  const handleOpen = () => {
    const buttonRect = buttonRef.current.getBoundingClientRect();
    if (window.innerHeight - buttonRect.top < 200) {
      setPositionTop(true);
    } else {
      setPositionTop(false);
    }
    dropdown.open();
  };

  const openDeleteMessageModal = () => {
    portal.open(
      <DeleteMessageModal
        onClose={() => {
          portal.close('delete_message_modal');
        }}
        show={true}
        messageId={message.messageId}
      />,
      'delete_message_modal'
    );
  };

  return (
    <FeatureFlag enabled={canEliminate || message.type === 'string'}>
      <div ref={buttonRef}>
        <Button
          size="small"
          className="ml-auto bg-transparent hover:bg-transparent z-0"
          onClick={() => handleOpen()}
        >
          <Caret
            className={`${isImage ? 'text-white' : 'text-gray-dark'} h-[5px]`}
          />
        </Button>
        <div ref={actionsRef} className="bg-green">
          <DropdownMenu
            className={`w-52 z-10 ${isMine ? 'right-2' : 'left-2'} ${
              positionTop && !isImage ? 'bottom-4' : 'top-3'
            }`}
            open={dropdown.isOpen}
            onClose={dropdown.close}
          >
            {message.type === 'string' && (
              <DropdownOption
                className="flex flex-row items-center"
                onClick={() => {
                  navigator.clipboard
                    .writeText(message?.string)
                    .then(() =>
                      toast(
                        <InfoToast icon={<DoublePaper className="h-5 ml-1" />}>
                          {t('room__message_options_text_copied')}
                        </InfoToast>
                      )
                    );
                  dropdown.close();
                }}
              >
                <DoublePaper className="w-4 mr-2" />
                {t('room__message_options_copy')}
              </DropdownOption>
            )}
            <FeatureFlag enabled={canEliminate}>
              {isMine && message.auto !== 1 && (
                <DropdownOption
                  className="flex flex-row items-center text-negative"
                  onClick={() => {
                    openDeleteMessageModal();
                    dropdown.close();
                  }}
                >
                  <Trash className="w-4 mr-2" />
                  {t('room__message_options_delete')}
                </DropdownOption>
              )}
            </FeatureFlag>
          </DropdownMenu>
        </div>
      </div>
    </FeatureFlag>
  );
}
