import { useClinicalHistoryDisclaimer } from 'containers/DetailBar/PatientBar/hooks/clinicalHistoryDisclaimer.hook';
import { useTranslation } from 'react-i18next';
import Cross from 'components/icons/outline/Cross';

const ClinicalHistoryDisclaimer = () => {
  const { showDisclaimer, dismiss } = useClinicalHistoryDisclaimer();
  const { t } = useTranslation();

  if (!showDisclaimer) {
    return null;
  }

  return (
    <div className="flex rounded-lg bg-gray-light text-gray-dark py-2 px-4 mb-4 text-xs leading-5">
      <div>{t('sidebar__patient_history_disclaimer_text')}</div>

      <button
        onClick={() => {
          dismiss();
        }}
        className="flex justify-center pt-1 cursor-pointer rounded-lg hover:text-gray-dark hover:bg-gray-light focus:outline-none"
      >
        <Cross className="text-gray-medium w-3" />
      </button>
    </div>
  );
};

export default ClinicalHistoryDisclaimer;
