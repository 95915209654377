import Button from 'components/Button';
import Form from 'components/Form';
import Cross from 'components/icons/Cross';
import SquareGrid from 'components/icons/solid/SquareGrid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useDiagnosticTest from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/useDiagnosticTest';
import { useState } from 'react';

const SaveTemplate = ({
  isOpen = false,
  setIsOpen,
  setHideSaveTemplate,
}: any) => {
  const { t } = useTranslation();
  const {
    register,
    setValue,
    getValues,
    formState: { isDirty },
  } = useForm({ mode: 'onChange' });
  const handleAccordeonClick = () => {
    setIsOpen(!isOpen);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleSaveTemplate } = useDiagnosticTest();

  const handleSendTemplate = () => {
    setIsSubmitting(true);
    const title = getValues('title');
    handleSaveTemplate({ title });
    setValue('title', '');
    setTimeout(() => {
      setIsSubmitting(false);
      setIsOpen(false);
    }, 2000);
    setHideSaveTemplate(true);
  };

  return (
    <div className="pb-3 pt-4 border-t border-blue-light border-r px-2 justify-center">
      <button
        className="flex flex-row text-primary my-2 px-2 gap-2 items-center w-full"
        onClick={handleAccordeonClick}
      >
        <SquareGrid className="shrink-0" />
        <div className="uppercase text-xs font-medium tracking-wider whitespace-nowrap pt-0.5">
          {t('prescription_modal__template_save_accordeon_title')}
        </div>
        {isOpen && (
          <Cross size={9} className="text-gray-medium font-large ml-auto" />
        )}
      </button>
      {isOpen && (
        <div className="mt-5">
          <Form
            onSubmit={(event: any) => {
              event.preventDefault();
              handleSendTemplate();
            }}
          >
            <input
              type="text"
              name="title"
              placeholder={t(
                'prescription_modal__template_save_input_placeholder'
              )}
              ref={register()}
              className="w-full px-4 mb-2 bg-background rounded-lg focus:outline-none max-h-8 h-8"
            />
            <Button
              className="flex w-full mr-2 max-h-10 mt-0"
              disabled={!isDirty || isSubmitting}
            >
              {t('prescription_modal__template_save_button')}
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
};

export default SaveTemplate;
