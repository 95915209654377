import { combineReducers } from 'redux';
import auth from 'legacy/modules/auth/auth.Dux';
import { reducer as inboxReducer } from 'containers/Inbox/reducers/inbox.reducer';
import { reducer as waitingRoomReducer } from 'containers/WaitingRoom/reducers/waitingRoom.reducer';
import { reducer as sessionReducer } from 'containers/Inbox/reducers/session.reducer';
import { reducer as consoleReducer } from 'containers/Room/reducers/console.reducer';
import { reducer as patientBarReducer } from 'containers/DetailBar/PatientBar/reducers/patientBar.reducer';
import { reducer as prescriptionReducer } from 'containers/Prescription/reducers/prescription.reducer';
import layoutReducer from 'state/layout.slice';
import clinicalCoursesReducer from 'state/clinicalCourses/index.slice';
import medicalReportsReducer from 'state/medicalReports/index.slice';
import notificationReducer from 'state/notification.slice';
import kycReducer from 'state/kyc.slice';
import appointmentsReducer from 'state/appointments.slice';
import pendingReducer from "state/pending.slice"
import { invitationsApi } from 'services/invitations'
import { contactsApi } from 'services/contacts'
import { legacyReportsApi } from 'services/reports.legacy'
import { reportsApi } from 'services/reports'
import {schedulesApi } from 'services/schedules'
import { organizationGuidesApi } from 'services/organizationGuides'
import { notificationsApi } from 'services/notifications';
import DiagnosticTestsReducer from 'state/diagnosticTests/index.slice';
import  phoneCallReducer  from 'state/phoneCall/phoneCall.slice';
import audioReducer from 'state/audio.slice';
import organizationGuidesReducer from 'state/organizationGuides.slice';


export const CLEAR_STORE = 'CLEAR_STORE';
export const clearStore = () => ({
  type: CLEAR_STORE,
});

const appReducer = combineReducers({
  session: sessionReducer,
  waitingRoom: waitingRoomReducer,
  inbox: inboxReducer,
  patientBar: patientBarReducer,
  console: consoleReducer,
  prescription: prescriptionReducer,
  layout: layoutReducer,
  notification: notificationReducer,
  kyc: kycReducer,
  appointments: appointmentsReducer,
  auth,
  clinicalCourses: clinicalCoursesReducer,
  medicalReports: medicalReportsReducer,
  diagnosticTests: DiagnosticTestsReducer,
  [invitationsApi.reducerPath]: invitationsApi.reducer,
  [contactsApi.reducerPath]: contactsApi.reducer,
  [legacyReportsApi.reducerPath]: legacyReportsApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [schedulesApi.reducerPath]: schedulesApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [organizationGuidesApi.reducerPath]: organizationGuidesApi.reducer,
  pending: pendingReducer,
  phoneCall: phoneCallReducer,
  audio: audioReducer,
  organizationGuides: organizationGuidesReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    state = undefined;
    localStorage.removeItem('jwtToken');
  }

  return appReducer(state, action);
};

export default rootReducer;
