const Ban = (props: any) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 2C4.688 2 2 4.688 2 8C2 11.312 4.688 14 8 14C11.312 14 14 11.312 14 8C14 4.688 11.312 2 8 2ZM3.2 8C3.2 5.348 5.348 3.2 8 3.2C9.11 3.2 10.13 3.578 10.94 4.214L4.214 10.94C3.578 10.13 3.2 9.11 3.2 8ZM8 12.8C6.89 12.8 5.87 12.422 5.06 11.786L11.786 5.06C12.422 5.87 12.8 6.89 12.8 8C12.8 10.652 10.652 12.8 8 12.8Z"
      fill="currentColor"
    />
  </svg>
);

export default Ban;
