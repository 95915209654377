const MaterialSymbols = (props: any) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.858398" width="32" height="32" rx="16" fill="#FFEBCE" />
    <path
      d="M10.6362 15.1111C10.1473 15.1111 9.72862 14.9369 9.38018 14.5884C9.03173 14.24 8.85781 13.8216 8.8584 13.3333V9.77778C8.8584 9.28889 9.03262 8.87022 9.38107 8.52178C9.72951 8.17333 10.1479 7.99941 10.6362 8H14.1917C14.6806 8 15.0993 8.17422 15.4477 8.52267C15.7962 8.87111 15.9701 9.28948 15.9695 9.77778V13.3333C15.9695 13.8222 15.7953 14.2409 15.4468 14.5893C15.0984 14.9378 14.68 15.1117 14.1917 15.1111H10.6362ZM19.5251 24C19.0362 24 18.6175 23.8258 18.2691 23.4773C17.9206 23.1289 17.7467 22.7105 17.7473 22.2222V18.6667C17.7473 18.1778 17.9215 17.7591 18.27 17.4107C18.6184 17.0622 19.0368 16.8883 19.5251 16.8889H23.0806C23.5695 16.8889 23.9882 17.0631 24.3366 17.4116C24.6851 17.76 24.859 18.1784 24.8584 18.6667V22.2222C24.8584 22.7111 24.6842 23.1298 24.3357 23.4782C23.9873 23.8267 23.5689 24.0006 23.0806 24H19.5251Z"
      fill="#FF8A01"
    />
    <path
      d="M20.464 8.78885C20.8325 8.05181 21.8843 8.05181 22.2528 8.78885L24.6348 13.5528C24.9672 14.2177 24.4837 15 23.7404 15H18.9764C18.2331 15 17.7496 14.2177 18.082 13.5528L20.464 8.78885Z"
      fill="#3C50EC"
    />
    <circle cx="12.3584" cy="20.5" r="3.5" fill="#42CECE" />
  </svg>
);

export default MaterialSymbols;
