import { getDeletedMessage } from 'containers/Room/actions/DeleteMessage.actions';
import { messageStatusChanged } from 'containers/Room/actions/GetMessages.actions';
import { newMessageReceived } from 'containers/Room/actions/NewMessage.actions';
import { messageSent } from 'containers/Room/actions/SendMessage.actions';
import ConsultationsApiClient from 'legacy/Services/Consultations';
import Tracker from 'utils/Tracking';

export const onMessageSent = (dispatch) => (data) => {
  ConsultationsApiClient.chat
    .fetchRoomLastMessage(data.roomId)
    .then((message) => {
      dispatch(messageSent(message));
    });
};

export const onMessageReceived = (dispatch) => {
  return (data) => {
    Tracker.event('chat message received', {
      event_room_id: data.roomId,
      event_message_patient_hash: data.fromUserHash,
      event_message_content: data.string,
    });

    ConsultationsApiClient.chat
      .fetchRoomLastMessage(data.roomId)
      .then((message) => {
        dispatch(newMessageReceived(message));
      });
  };
};

export const onMessageStatusChanged = (dispatch) => (data) =>
  dispatch(
    messageStatusChanged({
      room_id: data.roomId,
      message_id: data.messageId,
      status: data.status,
    })
  );

export const onMessageDeleted = (dispatch) => (data) =>
  dispatch(
    getDeletedMessage({
      message_id: data.message_id,
      room_id: data.room_id,
      user_hash: data.user_hash,
    })
  );
