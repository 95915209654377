import { FC } from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import Dollar from 'components/icons/outline/Dollar';

export interface Props {
  onClose?: () => void;
}

interface AdminProps extends Props {
  title: string;
  description: string;
  footer: string;
}

const Admin: FC<AdminProps> = ({
  children,
  title,
  description,
  footer,
  onClose,
}) => (
  <Modal onClose={onClose} rounded="large" width="narrow">
    <Modal.CloseButton onClose={onClose} />
    <Modal.Body type="full" className="flex flex-col items-center text-center">
      <Modal.BoxRounded negative>
        <Dollar className="w-[30px] h-[30px]" />
      </Modal.BoxRounded>
      <div className="mb-6 text-2xl mt-2 font-medium w-[384px] text-primary">
        {title}
      </div>
      <div className="mb-12 w-[384px]">{description}</div>
      <div className="flex flex-col items-center w-[300px]">{children}</div>
      <p className="text-gray-dark text-[10px] w-[300px] mt-2">{footer}</p>
    </Modal.Body>
  </Modal>
);

const Separator = () => (
  <div className="h-[1px] w-[120px] bg-separators my-6" />
);

interface PendingProps extends Props {
  onRetry: () => void;
  onFinishFreeCharge: () => void;
}

export const Pending: FC<PendingProps> = ({
  onClose,
  onRetry,
  onFinishFreeCharge,
}) => {
  const { t } = useTranslation();
  return (
    <Admin
      title={t('appointments__payment_detail_admin_pending_title')}
      description={t('appointments__payment_detail_admin_pending_description')}
      footer={t('appointments__payment_detail_admin_pending_footer')}
      onClose={onClose}
    >
      <Button onClick={onRetry} className="w-full">
        {t('appointments__payment_detail_admin_button_retry')}
      </Button>
      <Separator />
      <Button
        onClick={onFinishFreeCharge}
        className="w-full"
        variant="secondary"
      >
        {t('appointments__payment_detail_admin_button_finish_free_charge')}
      </Button>
    </Admin>
  );
};

interface UnpaidProps extends Props {
  days: number;
  onAgreed: () => void;
  onFinishFreeCharge: () => void;
}

export const Unpaid: FC<UnpaidProps> = ({
  days,
  onClose,
  onAgreed,
  onFinishFreeCharge,
}) => {
  const { t } = useTranslation();
  return (
    <Admin
      title={t('appointments__payment_detail_admin_unpaid_title', { days })}
      description={t('appointments__payment_detail_admin_unpaid_description')}
      footer={t('appointments__payment_detail_admin_unpaid_footer')}
      onClose={onClose}
    >
      <Button onClick={onAgreed} className="w-full">
        {t('appointments__payment_detail_admin_button_agreed')}
      </Button>
      <Separator />
      <Button
        onClick={onFinishFreeCharge}
        className="w-full"
        variant="secondary"
      >
        {t('appointments__payment_detail_admin_button_finish_free_charge')}
      </Button>
    </Admin>
  );
};

interface UnpaidLastProps extends Props {
  onRetry: () => void;
  onFinish: () => void;
  onFinishOwe: () => void;
}

export const UnpaidLast: FC<UnpaidLastProps> = ({
  onClose,
  onRetry,
  onFinish,
  onFinishOwe,
}) => {
  const { t } = useTranslation();
  return (
    <Admin
      title={t('appointments__payment_detail_admin_unpaid_last_title')}
      description={t(
        'appointments__payment_detail_admin_unpaid_last_description'
      )}
      footer={t('appointments__payment_detail_admin_unpaid_last_footer')}
      onClose={onClose}
    >
      <Button onClick={onRetry} className="w-full">
        {t('appointments__payment_detail_admin_button_retry')}
      </Button>
      <Separator />
      <div className="space-y-4">
        <Button onClick={onFinish} className="w-full" variant="secondary">
          {t('appointments__payment_detail_admin_button_mark_as_paid')}
        </Button>
        <Button onClick={onFinishOwe} className="w-full" variant="secondary">
          {t('appointments__payment_detail_admin_button_mark_as_unpaid')}
        </Button>
      </div>
    </Admin>
  );
};
