import { useTranslation } from 'react-i18next';
import useDiagnosticTest from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/useDiagnosticTest';
import { useState } from 'react';
import MaterialSymbols from 'components/images/MaterialSymbols';
import Caret from 'components/icons/outline/Caret';
import classNames from 'classnames';
import Plus from 'components/icons/Plus';
import RefreshTwoArrows from 'components/icons/outline/RefreshTwoArrows';

const EditTemplate = ({
  isOpen = false,
  setIsOpen,
  template,
  setIsSaveNewOpen,
  setHideSaveTemplate,
  showEditTemplate,
  setShowEditTemplate,
}: any) => {
  const { t } = useTranslation();

  const handleAccordeonClick = () => {
    setIsOpen(!isOpen);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleUpdateTemplate } = useDiagnosticTest();

  const handleSendNewTemplate = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsSaveNewOpen(true);
      setHideSaveTemplate(false);
      setShowEditTemplate(false);
    }, 800);
  };

  const handleUpdateTemplateClick = () => {
    setIsSubmitting(true);
    handleUpdateTemplate(template);
    setTimeout(() => {
      setIsSubmitting(false);
      setIsOpen(false);
    }, 1000);
  };

  return (
    <div
      className={classNames(
        'py-2 border-t border-blue-light border-r px-2 justify-center duration-200 transition-transform',
        !showEditTemplate && 'hidden'
      )}
    >
      <div className="p-2 items-center bg-dark rounded-lg text-white">
        <button
          className="flex flex-row text-primary m-2 gap-4 items-center"
          onClick={handleAccordeonClick}
        >
          <MaterialSymbols className="shrink-0" />

          <div className="text-xs tracking-wide pt-0.5 text-white">
            <span className="flex">
              {t('prescription_modal__template_update_accordeon_title')}
            </span>
            <span>
              {t('prescription_modal__template_update_accordeon_subtitle')}
            </span>
          </div>

          <Caret
            className={classNames(
              'text-white font-large mr-0 w-3.5 shrink-0',
              isOpen ? 'rotate-180' : ''
            )}
          />
        </button>
        {isOpen && (
          <div className="flex flex-col mt-5 text-xs object-contain">
            <div className="flex flex-row mb-2">
              {t('prescription_modal__template_update_accordeon_description')}
            </div>
            <div className="flex justify-between gap-2">
              <button
                className="flex flex-row w-full items-center rounded-lg p-2 max-h-10 items-center uppercase text-primary bg-secundary font-medium text-xs whitespace-nowrap tracking-wider justify-center"
                onClick={handleUpdateTemplateClick}
                disabled={isSubmitting}
              >
                <RefreshTwoArrows className="w-4 mr-1" />
                {t('prescription_modal__template_update_button')}
              </button>
              <button
                className="flex flex-row w-full items-center rounded-lg p-2 h-10 max-h-10 h-10 items-center uppercase text-white bg-primary font-medium text-xs whitespace-nowrap tracking-wider justify-center"
                disabled={isSubmitting}
                onClick={handleSendNewTemplate}
              >
                <Plus className="h-3 w-3 mr-2" />
                {t('prescription_modal__template_update_save_button')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditTemplate;
