import { FC } from 'react';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { phone as phoneRegexp } from 'utils/regexp';
import countries from 'data/countries.json';
import ReactSelect, { components } from 'react-select';
import Form from 'components/Form';

export const DEFAULT_PHONE_PREFIX = '+34';

export const Fields = {
  prefix: {
    name: 'prefix',
  },
  phone: {
    name: 'phone',
  },
};

export interface Props {
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  register: any;
  control: any;
  isDirty: any;
  errors: any;
  menuPlacement?: 'top' | 'bottom';
}

const Phone: FC<Props> = ({
  required,
  disabled,
  readOnly,
  register,
  control,
  isDirty,
  errors,
  menuPlacement = 'bottom',
}) => {
  const { t } = useTranslation();

  return (
    <div className={classnames('flex', { 'opacity-50': disabled })}>
      <Dropdown
        disabled={disabled}
        readOnly={readOnly}
        errors={errors}
        control={control}
        menuPlacement={menuPlacement}
        defaultValue={DEFAULT_PHONE_PREFIX}
        name={Fields.prefix.name}
        options={countries.map(({ prefix, name }) => ({
          value: prefix,
          label: `${name} (${prefix})`,
        }))}
      />
      <Form.Field
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        key={Fields.phone.name}
        label={t('shared__field_label_phone')}
        type="tel"
        placeholder={t('shared__field_label_phone')}
        name={Fields.phone.name}
        register={register({
          required: required && t('shared__errors_required'),
          pattern: required
            ? {
                value: phoneRegexp,
                message: t('shared__errors_only_numbers'),
              }
            : {},
        })}
        isDirty={isDirty}
        errors={errors}
      />
    </div>
  );
};

const Dropdown: FC<any> = ({
  menuPlacement,
  name,
  required,
  disabled,
  options,
  control,
  defaultValue,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ onChange, value, name, ref }) => (
      <ReactSelect
        isDisabled={disabled}
        className={classnames('Dropdown prefix w-28')}
        styles={{
          menu: (provided) => ({ ...provided, width: '250px' }),
          singleValue: (base) => ({ ...base }),
        }}
        components={{ SingleValue }}
        menuPlacement={menuPlacement}
        isSearchable={true}
        classNamePrefix="ReactDropdown"
        options={options}
        inputRef={ref}
        value={options.find((o: any) => o.value === value)}
        name={`${name}${required ? '*' : ''}`}
        defaultValue={options.find((o: any) => o.value === defaultValue)}
        onChange={(selectedOption: any) => onChange(selectedOption.value)}
      />
    )}
  />
);

const SingleValue: FC<any> = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {children.match(/\((.*)\)/)[1]}
  </components.SingleValue>
);

export default Phone;
