import Axios from 'axios';
import uuid from 'uuid/v4';
import { browserName } from 'react-device-detect';
import * as Sentry from '@sentry/browser';
import {config} from 'config';

class TrackingCall {
  constructor() {
    this.deviceOs = navigator.platform;
    this.deviceModel = browserName;
    this.deviceId = MediaDeviceInfo.deviceId;
    this.environment = config.app.environment;
    this.url = config.services.mediquo.tracking_call_url;
  }

  setConsultation({ consultationId, consultationType, callId, userHash, contactId }) {
    this.consultationId = consultationId;
    this.consultationType = consultationType;
    this.callId = callId;
    this.userHash = userHash;
    this.contactId = contactId;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new TrackingCall();
    }
    return this.instance;
  }

  event(eventName, payload) {
    this.sendRequest({
      ...payload,
      event_type: eventName,
      action_type: 'event',
    });
  }

  debug(eventName, payload) {
    this.sendRequest({
      ...payload,
      event_type: eventName,
      action_type: 'debug',
    });
  }

  sendRequest(payload) {
    const body = {
      ...payload,
      unique_id: uuid(),
      user_hash: this.userHash,
      user_type: 'professional',
      device_os: this.deviceOs,
      device_model: this.deviceModel,
      device_id: this.deviceId,
      platform: 'web',
      product: 'console',
      environment: this.environment,
      consultation_id: this.consultationId,
      consultation_type: this.consultationType,
      call_id: this.callId,
    };

    Axios.post(this.url, body).catch((error) =>
      Sentry.captureException(error, {extra: body})
    );
  }
}

const Tracker = TrackingCall.getInstance();
export default Tracker;
