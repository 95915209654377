import axios, { AxiosPromise } from 'axios';
import getHeaders from 'utils/getHeaders';
import { Fields as InviteFields } from 'containers/Invitations/Vithas/InviteForm';
import { Fields as SearchFields } from 'containers/Invitations/Vithas/SearchForm';
import { Room } from 'api/invitations.d';
import { config } from 'config';

const API = 'professionals/v1/vithas';
const BASE_URL = config.services.mediquo.chat_url;

const vithas = {
  create: ({ nhc, center_code }: InviteFields): AxiosPromise<{ data: Room }> =>
    axios({
      method: 'POST',
      url: `${BASE_URL}/${API}/invitations`,
      headers: getHeaders(),
      data: {
        nhc,
        center_code,
      },
    }),
  search: ({
    nhc,
    center_code,
  }: Pick<SearchFields, 'nhc' | 'center_code'>): AxiosPromise<{
    data: { first_name: string; last_name: string; birth_date: string };
  }> =>
    axios({
      method: 'GET',
      headers: getHeaders(),
      url: `${BASE_URL}/${API}/invitations`,
      params: { nhc, center_code },
    }),
  advancedSearch: ({
    name,
    first_last_name,
    second_last_name,
    phone,
    email,
    document_type,
    document_id,
    birthdate,
    nhc,
    center_code,
  }: SearchFields): AxiosPromise<{ data: Room }> =>
    axios({
      method: 'POST',
      url: `${BASE_URL}/${API}/invitations/search`,
      headers: getHeaders(),
      data: {
        name,
        first_last_name,
        second_last_name,
        phone,
        email,
        document_type,
        document_id,
        birth_date: birthdate,
        nhc,
        center_code,
      },
    }),
};

export default {
  vithas,
};
