import axios, { AxiosPromise } from 'axios';
import getHeaders from 'utils/getHeaders';
import { BillingRQ, ProfileResponse } from 'api/patients/index.d';
import { config } from 'config';

const API_URL = config.services.mediquo.api_url;
const CHAT_URL = config.services.mediquo.chat_url;

const profile = {
  billing: ({
    hash,
    billing_name,
    dni,
    address,
    country_code,
    region,
    postal_code,
  }: BillingRQ) =>
    axios({
      method: 'PUT',
      url: `${API_URL}pro/v1/customers/${hash}/billing`,
      data: {
        name: billing_name,
        tax_id: dni,
        address,
        country_code,
        region,
        postal_code,
      },
      headers: getHeaders(),
    }),
  get: ({ hash }: { hash: string }): AxiosPromise<ProfileResponse> =>
    axios({
      method: 'GET',
      url: `${API_URL}pro/v1/customers/${hash}/profile`,
      headers: getHeaders(),
    }),
};

const rooms = {
  get: ({ hash }: any): AxiosPromise<any> =>
    axios({
      method: 'GET',
      url: `${CHAT_URL}/api/v1/customers/${hash}/rooms`,
      headers: getHeaders(),
    }),
};

export default {
  profile,
  rooms,
};
