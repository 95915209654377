import api from 'api';
import { toast } from 'react-toastify';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import i18n from 'i18n';

const PATIENT_BILLING_PROFILE_UPDATED =
  'PATIENT_BAR/PATIENT_BILLING_PROFILE_UPDATED';

export const actionTypes = {
  PATIENT_BILLING_PROFILE_UPDATED,
};

export const updateBillingProfile = (hash, data) => {
  return (dispatch) => {
    api.patients.profile
      .billing({
        hash,
        billing_name: data.name,
        dni: data.tax_id,
        postal_code: data.postal_code,
        address: data.address,
        region: data.region,
        country_code: data.country_code,
      })
      .then(() => dispatch(billingProfileUpdated(data)))
      .catch(() =>
        toast.error(
          <ErrorToast>{i18n.t('shared__errors_generic_message')}</ErrorToast>
        )
      );
  };
};

const billingProfileUpdated = (data) => ({
  type: PATIENT_BILLING_PROFILE_UPDATED,
  payload: data,
});
