import Image from 'containers/Shared/components/Image';
import XIcon from 'containers/VideoCall/icons/XIcon';
import VideoIcon from 'components/icons/Video';
import PhoneIcon from 'components/icons/Phone';
import { useDispatch, useSelector } from 'react-redux';
import { removeMessage } from 'state/notification.slice';
import ConsultationsApiClient from 'legacy/Services/Consultations';
import { usePortal } from 'containers/Shared/components/Portal/index';
import PermissionsModal from 'containers/VideoCall/PermissionsModal';
import { useTranslation } from 'react-i18next';

export const CallType = {
  CALL: 'call',
  VIDEO_CALL: 'video',
};

export const PermissionCallTypes = {
  video: { audio: true, video: true },
  call: { audio: true },
};

export const CallTypes = {
  video: CallType.VIDEO_CALL,
  call: CallType.CALL,
};

const CallNotificationContainer = () => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.notification.messages);
  const jwt = useSelector((state) => state.session.profile?.jwt);

  const rejectCall = async ({ uuid }) => {
    const {
      hasAudioEnabled: audio,
      hasVideoEnabled: video,
    } = await getPermissions();
    ConsultationsApiClient.updateHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    ConsultationsApiClient.chat.rejectCall({ uuid, audio, video });
  };

  const openPickup = ({ type, roomId, sessionId, token, uuid, title }) => {
    window.open(
      `/call/${roomId}?mode=pickup&type=${type}&sessionId=${sessionId}&token=${token}&uuid=${uuid}&title=${title}`,
      '_blank'
    );
  };

  return messages.map(
    ({
      id,
      content: {
        call: { uuid, room_id: roomId, type, session_id: sessionId, token },
        professional: { avatar, name },
      },
    }) => (
      <CallNotification
        key={id}
        name={name}
        imageSrc={avatar}
        onCancel={() => {
          rejectCall({ uuid });
          dispatch(removeMessage(id));
        }}
        type={CallTypes[type]}
        onAccept={() => {
          openPickup({ type, roomId, sessionId, token, uuid, title: name });
          dispatch(removeMessage(id));
        }}
      />
    )
  );
};

const CallNotification = ({
  type = CallType.VIDEO_CALL,
  name,
  imageSrc,
  onCancel,
  onAccept,
}) => {
  const { t } = useTranslation();
  const portal = usePortal();

  const onClickAccept = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: CallType.VIDEO_CALL === type })
      .then(onAccept)
      .catch(() => portal.open(<PermissionsModal onClose={portal.close} />));
  };

  return (
    <div className="fixed z-10 p-4 text-center transform -translate-x-1/2 bg-white shadow-2xl text-dark top-6 left-1/2 rounded-24 w-96">
      <div className="pb-2 text-xs text-primary">
        {
          {
            [CallType.CALL]: t('videocall__notification_call_title'),
            [CallType.VIDEO_CALL]: t('videocall__notification_videocall_title'),
          }[type]
        }
      </div>
      <Image
        src={imageSrc}
        alt={name}
        placeholder={<ImagePlaceHolder text={name} />}
        className="w-12 h-12 mx-auto my-2 rounded-full"
      />
      <h3 className="mb-1 font-medium">{name}</h3>
      <p className="text-sm">{t('videocall__notification_text')}</p>
      <div className="flex justify-center gap-2 mt-6">
        <button
          className="h-12 rounded-lg bg-negative min-w-7"
          onClick={onCancel}
        >
          <XIcon className="m-auto" />
        </button>
        <button
          className="h-12 rounded-lg bg-positive min-w-7"
          onClick={onClickAccept}
        >
          {
            {
              [CallType.CALL]: <PhoneIcon className="m-auto" />,
              [CallType.VIDEO_CALL]: <VideoIcon className="m-auto" />,
            }[type]
          }
        </button>
      </div>
    </div>
  );
};

const getPermissions = () =>
  navigator.mediaDevices.enumerateDevices().then((devices) => ({
    hasAudioEnabled: devices.some(
      (device) => device.label && device.kind === 'audioinput'
    ),
    hasVideoEnabled: devices.some(
      (device) => device.label && device.kind === 'videoinput'
    ),
  }));

const ImagePlaceHolder = ({ text, className }) => (
  <div
    className={`flex items-center justify-center font-medium uppercase bg-blue-light text-primary ${className}`}
  >
    {text?.charAt(0) ?? '?'}
  </div>
);

export default CallNotificationContainer;
