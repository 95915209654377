import { createAction, createSlice } from '@reduxjs/toolkit';
import {
  BillingDocumentsAddData,
  BillingUpdateData,
  ProfileBilling,
} from 'api/profile.d';

export const VALIDATE = 'kyc/validate';
export const validate = createAction(VALIDATE);

const initialState = {
  billing: {},
} as { billing: ProfileBilling };

const kycSlice = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    setBilling(state, action) {
      state.billing = action.payload;
    },
    updateBilling(state, { payload }: { payload: BillingUpdateData }) {
      state.billing.address = payload.address;
      state.billing.birth_date = payload.birthDate;
      state.billing.city = payload.city;
      state.billing.country = payload.country;
      state.billing.last_name = payload.lastName;
      state.billing.name = payload.name;
      state.billing.iban = payload.iban;
      state.billing.postal_code = payload.postalCode;
    },
    updateDocuments(state, { payload }: { payload: BillingDocumentsAddData }) {
      if (payload.idDocumentFrontUrl) {
        state.billing.documents.id.front_url = payload.idDocumentFrontUrl;
      }
      if (payload.idDocumentReverseUrl) {
        state.billing.documents.id.reverse_url = payload.idDocumentReverseUrl;
      }
      if (payload.selfEmploymentDocumentUrl) {
        state.billing.documents.self_employment.front_url =
          payload.selfEmploymentDocumentUrl;
      }
    },
  },
});

export const { setBilling, updateBilling, updateDocuments } = kycSlice.actions;
export default kycSlice.reducer;
