const Flag = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5089_42156)">
      <path
        d="M2.5 14.5V2.12942C2.50015 2.08569 2.51178 2.04276 2.53371 2.00492C2.55565 1.96708 2.58712 1.93566 2.625 1.9138C2.86906 1.77067 3.52875 1.50005 5 1.50005C7 1.50005 9.53125 3.00005 11 3.00005C11.8292 2.99789 12.6497 2.83035 13.4134 2.50723C13.4229 2.50327 13.4333 2.50171 13.4435 2.50271C13.4538 2.5037 13.4636 2.50722 13.4722 2.51294C13.4808 2.51866 13.4878 2.52641 13.4926 2.5355C13.4975 2.5446 13.5 2.55474 13.5 2.56505V9.42005C13.4999 9.44425 13.4928 9.46791 13.4796 9.48816C13.4663 9.5084 13.4475 9.52437 13.4253 9.53411C13.1678 9.64692 12.2603 10 11 10C9.5 10 7 9.00005 5 9.00005C3 9.00005 2.5 9.50005 2.5 9.50005"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5089_42156">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Flag;
