import WomanSearching from 'components/images/WomanSearching';
import Loading from 'components/Loading';
import ProfileImage from 'components/ProfileImage';
import SearchInput from 'components/SearchInput';
import Drawer from 'components/Drawer';
import Empty from 'pages/main/Contacts/Invitations/Empty';
import { FC, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Contact } from 'services/contacts';
import useInfiniteScroll from 'utils/useInfiniteScroll';
import useContacts from 'containers/Appointments/useContacts';
import classNames from 'classnames';
import Plus from 'components/icons/solid/Plus';
import ProfilePlus from 'components/icons/outline/ProfilePlus';
import { usePortal } from 'containers/Shared/components/Portal';
import Invite from 'containers/Invitations/Invite';
import Banner from 'components/Banner';
import BannerType from 'components/Banner/BannerType';

export interface Props {
  enabled?: boolean;
  title?: string;
  with_accepted_invitation?: boolean;
  onSelected: (contact: Partial<Contact>) => void;
  onBack?: () => void;
  onClose?: () => void;
}

const ContactSelector: FC<Props> = ({
  enabled,
  title,
  with_accepted_invitation,
  onSelected,
  onBack,
  onClose,
}) => {
  const { t } = useTranslation();
  const { contacts, status, searchByTerm, term, more, isLoading } = useContacts(
    { enabled, with_accepted_invitation }
  );

  return (
    <>
      <Drawer.Header
        title={title ?? t('appointments__search_contact_title')}
        onBack={onBack}
        onClose={onClose}
      />
      {with_accepted_invitation && (
        <Banner
          text={
            'Sólo verás los pacientes que hayan aceptado la invitación a tu consulta.'
          }
          type={BannerType.info}
        />
      )}

      {status !== 'empty' && (
        <div className="p-4">
          <SearchInput
            placeholder={t('appointments__search_contact_input_placeholder')}
            onSearch={searchByTerm}
            onReset={() => searchByTerm('')}
          />
        </div>
      )}
      {!with_accepted_invitation && <NewContact onInvited={onSelected} />}
      {
        {
          loading: <Loading />,
          empty: <Empty />,
          'empty-search': <EmtySearch term={term} />,
          success: (
            <ContactsList
              contacts={contacts}
              isLoading={isLoading}
              onSelect={onSelected}
              onMore={more}
            />
          ),
        }[status]
      }
    </>
  );
};

const NewContact: FC<{ onInvited: (contact: Partial<Contact>) => void }> = ({
  onInvited,
}) => {
  const portal = usePortal();
  const { t } = useTranslation();

  const openInvite = () =>
    portal.open(
      <Invite
        withConfirmation={false}
        onClose={(contact?: Partial<Contact>) => {
          contact && onInvited(contact);
          portal.close('contact');
        }}
        target="patient"
      />,
      'contact'
    );

  return (
    <>
      <div className="bg-separators h-[1px] mx-6 flex-shrink-0" />
      <button
        className="flex items-center gap-4 px-1 py-1 mx-4 my-3 rounded-lg hover:bg-gray-light"
        onClick={openInvite}
      >
        <div className="grid w-8 h-8 rounded-full bg-blue-light shrink-0 place-items-center text-primary">
          <ProfilePlus className="w-4 h-4" />
        </div>
        <div className="text-sm font-medium text-dark">
          {t('appointments__search_contact_new_contact')}
        </div>
        <Plus className="w-6 h-6 ml-auto text-primary" />
      </button>
      <div className="bg-separators h-[1px] mx-6 flex-shrink-0" />
    </>
  );
};

const EmtySearch: FC<{ term: string }> = ({ term }) => {
  const { t } = useTranslation();
  return (
    <div className="grid h-full text-center place-content-center">
      <WomanSearching className="h-24 mx-auto mb-6" />
      <p className="max-w-xs text-sm break-words text-gray-dark">
        {t('appointments_search_contact_empty_term', { term })}
      </p>
    </div>
  );
};

const ContactsList: FC<{
  contacts?: Partial<Contact>[];
  isLoading?: boolean;
  onSelect: (contact: Partial<Contact>) => void;
  onMore: () => void;
}> = ({ contacts, isLoading, onSelect, onMore }) => {
  const sortedContactsByName = [...(contacts ?? [])].sort(byName);
  const groupContactsByFirstLetter = Array.from(
    groupByFirstLetter(sortedContactsByName)
  );

  const infiniteScrollRef = useInfiniteScroll({
    onIntersect: onMore,
    isActive: !isLoading,
  });

  return (
    <ul className="px-4 pb-6 overflow-auto h-full">
      {groupContactsByFirstLetter.map(
        ([letter, contacts], groupIndex, groupArray) => (
          <li key={letter}>
            <>
              <p className="m-2 text-sm font-medium uppercase text-primary">
                {letter}
              </p>
              <ul className="flex flex-col gap-1">
                {contacts.map(
                  (contact: Partial<Contact>, contactIndex, contactsArray) => (
                    <li key={contact.id}>
                      <ContactListItem
                        ref={
                          isLast(groupArray, groupIndex) &&
                          isLast(contactsArray, contactIndex)
                            ? infiniteScrollRef
                            : undefined
                        }
                        contact={contact}
                        onClick={() => onSelect(contact)}
                      />
                    </li>
                  )
                )}
              </ul>
            </>
          </li>
        )
      )}
    </ul>
  );
};

const ContactListItem = forwardRef<
  HTMLButtonElement,
  { contact: Partial<Contact>; onClick: () => void }
>(({ contact, onClick }, ref) => {
  return (
    <button
      ref={ref}
      className={classNames(
        'flex items-center w-full gap-4 p-2 rounded-lg hover:bg-gray-light',
        { 'opacity-50': !contact.features?.can_be_appointed }
      )}
      onClick={onClick}
    >
      <ProfileImage
        classNameSize="w-8 h-8"
        src={contact.avatar ?? ''}
        alt={contact.name!}
      />
      <h3 title={contact.name} className="text-sm text-left truncate text-dark">
        {contact.name}
      </h3>
    </button>
  );
});

const isLast = (array: any[], index: number) => index === array?.length - 1;

const byName = (a: Partial<Contact>, b: Partial<Contact>) =>
  (a?.name?.toLowerCase() ?? '') < (b?.name?.toLowerCase() ?? '') ? -1 : 1;

const groupByFirstLetter = (contacts: Partial<Contact>[]) => {
  const groups = new Map<string, Partial<Contact>[]>();

  const KEY_WITHOUT_LETTER = '#';

  contacts.forEach((contact) => {
    const firstLetter = contact.name?.charAt(0).toUpperCase();
    const key =
      !firstLetter || Number.parseInt(firstLetter)
        ? KEY_WITHOUT_LETTER
        : firstLetter;

    const group = groups.get(key) ?? [];

    groups.set(key, [...group, contact]);
  });

  return groups;
};

export default ContactSelector;
