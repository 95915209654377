const FileAdd = (props: any) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_806_3815)">
      <path
        d="M14 2L20 8.00002V20C20 21.1 19.1 22 17.9999 22H5.99C4.89001 22 4 21.1 4 20L4.01 4C4.01 2.9 4.9 2 6 2H14ZM13 4H6V20H17.9999V9.00001H13V4ZM12.8 10C13.2418 10 13.6 10.3582 13.6 10.8V12.4H15.2C15.6418 12.4 16 12.7582 16 13.2V14.8C16 15.2418 15.6418 15.6 15.2 15.6H13.6V17.2C13.6 17.6418 13.2418 18 12.8 18H11.2C10.7582 18 10.4 17.6418 10.4 17.2V15.6H8.8C8.35818 15.6 8 15.2418 8 14.8V13.2C8 12.7582 8.35818 12.4 8.8 12.4H10.4V10.8C10.4 10.3582 10.7582 10 11.2 10H12.8Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_806_3815">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FileAdd;
