import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  pin as _pin,
  unpin as _unpin,
  archiveRooms,
  unarchiveRooms,
  unreadRooms,
  readRooms,
} from 'containers/Inbox/actions/RoomListOptions.actions';
import {
  acceptRoom,
  rejectRoom,
} from 'containers/Room/actions/PendingPatient.actions';
import { Room } from 'api/rooms';
import Tracker from 'utils/Tracking';
import { usePortal } from 'containers/Shared/components/Portal';
import Confirmation from 'components/Modal/Confirmation';
import { toggleDetailBar } from 'state/layout.slice';
import { setEmptyRecord as _setEmptyRecord } from 'containers/DetailBar/PatientBar/actions/MedicalHistory.actions';
import PatientBlockedModal from 'containers/Shared/components/Patient/PatientBlockedModal';
import PatientUnavailableModal from 'containers/Shared/components/Patient/PatientUnavailableModal';
import BlockRoomModal from 'containers/Room/components/RoomBody/BlockRoomModal';
import { getAppointmentByRoomId } from 'state/appointments.slice';

const useRoomActions = ({ room }: { room: Room | null | undefined }) => {
  const { t } = useTranslation();
  const portal = usePortal();
  const dispatch = useDispatch();
  const profile = useSelector((state: any) => state.session.profile);
  const appointment = useSelector((state) =>
    getAppointmentByRoomId({ state, roomId: room?.room_id })
  );

  const hasCurrentAppointment = ['pending', 'accepted'].includes(
    appointment?.status ?? ''
  )  && !appointment?.no_show;
  const isArchived = !room?.is_open;
  const isBanned = room?.meta?.banned_at;
  const isPending = !room?.meta?.hash;
  const isBetweenProfessionals = room?.type === 'professional';
  const isBlocked = room?.status === 'blocked';
  const isBlockedBy = (hash: string) => room?.blocked_by === hash;
  const isConsultation = room?.type === 'patient';
  const isPinned = room?.pinned_at;
  const isPremium = room?.meta?.is_premium;
  const isUnread = room?.need_to_read === true;
  const isDetailBarOpen = useSelector(
    (state: any) => state.layout.detailBarOpen
  );

  const checkBan = () => {
    if (!isBanned) return;

    portal.open(
      <PatientBlockedModal
        room={room}
        show={() => portal.isOpen('blocked')}
        onClose={() => portal.close('blocked')}
      />,
      'blocked'
    );
  };
  const checkAvailable = () => {
    if (room?.meta?.hash) return;

    portal.open(
      <PatientUnavailableModal
        show={() => portal.isOpen('available')}
        onClose={() => portal.close('available')}
      />,
      'available'
    );
  };

  const setEmptyRecord = () => {
    Tracker.event('chat view menu unknown medical history', {
      event_room_id: room?.room_id,
    });

    dispatch(_setEmptyRecord(room?.meta?.hash));
  };

  const archive = () => {
    const roomId = room?.room_id;

    Tracker.event('chat view menu close room', { event_room_id: roomId });
    Tracker.event('close room view', { event_room_id: roomId });

    portal.open(
      <Confirmation
        title={t('room__archive_confirmation_title')}
        content={t('room__archive_confirmation_text')}
        cancelText={t('shared__confirm_modal_cancel')}
        confirmText={t('shared__confirm_modal_confirm')}
        onConfirm={() => {
          Tracker.event('close room accept', { event_room_id: roomId });
          dispatch(archiveRooms([roomId]));
          portal.close('confirmation');
        }}
        onCancel={() => {
          Tracker.event('close room dismiss', { event_room_id: roomId });
          portal.close('confirmation');
        }}
      />,
      'confirmation'
    );
  };

  const reopen = () => {
    checkBan();
    dispatch(unarchiveRooms([room?.room_id]));
  };

  const unread = () => {
    checkBan();
    dispatch(unreadRooms([room?.room_id]));
  };

  const read = () => {
    checkBan();
    dispatch(readRooms([room?.room_id]));
  };

  const pin = (hash: string) => {
    checkBan();

    dispatch(_pin({ roomId: room?.room_id, hash }));
  };

  const unpin = (hash: string) => {
    checkBan();
    dispatch(_unpin({ roomId: room?.room_id, hash }));
  };

  const toggleUnread = isUnread ? read : unread;
  const toggleArchive = isArchived ? reopen : archive;
  const togglePin = (hash: string) => (isPinned ? unpin(hash) : pin(hash));

  const block = () => {
    portal.open(
      <BlockRoomModal
        room={room}
        open={() => portal.isOpen('block')}
        onClose={() => {
          Tracker.event('chat block reason dismiss', {
            event_room_id: room?.room_id,
          });
          portal.close('block');
        }}
        reject={(reason: any) => {
          Tracker.event('chat block reason select', {
            event_room_id: room?.room_id,
          });
          dispatch(rejectRoom(room?.room_id, reason, profile));
          portal.close('block');
        }}
      />,
      'block'
    );
  };

  const unblock = () => {
    Tracker.event('chat view menu unblock chat', {
      event_room_id: room?.room_id,
    });

    checkBan();
    dispatch(acceptRoom(room?.room_id));
  };

  const toggleProfile = () => {
    checkAvailable();
    Tracker.event('chat view menu view profile', {
      event_room_id: room?.room_id,
    });

    dispatch(toggleDetailBar());
  };

  return {
    isPending,
    isArchived,
    isBlocked,
    isBlockedBy,
    isBetweenProfessionals,
    isConsultation,
    isPinned,
    isPremium,
    isUnread,
    isDetailBarOpen,
    hasCurrentAppointment,
    toggleProfile,
    setEmptyRecord,
    archive,
    reopen,
    unread,
    read,
    pin,
    unpin,
    unblock,
    block,
    toggleArchive,
    toggleUnread,
    togglePin,
  };
};

export default useRoomActions;
