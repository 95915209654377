const CloudDownload = (props: any) => (
  <svg
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.8633 5.66675C8.1653 5.66675 8.41863 5.89075 8.45597 6.18275L8.46064 6.25675V9.00075L9.18063 8.29941C9.37397 8.11075 9.6653 8.07941 9.8913 8.20475L9.95663 8.24741L10.0173 8.29941C10.2353 8.51075 10.2533 8.84608 10.072 9.07808L10.0173 9.13875L8.28063 10.8307C8.0673 11.0374 7.7373 11.0547 7.50463 10.8821L7.44397 10.8307L5.70663 9.13875C5.4693 8.90808 5.4693 8.53008 5.70663 8.29875C5.91997 8.09208 6.25264 8.07475 6.4853 8.24741L6.54597 8.29941L7.26597 9.00075V6.25675C7.26597 5.98008 7.4593 5.74941 7.7173 5.68475L7.7893 5.67141L7.8633 5.66675ZM8.0013 0.666748C10.24 0.666748 12.08 2.36008 12.3106 4.53808L12.3213 4.66675H12.668C14.098 4.66675 15.262 5.78741 15.3313 7.20008L15.3346 7.33341C15.3346 8.52341 14.6386 9.30675 13.5686 9.70741C12.9793 9.92875 12.4073 10.0001 12.0013 10.0001C11.6333 10.0001 11.3346 9.70141 11.3346 9.33341C11.3346 8.99141 11.592 8.70941 11.9233 8.67141L12.098 8.66341L12.2453 8.65075L12.3353 8.64075C12.5993 8.60741 12.862 8.54875 13.1006 8.45941C13.6973 8.23541 14.0013 7.89341 14.0013 7.33341C14.0013 6.62875 13.4593 6.05475 12.768 6.00341L12.668 6.00008H11.0013V5.00008C11.0013 3.34141 9.65997 2.00008 8.0013 2.00008C6.6993 2.00008 5.56664 2.83541 5.15797 4.04675L5.11597 4.18341L4.9773 4.66675H4.0013C2.89597 4.66675 2.0013 5.56141 2.0013 6.66675C2.0013 7.73275 2.8333 8.60275 3.88397 8.66341L4.0013 8.66675C4.3693 8.66675 4.66797 8.96541 4.66797 9.33341C4.66797 9.70141 4.3693 10.0001 4.0013 10.0001C2.15997 10.0001 0.667969 8.50808 0.667969 6.66675C0.667969 4.87341 2.08197 3.41208 3.85664 3.33675L4.00264 3.33341L4.0673 3.18675C4.74063 1.72675 6.17997 0.734748 7.82997 0.670081L8.0013 0.666748Z"
      fill="currentColor"
    />
  </svg>
);

export default CloudDownload;
