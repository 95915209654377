import Axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

const CRMTracker = {
  event: (eventName, params) => {
    Axios({
      method: 'post',
      url: `${config.services.mediquo.api_url}pro/v1/track`,
      data: {
        ...params,
        event_name: eventName,
      },
      headers: getHeaders(),
    });
  },
};

export default CRMTracker;
