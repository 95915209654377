import useLocalStorageState from 'hooks/useLocalStorageState';
import { useState } from 'react';
import Tracker from 'utils/TrackingCall';

export const Background = {
  Blur: 'blur',
  Image: 'image',
} as const;

export type BackgroundType = typeof Background[keyof typeof Background];

const useControls = () => {
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [backgroundFilter, setBackgroundFilter] = useLocalStorageState<
    BackgroundType | undefined
  >('background-filter', undefined);

  const toggleAudio = () =>
    setIsAudioEnabled((enabled) => {
      if (enabled) {
        Tracker.event('toggle off audio');
      }

      if (!enabled) {
        Tracker.event('toggle on audio');
      }

      return !enabled;
    });

  const toggleVideo = () =>
    setIsVideoEnabled((enabled) => {
      if (enabled) {
        Tracker.event('toggle off video');
      }

      if (!enabled) {
        Tracker.event('toggle on video');
      }

      return !enabled;
    });

  const setBackground = (value: BackgroundType | undefined) => {
    setBackgroundFilter(value);
  };

  return {
    isVideoEnabled,
    isAudioEnabled,
    backgroundFilter,
    toggleAudio,
    toggleVideo,
    setBackground,
  };
};

export default useControls;
