import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useTimer from 'containers/VideoCall/useTimer';
import ToastContainer from 'containers/VideoCall/ToastContainer';
import { CallState } from 'containers/VideoCall/index';
import HangUpIcon from 'containers/VideoCall/icons/HangUpIcon';
import SpinnerIcon from 'containers/VideoCall/icons/SpinnerIcon';
import MicMutedInverseIcon from 'containers/VideoCall/icons/MicMutedInverseIcon';
import MicrophoneIcon from 'containers/VideoCall/icons/MicrophoneIcon';
import MicrophoneOffIcon from 'containers/VideoCall/icons/MicrophoneOffIcon';
import CameraIcon from 'containers/VideoCall/icons/CameraIcon';
import MutedCameraIcon from 'containers/VideoCall/icons/MutedCameraIcon';
import strings from 'containers/VideoCall/strings';
import ErrorBoundary from 'containers/Shared/components/ErrorBoundary';
import classNames from 'classnames';
import Caret from 'components/icons/outline/Caret';
import {
  DropdownHeader,
  DropdownMenu,
  DropdownOption,
  useDropdown,
} from 'containers/Shared/components/Dropdown';
import ReactTooltip from 'react-tooltip';
import BackgroundIcon from 'containers/VideoCall/icons/BackgroundIcon';

const CallView = ({
  title,
  videoActivated,
  audioEnabled,
  videoEnabled,
  receiverAudioEnabled,
  receiverVideoEnabled,
  callState,
  onFinish,
  onToggleAudio,
  onToggleVideo,
  publisher,
  subscriber,
  devices,
  videoSource,
  onCamaraSelect,
}) => {
  const seconds = useTimer({ callState });

  const isFinishedState = ![
    CallState.ON_CALL,
    CallState.DIALING,
    CallState.RECONNECTING,
  ].includes(callState);

  return (
    <ErrorBoundary
      fallback={
        <VideoCallEndPage
          timer={seconds}
          title={title}
          callState={CallState.ERROR}
        />
      }
    >
      {isFinishedState ? (
        <VideoCallEndPage timer={seconds} title={title} callState={callState} />
      ) : (
        <VideoCallPage
          title={title}
          seconds={seconds}
          videoActivated={videoActivated}
          audioEnabled={audioEnabled}
          videoEnabled={videoEnabled}
          receiverAudioEnabled={receiverAudioEnabled}
          receiverVideoEnabled={receiverVideoEnabled}
          callState={callState}
          onFinish={onFinish}
          onToggleAudio={onToggleAudio}
          onToggleVideo={onToggleVideo}
          publisher={publisher}
          subscriber={subscriber}
          devices={devices}
          videoSource={videoSource}
          onCamaraSelect={onCamaraSelect}
        />
      )}
    </ErrorBoundary>
  );
};

const VideoCallPage = ({
  title,
  seconds,
  videoActivated,
  audioEnabled,
  videoEnabled,
  receiverAudioEnabled,
  receiverVideoEnabled,
  callState,
  onFinish,
  onToggleAudio,
  onToggleVideo,
  publisher,
  subscriber,
  devices,
  videoSource,
  onCamaraSelect,
}) => {
  const { t } = useTranslation();
  const avatar = useSelector((state) => state.session?.profile?.avatar);
  const [showCallModal, setShowCallModal] = useState(true);

  return (
    <div className="vc_layout">
      <InitCallModal
        onClose={() => setShowCallModal(false)}
        showModal={showCallModal}
      />
      <div className="vc_toast-container">
        <ToastContainer
          videoActivated={videoActivated}
          audioEnabled={audioEnabled}
          videoEnabled={videoEnabled}
          receiverAudioEnabled={receiverAudioEnabled}
          receiverVideoEnabled={receiverVideoEnabled}
        />
      </div>
      <div
        className={`vc_publisher ${
          callState === CallState.DIALING ? 'calling' : ''
        }`}
      >
        {!videoEnabled && callState !== CallState.DIALING && (
          <img
            className="professional_avatar centered"
            src={avatar}
            alt="Professional Avatar"
          />
        )}
        {!audioEnabled && callState !== CallState.DIALING && (
          <div className="audio_muted">
            <MicMutedInverseIcon />
          </div>
        )}
        {publisher}
      </div>
      <div className="vc_subscriber">
        {(!receiverVideoEnabled ||
          (!videoActivated && callState === CallState.ON_CALL)) && (
          <div className="patient_avatar centered big">{title.charAt(0)}</div>
        )}
        {callState === CallState.RECONNECTING && (
          <div className="vc_reconnecting-box">
            <SpinnerIcon
              alt="Spinner"
              style={{ margin: 'auto', animation: `spin 0.8s linear infinite` }}
            />{' '}
            {t('videocall__reconnecting')}
          </div>
        )}
        {subscriber}
      </div>
      {callState === CallState.ON_CALL && (
        <div className="vc_info">
          <div
            className="vc_info-box bg-black text-white"
            style={{ display: 'flex' }}
          >
            {!receiverAudioEnabled && (
              <div className="audio_muted info">
                <MicMutedInverseIcon />
              </div>
            )}
            {title}
          </div>
          <div style={{ width: '80px', marginLeft: '24px' }}>
            <Timer className="bg-black text-white" seconds={seconds} />
          </div>
        </div>
      )}
      {callState === CallState.DIALING && (
        <div className="vc_patient_info">
          <div>
            <div className="patient_avatar">{title.charAt(0)}</div>
          </div>
          <div className="patient_name">{title}</div>
          <div className="vc_action">{t('videocall__calling')}</div>
        </div>
      )}
      <div className={`vc_controls ${!videoActivated ? 'center' : ''}`}>
        <MicrophoneControl audio={audioEnabled} toggle={onToggleAudio} />
        <button onClick={onFinish} className="btn-control btn-control-finish">
          <HangUpIcon />
        </button>
        <div className={`${!videoActivated ? 'not-visible' : ''}`}>
          <CameraControl
            video={videoEnabled}
            toggle={onToggleVideo}
            devices={devices}
            videoSource={videoSource}
            onCamaraSelect={onCamaraSelect}
          />
        </div>
      </div>
    </div>
  );
};

const VideoCallEndPage = ({ callState, timer, title }) => {
  return (
    <div
      className="vc_patient_info"
      style={{ marginBottom: '80px', color: '#201552' }}
    >
      <div>
        <div className="patient_avatar">{title.charAt(0)}</div>
      </div>
      <div className="patient_name">{title}</div>
      <div className="vc_action">{strings[callState].title}</div>
      {callState === CallState.FINISHED && (
        <div className="vc_timer_wrap">
          <Timer className="bg-black text-white" seconds={timer} />
        </div>
      )}
      <div className="popup">
        <div className={`btn-control ${strings[callState].class}`}>
          {strings[callState].icon}
        </div>
        <div className="popup-action">{strings[callState].text}</div>
      </div>
    </div>
  );
};

const InitCallModal = ({ showModal, onClose }) => {
  const { t } = useTranslation();

  if (!showModal) {
    return null;
  }

  return (
    <div className="fixed inset-x-0 bottom-0 z-10 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
      <div className="fixed inset-0 transition-opacity">
        <div
          onClick={onClose}
          className="absolute inset-0 opacity-75 bg-gray-medium"
        />
      </div>
      <div className="max-w-xl p-10 overflow-hidden transition-all transform bg-white shadow-xl text-dark rounded-24 sm:w-full">
        <div className="flex flex-col items-center text-center">
          <div className="mb-6 text-2xl font-medium text-primary">
            {t('videocall__banner_title')}
          </div>
          <div className="mb-12">{t('videocall__banner_text')}</div>
          <button
            onClick={onClose}
            className="flex items-center h-12 px-6 font-medium text-white uppercase border rounded-lg bg-primary border-primary focus:outline-none hover:bg-primary-dark"
          >
            {t('videocall__banner_button')}
          </button>
        </div>
      </div>
    </div>
  );
};

export const Timer = ({ seconds, className }) => {
  const hours = Math.floor(seconds / 3600);
  const timeLeft = seconds % 3600;
  let minutes = Math.floor(timeLeft / 60).toString();
  minutes = minutes.padStart(2, '0');
  let secondsLeft = (timeLeft % 60).toString();
  secondsLeft = secondsLeft.padStart(2, '0');
  const time = hours
    ? `${hours}:${minutes}:${secondsLeft}`
    : `${minutes}:${secondsLeft}`;

  return (
    <div
      className={classNames(
        'flex rounded max-h-5 items-center w-fit px-1.5',
        className
      )}
    >
      {time}
    </div>
  );
};

export const MicrophoneControl = ({ toggle, audio, className }) => (
  <button
    onClick={toggle}
    className={classNames(
      audio ? 'btn-control' : 'btn-control btn-control-off',
      className
    )}
  >
    {audio ? <MicrophoneIcon className="text-white" /> : <MicrophoneOffIcon />}
  </button>
);

export const CameraControl = ({
  toggle,
  video,
  className,
  onCamaraSelect,
  devices,
  videoSource,
}) => {
  const dropdown = useDropdown();
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex rounded-xl bg-black/50">
        <button
          onClick={toggle}
          className={classNames(
            'w-12 h-[48px] rounded-l-xl flex items-center justify-center ',
            video ? 'text-white' : 'bg-white text-negative',
            className
          )}
        >
          {video ? <CameraIcon /> : <MutedCameraIcon />}
        </button>
        <button
          className="p-2 text-white hover:bg-white/20 rounded-r-xl"
          onClick={() => {
            dropdown.open();
          }}
        >
          <Caret className="text-white w-2.5" />
        </button>
      </div>
      <DropdownMenu
        open={dropdown.isOpen}
        onClose={dropdown.close}
        className="bottom-full -translate-y-2 right-1/3 w-full max-w-[200px]"
      >
        <DropdownHeader className="whitespace-nowrap">
          <span className="text-dark">{t('videocall__select_camara')}</span>
        </DropdownHeader>
        {devices.map((device, index) => (
          <DropdownOption
            key={device.deviceId + device.kind}
            className={classNames(
              'text-xs flex flex-row items-center justify-between',
              {
                'text-primary font-medium text-xs':
                  device.deviceId === videoSource.deviceId,
                'text-dark font-light':
                  device.deviceId !== videoSource.deviceId,
              }
            )}
            onClick={() => {
              onCamaraSelect(device);
              dropdown.close();
            }}
          >
            <span className="truncate">
              {device.label
                ? device.label
                : t('videocall__select_camara_item', {
                    index: index + 1,
                  })}
            </span>
            {device.deviceId === videoSource.deviceId && (
              <div className="rounded-full bg-primary w-1.5 h-1.5 shrink-0 ml-2" />
            )}
          </DropdownOption>
        ))}
      </DropdownMenu>
    </div>
  );
};

export const BackgroundControl = ({
  onClick,
  active = false,
  disabled = false,
  className,
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const { t } = useTranslation();
  return (
    <button
      data-tip={t('videocall__tooltip_background')}
      data-place="top"
      className={classNames(
        'btn-control',
        active && 'btn-control-off',
        disabled && 'opacity-40',
        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <BackgroundIcon
        className={classNames(active ? 'text-black' : 'text-white')}
      />
    </button>
  );
};

export default CallView;
