const Microphone = (props: any) => (
  <svg
    width="14"
    height="19"
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.72676 1.72988L6.82367 1.72635C7.16607 1.71368 7.50752 1.77018 7.82759 1.89247C8.14766 2.01476 8.43979 2.20033 8.68651 2.43809C8.93324 2.67584 9.12949 2.96091 9.26353 3.27624C9.39758 3.59157 9.46667 3.93069 9.46667 4.27333V7.79557C9.46667 8.49654 9.18821 9.1688 8.69255 9.66446C8.19689 10.1601 7.52464 10.4386 6.82367 10.4386C6.1227 10.4386 5.45044 10.1601 4.95478 9.66446C4.45912 9.1688 4.18066 8.49654 4.18066 7.79557V4.37112C4.18066 3.68693 4.44598 3.02939 4.92082 2.53681C5.39566 2.04422 6.04303 1.75496 6.72676 1.72988V1.72988Z"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.82337 13.9147C8.41116 13.9147 9.93392 13.3003 11.0567 12.2067C11.6579 11.621 12.118 10.9236 12.4158 10.1646M6.82337 13.9147C5.23558 13.9147 3.71282 13.3003 2.59008 12.2067C1.98885 11.621 1.52871 10.9236 1.23096 10.1646M6.82337 13.9147V17.1948"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Microphone;
