import React, { useEffect } from 'react';
import {
  ModalConfirm,
  ModalDismiss,
  ModalHeader,
  Modal,
} from 'containers/Shared/components/Modal/Modal';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Api from 'api/index';
import useLazyQuery from 'utils/useLazyQuery';
import { sentenceEdited } from 'containers/Inbox/actions/Session.actions';
import { toast } from 'react-toastify';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import Tracker from 'utils/Tracking';
import { useTranslation } from 'react-i18next';

const EditSentence = ({ sentenceToEdit, onClose }) => {
  const { t } = useTranslation();
  const [ApiEditSentence, isEditing] = useLazyQuery(Api.editSentence);
  const dispatch = useDispatch();
  const {
    register,
    watch,
    formState: { isValid },
  } = useForm({ mode: 'onBlur' });
  const sentence = watch('sentence', sentenceToEdit?.string);

  useEffect(() => {
    Tracker.pageView('sentence edit view');
  }, []);

  const editAndClose = () => {
    if (sentence.length > 0) {
      Tracker.event('sentence edit save');
    }

    isValid &&
      !isEditing &&
      ApiEditSentence({ ...sentenceToEdit, string: sentence.trim() })
        .then(() => {
          dispatch(
            sentenceEdited({ ...sentenceToEdit, string: sentence.trim() })
          );
          onClose && onClose();
        })
        .catch(() =>
          toast.error(
            <ErrorToast>{t('sentence__edit_modal_error')}</ErrorToast>
          )
        );
  };

  const dismissModal = () => {
    Tracker.event('sentence edit dismiss');
    onClose();
  };

  const cancelEdit = () => {
    Tracker.event('sentence edit cancel');
    onClose();
  };

  return (
    <Modal onClose={dismissModal}>
      <ModalHeader onClose={dismissModal}>
        {t('sentence__edit_modal_title')}
      </ModalHeader>
      <form onSubmit={(event) => event.preventDefault()}>
        <div className="px-6 py-4 bg-white">
          <div className="mb-5 text-xs text-primary">
            {t('sentence__edit_modal_subtitle')}
          </div>
          <div className="relative">
            <textarea
              ref={register({ required: true })}
              name="sentence"
              defaultValue={sentenceToEdit?.string}
              maxLength={280}
              className="w-full p-4 border rounded-md border-separators"
              placeholder={t('sentence__edit_modal_form_placeholder')}
              rows="6"
            />
            <span className="absolute bottom-4 right-4 text-gray-medium">
              {sentence?.length || 0} / 280
            </span>
          </div>
        </div>
        <div className="px-4 py-5 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
          <ModalConfirm onClick={editAndClose}>
            {t('sentence__edit_modal_submit')}
          </ModalConfirm>
          <ModalDismiss onClose={cancelEdit}>
            {t('sentence__edit_modal_cancel')}
          </ModalDismiss>
        </div>
      </form>
    </Modal>
  );
};

export default EditSentence;
