import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

export default ({ callStartedAt }) => {
  const intervalTimerRef = useRef();
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (callStartedAt) {
      const diff = dayjs().diff(dayjs(callStartedAt), 'second');
      setSeconds(diff);
    }
  }, [callStartedAt]);

  useEffect(() => {
    intervalTimerRef.current = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);

    return () =>
      intervalTimerRef.current && clearInterval(intervalTimerRef.current);
  }, []);

  return seconds;
};
