import React from 'react';
import {
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  ModalConfirm,
  ModalDismiss,
} from 'containers/Shared/components/Modal/Modal';
import { useDispatch } from 'react-redux';
import { acceptRoom } from 'containers/Room/actions/PendingPatient.actions';
import { useTranslation, Trans } from 'react-i18next';

const UnlockRoomModal = ({ room, show, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!show) {
    return null;
  }

  const acceptRoomStatus = () => {
    dispatch(acceptRoom(room?.room_id)).then(() => {
      onClose(true);
    });
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose}>
        {t('room__unblock_modal_title')}
      </ModalHeader>
      <ModalBody>
        <div className="mb-4">
          <p className="text-md text-primary">
            <Trans i18nKey="room__unblock_modal_text" title={room?.title}>
              Desbloquear la consulta de <strong>{room?.title}</strong> para
              enviarle un mensaje
            </Trans>
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalConfirm onClick={acceptRoomStatus}>
          {t('room__unblock_modal_confirm')}
        </ModalConfirm>
        <ModalDismiss onClose={onClose}>
          {t('room__unblock_modal_cancel')}
        </ModalDismiss>
      </ModalFooter>
    </Modal>
  );
};

export default UnlockRoomModal;
