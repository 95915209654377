import React from 'react';

export default () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0646 5.0003L15.9978 5C11.2898 5.073 7.36955 8.18702 6.26958 12.7325C5.63946 15.3158 6.12626 18.1123 7.60114 20.4067C9.07412 22.7002 11.4137 24.3033 14.0282 24.8054C14.7013 24.9346 15.3766 25 16.038 25C17.6277 25 19.1666 24.6198 20.6049 23.8736C20.6786 23.8411 20.7393 23.8269 20.8095 23.8269C20.8549 23.8269 20.9041 23.8316 20.9619 23.8423L24.4073 24.746C25.1519 24.9407 25.8281 24.2567 25.6248 23.5144L25.0497 21.4221C24.9369 21.0157 24.8264 20.6211 24.7173 20.2349C24.6774 20.0522 24.692 19.9386 24.7753 19.7912C26.3066 16.9626 26.4039 13.6836 25.0521 10.7854C23.697 7.87921 21.0999 5.83807 17.94 5.19432C17.3668 5.0849 16.7267 5.01944 16.0646 5.0003ZM16.0357 6.99989C16.5666 7.01566 17.1014 7.07035 17.5529 7.15649C20.0807 7.67153 22.1528 9.30003 23.2395 11.6307C24.2803 13.8623 24.2506 16.359 23.1661 18.551L23.0251 18.8234C22.6997 19.3992 22.6197 20.0212 22.7797 20.7276L23.2373 22.373L21.3956 21.8912C21.1492 21.8432 20.9794 21.8269 20.8095 21.8269C20.441 21.8269 20.0984 21.907 19.7278 22.0768C18.5283 22.6978 17.3051 23 16.038 23C15.5043 23 14.9552 22.9468 14.4054 22.8413C12.333 22.4433 10.4633 21.1622 9.28376 19.3256C8.10249 17.4879 7.71344 15.2529 8.21305 13.2046C9.0773 9.63331 12.0821 7.1778 15.7586 7.00809L16.0357 6.99989Z"
      fill="#4A1EA7"
    />
    <path
      d="M20.6413 11.9395L20.7257 12.0216C21.0914 12.3774 21.0914 12.9521 20.7257 13.3078L15.4937 18.407C15.128 18.7628 14.5373 18.7628 14.1716 18.407L11.2743 15.5883C10.9086 15.2326 10.9086 14.6579 11.2743 14.3021L11.3586 14.22C11.7243 13.8643 12.315 13.8643 12.6807 14.22L14.8373 16.3181L19.3193 11.9486C19.6756 11.5838 20.2757 11.5838 20.6413 11.9395Z"
      fill="#A48ED2"
    />
  </svg>
);
