import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // There are no Hook equivalent yet.
  // https://reactjs.org/docs/hooks-faq.html#from-classes-to-hooks
  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo });
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children, fallback } = this.props;

    return <>{hasError ? fallback : children}</>;
  }
}

export default ErrorBoundary;
