import React from 'react';

export default () => (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0H32V32H0z"
              transform="translate(-32 -488) translate(0 464) translate(32 24)"
            />
            <path
              fill="#4A1FA5"
              fillRule="nonzero"
              d="M5.333 24c0-3.64 7.494-5.453 11.134-5.32-.294.827-.467 1.72-.467 2.653-3.556 0-6.222.89-8 2.667h8.467c.346 1 .893 1.893 1.586 2.667H8c-1.473 0-2.667-1.194-2.667-2.667zM16 5.333c2.947 0 5.333 2.387 5.333 5.334C21.333 13.613 18.947 16 16 16s-5.333-2.387-5.333-5.333c0-2.947 2.386-5.334 5.333-5.334zM16 8c-1.467 0-2.667 1.2-2.667 2.667 0 1.466 1.2 2.666 2.667 2.666 1.467 0 2.667-1.2 2.667-2.666C18.667 9.2 17.467 8 16 8z"
              transform="translate(-32 -488) translate(0 464) translate(32 24)"
            />
            <path
              fill="#A48ED2"
              fillRule="nonzero"
              d="M19.174 17.338c-.255.336-.23.815.077 1.122l2.79 2.79-2.79 2.79c-.335.334-.335.875 0 1.21l.087.076c.336.255.815.23 1.122-.077l2.79-2.79 2.79 2.79c.334.335.875.335 1.21 0l.076-.087c.255-.336.23-.815-.077-1.122l-2.79-2.79 2.79-2.79c.328-.328.328-.875 0-1.203l-.087-.077c-.336-.255-.815-.23-1.122.077l-2.79 2.784-2.79-2.79c-.334-.335-.875-.335-1.21 0l-.076.087z"
              transform="translate(-32 -488) translate(0 464) translate(32 24)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
