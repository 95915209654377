import { useEffect } from 'react';
import { isFirefox, isEdge, isChrome } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import * as Sentry from '@sentry/browser';
import { isMobile } from 'utils/deviceDetect';
import ConsultationsApiClient from 'legacy/Services/Consultations';
import Tracker from 'utils/Tracking';
import { isOnboardingCompleted as sessionIsOnboardingCompleted } from 'containers/Inbox/reducers/session.reducer';
import { connectSocket } from 'socket';
import useSocketSubscriber from 'socket/useSocketSubscriber';
import usePushNotifications from 'notifications/usePushNotifications';
import NotOptimizedForMobilePage from 'containers/Login/NotOptimizedForMobilePage';
import { NotOptimizedForBrowserPage } from 'containers/Shared/components/NoOptimizedBrowser';
import { getAllWaitingRoom } from 'containers/WaitingRoom/actions/GetWaitingRoom.actions';
import useScreenSize from 'utils/useScreenSize';

const AuthenticationGate = ({ children }) => {
  const token = useSelector((state) => state.auth.jwtToken);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isOnboardingCompleted = useSelector(sessionIsOnboardingCompleted);
  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const waitingRoomAccess = useSelector(
    (state) => state.waitingRoom.accessData
  );
  const waitingRoomExits = useSelector((state) => state.waitingRoom.exitData);
  const { width } = useScreenSize();

  connectSocket();
  useSocketSubscriber();
  usePushNotifications();
  ConsultationsApiClient.updateHeaders({ Authorization: `Bearer ${token}` });

  useEffect(() => {
    waitingRoomAccess.forEach((waitingRoomElement) => {
      const actionType =
        waitingRoomElement.originRequest === 'socket'
          ? 'debug socket'
          : 'debug';
      Tracker.debug(
        'waiting room patient access',
        {
          consultation_id: waitingRoomElement.consultation_id,
          consultation_type: waitingRoomElement.consultation_type,
        },
        actionType
      );
    });
  }, [waitingRoomAccess]);

  useEffect(() => {
    waitingRoomExits.forEach((waitingRoomElement) => {
      const actionType =
        waitingRoomElement.originRequest === 'socket'
          ? 'debug socket'
          : 'debug';
      Tracker.debug(
        'waiting room patient exit',
        {
          consultation_id: waitingRoomElement.consultation_id,
          consultation_type: waitingRoomElement.consultation_type,
        },
        actionType
      );
    });
  }, [waitingRoomExits]);

  useEffect(() => {
    if (session?.profile?.hash) {
      Sentry.setUser({ id: session.profile.hash });
      Tracker.setUser({
        userHash: session.profile.hash,
        professionalStatus: session.profile.validation_status,
        installationId: localStorage.getItem('sessionId'),
      });
    }
  }, [session?.profile?.hash]);

  useEffect(() => {
    if (session?.profile?.is_immediate_consultant) {
      setInterval(() => {
        dispatch(getAllWaitingRoom());
      }, 60000);
    }
  }, [session?.profile?.hash]);

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (!isOnboardingCompleted) {
    return <Redirect to="/onboarding" />;
  }

  if (isMobile() || width < 700) {
    return <NotOptimizedForMobilePage />;
  }

  if (!isFirefox && !isEdge && !isChrome) {
    return <NotOptimizedForBrowserPage />;
  }

  return children;
};

export default AuthenticationGate;
