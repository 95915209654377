import React from 'react';
import i18n from 'i18n';

const CrossIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M17.4004 0.613233C16.8804 0.093233 16.0404 0.093233 15.5204 0.613233L9.00045 7.1199L2.48045 0.5999C1.96045 0.0798999 1.12045 0.0798999 0.600449 0.5999C0.0804492 1.1199 0.0804492 1.9599 0.600449 2.4799L7.12045 8.9999L0.600449 15.5199C0.0804492 16.0399 0.0804492 16.8799 0.600449 17.3999C1.12045 17.9199 1.96045 17.9199 2.48045 17.3999L9.00045 10.8799L15.5204 17.3999C16.0404 17.9199 16.8804 17.9199 17.4004 17.3999C17.9204 16.8799 17.9204 16.0399 17.4004 15.5199L10.8805 8.9999L17.4004 2.4799C17.9071 1.97323 17.9071 1.1199 17.4004 0.613233Z"
      fill="white"
    />
  </svg>
);

const VideoRejectedIcon = () => (
  <svg width="27" height="20" viewBox="0 0 27 20" fill="none">
    <path
      d="M15.9196 0.666595C18.0403 0.666595 19.7596 2.45746 19.7596 4.6666V6.17593L21.5949 4.18237C22.5107 3.187 24.0125 3.0575 25.079 3.84281L25.2526 3.98218C25.8255 4.48296 26.1596 5.21223 26.1596 5.98547V14.0144C26.1596 15.4997 24.9623 16.6666 23.5264 16.6666C22.7965 16.6666 22.0955 16.3616 21.5949 15.8175L19.7596 13.8226V15.3333C19.7596 17.5424 18.0403 19.3333 15.9196 19.3333H4.39957C2.2788 19.3333 0.55957 17.5424 0.55957 15.3333V4.6666C0.55957 2.45746 2.2788 0.666595 4.39957 0.666595H15.9196ZM15.9196 3.33326H4.39957C3.74314 3.33326 3.20212 3.84798 3.12818 4.5111L3.11957 4.6666V15.3333C3.11957 16.017 3.6137 16.5806 4.2503 16.6576L4.39957 16.6666H15.9196C16.576 16.6666 17.117 16.1519 17.191 15.4888L17.1996 15.3333V4.6666C17.1996 3.98282 16.7054 3.41925 16.0688 3.34223L15.9196 3.33326ZM23.5684 6.00536C23.5237 5.99305 23.4665 6.00213 23.4431 6.02754L19.7877 9.99992L23.4431 13.9723C23.4516 13.9816 23.4685 13.9908 23.4903 13.9959L23.5264 13.9999C23.5851 13.9999 23.5996 13.9859 23.5996 14.0144V6.02126L23.5684 6.00536Z"
      fill="#FF014D"
    />
  </svg>
);

const CheckIcon = () => (
  <svg width="30" height="23" viewBox="0 0 30 23" fill="none">
    <path
      d="M9.85933 18.1485L3.91076 12.1999C3.24219 11.5313 2.16219 11.5313 1.49362 12.1999C0.825045 12.8685 0.825045 13.9485 1.49362 14.6171L8.65933 21.7828C9.3279 22.4513 10.4079 22.4513 11.0765 21.7828L29.2136 3.64562C29.8822 2.97705 29.8822 1.89705 29.2136 1.22848C28.545 0.559908 27.465 0.559908 26.7965 1.22848L9.85933 18.1485Z"
      fill="white"
    />
  </svg>
);

const CallRejectedIcon = () => (
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none">
    <path
      d="M15.7843 14.0503L15.5875 14.2369L13.0236 11.673L13.0542 11.6424C13.9069 10.7897 14.3219 9.59783 14.1842 8.39754L13.933 6.21465C13.6953 4.21394 12.0113 2.71376 10.0021 2.71376L8.504 2.71376C6.25379 2.71376 4.41365 4.59773 4.55415 6.86588C5.08237 15.3772 11.8858 22.1776 20.3941 22.7063C22.6627 22.8468 24.5468 21.0067 24.5468 18.7565L24.5468 17.2584C24.5625 15.6649 23.5342 14.508 21.8683 13.595L21.4858 13.3854L18.853 13.0848C17.7339 12.9564 16.621 13.3094 15.7843 14.0503ZM10.0021 4.93598C10.8301 4.93598 11.5288 5.51441 11.6985 6.30736L11.7253 6.46871L11.9764 8.65089C12.0303 9.12042 11.8926 9.58312 11.5998 9.94166L11.4522 10.1017C10.6301 10.9238 10.5868 12.2299 11.3224 13.103L11.4522 13.2444L14.0161 15.8083L14.1574 15.9381C14.982 16.6328 16.1928 16.6328 17.0174 15.9381L17.1807 15.7863C17.5117 15.4553 17.959 15.2748 18.4253 15.2814L18.6009 15.2927L20.8004 15.5438C21.7492 16.0638 22.255 16.5937 22.3178 17.1336L22.3244 17.2584L22.3244 18.7565C22.3244 19.735 21.5104 20.549 20.5319 20.4884C13.1454 20.0294 7.23107 14.1237 6.77212 6.72849C6.7147 5.80147 7.44223 5.02212 8.35097 4.94265L8.504 4.93598L10.0021 4.93598Z"
      fill="#FF014D"
    />
  </svg>
);

export default {
  discarded: {
    icon: <CheckIcon />,
    class: 'btn-control-success',
    title: i18n.t('videocall__discarded_title'),
    text: i18n.t('videocall__discarded_text'),
  },
  finished: {
    icon: <CheckIcon />,
    class: 'btn-control-success',
    title: i18n.t('videocall__finished_title'),
    text: i18n.t('videocall__finished_text'),
  },
  rejected: {
    icon: <CrossIcon />,
    title: i18n.t('videocall__rejected_title'),
    class: 'btn-control-finish',
    text: i18n.t('videocall__rejected_text'),
  },
  communicating: {
    icon: <CrossIcon />,
    title: i18n.t('videocall__communicating_title'),
    class: 'btn-control-finish',
    text: i18n.t('videocall__communicating_text'),
  },
  missed_call: {
    icon: <CrossIcon />,
    title: i18n.t('videocall__missed_call_title'),
    class: 'btn-control-finish',
    text: i18n.t('videocall__missed_call_text'),
  },
  permissions_failed: {
    icon: <CallRejectedIcon />,
    title: i18n.t('videocall__permissions_failed_title'),
    class: 'btn-control-rejected',
    text: i18n.t('videocall__permissions_failed_text'),
  },
  video_permissions_failed: {
    icon: <VideoRejectedIcon />,
    title: i18n.t('videocall__video_permissions_failed_title'),
    class: 'btn-control-rejected',
    text: i18n.t('videocall__video_permissions_failed_text'),
  },
  error: {
    icon: <CrossIcon />,
    title: i18n.t('videocall__error_title'),
    class: 'btn-control-finish',
    text: i18n.t('videocall__error_text'),
  },
};
