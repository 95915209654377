import { useEffect, useState } from 'react';
import ConsultationsApiClient from 'legacy/Services/Consultations';
import { useLocation, useParams } from 'react-router';
import { createSession } from 'containers/VideoCall/CallStream';
import { config as appConfig } from 'config';
const useQueryParams = () => {
  const query = new URLSearchParams(useLocation().search);

  const videoActivated = query.get('type') === 'video';
  const isPickup = query.get('mode') === 'pickup';
  const sessionId = query.get('sessionId');
  const token = query.get('token');
  const uuid = query.get('uuid');
  const title = query.get('title');

  return { videoActivated, isPickup, sessionId, token, uuid, title };
};

export default ({ onCallError }) => {
  const {
    videoActivated,
    isPickup,
    title,
    sessionId: sessionIdParam,
    token: tokenParam,
    uuid: uuidParam,
  } = useQueryParams();
  const { roomId } = useParams();

  const [config, setConfig] = useState();
  const [session, setSession] = useState();
  const [streams, setStreams] = useState();

  useEffect(() => {
    const pickup = () =>
      ConsultationsApiClient.chat.pickup(uuidParam).then((_) => ({
        sessionId: sessionIdParam,
        token: tokenParam,
        uuid: uuidParam,
      }));

    const call = () =>
      (videoActivated
        ? ConsultationsApiClient.chat.videoCall(roomId)
        : ConsultationsApiClient.chat.call(roomId)
      ).then((response) => ({
        sessionId: response.data.data.session.session_id,
        token: response.data.data.session.professional_token,
        uuid: response.data.data.call.uuid,
      }));

    if (!config) {
      (isPickup ? pickup() : call())
        .then(setConfig)
        .catch((_) => onCallError && onCallError());
    }
  }, [
    config,
    roomId,
    isPickup,
    videoActivated,
    sessionIdParam,
    tokenParam,
    uuidParam,
  ]);

  useEffect(() => {
    if (session || !config) {
      return;
    }

    setSession(
      createSession({
        apiKey: appConfig.services.tokbox.api_key,
        sessionId: config.sessionId,
        token: config.token,
        onStreamsUpdated: setStreams,
      }).session
    );
  }, [config, session]);

  return { session, streams, videoActivated, title, uuid: config?.uuid };
};
