import ConsultationsApiClient from 'legacy/Services/Consultations';
import Tracker from 'utils/Tracking';
import api from 'api/index';
import { setBilling } from 'state/kyc.slice';

const KYC_VALIDATED = 'SESSION/KYC_VALIDATED';
const CONSOLE_UPDATED = 'SESSION/CONSOLE_UPDATED';
const CONSOLE_REFRESHED = 'SESSION/CONSOLE_REFRESHED';
const FETCHING_PROFILE = 'SESSION/FETCHING_PROFILE';
const PROFILE_FETCHED = 'SESSION/PROFILE_FETCHED';
const PROFILE_UPDATED = 'SESSION/PROFILE_UPDATED';
const PROFILE_REFRESHED = 'SESSION/PROFILE_REFRESHED';
const CHANGE_CHAT_PROFESSIONAL_STATUS =
  'SESSION/CHANGE_CHAT_PROFESSIONAL_STATUS';
const CHANGE_VIDEOCALL_PROFESSIONAL_STATUS =
  'SESSION/CHANGE_VIDEOCALL_PROFESSIONAL_STATUS';
const SENTENCES_FETCHED = 'SESSION/SENTENCES_FETCHED';
const SENTENCE_DELETED = 'SESSION/SENTENCE_DELETED';
const SENTENCE_ADDED = 'SESSION/SENTENCE_ADDED';
const SENTENCE_EDITED = 'SESSION/SENTENCE_EDITED';
const PRESCRIPTION_VALIDATION_UPDATED =
  'SESSION/PRESCRIPTION_VALIDATION_UPDATED';

export const actionTypes = {
  KYC_VALIDATED,
  CONSOLE_UPDATED,
  CONSOLE_REFRESHED,
  CHANGE_CHAT_PROFESSIONAL_STATUS,
  CHANGE_VIDEOCALL_PROFESSIONAL_STATUS,
  PROFILE_FETCHED,
  PROFILE_UPDATED,
  FETCHING_PROFILE,
  PROFILE_REFRESHED,
  SENTENCES_FETCHED,
  SENTENCE_DELETED,
  SENTENCE_ADDED,
  SENTENCE_EDITED,
  PRESCRIPTION_VALIDATION_UPDATED,
};

export const refreshProfile = () => {
  return (dispatch) => {
    dispatch(fetchingProfile());
    api.profile.chat.get().then(({ data: profile }) => {
      api.profile.billing.get().then((data) => {
        dispatch(setBilling(data.data.data));
      });
      api.profile.get().then(({ data }) => {
        dispatch(
          profileFetched(profile.data, {
            token: localStorage.getItem('jwtToken'),
            profile: data.data,
          })
        );
      });
      ConsultationsApiClient.sentence
        .index()
        .then(({ data }) => dispatch(sentencesFetched(data)));
    });
  };
};

export const changeChatStatus = (status) => {
  return (dispatch) => {
    return api.profile.status.chat({ status }).then(() => {
      const professionalStatus =
        status === 1 ? 'chats view connect' : 'chats view disconnect';

      Tracker.event(professionalStatus);
      dispatch(changeChatProfessionalStatus(status));
    });
  };
};

export const changeVideoCallStatus = (status) => {
  return (dispatch) => {
    return api.profile.status.videocall({ status }).then(() => {
      const professionalStatus =
        status === 1 ? 'videocall view connect' : 'videocall view disconnect';

      Tracker.event(professionalStatus);
      dispatch(changeVideoCallProfessionalStatus(status));
    });
  };
};

export const fetchingProfile = () => ({
  type: FETCHING_PROFILE,
});

export const updateAvailable = () => ({
  type: CONSOLE_UPDATED,
});

export const updateDownloaded = () => ({
  type: CONSOLE_REFRESHED,
});

export const profileUpdated = (profile) => ({
  type: PROFILE_UPDATED,
  payload: profile,
});

export const updatePrescriptionValidationStatus = ({ status }) => ({
  type: PRESCRIPTION_VALIDATION_UPDATED,
  payload: status,
});

export const KYCValidated = () => ({
  type: KYC_VALIDATED,
});

export const profileFetched = (
  profile,
  { token, profile: professionalProfile }
) => {
  return {
    type: PROFILE_FETCHED,
    payload: {
      ...profile,
      jwt: token,
      public_profile_pdf: professionalProfile.public_profile_pdf,
      public_profile_url: professionalProfile?.share?.public_profile_url,
      code: professionalProfile?.code,
      title: professionalProfile?.title,
      firstName: professionalProfile?.first_name,
      lastName: professionalProfile?.last_name,
      email: professionalProfile?.email,
      description: professionalProfile?.description,
      gender: professionalProfile?.gender,
      kyc_status: professionalProfile?.kyc_status,
      features: professionalProfile?.features,
      speciality_id: professionalProfile?.speciality_id,
      speciality_alias: professionalProfile?.speciality_alias,
      payment_provider: professionalProfile?.payment_provider,
      currency: professionalProfile?.currency,
      workPracticeCountry: professionalProfile?.work_practice_country,
      has_immediate_service: !!professionalProfile?.has_immediate_service,
      is_attending_consultations: !!profile?.is_attending_consultations,
      is_immediate_consultant: !!professionalProfile?.is_immediate_consultant,
      collegiateNumber: professionalProfile?.collegiate_number,
      college: professionalProfile?.college,
      region: professionalProfile?.region,
      organization: professionalProfile?.organization,
      license: professionalProfile?.license,
      timezone: professionalProfile?.timezone,
      work: {
        centerName: professionalProfile?.work?.work_center_name,
        address: professionalProfile?.work?.work_address,
        city: professionalProfile?.work?.work_city,
        postalCode: professionalProfile?.work?.work_postal_code,
        taxId: professionalProfile?.work?.work_tax_id,
      },
    },
  };
};

export const sentencesFetched = (sentences) => ({
  type: SENTENCES_FETCHED,
  payload: sentences,
});

export const sentenceDeleted = (sentence) => ({
  type: SENTENCE_DELETED,
  payload: sentence,
});

export const sentenceAdded = (sentence) => ({
  type: SENTENCE_ADDED,
  payload: sentence,
});

export const sentenceEdited = (sentence) => ({
  type: SENTENCE_EDITED,
  payload: sentence,
});

export const profileRefreshed = (profile) => ({
  type: PROFILE_REFRESHED,
  payload: profile,
});

export const changeChatProfessionalStatus = (status) => ({
  type: CHANGE_CHAT_PROFESSIONAL_STATUS,
  payload: status,
});

export const changeVideoCallProfessionalStatus = (status) => ({
  type: CHANGE_VIDEOCALL_PROFESSIONAL_STATUS,
  payload: status,
});
