import axios, { AxiosPromise } from 'axios';
import getHeaders from 'utils/getHeaders';
import { DownloadRequest, Report } from 'api/reports.d';
import { config } from 'config';

const API = 'professionals/v1';
const BASE_URL = `${config.services.mediquo.chat_url}/${API}/customers/`;

const create = ({
  patient_hash,
  objective_data,
  subjective_data,
  analysis,
  plan,
  is_public,
  consultation_type,
  consultation_id,
  diagnostic_id,
}: {
  patient_hash: string;
  consultation_type?: string;
  consultation_id?: string;
} & Pick<
  Report,
  | 'objective_data'
  | 'subjective_data'
  | 'analysis'
  | 'plan'
  | 'is_public'
  | 'diagnostic_id'
>): AxiosPromise<{
  data: { report_uuid: string };
}> =>
  axios({
    method: 'POST',
    url: `${BASE_URL}${patient_hash}/reports`,
    headers: getHeaders(),
    data: {
      objective_data,
      subjective_data,
      analysis,
      diagnostic_id,
      plan,
      is_public,
      consultation_type,
      consultation_id,
    },
  });

const download = ({
  patient_hash,
  report_uuid,
}: DownloadRequest): AxiosPromise<Blob> =>
  axios({
    method: 'GET',
    url: `${BASE_URL}${patient_hash}/reports/${report_uuid}/download`,
    headers: getHeaders(),
    responseType: 'blob',
  });

export default {
  BASE_URL,
  create,
  download,
};
