import { Trans } from 'react-i18next';
import ChatBadge from 'components/icons/outline/ChatBadge';

const MarkRoomAsUnread = ({ rooms }) => (
  <div className="flex items-center justify-center text-positive">
    <ChatBadge className="h-5 w-5" />
    <div className="ml-3">
      <Trans i18nKey="toast__mark_as_unread_text" count={rooms.length}>
        Has marcado como no leído
        <span className="font-medium lowercase">
          {` ${rooms.length} consultas.`}
        </span>
      </Trans>
    </div>
  </div>
);

export default MarkRoomAsUnread;
