import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Contact, useGetAllContactsQuery } from 'services/contacts';

const useContacts = ({ enabled, with_accepted_invitation }: { enabled?: boolean, with_accepted_invitation?: boolean }) => {
  const { t } = useTranslation();
  const [paginatedContacts, setPaginatedContacts] = useState<Contact[][]>([[]]);
  const [state, setState] = useState<{ term: string; page: number }>({
    term: '',
    page: 1,
  });

  const {
    data: paginatedResult,
    isUninitialized,
    isLoading,
    isFetching,
    isError,
  } = useGetAllContactsQuery(
    { term: state.term, page: state.page, per_page: 30, with_accepted_invitation },
    {
      skip: !enabled,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    isError &&
      toast.error(
        <ErrorToast>{t('shared__errors_generic_message')}</ErrorToast>
      );
  }, [isError]);

  useEffect(() => {
    const nextPageIndex = (paginatedResult?.meta.current_page ?? 1) - 1;
    const nextPageContacts = paginatedResult?.data ?? [];

    if (!nextPageIndex) {
      setPaginatedContacts([nextPageContacts]);
      return;
    }

    setPaginatedContacts((contacts) => {
      const nextContacts = [...contacts];
      nextContacts[nextPageIndex] = nextPageContacts;
      return nextContacts;
    });
  }, [paginatedResult]);

  const searchByTerm = (term: string) => setState({ term, page: 1 });

  const more = () =>
    paginatedResult?.meta.hasMore &&
    setState((state) => ({ ...state, page: state.page + 1 }));

  const status =
    isUninitialized || isLoading
      ? 'loading'
      : paginatedContacts?.flat()?.length
      ? 'success'
      : state.term
      ? 'empty-search'
      : 'empty';

  return {
    contacts: paginatedContacts.flat(),
    status,
    searchByTerm,
    term: state.term,
    more,
    isLoading: isLoading || isFetching,
  };
};

export default useContacts;
