const ChatSquare = (props: any) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6693 3C14.6693 2.44772 14.2216 2 13.6693 2H10.502H2.66927C1.93594 2 1.33594 2.6 1.33594 3.33333V13.5858C1.33594 14.4767 2.41308 14.9229 3.04305 14.2929L4.0026 13.3333H13.3359C14.0693 13.3333 14.6693 12.7333 14.6693 12V3ZM2.66925 3.49999H10.5019H13.3359L13.3366 12H2.66992L2.66925 3.49999ZM4.50195 5C4.22581 5 4.00195 5.22386 4.00195 5.5C4.00195 5.77614 4.22581 6 4.50195 6H11.502C11.7781 6 12.002 5.77614 12.002 5.5C12.002 5.22386 11.7781 5 11.502 5H4.50195ZM4.00195 7.5C4.00195 7.22386 4.22581 7 4.50195 7H11.502C11.7781 7 12.002 7.22386 12.002 7.5C12.002 7.77614 11.7781 8 11.502 8H4.50195C4.22581 8 4.00195 7.77614 4.00195 7.5ZM4.50195 9C4.22581 9 4.00195 9.22386 4.00195 9.5C4.00195 9.77614 4.22581 10 4.50195 10H11.502C11.7781 10 12.002 9.77614 12.002 9.5C12.002 9.22386 11.7781 9 11.502 9H4.50195Z"
      fill="currentColor"
    />
  </svg>
);

export default ChatSquare;
