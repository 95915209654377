import Button from 'components/Button';
import Form from 'components/Form';
import Modal from 'components/Modal';
import { updateContactName } from 'containers/Contacts/actions/Contact.actions';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const EditPatientModal = ({ onClose, roomId, roomTitle, contactId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { patient } = useSelector((state) => state.patientBar);

  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid, isSubmitted, dirtyFields },
  } = useForm({ mode: 'onChange' });

  const onSubmit = (formData) => {
    dispatch(
      updateContactName({ contact_id: contactId, room_id: roomId, ...formData })
    );
    setTimeout(() => onClose(), 200);
  };

  return (
    <Modal onClose={onClose} width="huge" position="center" rounded="small">
      {({ onClose }) => (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header onClose={onClose}>
            {t('room__banner_edit_new_patient_modal_title')}
          </Modal.Header>
          <Modal.Body>
            <h3 className="text-xs text-gray-dark mb-2">
              {t('room__banner_edit_new_patient_modal_subtitle')}
            </h3>
            <Form.Group>
              <Form.Field
                type="text"
                defaultValue={roomTitle}
                name="name"
                label={t('room__banner_edit_new_patient_modal_name_label')}
                isDirty={dirtyFields.name}
                placeholder={t(
                  'room__banner_edit_new_patient_modal_name_placeholder'
                )}
                register={register({
                  required: t('room__banner_edit_new_patient_modal_name_error'),
                })}
                errors={errors.name}
              />
              <Form.Field
                type="text"
                info={t('room__banner_edit_new_patient_modal_tag_info')}
                name="external_id"
                defaultValue={patient?.tag_id}
                isDirty={dirtyFields.external_id}
                label={t('room__banner_edit_new_patient_modal_tag_placeholder')}
                placeholder={t(
                  'room__banner_edit_new_patient_modal_tag_placeholder'
                )}
                register={register()}
              />
              <Form.Field
                type="email"
                name="email"
                defaultValue={patient?.email}
                isDirty={dirtyFields.email}
                label="Email"
                placeholder="Email"
                register={register()}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer
            disclamer={t('room__banner_edit_new_patient_modal_help_text')}
          >
            <>
              <Button type="submit" disabled={!isValid || isSubmitted}>
                {t('room__banner_edit_new_patient_modal_save')}
              </Button>
            </>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
};

export default EditPatientModal;
