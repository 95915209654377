const Pause = (props: any) => (
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.87207 14.1621C3.97207 14.1621 4.87207 13.2621 4.87207 12.1621V2.16211C4.87207 1.06211 3.97207 0.162109 2.87207 0.162109C1.77207 0.162109 0.87207 1.06211 0.87207 2.16211V12.1621C0.87207 13.2621 1.77207 14.1621 2.87207 14.1621ZM8.87207 2.16211V12.1621C8.87207 13.2621 9.77207 14.1621 10.8721 14.1621C11.9721 14.1621 12.8721 13.2621 12.8721 12.1621V2.16211C12.8721 1.06211 11.9721 0.162109 10.8721 0.162109C9.77207 0.162109 8.87207 1.06211 8.87207 2.16211Z"
      fill="currentColor"
    />
  </svg>
);
export default Pause;
