import ArrowDownRecipe from 'components/icons/ArrowDownRecipe';
import { FC } from 'react';
import classnames from 'classnames';
import Spinner from 'components/Spinner';

export interface Props {
  state?: 'initial' | 'loading' | 'success';
  className?: string;
  onClick?: () => void;
}

const Download: FC<Props> = ({
  state = 'initial',
  className = '',
  onClick = () => {},
}) => (
  <div
    className={classnames(
      'relative flex items-center justify-center',
      className
    )}
  >
    {state === 'loading' && (
      <div className="absolute">
        <Spinner sizeClassName="w-12 h-12" strokeWidth={3} />
      </div>
    )}
    <button
      onClick={onClick}
      disabled={state === 'loading'}
      className={classnames(
        'absolute grid w-8 h-8 m-2 rounded-full place-items-center',
        {
          initial: 'hover:text-dark hover:bg-gray-light text-gray-dark',
          loading: 'bg-gray-light text-dark',
          success: 'bg-primary text-white',
        }[state]
      )}
    >
      <ArrowDownRecipe />
    </button>
  </div>
);

export default Download;
