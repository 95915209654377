const InfoCircle = (props: any) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.01 22C17.53 22 22 17.52 22 12C22 6.48 17.53 2 12.01 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12.01 22Z"
      fill="currentColor"
    />
    <path
      d="M10.833 16C10.833 16.55 11.283 17 11.833 17C12.383 17 12.833 16.55 12.833 16L12.833 12C12.833 11.45 12.383 11 11.833 11C11.283 11 10.833 11.45 10.833 12L10.833 16Z"
      fill="white"
    />
    <path d="M12.833 7L10.833 7L10.833 9L12.833 9L12.833 7Z" fill="white" />
  </svg>
);
export default InfoCircle;
