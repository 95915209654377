import { FC, useRef, useState } from 'react';
import classnames from 'classnames';
import useOutside from 'utils/useOutside';
import useDebounce from 'utils/useDebounce';
import clearRef from 'utils/clearRef';
import focusRef from 'utils/focusRef';
import isEmptyRef from 'utils/isEmptyRef';
import Cross from 'components/icons/Cross';
import LensEmpty from 'components/icons/outline/LensEmpty';

export interface Props {
  placeholder: string;
  onSearch: (term: string) => void;
  onReset: () => void;
  divClassName?: string;
  inputClassName?: string;
}

const SearchInput: FC<Props> = ({
  placeholder,
  onSearch,
  onReset,
  divClassName,
  inputClassName,
}) => {
  const [active, setActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const search = (term: string) => {
    if (!active) {
      return;
    }

    term ? onSearch(term) : onReset();
  };

  const reset = () => {
    clearRef(inputRef);
    resetDebounce();
    onReset();
  };

  const resetDebounce = () => searchDebounce('');

  const searchDebounce = useDebounce({ onDebounce: search });

  const wrapperRef = useOutside(() => {
    if (isEmptyRef(inputRef) && active) {
      setActive(false);
    }
  });

  const handleOnChangeInput = ({ target: { key, value } }: any) =>
    ('Enter' === key && search(value)) || searchDebounce(value);

  const activate = () => {
    setActive(true);
    focusRef(inputRef);
  };

  const handleOnClickSearch = () =>
    (active && search(inputRef.current!.value)) || activate();

  return (
    <div
      ref={wrapperRef}
      className={classnames('relative w-full min-w-[200px]', divClassName)}
    >
      <button
        className="absolute transform -translate-y-1/2 left-2 text-gray-medium top-1/2"
        onClick={handleOnClickSearch}
      >
        <LensEmpty className="w-4 h-4" />
      </button>
      <input
        ref={inputRef}
        type="text"
        className={classnames(
          'm-0 p-0 min-h-0 h-8 pl-8 w-full flex rounded-lg px-4 text-dark bg-background',
          'focus:outline-none',
          !inputClassName && 'focus-visible:ring-1 focus-visible:ring-primary',
          inputClassName
        )}
        placeholder={placeholder}
        onFocus={activate}
        onChange={handleOnChangeInput}
        autoFocus
      />
      {inputRef?.current?.value && (
        <button
          className="absolute p-1 transform -translate-y-1/2 right-1 text-gray-dark top-1/2"
          onClick={reset}
        >
          <Cross size={8} />
        </button>
      )}
    </div>
  );
};

export default SearchInput;
