import Axios from 'axios';

export class UnknownMedicalHistory {
  constructor(client) {
    this.client = client;
  }

  show(customerHash) {
    return this.client
      .get(`/api/medical-history/${customerHash}`)
      .then((response) => response.data);
  }

  setEmptyRecord(hash) {
    return Axios.all([
      this.createUnknownAllergy(hash),
      this.createUnknownMedication(hash),
      this.createUnknownDisease(hash),
    ]);
  }

  createUnknownAllergy(customerHash) {
    return this.client.post(
      '/professionals/v1/medical-history/unknown-allergies',
      { customer_hash: customerHash }
    );
  }

  destroyUnknownAllergy(customerHash) {
    return this.client.delete(
      `/professionals/v1/medical-history/unknown-allergies/${customerHash}`
    );
  }

  createUnknownDisease(customerHash) {
    return this.client.post(
      '/professionals/v1/medical-history/unknown-diseases',
      { customer_hash: customerHash }
    );
  }

  destroyUnknownDisease(customerHash) {
    return this.client.delete(
      `/professionals/v1/medical-history/unknown-diseases/${customerHash}`
    );
  }

  createUnknownMedication(customerHash) {
    return this.client.post(
      '/professionals/v1/medical-history/unknown-medications',
      { customer_hash: customerHash }
    );
  }

  destroyUnknownMedication(customerHash) {
    return this.client.delete(
      `/professionals/v1/medical-history/unknown-medications/${customerHash}`
    );
  }
}
