import classNames from 'classnames';
import classnames from 'classnames';
import { CloseIcon } from 'components/Drawer';
import Clip from 'components/icons/outline/Clip';

const File = ({
  name,
  label,
  handleChange,
  file,
  setFile,
  placeholder,
  fileRef,
}: any) => {
  return (
    <div className={classnames('input_container')}>
      <label
        htmlFor={name}
        className={classNames(
          'flex flex-row items-center gap-2 bg-secundary text-primary p-2.5 rounded-lg shrink-0 w-fit uppercase text-sm font-medium tracking-widest cursor-pointer',
          {
            'cursor-not-allowed': file?.name,
          }
        )}
      >
        <Clip className="min-w-4 h-4" />

        {label}
        <input
          type="file"
          id="file-input"
          name={name}
          className="hidden"
          onChange={handleChange}
          disabled={file?.name}
          ref={fileRef}
        />
      </label>
      <div className="my-2">
        {file?.name ? (
          <div className="flex flex-row">
            <div className="mr-2">
              <CloseIcon
                name="close"
                className="text-gray-dark cursor-pointer w-2.5"
                onClick={() => {
                  setFile(null);
                }}
              />
            </div>
            <div className="text-xs text-gray-dark max-w-60 truncate">
              {file?.name}
            </div>
          </div>
        ) : (
          <div className="text-xs text-gray-dark">{placeholder}</div>
        )}
      </div>
    </div>
  );
};

export default File;
