import React, { useState, useEffect } from 'react';
import GoogleTagManager from 'utils/GoogleTagManager';
import useCookies from 'utils/useCookies';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const CookieAlert = () => {
  const { t } = useTranslation();
  const { cookiesAccepted, cookies, acceptCookies } = useCookies();
  const [configOpen, setConfigOpen] = useState();
  const [analyticsAccepted, setAnalyticsAccepted] = useState();

  const acceptAll = () => acceptCookies({ analyticsAccepted: true });
  const confirm = () => acceptCookies({ analyticsAccepted });

  useEffect(() => {
    if (cookies?.analyticsAccepted) {
      GoogleTagManager.init();
    }
  }, [cookies]);

  if (cookiesAccepted) {
    return null;
  }

  if (!configOpen) {
    return (
      <Container
        onAccept={acceptAll}
        className="max-w-400px"
        secondaryButton={
          <SecondaryButton onClick={() => setConfigOpen(true)}>
            {t('cookies__alert_configure')}
          </SecondaryButton>
        }
      >
        {t('cookies__alert_text')} <CookiesLink />
      </Container>
    );
  }

  return (
    <Container
      onAccept={acceptAll}
      className="max-w-560px"
      secondaryButton={
        <SecondaryButton onClick={confirm}>
          {t('cookies__configure_confirm')}
        </SecondaryButton>
      }
    >
      <h3 className="pb-8 text-lg font-medium">
        {t('cookies__configure_title')}
      </h3>
      <div className="grid grid-cols-cookiesConfig gap-y-4 gap-x-8">
        <CookieType
          title={t('cookies__configure_required_title')}
          description={
            <>
              {t('cookies__configure_required_text')} <CookiesLink />
            </>
          }
        >
          {t('cookies__configure_required_label')}
        </CookieType>
        <CookieType
          title={t('cookies__configure_analytics_title')}
          description={
            <>
              {t('cookies__configure_analytics_text')} <CookiesLink />
            </>
          }
        >
          <button
            onClick={() => setAnalyticsAccepted(!analyticsAccepted)}
            className={`${classnames({
              'justify-end bg-primary': analyticsAccepted,
              'justify-start bg-separators': !analyticsAccepted,
            })} flex ml-auto focus:outline-none rounded-full h-4 w-8 p-1px`}
          >
            <span className="w-1/2 h-full bg-white rounded-full"></span>
          </button>
        </CookieType>
      </div>
    </Container>
  );
};

const Container = ({ children, secondaryButton, onAccept, className }) => {
  const { t } = useTranslation();

  return (
    <section
      className={`bg-white z-10 text-dark shadow-lg fixed right-10 bottom-10 text-md rounded-40 rounded-br-lg p-8 ${className}`}
    >
      <p className="col-span-2 mb-6">{children}</p>
      <div className="flex justify-end gap-2">
        {secondaryButton}
        <button
          onClick={onAccept}
          className="px-4 py-4 font-medium tracking-widest text-white uppercase rounded-lg bg-primary text btn-block"
        >
          {t('cookies__alert_accept')}
        </button>
      </div>
    </section>
  );
};

const CookieType = ({ title, description, children }) => (
  <>
    <div>
      <h4 className="pb-2 text-sm font-medium">{title}</h4>
      <p className="text-xs">{description}</p>
    </div>
    <span className="text-xs font-medium text-right whitespace-pre text-gray-dark">
      {children}
    </span>
  </>
);

const CookiesLink = () => {
  const { t } = useTranslation();

  return (
    <a
      className="cursor-pointer text-primary hover:underline"
      target="_blank"
      rel="noreferrer noopener"
      href={`${import.meta.env.PUBLIC_URL}/politica-de-cookies.html`}
    >
      {t('cookies__alert_link')}
    </a>
  );
};

const SecondaryButton = ({ children, onClick }) => (
  <button
    onClick={onClick}
    className="px-4 py-4 font-medium tracking-widest uppercase rounded-lg bg-secundary text text-primary btn-block"
  >
    {children}
  </button>
);

export default CookieAlert;
