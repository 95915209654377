import Button from 'components/Button';
import Form from 'components/Form';
import ClinicalCourseNotFound from 'containers/ClinicalCourse/ClinicalCourseNotFound';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const ClinicalCourseDetail = ({
  clinicalCourse,
  isEditable,
  onDelete,
  onSave,
  isNotFound,
}) => {
  const { t } = useTranslation();
  const [screen, setScreen] = useState('detail');

  const {
    register,
    errors,
    handleSubmit,
    watch,
    formState: { dirtyFields },
  } = useForm({ mode: 'onChange' });
  const formData = watch();

  const onSubmit = (data) => {
    onSave(data);
    setScreen('detail');
  };

  useEffect(() => {
    setScreen('detail');
  }, [clinicalCourse, isNotFound]);

  if (screen === 'edit') {
    return (
      <Form
        className="p-6 text-dark flex flex-col w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h3 className="mb-6 font-medium text-dark">
          {t('clinical__course_detail_edit_title')}
        </h3>
        <Form.Group>
          <Form.Date
            name="date"
            max={dayjs().format('YYYY-MM-DD')}
            defaultValue={dayjs(clinicalCourse?.dated_at).format('YYYY-MM-DD')}
            label={t('clinical__course_create_modal_date_label')}
            placeholder={t('clinical__course_create_modal_date_placeholder')}
            register={register({
              required: t('clinical__course_create_modal_date_error'),
            })}
            errors={errors.date}
          />
          <Form.Time
            name="time"
            max={
              formData.date === dayjs().format('YYYY-MM-DD')
                ? dayjs().format('HH:mm')
                : null
            }
            defaultValue={dayjs(clinicalCourse?.dated_at).format('HH:mm')}
            label={t('clinical__course_create_modal_time_label')}
            placeholder={t('clinical__course_create_modal_time_placeholder')}
            register={register({
              required: t('clinical__course_create_modal_time_error'),
            })}
            errors={errors.time}
          />
        </Form.Group>

        <Form.Field
          type="text"
          defaultValue={clinicalCourse?.reason}
          name="reason"
          isDirty={dirtyFields.reason}
          label={t('clinical__course_create_modal_reason_label')}
          placeholder={t('clinical__course_create_modal_reason_placeholder')}
          register={register()}
        />

        <Form.Field
          type="textarea"
          defaultValue={clinicalCourse?.briefing}
          isDirty={dirtyFields.briefing}
          name="briefing"
          label={t('clinical__course_create_modal_brief_label')}
          placeholder={t('clinical__course_create_modal_brief_placeholder')}
          register={register({
            required: t('clinical__course_create_modal_brief_error'),
          })}
          errors={errors.briefing}
        />
        <div className="ml-auto mt-auto flex items-center gap-4">
          <Button onClick={() => setScreen('detail')} variant="reverse">
            {t('clinical__course_detail_edit_cancel')}
          </Button>
          <Button type="submit" variant="primary">
            {t('clinical__course_detail_edit_save')}
          </Button>
        </div>
      </Form>
    );
  }

  return isNotFound ? (
    <ClinicalCourseNotFound />
  ) : (
    <article className="p-6 text-dark flex flex-col w-full">
      <div className="mb-8 grid grid-cols-2 gap-4">
        <section>
          <h3 className="font-medium mb-2">
            {t('clinical__course_detail_modal_professional')}
          </h3>
          <p className="text-md">{clinicalCourse.professional.name}</p>
          <p className="text-md text-blue">
            {clinicalCourse.professional.speciality}
          </p>
          <p className="text-md">
            {clinicalCourse.professional.collegiate_number}
          </p>
        </section>
        <section>
          <h3 className="font-medium mb-2">
            {t('clinical__course_detail_modal_date')}
          </h3>
          <p className="text-md">
            {dayjs(clinicalCourse.dated_at).format('DD/MM/YY HH:mm')}
          </p>
        </section>
      </div>

      {clinicalCourse.reason && (
        <section className="mb-8">
          <h3 className="font-medium mb-2">
            {t('clinical__course_detail_modal_reason')}
          </h3>
          <p className="text-md">{clinicalCourse.reason}</p>
        </section>
      )}

      <section className="mb-8">
        <h3 className="font-medium mb-2">
          {t('clinical__course_detail_modal_brief')}
        </h3>
        <p className="text-md">{clinicalCourse.briefing}</p>
      </section>

      {isEditable && (
        <div className="ml-auto mt-auto flex items-center gap-4">
          <Button
            onClick={() => onDelete({ id: clinicalCourse.id })}
            variant="plain-negative"
            size="small"
          >
            {t('clinical__course_detail_modal_delete')}
          </Button>
          <Button
            onClick={() => setScreen('edit')}
            variant="plain-primary"
            size="small"
          >
            {t('clinical__course_detail_modal_update')}
          </Button>
        </div>
      )}
    </article>
  );
};

export default ClinicalCourseDetail;
