import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

const BASE_URL = config.services.mediquo.api_url;
const PRO = 'pro/v1';

export const loginPassword = (credentials) =>
  axios({
    method: 'post',
    url: `${BASE_URL}${PRO}/login`,
    data: credentials,
    headers: getHeaders(),
  }).then((response) => response.data);

export const login = ({ token, prefix, phone, channel }) =>
  axios({
    method: 'post',
    url: `${BASE_URL}${PRO}/login-${channel}`,
    data: {
      token,
      installation_guid: localStorage.getItem('sessionId'),
      phone_prefix: prefix,
      phone,
    },
    headers: getHeaders(),
  });

export const loginSms = ({ prefix, phone }) =>
  axios({
    method: 'post',
    url: `${BASE_URL}${PRO}/login-sms`,
    data: {
      phone_prefix: prefix,
      phone,
    },
    headers: getHeaders(),
  });

export const checkVerificationCode = ({
  prefix,
  phone,
  code,
  sessionId,
  source,
  campaign,
}) =>
  axios({
    method: 'post',
    url: `${BASE_URL}${PRO}/check`,
    data: {
      phone_prefix: prefix,
      phone,
      verification_code: code,
      installation_guid: sessionId,
      source,
      campaign,
    },
    headers: getHeaders(),
  });

export const updateProfile = ({
  name: first_name,
  lastName: last_name,
  email,
  specialityId: speciality_id,
  country: work_practice_country,
  collegiateNumber: collegiate_number,
  region,
  work,
}) =>
  axios({
    method: 'put',
    url: `${BASE_URL}${PRO}/profile`,
    data: {
      first_name,
      last_name,
      email,
      speciality_id,
      work_practice_country,
      collegiate_number,
      region,
      work_center_name: work?.centerName,
      work_tax_id: work?.taxId,
      work_postal_code: work?.postalCode,
      work_address: work?.address,
      work_city: work?.city,
    },
    headers: getHeaders(),
  });

export const sendLinkResetPassword = ({ email }) =>
  axios({
    method: 'post',
    url: `${BASE_URL}pro/password/email`,
    params: { email },
    headers: getHeaders(),
  });

export const resetPassword = ({ email, token, password }) =>
  axios({
    method: 'post',
    url: `${BASE_URL}pro/password/reset`,
    params: {
      email,
      token,
      password,
      password_confirmation: password,
    },
    headers: getHeaders(),
  });

export const getProfessionalProfile = ({ hash }) =>
  axios({
    method: 'get',
    url: `${BASE_URL}${PRO}/professionals/${hash}/profile`,
    headers: getHeaders(),
  });

export const getSpecialities = () =>
  axios({
    method: 'get',
    url: `${BASE_URL}${PRO}/onboarding/specialities`,
    headers: getHeaders(),
  });

export const getCountries = () =>
  axios({
    method: 'get',
    url: `${BASE_URL}${PRO}/onboarding/countries`,
    headers: getHeaders(),
  });

export const getRegions = () =>
  axios({
    method: 'get',
    url: `${BASE_URL}${PRO}/onboarding/regions`,
    headers: getHeaders(),
  });

export const getTimezones = () =>
  axios({
    method: 'get',
    url: `${BASE_URL}${PRO}/onboarding/timezones`,
    headers: getHeaders(),
  }).then((response) => response.data.data);
