import Modal from 'components/Modal';
import KeyboardUpDown from 'components/images/KeyboardUpDown';
import NavigationButtons from 'components/NavigationButtons';
import { useTranslation } from 'react-i18next';
import ClinicalCourseDetail from 'containers/ClinicalCourse/ClinicalCourseDetail';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import api from 'api';
import ClinicalCourseNavigation from 'containers/ClinicalCourse/ClinicalCourseNavigation';
import useClinicalCourses from 'containers/ClinicalCourse/useClinicalCourses';
import useKeyPress from 'utils/useKeyPress';
import Tracker from 'utils/Tracking';

const ClinicalCoursesModal = ({ onClose, patient, roomId }) => {
  const { t } = useTranslation();
  const { remove, create } = useClinicalCourses({ roomId });
  const { profile } = useSelector((state) => state.session);
  const [currentCourse, setCurrentCourse] = useState();
  const [clinicalCourses, setClinicalCourses] = useState([]);
  const [filters, setFilters] = useState([]);
  const [isCloseable, setIsCloseable] = useState(true);
  const [isNotFound, setIsNotFound] = useState(false);

  const refresh = () =>
    api.clinicalCourse.list({ roomId }).then(({ data }) => {
      setClinicalCourses(data.data);
      setCurrentCourse(data.data[0]);
    });

  useEffect(() => {
    refresh();
  }, [roomId]);

  const save = async (data) => {
    const item = await create({
      id: currentCourse.id,
      room_id: roomId,
      ...data,
    });

    const index = clinicalCourses.findIndex(
      (item) => item.id === currentCourse.id
    );

    setClinicalCourses((prev) => {
      prev[index] = item;
      return [...prev];
    });
    setCurrentCourse(item);
  };

  const navigateNext = () => {
    Tracker.event('clinical courses history menu arrow key press', {
      event_room_id: roomId,
    });
    const index = clinicalCourses.findIndex(
      (item) => item.id === currentCourse.id
    );

    if (index + 1 > clinicalCourses.length - 1) {
      return;
    }

    setCurrentCourse(clinicalCourses[index + 1]);
  };

  const navigatePrev = () => {
    Tracker.event('clinical courses history menu arrow key press', {
      event_room_id: roomId,
    });
    const index = clinicalCourses.findIndex(
      (item) => item.id === currentCourse.id
    );

    if (index - 1 < 0) {
      return;
    }

    setCurrentCourse(clinicalCourses[index - 1]);
  };

  useKeyPress({
    key: 'ArrowUp',
    onPress: navigatePrev,
  });

  useKeyPress({
    key: 'ArrowDown',
    onPress: navigateNext,
  });

  const sortList = () => setClinicalCourses([...clinicalCourses].reverse());

  const applyFilters = (filters) => {
    if (filters.length < 1) {
      setIsNotFound(false);
    }
    setFilters(filters);
  };

  const getItems = () => {
    if (filters.includes('own')) {
      let filterClinicalCourses = clinicalCourses.filter(
        (i) => i.professional.hash === profile.hash
      );

      if (filterClinicalCourses.length < 1) {
        setIsNotFound(true);
      }
      return filterClinicalCourses;
    }
    return clinicalCourses;
  };

  const handleDelete = ({ id }) => {
    setIsCloseable(false);
    remove({ id })
      .then(refresh)
      .finally(() => setIsCloseable(true));
  };

  return (
    <Modal
      onClose={onClose}
      width="custom"
      className="w-[980px]"
      allowOutsideClose={isCloseable}
    >
      {({ onClose }) => (
        <>
          <Modal.Header onClose={onClose}>
            {t('clinical__course_list_modal_title', { name: patient.name })}
          </Modal.Header>

          <div className="flex h-[540px] overflow-hidden">
            <ClinicalCourseNavigation
              onFilter={applyFilters}
              onSort={sortList}
              items={getItems()}
              itemSelected={currentCourse}
              onSelected={(item) => setCurrentCourse(item)}
            />
            {currentCourse && (
              <ClinicalCourseDetail
                onDelete={handleDelete}
                onSave={save}
                isEditable={currentCourse.professional.hash === profile.hash}
                clinicalCourse={currentCourse}
                isNotFound={isNotFound}
              />
            )}
          </div>

          <Modal.Footer className="text-gray-dark bg-background">
            <div className="text-2xs flex gap-4 items-center">
              <span>{t('clinical__course_list_modal_footer_help')}</span>
              <KeyboardUpDown />
            </div>
            <div className="mr-auto">
              <NavigationButtons onPrev={navigatePrev} onNext={navigateNext} />
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default ClinicalCoursesModal;
