export interface Props {
  data?: BlobPart;
  url?: string;
  name?: string;
  type?: string;
}

const downloadFile = ({ data, url, name, type }: Props) => {
  if (!data && !url) {
    throw new Error('No data or url provided');
  }

  const downloadUrl =
    url ?? window.URL.createObjectURL(new Blob([data!], { type }));

  downloadResource({ url: downloadUrl, fileName: name });
};

const downloadResource = ({
  url,
  fileName,
}: {
  url: string;
  fileName?: string;
}) =>
  fetch(url)
    .then((response) => response.blob())
    .then((blob) =>
      forceDownload({
        url: window.URL.createObjectURL(blob),
        fileName: fileName ?? extractFileName(url),
      })
    )
    .catch(console.error);

const extractFileName = (url: string) =>
  url.substring(url.lastIndexOf('/') + 1);

const forceDownload = ({
  url,
  fileName,
}: {
  url: string;
  fileName: string;
}) => {
  const anchor = document.createElement('a');

  anchor.href = url;
  anchor.download = fileName;

  document.body.appendChild(anchor);

  anchor.click();
  anchor.remove();
};

export default downloadFile;
