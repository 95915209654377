const EmptyFilter = ({ props }: any) => (
  <svg
    width="240"
    height="240"
    viewBox="0 0 240 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="240" height="240" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.192 27.3913L120 15.1995L107.807 27.392L92.8742 18.7705L84.2531 33.7026L67.5987 29.2401L63.1361 45.8948H45.8943V63.1369L29.2386 67.5997L33.7013 84.2547L18.7693 92.8756L27.3908 107.808L15.1992 120L27.3906 132.191L18.769 147.124L33.7016 155.746L29.2391 172.4L45.8943 176.863V194.105H63.1357L67.5983 210.76L84.2533 206.297L92.8745 221.23L107.807 212.608L120 224.801L132.191 212.609L147.123 221.23L155.744 206.297L172.399 210.76L176.862 194.105H194.105V176.863L210.759 172.4L206.296 155.746L221.228 147.124L212.608 132.193L224.8 120L212.607 107.807L221.228 92.8757L206.296 84.2547L210.759 67.5998L194.105 63.1373V45.8948H176.861L172.399 29.2401L155.744 33.7026L147.123 18.7704L132.192 27.3913Z"
      fill="#D7BCF1"
    />
    <rect
      width="10.6509"
      height="10.6509"
      transform="matrix(0.707107 0.707107 0.707107 -0.707107 188.636 22.7308)"
      fill="#D7BCF1"
    />
    <path
      d="M111.789 56.2669C112.618 54.2104 115.105 53.7991 116.349 55.8556L122.981 66.138L132.516 58.3234C134.174 57.0895 136.661 58.3234 136.661 60.3798V72.7187L148.683 71.4848C150.756 71.0735 152.414 73.5413 151.17 75.1865L144.538 85.4689L155.73 90.8158C157.803 91.6384 157.389 94.5174 155.73 95.34L144.538 100.276L150.756 110.558C152 112.203 150.341 114.671 148.269 114.26L136.247 112.614L135.832 124.542C135.832 126.599 133.345 127.832 131.687 126.187L124.64 120.018C123.396 119.195 121.738 119.195 120.909 120.429L115.52 128.244C114.276 129.889 111.789 129.478 110.96 127.421L107.643 115.905L96.4507 120.84C94.378 121.663 92.3053 120.018 93.1344 117.961L96.8652 106.445L84.8435 104.389C82.7708 103.977 81.9417 101.509 83.5999 100.276L92.7198 92.461L83.5999 84.235C81.9417 83.0011 82.7708 80.1221 84.8435 80.1221L96.8652 78.4769L93.5489 66.5493C93.1343 64.4928 95.2071 62.8476 96.8652 63.6702L108.058 68.6058L111.789 56.2669Z"
      fill="#F1E4FF"
    />
    <path
      d="M161.841 97.6491H78.1582V99.75C78.1582 109.248 82.1607 118.307 89.1833 124.703L111.759 145.263V185.496L128.719 174.122V145.263L150.798 125.183C157.832 118.787 161.841 109.721 161.841 100.214V97.6491Z"
      fill="#6321A8"
    />
    <path
      d="M113.285 79.5065H126.716L124.136 104.256H115.865L113.285 79.5065Z"
      fill="#EDC2E2"
    />
    <rect
      x="115.717"
      y="107.373"
      width="8.41886"
      height="8.41886"
      fill="#EDC2E2"
    />
    <path
      d="M31.9531 224.801C34.1704 224.801 35.9678 223.017 35.9678 220.817C35.9678 218.618 34.1704 216.834 31.9531 216.834C29.7359 216.834 27.9385 218.618 27.9385 220.817C27.9385 223.017 29.7359 224.801 31.9531 224.801Z"
      fill="#FFB801"
    />
    <path
      d="M215.547 50.7149C217.209 50.7149 218.556 49.3783 218.556 47.7296C218.556 46.0808 217.209 44.7442 215.547 44.7442C213.885 44.7442 212.538 46.0808 212.538 47.7296C212.538 49.3783 213.885 50.7149 215.547 50.7149Z"
      fill="#201552"
    />
    <path
      d="M152.625 157.811C154.287 157.811 155.634 156.474 155.634 154.826C155.634 153.177 154.287 151.84 152.625 151.84C150.963 151.84 149.616 153.177 149.616 154.826C149.616 156.474 150.963 157.811 152.625 157.811Z"
      fill="white"
    />
    <path
      d="M17.4825 190.385C17.6737 189.911 18.2472 189.816 18.5339 190.29L20.0633 192.661L22.2618 190.859C22.6441 190.575 23.2176 190.859 23.2176 191.334V194.179L25.9896 193.894C26.4675 193.799 26.8499 194.368 26.5631 194.748L25.0338 197.119L27.6146 198.352C28.0925 198.541 27.9969 199.205 27.6146 199.395L25.0338 200.533L26.4675 202.904C26.7543 203.283 26.372 203.852 25.894 203.757L23.1221 203.378L23.0265 206.128C23.0265 206.602 22.453 206.887 22.0706 206.507L20.4457 205.085C20.1589 204.895 19.7766 204.895 19.5854 205.18L18.3428 206.982C18.056 207.361 17.4825 207.266 17.2913 206.792L16.5267 204.137L13.9458 205.275C13.4679 205.464 12.99 205.085 13.1812 204.611L14.0414 201.955L11.2695 201.481C10.7915 201.386 10.6003 200.817 10.9827 200.533L13.0856 198.731L10.9827 196.834C10.6003 196.55 10.7915 195.886 11.2695 195.886L14.0414 195.506L13.2767 192.756C13.1812 192.282 13.6591 191.903 14.0414 192.092L16.6222 193.23L17.4825 190.385Z"
      fill="#3C50EC"
    />
    <path
      d="M80.8419 149.599C81.0331 149.125 81.6066 149.03 81.8933 149.504L83.4227 151.875L85.6212 150.073C86.0035 149.789 86.577 150.073 86.577 150.547V153.393L89.349 153.108C89.8269 153.013 90.2093 153.582 89.9225 153.962L88.3931 156.332L90.9739 157.565C91.4519 157.755 91.3563 158.419 90.9739 158.609L88.3931 159.747L89.8269 162.117C90.1137 162.497 89.7313 163.066 89.2534 162.971L86.4814 162.592L86.3859 165.342C86.3859 165.816 85.8123 166.101 85.43 165.721L83.805 164.299C83.5183 164.109 83.1359 164.109 82.9448 164.394L81.7022 166.195C81.4154 166.575 80.8419 166.48 80.6507 166.006L79.886 163.35L77.3052 164.488C76.8273 164.678 76.3494 164.299 76.5405 163.825L77.4008 161.169L74.6288 160.695C74.1509 160.6 73.9597 160.031 74.3421 159.747L76.4449 157.945L74.3421 156.048C73.9597 155.763 74.1509 155.1 74.6288 155.1L77.4008 154.72L76.6361 151.97C76.5405 151.496 77.0185 151.116 77.4008 151.306L79.9816 152.444L80.8419 149.599Z"
      fill="white"
    />
    <path
      d="M225.451 23.0993L223.443 28.505C223.348 28.6947 223.252 28.7895 223.061 28.8844L218.951 30.0224C218.473 30.2121 218.377 30.8759 218.951 31.0656L223.443 32.7727C223.634 32.8675 223.73 32.9624 223.73 33.0572L225.642 38.368C225.833 38.8422 226.598 38.8422 226.693 38.2732L227.936 32.6778C227.936 32.4882 228.127 32.2985 228.318 32.2985L232.524 30.8759C233.002 30.6863 233.002 29.9276 232.524 29.8327L228.509 28.6947C228.318 28.5999 228.223 28.505 228.127 28.3153L226.406 23.0045C226.406 22.6251 225.642 22.6251 225.451 23.0993Z"
      fill="#FFB801"
    />
    <path
      d="M155.366 48.098L153.358 53.5037C153.263 53.6934 153.167 53.7882 152.976 53.8831L148.866 55.0211C148.388 55.2108 148.292 55.8747 148.866 56.0643L153.358 57.7714C153.549 57.8662 153.645 57.9611 153.645 58.0559L155.557 63.3668C155.748 63.8409 156.513 63.841 156.608 63.2719L157.851 57.6766C157.851 57.4869 158.042 57.2972 158.233 57.2972L162.439 55.8747C162.917 55.685 162.917 54.9263 162.439 54.8314L158.424 53.6934C158.233 53.5986 158.138 53.5037 158.042 53.3141L156.321 48.0032C156.321 47.6238 155.557 47.6239 155.366 48.098Z"
      fill="white"
    />
  </svg>
);

export default EmptyFilter;
