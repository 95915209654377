import { useSelector } from 'react-redux';

import NotesPanel from 'containers/DetailBar/PatientBar/components/Notes/NotesPanel';
import useAccordeon from 'containers/Shared/components/Accordeon/accordeon.hook';
import TrackingsPanel from 'containers/DetailBar/PatientBar/components/Trackings/TrackingsPanel';
import AllergiesPanel from 'containers/DetailBar/PatientBar/components/Allergies/AllergiesPanel';
import DiseasesPanel from 'containers/DetailBar/PatientBar/components/Diseases/DiseasesPanel';
import BillingPanel from 'containers/DetailBar/PatientBar/components/Billing/BillingPanel';
import MedicationsPanel from 'containers/DetailBar/PatientBar/components/Medications/MedicationsPanel';
import IMCsPanel from 'containers/DetailBar/PatientBar/components/IMCs/IMCsPanel';
import AttachmentsPanel from 'containers/DetailBar/PatientBar/components/Attachments/AttachmentsPanel';
import ChatRecordsPanel from 'containers/DetailBar/PatientBar/components/ChatRecords/ChatRecordsPanel';
import PrescriptionsPanel from 'containers/DetailBar/PatientBar/components/Prescriptions/PrescriptionsPanel';
import ReportsPanel from 'containers/DetailBar/PatientBar/components/Reports';
import ClinicalCoursesPanel from 'containers/DetailBar/PatientBar/components/ClinicalCourses/ClinicalCoursesPanel';
import DiagnosticTestPrescriptionsPanel from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/DiagnosticTestPrescriptionsPanel';
import { useEffect } from 'react';
import FeatureFlag from 'containers/Shared/components/FeatureFlag';
import {
  getCanPrescribe,
  getCanPrescribeDiagnosticTest,
} from 'containers/Inbox/reducers/session.reducer';
import { getCanPatientDiagnosticTestPrescription } from 'containers/Room/reducers/console.reducer';

const MedicalHistory = ({
  patient,
  room,
  isLoading,
  consultationType,
  consultationId,
  setTab,
  setIsReportCancelled,
  isReportCancelled,
}) => {
  const accordeon = useAccordeon();
  const { summary } = useSelector((state) => state.patientBar);
  const canPrescribe = useSelector(getCanPrescribe);
  const canPrescribeDiagnosticTest = useSelector(getCanPrescribeDiagnosticTest);
  const canPatientGetDiagnosticTest = useSelector(
    getCanPatientDiagnosticTestPrescription
  );

  useEffect(() => {
    accordeon.open('clinical_courses');
  }, []);

  if (!summary || isLoading) {
    return null;
  }

  const hash = room.meta?.hash;

  return (
    <div>
      <ClinicalCoursesPanel
        room={room}
        badge={summary.clinical_courses_count}
        open={accordeon.isOpen('clinical_courses')}
        onOpen={() => accordeon.open('clinical_courses')}
        onClose={() => accordeon.close()}
        consultationType={consultationType}
        consultationId={consultationId}
      />
      <DiseasesPanel
        hash={hash}
        summary={summary}
        open={accordeon.isOpen('diseases')}
        onOpen={() => accordeon.open('diseases')}
        onClose={() => accordeon.close()}
      />
      <MedicationsPanel
        hash={hash}
        summary={summary}
        open={accordeon.isOpen('medications')}
        onOpen={() => accordeon.open('medications')}
        onClose={() => accordeon.close()}
      />
      <AllergiesPanel
        hash={hash}
        summary={summary}
        open={accordeon.isOpen('allergies')}
        onOpen={() => accordeon.open('allergies')}
        onClose={() => accordeon.close()}
      />
      <ReportsPanel
        hash={hash}
        badge={summary.reports_count}
        open={accordeon.isOpen('reports')}
        onOpen={() => accordeon.open('reports')}
        onClose={() => accordeon.close()}
        setTab={setTab}
        setIsReportCancelled={setIsReportCancelled}
        isReportCancelled={isReportCancelled}
      />
      <FeatureFlag enabled={canPrescribe}>
        <PrescriptionsPanel
          hash={hash}
          room={room}
          open={accordeon.isOpen('prescriptions')}
          onOpen={() => accordeon.open('prescriptions')}
          onClose={() => accordeon.close()}
          badge={summary.prescriptions_count}
          consultationType={consultationType}
          consultationId={consultationId}
        />
      </FeatureFlag>
      <FeatureFlag
        enabled={canPrescribeDiagnosticTest && canPatientGetDiagnosticTest}
      >
        <DiagnosticTestPrescriptionsPanel
          hash={hash}
          room={room}
          open={accordeon.isOpen('diagnostic_tests')}
          onOpen={() => accordeon.open('diagnostic_tests')}
          onClose={() => accordeon.close()}
          badge={summary.diagnostic_test_prescriptions_count}
          consultationType={consultationType}
          consultationId={consultationId}
        />
      </FeatureFlag>
      <IMCsPanel
        hash={hash}
        badge={summary.imcs_count}
        open={accordeon.isOpen('imcs')}
        onOpen={() => accordeon.open('imcs')}
        onClose={() => accordeon.close()}
      />
      <NotesPanel
        hash={hash}
        badge={summary.notes_count}
        open={accordeon.isOpen('notes')}
        onOpen={() => accordeon.open('notes')}
        onClose={() => accordeon.close()}
      />
      <TrackingsPanel
        room={room}
        open={accordeon.isOpen('trackings')}
        onOpen={() => accordeon.open('trackings')}
        onClose={() => accordeon.close()}
      />
      <AttachmentsPanel
        room={room}
        open={accordeon.isOpen('attachments')}
        onOpen={() => accordeon.open('attachments')}
        onClose={() => accordeon.close()}
      />
      <ChatRecordsPanel
        hash={hash}
        open={accordeon.isOpen('chatRecords')}
        onOpen={() => accordeon.open('chatRecords')}
        onClose={() => accordeon.close()}
      />

      {room.meta.can_be_appointed && (
        <BillingPanel
          hash={hash}
          patient={patient}
          open={accordeon.isOpen('billing')}
          onOpen={() => accordeon.open('billing')}
          onClose={() => accordeon.close()}
        />
      )}
    </div>
  );
};

export default MedicalHistory;
