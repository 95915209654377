import { useEffect, useState } from 'react';
import api from 'api';

export const useChatRecords = (hash) => {
  const [chatRecords, setChatRecords] = useState([]);

  useEffect(() => {
    api.patients.rooms
      .get({ hash })
      .then(({ data }) => setChatRecords(sortByLastUpdated(data.data)));
  }, [hash]);

  return chatRecords;
};

const sortByLastUpdated = (chatRecords) =>
  chatRecords.sort((chatA, chatB) =>
    chatA.updated_at < chatB.updated_at ? 1 : -1
  );

export const useChatRecordMessages = (record) => {
  const [chatRecord, setChatRecord] = useState({ ...record, messages: [] });

  useEffect(() => {
    api.rooms.messages.get({ roomId: record.room_id }).then(({ data }) =>
      setChatRecord({
        ...chatRecord,
        messages: data.data,
      })
    );
  }, []);

  return chatRecord;
};
