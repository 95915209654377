import Modal from 'components/Modal';
import Button from 'components/Button';
import Form from 'components/Form';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import useQuery from 'utils/useQuery';
import api from 'api';

const BillingForm = ({ onClose, onSave, billingProfile }) => {
  const { t } = useTranslation();
  const { data: countries, isLoading: countriesLoading } = useQuery(
    api.getCountries
  );

  const { register, handleSubmit } = useForm({ mode: 'onChange' });

  const onSubmit = (data) => {
    onSave && onSave(data);
    onClose && onClose();
  };

  return (
    <Modal onClose={onClose} onOutside={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header onClose={onClose}>
          {t('sidebar__patient_billing_form_title')}
        </Modal.Header>
        <Modal.Body>
          <section>
            <p className="mb-2 text-xs text-gray-dark">
              {t('sidebar__patient_billing_form_subtitle')}
            </p>
            <div className="flex gap-4">
              <Form.Field
                type="text"
                defaultValue={billingProfile?.name}
                name="name"
                register={register()}
                label={t('sidebar__patient_billing_form_billing_name_label')}
                placeholder={t(
                  'sidebar__patient_billing_form_billing_name_placeholder'
                )}
              />
              <Form.Field
                type="text"
                defaultValue={billingProfile?.tax_id}
                name="tax_id"
                register={register()}
                label={t('sidebar__patient_billing_form_dni_label')}
                placeholder={t('sidebar__patient_billing_form_dni_placeholder')}
              />
            </div>
            <div className="flex gap-4">
              <Form.Field
                type="text"
                defaultValue={billingProfile?.address}
                name="address"
                register={register()}
                label={t('sidebar__patient_billing_form_address_label')}
                placeholder={t(
                  'sidebar__patient_billing_form_address_placeholder'
                )}
              />
              <Form.Field
                type="text"
                defaultValue={billingProfile?.postal_code}
                name="postal_code"
                register={register()}
                label={t('sidebar__patient_billing_form_postal_code_label')}
                placeholder={t(
                  'sidebar__patient_billing_form_postal_code_placeholder'
                )}
              />
              <Form.Field
                type="text"
                defaultValue={billingProfile?.region}
                name="region"
                register={register()}
                label={t('sidebar__patient_billing_form_region_label')}
                placeholder={t(
                  'sidebar__patient_billing_form_region_placeholder'
                )}
              />
              <Form.Field
                type="select"
                isLoading={countriesLoading}
                options={countries?.data?.data?.map(({ key, name }) => ({
                  key: key,
                  name: name,
                }))}
                defaultValue={billingProfile?.country_code}
                name="country_code"
                register={register()}
                label={t('sidebar__patient_billing_form_country_code_label')}
                placeholder={t(
                  'sidebar__patient_billing_form_country_code_placeholder'
                )}
              />
            </div>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button>{t('sidebar__patient_billing_form_submit')}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default BillingForm;
