import useMedicalReport from 'containers/MedicalReport/useMedicalReport';
import { Report } from 'api/reports.d';
import AutomaticItem from 'containers/DetailBar/PatientBar/components/PatientProfile/AutomaticItem';
import { updateAutomaticReport } from 'state/medicalReports/index.slice';
import { useDispatch } from 'react-redux';
import Tracker from 'utils/Tracking';

type DraftReportProps = {
  report?: Report;
  status: string;
  consultationId: string;
  setIsReportCancelled?: (value: boolean) => void;
};

const AutomaticReport = ({
  status,
  consultationId,
  setIsReportCancelled = () => {},
}: DraftReportProps) => {
  const { handleChangeScreen } = useMedicalReport();
  const dispatch = useDispatch();

  const handleClick = () => {
    switch (status) {
      case 'loading':
        Tracker.event('cancel automatic report button click', {
          consultation_id: consultationId,
        });
        setIsReportCancelled(true);
        dispatch(updateAutomaticReport('cancel'));
        return;
      case 'finished':
        handleChangeScreen('report');
        break;
      default:
        handleChangeScreen('report');
    }
  };

  return (
    <>
      <AutomaticItem onEdit={handleClick} status={status} />
    </>
  );
};

export default AutomaticReport;
