import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL, INVITATIONS_URL, PROFESSIONALS } from 'api/Consultations';
import { Invitation, InvitationsRequestParams } from 'api/invitations.d';
import find from 'utils/find';
import getHeaders from 'utils/getHeaders';
import merge from 'utils/merge';

export const invitationsApi = createApi({
  reducerPath: 'invitationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: INVITATIONS_URL,
    prepareHeaders: (headers) => {
      Object.entries(getHeaders()).forEach(([key, value]) =>
        headers.set(key, value!)
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllInvitations: builder.query<
      Partial<Invitation>[],
      InvitationsRequestParams | undefined
    >({
      query: ({ status = 'pending', target } = { status: 'pending' }) =>
        `/?status=${status}${target ? `&target=${target}` : ''}`,
      transformResponse: (response: { data: Invitation[] }) => response.data,
    }),
    sendInvitation: builder.mutation<void, Partial<Invitation>>({
      query: ({ id }) => ({
        url: `${BASE_URL}/${PROFESSIONALS}/invitations/${id}/invitations/send`,
        method: 'POST',
      }),
    }),
  }),
});

export const alphabetically = (
  a: Partial<Invitation>,
  b: Partial<Invitation>
) => {
  const aName = a.name?.toLowerCase();
  const bName = b.name?.toLowerCase();
  if (!bName) return 1;
  if (!aName) return -1;
  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
};

export const lastSent = (a: Partial<Invitation>, b: Partial<Invitation>) => {
  const aDate = a.last_sent_at ?? a.created_at;
  const bDate = b.last_sent_at ?? b.created_at;
  if (!bDate) return 1;
  if (!aDate) return -1;
  return new Date(bDate).getTime() - new Date(aDate).getTime();
};

export const addInvitation = (invitation: Partial<Invitation>) =>
  invitationsApi.util.updateQueryData(
    'getAllInvitations',
    undefined,
    (draftInvitations) => {
      if (!invitation.id) {
        return;
      }
      const invitationDuplicated = find({
        items: draftInvitations,
        id: invitation.id,
      });
      if (!invitationDuplicated.found) {
        draftInvitations.push(invitation);
      }
    }
  );

export const addOrUpdateInvitation = (invitation: Partial<Invitation>) =>
  invitationsApi.util.updateQueryData(
    'getAllInvitations',
    undefined,
    (draftInvitations) => merge({ items: draftInvitations, item: invitation })
  );

export const { useGetAllInvitationsQuery } = invitationsApi;
