import Drawer from 'components/Drawer';
import { useTranslation } from 'react-i18next';
import useRempeListener from 'containers/Prescription/Rempe/useRempeListener';
import { useEffect } from 'react';
import api from 'api';
import { Contact } from 'services/contacts';
import { toast } from 'react-toastify';
import Checked from 'components/icons/Checked';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import { prescriptionsFetched } from 'containers/Prescription/actions/Prescription.actions';
import { useDispatch } from 'react-redux';
import RempeNotValidatedView from 'containers/Prescription/components/RempeNotValidatedView';

export interface Props {
  onClose: () => void;
  widgetCredentials: any;
  consultationType?: string;
  consultationId?: string;
  contact?: Partial<Contact>;
  room?: any;
}

const RempePrescriptionIframe = ({
  onClose,
  widgetCredentials,
  consultationType,
  consultationId,
  contact,
  room,
}: Props) => {
  const { t } = useTranslation();

  const { isEvent, rempeEvent, setIsEvent } = useRempeListener();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEvent && ['REMPE_PRESCRIPTION_UPDATE'].includes(rempeEvent)) {
      setIsEvent(false);
      api.prescriptions
        .updateRempe({
          contactId: contact?.id ?? room?.meta?.contact_id,
          consultationId: consultationId,
          consultationType: consultationType,
        })
        .then(() => {
          toast.success(
            <div className="flex items-center gap-3">
              <Checked />
              {t('prescription__update_success')}
            </div>
          );
        })
        .catch(() => {
          toast.error(
            <ErrorToast>{t('shared__errors_generic_message')}</ErrorToast>
          );
        })
        .then(() => {
          api.prescriptions
            .list({
              roomId: room?.room_id,
            })
            .then(({ data }) => {
              dispatch(prescriptionsFetched(data.data));
            });
        });
    }
  }, [isEvent]);

  // patch fix for asisa
  // TODO: check if prescription.validation_status === null should be validated for asisa and rempe pros MQB-12183
  if (
    !widgetCredentials ||
    !widgetCredentials.meta ||
    (Array.isArray(widgetCredentials.meta) &&
      widgetCredentials.meta.length === 0)
  ) {
    return <RempeNotValidatedView onClose={onClose} />;
  }
  //
  const { meta } = widgetCredentials;

  return (
    <div className="flex h-full flex-col">
      <Drawer.Header
        title={t('prescription__create_title')}
        onClose={onClose}
      />
      <iframe
        id="inlineFrameExample"
        title="Inline Frame Example"
        width="100%"
        height="100%"
        src={meta?.session_url}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default RempePrescriptionIframe;
