import IconBoxArrowDown from 'containers/RoomFilters/icons/IconBoxArrowDown';
import IconCalendarX from 'containers/RoomFilters/icons/IconCalendarX';
import IconChat from 'components/icons/Chat';
import IconChatBadge from 'containers/RoomFilters/icons/IconChatBadge';

export const FilterTypes = {
  active: 'ACTIVE',
  closed: 'CLOSED',
  open: 'OPEN',
  pending: 'PENDING',
  unread: 'UNREAD',
  untracked: 'UNTRACKED',
};

export const Filters = {
  [FilterTypes.open]: {
    api: {
      summary: 'pending_count',
      rooms: 'open',
    },
    label: 'filters__chats_open',
    icon: <IconChat />,
  },
  [FilterTypes.unread]: {
    api: {
      summary: 'unread_count',
      rooms: 'unread',
    },
    label: 'filters__chats_unread',
    icon: <IconChatBadge />,
  },
  [FilterTypes.untracked]: {
    api: {
      summary: 'untracked_count',
      rooms: 'untracked',
    },
    label: 'filters__chats_untracked',
    icon: <IconCalendarX />,
  },
  [FilterTypes.closed]: {
    api: {
      summary: 'closed_count',
      rooms: 'closed',
    },
    label: 'filters__chats_closed',
    icon: <IconBoxArrowDown />,
  },
};
