const FlagSolid = (props: any) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.25 37.5C5.91848 37.5 5.60054 37.3683 5.36612 37.1339C5.1317 36.8995 5 36.5815 5 36.25V5.32266C5.00009 4.99514 5.08596 4.67337 5.24908 4.38936C5.41219 4.10535 5.64685 3.86903 5.92969 3.70391C6.875 3.15469 8.77969 2.5 12.5 2.5C15.407 2.5 18.6586 3.64922 21.5273 4.6625C23.8375 5.47891 26.0195 6.25 27.5 6.25C29.4058 6.24415 31.2914 5.85907 33.0469 5.11719C33.2606 5.02695 33.4934 4.99101 33.7245 5.01258C33.9555 5.03415 34.1776 5.11255 34.371 5.2408C34.5644 5.36905 34.723 5.54316 34.8327 5.7476C34.9425 5.95204 35 6.18046 35 6.4125V23.55C34.9997 23.8538 34.9109 24.1508 34.7443 24.4049C34.5778 24.6589 34.3409 24.859 34.0625 24.9805C33.382 25.2781 30.8977 26.25 27.5 26.25C25.6141 26.25 23.2516 25.6922 20.7508 25.1008C17.9398 24.4367 15.0336 23.75 12.5 23.75C9.61953 23.75 8.14531 24.1859 7.5 24.4617V36.25C7.5 36.5815 7.3683 36.8995 7.13388 37.1339C6.89946 37.3683 6.58152 37.5 6.25 37.5Z"
      fill="currentColor"
    />
  </svg>
);

export default FlagSolid;
