import classnames from 'classnames';
import { FC } from 'react';

export interface Props {
  variant?: 'square' | 'circle' | 'rectangle-md' | 'rectangle-lg' | 'custom';
  className?: string;
}

const Skeleton: FC<Props> = ({ className = '', variant = 'custom' }) => (
  <div
    className={classnames(
      'flex bg-gray-light rounded-full',
      {
        square: 'h-8 w-8 rounded-md',
        circle: 'h-8 w-8 rounded-full',
        'rectangle-md': 'h-4 w-16 rounded-full',
        'rectangle-lg': 'h-4 w-24 rounded-full',
        custom: '',
      }[variant],
      className
    )}
  />
);

export default Skeleton;
