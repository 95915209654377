import AccordeonHandler from 'containers/Shared/components/Accordeon/AccordeonHandler';
import {
  AccordeonHeader,
  AccordeonBody,
  AccordeonButton,
} from 'containers/Shared/components/Accordeon/AccordeonPanel';
import TrackingMessageScheduler from 'containers/Inbox/components/RoomListHeader/TrackingMessageScheduler';
import { useTrackings } from 'containers/DetailBar/PatientBar/hooks/trackings.hook';
import TrackingItem from 'containers/DetailBar/PatientBar/components/Trackings/TrackingItem';
import { useTranslation } from 'react-i18next';
import { usePortal } from 'containers/Shared/components/Portal';
import useConfirmDelete from 'utils/useConfirmDelete';

const TrackingsPanel = ({ open, onOpen, onClose, room }) => {
  const { t } = useTranslation();
  const [trackings, destroy, add] = useTrackings(room);
  const portal = usePortal();
  const confimDelete = useConfirmDelete();

  const openTranckingMessageScheduler = (tracking) =>
    portal.open(
      <TrackingMessageScheduler
        tracking={tracking}
        rooms={[room.room_id]}
        onClose={() => portal.close('tracking')}
        onSubmit={() => {
          if (!tracking) add();
          portal.close('tracking');
        }}
        single
      />,
      'tracking'
    );

  const handleDelete = (trackingId, roomId) => {
    confimDelete({
      title: t('sidebar__patient_tracking_delete_title'),
      content: t('sidebar__patient_tracking_delete_content'),
    }).then(() => {
      destroy(trackingId, roomId);
    });
  };

  if (!open) {
    return (
      <AccordeonHandler onOpen={onOpen} badge={trackings?.length}>
        {t('sidebar__patient_tracking_title')}
      </AccordeonHandler>
    );
  }

  return (
    <div>
      <div className="p-4">
        <AccordeonHeader onClose={onClose} badge={trackings?.length}>
          {t('sidebar__patient_tracking_title')}
        </AccordeonHeader>
        <AccordeonBody show={trackings?.length === 0}>
          {t('sidebar__patient_tracking_empty_text')}
        </AccordeonBody>
        <AccordeonButton onClick={openTranckingMessageScheduler}>
          {t('sidebar__patient_tracking_add')}
        </AccordeonButton>
      </div>
      <div className="border-b border-separators">
        {trackings.map((tracking) => (
          <TrackingItem
            key={tracking.id}
            tracking={tracking}
            onDelete={() => handleDelete(tracking.id, room.room_id)}
            onEdit={() => openTranckingMessageScheduler(tracking)}
          />
        ))}
      </div>
    </div>
  );
};

export default TrackingsPanel;
