const format = (
  date?: Date | string,
  locale: string = window.navigator.language ?? 'es-ES',
  options?: Intl.DateTimeFormatOptions
) =>
  !!date
    ? Intl.DateTimeFormat(locale, options).format(new Date(date))
    : undefined;

export const formatDayOfWeekDate = (date?: Date | string, locale?: string) =>
  format(date, locale, { weekday: 'narrow' });

export const formatYearMonthDate = (date?: Date | string, locale?: string) =>
  format(date, locale, { month: 'long', year: 'numeric' })?.replace(
    ' de ',
    ' '
  );

export const formatShortDate = (date?: Date | string, locale?: string) =>
  format(date, locale);

export const formatMediumDate = (date?: Date | string, locale?: string) =>
  format(date, locale, { day: 'numeric', month: 'long' });

export const formatMediumDateExtended = (
  date?: Date | string,
  locale?: string
) => format(date, locale, { weekday: 'long', day: 'numeric', month: 'long' });

export const formatLongDate = (date?: Date | string, locale?: string) =>
  format(date, locale, { weekday: 'long', day: '2-digit', month: '2-digit' });

export const formatTime = (date?: Date | string, locale?: string) =>
  format(date, locale, { hour: '2-digit', minute: '2-digit' });

const getDate = (date?: Date | string): Date | undefined =>
  !date ? undefined : date instanceof Date ? date : new Date(date);

export const isSameDay = (date1?: Date | string, date2?: Date | string) =>
  getDate(date1)?.getFullYear() === getDate(date2)?.getFullYear() &&
  getDate(date1)?.getMonth() === getDate(date2)?.getMonth() &&
  getDate(date1)?.getDate() === getDate(date2)?.getDate();

export const addDays = (date: Date, days: number) => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
};

export const subtractDays = (date: Date, days: number) => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() - days);
  return newDate;
};

export const getFirstDayOfMonth = (day: Date) =>
  new Date(day.getFullYear(), day.getMonth(), 1);

export const getDaysInMonth = ({
  year,
  month,
}: {
  year: number;
  month: number;
}) => {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const getFirstDayNextMonth = (day: Date) =>
  day.getMonth() === 11
    ? new Date(day.getFullYear() + 1, 0, 1)
    : new Date(day.getFullYear(), day.getMonth() + 1, 1);

export const getFirstDayPreviousMonth = (day: Date) =>
  day.getMonth() === 0
    ? new Date(day.getFullYear() - 1, 11, 1)
    : new Date(day.getFullYear(), day.getMonth() - 1, 1);

export const daysOfWeek = () => {
  const week = [];
  const current = new Date();
  current.setDate(current.getDate() - current.getDay() + 1);
  for (var i = 0; i < 7; i++) {
    week.push(formatDayOfWeekDate(new Date(current)));
    current.setDate(current.getDate() + 1);
  }
  return week;
};

export const diffDays = (date1: Date, date2: Date) => {
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.round((date1.getTime() - date2.getTime()) / oneDay);
};

const DATE_UNITS = [
  ['year', 31536000],
  ['month', 2592000],
  ['day', 86400],
  ['hour', 3600],
  ['minute', 60],
  ['second', 1],
];

export type unit = 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second';

export const timeAgoUnits = (
  date?: string | Date | null,
  { unit, filter }: { unit: unit; filter: 'min' | 'strict' } = {
    unit: 'second',
    filter: 'min',
  }
) => {
  if (!date) {
    return undefined;
  }

  const now = Date.now();
  const secondsElapsed = (now - new Date(date).getTime()) / 1000;

  for (const [currentUnit, secondsInUnit] of DATE_UNITS) {
    const matchStrict = filter === 'strict' && unit === currentUnit;
    const matchMin = filter === 'min' && currentUnit === unit;
    if (
      matchStrict ||
      (filter !== 'strict' &&
        (matchMin || Math.abs(secondsElapsed) > secondsInUnit))
    ) {
      const value = Math.floor(secondsElapsed / (secondsInUnit as number));
      return {
        value,
        unit: currentUnit as Partial<unit>,
      };
    }
  }
};
