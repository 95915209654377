const PhoneCircleArrow = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.20891 8.58214C6.7889 9.79016 7.57956 10.9224 8.58088 11.9237C9.58219 12.925 10.7144 13.7156 11.9224 14.2956C12.0263 14.3455 12.0783 14.3705 12.144 14.3896C12.3776 14.4577 12.6645 14.4088 12.8624 14.2671C12.9181 14.2273 12.9657 14.1797 13.0609 14.0844C13.3523 13.7931 13.498 13.6474 13.6444 13.5521C14.1968 13.193 14.909 13.193 15.4614 13.5521C15.6079 13.6474 15.7536 13.7931 16.0449 14.0844L16.2073 14.2468C16.6502 14.6897 16.8716 14.9111 16.9919 15.1489C17.2311 15.6219 17.2311 16.1804 16.9919 16.6534C16.8716 16.8912 16.6502 17.1126 16.2073 17.5555L16.0759 17.6869C15.6346 18.1282 15.4139 18.3489 15.1139 18.5174C14.7809 18.7045 14.2639 18.8389 13.882 18.8378C13.5379 18.8368 13.3027 18.77 12.8323 18.6365C10.3045 17.919 7.91921 16.5653 5.92923 14.5753C3.93924 12.5853 2.58551 10.2 1.86804 7.67221C1.73453 7.20184 1.66778 6.96665 1.66676 6.62253C1.66562 6.24068 1.80009 5.7236 1.9871 5.39068C2.15564 5.09065 2.37632 4.86998 2.81767 4.42862L2.94903 4.29726C3.3919 3.85439 3.61334 3.63296 3.85116 3.51267C4.32412 3.27345 4.88268 3.27345 5.35564 3.51267C5.59346 3.63296 5.8149 3.85439 6.25777 4.29726L6.42016 4.45965C6.7115 4.75099 6.85716 4.89666 6.9524 5.04314C7.31156 5.59555 7.31156 6.3077 6.9524 6.86011C6.85716 7.00659 6.7115 7.15226 6.42016 7.4436C6.3249 7.53886 6.27727 7.58649 6.2374 7.64217C6.09572 7.84002 6.0468 8.12691 6.11491 8.36053C6.13407 8.42628 6.15902 8.47823 6.20891 8.58214Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.7891 8.44626C17.725 7.76513 18.3333 6.66181 18.3333 5.41659C18.3333 3.34773 16.6543 1.67059 14.5833 1.67059H14.3627M14.5833 9.16258C12.5122 9.16258 10.8333 7.48544 10.8333 5.41659C10.8333 4.17136 11.4415 3.06804 12.3774 2.38691M14.1421 9.99992L15.0244 9.11851L14.1421 8.2371M15.0244 2.59607L14.1421 1.71466L15.0244 0.833252"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PhoneCircleArrow;
