import {
  changeChatProfessionalStatus,
  changeVideoCallProfessionalStatus,
  updateAvailable,
  updatePrescriptionValidationStatus,
} from 'containers/Inbox/actions/Session.actions';
import { reconnectCurrentRoom } from 'containers/Room/actions/JoinRoom.actions';

export const onReconnect = (dispatch) => () => dispatch(reconnectCurrentRoom());
export const onUpdateAvailable = (dispatch) => () =>
  dispatch(updateAvailable());

export const onProfessionalWentOnline = (dispatch) => () =>
  dispatch(changeChatProfessionalStatus('1'));

export const onProfessionalWentOffline = (dispatch) => () =>
  dispatch(changeChatProfessionalStatus('0'));

export const onProfessionalVideoCallWentOnline = (dispatch) => () =>
  dispatch(changeVideoCallProfessionalStatus('online'));

export const onProfessionalVideoCallWentOffline = (dispatch) => () =>
  dispatch(changeVideoCallProfessionalStatus('offline'));

export const onPrescriptionValidationUpdated =
  (dispatch) =>
  ({ status }) =>
    dispatch(updatePrescriptionValidationStatus({ status }));
