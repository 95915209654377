import { actionTypes } from 'containers/Inbox/actions/Session.actions';
import { createSelector } from 'reselect';
import { VALIDATE as KYC_VALIDATE } from 'state/kyc.slice';

const initialState = {
  updateAvailable: false,
  isLoading: true,
  sentences: [],
  profile: undefined,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONSOLE_UPDATED:
      return {
        ...state,
        updateAvailable: true,
      };
    case actionTypes.CONSOLE_REFRESHED:
      return {
        ...state,
        updateAvailable: false,
      };
    case actionTypes.FETCHING_PROFILE:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.PROFILE_FETCHED:
      return {
        ...state,
        isLoading: false,
        updateAvailable: false,
        profile: {
          ...action.payload,
        },
      };
    case actionTypes.KYC_VALIDATED:
      return {
        ...state,
        profile: { ...state.profile, kyc_status: 'success' },
      };
    case actionTypes.PROFILE_UPDATED:
      return {
        ...state,
        isLoading: false,
        profile: { ...state.profile, ...action.payload },
      };
    case actionTypes.SENTENCE_DELETED:
      return {
        ...state,
        sentences: state.sentences.filter(({ id }) => action.payload.id !== id),
      };
    case actionTypes.SENTENCE_ADDED:
      return {
        ...state,
        sentences: [...state.sentences, action.payload],
      };
    case actionTypes.SENTENCE_EDITED:
      return {
        ...state,
        sentences: [
          ...state.sentences.map((sentence) =>
            sentence.id === action.payload?.id ? action.payload : sentence
          ),
        ],
      };
    case actionTypes.PROFILE_REFRESHED:
      return {
        isLoading: false,
        sentences: [],
        profile: {
          ...state.profile,
          name: action.payload.name,
          hash: action.payload.hash,
          avatar: action.payload.avatar,
          status: action.payload.status,
        },
      };
    case KYC_VALIDATE:
      return {
        ...state,
        profile: {
          ...state.profile,
          kyc_status: 'sent',
        },
      };
    case actionTypes.SENTENCES_FETCHED:
      return {
        ...state,
        sentences: action.payload,
      };
    case actionTypes.PRESCRIPTION_VALIDATION_UPDATED:
      return {
        ...state,
        profile: {
          ...state.profile,
          prescription_validation_status: action.payload,
        },
      };
    case actionTypes.CHANGE_CHAT_PROFESSIONAL_STATUS:
      return {
        ...state,
        profile: {
          ...state.profile,
          status: action.payload,
        },
      };
    case actionTypes.CHANGE_VIDEOCALL_PROFESSIONAL_STATUS:
      return {
        ...state,
        profile: {
          ...state.profile,
          immediate_video_call: {
            ...state.profile.immediate_video_call,
            status: action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export const isOnboardingCompleted = createSelector(
  (state) => state.session?.profile,
  (profile) =>
    profile?.name &&
    profile?.lastName &&
    profile?.email &&
    profile?.workPracticeCountry &&
    profile?.speciality_id
);

const features = (state) => state?.session?.profile?.features;

export const canChargeConsultations = createSelector(
  features,
  (state) => state.session.profile.has_inmediate_service,
  (features, hasImmediateService) =>
    features?.payments?.can_charge_consultations && !hasImmediateService
);

export const getCanPrescribe = createSelector(
  features,
  (features) => features?.prescriptions?.can_create_prescriptions
);

export const canShareProfile = createSelector(
  features,
  (features) => features?.invite?.can_share_profile
);

export const publicProfileUrl = createSelector(
  (state) => state?.session?.profile?.public_profile_url,
  (publicProfileUrl) => publicProfileUrl
);

export const isPaymentEnabled = createSelector(
  (state) => state.session?.profile,
  (profile) => profile.features?.payments?.can_charge_consultations
);

export const getCanPrescribeDiagnosticTest = createSelector(
  features,
  (features) =>
    features?.diagnostic_test_prescriptions?.is_diagnostic_test_prescriber
);

export default reducer;
