import React from 'react';

export default (props) => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33464 11.6666C1.33464 12.4 1.93464 13 2.66797 13H9.33463C10.068 13 10.668 12.4 10.668 11.6666V2.33331C11.0346 2.33331 11.3346 2.03331 11.3346 1.66665C11.3346 1.29998 11.0346 0.99998 10.668 0.99998H9.33463H9.0013L8.52797 0.526646C8.40797 0.406646 8.23463 0.333313 8.0613 0.333313H3.9413C3.76797 0.333313 3.59464 0.406646 3.47464 0.526646L3.0013 0.99998H2.66797H1.33464C0.967969 0.99998 0.667969 1.29998 0.667969 1.66665C0.667969 2.03331 0.967969 2.33331 1.33464 2.33331V11.6666ZM3.33464 2.33331H8.66797C9.03463 2.33331 9.33463 2.63331 9.33463 2.99998V11C9.33463 11.3666 9.03463 11.6666 8.66797 11.6666H3.33464C2.96797 11.6666 2.66797 11.3666 2.66797 11V2.99998C2.66797 2.63331 2.96797 2.33331 3.33464 2.33331ZM4.0013 4.33331C4.0013 3.96512 4.29978 3.66665 4.66797 3.66665C5.03616 3.66665 5.33464 3.96512 5.33464 4.33331V9.66665C5.33464 10.0348 5.03616 10.3333 4.66797 10.3333C4.29978 10.3333 4.0013 10.0348 4.0013 9.66665V4.33331ZM7.33464 3.66665C6.96645 3.66665 6.66797 3.96512 6.66797 4.33331V9.66665C6.66797 10.0348 6.96645 10.3333 7.33464 10.3333C7.70282 10.3333 8.0013 10.0348 8.0013 9.66665V4.33331C8.0013 3.96512 7.70282 3.66665 7.33464 3.66665Z"
      fill="currentColor"
    />
  </svg>
);
