import { Room } from 'api/rooms';
import Drawer from 'components/Drawer';
import { useEffect, useState } from 'react';
import { Contact } from 'services/contacts';
import ContactSelector from 'containers/Contacts/ContactSelector';
import { useTranslation } from 'react-i18next';
import OsiguPrescriptionDrawer from 'containers/Prescription/PrescriptionDrawer';
import { usePortal } from 'containers/Shared/components/Portal';
import api from 'api';
import ConsultationsApiClient from 'legacy/Services/Consultations';
import useQuery from 'utils/useQuery';
import Loading from 'components/Loading';
import { useSelector } from 'react-redux';
import { getPrescriptionValidationStatus } from 'state/profile/index.selectors';
import ValidationStatusModal from 'containers/Prescription/components/ValidationStatusModal';
import RempePrescriptionIframe from 'containers/Prescription/Rempe/RempePrescriptionIframe';
import { AxiosError, isAxiosError } from 'axios';
import { SetupErrorCode } from 'api/prescriptions';
import RempeNotValidatedView from 'containers/Prescription/components/RempeNotValidatedView';
import ValidationFailedView from 'containers/Prescription/components/ValidationFailedView';
import UnknownErrorView from 'containers/Prescription/components/UnknownErrorView';
import NotValidatedView from 'containers/Prescription/components/NotValidatedView';
import { match_ } from 'utils/match';
import { MEDIQUO_PRO_SUPPORT_EMAIL } from 'config/constants';
import WarningModal from 'components/Modal/Warning';
import Warning from 'components/icons/Warning';

export interface Props {
  room?: Room;
  consultationType?: string;
  consultationId?: string;
  onClose: () => void;
}

export interface ProviderProps {
  room?: Room;
  contact?: Partial<Contact>;
  onClose: () => void;
  consultationType?: string;
  consultationId?: string;
  //patch fix for asisa
  status: string | null;
}

type Screen = 'idle' | 'contacts' | 'prescription';

const PrescriptionDrawerIndex = ({
  room,
  consultationType,
  consultationId,
  onClose,
}: Props) => {
  const [screen, setScreen] = useState<Screen>('idle');
  const [contact, setContact] = useState<Partial<Contact>>();
  const { t } = useTranslation();
  const status = useSelector(getPrescriptionValidationStatus);
  const portal = usePortal();

  useEffect(() => {
    if (!room) {
      setScreen('contacts');
    }
    if (room || contact) {
      setScreen('prescription');
    }
  }, []);

  useEffect(() => {
    if (room || contact) {
      setScreen('prescription');
    }
  }, [room, contact]);

  if (status === 'pending' || status === 'failed') {
    return (
      <ValidationStatusModal
        status={status}
        onClose={onClose}
        continueLink={`mailto:${MEDIQUO_PRO_SUPPORT_EMAIL}`}
      />
    );
  }

  const createPrescription = (contact: Partial<Contact>) => {
    if (!contact.features?.can_be_appointed) {
      portal.open(
        <WarningModal
          icon={<Warning />}
          title={t('appointments__no_access_modal_title')}
          text={t('appointments__no_access_modal_text')}
          button={t('appointments__no_access_modal_button')}
          onClose={() => portal.close('alert')}
        />,
        'alert'
      );
      return;
    }

    setContact(contact);
    setScreen('prescription');
  };

  return (
    <Drawer widthClassName="w-[800px]" onClose={onClose} onBackdrop={onClose}>
      {({ close }: { close: () => void }) =>
        ({
          idle: <LoadingDrawer />,
          contacts: (
            <ContactSelector
              title={t('prescription__contacts_title')}
              enabled={screen === 'contacts'}
              onSelected={createPrescription}
              onClose={close}
            />
          ),
          prescription: (
            <PrescriptionDrawerProvider
              room={room}
              contact={contact}
              onClose={onClose}
              consultationType={consultationType}
              consultationId={consultationId}
              //patch fix for asisa
              status={status}
            />
          ),
        }[screen])
      }
    </Drawer>
  );
};

const PrescriptionDrawerProvider = ({
  room,
  contact,
  onClose,
  consultationType,
  consultationId,
  //patch fix for asisa
  status,
}: ProviderProps) => {
  const {
    widgetCredentials,
    isLoading: isLoadingCredentials,
    error,
  } = useWidgetSetup({
    contactId: room?.meta?.contact_id ?? contact?.id,
    patientHash: room?.meta?.hash,
  });

  const [provider, setProvider] = useState<any>('idle');

  useEffect(() => {
    // PATCH FIX TODO:check if prescription.validation_status === null should be "validated" for asisa and rempe pros MQB-12183
    if (widgetCredentials) {
      if (widgetCredentials?.provider) {
        return setProvider(widgetCredentials?.provider);
      }
      setProvider('osigu');
      // patch fix for asisa
    } else if (status === null) {
      return setProvider('rempe');
    }
    //
  }, [widgetCredentials]);

  if (error && isAxiosError<{ errorCode: SetupErrorCode }, unknown>(error)) {
    return match_({
      value: error.response?.data.errorCode,
      branches: [
        {
          pattern: SetupErrorCode.Unauthorized,
          result: () => <NotValidatedView onClose={onClose} />,
        },
        {
          pattern: SetupErrorCode.ProfessionalNotFound,
          result: () => <RempeNotValidatedView onClose={onClose} />,
        },
        {
          pattern: SetupErrorCode.PatientFieldsError,
          result: () => <ValidationFailedView onClose={onClose} />,
        },
        {
          pattern: SetupErrorCode.GeneralError,
          result: () => <UnknownErrorView onClose={onClose} />,
        },
      ],
      fallback: () => <UnknownErrorView onClose={onClose} />,
    });
  }

  if (isLoadingCredentials) {
    return <LoadingDrawer />;
  }

  if (provider === 'idle') {
    return <LoadingDrawer />;
  }

  if (provider === 'rempe') {
    return (
      <RempePrescriptionIframe
        onClose={() => {
          onClose?.();
        }}
        widgetCredentials={widgetCredentials}
        consultationType={consultationType}
        consultationId={consultationId}
        contact={contact}
        room={room}
      />
    );
  }

  return (
    <OsiguPrescriptionDrawer
      room={room}
      consultationType={consultationType}
      consultationId={consultationId}
      contact={contact}
      onClose={() => {
        onClose?.();
      }}
      widgetCredentials={widgetCredentials}
    />
  );
};

export const usePrescriptionDrawer = () => {
  const portal = usePortal();

  const openPrescriptionDrawer = (
    {
      room,
      consultationType,
      consultationId,
      onClose,
    }: {
      room?: Room;
      consultationType?: string;
      consultationId?: string;
      onClose?: () => void;
    } = {
      room: undefined,
      consultationType: undefined,
      consultationId: undefined,
      onClose: () => {},
    }
  ) => {
    portal.open(
      <PrescriptionDrawerIndex
        room={room}
        consultationType={consultationType}
        consultationId={consultationId}
        onClose={() => {
          portal.close('prescription');
          onClose?.();
        }}
      />,
      'prescription'
    );
  };

  return { openPrescriptionDrawer };
};

export const useCredentials = ({
  widgetCredentials,
}: {
  widgetCredentials: any;
}) => {
  const { providerCredential, isLoading: isLoadingProviders } =
    usePrescriptionCredentials(widgetCredentials?.provider_name);

  const [credentials, setCredentials] = useState<any>(providerCredential);

  useEffect(() => {
    const newCredentials = (providerCredential as any)?.data;
    setCredentials(newCredentials ? JSON.parse(newCredentials) : undefined);
  }, [providerCredential]);
  return {
    credentials,
    setCredentials,
    widgetCredentials,
    isLoading: isLoadingProviders,
  };
};

const useWidgetSetup = (params: {
  contactId?: string | null;
  patientHash?: string | null;
}) => {
  const { data, isLoading, error } = useQuery(api.prescriptions.setup, params);

  const widgetCredentials = (data as any)?.data?.data;

  return { widgetCredentials, isLoading, error: error as AxiosError | null };
};

const usePrescriptionCredentials = (providerName: string) => {
  const [providerCredential, setProviderCredential] = useState();
  const [providerCredentials, setProviderCredentials] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ConsultationsApiClient.prescription_provider
      .index()
      .then((data: any) => setProviderCredentials(data?.data))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setProviderCredential(
      providerCredentials?.find((item: any) => item.provider === providerName)
    );
  }, [providerName, providerCredentials]);

  return { providerCredential, isLoading };
};

export const LoadingDrawer = () => {
  return (
    <div className="flex h-screen items-center justify-center">
      <Loading />
    </div>
  );
};

export default PrescriptionDrawerIndex;
