const Play = (props: any) => (
  <svg
    width="14"
    height="19"
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.49231 0.440213C2.268 0.280503 2.00405 0.185615 1.7294 0.165943C1.45474 0.146271 1.17996 0.202574 0.935178 0.328685C0.690393 0.454797 0.485039 0.64585 0.341618 0.880912C0.198196 1.11597 0.12224 1.38598 0.12207 1.66134V16.6629C0.12224 16.9382 0.198196 17.2082 0.341618 17.4433C0.485039 17.6784 0.690393 17.8694 0.935178 17.9955C1.17996 18.1216 1.45474 18.1779 1.7294 18.1583C2.00405 18.1386 2.268 18.0437 2.49231 17.884L12.9934 10.3832C13.1879 10.2445 13.3464 10.0613 13.4557 9.84886C13.5651 9.63646 13.6222 9.40101 13.6222 9.16211C13.6222 8.92321 13.5651 8.68776 13.4557 8.47536C13.3464 8.26296 13.1879 8.07975 12.9934 7.94098L2.49231 0.440213Z"
      fill="currentColor"
    />
  </svg>
);
export default Play;
