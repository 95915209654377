import { useTranslation } from 'react-i18next';
import Api from 'api';
import { toast } from 'react-toastify';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import Checked from 'components/icons/Checked';
import { useDispatch, useSelector } from 'react-redux';
import { addReport } from 'services/reports.legacy';
import { reportCreated } from 'containers/MedicalReport/actions';
import { Fields } from 'containers/MedicalReport';

export interface Props {
  patient_hash: string;
  onSuccess: (onClose?: () => void) => void;
  consultation_type?: string;
  consultation_id?: string;
}

const useReport = ({
  patient_hash,
  onSuccess,
  consultation_type,
  consultation_id,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const professionalName = useSelector(
    (state: any) => state.session?.profile?.name
  );

  const create = (
    {
      objective: objective_data,
      subjective: subjective_data,
      analysis,
      diagnostic_id,
      plan,
    }: Fields,
    onClose?: () => void
  ) =>
    Api.reports
      .create({
        patient_hash,
        consultation_type,
        consultation_id,
        objective_data,
        subjective_data,
        analysis,
        diagnostic_id,
        plan,
        is_public: true,
      })
      .then(
        ({
          data: {
            data: { report_uuid },
          },
        }) =>
          handleOnSuccess(
            {
              report_uuid,
              objective_data,
              subjective_data,
              analysis,
              plan,
              is_public: true,
              diagnostic_id,
            },
            onClose
          )
      )
      .catch(() => handleOnError());

  const handleOnSuccess = (
    {
      report_uuid,
      objective_data,
      subjective_data,
      analysis,
      plan,
      diagnostic_id,
      is_public,
    }: {
      report_uuid: string;
      objective_data: string;
      subjective_data: string;
      analysis?: string;
      diagnostic_id: string;
      plan: string;
      is_public: boolean;
    },
    onClose?: () => void
  ) => {
    onSuccess(onClose);
    dispatch(reportCreated());
    dispatch(
      addReport({
        patient_hash,
        report: {
          uuid: report_uuid,
          objective_data,
          subjective_data,
          analysis,
          diagnostic_id,
          plan,
          is_public,
          created_at: new Date().toISOString(),
          professional: {
            name: professionalName,
          },
        },
      })
    );
    toast.success(
      <div className="flex items-center gap-3">
        <Checked />
        {t('report__modal_form_success')}
      </div>
    );
  };

  const handleOnError = () =>
    toast.error(<ErrorToast>{t('shared__errors_generic_message')}</ErrorToast>);

  return { create };
};

export default useReport;
