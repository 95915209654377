import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useListener from 'utils/useListener';

export interface Props {
  when: boolean;
  message?: string;
}

const useBeforeUnloadPrompt = ({
  when,
  message = 'Changes you made may not be saved.',
}: Props) => {
  const history = useHistory();

  const self = useRef<any>(null);

  const onWindowOrTabClose = (event: BeforeUnloadEvent) => {
    if (!when) {
      return;
    }

    if (typeof event === 'undefined') {
      event = window.event as BeforeUnloadEvent;
    }

    if (event) {
      event.returnValue = message;
    }

    return message;
  };

  useEffect(() => {
    if (when) {
      self.current = history.block(message);
    } else {
      self.current = null;
    }

    return () => {
      if (self.current) {
        self.current();
        self.current = null;
      }
    };
  }, [message, when, onWindowOrTabClose]);

  useListener({
    listener: 'beforeunload',
    dependencies: [message, when, onWindowOrTabClose],
    onEvent: onWindowOrTabClose,
  });
};

export default useBeforeUnloadPrompt;
