import { useTranslation, Trans } from 'react-i18next';

export const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <p className="font-medium text-gray-medium text-2xs">
      <Trans i18nKey="appointments__payment_form_terms_and_conditions">
        Al enviar la cita aceptas los
        <a
          href={t('appointments__payment_form_terms_and_conditions_href')}
          target="_blank"
          rel="noreferrer"
          className="underline focus:outline-none focus-visible:outline-black"
        >
          Términos y condiciones
        </a>
        del servicio
      </Trans>
    </p>
  );
};
