import classNames from 'classnames';

import styles from 'containers/Room/components/RoomBody/Message/VoiceMessage/Timeline.module.css';
import { useMemo } from 'react';

export interface TimelineProps extends React.ComponentPropsWithoutRef<'input'> {
  duration: number;
  currentProgress: number;
  isMine: boolean;
}

const Timeline = ({
  duration,
  currentProgress,
  className,
  isMine,
  ...props
}: TimelineProps) => {
  const flooredProgress = Math.floor(currentProgress);
  const flooredDuration = Math.floor(duration);

  const timelineStyles = useMemo(() => {
    const percentage = (flooredProgress / duration) * 100;

    const fillColor = '#0075FF';
    const emptyColor = isMine ? 'white' : '#ECEFF1';

    return {
      background: `linear-gradient(to right, ${fillColor} ${percentage}%, ${emptyColor} ${percentage}%)`,
    };
  }, [currentProgress]);

  return (
    <input
      className={classNames(
        styles.input,
        isMine ? styles.inputMine : styles.inputOther,
        className
      )}
      type="range"
      min={0}
      max={flooredDuration}
      value={flooredProgress}
      style={timelineStyles}
      {...props}
    />
  );
};

export default Timeline;
