const ExclamationCircle = (props: any) => (
  <svg fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      d="M8 5c.33 0 .6.27.6.6V8c0 .33-.27.6-.6.6-.33 0-.6-.27-.6-.6V5.6c0-.33.27-.6.6-.6zm-.006-3A5.997 5.997 0 002 8c0 3.312 2.682 6 5.994 6A6.003 6.003 0 0014 8c0-3.312-2.688-6-6.006-6zM8 12.8A4.799 4.799 0 013.2 8c0-2.652 2.148-4.8 4.8-4.8 2.652 0 4.8 2.148 4.8 4.8 0 2.652-2.148 4.8-4.8 4.8zm.6-1.8H7.4V9.8h1.2V11z"
    ></path>
  </svg>
);

export default ExclamationCircle;
