import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/Button';
import Modal from 'components/Modal';

export interface Props {
  onClose: () => void;
}

const InvitationError: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} width="narrow">
      <Modal.Body>
        <p className="my-4 text-primary">
          {t('invite__vithas_not_found_title')}
        </p>
        <p className="py-4">
          <Trans i18nKey="invite__vithas_not_found_text">
            Se ha producido un error en la invitación o no se ha encontrado al
            paciente con los datos facilitados. Comprueba que los datos son
            correctos y vuelve a intentarlo, o envía los datos del paciente a
            <a
              href="mailto:okvithas@vithas.es"
              target="_blank"
              rel="noreferrer"
              className="underline text-primary"
            >
              okvithas@vithas.es
            </a>
            con el asunto “Error en Invitación chat médico”
          </Trans>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>
          {t('invite__vithas_not_found_button')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvitationError;
