import AuthenticationGate from 'containers/Login/Authentication.Gate';
import { Switch, Route } from 'react-router';
import { lazy, Suspense } from 'react';

import NoAuthenticatedRoute from 'containers/Login/NoAuthenticatedRoute';
import Loading from 'components/Loading';
import AppointmentMeet from 'pages/AppointmentMeets';

const Console = lazy(() => import('pages/main'));
const Chat = lazy(() => import('pages/main/Chat'));
const Login = lazy(() => import('containers/Login/SignIn'));
const SmsSignIn = lazy(() => import('pages/SmsSignIn'));
const Landing = lazy(() => import('containers/Login/Landing'));
const Profile = lazy(() => import('pages/main/Profile'));
const ResetPassword = lazy(() => import('containers/Login/ResetPassword'));
const ResetPasswordToken = lazy(() =>
  import('containers/Login/ResetPasswordToken')
);
const VideoCall = lazy(() => import('containers/VideoCall'));
const Onboarding = lazy(() => import('containers/Onboarding'));
const Logout = lazy(() => import('pages/Logout'));
const Invitations = lazy(() => import('pages/main/Contacts/Invitations'));
const Patients = lazy(() => import('pages/main/Contacts/Patients'));
const Professionals = lazy(() => import('pages/main/Contacts/Professionals'));
const AppointmentsPage = lazy(() => import('pages/main/Appointments'));
const WaitingRoomPage = lazy(() => import('pages/main/WaitingRoom'));
const ContactsPage = lazy(() => import('pages/main/Contacts'));

export const Routes = () => (
  <Switch>
    <NoAuthenticatedRoute exact path="/">
      <Suspense fallback={<Loading />}>
        <SmsSignIn />
      </Suspense>
    </NoAuthenticatedRoute>
    <NoAuthenticatedRoute exact path="/landing">
      <Suspense fallback={<Loading />}>
        <Landing />
      </Suspense>
    </NoAuthenticatedRoute>
    <NoAuthenticatedRoute exact path="/admin">
      <Suspense fallback={<Loading />}>
        <Login />
      </Suspense>
    </NoAuthenticatedRoute>
    <Route exact path="/onboarding">
      <Suspense fallback={<Loading />}>
        <Onboarding />
      </Suspense>
    </Route>
    <Route exact path="/password/reset">
      <Suspense fallback={<Loading />}>
        <ResetPassword />
      </Suspense>
    </Route>
    <Route path="/password/reset/:token">
      <Suspense fallback={<Loading />}>
        <ResetPasswordToken />
      </Suspense>
    </Route>

    <AuthenticationGate>
      <Switch>
        <Route path="/(chat|contacts|profile|appointments|billing|invitations|patients|professionals|appointment-meet|waiting-room|call)">
          <Suspense fallback={<Loading />}>
            <Console>
              <Route path="/chat/:roomId?">
                <Suspense fallback={null}>
                  <Chat />
                </Suspense>
              </Route>

              <Route path="/appointment-meet/:appointmentId">
                <Suspense fallback={null}>
                  <AppointmentMeet />
                </Suspense>
              </Route>
              <Route exact path="/call/:appointmentId">
                <Suspense fallback={null}>
                  <AppointmentMeet />
                </Suspense>
              </Route>
              <Route path="/appointments">
                <Suspense fallback={null}>
                  <AppointmentsPage />
                </Suspense>
              </Route>
              <Route path="/contacts">
                <Suspense fallback={null}>
                  <ContactsPage />
                </Suspense>
              </Route>
              <Route path="/profile">
                <Suspense fallback={null}>
                  <Profile />
                </Suspense>
              </Route>
              <Route path="/invitations">
                <Suspense fallback={null}>
                  <Invitations />
                </Suspense>
              </Route>
              <Route path="/patients">
                <Suspense fallback={null}>
                  <Patients />
                </Suspense>
              </Route>
              <Route path="/professionals">
                <Suspense fallback={null}>
                  <Professionals />
                </Suspense>
              </Route>
              <Route path="/waiting-room">
                <Suspense fallback={null}>
                  <WaitingRoomPage />
                </Suspense>
              </Route>
            </Console>
          </Suspense>
        </Route>

        <Route exact path="/logout">
          <Suspense fallback={<Loading />}>
            <Logout />
          </Suspense>
        </Route>
        <Route exact path="/videocall/:roomId">
          <Suspense fallback={<Loading />}>
            <VideoCall />
          </Suspense>
        </Route>
      </Switch>
    </AuthenticationGate>
  </Switch>
);
