import api from 'api';

const DELETE_MESSAGE = 'ROOM/DELETE_MESSAGE';

export const actionTypes = {
  DELETE_MESSAGE,
};

export const deleteOwnMessage = (messageId) => {
  const isMessageFromCurrentRoom = true;
  const isOwnMessage = true;
  return (dispatch) => {
    api.rooms
      .deleteMessage(messageId)
      .then(() =>
        dispatch(
          messageDeleted(messageId, isOwnMessage, isMessageFromCurrentRoom)
        )
      );
  };
};

export const getDeletedMessage = (message) => {
  return (dispatch, getState) => {
    const state = getState();
    const { room_id, message_id, user_hash } = message;
    const profile = state.session.profile;

    const isMessageFromCurrentRoom =
      parseInt(state.console?.current?.room_id) === parseInt(room_id);
    const isOwnMessage = profile.hash === user_hash;
    return dispatch(
      messageDeleted(message_id, isOwnMessage, isMessageFromCurrentRoom)
    );
  };
};

export const messageDeleted = (
  message_id,
  isOwnMessage,
  isMessageFromCurrentRoom
) => {
  return {
    type: DELETE_MESSAGE,
    payload: { message_id, isOwnMessage, isMessageFromCurrentRoom },
  };
};
