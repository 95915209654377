import { useTranslation } from 'react-i18next';
import BlockIcon from 'containers/Room/icons/BlockIcon';

const PatientBlockedBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center px-4 py-2 border shadow text-negative text-md rounded-24 bg-negative-light border-dark-border">
      <BlockIcon className="mr-3" />
      <div>{t('room__patient_blocked_banner_text')}</div>
    </div>
  );
};

export default PatientBlockedBanner;
