import { browserName } from 'react-device-detect';
import LogoAppIcon from 'components/icons/LogoAppIcon';

export const NoOptimizedBrowserModal = ({ onClose }) => {
  return (
    <NoOptimizedBrowser
      backdrop={
        <div
          onClick={onClose}
          className="absolute inset-0 opacity-75 bg-gray-medium"
        />
      }
    />
  );
};

export const NotOptimizedForBrowserPage = () => {
  return (
    <NoOptimizedBrowser
      backdrop={<div className="absolute inset-0 opacity-90" />}
    />
  );
};

const NoOptimizedBrowser = ({ backdrop }) => (
  <div className="fixed inset-x-0 bottom-0 z-10 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
    <div className="fixed inset-0 transition-opacity">{backdrop}</div>
    <div className="max-w-xl p-10 mt-8 overflow-hidden transition-all transform bg-white shadow-xl text-dark rounded-24 sm:w-full">
      <div className="flex flex-col items-center text-center">
        <div className="flex items-center justify-center mb-8 rounded-lg">
          <LogoAppIcon />
        </div>
        <div className="mb-6 text-2xl font-medium text-primary">
          Mediquo PRO no está optimizado para {browserName}
        </div>
        <div className="mb-12">
          Para obtener la experiencia completa, por favor utilízalo en Chrome.
        </div>
        <a
          className="flex items-center h-12 px-6 font-medium text-white uppercase border rounded-lg bg-primary border-primary focus:outline-none hover:bg-primary-dark"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.google.com/chrome/"
        >
          Ir a Chrome
        </a>
      </div>
    </div>
  </div>
);
