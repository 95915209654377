import { createSlice } from '@reduxjs/toolkit';
export interface LocalMedicalReport {
  id: string;
  room_id: number;
  subjective: string;
  objective: string;
  diagnostic_id: string;
  plan?: string;
  consultation_id?: string;
  consultation_type?: string;
}

interface MedicalReportsState {
  medicalReports: LocalMedicalReport[];
  automaticReport: boolean;
  automaticReportStatus?:
    | 'idle'
    | 'loading'
    | 'finished'
    | 'error'
    | 'cancelled';
  isRecordable: boolean;
  automaticReportDraft: LocalMedicalReport | null;
}

const initialState: MedicalReportsState = {
  automaticReport: false,
  medicalReports: [],
  automaticReportStatus: 'idle',
  isRecordable: false,
  automaticReportDraft: null,
};

const slice = createSlice({
  name: 'medicalReports',
  initialState,
  reducers: {
    fetchMedicalReports: (state: MedicalReportsState, action) => {
      state.medicalReports = action.payload;
    },
    updateAutomaticReport: (state: MedicalReportsState, action) => {
      switch (action.payload) {
        case 'start':
          state.automaticReportStatus = 'loading';
          state.automaticReport = true;
          break;
        case 'finished':
          state.automaticReportStatus = 'finished';
          state.automaticReport = true;
          break;
        case 'error':
          state.automaticReportStatus = 'error';
          break;
        case 'cancel':
          state.automaticReportStatus = 'cancelled';
          state.automaticReport = false;
          state.automaticReportDraft = null;
          break;
        case 'reset':
        default:
          state.automaticReportStatus = 'idle';
          state.automaticReport = false;
          state.automaticReportDraft = null;

          break;
      }
    },
    resetAutomaticReport: (state: MedicalReportsState) => {
      state.automaticReport = false;
      state.automaticReportStatus = 'idle';
    },
    addMedicalReport: (state: MedicalReportsState, action) => {
      const index = state.medicalReports.findIndex(
        (item: LocalMedicalReport) => item.room_id === action.payload.room_id
      );
      if (index !== -1) {
        state.medicalReports[index] = action.payload;
      } else {
        state.medicalReports.push(action.payload);
      }
    },
    updateMedicalReport: (state: MedicalReportsState, action) => {
      const index = state.medicalReports.findIndex(
        (item: LocalMedicalReport) => item.id === action.payload.id
      );
      state.medicalReports[index] = action.payload;
    },
    deleteMedicalReport: (state: MedicalReportsState, action) => {
      state.medicalReports = state.medicalReports.filter(
        (item: LocalMedicalReport) => item.id !== action.payload.id
      );
    },
    updateIsRecordable: (state: MedicalReportsState, action) => {
      state.isRecordable = action.payload.isRecordable;
    },
    addAutomaticReportDraft: (state: MedicalReportsState, action) => {
      state.automaticReportDraft = action.payload;
    },
  },
});

export const {
  addMedicalReport,
  deleteMedicalReport,
  fetchMedicalReports,
  updateMedicalReport,
  updateAutomaticReport,
  updateIsRecordable,
  addAutomaticReportDraft,
} = slice.actions;
export default slice.reducer;
