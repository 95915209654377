import find from 'utils/find';

export interface Props<K> {
  items: K[];
  item: K;
  idAttribute?: string;
}

const merge = <K>({ items, item, idAttribute = 'id' }: Props<K>) => {
  if (!(item as any).id) {
    return;
  }
  const finded = find({ items, id: (item as any)?.[idAttribute] });
  if (finded.found) {
    items[finded.index] = {
      ...items[finded.index],
      ...item,
    };
  } else {
    items.push(item);
  }
  return items;
};

export default merge;
