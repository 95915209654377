const MedicalPaper = (props: any) => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 0L16 6V18C16 19.1 15.1 20 14 20H1.99C0.89 20 0 19.1 0 18L0.0100002 2C0.0100002 0.9 0.9 0 2 0H10ZM9 2H2V18H14V7H9V2ZM8.8 8C9.242 8 9.6 8.358 9.6 8.8V10.4H11.2C11.642 10.4 12 10.758 12 11.2V12.8C12 13.242 11.642 13.6 11.2 13.6H9.6V15.2C9.6 15.642 9.242 16 8.8 16H7.2C6.758 16 6.4 15.642 6.4 15.2V13.6H4.8C4.358 13.6 4 13.242 4 12.8V11.2C4 10.758 4.358 10.4 4.8 10.4H6.4V8.8C6.4 8.358 6.758 8 7.2 8H8.8Z"
      fill="currentColor"
    />
  </svg>
);

export default MedicalPaper;
