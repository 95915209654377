import classNames from 'classnames';
import Button from 'components/Button';
import Cross from 'components/icons/outline/Cross';
import Filter from 'components/icons/outline/Filter';
import SortDown from 'components/icons/outline/SortDown';
import SortUp from 'components/icons/outline/SortUp';
import {
  DropdownHeader,
  DropdownMenu,
  useDropdown,
} from 'containers/Shared/components/Dropdown';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ClinicalCourseNavigation = ({
  items,
  onSelected,
  itemSelected,
  onSort,
  onFilter,
}) => {
  const nav = useRef();
  const { t } = useTranslation();
  const [sortDirection, setSortDirection] = useState('initial');
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    onFilter(filters);
  }, [filters]);

  useEffect(() => {
    nav.current.focus();
  }, []);

  const handleSort = () => {
    onSort();
    setSortDirection(
      sortDirection === 'initial'
        ? 'asc'
        : sortDirection === 'asc'
        ? 'desc'
        : 'asc'
    );
  };

  return (
    <aside
      tabIndex={-1}
      ref={nav}
      className="focus:outline-none w-[300px] flex-shrink-0 border-r border-separators flex flex-col"
    >
      <div className="text-primary text-xs p-4 flex items-center gap-2">
        {t('clinical__course_detail_navigation_title')}
        {items.length > 0 && (
          <>
            <div className="text-white bg-primary rounded-full py-1 text-2xs w-6 flex items-center justify-center">
              {items.length > 99 ? '99+' : items.length}
            </div>

            <div className="ml-auto flex items-center gap-2 text-gray-dark">
              <Button
                variant="light"
                className={classNames('w-6 h-6 p-1', {
                  'bg-gray-light': sortDirection !== 'initial',
                })}
                size="icon"
                onClick={handleSort}
              >
                {sortDirection === 'asc' ? (
                  <SortDown className="w-4 h-4" />
                ) : (
                  <SortUp className="w-4 h-4" />
                )}
              </Button>
              <Filters
                onClick={(filter) => setFilters(filter)}
                active={filters.length}
              />
            </div>
          </>
        )}
      </div>

      <div className="px-4 pb-2 flex items-center gap-2">
        {filters.map((filter) => (
          <div
            key={filter}
            className="text-xs bg-secundary rounded-lg p-1 flex items-center gap-2"
          >
            {t(`clinical__course_detail_filters_${filter}`)}
            <button
              autofocus
              onClick={() => setFilters([])}
              className="w-3 h-3 flex items-center justify-center"
            >
              <Cross className="w-2 h-2" />
            </button>
          </div>
        ))}
      </div>

      <nav className="overflow-y-scroll h-full">
        {items.length > 0 ? (
          items.map((item) => (
            <button
              key={item.id}
              onClick={() => onSelected(item)}
              className={classNames(
                'p-4 text-sm hover:bg-background text-left w-full border-b border-separators last:border-none',
                { 'bg-background': item.id === itemSelected?.id }
              )}
            >
              <h3 className="font-medium mb-1">{item.professional.name}</h3>
              <p className="text-blue">{item.professional.speciality}</p>
              {item.reason && <p>{item.reason}</p>}
              <p className="text-gray">
                {dayjs(item.dated_at).format('DD/MM/YY HH:mm')}
              </p>
            </button>
          ))
        ) : (
          <div
            className={classNames(
              'p-4 text-sm bg-background text-left w-full border-b border-separators last:border-none h-[125px]'
            )}
          ></div>
        )}
      </nav>
    </aside>
  );
};

const Filters = ({ onClick, active }) => {
  const dropdown = useDropdown();
  const { t } = useTranslation();

  return (
    <div className="relative">
      <Button
        variant="light"
        className={classNames('w-6 h-6 p-1', {
          'bg-gray-light': active,
        })}
        size="icon"
        onClick={dropdown.toggle}
      >
        <Filter className="w-4 h-4" />
      </Button>
      <DropdownMenu
        open={dropdown.isOpen}
        onClose={dropdown.close}
        className="w-48"
      >
        <DropdownHeader>
          {t('clinical__course_detail_navigation_filter')}
        </DropdownHeader>
        <button
          onClick={() => {
            onClick(['own']);
            dropdown.close();
          }}
          className="text-dark px-4 py-2 hover:bg-gray-light w-full text-left font-medium"
        >
          {t('clinical__course_detail_filters_own')}
        </button>
      </DropdownMenu>
    </div>
  );
};

export default ClinicalCourseNavigation;
