import React from 'react';

import AccordeonHandler from 'containers/Shared/components/Accordeon/AccordeonHandler';
import {
  AccordeonHeader,
  AccordeonBody,
  AccordeonButton,
} from 'containers/Shared/components/Accordeon/AccordeonPanel';
import Trash from 'components/icons/Trash';
import { useDiseases } from 'containers/DetailBar/PatientBar/hooks/diseases.hook';
import DiseaseItem from 'containers/DetailBar/PatientBar/components/Diseases/DiseaseItem';
import DiseaseModal from 'containers/DetailBar/PatientBar/components/Diseases/DiseaseModal';
import { useTranslation } from 'react-i18next';
import { usePortal } from 'containers/Shared/components/Portal';

const DiseaseContainer = ({ badge, onClose, hash, unknown_diseases_at }) => {
  const portal = usePortal();
  const [diseases, store, destroy, markAsUnknown, removeUnknown] = useDiseases(
    hash
  );
  const { t } = useTranslation();

  const openDiseaseModal = () => {
    portal.open(
      <DiseaseModal
        onClose={() => portal.close('disease')}
        onSubmit={(disease) => {
          store(disease, hash);
          portal.close('disease');
        }}
      />,
      'disease'
    );
  };

  return (
    <div>
      <div className="p-4">
        <AccordeonHeader
          onClose={onClose}
          badge={badge}
          empty={unknown_diseases_at !== null}
        >
          {t('sidebar__patient_disease_title')}
        </AccordeonHeader>
        <AccordeonBody show={badge === 0 && unknown_diseases_at === null}>
          {t('sidebar__patient_disease_empty_text')}
        </AccordeonBody>
        <AccordeonBody show={unknown_diseases_at !== null}>
          <div className="flex items-center justify-between">
            <div className="text-dark">
              {t('sidebar__patient_disease_unknown_text')}
            </div>
            <button
              onClick={() => removeUnknown(hash)}
              className="cursor-pointer focus:outline-none"
            >
              <Trash />
            </button>
          </div>
        </AccordeonBody>
        <AccordeonButton onClick={openDiseaseModal}>
          {t('sidebar__patient_disease_add')}
        </AccordeonButton>
        {badge === 0 && unknown_diseases_at === null && (
          <AccordeonButton className="mt-1" onClick={() => markAsUnknown(hash)}>
            {t('sidebar__patient_disease_mark_unknown')}
          </AccordeonButton>
        )}
      </div>
      <div className="border-b border-separators">
        {diseases.map((disease) => (
          <DiseaseItem
            key={disease.id}
            disease={disease}
            onDelete={() => destroy(disease.id)}
          />
        ))}
      </div>
    </div>
  );
};

const DiseasesPanel = ({ summary, open, onOpen, onClose, hash }) => {
  const { diseases_count: badge, unknown_diseases_at } = summary;
  const { t } = useTranslation();

  if (!open) {
    return (
      <AccordeonHandler
        onOpen={onOpen}
        badge={badge}
        empty={unknown_diseases_at !== null}
      >
        {t('sidebar__patient_disease_title')}
      </AccordeonHandler>
    );
  }

  return (
    <DiseaseContainer
      hash={hash}
      badge={badge}
      onClose={onClose}
      unknown_diseases_at={unknown_diseases_at}
    />
  );
};

export default DiseasesPanel;
