import { useEffect, useRef } from 'react';

import {
  Message,
  VoiceNoteMessageMeta,
} from 'containers/Room/components/RoomBody/Message';
import MessageOptions from 'containers/Room/components/RoomBody/MessageOptions';
import MicrophoneIcon from 'components/icons/outline/Microphone';
import Pause from 'components/icons/solid/Pause';
import Play from 'components/icons/solid/Play';
import dayjs from 'dayjs';
import FeatureFlag from 'containers/Shared/components/FeatureFlag';
import { config } from 'config';
import Timeline from 'containers/Room/components/RoomBody/Message/VoiceMessage/Timeline';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  AudioState,
  pauseAudio,
  playAudio,
  setCurrentTime,
  setIsPlaying,
  setSpeed,
} from 'state/audio.slice';
import { match_ } from 'utils/match';

export interface VoiceMessageProps {
  message: Message;
  isMine: boolean;
  isButtonShown: boolean;
  onShowButton: (show: boolean) => void;
}

const resetAudioState = (audio: HTMLAudioElement) => {
  audio.pause();
  audio.currentTime = 0;
  audio.playbackRate = 1;
};

const VoiceMessage = ({
  message,
  isMine,
  isButtonShown,
  onShowButton,
}: VoiceMessageProps) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const canEliminate = config.features.delete_messages;
  const dispatch = useDispatch();
  const { currentTime, isPlaying, speed, messageId } = useSelector<
    any,
    AudioState
  >((state) => state.audio);
  const isCurrentAudio = messageId === message.messageId;

  const duration = (message.attachment?.meta as VoiceNoteMessageMeta)?.duration;

  useEffect(
    function pauseRefWhenNotCurrent() {
      if (!isCurrentAudio && !audioRef.current?.paused) {
        audioRef.current && resetAudioState(audioRef.current);
      }
    },
    [isCurrentAudio]
  );

  const toggleAudio = () => {
    const audio = audioRef.current;
    if (!audio) return;

    if (isCurrentAudio && isPlaying) {
      dispatch(pauseAudio());
      audio.pause();
    } else {
      dispatch(playAudio(message.messageId));
      audio.play();
    }
  };

  const changeSpeed = () => {
    const audio = audioRef.current;
    if (!audio) return;

    if (!isCurrentAudio) {
      dispatch(setSpeed({ speed: 1.5, messageId: message.messageId }));
      return;
    }

    const newSpeed = match_({
      value: speed,
      branches: [
        { pattern: 1, result: 1.5 },
        { pattern: 1.5, result: 2 },
        { pattern: 2, result: 1 },
      ],
      fallback: 1,
    });

    audio.playbackRate = newSpeed;
    dispatch(setSpeed({ speed: newSpeed, messageId: message.messageId }));
  };

  return (
    <div
      className={`relative flex text-dark break-words pl-4 pr-2 pt-5 pb-6 rounded-lg mb-1 ${
        message.auto !== 1 && isMine && 'cursor-pointer'
      } ${
        isMine
          ? 'bg-secundary shadow-separators rounded-br'
          : 'bg-white shadow-separators rounded-bl border-blue-light border'
      }`}
      onMouseEnter={() => onShowButton(true)}
      onMouseLeave={() => onShowButton(false)}
    >
      <span className="items-center">
        <div className="flex flex-row items-center">
          <button
            className={`flex w-7 h-7 justify-center items-center`}
            onClick={changeSpeed}
          >
            {isCurrentAudio && (speed !== 1 || isPlaying) ? (
              <span className="flex w-7 h-5 bg-gray-dark rounded-md items-center justify-center text-xs text-white font-medium ">{`${speed}x`}</span>
            ) : (
              <div
                className={classNames(
                  `flex w-7 h-7 justify-center rounded-full items-center`,
                  isMine ? 'bg-white' : 'bg-gray-light'
                )}
              >
                <MicrophoneIcon
                  className={
                    isMine
                      ? 'rounded-full bg-white text-gray-dark w-3.5'
                      : 'bg-gray-light text-gray-dark rounded-bl'
                  }
                />
              </div>
            )}
          </button>

          <audio
            ref={audioRef}
            className={
              isMine
                ? 'bg-secundary shadow-separators rounded-br'
                : 'bg-white shadow-separators rounded-bl border-blue-light border'
            }
            src={message?.attachment?.url}
            onPlaying={() => isCurrentAudio && dispatch(setIsPlaying(true))}
            onPause={() => isCurrentAudio && dispatch(setIsPlaying(false))}
            onEnded={() => isCurrentAudio && dispatch(setIsPlaying(false))}
            onTimeUpdate={(e) =>
              isCurrentAudio &&
              dispatch(
                setCurrentTime({
                  currentTime: e.currentTarget.currentTime,
                  messageId: message.messageId,
                })
              )
            }
          />
          <div className="flex flex-row items-center justify-between flex-1">
            <button
              className="flex text-gray-dark h-4 w-4 justify-center mx-4"
              onClick={toggleAudio}
            >
              {isCurrentAudio && isPlaying ? <Pause /> : <Play />}
            </button>
            <Timeline
              isMine={isMine}
              className="w-[203px]"
              currentProgress={isCurrentAudio ? currentTime : 0}
              duration={duration}
              onChange={(e) => {
                if (!isCurrentAudio) return;

                const value = Number(e.target.value);
                dispatch(
                  setCurrentTime({
                    currentTime: value,
                    messageId: message.messageId,
                  })
                );

                const audio = audioRef.current;
                if (!audio) return;
                audio.currentTime = value;
              }}
            />
          </div>
        </div>
        <span className="text-xs absolute bottom-2 left-9 font-light text-gray-dark ml-14">
          {dayjs(
            (isCurrentAudio && isPlaying ? currentTime : duration) * 1000
          ).format('m:ss')}
        </span>
      </span>
      <div
        className={`absolute top-0.5 right-0 mr-1 ${
          !isButtonShown && 'opacity-0'
        }`}
      >
        {!message.deletedAt && message.auto !== 1 && isMine && (
          <FeatureFlag enabled={canEliminate}>
            <MessageOptions message={message} isMine={isMine} />
          </FeatureFlag>
        )}
      </div>
    </div>
  );
};

export default VoiceMessage;
