import Button from 'components/Button';
import FolderPlus from 'components/icons/solid/FolderPlus';
import InfoCircle from 'components/icons/solid/InfoCircle';
import Modal from 'components/Modal';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export interface Props {
  title: string;
  description: string;
  onClose: () => void;
  consultation: any;
  costumerName: string;
  onConfirm: () => void;
}

const StartConsultationModal = ({
  title,
  description,
  onClose,
  consultation,
  costumerName,
  onConfirm,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} width="narrow" className="rounded-xl">
      <>
        <Modal.Body>
          <div className="py-10 flex flex-col justify-center">
            <div className="flex flex-col justify-center m-auto w-full overflow-hidden rounded-lg max-w-sm">
              <div className="flex center items-center text-center p-3 rounded-[34px] bg-blue-light drop-shadow-lg w-20 h-20 my-2 m-auto">
                <FolderPlus className="text-primary m-auto w-8 h-8" />
              </div>
              <div className="relative w-full">
                <p className="text-primary text-2xl font-medium center text-center my-4 tracking-wide">
                  {title}
                </p>
                <p
                  className="center text-center my-4"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
                <div className="bg-background p-4 mt-4 text-gray-dark text-sm rounded-lg gap-2 tracking-lg">
                  <div className="text-xs font-medium uppercase flex flex-row justify-between ">
                    {t('chat__consultation_start_modal_text') + costumerName}
                    <InfoCircle className="w-4 text-gray-dark" />
                  </div>
                  <div>
                    {t('chat__consultation_start_modal_date_text') +
                      dayjs
                        .unix(consultation?.created_at)
                        .format('DD/MM/YY - HH:mm')}
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto mt-6 max-w-xs flex gap-4 items-center">
              <Button
                variant="secondary"
                className="flex w-full uppercase hover:bg-primary-dark hover:text-white rounded-xl py-4 px-6"
                onClick={onClose}
              >
                {t('chat__consultation_start_modal_cancel_button')}
              </Button>
              <Button
                variant="primary"
                className="text-white uppercase flex w-full py-4 px-6 rounded-xl"
                onClick={onConfirm}
              >
                {t('chat__consultation_start_modal_start_button')}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </>
    </Modal>
  );
};

export default StartConsultationModal;
