import { toast } from 'react-toastify';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import Api from 'api';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { consultationFinished } from 'containers/Room/actions/JoinRoom.actions';
import Checked from 'components/icons/Checked';

const useConsultation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const finishChatConsultation = ({
    consultationId,
  }: {
    consultationId: string;
  }) =>
    Api.chat
      .finish({ consultation_id: consultationId })
      .then(() => {
        dispatch(consultationFinished());
        setTimeout(
          () =>
            toast.success(
              <div className="flex items-center gap-3">
                <Checked />
                {t('appointments__appointment_finish_success')}
              </div>
            ),
          150
        );
      })
      .catch(() => {
        toast.error(
          <ErrorToast>
            {t('appointments__payment_detail_finish_error')}
          </ErrorToast>
        );
      });

  return {
    finishChatConsultation,
  };
};

export default useConsultation;
