const NetworkExclamation = (props: any) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.6 20.25C4.65 19.3333 3.896 18.254 3.338 17.012C2.77933 15.7707 2.5 14.4333 2.5 13C2.5 11.6167 2.76267 10.3167 3.288 9.1C3.81267 7.88333 4.525 6.825 5.425 5.925C6.325 5.025 7.38333 4.31233 8.6 3.787C9.81667 3.26233 11.1167 3 12.5 3C14.3667 3 16.0583 3.454 17.575 4.362C19.0917 5.27067 20.2917 6.48333 21.175 8H18.75C18 7.08333 17.0873 6.354 16.012 5.812C14.9373 5.27067 13.7667 5 12.5 5C10.2667 5 8.375 5.775 6.825 7.325C5.275 8.875 4.5 10.7667 4.5 13C4.5 14.15 4.725 15.225 5.175 16.225C5.625 17.225 6.24167 18.0917 7.025 18.825L5.6 20.25ZM8.425 17.425C7.84167 16.875 7.375 16.221 7.025 15.463C6.675 14.7043 6.5 13.8833 6.5 13C6.5 11.3333 7.08333 9.91667 8.25 8.75C9.41667 7.58333 10.8333 7 12.5 7C14.1667 7 15.5833 7.58333 16.75 8.75C17.9167 9.91667 18.5 11.3333 18.5 13C18.5 13.8833 18.325 14.7083 17.975 15.475C17.625 16.2417 17.1583 16.8917 16.575 17.425L15.15 16C15.5667 15.6167 15.8957 15.1667 16.137 14.65C16.379 14.1333 16.5 13.5833 16.5 13C16.5 11.9 16.1083 10.9583 15.325 10.175C14.5417 9.39167 13.6 9 12.5 9C11.4 9 10.4583 9.39167 9.675 10.175C8.89167 10.9583 8.5 11.9 8.5 13C8.5 13.6 8.621 14.154 8.863 14.662C9.10433 15.1707 9.43333 15.6167 9.85 16L8.425 17.425ZM12.5 15C11.95 15 11.4793 14.804 11.088 14.412C10.696 14.0207 10.5 13.55 10.5 13C10.5 12.45 10.696 11.979 11.088 11.587C11.4793 11.1957 11.95 11 12.5 11C13.05 11 13.521 11.1957 13.913 11.587C14.3043 11.979 14.5 12.45 14.5 13C14.5 13.55 14.3043 14.0207 13.913 14.412C13.521 14.804 13.05 15 12.5 15ZM21.5 20C21.2167 20 20.9793 19.904 20.788 19.712C20.596 19.5207 20.5 19.2833 20.5 19C20.5 18.7167 20.596 18.4793 20.788 18.288C20.9793 18.096 21.2167 18 21.5 18C21.7833 18 22.0207 18.096 22.212 18.288C22.404 18.4793 22.5 18.7167 22.5 19C22.5 19.2833 22.404 19.5207 22.212 19.712C22.0207 19.904 21.7833 20 21.5 20ZM20.5 16V10H22.5V16H20.5Z"
      fill="currentColor"
    />
  </svg>
);

export default NetworkExclamation;
