import PlusIcon from 'components/icons/Plus';
import Lottie from 'lottie-react';
import Loading from 'containers/Appointments/animations/loading.json';

export type Props = JSX.IntrinsicElements['button'] & {
  endSection?: JSX.Element;
  status?: string;
  isAppointmentAction?: boolean;
};

const AddButton = ({
  children,
  endSection,
  status,
  isAppointmentAction,
  ...props
}: Props) => {
  return (
    <button
      className={`flex items-center justify-between p-3 bg-[#1B1B1B] text-white ${
        !isAppointmentAction && status === 'loading'
          ? 'cursor-default'
          : 'hover:bg-gray-darker'
      } tracking-wide normal-case rounded-md font-medium whitespace-nowrap text-sm min-w-fit`}
      {...props}
    >
      <div className="inline-flex items-center">
        {!isAppointmentAction && status === 'loading' ? (
          // @ts-ignore
          <Lottie animationData={Loading} className="h-7 w-7 mr-2" loop />
        ) : (
          <div className="flex place-items-center justify-center w-4 h-4 text-blue bg-secundary rounded-md mr-[10px] font-normal shrink-0">
            <PlusIcon className="h-2 w-2" />
          </div>
        )}
        <span>{children}</span>
      </div>
      {endSection}
    </button>
  );
};

export default AddButton;
