import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getHeaders from 'utils/getHeaders';
import { config } from 'config';

const ORGANIZATION_GUIDES_BASE_URL = `${config.services.mediquo.chat_url}/organizations/v1`;

export interface OrganizationGuidesResponse {
  service_guide: string | null;
}

export const organizationGuidesApi = createApi({
  reducerPath: 'organizationGuidesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ORGANIZATION_GUIDES_BASE_URL,
    prepareHeaders: (headers) => {
      Object.entries(getHeaders()).forEach(([key, value]) =>
        headers.set(key, value!)
      );
      return headers;
    },
  }),
  tagTypes: ['OrganizationGuides'],
  endpoints: (builder) => ({
    getOrganizationGuides: builder.query<
      OrganizationGuidesResponse,
      { organization_id: string }
    >({
      query: ({ organization_id }) => ({
        url: `/${organization_id}/service-guide`,
      }),
      transformResponse: (response: { data: OrganizationGuidesResponse }) => {
        return response.data;
      },
      providesTags: ['OrganizationGuides'],
    }),
  }),
});

export const { useGetOrganizationGuidesQuery } = organizationGuidesApi;
