import { createSelector } from 'reselect';

const getClinicalCourses = (state) => state.clinicalCourses.clinical_courses;

export const getCurrentClinicalCourse = createSelector(
  getClinicalCourses,
  (courses) => courses?.filter((course) => course.status === 'published')[0]
);

export const getCurrentDraft = createSelector(
  getClinicalCourses,
  (courses) => courses?.filter((course) => course.status === 'draft')[0]
);
