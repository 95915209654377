export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="14"
    fill="none"
    viewBox="0 0 21 14"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.002 0a3 3 0 013 3v1.132l1.434-1.495a2.11 2.11 0 012.722-.255l.135.105c.448.375.709.922.709 1.502v6.022c0 1.114-.935 1.989-2.057 1.989a2.09 2.09 0 01-1.51-.637l-1.433-1.496V11a3 3 0 01-3 3h-9a3 3 0 01-3-3V3a3 3 0 013-3h9zm0 2h-9a1 1 0 00-.993.883L2.002 3v8a1 1 0 00.883.993l.117.007h9a1 1 0 00.993-.883l.007-.117V3a1 1 0 00-.883-.993L12.002 2zm5.976 2.004c-.035-.01-.08-.002-.098.017L15.024 7l2.856 2.98c.006.006.02.013.037.017l.028.003c.046 0 .057-.01.057.01V4.017l-.024-.012z"
    ></path>
  </svg>
);
