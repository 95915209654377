import { useState } from 'react';
import { usePortal } from 'containers/Shared/components/Portal';
import { toast } from 'react-toastify';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import {
  appointmentFinished,
  appointmentFinishedFreeCharge,
  appointmentFinishedOwe,
  appointmentNotPresent,
  appointmentRetried,
  HighlightStatus,
} from 'state/appointments.slice';
import useLazyQuery from 'utils/useLazyQuery';
import Api from 'api';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Pending,
  Unpaid,
  UnpaidLast,
} from 'containers/Appointments/components/Admin';
import { AppointmentDetail } from 'api/appointments/index.d';
import { diffDays } from 'utils/date';
import { AxiosResponse } from 'axios';
import {
  setInCall,
  setNotInCall,
} from 'containers/WaitingRoom/actions/GetWaitingRoom.actions';
import Checked from 'components/icons/Checked';

const useAppointment = () => {
  const portal = usePortal();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [FinishAppointment, isFinishingAppointment] = useLazyQuery(
    Api.appointments.finish
  );
  const [FinishFreeChargeAppointment, isFinishingFreeChargeAppointment] =
    useLazyQuery(Api.appointments.finishFreeCharge);
  const [FinishOweAppointment, isFinishingOweAppointment] = useLazyQuery(
    Api.appointments.finishOwe
  );
  const [RetryAppointment, isRetringAppointment] = useLazyQuery(
    Api.appointments.retry
  );
  const [status, setStatus] = useState('loading');

  const get = ({ appointmentId }: { appointmentId: string }) => {
    dispatch(setInCall());
    return Api.appointments.get(appointmentId);
  };

  const reporting = ({ appointmentId }: { appointmentId: string }) => {
    Api.appointments.reporting(appointmentId);
    setStatus('reporting');
  };

  const inCall = ({ appointmentId }: { appointmentId: string }) => {
    setStatus('meet');
  };

  const notPresent = ({ appointmentId }: { appointmentId: string }) => {
    Api.appointments.notPresent(appointmentId);
    dispatch(appointmentNotPresent({ id: appointmentId }));
  };

  const finishPriceless = ({
    close,
    appointmentId,
  }: {
    close: () => void;
    appointmentId: string;
  }) =>
    (FinishAppointment as any)(appointmentId)
      .then(() => {
        dispatch(appointmentFinished({ id: appointmentId }));
        close();
        setTimeout(
          () =>
            toast.success(
              <div className="flex items-center gap-3">
                <Checked />
                {t('appointments__appointment_finish_success')}
              </div>
            ),
          150
        );
      })
      .catch(() => {
        toast.error(
          <ErrorToast>
            {t('appointments__payment_detail_finish_error')}
          </ErrorToast>
        );
      });

  const finishFreeCharge = ({
    close,
    appointmentId,
  }: {
    close: () => void;
    appointmentId: string;
  }) =>
    (FinishFreeChargeAppointment as any)(appointmentId)
      .then(() => {
        dispatch(appointmentFinishedFreeCharge({ id: appointmentId }));
        close();
        setTimeout(
          () =>
            toast.success(
              <div className="flex items-center gap-3">
                <Checked />
                {t('appointments__appointment_finish_success')}
              </div>
            ),
          150
        );
      })
      .catch(() => {
        toast.error(
          <ErrorToast>
            {t('appointments__payment_detail_finish_error')}
          </ErrorToast>
        );
      });

  const finishOwe = ({
    close,
    appointmentId,
  }: {
    close: () => void;
    appointmentId: string;
  }) =>
    (FinishOweAppointment as any)(appointmentId)
      .then(() => {
        dispatch(appointmentFinishedOwe({ id: appointmentId }));
        close();
        setTimeout(
          () =>
            toast.success(
              <div className="flex items-center gap-3">
                <Checked />
                {t('appointments__appointment_finish_success')}
              </div>
            ),
          150
        );
      })
      .catch(() => {
        toast.error(
          <ErrorToast>
            {t('appointments__payment_detail_finish_error')}
          </ErrorToast>
        );
      });

  const finish = ({
    close,
    appointmentId,
  }: {
    close: () => void;
    appointmentId: string;
  }) =>
    (FinishAppointment as any)(appointmentId)
      .then(() => {
        dispatch(appointmentFinished({ id: appointmentId }));
        close();
        setTimeout(
          () =>
            toast.success(
              <div className="flex items-center gap-3">
                <Checked />
                {t('appointments__appointment_finish_success')}
              </div>
            ),
          150
        );
      })
      .catch(() => {
        toast.error(
          <ErrorToast>
            {t('appointments__payment_detail_finish_error')}
          </ErrorToast>
        );
      });

  const retry = ({ appointmentId }: { appointmentId: string }) =>
    (RetryAppointment as any)(appointmentId)
      .then(
        ({
          data: {
            data: { status, retried_until },
          },
        }: AxiosResponse<{ data: AppointmentDetail }>) => {
          dispatch(
            appointmentRetried({ id: appointmentId, status, retried_until })
          );
          setTimeout(
            () =>
              toast.success(
                <div className="flex items-center gap-3">
                  <Checked />
                  {t('appointments__appointment_finish_success')}
                </div>
              ),
            150
          );
        }
      )
      .catch(() =>
        toast.error(
          <ErrorToast>
            {t('appointments__payment_detail_retry_error')}
          </ErrorToast>
        )
      );

  const complete = ({
    appointment: { id, status, price, retried_until, to },
    onClose = () => {},
  }: {
    appointment: {
      id: string;
      status: HighlightStatus;
      price: number;
      retried_until?: string | null;
      to?: {
        hash: string | null;
      };
    };
    onClose?: () => void;
  }) => {
    if (!['pending', 'accepted', 'unpaid', 'reporting'].includes(status)) {
      return;
    }

    dispatch(setNotInCall());
    switch (status) {
      case 'pending':
        !price || price === 0
          ? finishPriceless({ appointmentId: id, close: onClose })
          : portal.open(
              <Pending
                onClose={() => portal.close('pending')}
                onRetry={() => {
                  retry({ appointmentId: id });
                  portal.close('pending');
                }}
                onFinishFreeCharge={() => {
                  finishFreeCharge({ appointmentId: id, close: onClose });
                  portal.close('pending');
                }}
              />,
              'pending'
            );
        break;
      case 'reporting':
      case 'accepted':
        finish({ appointmentId: id, close: onClose });
        break;
      case 'unpaid':
        const daysUntilRetry = getDaysUntilRetry(retried_until);

        isUnpaidLast(daysUntilRetry)
          ? portal.open(
              <UnpaidLast
                onClose={() => portal.close('unpaid')}
                onRetry={() => {
                  retry({ appointmentId: id });
                  portal.close('unpaid');
                }}
                onFinish={() => {
                  finish({ appointmentId: id, close: onClose });
                  portal.close('unpaid');
                }}
                onFinishOwe={() => {
                  finishOwe({ appointmentId: id, close: onClose });
                  portal.close('unpaid');
                }}
              />,
              'unpaid'
            )
          : portal.open(
              <Unpaid
                days={daysUntilRetry!}
                onClose={() => portal.close('unpaid')}
                onAgreed={() => portal.close('unpaid')}
                onFinishFreeCharge={() => {
                  finishFreeCharge({ appointmentId: id, close: onClose });
                  portal.close('unpaid');
                }}
              />,
              'unpaid'
            );
        break;
    }
  };

  return {
    get,
    finish,
    inCall,
    retry,
    finishOwe,
    finishFreeCharge,
    notPresent,
    reporting,
    complete,
    status,
    setStatus,
    isProcessingAsDone:
      isFinishingAppointment ||
      isRetringAppointment ||
      isFinishingFreeChargeAppointment ||
      isFinishingOweAppointment,
  };
};

const getDaysUntilRetry = (date?: string | null) =>
  date ? diffDays(new Date(date), new Date()) : undefined;

const isUnpaidLast = (untilDays?: number) =>
  untilDays !== undefined && untilDays <= 0;

export default useAppointment;
