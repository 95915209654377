import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeArchivedRooms,
  trackingMessageScheduled,
} from 'containers/Inbox/actions/RoomListOptions.actions';
import { useTranslation, Trans } from 'react-i18next';
import { InboxMode } from 'containers/Inbox/reducers/inbox.reducer';
import Modal from 'components/Modal';
import Button from 'components/Button';
import api from 'api';
import ConsultationsApiClient from 'legacy/Services/Consultations';
import { getSummaryRoomsList } from 'containers/Inbox/actions/GetRoomList.actions';
import CRMTracker from 'utils/CRMTracker';
import { toast } from 'react-toastify';
import ScheduleTrackingMessage from 'containers/Inbox/toasts/ScheduleTrackingMessage';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import dayjs from 'dayjs';
import Cross from 'components/icons/outline/Cross';

export interface Props {
  rooms: number[] | string[];
  onClose?: () => void;
  onSubmit?: () => void;
  single?: boolean;
  tracking?: any;
}

const TrackingMessageScheduler: FC<Props> = ({
  rooms,
  onClose,
  onSubmit,
  single = false,
  tracking,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mode = useSelector((state: any) => state.inbox.mode);
  const [text, setText] = useState(
    t('tracking__create_modal_form_default_message') as string
  );
  const [attachment, setAttachment] = useState();
  const [date, setDate] = useState(dayjs().add(1, 'day'));
  const scheduleTrackingMessages = useTrackingMessages();

  const submit = () => {
    if (!tracking?.id) {
      scheduleTrackingMessages({ rooms, text, date, attachment })
        .then(() => {
          InboxMode.untracked === mode && dispatch(removeArchivedRooms(rooms));
          dispatch(getSummaryRoomsList());
          dispatch(trackingMessageScheduled(rooms));
          CRMTracker.event('FollowUpSchedule');
          toast(<ScheduleTrackingMessage rooms={rooms} />);
          onSubmit && onSubmit();
        })
        .catch(() =>
          toast.error(
            <ErrorToast>{t('shared__errors_generic_message')}</ErrorToast>
          )
        );
    } else {
      const id = tracking.id;
      scheduleTrackingMessages({
        rooms,
        text,
        date,
        attachment,
        id,
        edit: true,
      })
        .then(() => {
          InboxMode.untracked === mode && dispatch(removeArchivedRooms(rooms));
          dispatch(getSummaryRoomsList());
          dispatch(trackingMessageScheduled(rooms));
          CRMTracker.event('FollowUpSchedule');
          toast(<ScheduleTrackingMessage rooms={rooms} edited />);
          onSubmit && onSubmit();
        })
        .catch(() =>
          toast.error(
            <ErrorToast>{t('shared__errors_generic_message')}</ErrorToast>
          )
        );
    }
  };

  useEffect(() => {
    if (tracking?.id) {
      setText(tracking.message);
      setDate(dayjs(tracking.to_send_at));
      setAttachment(tracking.attached_file_url);
    }
  }, []);

  return (
    <Modal onClose={onClose}>
      <Modal.Header onClose={onClose}>
        {tracking
          ? t('tracking__edit_modal_title')
          : t('tracking__create_modal_title')}
      </Modal.Header>
      <Modal.Body>
        <div className="mt-3 text-left">
          {!single && (
            <div className="flex items-center justify-center p-2 mb-6 text-xs text-center rounded-lg text-primary bg-blue-light">
              <Trans
                i18nKey="tracking__create_modal_subtitle"
                count={rooms.length}
              >
                ⚠️ Estás realizando un seguimiento masivo para
                <span className="ml-2 font-medium">
                  {` ${rooms.length} usuarios`}
                </span>
              </Trans>
            </div>
          )}
          <div>
            <div className="mb-4">
              <label className="pb-2 font-medium">
                {t('tracking__create_modal_form_message')}
              </label>
              <textarea
                onChange={(e: any) => setText(e.target.value)}
                value={text}
                className="w-full p-2 border rounded-md border-separators"
                rows={5}
              ></textarea>
              {tracking?.attached_file_url && attachment ? (
                <span className="flex mb-10 items-center ">
                  {t('tracking__edit_modal_existing_file')}
                  <button
                    className="ml-2 h-7 w-7 rounded-lg text-gray-dark hover:bg-gray-light"
                    onClick={(e) => {
                      e.preventDefault();
                      setAttachment(undefined);
                    }}
                  >
                    <Cross className="m-auto h-2" />
                  </button>
                </span>
              ) : (
                <input
                  type="file"
                  onChange={(event: any) =>
                    setAttachment(event.target.files[0])
                  }
                />
              )}
            </div>
            <label className="pb-2 font-medium">
              {t('tracking__create_modal_form_date')}
            </label>
            <div className="flex items-center">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setDate(dayjs().add(1, 'day'));
                }}
                className="px-3 py-2 text-sm font-medium text-white uppercase rounded-md bg-primary hover:bg-primary-dark leading-button focus:outline-none"
              >
                {t('tracking__create_modal_form_tomorrow_button')}
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setDate(dayjs().add(3, 'day'));
                }}
                className="mx-2 px-3 py-2 text-sm font-medium text-white uppercase rounded-md bg-primary hover:bg-primary-dark leading-button focus:outline-none"
              >
                {t('tracking__create_modal_form_in_days_button', { count: 3 })}
              </button>
              <button
                type="button"
                onClick={() => setDate(dayjs().add(5, 'day'))}
                className="px-3 py-2 text-sm font-medium text-white uppercase rounded-md bg-primary hover:bg-primary-dark leading-button focus:outline-none"
              >
                {t('tracking__create_modal_form_in_days_button', { count: 5 })}
              </button>
              <input
                onChange={(event) => setDate(dayjs(event.target.value))}
                min={dayjs().format('YYYY-MM-DDTHH:mm')}
                value={date.format('YYYY-MM-DDTHH:mm')}
                className="w-1/2 min-h-0 px-4 py-2 text-sm border rounded-md border-separators ml-auto"
                type="datetime-local"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={!text} onClick={submit}>
          {t('tracking__create_modal_accept')}
        </Button>
        <Button variant="reverse" onClick={onClose}>
          {t('tracking__create_modal_cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const useTrackingMessages = () => {
  const uploadAttachment = async (attachment: any) => {
    if (!attachment) {
      return undefined;
    }

    const {
      data: {
        data: { presigned_storage_url, file_path },
      },
    } = await api.tracking.storage.get();

    await api.tracking.attachments.store(presigned_storage_url, attachment);

    return file_path;
  };

  const scheduleTrackingMessages = async ({
    rooms: selectedRooms,
    text: message,
    date,
    attachment,
    id,
    edit = false,
  }: any) => {
    const attached_file_url = await uploadAttachment(attachment);
    if (edit) {
      return api.tracking.edit({
        id,
        message,
        to_send_at: date,
        attached_file_url,
      });
    }
    return ConsultationsApiClient.automaticTrackings.store({
      room_ids: selectedRooms,
      message: message,
      to_send_at: date,
      attached_file_url,
    });
  };

  return scheduleTrackingMessages;
};

export default TrackingMessageScheduler;
