const WomanSearching = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 202 114"
      {...props}
    >
      <path
        fill="#D7BCF1"
        fillRule="evenodd"
        d="M201.494 63.767a16.754 16.754 0 00-16.751-16.752 16.754 16.754 0 0015.481-10.339 16.759 16.759 0 00-3.63-18.259 16.753 16.753 0 00-25.776 2.543 16.755 16.755 0 00-2.82 9.309 16.754 16.754 0 00-16.751-16.752 16.751 16.751 0 00-16.751 16.752 16.75 16.75 0 10-33.496 0 16.756 16.756 0 00-10.264-15.763 16.75 16.75 0 00-23.232 15.763 16.753 16.753 0 00-28.596-11.845 16.753 16.753 0 00-4.906 11.845A16.752 16.752 0 005.406 18.418a16.753 16.753 0 0011.851 28.597A16.75 16.75 0 005.412 75.612a16.75 16.75 0 0011.845 4.907 16.75 16.75 0 00-11.85 28.597 16.748 16.748 0 0028.596-11.851h133.995a16.754 16.754 0 0010.338 15.482 16.746 16.746 0 0018.258-3.631 16.748 16.748 0 003.63-18.258 16.753 16.753 0 00-15.481-10.338 16.746 16.746 0 0011.845-4.908 16.762 16.762 0 004.906-11.845z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#201552"
        d="M115.423 24.684c4.02-8.706-1.902-13.32-4.002-13.404-.6-5.028-9.797-11.508-17.873-11.274-1.41.037-2.814.187-4.2.45-11.447 2.16-19.612 11.364-20.2 21.738-.108 1.854-.762 5.01-2.634 5.598-8.843 2.874-14.963 13.098-14.021 22.224.414 4.032-2.352 4.8-4.71 6-10.511 4.386-12.941 17.13-7.277 28.86 3.51 7.332 11.303 10.398 19.972 10.884 16.92.876 22.625-11.856 19.871-26.226a24.767 24.767 0 0015.815-13.89s15.497-23.526 16.757-25.8c.144-.27 2.316-4.686 2.502-5.16z"
      ></path>
      <path
        fill="#F1E4FF"
        d="M79.101 62.694l6-27.9s-6.6-5.214-1.338-10.614c3.264-3.342 6.503 2.628 6.503 2.628a10.547 10.547 0 005.808-5.85l1.422-3.6c1.2-1.584 3.03-1.77 4.974-1.638 1.307.078 2.619.012 3.912-.198l2.483-.39.888 1.104c.759.98 1.624 1.87 2.58 2.658a1.269 1.269 0 01.6.972v9.96a30.863 30.863 0 01-2.634 12.462l-1.686 3.828a5.31 5.31 0 01-5.999 3.054l-.414-.084-2.022 16.956-5.694 30.48-15.383-33.828z"
      ></path>
      <path
        fill="#6321A8"
        d="M123.396 35.16l12.762 7.692-1.05 1.842-12.707-7.938.995-1.596z"
      ></path>
      <path
        fill="#6321A8"
        fillRule="evenodd"
        d="M125.857 27.21a12.899 12.899 0 11-15.677-9.324 12.893 12.893 0 0115.677 9.324zm-1.74.444a11.099 11.099 0 11-13.493-8.028 11.098 11.098 0 0113.475 8.028h.018z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F1E4FF"
        d="M144.689 81.216l-11.759-4.968-8.232 31.746 29.927 5.904c9.659 1.05 17.062-9.702 12.833-18.456l-15.869-36.72a79.466 79.466 0 00-.966-9c-.174-.912-.252-2.256-.48-3.18-.414-1.71-.936-1.086-1.566-3.57-.66-1.95-1.734-2.352-2.736-1.884a1.29 1.29 0 01-1.8-.648c-.57-1.35-1.722-1.866-3.222-.858a.961.961 0 01-1.173-.052.972.972 0 01-.255-.332c-.527-1.272-1.691-1.632-3.071-.564a.875.875 0 01-1.278-.228c-1.2-2.028-3.504-1.434-4.134 1.452-.696 3.138-.552 4.89 1.02 5.502 1.308.504 2.706-.342 2.64-1.512 0 0-1.614.966-1.866.474-.546-1.08-2.808-2.01-4.41-2.55a.598.598 0 00-.669.572c-.005.11.021.22.075.316l5.784 13.734a6.407 6.407 0 002.832 3.138l3.401 1.854 10.872 43.32"
      ></path>
      <path
        fill="#F5C4DD"
        d="M113.353 41.49c6.13 0 11.099-4.97 11.099-11.1s-4.969-11.1-11.099-11.1-11.099 4.97-11.099 11.1 4.969 11.1 11.099 11.1z"
      ></path>
      <path
        fill="#6321A8"
        d="M79.101 62.694l15.401 33.828 5.694-30.48s10.517.396 32.74 10.2l-8.238 31.752-3.059-.444-.858 6.444H31.746l1.5-16.752-2.742-.39c.462-11.64 3.714-25.572 13.62-30.81C55.186 60.684 79.1 62.694 79.1 62.694z"
      ></path>
      <path
        fill="#F1E4FF"
        d="M59.998 100.872l-27.136-3.624-1.494 16.746H57.82l2.178-13.122z"
      ></path>
      <path
        fill="#fff"
        d="M120.948 30.594s-3.396-3.996-7.595-3.996c-4.2 0-7.59 3.996-7.59 3.996s3.396 3.99 7.59 3.99c4.193 0 7.595-3.99 7.595-3.99z"
      ></path>
      <path
        fill="#3C50EC"
        d="M113.353 33.354a2.748 2.748 0 100-5.496 2.748 2.748 0 000 5.496z"
      ></path>
      <path
        stroke="#201552"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="M142.289 71.664l7.501 29.886M122.053 103.44l-1.272 10.554M53.668 100.026l8.07 1.128 2.508-14.232"
      ></path>
      <path
        stroke="#3C50EC"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="M83.487 33.768a7.542 7.542 0 003.894 2.43"
      ></path>
      <path
        stroke="#D7BCF1"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="M99.342 47.702a7.54 7.54 0 004.21 1.827"
      ></path>
    </svg>
  );
};

export default WomanSearching;
