import { config } from 'config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getHeaders from 'utils/getHeaders';
import { z } from 'zod';

const SCHEDULES_BASE_URL = `${config.services.mediquo.chat_url}/schedules/v1`;

export const ScheduleDaysOfWeekSchema = z.enum([
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
  'working_week',
  'weekend',
  'all_week',
]);

const AgendaLockSchema = z.object({
  id: z.string().uuid(),
  schedule_id: z.string().min(1),
  name: z.string().min(1),
  starts_at: z.string().date(),
  finishes_at: z.string().date(),
});
export type AgendaLock = z.infer<typeof AgendaLockSchema>;

export interface Agenda {
  id: string;
  name: string;
  patientName: string;
  gaps: Array<ScheduleGap>;
  channel: ScheduleChannel;
  starts_at: string;
  finishes_at?: string;
  timezone: string;
  is_editable: boolean;
  max_advance_booking?: number;
  min_booking_notice?: number;
  duration?: number;
  blocks: Array<AgendaLock>;
}

export type ScheduleDaysOfWeek = z.infer<typeof ScheduleDaysOfWeekSchema>;

export interface ScheduleGap {
  from_time: string;
  to_time: string;
  days_of_week: ScheduleDaysOfWeek;
}

export interface GetChatScheduleResponse {
  id: string;
  gaps: Array<ScheduleGap>;
  timezone: string;
  starts_at: string;
  ends_at: string;
}
type GetChatScheduleRequest = void;

export interface PostChatScheduleRequest {
  timezone: string;
  gaps: Array<ScheduleGap>;
  starts_at: string;
  finishes_at?: string;
}

export const VideoCallStatusSchema = z.enum([
  'available',
  'unavailable',
  'scheduled',
]);
export type VideoCallStatus = z.infer<typeof VideoCallStatusSchema>;

export type VideoCallScheduleFields = {
  name: string;
  name_for_patient: string;
  timezone: string;
  starts_at: string;
  finishes_at?: string;
  duration: number;
  max_advance_booking: number;
  min_booking_notice: number;
  gaps: Array<ScheduleGap>;
};

export type PostVideoCallScheduleRequest = VideoCallScheduleFields;

export interface PutVideoCallScheduleRequest {
  id: string;
  data: VideoCallScheduleFields;
}

export type ScheduleChannel = 'videocall' | 'chat' | 'phonecall';

export interface GetVideoCallSchedulesRequest {
  status?: VideoCallStatus;
}
export type GetVideoCallSchedulesResponse = Array<Agenda>;

export type PostVideoCallScheduleLockRequest = Omit<AgendaLock, 'id'>;

export const schedulesApi = createApi({
  reducerPath: 'schedulesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SCHEDULES_BASE_URL,
    prepareHeaders: (headers) => {
      Object.entries(getHeaders()).forEach(([key, value]) =>
        headers.set(key, value!)
      );
      return headers;
    },
  }),
  tagTypes: ['ChatSchedules', 'VideoCallSchedules'],
  endpoints: (builder) => ({
    getChatSchedules: builder.query<
      GetChatScheduleResponse,
      GetChatScheduleRequest
    >({
      query: () => ({ url: '/chat' }),
      providesTags: ['ChatSchedules'],
      transformResponse: (response: { data: GetChatScheduleResponse }) =>
        response.data,
    }),

    getVideoCallSchedules: builder.query<
      GetVideoCallSchedulesResponse,
      GetVideoCallSchedulesRequest
    >({
      query: ({ status }) => ({ url: '/video-call', params: { status } }),
      providesTags: ['VideoCallSchedules'],
      transformResponse: (response: { data: GetVideoCallSchedulesResponse }) =>
        response.data,
    }),

    postChatSchedules: builder.mutation<void, PostChatScheduleRequest>({
      query: (data) => ({ url: '/chat', method: 'POST', body: data }),
      invalidatesTags: ['ChatSchedules'],
    }),

    postVideoCallSchedules: builder.mutation<
      void,
      PostVideoCallScheduleRequest
    >({
      query: (data) => ({ url: '/video-call', method: 'POST', body: data }),
      invalidatesTags: ['VideoCallSchedules'],
    }),

    putVideoCallSchedules: builder.mutation<void, PutVideoCallScheduleRequest>({
      query: ({ id, data }) => ({
        url: `/video-call/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['VideoCallSchedules'],
    }),

    deleteVideoCallSchedule: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/video-call/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['VideoCallSchedules'],
    }),

    postVideoCallScheduleLock: builder.mutation<
      void,
      PostVideoCallScheduleLockRequest
    >({
      query: (data) => ({
        url: '/block',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['VideoCallSchedules'],
    }),

    deleteVideoCallScheduleLock: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/block/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['VideoCallSchedules'],
    }),
  }),
});

export const {
  useGetChatSchedulesQuery,
  usePostChatSchedulesMutation,
  useGetVideoCallSchedulesQuery,
  usePostVideoCallSchedulesMutation,
  usePutVideoCallSchedulesMutation,
  usePostVideoCallScheduleLockMutation,
  useDeleteVideoCallScheduleLockMutation,
  useDeleteVideoCallScheduleMutation,
} = schedulesApi;
