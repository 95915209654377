import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import store from 'legacy/Services/Store';
import App from 'App';
import 'moment/locale/es';
import 'dayjs/locale/es';
import 'styles/index.css';

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistStore(store)}>
        <App />
      </PersistGate>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);
