import { useState } from 'react';

export default (query) => {
  const [isLoading, setIsLoading] = useState(false);

  const execute = (...args) => {
    setIsLoading(true);
    return query(...args).finally(() => setIsLoading(false));
  };

  return [execute, isLoading];
};
