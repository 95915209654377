import classNames from 'classnames';
import CaretLight from 'components/icons/outline/CaretLight';
import { useEffect, useState } from 'react';
import useDiagnosticTest from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/useDiagnosticTest';
import Checkbox from 'containers/Inbox/components/Checkbox';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

const DiagnosticTestBody = ({
  categories,
  activeNavId,
  activeRootCategoryId,
  items,
  previousSelectedItems = [],
}) => {
  const [childrenItems, setChildrenItems] = useState([]);

  useEffect(() => {
    if (items.length) {
      const newItems = items.map((item) => {
        if (item.category_id) {
          return item;
        }
      });
      setChildrenItems(newItems);
    }
  }, [items]);

  return categories.map(({ root }) => {
    return root.children.map((navItem) => {
      if (
        navItem.parent.id === activeRootCategoryId &&
        activeNavId === navItem.id
      ) {
        return (
          <div key={navItem}>
            <div>
              {childrenItems?.map((item, index) => {
                if (item.category_id === navItem.id) {
                  return (
                    <DiagnosticTestItem
                      key={index + item.id}
                      item={item}
                      previousSelectedItems={previousSelectedItems}
                    />
                  );
                }
              })}
            </div>
            <div>
              {navItem.children.map((filter, index) => {
                return (
                  <DiagnosticFilter
                    key={index + filter.id}
                    filter={filter}
                    items={items}
                    previousSelectedItems={previousSelectedItems}
                  />
                );
              })}
            </div>
          </div>
        );
      }
    });
  });
};

const DiagnosticFilter = ({ filter, items, previousSelectedItems }) => {
  const [childrenItems, setChildrenItems] = useState([]);

  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    if (items.length) {
      const newItems = items.map((item) => {
        if (item.category_id) {
          return item;
        }
      });
      setChildrenItems(newItems);
    }
  }, [items]);

  return (
    <div>
      <div className="flex text-gray-dark font-normal text-sm py-3.5 px-4">
        {filter.value.name}
      </div>
      {childrenItems?.map((item, index) => {
        if (item.category_id === filter.id) {
          return (
            <DiagnosticTestItem
              key={index + item.id}
              item={item}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              previousSelectedItems={previousSelectedItems}
            />
          );
        }
      })}
      {filter?.children.map((accordeon, index) => {
        return (
          <DiagnosticTestAccordion
            key={index + accordeon.id}
            accordeon={accordeon}
            items={items}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            previousSelectedItems={previousSelectedItems}
          />
        );
      })}
    </div>
  );
};

const DiagnosticTestAccordion = ({
  accordeon,
  items,
  checkedItems,
  setCheckedItems,
  previousSelectedItems,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [childrenItems, setChildrenItems] = useState([]);
  const [checkAllStatus, setCheckAllStatus] = useState('empty');
  const { handleCheckAll, handleUncheckAll, selected_items } =
    useDiagnosticTest();
  const isPreviousSelected = previousSelectedItems.find(
    (prevItem) => prevItem.category_id === accordeon.id
  );

  useEffect(() => {
    if (items.length) {
      const newItems = items.filter(
        (item) => item.category_id === accordeon.id
      );

      setChildrenItems(newItems);
    }
  }, [items]);

  useEffect(() => {
    if (childrenItems.length) {
      const checkedItems = selected_items.filter((item) => {
        if (item.category_id === accordeon.id) {
          return item;
        }
      });
      if (checkedItems.length === childrenItems.length) {
        setCheckAllStatus('all');
      } else if (checkedItems.length === 0) {
        setCheckAllStatus('empty');
      } else if (checkedItems.length < childrenItems.length) {
        setCheckAllStatus('some');
      }
    }
  }, [childrenItems, selected_items]);

  const handleCheckChildren = () => {
    setIsOpen(true);
    handleCheckAll(accordeon.id, childrenItems);
    setCheckAllStatus('all');
  };

  const handleUncheckChildren = () => {
    setCheckAllStatus('empty');
    handleUncheckAll(accordeon.id, childrenItems);
  };

  return (
    <div>
      <div
        className={classNames(
          'flex justify-between items-center py-2.5 px-4 rounded-lg mb-1 cursor-pointer hover:bg-gray-light',
          { 'bg-background': !isOpen, 'bg-gray-light': isOpen }
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex flex-row items-center">
          <Checkbox
            onChange={() => {
              checkAllStatus !== 'empty'
                ? handleUncheckChildren()
                : handleCheckChildren();
            }}
            isChecked={checkAllStatus === 'all'}
            isIndeterminate={checkAllStatus === 'some'}
            isBlocked={isPreviousSelected}
          />
          <div className="text-dark ml-2">{accordeon.value.name}</div>
        </div>
        <button
          className="flex items-center text-gray-medium justify-center"
          onClick={() => setIsOpen(!isOpen)}
        >
          <CaretLight
            className={classNames('w-3 h-3 ', {
              '-rotate-90': !isOpen,
              'rotate-90': isOpen,
            })}
          />
        </button>
      </div>
      {isOpen && (
        <div>
          {childrenItems &&
            childrenItems.map((item, index) => {
              if (item.category_id === accordeon.id) {
                return (
                  <DiagnosticTestItem
                    key={index + item.id}
                    item={item}
                    checkAll={checkAllStatus}
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    setCheckAll={setCheckAllStatus}
                    previousSelectedItems={previousSelectedItems}
                    isAccordeon
                  />
                );
              }
            })}
        </div>
      )}
    </div>
  );
};

const DiagnosticTestItem = ({
  item,
  checkAll,
  setCheckAll,
  previousSelectedItems,
  isAccordeon = false,
}) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const { id, name } = item;
  const { handleAddTest, handleRemoveTest, selected_items } =
    useDiagnosticTest();
  const isPreviousSelected = previousSelectedItems.find(
    (prevItem) => prevItem.id === id
  );
  const [mouseOver, setMouseOver] = useState(false);
  useTooltip({ open: mouseOver });

  useEffect(() => {
    if (checkAll === 'all') {
      setIsChecked(true);
      handleAddTest(item);
    }
    if (checkAll === 'empty') {
      setIsChecked(false);
      handleRemoveTest(item);
    }
  }, [checkAll]);

  const handleCheckItem = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      handleAddTest(item);
    } else {
      handleRemoveTest(item);
    }
  };

  useEffect(() => {
    if (selected_items.length) {
      selected_items.map((item) => {
        if (item.id === id) {
          setIsChecked(true);
        }
      });
    }
  }, []);

  return (
    <div
      className={classNames(
        `flex flex-row items-center py-2 text-dark relative`,
        {
          'px-10': isAccordeon,
          'px-4': !isAccordeon,
        }
      )}
      onMouseOver={() => {
        isPreviousSelected && setMouseOver(true);
      }}
      onMouseLeave={() => {
        isPreviousSelected && setMouseOver(false);
      }}
    >
      <Checkbox
        isChecked={isChecked}
        onChange={handleCheckItem}
        isBlocked={isChecked && isPreviousSelected}
      />
      <div
        className={classNames('ml-2 cursor-default', {
          'text-gray-medium': isChecked && isPreviousSelected,
        })}
      >
        {name}
      </div>
      {mouseOver && (
        <div className="absolute p-1 translate-y-5 translate-x-5 flex justify-center items-center bg-dark text-white text-xs rounded-md shadow-md">
          {t('prescription_modal__add_petition_item_tooltip')}
        </div>
      )}
    </div>
  );
};

const useTooltip = ({ open }) => {
  useEffect(() => {
    return () => ReactTooltip.hide();
  }, []);

  useEffect(() => {
    if (open) {
      ReactTooltip.rebuild();
    }
  }, [open]);
};

export default DiagnosticTestBody;
