import AccordeonHandler from 'containers/Shared/components/Accordeon/AccordeonHandler';
import {
  AccordeonHeader,
  AccordeonBody,
  AccordeonButton,
} from 'containers/Shared/components/Accordeon/AccordeonPanel';
import Trash from 'components/icons/Trash';
import { useAllergies } from 'containers/DetailBar/PatientBar/hooks/allergies.hook';
import AllergyItem from 'containers/DetailBar/PatientBar/components/Allergies/AllergyItem';
import AllergyModal from 'containers/DetailBar/PatientBar/components/Allergies/AllergyModal';
import { useTranslation } from 'react-i18next';
import { usePortal } from 'containers/Shared/components/Portal';

const AllergyContainer = ({ badge, onClose, hash, unknown_allergies_at }) => {
  const [
    allergies,
    store,
    destroy,
    markAsUnknown,
    removeUnknown,
  ] = useAllergies(hash);
  const { t } = useTranslation();
  const portal = usePortal();

  const openAllergyModal = () =>
    portal.open(
      <AllergyModal
        onClose={() => portal.close('allergy')}
        onSubmit={(allergy) => {
          store(allergy, hash);
          portal.close('allergy');
        }}
      />,
      'allergy'
    );

  return (
    <div>
      <div className="p-4">
        <AccordeonHeader
          onClose={onClose}
          badge={badge}
          empty={unknown_allergies_at !== null}
        >
          {t('sidebar__patient_allergies_title')}
        </AccordeonHeader>
        <AccordeonBody show={badge === 0 && unknown_allergies_at === null}>
          {t('sidebar__patient_allergies_empty_text')}
        </AccordeonBody>
        <AccordeonBody show={unknown_allergies_at !== null}>
          <div className="flex items-center justify-between">
            <div className="text-dark">
              {t('sidebar__patient_allergies_unknown_text')}
            </div>
            <button
              onClick={() => removeUnknown(hash)}
              className="cursor-pointer focus:outline-none"
            >
              <Trash />
            </button>
          </div>
        </AccordeonBody>
        <AccordeonButton onClick={openAllergyModal}>
          {t('sidebar__patient_allergies_add')}
        </AccordeonButton>
        {badge === 0 && unknown_allergies_at === null && (
          <AccordeonButton className="mt-1" onClick={() => markAsUnknown(hash)}>
            {t('sidebar__patient_allergies_mark_unknown')}
          </AccordeonButton>
        )}
      </div>
      <div className="border-b border-separators">
        {allergies.map((allergy) => (
          <AllergyItem
            key={allergy.id}
            allergy={allergy}
            onDelete={() => destroy(allergy.id)}
          />
        ))}
      </div>
    </div>
  );
};

const AllergiesPanel = ({ summary, open, onOpen, onClose, hash }) => {
  const { allergies_count: badge, unknown_allergies_at } = summary;
  const { t } = useTranslation();

  if (!open) {
    return (
      <AccordeonHandler
        onOpen={onOpen}
        badge={badge}
        empty={unknown_allergies_at !== null}
      >
        {t('sidebar__patient_allergies_title')}
      </AccordeonHandler>
    );
  }

  return (
    <AllergyContainer
      badge={badge}
      onClose={onClose}
      hash={hash}
      unknown_allergies_at={unknown_allergies_at}
    />
  );
};

export default AllergiesPanel;
