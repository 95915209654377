import classNames from "classnames";
import BannerType from "components/Banner/BannerType";

const Banner = ({ text, type }: {text: string, type: BannerType}) => {
  return (
    <div
      className={classNames(
        'px-8 py-2 mt-auto text-xs text-center',
        (() => {
          switch (type) {
            case BannerType.warning:
              return 'bg-orange-light text-orange';
            case BannerType.info:
              return 'text-blue bg-blue-light';
          }
        })()
      )}
    >
      {text}
    </div>
  );
};

export default Banner;
