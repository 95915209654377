export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M20.54 5.23L19.15 3.55C18.88 3.21 18.47 3 18 3H6C5.53 3 5.12 3.21 4.84 3.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V6.5C21 6.02 20.83 5.57 20.54 5.23ZM6.24 5H17.76L18.57 5.97H5.44L6.24 5ZM5 19V8H19V19H5Z"
      fill="currentColor"
    />
    <path
      d="M13.45 14L13.45 15.55C13.45 16.3508 12.8008 17 12 17C11.1992 17 10.55 16.3508 10.55 15.55L10.55 14L10.4142 14C9.52331 14 9.07714 12.9229 9.70711 12.2929L11.2929 10.7071C11.6834 10.3166 12.3166 10.3166 12.7071 10.7071L14.2929 12.2929C14.9229 12.9229 14.4767 14 13.5858 14L13.45 14Z"
      fill="currentColor"
    />
  </svg>
);
