import { useState } from 'react';

export const useClinicalHistoryDisclaimer = () => {
  const getShowClinicalHistoryDisclaimer = () => {
    const showClinicalHistoryDisclaimer = localStorage.getItem(
      'showClinicalHistoryDisclaimer'
    );
    return (
      showClinicalHistoryDisclaimer === null ||
      showClinicalHistoryDisclaimer !== 'false'
    );
  };

  const [showDisclaimer, setShowDisclaimer] = useState(
    getShowClinicalHistoryDisclaimer
  );

  const dismiss = () => {
    setShowDisclaimer(false);
    localStorage.setItem('showClinicalHistoryDisclaimer', false);
  };

  return { showDisclaimer, dismiss };
};
