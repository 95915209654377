import ConsultationsApiClient from 'legacy/Services/Consultations';

export const ROOM_ACCEPTED = 'ROOM/ROOM_ACCEPTED';
export const ROOM_REJECTED = 'ROOM/ROOM_REJECTED';

export const roomAccepted = () => ({
  type: ROOM_ACCEPTED,
});

export const acceptRoom = (roomId) => {
  return (dispatch) => {
    return ConsultationsApiClient.chat.acceptRoom(roomId).then(() => {
      dispatch(roomAccepted());
    });
  };
};

export const roomRejected = (profile) => ({
  type: ROOM_REJECTED,
  payload: profile,
});

export const rejectRoom = (roomId, reason, profile) => {
  return (dispatch) => {
    return ConsultationsApiClient.chat.rejectRoom(roomId, reason).then(() => {
      dispatch(roomRejected(profile));
    });
  };
};

export const actionTypes = {
  ROOM_ACCEPTED,
  ROOM_REJECTED,
};
