import { getProfile } from "state/profile/index.selectors";

const initialAuthState = {
  isAuthenticated: false,
};

const authReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case SIGNIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        jwtToken: action.jwtToken,
        profile: action.profile,
        user: action.profile,
      };

    default:
      return state;
  }
};

export const selectJWT = (state) => state.session.profile.jwtToken;
export const selectUser = getProfile;

export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const signInSuccess = (auth) => ({
  type: SIGNIN_SUCCESS,
  jwtToken: auth.token,
  profile: auth.profile,
});

export default authReducer;
