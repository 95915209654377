import React, { useState } from 'react';
import moment from 'moment';

import RoomBody from 'containers/Room/components/RoomBody/RoomBody';
import { useChatRecordMessages } from 'containers/DetailBar/PatientBar/hooks/chatRecords.hook';

const ChatRecordRoom = ({ record }) => {
  const room = useChatRecordMessages(record);

  if (!room) {
    return null;
  }

  return (
    <div style={{ maxHeight: '420px' }} className="flex overflow-y-scroll">
      <RoomBody selfHash={room.professional.hash} room={room} />
    </div>
  );
};

const ChatRecordHandler = ({ record, onClick }) => (
  <div
    onClick={onClick}
    className="flex items-center w-full p-4 cursor-pointer hover:bg-gray-light"
  >
    <img
      src={record.professional.avatar}
      className="object-cover w-8 h-8 mr-2 rounded-full"
    />
    <div>
      <div className="font-medium leading-4 text-md">
        {record.professional.name}
      </div>
      <div className="text-xs font-medium leading-4 text-gray-medium">
        {record.professional.speciality_alias}
      </div>
      {record.last_message && (
        <div className="text-xs leading-4 text-gray-medium">
          {moment(record.last_message.createdAt).format('LLL')}
        </div>
      )}
    </div>
  </div>
);

const ChatRecordItem = ({ record }) => {
  const [showRoom, setShowRoom] = useState(false);

  if (showRoom) {
    return (
      <div>
        <ChatRecordHandler record={record} onClick={() => setShowRoom(false)} />
        <ChatRecordRoom record={record} />
      </div>
    );
  }

  return (
    <div onClick={() => setShowRoom(true)}>
      <ChatRecordHandler onClick={() => setShowRoom(true)} record={record} />
    </div>
  );
};

export default ChatRecordItem;
