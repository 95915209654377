import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import Trash from 'components/icons/outline/Trash';

const IMCItem = ({ imc, onDelete }) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full p-4">
      <div className="leading-4">
        <div className="font-medium text-md">
          {t('sidebar__patient_imc_item_text', {
            value: Number(imc.value).toFixed(2),
          })}
        </div>
        <div className="text-xs font-medium text-gray-medium">{`${imc.height}cm - ${imc.weight}kg`}</div>
        <div className="text-xs text-gray-medium">
          {dayjs(imc.created_at).format('DD/MM/YYYY')}
        </div>
      </div>
      <button
        onClick={onDelete}
        className="flex items-center justify-center w-4 h-4 ml-auto cursor-pointer focus:outline-none"
      >
        <Trash className="text-gray-medium" />
      </button>
    </div>
  );
};

export default IMCItem;
