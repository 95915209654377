import React from 'react';

export default () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0646 6.0003L15.9978 6C11.2898 6.073 7.36955 9.18702 6.26958 13.7325C5.63946 16.3158 6.12626 19.1123 7.60114 21.4067C9.07412 23.7002 11.4137 25.3033 14.0282 25.8054C14.7013 25.9346 15.3766 26 16.038 26C17.6277 26 19.1666 25.6198 20.6049 24.8736C20.6786 24.8411 20.7393 24.8269 20.8095 24.8269C20.855 24.8269 20.9041 24.8316 20.9619 24.8423L24.4073 25.746C25.1519 25.9407 25.8281 25.2567 25.6248 24.5144L25.0497 22.4221C24.9369 22.0157 24.8264 21.6211 24.7173 21.2349C24.6774 21.0522 24.692 20.9386 24.7753 20.7912C26.3066 17.9626 26.4039 14.6836 25.0521 11.7854C23.697 8.87921 21.0999 6.83807 17.94 6.19432C17.3668 6.0849 16.7267 6.01944 16.0646 6.0003ZM16.0357 7.99989C16.5666 8.01566 17.1014 8.07035 17.5529 8.15649C20.0807 8.67153 22.1528 10.3 23.2395 12.6307C24.2803 14.8623 24.2506 17.359 23.1661 19.551L23.0251 19.8234C22.6997 20.3992 22.6197 21.0212 22.7797 21.7276L23.2373 23.373L21.3956 22.8912C21.1492 22.8432 20.9794 22.8269 20.8095 22.8269C20.441 22.8269 20.0984 22.907 19.7278 23.0768C18.5283 23.6978 17.3051 24 16.038 24C15.5043 24 14.9552 23.9468 14.4054 23.8413C12.333 23.4433 10.4633 22.1622 9.28376 20.3256C8.10249 18.4879 7.71344 16.2529 8.21305 14.2046C9.0773 10.6333 12.0821 8.1778 15.7586 8.00809L16.0357 7.99989Z"
      fill="#4A1EA7"
    />
    <path
      d="M11.9241 12.0883C11.6688 12.4236 11.6943 12.9034 12.0007 13.2098L14.791 16L12.0007 18.7902C11.6664 19.1245 11.6664 19.665 12.0007 19.9993L12.0883 20.0759C12.4236 20.3312 12.9034 20.3057 13.2098 19.9993L16 17.209L18.7902 19.9993C19.1245 20.3336 19.665 20.3336 19.9993 19.9993L20.0759 19.9117C20.3312 19.5764 20.3057 19.0966 19.9993 18.7902L17.209 16L19.9993 13.2098C20.3275 12.8816 20.3275 12.335 19.9993 12.0068L19.9117 11.9302C19.5764 11.6748 19.0966 11.7004 18.7902 12.0068L16 14.791L13.2098 12.0007C12.8755 11.6664 12.335 11.6664 12.0007 12.0007L11.9241 12.0883Z"
      fill="#A48ED2"
    />
  </svg>
);
