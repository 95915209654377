import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { create } from 'containers/Prescription/actions/Prescription.actions';
import * as Sentry from '@sentry/browser';
import parsePhoneNumberLib from 'libphonenumber-js';
import useScript from 'utils/useScript';
import { config } from 'config';

const WIDGET_ROOT_EL = 'widget_osigu_create_prescription';
const MAX_CHARACTERS_ALLOWED = 50;
const SPECIAL_PATIENT_HASH = import.meta.env
  .REACT_APP_SPECIAL_PATIENT_FOR_PRESCRIPTIONS_HASH;

const OsiguCreatePrescription = ({
  hash,
  userHash,
  credentials,
  patient,
  roomId,
  providerCredentials,
  consultationType,
  consultationId,
  onFinish,
}) => {
  const dispatch = useDispatch();
  useOsiguPrescriptionScript({
    hash,
    userHash,
    credentials,
    providerCredentials,
    patient,
    onPrescriptionComplete: ({ detail }) => {
      dispatch(
        create({
          hash,
          roomId,
          provider: providerCredentials.provider_name,
          data: detail,
          consultationType,
          consultationId,
        })
      );
      onFinish();
    },
  });

  return <div id={WIDGET_ROOT_EL} />;
};

const useOsiguPrescriptionScript = ({
  hash,
  userHash,
  credentials,
  onPrescriptionComplete,
  providerCredentials,
  patient,
}) => {
  const src = config.services.osigu.prescription_url;
  const { profile } = useSelector((state) => state.session);

  const status = useScript(src);

  useEffect(() => {
    const container = document.getElementById(WIDGET_ROOT_EL);
    container.addEventListener(
      'onPrescriptionComplete',
      onPrescriptionComplete
    );
  }, []);

  useEffect(() => {
    if (status !== 'ready') {
      return;
    }

    const validatePrescriptionWidgetData = () => {
      if (
        !providerCredentials?.client_id ||
        !providerCredentials?.client_secret ||
        !providerCredentials?.provider_name ||
        !credentials?.countryCode ||
        !credentials?.medicalLicenseNumber
      ) {
        Sentry.captureException(new Error('Invalid credentials'), {
          extra: { providerCredentials, credentials, patient },
        });
      }
    };

    const initPrescriptionWidget = () => {
      validatePrescriptionWidgetData();

      const [firstName, ...lastNames] = patient?.name?.split(' ') ?? [
        undefined,
        undefined,
      ];
      const { nationalNumber, countryCallingCode } = parsePhoneNumber(
        patient?.phone
      );

      const patientConfig =
        isSpecialPatient({ userHash }) ||
        [40, 13].includes(profile.speciality_id)
          ? undefined
          : {
              givenNames: firstName?.slice(0, MAX_CHARACTERS_ALLOWED),
              surnames: lastNames?.join(' ').slice(0, MAX_CHARACTERS_ALLOWED),
              nationality: patient?.country_code?.toUpperCase(),
              areaCode: countryCallingCode
                ? `+${countryCallingCode}`
                : undefined,
              phoneNumber: nationalNumber,
              dateOfBirth: patient?.birth_date,
              emailAddress: patient?.email,
              sex: patient?.gender?.toUpperCase(),
              identityDocumentType: patient?.tax_id
                ? 'NATIONAL_IDENTITY_CARD'
                : undefined,
              identityDocumentCode: patient?.tax_id,
            };

      const config = {
        containerId: WIDGET_ROOT_EL,
        clientId: providerCredentials.meta.client_id,
        clientSecret: providerCredentials.meta.client_secret,
        primaryColor: '#4A1EA7',
        errorColor: '#FF014D',
        locale: 'es',
        template: patient?.template,
        doctor: {
          countryCode: credentials.countryCode.toUpperCase(),
          medicalLicenseNumber: credentials.medicalLicenseNumber,
        },
        patient: patientConfig,
      };

      if (providerCredentials.meta.provider_slug) {
        config.providerSlugs = [providerCredentials.meta.provider_slug];
      }

      if (providerCredentials.meta.customization.observations) {
        config.prescriptionObservation =
          providerCredentials.meta.customization.observations;
      }

      if (credentials?.countryCode?.toLowerCase() !== 'es') {
        config.enabledFeatures = ['diagnoses'];
      }
      window.EPrescriptionWidget.uniqueInstanceCreated = false;
      window.EPrescriptionWidget.createWidget(config);
    };

    initPrescriptionWidget();
  }, [status]);
};

const isSpecialPatient = ({ userHash }) => userHash === SPECIAL_PATIENT_HASH;

const parsePhoneNumber = (phone) => {
  const parsedDefault = { nationalNumber: phone };
  try {
    return parsePhoneNumberLib(phone) ?? parsedDefault;
  } catch (error) {
    Sentry.captureException(error, { extra: { phone } });
    return parsedDefault;
  }
};

export default OsiguCreatePrescription;
