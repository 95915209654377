import Trash from 'components/icons/Trash';
import Pencil from 'components/icons/Pencil';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import i18n from 'i18n';

const TrackingItem = ({ tracking, onDelete, onEdit }) => {
  const language = i18n.language;

  dayjs.extend(relativeTime);

  return (
    <div className="flex p-4 w-full">
      <img
        src={tracking.professional.avatar}
        className="w-8 h-8 rounded-full mr-2"
      />
      <div className="leading-4">
        <div className="text-md font-medium">{tracking.professional.name}</div>
        <div className="text-xs text-gray-medium font-medium leading-4">
          {dayjs(tracking.to_send_at).locale(language).fromNow()}
        </div>
        <div className="text-xs leading-4 text-gray-medium">
          {tracking.message}
        </div>
      </div>
      <button
        onClick={onEdit}
        className="w-4 h-4 cursor-pointer focus:outline-none flex items-center justify-center ml-auto"
      >
        <Pencil className=" w-3 h-3 text-gray-medium" />
      </button>
      <button
        onClick={onDelete}
        className="w-4 h-4 cursor-pointer focus:outline-none flex items-center justify-center ml-1"
      >
        <Trash className="text-gray-medium" />
      </button>
    </div>
  );
};

export default TrackingItem;
