const ChatBadge = (props: any) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.97732 1.33331L8.09227 1.33401L8.2674 1.34607L8.58558 1.37154L8.86176 1.399L9.09688 1.42869L9.19933 1.44444L9.29188 1.46083L9.52599 1.51197C9.65565 1.54273 9.7838 1.57706 9.91032 1.61489C9.57858 1.95735 9.32749 2.37382 9.18522 2.83652L9.03073 2.80217L8.87166 2.77628L8.6765 2.75246L8.43724 2.72924L8.01988 2.69739L7.64742 2.71216C5.33118 2.86455 3.43439 4.47796 2.87688 6.78183C2.5481 8.13095 2.805 9.60156 3.58141 10.8101C4.35685 12.0179 5.58628 12.8619 6.95089 13.1241C7.31221 13.1934 7.67352 13.2285 8.02507 13.2285C9.03856 13.2285 9.4895 13.1514 10.213 12.7809C10.4504 12.6747 10.6702 12.6253 10.9049 12.6253C11.0598 12.6253 11.2585 12.6625 11.493 12.731C11.6916 12.7892 11.911 12.8684 12.1433 12.9634C12.2639 13.0127 12.3833 13.0644 12.4993 13.1169L12.6706 13.1961L12.8873 13.3016L12.3819 11.4537C12.3589 11.3472 12.3481 11.2561 12.3486 11.1759C12.3489 11.0932 12.3555 11.0519 12.3841 10.945C12.3981 10.9007 12.416 10.8567 12.4397 10.8077L12.4798 10.7298L12.5991 10.5198L12.7123 10.305C13.2814 9.1542 13.41 7.88096 13.0962 6.67072C13.571 6.5371 13.9998 6.29749 14.3535 5.98001C14.8489 7.52693 14.7563 9.18377 14.0774 10.685L13.9764 10.8984L13.8696 11.1046L13.78 11.2616L14.2437 12.9499C14.3674 13.4011 14.2446 13.8768 13.9298 14.2161L13.8471 14.298L13.7634 14.3685C13.3587 14.6828 12.8126 14.7526 12.3429 14.5599L12.236 14.5108L12.1535 14.47L11.8318 14.3209L11.5993 14.2219C11.4566 14.1635 11.3226 14.1134 11.2031 14.074L11.0347 14.0231L10.9049 13.9904C10.8785 13.9904 10.8576 13.9951 10.8339 14.0046C9.90726 14.4806 9.2466 14.5937 8.02507 14.5937C7.58125 14.5937 7.12897 14.5497 6.679 14.4634C4.92821 14.127 3.36899 13.0565 2.39103 11.5333C1.4174 10.0178 1.09526 8.17371 1.51093 6.46801C2.21032 3.57783 4.63313 1.52709 7.58718 1.34793L7.78489 1.33877L7.97732 1.33331ZM12.2108 1.67352C13.3764 1.67352 14.3161 2.58756 14.3161 3.72125C14.3161 4.85493 13.3764 5.76897 12.2108 5.76897C11.0453 5.76897 10.1055 4.85494 10.1055 3.72125C10.1055 2.58756 11.0453 1.67352 12.2108 1.67352Z"
      fill="currentColor"
    />
  </svg>
);
export default ChatBadge;
