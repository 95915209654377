import { FC } from 'react';
import { cn } from 'components/utils';

export interface Props {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const DropdownOption: FC<Props> = ({
  children,
  className,
  onClick,
  disabled,
}) => (
  <button
    className={cn(
      'px-4 py-2 w-full text-left font-medium text-md text-dark hover:bg-gray-light focus:outline-none',
      { 'opacity-50 hover:bg-transparent': disabled },
      className ?? ''
    )}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

export default DropdownOption;
