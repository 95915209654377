import api from 'api';
import Check from 'components/icons/outline/Check';
import {
  clinicalCourseCreated,
  clinicalCourseDeleted,
} from 'containers/DetailBar/PatientBar/actions/MedicalHistory.actions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getCurrentClinicalCourse,
  getCurrentDraft,
} from 'state/clinicalCourses/index.selectors';
import {
  addCourses,
  updateCourses,
  fetchCourses,
  removeCourses,
} from 'state/clinicalCourses/index.slice';
import useConfirmDelete from 'utils/useConfirmDelete';

const useClinicalCourses = ({ roomId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const confirmDelete = useConfirmDelete();
  const draft = useSelector(getCurrentDraft);
  const current = useSelector(getCurrentClinicalCourse);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = () => {
    setIsLoading(true);
    api.clinicalCourse
      .list({ roomId })
      .then(({ data }) => dispatch(fetchCourses(data.data)))
      .finally(() => setIsLoading(false));
  };

  const remove = ({ id }) =>
    confirmDelete({
      title: t('clinical__course_confirm_modal_title'),
      content: t('clinical__course_confirm_modal_content'),
    }).then(() => {
      api.clinicalCourse.remove({ id });
      dispatch(removeCourses({ id }));
      dispatch(clinicalCourseDeleted());
    });

  const deleteDraft = ({ id }) => {
    confirmDelete({
      title: t('clinical__course_confirm_modal_title'),
      content: t('clinical__course_confirm_modal_content'),
    }).then(() => {
      api.clinicalCourse.remove({ id });
      dispatch(removeCourses({ id }));
    });
  };

  const saveDraft = async (draft) => {
    const response = await api.clinicalCourse.create(draft);
    dispatch(addCourses(response.data.data));
  };

  const create = async (data) => {
    const response = await api.clinicalCourse.create(data);
    if (response.status === 201 || data.wasDraft) {
      dispatch(clinicalCourseCreated());
      dispatch(addCourses(response.data.data));
    } else {
      dispatch(updateCourses(response.data.data));
    }

    toast.success(
      <div className="flex items-center gap-3 text-positive-dark">
        <Check className="h-6 w-6" />
        {t('clinical__course_toast_create_success')}
      </div>
    );

    return response.data.data;
  };

  return {
    fetch,
    deleteDraft,
    draft,
    isLoading,
    current,
    saveDraft,
    create,
    remove,
  };
};

export default useClinicalCourses;
