import { config } from 'config';

export const getImageUrl = ({ message, profile }) => {
  if (message.url) {
    return message.url;
  }

  return `${config.services.mediquo.chat_server_url}getImage?id=${message.messageId}&jwt=${profile.jwt}`;
};

export const getFileUrl = ({ message, profile }) => {
  if (message.url) {
    return message.url;
  }

  return `${config.services.mediquo.chat_server_url}getFile?id=${message.messageId}&jwt=${profile.jwt}`;
};

export const getMeta = (message) => {
  let meta;
  try {
    meta = JSON.parse(message.string);
  } catch (err) {
    meta = { fileName: message.fileName, fileSize: message.fileSize };
  }
  return meta;
};
