import React, { useState } from 'react';
import { LinkItUrl } from 'react-linkify-it';
import ModalImage from 'react-modal-image';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { sizeForHumans } from 'utils/sizeForHumans';
import { getFileUrl, getImageUrl, getMeta } from 'utils/getAttachmentUrl';
import { useSelector } from 'react-redux';
import isSystemMessage from 'containers/Shared/Room/utils/isSystemMessage';
import { getProfile } from 'state/profile/index.selectors';
import MessageOptions from 'containers/Room/components/RoomBody/MessageOptions';
import Ban from 'components/icons/outline/Ban';
import FeatureFlag from 'containers/Shared/components/FeatureFlag';
import { config } from 'config';
import VoiceMessage from 'containers/Room/components/RoomBody/Message/VoiceMessage/VoiceMessage';

const TYPES = {
  TEXT: 'string',
  FILE: 'file',
  IMAGE: 'image',
};

const FileMessage = ({
  message,
  showButton,
  setShowButton,
  isMine,
  profile,
  canEliminate,
}) => {
  const meta = getMeta(message);

  if (message?.attachment?.type === 'voice_note') {
    return (
      <VoiceMessage
        message={message}
        isButtonShown={showButton}
        onShowButton={setShowButton}
        isMine={isMine}
      />
    );
  }
  //
  return (
    <div
      style={{ wordBreak: 'break-word' }}
      className={`flex text-dark pl-4 py-2 rounded-lg max-w-[65%] mb-1 ${
        message.auto !== 1 && isMine && 'cursor-pointer'
      } ${
        isMine
          ? 'bg-secundary shadow-separators rounded-br'
          : 'bg-white shadow-separators rounded-bl border-blue-light border'
      }`}
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
    >
      <span className="pr">
        <a
          href={getFileUrl({ message, profile })}
          target="_blank"
          rel="noreferrer"
          className="mr-2 text-primary hover:text-blue hover:underline "
        >
          {meta.fileName}
        </a>
        <span>({sizeForHumans(meta.fileSize)})</span>
      </span>
      <div className={`relative mr-1 ${!showButton && 'opacity-0'}`}>
        {!message.deletedAt && message.auto !== 1 && isMine && (
          <FeatureFlag enabled={canEliminate}>
            <MessageOptions message={message} isMine={isMine} />
          </FeatureFlag>
        )}
      </div>
    </div>
  );
};

const Message = ({ message, isMine }) => {
  const { t } = useTranslation();
  const profile = useSelector(getProfile);
  const [showButton, setShowButton] = useState(false);
  const canEliminate = config.features.delete_messages;

  const TextMessage = () => {
    return (
      <div
        style={{ wordBreak: 'break-word' }}
        className={`flex relative text-dark pl-4 py-2 rounded-lg max-w-[65%] mb-1 min-w-0 whitespace-pre-line cursor-pointer border-3 ${
          isMine
            ? 'bg-secundary shadow-separators rounded-br'
            : 'bg-white shadow-separators rounded-bl border-blue-light border'
        }`}
        onMouseEnter={() => setShowButton(true)}
        onMouseLeave={() => setShowButton(false)}
      >
        <div>
          {Boolean(message.auto) && isMine && (
            <div className="text-sm text-primary">
              {t('room__automatic_message_text')}
            </div>
          )}
          <div className="break-words pr">
            <LinkItUrl className="hover:underline text-blue">
              {message.string}
            </LinkItUrl>
          </div>
        </div>
        <div className={`relative mr-1 ${!showButton && 'opacity-0'}`}>
          {!message.deletedAt && (
            <MessageOptions message={message} isMine={isMine} />
          )}
        </div>
      </div>
    );
  };

  const SystemMessage = () => {
    return (
      <div className="min-w-0 px-4 py-3 mb-1 text-sm text-center rounded-lg text-blue max-w-[65%] bg-blue-light">
        <div className="break-words">{message.string}</div>
      </div>
    );
  };

  const ImageMessage = () => {
    const meta = getMeta(message);

    return (
      <div
        className={`text-dark p-1 rounded-lg max-w-[65%] mb-1 relative ${
          isMine
            ? 'bg-secundary shadow-separators rounded-br'
            : 'bg-white shadow-separators rounded-bl border-blue-light border'
        }`}
        onMouseEnter={() => setShowButton(true)}
        onMouseLeave={() => setShowButton(false)}
      >
        <div style={{ width: '330px' }} className={`absolute z-10 w-full`}>
          <FeatureFlag enabled={canEliminate}>
            <div
              className={`bg-gradient-to-b from-black/40 flex justify-end h-7 rounded z-10 ${
                !showButton && 'opacity-0'
              }`}
            >
              {!message.deletedAt && isMine && (
                <MessageOptions message={message} isMine={isMine} isImage />
              )}
            </div>
          </FeatureFlag>
        </div>
        <div
          style={{ height: '330px', width: '330px' }}
          className="overflow-hidden img-cell-wrap"
        >
          <ModalImage
            className="lightBox rounded"
            small={getImageUrl({ message, profile })}
            large={getImageUrl({ message, profile })}
            alt={meta.fileName}
            showRotate
          />
        </div>
      </div>
    );
  };

  const DeletedMessage = () => {
    return (
      <div
        className={`text-gray-dark px-4 py-2 rounded-lg max-w-[70%] mb-1 min-w-0 whitespace-pre-line ${
          isMine
            ? 'bg-gray-light shadow-separators rounded-br'
            : 'bg-gray-light shadow-separators rounded-bl'
        }`}
      >
        <div className="break-words flex flex-row justify-between items-center">
          {isMine
            ? t('room__message_options_deleted_message_mine')
            : t('room__message_options_deleted_message_not_mine')}
          <Ban className="h-5 text-gray-medium ml-12" />
        </div>
      </div>
    );
  };

  let messageType = null;
  switch (message.type) {
    case TYPES.TEXT:
      messageType = <TextMessage />;
      if (isSystemMessage(message)) {
        messageType = <SystemMessage />;
      }
      break;
    case TYPES.IMAGE:
      messageType = <ImageMessage />;
      break;
    case TYPES.FILE:
      messageType = (
        <FileMessage
          message={message}
          showButton={showButton}
          setShowButton={setShowButton}
          isMine={isMine}
          profile={profile}
          canEliminate={canEliminate}
        />
      );
      break;
    default:
      messageType = null;
      break;
  }

  if (message.deletedAt) {
    messageType = <DeletedMessage />;
  }

  return (
    <div
      className={`flex-col flex ${classnames({
        'items-end': isMine && !isSystemMessage(message),
        'items-start': !isMine && !isSystemMessage(message),
        'items-center': isSystemMessage(message),
      })}`}
    >
      {messageType}
    </div>
  );
};

export default React.memo(Message);
