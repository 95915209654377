import PatientBlockedBanner from 'containers/Room/components/RoomBody/PatientBlockedBanner';
import Message from 'containers/Room/components/RoomBody/Message';
import MessageGroupDateSeparator from 'containers/Room/components/RoomBody/MessageGroupDateSeparator';
import MessageStatus from 'containers/Room/components/RoomBody/MessageStatus';
import MessagesInfiniteScroll from 'containers/Room/components/RoomBody/MessagesInfiniteScroll';
import Skeleton from 'components/Skeleton';
import { useSelector } from 'react-redux';
import isSystemMessage from 'containers/Shared/Room/utils/isSystemMessage';
import dayjs from 'dayjs';
import WelcomeMessage from 'components/WelcomeMessage';
import { getProfile } from 'state/profile/index.selectors';

const RoomBody = ({ room, messagesSource }) => {
  const { isLoading, isLoadingMessages } = useSelector(
    (state) => state.console
  );
  const profile = useSelector(getProfile);

  if (isLoading || isLoadingMessages) {
    return (
      <div className="flex flex-col-reverse flex-grow max-h-full gap-4 p-4 overflow-x-hidden overflow-y-scroll animate-pulse bg-background">
        <Skeleton variant="custom" className="self-end h-10 rounded-md w-72" />
        <Skeleton variant="custom" className="h-20 rounded-md w-80" />
        <Skeleton variant="custom" className="self-end h-10 rounded-md w-80" />
        <Skeleton variant="custom" className="h-12 rounded-md w-52" />
        <Skeleton variant="custom" className="self-end h-10 rounded-md w-28" />
      </div>
    );
  }

  const showWelcomeMessage =
    room?.meta?.hash &&
    room?.type === 'patient' &&
    !profile.has_immediate_service;

  return (
    <>
      <MessagesInfiniteScroll room={room} messagesSource={messagesSource}>
        {({ messages, totalMessages }) =>
          messages?.map((message, index) => {
            const isLast = totalMessages?.length - 1 === index;
            const isFirst = index === 0;
            const ownMessage = profile.hash === message.fromUserHash;
            const isNewDate = fromDifferentDay(
              totalMessages[index + 1],
              message,
              isLast
            );
            const isSameGroup = fromSameGroup(
              message,
              totalMessages[index - 1],
              isFirst
            );
            return (
              <div
                className={`flex flex-col ${isLast ? 'pt-6' : ''}`}
                key={message.messageId}
              >
                {isNewDate && (
                  <div className="self-center px-4 py-1 mb-2 text-gray-medium bg-gray-light rounded-24 text-md">
                    <MessageGroupDateSeparator date={message.createdAt} />
                  </div>
                )}
                <Message message={message} isMine={ownMessage} />
                {isSameGroup && !isSystemMessage(message) && (
                  <div
                    className={`flex items-center mb-3 ${
                      ownMessage ? 'justify-end' : ''
                    }`}
                  >
                    <div className="mr-1 text-xs text-gray-medium">
                      {dayjs(message.createdAt).format('HH:mm')}
                    </div>
                    {ownMessage && <MessageStatus status={message.status} />}
                  </div>
                )}
                {showWelcomeMessage &&
                  totalMessages.length === messages.length &&
                  isLast && <WelcomeMessage />}
              </div>
            );
          })
        }
      </MessagesInfiniteScroll>
      <div className="absolute grid self-center gap-4 mb-auto top-160px">
        {room?.meta?.banned_at && <PatientBlockedBanner />}
      </div>
    </>
  );
};

const fromSameGroup = (message, prevMessage, isFirst) => {
  return isFirst
    ? true
    : prevMessage.fromUserHash !== message.fromUserHash
    ? true
    : !dayjs(prevMessage.createdAt).isSame(dayjs(message.createdAt), 'minute');
};

const fromDifferentDay = (nextMessage, currentMessage, isLast) => {
  return isLast
    ? true
    : !dayjs(nextMessage.createdAt).isSame(
        dayjs(currentMessage.createdAt),
        'day'
      );
};

export default RoomBody;
