import Button from 'components/Button';
import { CloseIcon } from 'components/Drawer';

export interface HeaderProps {
  title: string;
  onClose: () => void;
}

const Header = ({ title, onClose }: HeaderProps) => {
  return (
    <header className="flex px-6 py-5 justify-between items-center border-b border-b-separators">
      <h2 className="text-primary-darker text-xl font-medium">{title}</h2>

      {onClose && (
        <Button variant="clear" size="icon" onClick={onClose}>
          <CloseIcon />
        </Button>
      )}
    </header>
  );
};

export default Header;
