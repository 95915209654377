import { useEffect } from 'react';

export default ({ listener, dependencies = [], onEvent }) => {
  useEffect(() => {
    window.addEventListener(listener, onEvent);
    return () => {
      window.removeEventListener(listener, onEvent);
    };
  }, dependencies);
};
