import classnames from 'classnames';
import IconArrow from 'containers/Inbox/icons/IconArrow';
import useAnimation from 'utils/useAnimation';
import Button from 'components/Button';
import { FC } from 'react';

export interface Props {
  title?: string;
  footer?: string;
  widthClassName?: string;
  onClose: (value?: unknown) => void;
  onBackdrop?: ({ close }: { close: (value?: string) => void }) => void;
}

interface Nested {
  Header: FC<HeaderProps>;
  Footer: FC;
}

const Drawer: FC<Props> & Nested = ({
  title,
  footer,
  children,
  widthClassName,
  onClose,
  onBackdrop,
}) => {
  const { isOpen, close } = useAnimation({ onCloseFinish: onClose });

  return (
    <div className="fixed inset-0 z-20">
      <div
        className={classnames(
          'bg-gray-medium absolute inset-0 transition-all flex-grow',
          {
            'opacity-75': isOpen,
            'opacity-0': !isOpen,
          }
        )}
        onClick={() => (onBackdrop ? onBackdrop({ close }) : close())}
      />
      <aside
        className={classnames(
          'bg-white absolute right-0 h-full flex flex-col transition-all transform overflow-hidden rounded-l-2xl',
          widthClassName ?? 'w-400px',
          {
            'translate-x-full': !isOpen,
          }
        )}
      >
        {title && <Header title={title} onClose={() => close()} />}
        {typeof children === 'function' ? children({ close }) : children}
        {footer && <Footer>{footer}</Footer>}
      </aside>
    </div>
  );
};

interface HeaderProps {
  title: string;
  onClose?: () => void;
  onBack?: () => void;
}

const Header: FC<HeaderProps> = ({ title, onClose, onBack }) => (
  <header className="flex items-center px-6 py-5 space-x-4 border-b border-separators">
    {onBack && (
      <Button variant="clear" size="icon" onClick={onBack}>
        <IconArrow />
      </Button>
    )}
    <div className="flex-grow text-lg font-medium text-dark">{title}</div>
    {onClose && (
      <Button variant="clear" size="icon" onClick={onClose}>
        <CloseIcon />
      </Button>
    )}
  </header>
);

const Footer: FC = ({ children }) => (
  <footer className="px-6 py-5 mt-auto border-t border-separators">
    {children}
  </footer>
);

export const CloseIcon = (props: any) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.3 0.709971C12.91 0.319971 12.28 0.319971 11.89 0.709971L7 5.58997L2.11 0.699971C1.72 0.309971 1.09 0.309971 0.700001 0.699971C0.310001 1.08997 0.310001 1.71997 0.700001 2.10997L5.59 6.99997L0.700001 11.89C0.310001 12.28 0.310001 12.91 0.700001 13.3C1.09 13.69 1.72 13.69 2.11 13.3L7 8.40997L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.41 6.99997L13.3 2.10997C13.68 1.72997 13.68 1.08997 13.3 0.709971Z"
      fill="currentColor"
    />
  </svg>
);

Drawer.Header = Header;
Drawer.Footer = Footer;
export default Drawer;
