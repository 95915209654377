import useDiagnosticTest from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/useDiagnosticTest';
import BottomDrawer from 'components/Drawer/BottomDrawer';
import classnames from 'classnames';

type ListingProps = {
  onClose: () => void;
  newSelectedItems?: any[];
  newPetitions?: any[];
};

const Listing = ({ onClose, newSelectedItems, newPetitions }: ListingProps) => {
  const { selected_items, categories } = useDiagnosticTest();
  const { petitions } = useDiagnosticTest();

  const getSelectedItems = () => {
    const selectedItems = newSelectedItems || selected_items;
    const selectedPetitions = newPetitions || petitions;
    return (
      <div className="overflow-y-auto mx-2">
        {selectedPetitions.map((petition: string, index: number) => {
          return (
            <div
              className="text-gray-dark flex flex-col text-sm mt-2"
              key={index}
            >
              <span>- {petition}</span>
              {selectedItems?.map(
                (item: any) =>
                  item?.meta?.component_name === petition &&
                  item?.name !== petition && (
                    <div className="flex mt-1 ml-2 px-2" key={item?.id}>
                      • <span className="ml-2">{item?.name}</span>
                    </div>
                  )
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <BottomDrawer
      onClose={onClose}
      heightClassName="h-fit max-h-[45vh]"
      isBackgroundTransparent
      className="bottom-[84px]"
    >
      <div className={classnames('flex w-full max-h-screen')}>
        <div className={classnames('flex w-full')}>
          {categories && (
            <div className="flex flex-col w-full bg-white rounded-t-xl justify-between py-4 px-6">
              {getSelectedItems()}
            </div>
          )}
        </div>
      </div>
    </BottomDrawer>
  );
};

export default Listing;
