import { useEffect, forwardRef, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useKeyPress from 'utils/useKeyPress';
import useOutside from 'utils/useOutside';
import Tracker from 'utils/Tracking';

const MIN_CHARACTERS_TO_SUGGEST = 3;

const SuggestedSentences = ({ inputRef, open, term, onClose, onSelected }) => {
  const [filteredSentences, setFilteredSentences] = useState([]);
  const sentences = useSelector((state) => state.session.sentences);

  useEffect(() => {
    if (open) {
      Tracker.pageView('suggested sentence view', { event_query: term });
    }
  }, [open]);

  useEffect(() => {
    if (isValidTerm()) {
      setFilteredSentences(
        sentences?.filter(({ string }) => startWith(string, term))
      );
    }
  }, [sentences, term]);

  const focusInputAndClose = () => {
    inputRef?.current?.focus();
    onClose && onClose();
  };

  const focusInputAndCloseWithMessage = (string) => {
    inputRef?.current?.focus();
    onSelected && onSelected(string);
  };

  const isValidTerm = () => term?.length >= MIN_CHARACTERS_TO_SUGGEST;

  if (!open || !isValidTerm() || filteredSentences.length === 0) {
    return null;
  }

  return (
    <HighlightedSentences
      term={term}
      sentences={filteredSentences}
      onSelected={focusInputAndCloseWithMessage}
      onClose={focusInputAndClose}
    />
  );
};

const HighlightedSentences = ({ term, sentences, onClose, onSelected }) => {
  const wrapperRef = useOutside(onClose);
  useKeyPress({ key: 'Escape', onPress: onClose });
  const [focusedIndex, setFocusedIndex] = useIndex({
    max: sentences.length - 1,
    term,
  });

  return (
    <Wrapper ref={wrapperRef}>
      <Header>Selecciona una frase predefinida</Header>
      <ItemList>
        {sentences.map(({ id, string }, index) => (
          <Item
            key={id}
            highlight={term}
            string={string}
            focused={focusedIndex === index}
            onClick={() => {
              Tracker.event('suggested sentence selection', {
                event_message_content: string,
                event_query: term,
              });
              onSelected(string);
            }}
            onFocus={() => setFocusedIndex(index)}
          />
        ))}
      </ItemList>
    </Wrapper>
  );
};

const Wrapper = forwardRef(({ children }, ref) => (
  <div
    ref={ref}
    className={
      'z-20 max-w-3xl absolute left-0 -top-4 transform -translate-y-full bg-white shadow-lg rounded-lg'
    }
  >
    {children}
  </div>
));

const Header = ({ children }) => (
  <div className={'text-xs text-primary px-4 py-2'}>{children}</div>
);

const ItemList = ({ children }) => (
  <div className="mb-4 overflow-auto max-h-200px">{children}</div>
);

const Item = ({ string, highlight, focused, onClick, onFocus }) => {
  const [highlighted, rest] = splitByIndex({ string, index: highlight.length });
  const buttonRef = useRef();

  useEffect(() => {
    if (focused) {
      buttonRef?.current?.focus();
    }
  }, [focused]);

  const handleKeyDown = (event) => {
    if ('Enter' === event.key && event.shiftKey === false) {
      Tracker.event('suggested sentence enter key', { event_query: highlight });
    }
  };

  return (
    <>
      <div className="w-full px-3 first:hidden">
        <div className="h-px rounded-lg bg-separators" />
      </div>
      <button
        ref={buttonRef}
        onKeyDown={(e) => handleKeyDown(e)}
        onClick={onClick}
        onFocus={onFocus}
        className={`cursor-pointer focus:bg-gray-light px-4 py-2 w-full text-left font-normal text-md text-dark focus:outline-none`}
      >
        <strong className="font-medium">{highlighted}</strong>
        {rest}
      </button>
    </>
  );
};

const useIndex = ({ initial = 0, min = 0, max, term }) => {
  const [index, setIndex] = useState(null);

  useKeyPress({
    key: 'ArrowUp',
    onPress: useCallback(() => {
      Tracker.event('suggested sentence arrow key', { event_query: term });
      setIndex((index) =>
        index === null ? initial : index > min ? index - 1 : min
      );
    }, [index, min, setIndex]),
  });

  useKeyPress({
    key: 'ArrowDown',
    onPress: useCallback(() => {
      Tracker.event('suggested sentence arrow key', { event_query: term });
      setIndex((index) =>
        index === null ? initial : index < max ? index + 1 : max
      );
    }, [index, max, setIndex]),
  });

  return [index, setIndex];
};

const startWith = (a, b) => a?.toLowerCase()?.startsWith(b?.toLowerCase());

const splitByIndex = ({ string, index }) => [
  string.slice(0, index),
  string.slice(index),
];

export default SuggestedSentences;
