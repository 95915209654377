export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    fill="none"
    viewBox="0 0 19 19"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.122 11.195l-.177.168-2.308-2.308.028-.028a3.551 3.551 0 001.017-2.92l-.226-1.965A3.562 3.562 0 004.918.993H3.569C1.544.992-.11 2.687.015 4.729c.475 7.66 6.598 13.78 14.256 14.256 2.041.127 3.737-1.53 3.737-3.555v-1.348c.014-1.434-.911-2.476-2.41-3.297l-.345-.189-2.37-.27a3.55 3.55 0 00-2.761.869zM4.918 2.992c.745 0 1.374.52 1.527 1.234l.024.145.226 1.964c.048.423-.076.84-.34 1.162l-.132.144a2 2 0 00-.117 2.701l.117.127 2.307 2.308.128.117a2 2 0 002.574 0l.147-.137c.297-.298.7-.46 1.12-.454l.158.01 1.98.226c.853.468 1.308.945 1.365 1.43l.006.113v1.348c0 .88-.733 1.613-1.613 1.559C7.747 16.576 2.424 11.26 2.01 4.605c-.052-.834.603-1.536 1.42-1.607l.138-.006h1.349z"
    ></path>
  </svg>
);
