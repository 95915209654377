export default (props) => (
  <svg width="110" height="66" fill="none" viewBox="0 0 110 66" {...props}>
    <path
      fill="#fff"
      d="M109.995 27.329V25.69l-1.45.16-.118.136L67.408 2.422c-.85-.48-2.123-.539-2.856-.116L2.465 37.966l-.387-.619-1.86.773v1.458c-.067.358.166.752.688 1.048l41.899 24.07c.85.48 2.123.539 2.856.116l63.851-36.673c.369-.213.526-.508.483-.81z"
    ></path>
    <path
      fill="#ECEFF1"
      d="M109.995 27.329V25.69l-1.45.16-.118.136L67.408 2.422c-.85-.48-2.123-.539-2.856-.116L2.465 37.966l-.387-.619-1.86.773v1.458c-.067.358.166.752.688 1.048l41.899 24.07c.85.48 2.123.539 2.856.116l63.851-36.673c.369-.213.526-.508.483-.81z"
    ></path>
    <path
      fill="#E7E3F1"
      d="M109.304 26.116L45.454 62.79c-.732.422-2.006.364-2.856-.117L.701 38.602c-.85-.48-.938-1.223-.205-1.646L64.346.284C65.078-.14 66.352-.081 67.2.4L109.1 24.47c.835.48.937 1.224.205 1.646z"
    ></path>
    <path
      fill="#F7F8FA"
      d="M109.304 26.116L45.454 62.79c-.732.422-2.006.364-2.856-.117L.701 38.602c-.85-.48-.938-1.223-.205-1.646L64.346.284C65.078-.14 66.352-.081 67.2.4L109.1 24.47c.835.48.937 1.224.205 1.646z"
    ></path>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M67.992 41.433l-22.698 13.27c-.367.212-1.005.183-1.43-.058L14.613 37.913c-.425-.24-.469-.612-.102-.823l23.732-13.443c.366-.211 1.004-.182 1.429.058L67.89 40.61c.417.24.469.612.102.824z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#F7F8FA"
      fillRule="evenodd"
      d="M29.988 37.869c-.01-1.847 2.597-3.359 5.823-3.376 3.227-.018 5.85 1.465 5.86 3.313.01 1.847-2.597 3.358-5.823 3.376-3.226.017-5.85-1.466-5.86-3.313zM42.463 48.714l13.427-7.87a.516.516 0 00-.068-.922c-1.73-.718-3.82-1.133-6.073-1.121-5.928.032-10.718 3.007-10.698 6.644.005 1.013.384 1.97 1.056 2.824.562.715 1.57.906 2.356.445z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#4A1EA7"
      fillRule="evenodd"
      d="M29.988 33.565c-.01-1.847 2.597-3.358 5.823-3.376 3.227-.017 5.85 1.466 5.86 3.313.01 1.847-2.597 3.359-5.823 3.376-3.226.018-5.85-1.466-5.86-3.313zM42.463 44.41l13.613-7.978a.407.407 0 00-.043-.724c-1.77-.773-3.94-1.223-6.284-1.21-5.928.031-10.718 3.006-10.698 6.643.005 1.013.384 1.97 1.056 2.824.562.715 1.57.906 2.356.445z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#fff"
      d="M91.278 20.38l1.077.602c.297.166.3.59.004.76L71.07 33.965a1.365 1.365 0 01-1.365-.005l-1.088-.633a.436.436 0 01.003-.757l21.317-12.183c.415-.236.924-.24 1.341-.006zm-6.762-4.11l1.077.601c.298.166.3.59.005.76l-21.29 12.223a1.365 1.365 0 01-1.366-.005l-1.088-.634a.436.436 0 01.003-.756l21.317-12.183c.415-.237.924-.24 1.342-.006zm-7.297-4.42l1.078.602c.297.166.3.59.004.76l-21.29 12.223a1.365 1.365 0 01-1.365-.005l-1.089-.633a.436.436 0 01.003-.757l21.317-12.183c.416-.237.925-.24 1.342-.006zm-6.761-4.11l1.077.6c.297.167.3.592.005.761L50.25 21.324a1.365 1.365 0 01-1.366-.005l-1.088-.633a.436.436 0 01.003-.757L69.116 7.746c.415-.237.924-.24 1.341-.007z"
    ></path>
    <path
      fill="#ECEEFD"
      d="M91.278 20.38l1.077.602c.297.166.3.59.004.76L71.07 33.965a1.365 1.365 0 01-1.365-.005l-1.088-.633a.436.436 0 01.003-.757l21.317-12.183c.415-.236.924-.24 1.341-.006zm-6.762-4.11l1.077.601c.298.166.3.59.005.76l-21.29 12.223a1.365 1.365 0 01-1.366-.005l-1.088-.634a.436.436 0 01.003-.756l21.317-12.183c.415-.237.924-.24 1.342-.006zm-7.297-4.42l1.078.602c.297.166.3.59.004.76l-21.29 12.223a1.365 1.365 0 01-1.365-.005l-1.089-.633a.436.436 0 01.003-.757l21.317-12.183c.416-.237.925-.24 1.342-.006zm-6.761-4.11l1.077.6c.297.167.3.592.005.761L50.25 21.324a1.365 1.365 0 01-1.366-.005l-1.088-.633a.436.436 0 01.003-.757L69.116 7.746c.415-.237.924-.24 1.341-.007z"
    ></path>
  </svg>
);
