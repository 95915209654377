export const config = {
  app: {
    environment: import.meta.env.REACT_APP_APP_ENV,
  },
  features: {
    delete_messages: import.meta.env.REACT_APP_CAN_DELETE_MESSAGES,
  },
  services: {
    firebase: {
      api_key: import.meta.env.REACT_APP_FIREBASE_API_KEY,
      app_id: import.meta.env.REACT_APP_FIREBASE_APP_ID,
      project_id: import.meta.env.REACT_APP_FIREBASE_PROJECT_ID,
      sender_id: import.meta.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    },
    google: {
      analytics: import.meta.env.REACT_APP_GOOGLE_ANALYTICS_ID,
      gtm_code: import.meta.env.REACT_APP_GTM_TRACKING_CODE,
      recaptcha_api_key: import.meta.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY,
    },
    mediquo: {
      chat_server_url: import.meta.env.REACT_APP_URL_NODE,
      chat_url: import.meta.env.REACT_APP_URL_CONSULTATIONS,
      api_url: import.meta.env.REACT_APP_MQ_API_URL,
      tracking_url: import.meta.env.REACT_APP_TRACKING_EVENTS_URL,
      tracking_call_url: import.meta.env.REACT_APP_TRACKING_CALL_EVENTS_URL,
    },
    osigu: {
      prescription_url: import.meta.env.REACT_APP_OSIGU_PRESCRIPTION_SCRIPT_URL,
      enrollment_url: import.meta.env
        .REACT_APP_OSIGU_DOCTOR_ENROLLMENT_SCRIPT_URL,
      spain_enrollment_url: import.meta.env.REACT_APP_OSIGU_ONBOARDING_LINK,
      spain_enrollment_callback_url: import.meta.env
        .REACT_APP_OSIGU_ONBOARDING_CALLBACK_LINK,
    },
    sentry: {
      environment: import.meta.env.REACT_APP_SENTRY_ENVIRONMENT,
      dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    },
    tokbox: {
      api_key: import.meta.env.REACT_APP_TOKBOX_APIKEY,
    },
  },
};
