export default () => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity="0.5"
      x="0.5"
      width="48"
      height="48"
      rx="16"
      fill="black"
      fillOpacity="0.5"
    />
    <path
      d="M22.5742 25.3125L22.5826 24.9871L26.8276 24.9871V25.0391C26.8276 26.4861 27.4708 27.8496 28.5784 28.7512L30.5934 30.3903C32.4465 31.8863 35.0824 31.7303 36.7457 30.0255L37.9858 28.7543C39.8486 26.8449 39.8124 23.6849 37.8184 21.8795C30.3352 15.1057 19.0734 15.1082 11.5924 21.8792C9.59802 23.6849 9.56161 26.845 11.4244 28.7544L12.6646 30.0256C13.9707 31.391 15.7798 31.5001 17.9145 30.8612L18.4047 30.7145L20.8332 28.7355C21.8659 27.8949 22.4949 26.6511 22.5742 25.3125ZM34.9061 28.1398C34.2206 28.8425 33.1633 28.9445 32.3664 28.4157L32.2107 28.3015L30.1963 26.6629C29.763 26.3102 29.494 25.8008 29.4395 25.2481L29.4293 24.9871C29.4293 23.5918 28.3839 22.4469 27.0521 22.3302L26.8276 22.3204L22.5827 22.3204L22.3582 22.3301C21.1005 22.4403 20.0981 23.4677 19.9907 24.7569L19.9811 25.0243C19.9811 25.586 19.7602 26.1187 19.3688 26.5088L19.2141 26.6482L17.1854 28.3014C15.9695 28.6653 15.112 28.6449 14.6131 28.24L14.5044 28.1398L13.2642 26.8686C12.4541 26.0383 12.4541 24.6569 13.3144 23.8781C19.8091 17.9999 29.5941 17.9926 36.096 23.8781C36.911 24.616 36.9539 25.8946 36.2674 26.7332L36.1462 26.8687L34.9061 28.1398Z"
      fill="white"
      fillOpacity="0.3"
    />
  </svg>
);
