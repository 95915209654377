import {
  getRoom,
  getRoomsList,
  getSummaryRoomsList,
} from 'containers/Inbox/actions/GetRoomList.actions';
import { InboxMode } from 'containers/Inbox/reducers/inbox.reducer';
import { socket } from 'socket';
import CRMTracker from 'utils/CRMTracker';

const MESSAGE_RECEIVED = 'ROOM/MESSAGE_RECEIVED';

export const actionTypes = {
  MESSAGE_RECEIVED,
};

export const newMessageReceived = (message) => {
  return (dispatch, getState) => {
    const state = getState();
    const { roomId, messageId } = message;
    const profile = state.session.profile;

    CRMTracker.event('MessageReceived', {
      id_speciality: profile.speciality_id,
      hash_professional: profile.hash,
    });

    const isOwnMessage = profile.hash === message.fromUserHash;

    // NOTE: Can't use this as `state.console.current` is set even if the user is not in a room,
    // causing messages to be marked as read. Using the pathname instead.
    // const isMessageFromCurrentRoom =
    //   parseInt(state.console?.current?.room_id) === parseInt(roomId);
    const isMessageFromCurrentRoom =
      window.location.pathname.includes(`/chat/${roomId}`) && !isOwnMessage;

    // For appointments we have the proper value for `state.console.current`,
    const isMessageFromAppointmentVC =
      window.location.pathname.includes(`appointment-meet/`) &&
      !isOwnMessage &&
      state.console.current?.room_id === roomId;

    if (isMessageFromCurrentRoom || isMessageFromAppointmentVC) {
      socket.emit('messageStatus', messageId, roomId, 3);
    }

    let isMessageFromInboxRoom = state.inbox.rooms.some(
      (room) => parseInt(room.room_id) === parseInt(roomId)
    );

    if (!isMessageFromInboxRoom) {
      const mode = state.inbox.mode;
      const add =
        mode === InboxMode.open ||
        mode === InboxMode.unread ||
        mode === InboxMode.untracked;

      if (add) {
        dispatch(getRoom({ roomId }));
      }

      dispatch(getSummaryRoomsList());
      return;
    }

    dispatch(getSummaryRoomsList());
    dispatch(messageReceived(message));
  };
};

export const newMessageNotification = ({ room_id }) => {
  return (dispatch, getState) => {
    const state = getState();
    const isNewMessageFromExistingRoom = state.inbox.rooms.filter(
      (room) => room.room_id === room_id
    );

    if (!isNewMessageFromExistingRoom.length) {
      dispatch(getRoomsList());
      dispatch(getSummaryRoomsList());
    }
  };
};

export const messageReceived = (message) => ({
  type: MESSAGE_RECEIVED,
  payload: message,
});
