const StepBallons = ({ props }: any) => (
  <svg
    width="113"
    height="140"
    viewBox="0 0 113 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.7346 17.1651C22.8126 16.9725 23.0465 16.934 23.1635 17.1266L23.7874 18.0894L24.6843 17.3577C24.8403 17.2421 25.0743 17.3577 25.0743 17.5502V18.7056L26.2051 18.5901C26.4001 18.5516 26.556 18.7827 26.4391 18.9367L25.8151 19.8996L26.868 20.4002C27.063 20.4773 27.024 20.7469 26.868 20.8239L25.8151 21.286L26.4001 22.2489C26.517 22.4029 26.3611 22.634 26.1661 22.5955L25.0353 22.4414L24.9963 23.5583C24.9963 23.7509 24.7623 23.8664 24.6063 23.7124L23.9434 23.1347C23.8264 23.0577 23.6705 23.0577 23.5925 23.1732L23.0855 23.9049C22.9686 24.059 22.7346 24.0205 22.6566 23.8279L22.3446 22.7495L21.2918 23.2117C21.0968 23.2887 20.9019 23.1347 20.9798 22.9421L21.3308 21.8637L20.2 21.6712C20.005 21.6327 19.927 21.4016 20.083 21.286L20.9408 20.5543L20.083 19.784C19.927 19.6685 20.005 19.3989 20.2 19.3989L21.3308 19.2448L21.0188 18.1279C20.9798 17.9354 21.1748 17.7813 21.3308 17.8584L22.3836 18.3205L22.7346 17.1651Z"
      fill="#6321A8"
    />
    <circle cx="35" cy="36" r="18" fill="#201552" />
    <path
      d="M33.3495 31.424L30.5175 32.072L29.6295 28.472L34.5495 27.08H37.9335V44H33.3495V31.424Z"
      fill="white"
    />
    <path
      d="M31.5 28L34.5496 27.08H37.9336V41.5"
      stroke="#F5C4DD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="79" cy="57" r="22" fill="#D7BCF1" />
    <path
      d="M70.6296 62.62L78.4896 56.62C79.4696 55.86 80.1596 55.2 80.5596 54.64C80.9796 54.08 81.1896 53.48 81.1896 52.84C81.1896 52.18 80.9696 51.66 80.5296 51.28C80.1096 50.9 79.5496 50.71 78.8496 50.71C78.1496 50.71 77.4796 50.93 76.8396 51.37C76.2196 51.79 75.4996 52.47 74.6796 53.41L70.7496 50.14C71.3096 49.42 71.8796 48.78 72.4596 48.22C73.0396 47.66 73.6596 47.19 74.3196 46.81C74.9996 46.43 75.7396 46.14 76.5396 45.94C77.3596 45.74 78.2796 45.64 79.2996 45.64C80.4396 45.64 81.4796 45.81 82.4196 46.15C83.3796 46.47 84.1996 46.92 84.8796 47.5C85.5596 48.08 86.0897 48.79 86.4696 49.63C86.8496 50.45 87.0396 51.35 87.0396 52.33V52.39C87.0396 53.23 86.9296 53.98 86.7096 54.64C86.5096 55.3 86.1996 55.92 85.7796 56.5C85.3596 57.06 84.8496 57.6 84.2496 58.12C83.6496 58.64 82.9596 59.18 82.1796 59.74L78.5796 62.26H87.2496V67H70.6296V62.62Z"
      fill="white"
    />
    <circle cx="49" cy="95" r="30" fill="#6321A8" />
    <path
      d="M49.1964 107.504C46.7484 107.504 44.6484 107.108 42.8964 106.316C41.1684 105.5 39.6924 104.408 38.4684 103.04L42.8604 98.648C43.7724 99.56 44.7084 100.268 45.6684 100.772C46.6524 101.276 47.7204 101.528 48.8724 101.528C49.8804 101.528 50.6724 101.3 51.2484 100.844C51.8244 100.364 52.1124 99.74 52.1124 98.972V98.9C52.1124 98.084 51.7524 97.448 51.0324 96.992C50.3364 96.536 49.3284 96.308 48.0084 96.308H45.0564L44.0484 92.672L49.8444 87.452H40.1964V81.8H58.4124V86.804L52.4004 91.952C53.2884 92.144 54.1284 92.42 54.9204 92.78C55.7124 93.14 56.4084 93.608 57.0084 94.184C57.6084 94.76 58.0764 95.444 58.4124 96.236C58.7484 97.028 58.9164 97.964 58.9164 99.044V99.116C58.9164 100.388 58.6644 101.54 58.1604 102.572C57.6804 103.604 57.0084 104.492 56.1444 105.236C55.2804 105.956 54.2484 106.52 53.0484 106.928C51.8724 107.312 50.5884 107.504 49.1964 107.504Z"
      fill="white"
    />
    <path
      d="M76.5396 45.94C77.3596 45.74 78.2796 45.64 79.2996 45.64C80.4396 45.64 81.4796 45.81 82.4196 46.15C83.3796 46.47 84.1996 46.92 84.8796 47.5C85.5596 48.08 86.0896 48.79 86.4696 49.63C86.8496 50.45 87.0396 51.35 87.0396 52.33V52.39C87.0396 53.23 86.9296 53.98 86.7096 54.64C86.5096 55.3 86.1996 55.92 85.7796 56.5C85.3596 57.06 84.8496 57.6 84.2496 58.12C83.6496 58.64 82.9596 59.18 82.1796 59.74L78.5796 62.26H87.2496"
      stroke="#3C50EC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M80.8609 12.2554L79.4458 16.0018C79.3784 16.1332 79.311 16.1989 79.1762 16.2647L76.2785 17.0534C75.9416 17.1848 75.8742 17.6449 76.2785 17.7763L79.4458 18.9594C79.5805 19.0251 79.6479 19.0909 79.6479 19.1566L80.9957 22.8372C81.1305 23.1659 81.6696 23.1659 81.737 22.7715L82.613 18.8937C82.613 18.7622 82.7478 18.6308 82.8826 18.6308L85.8476 17.6449C86.1845 17.5134 86.1845 16.9876 85.8476 16.9219L83.0173 16.1332C82.8825 16.0675 82.8152 16.0018 82.7478 15.8703L81.5348 12.1896C81.5348 11.9267 80.9957 11.9267 80.8609 12.2554Z"
      fill="#FFB801"
    />
    <path
      d="M100.006 28.7139C101.428 28.7139 102.581 27.5699 102.581 26.1588C102.581 24.7477 101.428 23.6038 100.006 23.6038C98.5839 23.6038 97.4309 24.7477 97.4309 26.1588C97.4309 27.5699 98.5839 28.7139 100.006 28.7139Z"
      fill="#3C50EC"
    />
    <path
      d="M15.0084 127.97C16.6699 127.97 18.0168 126.633 18.0168 124.985C18.0168 123.336 16.6699 122 15.0084 122C13.3469 122 12 123.336 12 124.985C12 126.633 13.3469 127.97 15.0084 127.97Z"
      fill="#FFB801"
    />
    <path
      d="M30.6911 115.118L24.1506 111L20 117.489L26.5405 121.607L30.6911 115.118Z"
      fill="#3C50EC"
    />
    <path
      d="M53.0485 106.928C54.2485 106.52 55.2805 105.956 56.1445 105.236C57.0085 104.492 57.6805 103.604 58.1605 102.572C58.6645 101.54 58.9165 100.388 58.9165 99.1161V99.044C58.9165 97.964 58.7485 97.028 58.4125 96.236C58.0765 95.444 57.6085 94.7601 57.0085 94.1841C56.4085 93.6081 55.7125 93.14 54.9205 92.78C54.1285 92.42 53.2885 92.144 52.4005 91.952L58.4125 86.804V81.8H44.5"
      stroke="#201552"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default StepBallons;
