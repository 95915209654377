import { useEffect, useState } from 'react';
import { ContentTitle } from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/DiagnosticTestPrescriptionsPanel';
import Caret from 'components/icons/outline/Caret';
import Spinner from 'components/Spinner';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import api from 'api';
import Trash from 'containers/Appointments/icons/Trash';
import { toast } from 'react-toastify';
import Check from 'components/icons/outline/Check';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import { setActiveTemplate } from 'state/diagnosticTests/index.slice';
import { useDispatch } from 'react-redux';
import useDiagnosticTest from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/useDiagnosticTest';

type TemplateConainerProps = {
  setCurrentStatus: (status: string) => void;
  isTemplates?: boolean;
};

type TemplateProps = {
  template: any;
  setTemplates: (templates: any) => void;
  templates: any[];
  setCurrentStatus: (status: string) => void;
};

const TemplateContainer = ({
  setCurrentStatus,
  isTemplates = false,
}: TemplateConainerProps) => {
  const [templates, setTemplates] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getTemplates();
  }, [templates?.length]);

  const getTemplates = () => {
    api.diagnosticTestPrescriptions.getTemplates().then((response) => {
      setTemplates(response.data.data);
    });
  };

  return (
    <div className="h-full flex flex-row w-full h-[78vh] max-h-[78vh">
      <div className="w-full px-8 pt-10">
        <div className="flex flex-row items-center gap-4">
          {!isTemplates && (
            <button onClick={() => setCurrentStatus('search')}>
              <Caret className="rotate-90 w-4 mb-6 text-gray-dark" />
            </button>
          )}
          <ContentTitle>
            {t('prescription_modal__templates_title')}
          </ContentTitle>
        </div>
        <div className="h-full overflow-y-auto grid grid-cols-3 xl:grid-cols-4 justify-center text-gray-medium">
          {templates?.length
            ? templates.map((template, index) => (
                <Template
                  template={template}
                  key={index}
                  setTemplates={setTemplates}
                  templates={templates}
                  setCurrentStatus={setCurrentStatus}
                />
              ))
            : t('prescription_modal__templates_no_templates')}
        </div>
      </div>
    </div>
  );
};

const Template = ({
  template,
  setTemplates,
  templates,
  setCurrentStatus,
}: TemplateProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const [petitions, setPetitions] = useState<string[]>([]);
  const dispatch = useDispatch();
  const { handleAddTest } = useDiagnosticTest();

  useEffect(() => {
    const filterPetitions: string[] = [];
    template.items.forEach((item: any) => {
      if (!filterPetitions.includes(item.meta.component_name)) {
        filterPetitions.push(item.meta.component_name);
      }
    });
    setPetitions(filterPetitions);
  }, [template]);

  const handleDeleteTemplate = () => {
    setIsDeleting(true);
    api.diagnosticTestPrescriptions
      .deleteTemplate(template.id)
      .then(() => {
        toast.success(
          <div className="flex items-center gap-3 text-positive-dark">
            <Check className="h-6 w-6" />
            {t('prescription_modal__template_deleted_toast')}
          </div>
        );
        setIsDeleting(false);
        setTemplates(templates.filter((item: any) => item.id !== template.id));
      })
      .catch(() =>
        toast.error(
          <ErrorToast>{t('shared__errors_generic_message')}</ErrorToast>
        )
      );
  };

  const handleOpenTemplate = () => {
    setIsLoading(true);
    template.items.forEach((item: any) => {
      handleAddTest(item);
    });
    dispatch(
      setActiveTemplate({
        id: template.id,
        title: template.title,
        template_items: template.items,
      })
    );
    setTimeout(() => {
      setCurrentStatus('review');
      setIsLoading(false);
    }, 500);
  };
  return (
    <div className="flex flex-col items-center justify-between border-b border-separators py-4 px-2">
      <div
        className="flex flex-col w-full items-center justify-between px-5 py-6 rounded-lg hover:bg-background min-h-[320px]"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className="pb-4 w-full border-b border-separators">
          <div className="relative flex flex-row items-center pt-2 pb-2 gap-4 justify-between w-full">
            <div className="text-xs tracking-wide font-medium uppercase text-positive-dark">
              {t('prescription_modal__templates_template_title')}
            </div>
            <button
              className={classNames(
                'absolute flex right-0 items-center h-7 w-7 shrink-0 m-auto text-gray-dark p-1 rounded-lg bg-white border-separators border',
                isHovering ? 'block' : 'hidden'
              )}
              onClick={handleDeleteTemplate}
            >
              {isDeleting ? (
                <Spinner className="h-3 w-3 m-auto" />
              ) : (
                <Trash className="h-3 w-3 m-auto" />
              )}
            </button>
          </div>

          <div className="flex w-full text-dark font-medium tracking-wide pt-1 capitalize">
            {template.title}
          </div>
          <div className="flex w-full justify-start text-xs">
            {t(
              petitions.length === 1
                ? 'prescription_modal__templates_petitions_unique'
                : 'prescription_modal__templates_petitions_count',
              {
                count: petitions.length,
              }
            )}
          </div>
        </div>
        <ul className="w-full h-full justify-start items-start pt-4 pb-6">
          {petitions.map((item: any, index: number) => (
            <li key={item + index} className="text-xs text-gray-dark my-1">
              <span className="mx-2">•</span>
              {item}
            </li>
          ))}
        </ul>
        <button
          className="flex items-center bg-secundary text-primary uppercase text-sm gap-2 justify-center py-2 font-medium tracking-wider rounded-lg w-full mb-4"
          onClick={handleOpenTemplate}
        >
          {isLoading ? (
            <Spinner className="h-3 w-3 m-auto" />
          ) : (
            t('prescription_modal__templates_template_button')
          )}
        </button>
      </div>
    </div>
  );
};

export default TemplateContainer;
