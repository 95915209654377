const SquareGrid = (props: any) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.19173 7.33333C3.82507 7.33333 3.51107 7.20267 3.24973 6.94133C2.9884 6.68 2.85796 6.36622 2.8584 6V3.33333C2.8584 2.96667 2.98907 2.65267 3.2504 2.39133C3.51173 2.13 3.82551 1.99956 4.19173 2H6.8584C7.22507 2 7.53907 2.13067 7.8004 2.392C8.06173 2.65333 8.19218 2.96711 8.19173 3.33333V6C8.19173 6.36667 8.06107 6.68067 7.79973 6.942C7.5384 7.20333 7.22462 7.33378 6.8584 7.33333H4.19173ZM4.19173 14C3.82507 14 3.51107 13.8693 3.24973 13.608C2.9884 13.3467 2.85796 13.0329 2.8584 12.6667V10C2.8584 9.63333 2.98907 9.31933 3.2504 9.058C3.51173 8.79667 3.82551 8.66622 4.19173 8.66667H6.8584C7.22507 8.66667 7.53907 8.79734 7.8004 9.05867C8.06173 9.32 8.19218 9.63378 8.19173 10V12.6667C8.19173 13.0333 8.06107 13.3473 7.79973 13.6087C7.5384 13.87 7.22462 14.0004 6.8584 14H4.19173ZM10.8584 7.33333C10.4917 7.33333 10.1777 7.20267 9.9164 6.94133C9.65507 6.68 9.52462 6.36622 9.52507 6V3.33333C9.52507 2.96667 9.65573 2.65267 9.91707 2.39133C10.1784 2.13 10.4922 1.99956 10.8584 2H13.5251C13.8917 2 14.2057 2.13067 14.4671 2.392C14.7284 2.65333 14.8588 2.96711 14.8584 3.33333V6C14.8584 6.36667 14.7277 6.68067 14.4664 6.942C14.2051 7.20333 13.8913 7.33378 13.5251 7.33333H10.8584ZM10.8584 14C10.4917 14 10.1777 13.8693 9.9164 13.608C9.65507 13.3467 9.52462 13.0329 9.52507 12.6667V10C9.52507 9.63333 9.65573 9.31933 9.91707 9.058C10.1784 8.79667 10.4922 8.66622 10.8584 8.66667H13.5251C13.8917 8.66667 14.2057 8.79734 14.4671 9.05867C14.7284 9.32 14.8588 9.63378 14.8584 10V12.6667C14.8584 13.0333 14.7277 13.3473 14.4664 13.6087C14.2051 13.87 13.8913 14.0004 13.5251 14H10.8584Z"
      fill="currentColor"
    />
  </svg>
);

export default SquareGrid;
