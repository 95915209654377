import { actionTypes as prescriptionActions } from 'containers/Prescription/actions/Prescription.actions';
import { createSelector } from 'reselect';

const initialState = {
  prescriptions: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case prescriptionActions.PRESCRIPTIONS_FETCHED:
      return {
        ...state,
        prescriptions: action.payload,
      };
    case prescriptionActions.PRESCRIPTION_CREATED:
      return {
        ...state,
        prescriptions: [...state.prescriptions, action.payload],
      };
    default:
      return state;
  }
};

export const sortedPrescriptions = createSelector(
  (state) => state.prescription.prescriptions,
  (prescriptions) => [...prescriptions]?.sort(byLastPrescribed)
);

const byLastPrescribed = (prescriptionA, prescriptionB) =>
  prescriptionA.prescribed_at < prescriptionB.prescribed_at ? 1 : -1;

export default reducer;
