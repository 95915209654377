import { useTranslation } from 'react-i18next';
import useDiagnosticTest from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/useDiagnosticTest';
import Button from 'components/Button';
import {
  ContentTitle,
  DiagnosticTestMenuCategory,
  SidebarTitle,
} from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/DiagnosticTestPrescriptionsPanel';
import {
  TabNavigation,
  TabNavigationMenu,
  TabNavigationItem,
} from 'components/TabNavigation';
import BottomDrawer from 'components/Drawer/BottomDrawer';
import classnames from 'classnames';
import DiagnosticTestBody from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/DiagnosticTestBody';
import { useEffect, useState } from 'react';
import Caret from 'components/icons/outline/Caret';
import { usePortal } from 'containers/Shared/components/Portal';
import Listing from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/Listing';
import classNames from 'classnames';

const AddPetitionDrawer = ({
  onClose,
  setDraftPetitions,
  setActivePetition,
  draftPetitions,
}) => {
  const {
    selected_items,
    categories,
    fetchCategories,
    setRootCategory,
    setActiveNavId,
    activeRootCategoryId,
    activeRootCategoryName,
    handleSetSelectedItems,
    handleSetIsNewPetition,
    activeNavId,
    items,
    petitions,
  } = useDiagnosticTest();
  const { t } = useTranslation();
  const [previousSelectedItems, setPreviousSelectedItems] = useState([]);
  const [newPetitions, setNewPetitions] = useState([]);
  const [showListingDrawer, setShowListingDrawer] = useState(false);
  const portal = usePortal();
  const [newSelectedItems, setNewSelectedItems] = useState([]);

  useEffect(() => {
    if (selected_items.length === previousSelectedItems.length) {
      setNewSelectedItems([]);
      setNewPetitions([]);
    } else {
      const newItems = selected_items.filter(
        (item) => !previousSelectedItems.includes(item)
      );
      let uniquePetitions = [];
      newItems.forEach((item) => {
        if (!uniquePetitions.includes(item.meta.component_name)) {
          uniquePetitions.push(item.meta.component_name);
        }
      });
      setNewPetitions(uniquePetitions);
      setNewSelectedItems(
        selected_items.filter((item) => !previousSelectedItems.includes(item))
      );
    }
  }, [selected_items]);

  useEffect(() => {
    fetchCategories();
    setPreviousSelectedItems(selected_items);
    handleSetIsNewPetition(true);
    setNewPetitions([]);
  }, []);

  const handleOnCancel = () => {
    handleSetSelectedItems(previousSelectedItems);
    handleSetIsNewPetition(false);
    onClose();
  };

  const handleDraftPetitions = () => {
    const newPetitions = [];
    petitions.forEach((petition) => {
      const newPetition = {
        name: petition,
        observations: '',
        consultation_id: '',
        consultation_type: '',
      };
      if (
        !draftPetitions.some((draftPetition) => draftPetition.name === petition)
      )
        newPetitions.push(newPetition);
    });
    setDraftPetitions([...draftPetitions, ...newPetitions]);
    setActivePetition(draftPetitions[0]);
  };

  const handleOnSelectRootCategory = (rootId, name) => {
    setRootCategory(rootId, name);
  };

  const showListing = () => {
    if (newPetitions.length) {
      setShowListingDrawer(true);
      portal.open(
        <Listing
          onClose={() => {
            setShowListingDrawer(false);
            portal.close('show_listing_drawer');
          }}
          newSelectedItems={newSelectedItems}
          newPetitions={newPetitions}
        />,
        'show_listing_drawer'
      );
    }
  };

  return (
    <BottomDrawer onClose={handleOnCancel} isTransparent>
      <div className={classnames('flex w-full max-h-screen')}>
        <div className={classnames('flex w-full')}>
          {categories && (
            <div className="flex flex-col w-full bg-white rounded-t-xl justify-between">
              <div className="flex flex-row w-full h-[78vh] max-h-[78vh]">
                <div className="w-250px min-w-250px lg:w-450px lg:min-w-450px h-full">
                  <SidebarTitle>
                    {t('prescription_modal__add_petition_modal_title')}
                  </SidebarTitle>
                  {categories.map(({ root }, index) => {
                    return (
                      <DiagnosticTestMenuCategory
                        key={index}
                        isActive={activeRootCategoryId === root.id}
                        category={root}
                        onClick={() =>
                          handleOnSelectRootCategory(root.id, root.value.name)
                        }
                      />
                    );
                  })}
                </div>
                <div className="flex flex-col w-full px-8 pt-10 h-full w-full max-w-full overflow-x-hidden">
                  <div className="flex flex-row justify-between">
                    <ContentTitle>{activeRootCategoryName}</ContentTitle>
                  </div>
                  <TabNavigationMenu>
                    {categories.map(({ root }) => {
                      return root.children.map((children, index) => {
                        if (children.parent.id === activeRootCategoryId) {
                          return (
                            <TabNavigationItem
                              key={index}
                              name={children.value.name}
                              setActiveTab={setActiveNavId}
                              isActive={activeNavId === children.id}
                              id={children.id}
                            />
                          );
                        }
                      });
                    })}
                  </TabNavigationMenu>
                  <TabNavigation className="overflow-y-auto">
                    <DiagnosticTestBody
                      categories={categories}
                      activeNavId={activeNavId}
                      activeRootCategoryId={activeRootCategoryId}
                      items={items}
                      previousSelectedItems={previousSelectedItems}
                    />
                  </TabNavigation>
                </div>
              </div>

              <div className="flex flex-row bottom-0 justify-between items-center px-6 h-20 max-h-20 border-t border-blue-light shrink-0">
                <div className="text-gray-dark flex flex-row items-center text-sm">
                  {t('prescription_modal__search_petitions_selected_count', {
                    count: newPetitions.length,
                  })}
                  <button
                    className="ml-5 flex flex-row items-center"
                    onClick={showListing}
                  >
                    <span className="mr-2 text-sm">
                      {showListingDrawer
                        ? t('prescription_modal__search_petitions_hide_listing')
                        : t(
                            'prescription_modal__search_petitions_show_listing'
                          )}
                    </span>
                    <Caret
                      className={classNames('h-2 w-2.5', {
                        'rotate-180': !showListingDrawer,
                      })}
                    />
                  </button>
                </div>
                <div className="flex flex-row gap-2">
                  <Button onClick={handleOnCancel} variant="secondary">
                    {t('prescription_modal__add_petition_modal_cancel_button')}
                  </Button>
                  <Button
                    onClick={() => {
                      handleSetIsNewPetition(false);
                      handleDraftPetitions();
                      onClose();
                    }}
                  >
                    {t('prescription_modal__add_petition_modal_add_button')}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </BottomDrawer>
  );
};

export default AddPetitionDrawer;
