import React from 'react';

const useBlockModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const show = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return { isOpen, show, close };
};

export default useBlockModal;
