import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

export const BASE_URL = config.services.mediquo.chat_url;

export const update = ({
  contact_id,
  name,
  external_id,
  email,
}: {
  contact_id: string;
  name: string;
  external_id: string;
  email: string;
}) =>
  axios({
    method: 'put',
    url: `${BASE_URL}/professionals/v1/contacts/${contact_id}`,
    data: {
      name,
      external_id,
      email,
    },
    headers: getHeaders(),
  });

export default {
  update,
};
