import { useDispatch } from 'react-redux';
import { smallLayout } from 'state/layout.slice';
import useListener from 'utils/useListener';

const useResizeLayout = () => {
  const dispatch = useDispatch();

  useListener({
    listener: 'resize',
    onEvent: () => window.innerWidth < 1024 && dispatch(smallLayout()),
  });
};

export default useResizeLayout;
