import Button from 'components/Button';
import Modal from 'components/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const NoteModal = ({ onClose, onSubmit }) => {
  const [message, setMessage] = useState('');
  const { t } = useTranslation();

  const submit = () => onSubmit(message);

  return (
    <Modal width="medium" onClose={onClose}>
      <Modal.Header onClose={onClose}>
        {t('sidebar__patient_add_note_title')}
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <label className="pb-2 font-medium">
            {t('sidebar__patient_add_note_text')}
          </label>
          <textarea
            autoFocus
            placeholder={t('sidebar__patient_add_note_text_placeholder')}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            className="w-full p-2 border rounded-md border-separators"
            rows="5"
          ></textarea>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={!message} onClick={submit}>
          {t('sidebar__patient_add_note_accept')}
        </Button>
        <Button variant="reverse" onClick={onClose}>
          {t('sidebar__patient_add_note_cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NoteModal;
