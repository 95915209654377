import IconBoxArrowDown from 'containers/Inbox/icons/IconBoxArrowDown';
import { Trans } from 'react-i18next';

const ArchiveRoom = ({ rooms }) => (
  <div className="flex items-center justify-center">
    <IconBoxArrowDown />
    <div className="ml-3">
      <Trans i18nKey="toast__archive_text" count={rooms.length}>
        Has archivado
        <span className="font-medium lowercase">
          {` ${rooms.length} consultas.`}
        </span>
      </Trans>
    </div>
  </div>
);

export default ArchiveRoom;
