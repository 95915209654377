import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialized: false,
  status: 'idle',
  startsAt: null,
  current: {},
  isMuted: false,
  hasMediaPermissions: true,
  isAppointment: false,
} as {
  initialized: boolean;
  status: PhonecallStatus;
  current: any | null;
  startsAt: string | null;
  isMuted: boolean;
  hasMediaPermissions: boolean;
  isAppointment: boolean;
};

const phoneCallSlice = createSlice({
  name: 'phoneCall',
  initialState,
  reducers: {
    phoneCallClear(state) {
      return (state = {
        ...state,
        current: {},
        status: 'idle',
        initialized: false,
        isMuted: false,
        isAppointment: false,
      });
    },
    phoneCallCalling(state, action) {
      return (state = {
        ...state,
        initialized: true,
        status: 'calling',
        current: action.payload.phoneCallData,
        isAppointment: action.payload.isAppointment || false,
      });
    },
    setIsMute(state, action) {
      state.isMuted = action.payload.isMuted;
    },
    phoneCallConnecting(state) {
      state.initialized = true;
      state.status = 'connecting';
    },
    phoneCallConnected(state) {
      state.status = 'connected';
      state.startsAt = new Date().toISOString();
    },
    phoneCallDeclined(state) {
      state.status = 'declined';
    },
    phoneCallError(state) {
      state.status = 'error';
    },
    phoneCallCancelled(state) {
      state.status = 'cancelled';
    },
    phoneCallFinished(state) {
      state.status = 'finished';
    },
    updateMediaPermissions(state, action) {
      state.hasMediaPermissions = action.payload.hasMediaPermissions;
    },
  },
});

export const isDetailed = (phoneCall?: any) =>
  !!phoneCall?.to && !!phoneCall?.from;

export const PHONECALL_STATUS = [
  'idle',
  'calling',
  'connecting',
  'connected',
  'busy',
  'declined',
  'error',
  'cancelled',
  'finished',
] as const;

export type PhonecallStatus = typeof PHONECALL_STATUS[number];

export const {
  phoneCallCancelled,
  phoneCallDeclined,
  phoneCallFinished,
  phoneCallClear,
  phoneCallCalling,
  phoneCallConnecting,
  phoneCallConnected,
  phoneCallError,
  setIsMute,
  updateMediaPermissions,
} = phoneCallSlice.actions;
export default phoneCallSlice.reducer;
