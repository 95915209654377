import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Button from 'components/Button';
import ThreeStars from 'components/icons/outline/ThreeStars';

export type DraftItemProps = JSX.IntrinsicElements['div'] & {
  onEdit?: () => void;
  status?: string;
};

const AutomaticItem = ({
  onEdit,
  status = 'finished',
  className,
  ...props
}: DraftItemProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classnames('bg-gray-lighter p-4 rounded-xl', className)}
      {...props}
    >
      <div className="flex justify-between items-center gap-2">
        <div className="overflow-hidden">
          <h3 className="text-primary text-sm font-medium">
            {t(
              `sidebar__patient_reports_automatic_${
                ['loading'].includes(status) ? status : 'finished'
              }_title`
            )}
          </h3>
          <p className="text-[#6D658B] text-xs">
            {t(
              `sidebar__patient_reports_automatic_${
                ['loading'].includes(status) ? status : 'finished'
              }_text`
            )}
          </p>
        </div>
        <Button
          variant="custom"
          size="custom"
          className={classnames('h-10 w-10 shrink-0 rounded-xl ', {
            'bg-negative': status === 'loading',
            'bg-primary': status === 'finished',
          })}
          onClick={onEdit}
        >
          {
            {
              loading: (
                <div className="rounded border-2 border-white w-3.5 h-3.5" />
              ),
              finished: <ThreeStars className="h-4 w-4 text-white" />,
              error: <ThreeStars className="h-4 w-4 text-white" />,
            }[status!]
          }
        </Button>
      </div>
    </div>
  );
};

export default AutomaticItem;
