import ThreeStars from 'components/icons/outline/ThreeStars';

export interface Props {
  type: string;
  title: string;
  actions: JSX.Element;
  isAutomatic: boolean;
}

const Document = ({ type, title, actions, isAutomatic }: Props) => {
  return (
    <div className="flex justify-between items-center px-4 py-2 bg-[#353535] hover:bg-gray-darker group transition-colors rounded-md text-sm truncate text-[#989898] hover:text-white">
      <div className="inline-flex items-center">
        {isAutomatic && <ThreeStars className="w-4 h-4 mr-1 self-center" />}
        <span className="font-medium text-sm self-center">{type}</span>
        <div className="ml-2 truncate max-w-[230px]">{title}</div>
      </div>
      {actions}
    </div>
  );
};

export default Document;
