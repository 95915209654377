import { memo } from 'react';

import SingleCheck from 'containers/Inbox/icons/check.svg';
import DoubleCheck from 'containers/Inbox/icons/double_check.svg';
import DoubleCheckRead from 'containers/Inbox/icons/double_check_read.svg';

const MessageStatus = ({ status }) => {
  return (
    <div>
      {status === 1 && <img src={SingleCheck} />}
      {status === 2 && <img src={DoubleCheck} />}
      {status === 3 && <img className="text-blue" src={DoubleCheckRead} />}
    </div>
  );
};

export default memo(MessageStatus);
