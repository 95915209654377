import React from 'react';

import Trash from 'components/icons/Trash';

const NoteItem = ({ note, onDelete }) => (
  <div className="flex p-4 w-full">
    <img src={note.professional.avatar} className="w-8 h-8 rounded-full mr-2" />
    <div className="mr-2">
      <div className="font-medium text-md">{note.professional.name}</div>
      <div className="text-xs text-gray-medium leading-4">{note.message}</div>
    </div>
    <button
      onClick={onDelete}
      className="w-4 h-4 cursor-pointer focus:outline-none flex items-center justify-center ml-auto"
    >
      <Trash className="text-gray-medium" />
    </button>
  </div>
);

export default NoteItem;
