import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';
import { EditTrackingMessageRQ } from 'api/trackings/index.d';

export const BASE_URL = config.services.mediquo.chat_url;
export const PROFESSIONALS = 'professionals/v1';

const attachments = {
  store: (signedUrl: any, attachment: any) =>
    axios.put(signedUrl, attachment, {
      headers: {
        'Content-Type': attachment.type,
      },
    }),
};

const storage = {
  get: () =>
    axios({
      method: 'get',
      url: `${BASE_URL}/${PROFESSIONALS}/tracking-messages/storage`,
      headers: getHeaders(),
    }),
};

const edit = ({
  id,
  message,
  to_send_at,
  attached_file_url,
}: EditTrackingMessageRQ) =>
  axios({
    method: 'put',
    url: `${BASE_URL}/${PROFESSIONALS}/tracking-messages/${id}`,
    data: {
      message,
      to_send_at,
      attached_file_url,
    },
    headers: getHeaders(),
  });

export default {
  attachments,
  storage,
  edit,
};
