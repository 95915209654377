import Confirmation from 'components/Modal/Confirmation';
import { usePortal } from 'containers/Shared/components/Portal';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  content: string;
}

const useConfirmDelete = () => {
  const portal = usePortal();
  const { t } = useTranslation();

  const confirm = ({ title, content }: Props) =>
    new Promise((resolve, reject) =>
      portal.open(
        <Confirmation
          title={title}
          content={content}
          cancelText={t('shared__modal_confirmation_cancel')}
          confirmText={t('shared__modal_confirmation_confirm_delete')}
          onCancel={() => {
            portal.close('confirmation');
            reject(false);
          }}
          onConfirm={() => {
            portal.close('confirmation');
            resolve(true);
          }}
        />,
        'confirmation'
      )
    );

  return confirm;
};

export default useConfirmDelete;
