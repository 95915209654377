import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  selected_items: [],
  petitions: [],
  isNewPetition: false,
  prescriptions: [],
  activeTemplate: null,
};

const DiagnosticTestsSlice = createSlice({
  name: 'diagnosticTests',
  initialState,
  reducers: {
    fetchTests(state, action) {
      state.selected_items = action.payload;
    },
    addTest(state, action) {
      if (!state.selected_items.find(({ id }) => id === action.payload.id)) {
        state.selected_items.push(action.payload);
        !state.petitions.includes(action.payload.meta.component_name) &&
          state.petitions.push(action.payload.meta.component_name);
      }
    },
    removeTest(state, action) {
      state.selected_items = state.selected_items.filter(
        ({ id }) => id !== action.payload.id
      );
      const newArray = [];
      state.selected_items.forEach((item) => {
        if (!newArray.includes(item.meta.component_name)) {
          newArray.push(item.meta.component_name);
        }
      });
      state.petitions = newArray;
    },
    setTests(state, action) {
      state.selected_items = action.payload;
    },
    removePetition(state, action) {
      state.petitions = state.petitions.filter(
        (petition) => petition !== action.payload.name
      );
      state.selected_items = state.selected_items.filter(
        ({ meta }) => meta.component_name !== action.payload.name
      );
    },
    clearTests(state) {
      state.selected_items = [];
      state.petitions = [];
      state.activeTemplate = null;
    },
    setIsNewPetition(state, action) {
      state.isNewPetition = action.payload;
    },
    isLoadingPrescriptions(state) {
      state.isLoading = true;
    },
    prescriptionsFetched(state, action) {
      state.prescriptions = action.payload;
      state.isLoading = false;
    },
    setActiveTemplate(state, action) {
      state.activeTemplate = action.payload;
    },
  },
});

export const {
  fetchTests,
  addTest,
  updateTest,
  removeTest,
  clearTests,
  setTests,
  isLoadingPrescriptions,
  removePetition,
  setIsNewPetition,
  prescriptionsFetched,
  setActiveTemplate,
} = DiagnosticTestsSlice.actions;
export default DiagnosticTestsSlice.reducer;
