import DraftItem from 'containers/DetailBar/PatientBar/components/PatientProfile/DraftItem';
import AlertIcon from 'containers/Login/icons/AlertIcon';
import useMedicalReport from 'containers/MedicalReport/useMedicalReport';
import { Report } from 'api/reports.d';
import { useTranslation } from 'react-i18next';

export const getBriefing = (draft: Report) => {
  return [
    draft.subjective_data,
    draft.objective_data,
    draft.diagnostic?.text,
    draft.plan,
  ].find((field) => !!field && field.length > 0);
};

type DraftReportProps = {
  report: Report;
};

const DraftReport = ({ report }: DraftReportProps) => {
  const { handleChangeScreen } = useMedicalReport();
  const briefing = getBriefing(report);
  const { t } = useTranslation();

  return (
    <>
      <div className="inline-flex items-center gap-1">
        <AlertIcon className="w-4 h-4 rounded-full text-[#6D658B]" />
        <span className="text-xs text-[#6D658B]">
          {t('reports__draft_report__alert_tooltip')}
        </span>
      </div>
      <DraftItem
        briefing={briefing}
        onEdit={() => handleChangeScreen('report')}
      />
    </>
  );
};

export default DraftReport;
