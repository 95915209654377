import Api from 'api';
import { useState } from 'react';
import { Tree } from 'components/Tree/Tree';
import { useDispatch } from 'react-redux';
import {
  addTest,
  removeTest,
  clearTests,
  removePetition,
  setTests,
  setIsNewPetition,
  prescriptionsFetched,
  isLoadingPrescriptions,
  setActiveTemplate,
} from 'state/diagnosticTests/index.slice';
import { useSelector } from 'react-redux';
import { usePortal } from 'containers/Shared/components/Portal';
import { diagnosticTestsFetched } from 'containers/DetailBar/PatientBar/actions/MedicalHistory.actions';
import { toast } from 'react-toastify';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import { useTranslation } from 'react-i18next';
import Check from 'components/icons/outline/Check';

export type Category = {
  id: string;
  name: string;
  parent_id: string | null;
  component: string;
};

export type Item = {
  id: string;
  name: string;
};

const useDiagnosticTest = () => {
  const [activeRootCategoryId, setActiveRootCategoryId] = useState('');
  const [activeRootCategoryName, setActiveRootCategoryName] = useState('');
  const [activeNavId, setActiveNavId] = useState('');
  const [categories, setCategories] = useState<any[]>([]);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const dispatch = useDispatch();
  const { selected_items, petitions } = useSelector(
    (state: any) => state.diagnosticTests
  );
  const { current } = useSelector((state: any) => state.console);
  const portal = usePortal();
  const { t } = useTranslation();

  const fetchCategories = () => {
    Api.diagnosticTestPrescriptions
      .categories({ patient_hash: current?.customer?.hash })
      .then((data: any) => {
        const categoriesData = data.data.data;
        let tree: Tree[] = [];

        const rootCategories: Category[] = categoriesData.filter(function (
          category: Category
        ) {
          return category.component === 'menu';
        });
        const navCategories: Category[] = categoriesData.filter(function (
          category: Category
        ) {
          return category.component === 'nav';
        });
        const filterCategories: Category[] = categoriesData.filter(function (
          category: Category
        ) {
          return category.component === 'filter';
        });
        const accordionCategories: Category[] = categoriesData.filter(function (
          category: Category
        ) {
          return category.component === 'accordion';
        });
        setActiveRootCategoryId(rootCategories[0].id);
        setActiveRootCategoryName(rootCategories[0].name);
        const firstActiveNav = navCategories.find(
          (nav) => nav.parent_id === rootCategories[0].id
        )?.id;
        setActiveNavId(firstActiveNav!);
        fetchItems({ categoryId: rootCategories[0].id });
        rootCategories.forEach((rootCategory: Category) => {
          const rootTree = new Tree(rootCategory.id, rootCategory);
          navCategories.forEach((childrenCategory: Category) => {
            rootTree.insert(
              childrenCategory.parent_id,
              childrenCategory.id,
              childrenCategory
            );
          });
          filterCategories.forEach((childrenCategory: Category) => {
            rootTree.insert(
              childrenCategory.parent_id,
              childrenCategory.id,
              childrenCategory
            );
          });
          accordionCategories.forEach((childrenCategory: Category) => {
            rootTree.insert(
              childrenCategory.parent_id,
              childrenCategory.id,
              childrenCategory
            );
          });
          tree.push(rootTree);
        });

        setCategories(tree);
      });
  };

  const fetchItems = ({ categoryId }: { categoryId: string }) => {
    Api.diagnosticTestPrescriptions
      .items({ categoryId, patient_hash: current?.customer?.hash })
      .then(async (data: any) => {
        const items = await data.data.data;
        setItems(items);
      });
  };

  const setRootCategory = (categoryId: string, categoryName: string) => {
    setActiveRootCategoryId(categoryId);
    setActiveRootCategoryName(categoryName);
    fetchItems({ categoryId });
    const selectedTree = categories?.find(
      (tree: any) => tree.root.id === categoryId
    );
    if (!selectedTree) return;
    const navArray = selectedTree?.find(categoryId).children;
    setActiveNavId(navArray[0].id);
  };

  const handleAddTest = (item: Item) => {
    dispatch(addTest(item));
  };
  const handleRemoveTest = (item: Item) => {
    dispatch(removeTest(item));
  };
  const handleRemovePetition = (item: Item) => {
    dispatch(removePetition(item));
  };
  const handleCheckAll = (accordeonId: string, childrenItems: any) => {
    childrenItems
      .filter((item: any) => item.category_id === accordeonId)
      .forEach((item: any) => handleAddTest(item));
  };

  const handleUncheckAll = (accordeonId: string, childrenItems: any) => {
    childrenItems
      .filter((item: any) => item.category_id === accordeonId)
      .forEach((item: any) => handleRemoveTest(item));
  };

  const handleOnClose = () => {
    portal.close('diagnostic_tests_drawer');
    dispatch(clearTests());
  };

  const handleSendPetitions = (
    petitions: any,
    consultationId: string | undefined,
    consultationType: string | undefined
  ) => {
    petitions.forEach((petition: any) => {
      const { name, observations, laterality, sessions, file } = petition;
      let newPetition: any = {
        contact_id: current?.meta?.contact_id,
        item_ids: selected_items
          .filter((item: any) => item.meta.component_name === name)
          .map((item: any) => item.id),
        observations,
        consultation_id: consultationId,
        consultation_type: consultationType && 'appointment',
        laterality,
        report_url: file && file.url,
      };
      sessions && (newPetition.sessions = sessions);
      Api.diagnosticTestPrescriptions
        .savePrescriptions(newPetition)
        .then(() => {
          toast.success(
            <div className="flex items-center gap-3 text-positive-dark">
              <Check className="h-6 w-6" />
              {t('prescription_modal__petitions_sent_toast')}
            </div>
          );
        })
        .catch((error) => {
          toast.error(
            <ErrorToast>
              {error.response.data.error ?? t('shared__errors_generic_message')}
            </ErrorToast>
          );
        });
    });

    handleOnClose();
    setTimeout(() => {
      fetchPrescriptions();
    }, 2000);
  };

  const handleSetSelectedItems = (items: Item[]) => {
    dispatch(setTests(items));
  };

  const handleSetIsNewPetition = (isNewPetition: boolean) => {
    dispatch(setIsNewPetition(isNewPetition));
  };
  const fetchPrescriptions = () => {
    dispatch(isLoadingPrescriptions());
    Api.diagnosticTestPrescriptions
      .fetchPrescriptions({ hash: current?.meta?.hash })
      .then((data: any) => {
        const prescriptions = data.data.data;
        dispatch(diagnosticTestsFetched(prescriptions?.length));
        dispatch(prescriptionsFetched(prescriptions));
      });
  };

  const handleSaveTemplate = ({ title }: { title: string }) => {
    Api.diagnosticTestPrescriptions
      .saveTemplate({
        title,
        item_ids: selected_items.map((item: any) => item.id),
      })
      .then(({ data }) => {
        toast.success(
          <div className="flex items-center gap-3 text-positive-dark">
            <Check className="h-6 w-6" />
            {t('prescription_modal__template_saved_toast')}
          </div>
        );
        dispatch(
          setActiveTemplate({
            id: data.data.id,
            title,
            template_items: selected_items,
          })
        );
      })
      .catch(() =>
        toast.error(
          <ErrorToast>{t('shared__errors_generic_message')}</ErrorToast>
        )
      );
  };

  const handleUpdateTemplate = (template: any) => {
    Api.diagnosticTestPrescriptions
      .updateTemplate({
        id: template.id,
        title: template.title,
        item_ids: selected_items.map((item: any) => item.id),
      })
      .then((res) => {
        toast.success(
          <div className="flex items-center gap-3 text-positive-dark">
            <Check className="h-6 w-6" />
            {t('prescription_modal__template_saved_toast')}
          </div>
        );

        dispatch(
          setActiveTemplate({
            id: res.data.data.id,
            title: template.title,
            template_items: selected_items,
          })
        );
      })
      .catch(() =>
        toast.error(
          <ErrorToast>{t('shared__errors_generic_message')}</ErrorToast>
        )
      );
  };

  return {
    fetchCategories,
    fetchItems,
    fetchPrescriptions,
    categories,
    items,
    setRootCategory,
    activeRootCategoryId,
    activeRootCategoryName,
    setActiveNavId,
    activeNavId,
    selectedItems,
    setSelectedItems,
    handleAddTest,
    handleRemoveTest,
    selected_items,
    petitions,
    handleCheckAll,
    handleUncheckAll,
    handleOnClose,
    handleSendPetitions,
    handleRemovePetition,
    handleSetSelectedItems,
    handleSetIsNewPetition,
    handleSaveTemplate,
    handleUpdateTemplate,
  };
};
export default useDiagnosticTest;
