import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';
import Tracker from 'utils/Tracking';

const API = 'professionals/v1';
const BASE_URL = config.services.mediquo.chat_url;

const create = ({ userHash, price, contactId, roomId }) => {
  Tracker.event('appointment send payment button click', {
    event_room_id: null,
  });

  if (contactId) {
    return axios({
      method: 'POST',
      url: `${BASE_URL}/${API}/appointments`,
      data: {
        to_contact_id: contactId,
        price: parseInt(parseFloat(price) * 100),
        type: 'past',
      },
      headers: getHeaders(),
    });
  }

  return axios({
    method: 'POST',
    url: `${BASE_URL}/${API}/rooms/${roomId}/appointments`,
    data: {
      to: userHash,
      roomId,
      price: parseInt(parseFloat(price) * 100),
      type: 'past',
    },
    headers: getHeaders(),
  });
};

const credentials = {
  get: () =>
    axios({
      method: 'GET',
      url: `${BASE_URL}/${API}/payments/provider`,
      headers: getHeaders(),
    }),
  update: ({ publicKey, accessToken }) =>
    axios({
      method: 'PUT',
      url: `${BASE_URL}/${API}/payments/provider`,
      data: {
        public_key: publicKey,
        access_token: accessToken,
      },
      headers: getHeaders(),
    }),
};

export default {
  create,
  credentials,
};
