import RichContainer from 'containers/DetailBar/PatientBar/components/PatientProfile/RichContainer';
import ScreenHeader from 'containers/DetailBar/PatientBar/components/common/ScreenHeader';
import useMedicalReport from 'containers/MedicalReport/useMedicalReport';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OrganizationGuidesState } from 'state/organizationGuides.slice';

const OrganizationDetailsScreen = () => {
  const { t } = useTranslation();
  const { handleChangeScreen } = useMedicalReport();
  const { currentOrganization } = useSelector<any, OrganizationGuidesState>(
    (state) => state.organizationGuides
  );

  const handleGoToProfile = () => {
    handleChangeScreen('profile');
  };

  return (
    <>
      <ScreenHeader
        title={t('sidebar__patient_organization_guides_header')}
        onBack={handleGoToProfile}
      />

      <RichContainer
        className="pt-0 px-4"
        dangerouslySetInnerHTML={{
          __html: currentOrganization?.service_guide || '',
        }}
      ></RichContainer>
    </>
  );
};

export default OrganizationDetailsScreen;
