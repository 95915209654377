import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TrackingMessageScheduler from 'containers/Inbox/components/RoomListHeader/TrackingMessageScheduler';
import SentencesPicker from 'containers/Room/components/RoomInput/SentencesPicker';
import {
  DropdownMenu,
  DropdownHeader,
  DropdownOption,
  useDropdown,
} from 'containers/Shared/components/Dropdown/index';
import { useDispatch, useSelector } from 'react-redux';
import UnlockRoomModal from 'containers/Room/components/RoomBody/UnlockRoomModal';
import useBlockModal from 'containers/Shared/components/Patient/blockModal.hook';
import PatientUnavailableModal from 'containers/Shared/components/Patient/PatientUnavailableModal';
import Tracker from 'utils/Tracking';
import { usePortal } from 'containers/Shared/components/Portal';
import classnames from 'classnames';
import EmptyCalendar from 'components/icons/outline/EmptyCalendar';
import ChatSquare from 'components/icons/outline/ChatSquare';
import FileAdd from 'components/icons/outline/FileAdd';
import File from 'components/icons/outline/File';
import Plus from 'components/icons/outline/Plus';
import { usePrescriptionDrawer } from 'containers/Prescription/index';
import { getCanPrescribe } from 'containers/Inbox/reducers/session.reducer';
import FeatureFlag from 'containers/Shared/components/FeatureFlag';
import useMedicalReport from 'containers/MedicalReport/useMedicalReport';
import useRoomActions from 'containers/Room/hooks/useRoomActions';
import StartConsultationModal from 'containers/Consultations/StartConsultationModal';
import api from 'api';
import { chatConsultationStarted } from 'containers/Room/actions/JoinRoom.actions';

const AddMorePicker = ({
  room,
  disabled = false,
  consultationType,
  consultationId,
  onSelect,
}) => {
  const { t } = useTranslation();
  const { openPrescriptionDrawer } = usePrescriptionDrawer();
  const dropdown = useDropdown();
  const portal = usePortal();
  const [addSentence, setAddSentence] = useState(false);
  const { handleChangeScreen } = useMedicalReport();
  const { isDetailBarOpen, toggleProfile } = useRoomActions({ room });

  const canPrescribe = useSelector(getCanPrescribe);

  const roomBlocked = useBlockModal();
  const patientUnavailable = useBlockModal();
  const isPatient = room?.type === 'patient';
  const current = useSelector((state) => state.console?.current);
  const dispatch = useDispatch();

  const handleScheduleTracking = () => {
    Tracker.event('chat view follow up bar button', {
      event_room_id: room?.room_id,
    });
    portal.open(
      <TrackingMessageScheduler
        rooms={[room?.room_id]}
        single
        onSubmit={() => portal.close('tracking')}
        onClose={() => portal.close('tracking')}
      />,
      'tracking'
    );
    dropdown.close();
  };

  const handleMedicalReport = () => {
    Tracker.event('chat view medical report button', {
      event_room_id: room?.room_id,
    });
    if (current?.current_chat_consultation?.status === 'pending') {
      portal.open(
        <StartConsultationModal
          title={t('chat__consultation_start_report_modal_title')}
          description={t('chat__consultation_start_report_modal_description')}
          onClose={() => portal.close('start_consultation')}
          consultation={current?.current_chat_consultation}
          costumerName={current?.customer?.name}
          onConfirm={() => {
            handleStartConsultation();
            portal.close('start_consultation');
          }}
        />,
        'start_consultation'
      );
    } else {
      !isDetailBarOpen && toggleProfile();
      handleChangeScreen('report');
    }
    dropdown.close();
  };

  const handlePredefinedSentence = () => {
    Tracker.event('chat view sentences bar button', {
      event_room_id: room?.room_id,
    });
    setAddSentence(true);
    dropdown.close();
  };
  const handleStartConsultation = () => {
    api.chat
      .start({ consultation_id: current?.current_chat_consultation?.id })
      .then(
        dispatch(
          chatConsultationStarted(current?.current_chat_consultation?.id)
        )
      );
  };

  const handlePrescription = () => {
    Tracker.event('chat view prescription bar button', {
      event_room_id: room?.room_id,
    });

    current?.current_chat_consultation?.status === 'pending'
      ? portal.open(
          <StartConsultationModal
            title={t('chat__consultation_start_prescription_modal_title')}
            description={t('chat__consultation_start_prescription_modal_description')}
            onClose={() => portal.close('start_consultation')}
            consultation={current?.current_chat_consultation}
            costumerName={current?.customer?.name}
            onConfirm={() => {
              handleStartConsultation();
              portal.close('start_consultation');
            }}
          />,
          'start_consultation'
        )
      : openPrescriptionDrawer({ room, consultationType, consultationId });

    dropdown.close();
  };

  const callDropdown = (dropdown) => {
    Tracker.event('chat view action plus button', {
      event_room_id: room?.room_id,
    });
    if (room?.status === 'blocked') {
      roomBlocked.show();
      return;
    }
    dropdown.toggle();
  };

  return (
    <div>
      {addSentence && (
        <SentencesPicker
          onClose={() => setAddSentence(false)}
          onSelect={(sentence) => onSelect(sentence)}
        />
      )}
      <DropdownMenu
        open={dropdown.isOpen}
        className="left-0 w-64 transform -translate-y-full -top-4"
        onClose={dropdown.close}
      >
        <DropdownHeader>{t('room__input_add_more_title')}</DropdownHeader>
        <DropdownOption
          className="flex items-center"
          onClick={handleScheduleTracking}
        >
          <EmptyCalendar className="w-4 h-4 mr-2" />
          {t('room__input_add_more_tracking')}
        </DropdownOption>
        <DropdownOption
          className="flex items-center"
          onClick={handlePredefinedSentence}
        >
          <ChatSquare className="w-4 h-4 mr-2" />
          {t('room__input_add_more_sentence')}
        </DropdownOption>
        <FeatureFlag enabled={isPatient && canPrescribe}>
          <DropdownOption
            className="flex items-center"
            onClick={handlePrescription}
          >
            <FileAdd className="w-4 h-4 mr-2" />
            {t('room__input_add_more_prescription')}
          </DropdownOption>
        </FeatureFlag>

        <FeatureFlag enabled={isPatient}>
          <DropdownOption
            className="flex items-center"
            onClick={handleMedicalReport}
          >
            <File className="w-4 h-4 mr-2" />
            {t('Informe médico')}
          </DropdownOption>
        </FeatureFlag>
      </DropdownMenu>
      <button
        disabled={disabled}
        onClick={() => callDropdown(dropdown)}
        className={classnames(
          'w-8 h-8 m-1 rounded-full flex items-center justify-center',
          {
            'hover:text-white hover:bg-primary focus:outline-none': !disabled,
            'z-30 text-white bg-primary': dropdown.isOpen && !disabled,
            'bg-secundary text-primary': !dropdown.isOpen && !disabled,
            'bg-separators text-white': !dropdown.isOpen && disabled,
          }
        )}
      >
        <Plus className="w-6 h-6" />
      </button>
      <UnlockRoomModal
        room={room}
        show={roomBlocked.isOpen}
        onClose={roomBlocked.close}
      />
      <PatientUnavailableModal
        show={patientUnavailable.isOpen}
        onClose={patientUnavailable.close}
      />
    </div>
  );
};

export default AddMorePicker;
