import { useTranslation } from 'react-i18next';
import { usePortal } from 'containers/Shared/components/Portal';
import useDiagnosticTest from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/useDiagnosticTest';
import { useEffect, useState } from 'react';
import Button from 'components/Button';
import ReviewTestBody from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/ReviewTestBody';
import Trash from 'containers/Appointments/icons/Trash';
import Confirmation from 'components/Modal/Confirmation';
import { clearTests } from 'state/diagnosticTests/index.slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  ContentTitle,
  DiagnosticTestMenuCategory,
  SidebarTitle,
} from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/DiagnosticTestPrescriptionsPanel';
import EditPetitionDrawer from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/EditPetitionDrawer';
import Pencil from 'components/icons/outline/Pencil';
import Plus from 'components/icons/Plus';
import AddPetitionDrawer from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/AddPetitionDrawer';
import SaveTemplate from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/Templates/SaveTemplate';
import classNames from 'classnames';
import FeatureFlag from 'containers/Shared/components/FeatureFlag';
import SquareGrid from 'components/icons/solid/SquareGrid';
import EditTemplate from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/Templates/EditTemplate';

const ReviewContainer = ({
  setCurrentStatus,
  onClose,
  consultationId,
  consultationType,
}) => {
  const { t } = useTranslation();
  const {
    petitions,
    handleSendPetitions,
    handleRemovePetition,
    selected_items,
  } = useDiagnosticTest();
  const [activePetition, setActivePetition] = useState({});
  const [savedPetitions, setSavedPetitions] = useState([]);
  const [draftPetitions, setDraftPetitions] = useState([]);
  const [canSend, setCanSend] = useState(false);
  const portal = usePortal();
  const dispatch = useDispatch();
  const [isUniquePetition, setIsUniquePetition] = useState(false);
  const [isSaveTemplateOpen, setIsSaveTemplateOpen] = useState(false);
  const [showEditTemplate, setShowEditTemplate] = useState(false);
  const [isEditTemplateOpen, setIsEditTemplateOpen] = useState(false);
  const { activeTemplate } = useSelector((state) => state.diagnosticTests);

  useEffect(() => {
    petitions.length === savedPetitions.length
      ? setCanSend(true)
      : setCanSend(false);
  }, [savedPetitions, petitions]);

  const handleOnSave = () => {
    !savedPetitions.includes(activePetition.name) &&
      setSavedPetitions([...savedPetitions, activePetition.name]);
    const nextPetition = draftPetitions.find(
      (item) =>
        item.name !== activePetition.name && !savedPetitions.includes(item.name)
    );
    nextPetition && setActivePetition(nextPetition);
  };
  const [hideSaveTemplate, setHideSaveTemplate] = useState(false);

  const handleOnSend = () => {
    handleSendPetitions(draftPetitions, consultationId, consultationType);
  };

  useEffect(() => {
    handleDraftPetitions();
  }, []);

  const deletePetition = (activePetition) => {
    handleRemovePetition(activePetition);
    const nextPetition = draftPetitions.find(
      (item) => item.name !== activePetition.name
    );
    if (nextPetition) {
      setDraftPetitions(
        draftPetitions.filter(
          (petition) => petition.name !== activePetition.name
        )
      );

      if (savedPetitions.includes(activePetition.name)) {
        setSavedPetitions(
          savedPetitions.filter((petition) => petition !== activePetition.name)
        );
      }
      setActivePetition(nextPetition);
    } else {
      setDraftPetitions([]);
      setSavedPetitions([]);
      setActivePetition({});
      dispatch(clearTests());
      setCurrentStatus('search');
    }
  };

  const getPetitionType = () => {
    const isUnique = !!selected_items.find(
      (item) => item.name === activePetition.name
    );
    setIsUniquePetition(isUnique);
  };

  useEffect(() => {
    getPetitionType();
  }, [activePetition]);

  const handleOnDelete = () => {
    portal.open(
      <Confirmation
        title={t('prescription_modal__delete_petition_modal_title')}
        content={t('prescription_modal__delete_petition_modal_text')}
        cancelText={t('prescription_modal__delete_petition_modal_cancel')}
        confirmText={t('prescription_modal__delete_petition_modal_confirm')}
        onCancel={() => {
          portal.close('confirmation_delete_petition');
        }}
        onConfirm={async () => {
          deletePetition(activePetition);
          await portal.close('confirmation_delete_petition');
        }}
        petitions
      />,
      'confirmation_delete_petition'
    );
  };

  const handleDraftPetitions = () => {
    const newPetitions = [];
    petitions.forEach((petition) => {
      const newPetition = {
        name: petition,
        observations: '',
        consultation_id: '',
        consultation_type: '',
        laterality: [],
        sessions: 0,
      };
      newPetitions.push(newPetition);
    });
    setDraftPetitions(newPetitions);
    setActivePetition(newPetitions[0]);
  };

  const openEditModal = () => {
    portal.open(
      <EditPetitionDrawer
        onClose={() => portal.close('diagnostic_tests_edit_drawer')}
        petition={activePetition}
        setCurrentStatus={setCurrentStatus}
        deletePetition={deletePetition}
      />,
      'diagnostic_tests_edit_drawer'
    );
  };

  const openAddPetitionModal = () => {
    portal.open(
      <AddPetitionDrawer
        onClose={() => portal.close('diagnostic_tests_add_drawer')}
        petition={activePetition}
        setDraftPetitions={setDraftPetitions}
        setActivePetition={setActivePetition}
        draftPetitions={draftPetitions}
      />,
      'diagnostic_tests_add_drawer'
    );
  };

  const handleSend = () => {
    handleOnSend();
    onClose();
  };

  useEffect(() => {
    if (activeTemplate) {
      setHideSaveTemplate(true);
      const { template_items } = activeTemplate;
      if (template_items.length) {
        if (template_items.length !== selected_items.length) {
          return setShowEditTemplate(true);
        } else {
          selected_items.forEach((item) => {
            const itemIndex = template_items.findIndex(
              (templateItem) => templateItem.id === item.id
            );
            if (itemIndex === -1) {
              return setShowEditTemplate(true);
            }
            setShowEditTemplate(false);
          });
        }
      }
    } else {
      setHideSaveTemplate(false);
    }
  }, [activeTemplate, selected_items]);

  return (
    <div className="h-full justify-between">
      <div className="flex flex-row w-full h-full">
        <div className="w-[320px] min-w-[320px] lg:w-450px lg:min-w-450px border-r border-blue-light min-h-full">
          {activeTemplate ? (
            <TemplateSidebarTitle>{activeTemplate.title}</TemplateSidebarTitle>
          ) : (
            <SidebarTitle>
              {t('prescription_modal__petitions_sidebar_title')}
            </SidebarTitle>
          )}

          <div
            className={classNames('h-full overflow-y-auto', {
              'pb-10 max-h-[48vh]': isSaveTemplateOpen,
              'pb-20 max-h-[48vh]': isEditTemplateOpen,
              'pb-3 max-h-[60vh]': !isSaveTemplateOpen && !isEditTemplateOpen,
            })}
          >
            {draftPetitions?.map((petition, index) => {
              return (
                <DiagnosticTestMenuCategory
                  key={index + petition.name}
                  isActive={activePetition.name === petition.name}
                  isPetition
                  petitionName={petition.name}
                  onClick={() => setActivePetition(petition)}
                  isSaved
                  savedPetitions={savedPetitions}
                />
              );
            })}

            <div className="m-3 flex">
              <button
                className="flex flex-row w-full rounded-lg py-2 items-center uppercase text-primary bg-secundary font-medium text-xs whitespace-nowrap tracking-wider justify-center"
                onClick={openAddPetitionModal}
              >
                <Plus className="h-3 w-3 mr-3" />
                {t('prescription_modal__add_petition_modal_button')}
              </button>
            </div>
          </div>
        </div>
        <div className="w-full px-8 pt-10 max-h-[78vh]">
          <div className="flex flex-row justify-between">
            <ContentTitle>{activePetition.name}</ContentTitle>
            <div className="flex flex-row gap-3">
              {!isUniquePetition && (
                <button
                  className="flex items-center justify-center text-gray-dark w-6 h-6 rounded border border-separators"
                  onClick={openEditModal}
                >
                  <Pencil className="w-4 h-5" />
                </button>
              )}
              <button
                className="flex items-center justify-center text-gray-dark w-6 h-6 rounded border border-separators"
                onClick={handleOnDelete}
              >
                <Trash className="w-3 h-4" />
              </button>
            </div>
          </div>
          <div className="max-h-[65vh] overflow-y-auto">
            <ReviewTestBody
              activePetition={activePetition}
              setActivePetition={setActivePetition}
              setDraftPetitions={setDraftPetitions}
              draftPetitions={draftPetitions}
              setSavedPetitions={setSavedPetitions}
              savedPetitions={savedPetitions}
              isUniquePetition={isUniquePetition}
            />
          </div>
        </div>
      </div>

      <div className="bottom-0 fixed w-full">
        <div className="w-[320px] min-w-320px max-w-350px lg:max-w-450px lg:w-450px lg:min-w-450px bg-white">
          <FeatureFlag enabled={!hideSaveTemplate}>
            <SaveTemplate
              isOpen={isSaveTemplateOpen}
              setIsOpen={setIsSaveTemplateOpen}
              setHideSaveTemplate={setHideSaveTemplate}
            />
          </FeatureFlag>

          <EditTemplate
            template={activeTemplate}
            isOpen={isEditTemplateOpen}
            setIsOpen={setIsEditTemplateOpen}
            setIsSaveNewOpen={setIsSaveTemplateOpen}
            setHideSaveTemplate={setHideSaveTemplate}
            setShowEditTemplate={setShowEditTemplate}
            showEditTemplate={showEditTemplate}
          />
        </div>
        <div className="flex flex-row justify-between bg-white items-center pr-6 h-20 max-h-20 border-t border-blue-light shrink-0">
          <div className="flex items-center px-4 w-[320px] min-w-[320]px lg:w-450px lg:min-w-450px border-r border-blue-light h-full">
            <Button
              disabled={!canSend}
              className="flex w-full"
              onClick={handleSend}
            >
              {t('prescription_modal__petitions_send_button')}
            </Button>
          </div>
          <div>
            <Button
              onClick={handleOnSave}
              disabled={savedPetitions.includes(activePetition.name)}
            >
              {t('prescription_modal__petitions_save_button')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const TemplateSidebarTitle = ({ children }) => {
  return (
    <div className="font-medium tracking-[0.01em] mt-5 mb-4 ml-6 mr-3">
      <div className="flex flex-row justify-between text-primary shrink-0">
        <div className="text-xs capitalize">plantilla</div>
        <SquareGrid className="w-4" />
      </div>
      <div className="text-lg font-medium tracking-[0.01em] text-dark truncate capitalize">
        {children}
      </div>
    </div>
  );
};

export default ReviewContainer;
