const File = (props) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 1.333h5.334l4 4v8c0 .733-.6 1.333-1.334 1.333H3.994c-.734 0-1.327-.6-1.327-1.333l.007-10.667c0-.733.593-1.333 1.326-1.333zm0 1.333v10.667h8V6H8.667V2.666H4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default File;
