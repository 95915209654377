import React, { useEffect } from 'react';
import {
  ModalConfirm,
  ModalDismiss,
  ModalHeader,
  Modal,
} from 'containers/Shared/components/Modal/Modal';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Api from 'api/index';
import useLazyQuery from 'utils/useLazyQuery';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import { sentenceAdded } from 'containers/Inbox/actions/Session.actions';
import { toast } from 'react-toastify';
import Tracker from 'utils/Tracking';
import CRMTracker from 'utils/CRMTracker';
import { useTranslation } from 'react-i18next';

const CreateSentence = ({ onClose, onCloseWithSend }) => {
  const { t } = useTranslation();
  const [ApiCreateSentence, isCreating] = useLazyQuery(Api.createSentence);
  const dispatch = useDispatch();
  const {
    register,
    watch,
    formState: { isValid },
  } = useForm({ mode: 'onBlur' });
  const sentence = watch('sentence');

  useEffect(() => {
    Tracker.pageView('sentence add view');
  }, []);

  const createAndClose = () => {
    if (sentence.length > 0) {
      Tracker.event('sentence add create');
    }
    create({ onSuccess: onClose });
  };

  const createAndSend = () => {
    if (sentence.length > 0) {
      Tracker.event('sentence add create and send', {
        event_message_content: sentence,
      });
    }
    create({ onSuccess: onCloseWithSend });
  };

  const create = ({ onSuccess }) =>
    isValid &&
    !isCreating &&
    ApiCreateSentence(sentence.trim())
      .then(({ data: { data: sentenceCreated } }) => {
        dispatch(sentenceAdded(sentenceCreated));
        onSuccess && onSuccess(sentenceCreated);
        CRMTracker.event('PredefinedPhraseCreated');
      })
      .catch(() =>
        toast.error(
          <ErrorToast>{t('sentence__create_modal_error')}</ErrorToast>
        )
      );

  const dismissModal = () => {
    Tracker.event('sentence add dismiss');
    onClose();
  };

  return (
    <Modal onClose={dismissModal}>
      <ModalHeader onClose={dismissModal}>
        {t('sentence__create_modal_title')}
      </ModalHeader>
      <form onSubmit={(event) => event.preventDefault()}>
        <div className="px-6 py-4 bg-white">
          <div className="mb-5 text-xs text-primary">
            {t('sentence__create_modal_subtitle')}
          </div>
          <div className="relative">
            <textarea
              ref={register({ required: true })}
              name="sentence"
              maxLength={280}
              className="w-full p-4 border rounded-md border-separators"
              placeholder={t('sentence__create_modal_form_placeholder')}
              rows="6"
            />
            <span className="absolute bottom-4 right-4 text-gray-medium">
              {sentence?.length || 0} / 280
            </span>
          </div>
        </div>
        <div className="px-4 py-5 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
          <ModalConfirm onClick={createAndClose}>
            {t('sentence__create_modal_submit')}
          </ModalConfirm>
          <ModalDismiss onClose={createAndSend}>
            {t('sentence__create_modal_send')}
          </ModalDismiss>
        </div>
      </form>
    </Modal>
  );
};

export default CreateSentence;
