import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

export const BASE_URL = config.services.mediquo.chat_url;

const start = () =>
  axios({
    method: 'post',
    url: `${BASE_URL}/professionals/v1/consultations/accept`,
    headers: getHeaders(),
  });

const skip = ({ id }) =>
  axios({
    method: 'put',
    url: `${BASE_URL}/professionals/v1/consultations/${id}/skip`,
    headers: getHeaders(),
  });

const disconnect = () =>
  axios({
    method: 'post',
    url: `${BASE_URL}/professionals/v1/consultations/disconnect`,
    headers: getHeaders(),
  });

const finish = ({ id }) =>
  axios({
    method: 'put',
    url: `${BASE_URL}/professionals/v1/consultations/${id}/finish`,
    headers: getHeaders(),
  });

export default {
  start,
  finish,
  skip,
  disconnect,
};
