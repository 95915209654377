import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Api from 'api';
import downloadFile from 'utils/download';

export interface Props {
  patientHash: string;
  reportUuid: string;
}

const useDownloadReport = ({ patientHash, reportUuid }: Props) => {
  const { t } = useTranslation();
  const [state, setState] = useState<'initial' | 'loading' | 'success'>(
    'initial'
  );
  const [downloadData, setDownloadData] = useState<BlobPart>();

  const getDownloadData = () => {
    setState('loading');
    Api.reports
      .download({ patient_hash: patientHash, report_uuid: reportUuid })
      .then(({ data }) => {
        setDownloadData(data);
        setState('success');
      })
      .catch(() => {
        toast.error(
          <ErrorToast>{t('shared__errors_generic_message')}</ErrorToast>
        );
        setState('initial');
      });
  };

  useEffect(() => {
    downloadData &&
      downloadFile({ data: downloadData, name: `${reportUuid}.pdf` });
  }, [downloadData]);

  const download = () =>
    !downloadData
      ? getDownloadData()
      : downloadFile({ data: downloadData, name: `${reportUuid}.pdf` });

  return { state, download };
};

export default useDownloadReport;
