import React, { useEffect, useState } from 'react';
import MicMutedInverse from 'containers/VideoCall/icons/MicMutedInverseIcon';
import BlockedCamInverseIcon from 'containers/VideoCall/icons/BlockedCamInverseIcon';
import { useTranslation } from 'react-i18next';

const ToastContainer = ({
  receiverAudioEnabled,
  receiverVideoEnabled,
  videoActivated,
  audioEnabled,
  videoEnabled,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {!receiverAudioEnabled && (
        <Toast
          message={t('videocall__toast_receiver_muted')}
          icon={MicMutedInverse}
        />
      )}
      {!receiverVideoEnabled && videoActivated && (
        <Toast
          message={t('videocall__toast_receiver_video_muted')}
          icon={BlockedCamInverseIcon}
        />
      )}
      {!audioEnabled && (
        <Toast
          message={t('videocall__toast_audio_muted')}
          icon={MicMutedInverse}
        />
      )}
      {!videoEnabled && videoActivated && (
        <Toast
          message={t('videocall__toast_video_muted')}
          icon={BlockedCamInverseIcon}
        />
      )}
    </>
  );
};

const TOAST_TIMEOUT = 5000;
const Toast = ({ icon, message }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, TOAST_TIMEOUT);
  }, []);

  return (
    <div className={`vc_toast ${visible ? 'show' : ''}`}>
      {icon}
      {message}
    </div>
  );
};

export default ToastContainer;
