import LogoAppIcon from 'components/icons/LogoAppIcon';

const NotOptimizedForMobilePage = () => {
  return (
    <div className="fixed inset-x-0 top-0 z-10 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 opacity-90"></div>
      </div>

      <div className="max-w-xl p-10 mt-8 overflow-hidden transition-all transform bg-white shadow-xl text-dark rounded-24 sm:w-full">
        <div className="flex flex-col items-center text-center">
          <div className="flex items-center justify-center my-7 rounded-lg text-negative">
            <LogoAppIcon className="w-full h-full" />
          </div>
          <div className="mb-6 text-2xl font-medium text-primary">
            Mediquo PRO no está optimizado para pantallas pequeñas
          </div>
          <div className="mb-12">
            Para obtener la experiencia completa, por favor utilízalo en tu
            ordenador
          </div>
          <a
            className="flex items-center h-12 px-6 font-medium text-white uppercase border rounded-lg bg-primary border-primary focus:outline-none hover:bg-primary-dark"
            href="https://eat.emmasolutions.net?entw=ec74767f8ce3fd371260461662a9328d"
          >
            Ir a MediQuo PRO
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotOptimizedForMobilePage;
