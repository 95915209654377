import React from 'react';

export default () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M5.33398 24C5.33398 20.36 12.8273 18.5467 16.4673 18.68C16.174 19.5067 16.0007 20.4 16.0007 21.3334C12.4451 21.3334 9.77843 22.2223 8.00065 24H16.4673C16.814 25 17.3607 25.8934 18.054 26.6667H8.00065C6.52789 26.6667 5.33398 25.4728 5.33398 24ZM16.0007 5.33337C18.9473 5.33337 21.334 7.72004 21.334 10.6667C21.334 13.6134 18.9473 16 16.0007 16C13.054 16 10.6673 13.6134 10.6673 10.6667C10.6673 7.72004 13.054 5.33337 16.0007 5.33337ZM16.0007 8.00004C14.534 8.00004 13.334 9.20004 13.334 10.6667C13.334 12.1334 14.534 13.3334 16.0007 13.3334C17.4673 13.3334 18.6673 12.1334 18.6673 10.6667C18.6673 9.20004 17.4673 8.00004 16.0007 8.00004Z"
      fill="#4A1FA5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.666 28C23.4024 28 23.9993 28.597 23.9993 29.3334C23.9993 30.0698 23.4024 30.6667 22.666 30.6667C21.9296 30.6667 21.3327 30.0698 21.3327 29.3334C21.3327 28.597 21.9296 28 22.666 28ZM22.666 17.3334C24.8793 17.3334 26.666 19.12 26.666 21.3334C26.666 22.2134 26.306 23.0134 25.7327 23.5867C25.7327 23.5867 25.226 24.1467 24.8393 24.5334C24.1993 25.1734 23.7327 26.0667 23.7327 26.6667H21.5993C21.5993 25.56 22.2127 24.64 22.8393 24L24.0793 22.7467C24.4393 22.3867 24.666 21.88 24.666 21.3334C24.666 20.2267 23.7727 19.3334 22.666 19.3334C21.5593 19.3334 20.666 20.2267 20.666 21.3334H18.666C18.666 19.12 20.4527 17.3334 22.666 17.3334Z"
      fill="#A48ED2"
    />
  </svg>
);
