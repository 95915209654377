import React from 'react';

export default (props) => (
  <svg width="18" height="18" viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M6 0C2.688 0 0 2.688 0 6C0 9.312 2.688 12 6 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 6 0ZM1.2 6C1.2 3.348 3.348 1.2 6 1.2C7.11 1.2 8.13 1.578 8.94 2.214L2.214 8.94C1.578 8.13 1.2 7.11 1.2 6ZM6 10.8C4.89 10.8 3.87 10.422 3.06 9.786L9.786 3.06C10.422 3.87 10.8 4.89 10.8 6C10.8 8.652 8.652 10.8 6 10.8Z"
      fill="currentColor"
    />
  </svg>
);
