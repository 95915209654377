import Button from 'components/Button';
import Form from 'components/Form';
import Modal from 'components/Modal';
import { TargetType } from 'containers/Invitations/Invitations';
import Message from 'containers/Onboarding/icons/Message';
import PhoneIcon from 'containers/Onboarding/icons/Phone';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { email as emailRegexp } from 'utils/regexp';
import Phone, {
  Fields as PhoneFields,
  DEFAULT_PHONE_PREFIX,
} from 'components/Form/Phone';
import { useForm } from 'react-hook-form';
import Api from 'api';
import classNames from 'classnames';
import PhoneAndMessage from 'containers/Onboarding/icons/PhoneAndMessage';
import { toast } from 'react-toastify';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import { useDispatch, useSelector } from 'react-redux';
import { addInvitation } from 'services/invitations';
import useQuery from 'utils/useQuery';
import InvitationSent from 'containers/Invitations/InvitationSent';
import Tracker from 'utils/Tracking';
import CRMTracker from 'utils/CRMTracker';
import GoogleTagManager from 'utils/GoogleTagManager';
import { useHistory } from 'react-router';
import {
  getRoomsList,
  getSummaryRoomsList,
} from 'containers/Inbox/actions/GetRoomList.actions';
import { Contact } from 'services/contacts';

export interface Props {
  target: TargetType;
  withConfirmation?: boolean;
  onClose: (contact?: Partial<Contact>) => void;
}

type FieldsType = {
  invite: 'sms' | 'email' | 'all';
  prefix?: string;
  name: string;
  lastName?: string;
  tag?: string;
  phone?: string;
  email?: string;
  billing_name?: string;
  billing_tax_id?: string;
  billing_address?: string;
  billing_postal_code?: string;
  billing_region?: string;
  billing_country_code?: string;
};

const Invite: FC<Props> = ({ target, onClose, withConfirmation = true }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const workPracticeCountry = useSelector(
    (state: any) => state?.session?.profile?.workPracticeCountry
  );
  const [invitation, setInvitation] = useState<{
    sent?: boolean;
    roomId?: number;
  }>({});
  const [showBilling, setShowBilling] = useState(false);

  const { data: countries, isLoading: countriesLoading } = useQuery(
    Api.getCountries
  );

  const Fields = {
    invite: {
      name: 'invite',
      options: [
        {
          value: 'sms',
          icon: PhoneIcon,
          label: t('login__onboarding_invite_form_type_sms_label'),
          defaultChecked: true,
        },
        {
          value: 'email',
          icon: Message,
          label: t('login__onboarding_invite_form_type_email_label'),
        },
        {
          value: 'all',
          icon: PhoneAndMessage,
          label: t('Teléfono y Email'),
        },
      ],
    },
    name: {
      name: 'name',
      label: t('login__onboarding_invite_form_name_label'),
      type: 'text',
      required: t('shared__errors_required'),
    },
    lastName: {
      name: 'lastName',
      label: t('login__onboarding_invite_form_last_name_label'),
      type: 'text',
    },
    tag: {
      name: 'tag',
      label: t('login__onboarding_invite_form_tag_label'),
      type: 'text',
      info: t('login__onboarding_invite_form_tag_info'),
    },
    email: {
      name: 'email',
      label: t('login__onboarding_invite_form_email_label'),
      type: 'email',
      required: t('shared__errors_required'),
      pattern: {
        value: emailRegexp,
        message: t('login__onboarding_invite_form_type_email_invalid'),
      },
    },
    ...PhoneFields,
    billing_name: { name: 'billing_name' },
    billing_identity_document: { name: 'billing_identity_document' },
    billing_address: { name: 'billing_address' },
    billing_postal_code: { name: 'billing_postal_code' },
    billing_region: { name: 'billing_region' },
    billing_country_code: { name: 'billing_country_code' },
  };

  const {
    register,
    errors,
    formState: { dirtyFields },
    watch,
    control,
    clearErrors,
    trigger,
    handleSubmit,
  } = useForm({ mode: 'onChange' });
  const watchFields = watch(Object.values(Fields).map(({ name }) => name));

  useEffect(() => {
    trigger(Fields.invite.name);
  }, []);

  useEffect(() => {
    switch (watchFields[Fields.invite.name]) {
      case 'email':
        clearErrors(Fields.phone.name);
        dirtyFields[Fields.email.name] && trigger(Fields.email.name);
        break;
      case 'sms':
        clearErrors(Fields.email.name);
        dirtyFields[Fields.phone.name] && trigger(Fields.phone.name);
        break;
      case 'all':
        dirtyFields[Fields.email.name] && trigger(Fields.email.name);
        dirtyFields[Fields.phone.name] && trigger(Fields.phone.name);
        break;
    }
  }, [watchFields[Fields.invite.name], dirtyFields]);

  const invite = (
    {
      prefix = DEFAULT_PHONE_PREFIX,
      name,
      lastName,
      tag,
      phone,
      email,
      billing_name,
      billing_tax_id,
      billing_address,
      billing_postal_code,
      billing_region,
      billing_country_code,
    }: FieldsType,
    onClose: (contact?: Partial<Contact>) => void
  ) => {
    const fullName = `${name} ${lastName}`.trim();
    return Api.invite({
      name: fullName,
      tag,
      email,
      phone: phone ? `${prefix}${phone}` : undefined,
      target: target,
      billing: {
        name: billing_name,
        tax_id: billing_tax_id,
        address: billing_address,
        postal_code: billing_postal_code,
        region: billing_region,
        country_code: billing_country_code,
      },
    })
      .then(
        ({
          data: {
            data: { id, contact_id, room_id: roomId },
          },
        }) => {
          const created = new Date().toISOString();
          dispatch(
            addInvitation({
              id,
              phone,
              email,
              target,
              name: fullName,
              created_at: created,
              updated_at: created,
              last_sent_at: created,
              status: 'pending',
              total_invitation_attempts: 1,
            })
          );
          withConfirmation
            ? setInvitation({ sent: true, roomId })
            : onClose({
                id: contact_id,
                name: fullName,
              });
        }
      )
      .catch(() =>
        toast.error(
          <ErrorToast>{t('shared__errors_generic_message')}</ErrorToast>
        )
      );
  };

  const handleClose = () => {
    dispatch(getRoomsList());
    dispatch(getSummaryRoomsList());
    onClose();
  };

  if (invitation.sent) {
    return (
      <Modal onClose={handleClose} width="narrow">
        {({ onClose }: { onClose: () => {} }) => (
          <>
            <Modal.Header onClose={onClose}>
              {t('invite__contact_created_title')}
            </Modal.Header>
            <InvitationSent
              send={watchFields.invite as 'sms' | 'email' | 'all'}
              onConfirm={() => {
                Tracker.event('invite success button');
                CRMTracker.event('InviteSuccess');
                GoogleTagManager.conversion('dsXbCKWw6r8CEPKinbUC');
                onClose();
                history.push(`/chat/${invitation.roomId}`);
              }}
            />
          </>
        )}
      </Modal>
    );
  }

  const handleOnClose = (contact?: unknown) =>
    withConfirmation
      ? !invitation.sent && onClose()
      : onClose(contact as Partial<Contact>);

  return (
    <Modal onClose={handleOnClose} width="huge">
      {({ onClose }: { onClose: (value?: unknown) => {} }) => (
        <Form
          onSubmit={handleSubmit((fields: FieldsType) =>
            invite(fields, onClose)
          )}
        >
          <Modal.Header onClose={onClose}>
            {t(
              {
                patient: 'invite__form_title_patient',
                professional: 'invite__form_title_professional',
              }[target]
            )}
          </Modal.Header>
          <Modal.Body>
            <div className="pb-4 text-sm text-gray-dark">
              {t('login__onboarding_invite_form_send_type')}
            </div>
            <div className="flex mb-6 space-x-4">
              {Fields.invite.options.map(
                ({ label, value, icon, defaultChecked }) => (
                  <RadioButton
                    key={value}
                    name={Fields.invite.name}
                    icon={icon}
                    label={label}
                    value={value}
                    defaultChecked={defaultChecked}
                    register={register({ required: true })}
                    checked={watchFields[Fields.invite.name] === value}
                  />
                )
              )}
            </div>
            <div className="pb-4 text-sm text-gray-dark capitalize-first">
              {t('login__onboarding_invite_form_patient_fields')}
            </div>
            <div className="grid grid-cols-3 gap-x-4">
              <Form.Field
                required={!!Fields.name.required}
                key={Fields.name.name}
                label={Fields.name.label}
                type={Fields.name.type}
                placeholder={Fields.name.label}
                name={Fields.name.name}
                register={register({
                  required: Fields.name.required,
                })}
                isDirty={!!watchFields[Fields.name.name]}
                errors={errors[Fields.name.name]}
              />
              <Form.Field
                key={Fields.lastName.name}
                label={Fields.lastName.label}
                type={Fields.lastName.type}
                placeholder={Fields.lastName.label}
                name={Fields.lastName.name}
                register={register}
                isDirty={!!watchFields[Fields.lastName.name]}
                errors={errors[Fields.lastName.name]}
              />
              <Form.Field
                key={Fields.tag.name}
                label={Fields.tag.label}
                type={Fields.tag.type}
                placeholder={Fields.tag.label}
                name={Fields.tag.name}
                register={register}
                isDirty={!!watchFields[Fields.tag.name]}
                info={Fields.tag.info}
                errors={errors[Fields.tag.name]}
              />
            </div>
            <div className="grid grid-cols-2 gap-x-4">
              <Phone
                required={watchFields[Fields.invite.name] !== 'email'}
                disabled={watchFields[Fields.invite.name] === 'email'}
                register={register}
                control={control}
                isDirty={!!watchFields[Fields.phone.name]}
                errors={errors[Fields.phone.name]}
                menuPlacement="top"
              />
              <Form.Field
                required={watchFields[Fields.invite.name] !== 'sms'}
                disabled={watchFields[Fields.invite.name] === 'sms'}
                key={Fields.email.name}
                label={Fields.email.label}
                type={Fields.email.type}
                placeholder={Fields.email.label}
                name={Fields.email.name}
                register={register({
                  required:
                    watchFields[Fields.invite.name] !== 'sms' &&
                    Fields.email.required,
                  pattern:
                    watchFields[Fields.invite.name] !== 'sms'
                      ? Fields.email.pattern
                      : undefined,
                })}
                isDirty={!!watchFields[Fields.email.name]}
                errors={errors[Fields.email.name]}
              />
            </div>
            <div
              className={classNames('grid grid-cols-4 gap-x-4', {
                hidden: !showBilling,
              })}
            >
              <Form.Field
                name="billing_name"
                className="col-span-2"
                register={register()}
                label={t('sidebar__patient_billing_form_billing_name_label')}
                placeholder={t(
                  'sidebar__patient_billing_form_billing_name_placeholder'
                )}
                isDirty={!!watchFields.billing_name}
              />
              <Form.Field
                name="billing_tax_id"
                className="col-span-2"
                register={register()}
                label={t('sidebar__patient_billing_form_dni_label')}
                placeholder={t('sidebar__patient_billing_form_dni_placeholder')}
                isDirty={!!watchFields.billing_tax_id}
              />
              <Form.Field
                name="billing_address"
                register={register()}
                label={t('sidebar__patient_billing_form_address_label')}
                placeholder={t(
                  'sidebar__patient_billing_form_address_placeholder'
                )}
                isDirty={!!watchFields.billing_address}
              />
              <Form.Field
                name="billing_postal_code"
                register={register()}
                label={t('sidebar__patient_billing_form_postal_code_label')}
                placeholder={t(
                  'sidebar__patient_billing_form_postal_code_placeholder'
                )}
                isDirty={!!watchFields.billing_postal_code}
              />
              <Form.Field
                name="billing_region"
                register={register()}
                label={t('sidebar__patient_billing_form_region_label')}
                placeholder={t(
                  'sidebar__patient_billing_form_region_placeholder'
                )}
                isDirty={!!watchFields.billing_region}
              />
              <Form.Field
                type="select"
                isLoading={countriesLoading}
                options={(countries as any)?.data?.data?.map(
                  ({ key, name }: { key: string; name: string }) => ({
                    key: key,
                    name: name,
                  })
                )}
                name="billing_country_code"
                register={register()}
                label={t('sidebar__patient_billing_form_country_code_label')}
                placeholder={t(
                  'sidebar__patient_billing_form_country_code_placeholder'
                )}
                isDirty={!!watchFields.billing_country_code}
                defaultValue={workPracticeCountry}
              />
            </div>
            {target === 'patient' && (
              <button
                className="flex items-center gap-2 ml-auto text-xs text-dark"
                onClick={(event) => {
                  event.preventDefault();
                  setShowBilling((showBilling) => !showBilling);
                }}
              >
                {t(
                  showBilling
                    ? 'invite__form_hidde_information'
                    : 'invite__form_more_information'
                )}
                <Arrow direction={showBilling ? 'up' : 'down'} />
              </button>
            )}
          </Modal.Body>
          <Modal.Footer disclamer={t('invite__form_footer_disclamer')}>
            <Button>{t('invite__form_submit_button')}</Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
};

const Arrow = ({ direction }: { direction: 'up' | 'down' }) => {
  return (
    <svg
      width="10"
      height="5"
      fill="none"
      viewBox="0 0 10 5"
      className={classNames(
        'transform transition-all',
        {
          up: 'rotate-180',
          down: 'rotate-0',
        }[direction]
      )}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.2.4A1 1 0 011.6.2L5 2.75 8.4.2a1 1 0 111.2 1.6l-4 3a1 1 0 01-1.2 0l-4-3A1 1 0 01.2.4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

const RadioButton = ({
  name,
  checked,
  label,
  icon,
  value,
  register,
  disabled,
  defaultChecked,
}: any) => (
  <label
    className={classNames(
      'relative flex items-center w-48 gap-4 group cursor-pointer px-4 py-2 border rounded-lg hover:border-primary',
      {
        'border-primary': checked,
        'border-separators': !checked,
      }
    )}
  >
    <input
      className="hidden"
      name={name}
      type="radio"
      disabled={disabled}
      value={value}
      ref={register}
      defaultChecked={defaultChecked}
    />
    {icon({
      className: classNames('group-hover:text-primary', {
        'text-gray-dark': !checked,
        'text-primary': checked,
      }),
    })}
    <div className="text-[10px] font-medium text-dark">{label}</div>
    <div
      className={classNames('absolute inset-0 w-full h-full opacity-10', {
        'bg-primary': checked,
      })}
    />
  </label>
);

export default Invite;
