import { createSlice } from '@reduxjs/toolkit';
import { OrganizationGuidesResponse } from 'services/organizationGuides';

export interface OrganizationGuidesState {
  currentOrganization: OrganizationGuidesResponse | null;
}

const initialState: OrganizationGuidesState = {
  currentOrganization: null,
};

const slice = createSlice({
  name: 'organizationGuides',
  initialState,
  reducers: {
    setCurrentOrganization: (state: OrganizationGuidesState, action) => {
      state.currentOrganization = action.payload;
    },
  },
});

export const { setCurrentOrganization } = slice.actions;
export default slice.reducer;
