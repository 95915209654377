import React, { useEffect } from 'react';
import {
  OTPublisher,
  OTSubscriber,
  createSession as createSessionOpentok,
} from 'opentok-react';

export const createSession = ({ apiKey, sessionId, token, onStreamsUpdated }) =>
  createSessionOpentok({
    apiKey,
    sessionId,
    token,
    onStreamsUpdated,
  });

export const Publisher = ({
  session,
  publishAudio,
  publishVideo,
  onChangeReceiverAudio,
  otPublisherRef,
}) => {
  useEffect(() => {
    if (session) {
      session.on('streamPropertyChanged', (event) => {
        if (
          event.changedProperty === 'hasAudio' &&
          event.stream.publisher === null
        ) {
          onChangeReceiverAudio && onChangeReceiverAudio(event.newValue);
        }
      });
    }
  }, [session]);

  if (!session) {
    return <></>;
  }

  return (
    <OTPublisher
      properties={{
        audioFallbackEnabled: false,
        showControls: false,
        fitMode: 'cover',
        publishAudio,
        publishVideo,
      }}
      session={session}
      ref={otPublisherRef}
    />
  );
};

export const Subscriber = ({
  session,
  streams,
  subscribeToVideo,
  onChangeReceiverVideo,
  onConnected,
  onDestroyed,
  onDisconnected,
  onError,
}) => {
  if (!session) {
    return <></>;
  }

  return (
    <>
      {streams?.map((stream) => (
        <OTSubscriber
          key={stream.id}
          session={session}
          stream={stream}
          retry={true}
          maxRetryAttempts={30}
          properties={{
            subscribeToVideo,
            showControls: false,
            fitMode: 'contain',
          }}
          eventHandlers={{
            videoEnabled: () =>
              onChangeReceiverVideo && onChangeReceiverVideo(true),
            videoDisabled: () =>
              onChangeReceiverVideo && onChangeReceiverVideo(false),
            connected: onConnected,
            destroyed: ({ reason }) => onDestroyed && onDestroyed(reason),
            disconnected: onDisconnected,
            error: onError,
          }}
        />
      ))}
    </>
  );
};
