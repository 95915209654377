import CaretLight from 'components/icons/outline/CaretLight';

export interface ScreenHeaderProps {
  title: string;
  onBack?: () => void;
  endSection?: React.ReactNode;
}

const ScreenHeader: React.FC<ScreenHeaderProps> = ({
  title,
  onBack,
  endSection,
}) => {
  return (
    <header className="flex flex-row justify-between p-4 border-b border-separators">
      <div className="inline-flex">
        {onBack && (
          <button onClick={onBack}>
            <CaretLight className="text-gray-medium w-3.5 mr-3.5" />
          </button>
        )}
        <span className="text-dark">{title}</span>
      </div>
      {endSection}
    </header>
  );
};

export default ScreenHeader;
