export interface Props<K, L> {
  items: Partial<K>[] | null;
  id: L;
  idAttribute?: string;
}

const find = <K, L>({
  items,
  id,
  idAttribute = 'id',
}: Props<K, L>): {
  index: number;
  found: boolean;
} => {
  if (!items) {
    return { index: -1, found: false };
  }

  const index = items?.findIndex((item) => (item as any)?.[idAttribute] === id);
  return { index: index, found: index !== undefined && index !== -1 };
};

export default find;
