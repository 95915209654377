import { useState } from 'react';
import classnames from 'classnames';
import User from 'components/icons/outline/User';

const ProfileImage = ({ src, alt, className = '' }) => {
  const [state, setState] = useState(!src ? 'success' : 'loading');

  return (
    <div
      className={classnames(
        'relative w-10 h-10 rounded-full bg-blue-light flex-shrink-0',
        { 'animate-pulse': state === 'loading' }
      )}
    >
      {!src ? (
        <div className="grid w-10 h-10 rounded-full bg-blue-light place-items-center">
          <User className="w-5 h-5 text-primary" />
        </div>
      ) : (
        <img
          alt={alt}
          src={src}
          className={classnames(
            'w-full h-full object-cover rounded-full transition-opacity',
            {
              'sr-only': state === 'error',
              'opacity-0': state === 'loading',
              'opacity-100': state === 'success',
            },
            className
          )}
          onLoad={() => setState('success')}
          onError={() => setState('error')}
        />
      )}
    </div>
  );
};

export default ProfileImage;
