import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { useForm } from 'react-hook-form';
import {
  ModalBody,
  ModalFooter,
} from 'containers/Shared/components/Modal/Modal';
import ProfileContact from 'containers/Invitations/img/ProfileContact';
import Tracker from 'utils/Tracking';
import Info from 'containers/Appointments/icons/Info';
import {
  Invitation,
  SendType,
  TargetType,
} from 'containers/Invitations/Invitations';
import classnames from 'classnames';
import Button from 'components/Button';

export interface Props {
  send: SendType;
  target: TargetType;
  onSubmit: (invitation: Invitation) => void;
}

type Field = {
  name: string;
  label: string;
  placeholder: string;
  type: string;
  info?: string;
  required?: boolean;
  visible?: 'organization';
};

const ContactForm: FC<Props> = ({ send, target, onSubmit }) => {
  const { t } = useTranslation();
  const organization = useSelector(
    (state: any) => state.session?.profile?.organization
  );
  const { register, handleSubmit, watch } = useForm();

  const NameField = {
    name: 'name',
    label: 'invite__contact_form_name_label',
    placeholder: 'invite__contact_form_name_placeholder',
    type: 'text',
    required: true,
  };

  const LastNameField = {
    name: 'lastName',
    label: 'invite__contact_form_last_name_label',
    placeholder: 'invite__contact_form_last_name_placeholder',
    type: 'text',
  };

  const SendField = {
    sms: {
      name: 'phone',
      label: 'invite__contact_form_phone_label',
      placeholder: 'invite__contact_form_phone_placeholder',
      type: 'number',
      required: true,
    },
    email: {
      name: 'email',
      label: 'invite__contact_form_email_label',
      placeholder: 'invite__contact_form_email_placeholder',
      type: 'email',
      required: true,
    },
  }[send];

  const OrganizationField = {
    name: 'tag',
    label: 'invite__contact_form_tag_label',
    placeholder: 'invite__contact_form_tag_placeholder',
    type: 'text',
    info: 'invite__contact_form_tag_info',
    visible: 'organization',
  };

  const Fields: Field[] = {
    patient: [NameField, LastNameField, SendField, OrganizationField],
    professional: [NameField, LastNameField, SendField],
  }[target];

  const watchFields = watch(Fields.map(({ name }) => name));

  useEffect(() => {
    Tracker.pageView('contact create view');
    ReactTooltip.rebuild();
  }, []);

  const labelStyles = ({ dirty }: { dirty: boolean }) =>
    `${classnames({
      hidden: !dirty,
    })} absolute left-4 top-2 text-primary z-[1]`;

  const inputStyles = ({ dirty }: { dirty: boolean }) =>
    `${classnames({
      'py-18px': !dirty,
      'pt-6': dirty,
      'pb-2': dirty,
    })} w-full px-4 mb-6 rounded-lg border-none placeholder-gray-medium bg-background`;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <div className="flex flex-col items-center">
          <div className="m-6 mb-8 min-h-80px">
            <HeaderImage text={watchFields?.name} />
          </div>
          {Fields.filter(
            ({ visible }) => visible !== 'organization' || organization
          ).map(({ name, label, placeholder, type, required, info }) => (
            <label key={name} className="relative w-full font-normal">
              <small className={labelStyles({ dirty: !!watchFields[name] })}>
                {t(`${label}`)}
              </small>
              <div className="relative">
                <input
                  ref={register}
                  className={inputStyles({ dirty: !!watchFields[name] })}
                  type={type}
                  name={name}
                  placeholder={t(`${placeholder}`, {
                    name: organization?.name,
                  })}
                  required={required}
                />
                {info && (
                  <div
                    data-tip={t(`${info}`)}
                    className="absolute top-5 right-4 text-gray-medium"
                  >
                    <Info />
                  </div>
                )}
              </div>
              {!required && (
                <span className="absolute bottom-0 right-0 text-gray-medium">
                  {t('invite__contact_form_optional')}
                </span>
              )}
            </label>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button>{t('invite__contact_form_submit_button')}</Button>
      </ModalFooter>
    </form>
  );
};

const HeaderImage = ({ text }: { text: string }) =>
  text ? (
    <div className="flex items-center justify-center text-4xl font-medium uppercase rounded-full h-80px w-80px bg-blue-light text-primary">
      {text.charAt(0)}
    </div>
  ) : (
    <ProfileContact />
  );

export default ContactForm;
