export default () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.2 5.75C31.4073 5.75 29.15 7.99241 29.15 10.7727C29.15 13.553 31.4073 15.7955 34.2 15.7955C36.9927 15.7955 39.25 13.553 39.25 10.7727C39.25 7.99241 36.9927 5.75 34.2 5.75ZM13.4 8.93182C9.72731 8.93182 6.75 11.9091 6.75 15.5818V34.6C6.75 38.2727 9.72731 41.25 13.4 41.25H32.5984C36.272 41.25 39.25 38.272 39.25 34.5984V16.6028L38.8494 16.905C37.9481 17.5849 36.8941 18.0926 35.7506 18.3231L35.55 18.3635V33.8182C35.55 35.8892 33.8711 37.5682 31.8 37.5682H14.2C12.1289 37.5682 10.45 35.8892 10.45 33.8182V16.3636C10.45 14.2926 12.1289 12.6136 14.2 12.6136H26.6615L26.6057 12.3173C26.5118 11.8195 26.45 11.2945 26.45 10.7727C26.45 10.251 26.5118 9.72598 26.6057 9.22814L26.6615 8.93182H13.4Z"
      fill="#42CECE"
    />
  </svg>
);
