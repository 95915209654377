import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import ProfileContact from 'containers/Invitations/img/ProfileContact';
import Button from 'components/Button';
import { SendType } from 'containers/Invitations/Invitations';

export interface Props {
  target?: 'patient' | 'professional';
  onClose: () => void;
  onConfirm: (send: SendType) => void;
}

const CreateContactPreview: FC<Props> = ({
  target = 'patient',
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} width="narrow">
      {({ onClose: onCloseModal }: any) => (
        <>
          <Modal.Header onClose={onCloseModal}>
            {t('invite__create_preview_title')}
          </Modal.Header>
          <Modal.Body className="max-w-md mx-auto space-y-6 text-center">
            <div className="grid m-6 mb-8 min-h-80px place-items-center">
              <ProfileContact />
            </div>
            <h5 className="text-2xl font-medium text-primary">
              {t(
                {
                  patient: 'invite__create_preview_header_patient',
                  professional: 'invite__create_preview_header_professional',
                }[target]
              )}
            </h5>
            <p className="text-gray-medium">
              {t(
                {
                  patient: 'invite__create_preview_content_patient',
                  professional: 'invite__create_preview_content_professional',
                }[target]
              )}
            </p>
          </Modal.Body>
          <div className="flex justify-center pb-12 space-x-4">
            <Button onClick={() => onConfirm('sms')}>
              {t('invite__create_preview_sms_button')}
            </Button>
            <Button onClick={() => onConfirm('email')}>
              {t('invite__create_preview_email_button')}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default CreateContactPreview;
