import { useSelector } from 'react-redux';
import { getProfile } from 'state/profile/index.selectors';

import { getFileUrl, getImageUrl, getMeta } from 'utils/getAttachmentUrl';

const AttachmentItem = ({ attachment }) => {
  const meta = getMeta(attachment);
  const profile = useSelector(getProfile);

  if (attachment.type === 'image') {
    return (
      <a
        href={getImageUrl({ message: attachment, profile })}
        target="_blank"
        rel="noreferrer"
        className="mr-2 text-primary hover:text-primary-dark hover:underline"
      >
        <img
          loading="lazy"
          src={getImageUrl({ message: attachment, profile })}
        />
      </a>
    );
  }

  return (
    <div classame="mb-2">
      <a
        href={getFileUrl({ message: attachment, profile })}
        target="_blank"
        rel="noreferrer"
        className="mr-2 text-primary hover:text-primary-dark hover:underline"
      >
        <div>{meta.fileName}</div>
      </a>
    </div>
  );
};

export default AttachmentItem;
