import Caret from 'components/icons/outline/Caret';

export const AccordeonBadge = ({ badge = 0, empty = false }) => {
  if (badge < 1 && !empty) {
    return null;
  }

  return (
    <div className="flex items-center justify-center h-4 px-1 mr-4 font-medium rounded min-w-16px py-2px bg-secundary text-primary text-2xs">
      {badge}
    </div>
  );
};

const AccordeonHandler = ({
  children,
  onOpen,
  badge = null,
  empty = false,
  icon = null,
}) => (
  <div
    onClick={onOpen}
    className="flex items-center justify-between p-4 border-b cursor-pointer border-separators"
  >
    <div className="font-medium text-md text-gray-dark">{children}</div>
    <div className="flex items-center">
      {icon ? icon : <AccordeonBadge badge={badge} empty={empty} />}
      <button onClick={onOpen} className="w-3 h-3 focus:outline-none">
        <Caret className="text-gray-medium w-[10px]" />
      </button>
    </div>
  </div>
);

export default AccordeonHandler;
