const Clip = (props: any) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.5726 10.5858L11.4409 18.7175C9.87817 20.2802 7.34673 20.2802 5.78402 18.7175C4.22132 17.1548 4.22132 14.6234 5.78402 13.0607L14.6229 4.22185C15.5987 3.24604 17.1826 3.24604 18.1584 4.22185C19.1342 5.19766 19.1342 6.78158 18.1584 7.75738L10.7338 15.182C10.3449 15.5709 9.70847 15.5709 9.31956 15.182C8.93065 14.7931 8.93065 14.1567 9.31956 13.7678L16.0371 7.05028L14.9764 5.98962L8.2589 12.7071C7.28309 13.6829 7.28309 15.2669 8.2589 16.2427C9.2347 17.2185 10.8186 17.2185 11.7944 16.2427L19.2191 8.81804C20.7818 7.25534 20.7818 4.7239 19.2191 3.16119C17.6563 1.59848 15.1249 1.59848 13.5622 3.16119L4.72336 12C2.57376 14.1496 2.57376 17.6286 4.72336 19.7782C6.87297 21.9278 10.3519 21.9278 12.5015 19.7782L20.6333 11.6465L19.5726 10.5858Z"
      fill="currentColor"
    />
  </svg>
);
export default Clip;
