import { toast } from 'react-toastify';

import history from 'utils/history';

import MarkRoomAsUnread from 'containers/Inbox/toasts/MarkRoomAsUnread';
import ArchiveRoom from 'containers/Inbox/toasts/ArchiveRoom';
import {
  fetchingArchivedRoomsList,
  fetchingUnreadRoomsList,
  getSummaryRoomsList,
} from 'containers/Inbox/actions/GetRoomList.actions';
import MarkRoomAsRead from 'containers/Inbox/toasts/MarkRoomAsRead';
import UnArchiveRoom from 'containers/Inbox/toasts/UnArchiveRoom';
import api from 'api/index';
import moment from 'moment';
import Tracker from 'utils/Tracking';

const PIN_ROOM = 'INBOX/PIN_ROOM';
const UNPIN_ROOM = 'INBOX/UNPIN_ROOM';
const MARK_ROOMS_AS_UNREAD = 'INBOX/MARK_ROOM_AS_UNREAD';
const MARK_ROOMS_AS_READ = 'INBOX/MARK_ROOM_AS_READ';
const REOPEN_ROOMS = 'INBOX/REOPEN_ROOMS';
const REMOVE_ARCHIVED_ROOMS = 'INBOX/REMOVE_ARCHIVED_ROOMS';
const TRACKING_MESSAGE_SCHEDULED = 'INBOX/TRACKING_MESSAGE_SCHEDULED';

export const actionTypes = {
  PIN_ROOM,
  UNPIN_ROOM,
  MARK_ROOMS_AS_UNREAD,
  MARK_ROOMS_AS_READ,
  REOPEN_ROOMS,
  REMOVE_ARCHIVED_ROOMS,
  TRACKING_MESSAGE_SCHEDULED,
};

export const markRoomsAsRead = (selectedRooms) => {
  return {
    type: MARK_ROOMS_AS_READ,
    payload: selectedRooms,
  };
};

export const markRoomsAsUnread = (selectedRooms) => {
  return {
    type: MARK_ROOMS_AS_UNREAD,
    payload: selectedRooms,
  };
};

export const pinRoom = ({ roomId, pinned_at }) => {
  return {
    type: PIN_ROOM,
    payload: { roomId, pinned_at },
  };
};

export const unpinRoom = ({ roomId }) => {
  return {
    type: UNPIN_ROOM,
    payload: { roomId },
  };
};

export const reopenRooms = (selectedRooms) => {
  return {
    type: REOPEN_ROOMS,
    payload: selectedRooms,
  };
};

export const pin =
  ({ roomId, hash }) =>
  (dispatch) => {
    Tracker.event('pin room button click', {
      event_room_id: roomId,
      user_hash: hash,
    });
    api.rooms.pin({ roomId });
    dispatch(pinRoom({ roomId, pinned_at: moment() }));
  };

export const unpin =
  ({ roomId, hash }) =>
  (dispatch) => {
    Tracker.event('unpin room button click', {
      event_room_id: roomId,
      user_hash: hash,
    });
    api.rooms.unpin({ roomId });
    dispatch(unpinRoom({ roomId }));
  };

export const unreadRooms = (selectedRooms) => (dispatch) => {
  dispatch(fetchingUnreadRoomsList());
  api.rooms
    .unread({ roomIds: selectedRooms })
    .then(() => dispatch(getSummaryRoomsList()));
  toast(<MarkRoomAsUnread rooms={selectedRooms} />);
  dispatch(getSummaryRoomsList());
  dispatch(markRoomsAsUnread(selectedRooms));
};

export const readRooms = (selectedRooms) => (dispatch) => {
  dispatch(fetchingUnreadRoomsList());
  api.rooms
    .read({ roomIds: selectedRooms })
    .then(() => dispatch(getSummaryRoomsList()));
  toast(<MarkRoomAsRead rooms={selectedRooms} />);
  dispatch(markRoomsAsRead(selectedRooms));
};

export const unarchiveRooms = (selectedRooms) => {
  return (dispatch) => {
    dispatch(fetchingArchivedRoomsList());
    api.rooms
      .reopen({ roomIds: selectedRooms })
      .then(() => dispatch(getSummaryRoomsList()));
    dispatch(getSummaryRoomsList());
    dispatch(removeArchivedRooms(selectedRooms));
    history.push('/chat');
    toast(<UnArchiveRoom rooms={selectedRooms} />);
  };
};

export const archiveRooms = (selectedRooms) => {
  return (dispatch) => {
    dispatch(fetchingArchivedRoomsList());
    api.rooms
      .archive({ roomIds: selectedRooms })
      .then(() => dispatch(getSummaryRoomsList()));
    dispatch(removeArchivedRooms(selectedRooms));
    history.push('/chat');
    toast(<ArchiveRoom rooms={selectedRooms} />);
  };
};

export const removeArchivedRooms = (chats) => {
  return {
    type: REMOVE_ARCHIVED_ROOMS,
    payload: chats,
  };
};

export const trackingMessageScheduled = (chats) => {
  return {
    type: TRACKING_MESSAGE_SCHEDULED,
    payload: chats,
  };
};
