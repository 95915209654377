import { changeScreen } from 'containers/DetailBar/PatientBar/actions/MedicalHistory.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentDraftReport } from 'state/medicalReports/index.selectors';
import {
  addMedicalReport,
  updateMedicalReport,
  deleteMedicalReport,
} from 'state/medicalReports/index.slice';

const useMedicalReport = () => {
  const dispatch = useDispatch();
  const currentDraft = useSelector(getCurrentDraftReport);

  const handleChangeScreen = (screen: string) => {
    dispatch(changeScreen(screen));
  };

  const saveDraft = (draft: any) => {
    dispatch(addMedicalReport(draft));
  };

  const editDraft = (draft: any) => {
    dispatch(updateMedicalReport(draft));
  };

  const deleteDraft = (draft: any) => {
    dispatch(deleteMedicalReport(draft));
  };

  return {
    currentDraft,

    handleChangeScreen,
    saveDraft,
    editDraft,
    deleteDraft,
  };
};
export default useMedicalReport;
