import { FC, ReactNode } from 'react';

export interface Props {
  icon?: ReactNode;
}

const InfoToast: FC<Props> = ({ icon, children }) => {
  return (
    <div className="flex justify-center space-x-3">
      {icon}
      <div>{children}</div>
    </div>
  );
};

export default InfoToast;
