import React from 'react';

const useAccordeon = () => {
  const [selected, setSelected] = React.useState(null);

  const isOpen = (id) => selected === id;
  const open = (id) => setSelected(id);
  const close = () => setSelected(null);

  return { isOpen, open, close };
};

export default useAccordeon;
