export default ({ props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM13.5 9C14.33 9 15 8.33 15 7.5C15 6.67 14.33 6 13.5 6C12.67 6 12 6.67 12 7.5C12 8.33 12.67 9 13.5 9ZM6.5 9C7.33 9 8 8.33 8 7.5C8 6.67 7.33 6 6.5 6C5.67 6 5 6.67 5 7.5C5 8.33 5.67 9 6.5 9Z"
      fill="currentColor"
    />
    <path
      d="M14 12C14.5523 12 14.9893 12.4547 14.8068 12.976C14.3612 14.2485 13.0564 16 10 16C6.9436 16 5.63875 14.2485 5.1932 12.976C5.01069 12.4547 5.44772 12 6 12H14Z"
      fill="currentColor"
    />
  </svg>
);
