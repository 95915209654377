import React from 'react';

function PaymentAlert(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      fill="none"
      viewBox="0 0 56 56"
      {...props}
    >
      <mask
        id="mask0"
        width="56"
        height="56"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <rect width="56" height="56" fill="#F7F8FA" rx="28"></rect>
      </mask>
      <g mask="url(#mask0)">
        <rect width="56" height="56" fill="#ECEEFD" rx="28"></rect>
        <rect width="24" height="41" x="16" y="9" fill="#201552" rx="3"></rect>
        <circle cx="28" cy="48" r="1" fill="#F7F8FA"></circle>
        <rect width="22" height="35" x="17" y="11" fill="#fff" rx="2"></rect>
        <path
          fill="#4A1EA7"
          d="M27.314 19.402a.786.786 0 011.372 0l.7 1.25a.786.786 0 001.088.293l1.23-.734a.786.786 0 011.189.686l-.02 1.433a.786.786 0 00.797.797l1.433-.02c.614-.008 1 .66.686 1.188l-.733 1.231a.786.786 0 00.291 1.088l1.25.7a.786.786 0 010 1.372l-1.25.7a.786.786 0 00-.291 1.088l.733 1.23a.786.786 0 01-.686 1.189l-1.433-.02a.786.786 0 00-.797.797l.02 1.433c.008.614-.66 1-1.188.686l-1.231-.733a.786.786 0 00-1.088.291l-.7 1.25a.786.786 0 01-1.372 0l-.7-1.25a.786.786 0 00-1.088-.291l-1.23.733a.786.786 0 01-1.189-.686l.02-1.433a.786.786 0 00-.797-.797l-1.433.02a.786.786 0 01-.686-1.188l.734-1.231a.786.786 0 00-.292-1.088l-1.25-.7a.786.786 0 010-1.372l1.25-.7a.786.786 0 00.292-1.088l-.734-1.23a.786.786 0 01.686-1.189l1.433.02a.786.786 0 00.797-.797l-.02-1.433a.786.786 0 011.188-.686l1.231.734a.786.786 0 001.088-.292l.7-1.25z"
        ></path>
        <path
          fill="#fff"
          d="M28 24.824c.35 0 .636.286.636.635V28c0 .35-.286.636-.636.636a.637.637 0 01-.635-.636V25.46c0-.35.286-.635.636-.635zm.636 6.353h-1.27v-1.27h1.27v1.27z"
        ></path>
        <path
          fill="#FED5E1"
          d="M12.012 36.837l2.76-8.622a.424.424 0 01.722-.15 10.178 10.178 0 012.44 7.964L17.5 39.5v3.333c0 .099.066.185.161.211a8.75 8.75 0 016.312 6.904L25.5 58.5 13 60l-1.67-16.695c-.217-2.18.015-4.382.682-6.468z"
        ></path>
        <rect
          width="4"
          height="6.506"
          x="41.23"
          y="25.856"
          fill="#FED5E1"
          rx="2"
          transform="rotate(40.554 41.23 25.856)"
        ></rect>
        <rect
          width="4"
          height="6.506"
          x="41.23"
          y="29.856"
          fill="#FED5E1"
          rx="2"
          transform="rotate(40.554 41.23 29.856)"
        ></rect>
        <rect
          width="4"
          height="6.506"
          x="41.23"
          y="34.856"
          fill="#FED5E1"
          rx="2"
          transform="rotate(40.554 41.23 34.856)"
        ></rect>
        <rect
          width="4"
          height="6.506"
          x="41.23"
          y="39.856"
          fill="#FED5E1"
          rx="2"
          transform="rotate(40.554 41.23 39.856)"
        ></rect>
      </g>
    </svg>
  );
}

export default PaymentAlert;
