import es from 'i18n/locales/es.json';
import en from 'i18n/locales/en.json';
import pt from 'i18n/locales/pt.json';
import de from 'i18n/locales/de.json';

export const locales = {
  es: {
    translation: es,
  },
  en: {
    translation: en,
  },
  pt: {
    translation: pt,
  },
  de: {
    translation: de,
  },
};
