import { FC } from 'react';
import classnames from 'classnames';

export interface Props {
  className?: string;
}

export interface TabNavigationItemProps {
  name: string;
  activeTab?: string;
  setActiveTab?: (id: string) => void;
  className?: string;
  isActive?: boolean;
  id: string;
}

const TabNavigation: FC<Props> = ({ children, className }) => (
  <div
    className={classnames(
      'flex-1 flex flex-col inset-0 px-[17px] bg-backrgound h-5/6 w-full',
      className
    )}
  >
    {children}
  </div>
);

const TabNavigationItem: FC<TabNavigationItemProps> = ({
  setActiveTab,
  activeTab,
  name,
  className,
  id,
  isActive = false,
}) => {
  return (
    <button
      className={classnames(
        'inset-0 pb-3 text-gray-medium capitalize whitespace-nowrap shrink-0',
        {
          'font-medium border-b-2 border-primary rounded-sm text-primary':
            (activeTab && [name].includes(activeTab)) || isActive,
        },
        className
      )}
      onClick={() => {
        setActiveTab && setActiveTab(id);
      }}
    >
      {isActive ? name : name}
    </button>
  );
};
const TabNavigationMenu: FC<Props> = ({ children, className }) => (
  <div
    className={classnames(
      'flex flex-row whitespace-nowrap  gap-6 tracking-sm border-b border-b-blue-light text-gray-medium max-w-5/6 overflow-x-auto scrollbar-hide',
      className
    )}
  >
    {children}
  </div>
);

export { TabNavigation, TabNavigationItem, TabNavigationMenu };
