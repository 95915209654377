export default (props) => (
  <svg width="14" height="14" viewBox="0 0 19 18" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.41424 9.99995L10.7071 16.2928L9.29292 17.7071L0.585815 8.99995L9.29292 0.292847L10.7071 1.70706L4.41424 7.99995H19V9.99995H4.41424Z"
      fill="currentColor"
    />
  </svg>
);
