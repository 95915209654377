const PauseCircle = (props: any) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_451_9931)">
      <path
        d="M9.98379 16.9621C10.6438 16.9621 11.1838 16.4221 11.1838 15.7621V8.56211C11.1838 7.90211 10.6438 7.36211 9.98379 7.36211C9.32379 7.36211 8.78379 7.90211 8.78379 8.56211V15.7621C8.78379 16.4221 9.32379 16.9621 9.98379 16.9621ZM12.3838 0.162109C5.75979 0.162109 0.383789 5.53811 0.383789 12.1621C0.383789 18.7861 5.75979 24.1621 12.3838 24.1621C19.0078 24.1621 24.3838 18.7861 24.3838 12.1621C24.3838 5.53811 19.0078 0.162109 12.3838 0.162109ZM12.3838 21.7621C7.09179 21.7621 2.78379 17.4541 2.78379 12.1621C2.78379 6.87011 7.09179 2.56211 12.3838 2.56211C17.6758 2.56211 21.9838 6.87011 21.9838 12.1621C21.9838 17.4541 17.6758 21.7621 12.3838 21.7621ZM14.7838 16.9621C15.4438 16.9621 15.9838 16.4221 15.9838 15.7621V8.56211C15.9838 7.90211 15.4438 7.36211 14.7838 7.36211C14.1238 7.36211 13.5838 7.90211 13.5838 8.56211V15.7621C13.5838 16.4221 14.1238 16.9621 14.7838 16.9621Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_451_9931">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.383789 0.162109)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default PauseCircle;
