import Confirmation from 'components/Modal/Confirmation';
import { usePortal } from 'containers/Shared/components/Portal';
import { useTranslation } from 'react-i18next';
import useBeforeUnloadPrompt from 'utils/useBeforeUnloadPrompt';

interface Props {
  when: boolean;
  roomId?: number;
}

const useConfirm = ({ when, roomId }: Props) => {
  const portal = usePortal();
  const { t } = useTranslation();

  useBeforeUnloadPrompt({ when });
  const confirm = () =>
    new Promise((resolve, reject) =>
      !when
        ? resolve(true)
        : portal.open(
            <Confirmation
              title={t('shared__modal_confirmation_title')}
              content={t('shared__modal_confirmation_content')}
              cancelText={t('shared__modal_confirmation_cancel')}
              confirmText={t('shared__modal_confirmation_confirm')}
              onCancel={() => {
                portal.close('confirmation');
                reject(false);
              }}
              onConfirm={() => {
                portal.close('confirmation');
                resolve(true);
              }}
            />,
            'confirmation'
          )
    );

  return confirm;
};

export default useConfirm;
