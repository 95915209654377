import { actionTypes as roomTypes } from 'containers/Inbox/actions/GetRoomList.actions';
import { actionTypes as contactTypes } from 'containers/Contacts/actions/Contact.actions';
import { actionTypes as roomListTypes } from 'containers/Inbox/actions/RoomList.actions';
import { actionTypes as optionTypes } from 'containers/Inbox/actions/RoomListOptions.actions';
import { actionTypes as getRoomTypes } from 'containers/Inbox/actions/GetRoom.actions';
import { actionTypes as typingTypes } from 'containers/Room/actions/Typing.actions';
import { actionTypes as medicalHistoryTypes } from 'containers/DetailBar/PatientBar/actions/MedicalHistory.actions';
import { MESSAGE_SENT } from 'containers/Room/actions/SendMessage.actions';
import { FilterTypes } from 'containers/Shared/filters';
import { actionTypes as messageTypes } from 'containers/Room/actions/GetMessages.actions';
import { actionTypes } from 'containers/Room/actions/NewMessage.actions';
import { actionTypes as deleteMessageTypes } from 'containers/Room/actions/DeleteMessage.actions';

export const InboxMode = {
  searched: 'SEARCHED',
  ...FilterTypes,
};

const initialState = {
  isLoading: true,
  isMoreLoading: false,
  isLoadingRoomsSummary: false,
  isLoadingArchivedRooms: false,
  isLoadingUnreadRooms: false,
  isLoadingUntrackedRooms: false,
  mode: InboxMode.open,
  highlightedString: '',
  searchedTerm: '',
  rooms: [],
  roomsSummary: {},
  selectedRoomsIds: [],
  meta: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case roomTypes.FETCHING_ROOMS_LIST:
      return {
        ...state,
        isLoading: true,
        mode: action.payload.mode,
        searchedTerm: action.payload.term,
      };
    case roomTypes.FETCHING_MORE_ROOMS_LIST:
      return {
        ...state,
        isMoreLoading: true,
      };
    case roomTypes.FETCHING_SUMMARY_ROOMS_LIST:
      return {
        ...state,
        isLoadingRoomsSummary: true,
      };
    case roomTypes.FETCHING_ARCHIVED_ROOMS_LIST:
      return {
        ...state,
        isLoadingArchivedRooms: true,
      };
    case roomTypes.FETCHING_UNREAD_ROOMS_LIST:
      return {
        ...state,
        isLoadingUnreadRooms: true,
      };
    case roomTypes.FETCHING_UNTRACKED_ROOMS_LIST:
      return {
        ...state,
        isLoadingUnTrackedRooms: true,
      };
    case roomTypes.ROOMS_LIST_SUMMARY_FETCHED:
      if (state.mode === InboxMode.unread && state.rooms.length === 1) {
        return {
          ...state,
          isLoadingUnreadRooms: false,
        };
      }
      return {
        ...state,
        isLoadingRoomsSummary: false,
        isLoadingArchivedRooms: false,
        isLoadingUnreadRooms: false,
        isLoadingUnTrackedRooms: false,
        roomsSummary: action.payload.summary,
      };
    case roomTypes.ROOMS_LIST_SEARCHED:
      const isSearchedModeLoading =
        state.isLoading && state.mode === InboxMode.searched;
      const isOutdatedSearchedTerm = action.payload.term !== state.searchedTerm;

      const isOutdatedSearchedRooms = !isSearchedModeLoading;
      if (isOutdatedSearchedRooms || isOutdatedSearchedTerm) {
        return state;
      }

      return {
        ...state,
        isLoading: false,
        isMoreLoading: false,
        mode: InboxMode.searched,
        highlightedString: action.payload.term,
        rooms: action.payload.rooms,
        selectedRoomsIds: [],
        meta: action.payload.meta,
      };
    case roomTypes.ROOM_ADDED:
      return {
        ...state,
        isLoading: false,
        isMoreLoading: false,
        highlightedString: '',
        rooms: [action.payload.room, ...state.rooms].filter(
          (room, index, self) =>
            index === self.findIndex((t) => t.room_id === room.room_id)
        ),
      };
    case roomTypes.ROOMS_LIST_FILTERED:
      const isSameFilterModeLoading =
        state.isLoading && state.mode === action.payload.mode;
      const isSameMoreLoading =
        state.isMoreLoading && action.payload.mode === undefined;

      const isOutdatedFilteredRooms =
        !isSameFilterModeLoading && !isSameMoreLoading;
      if (isOutdatedFilteredRooms) {
        return state;
      }

      const isAdd = action.payload.meta.current_page !== 1;
      const rooms = isAdd
        ? [...action.payload.rooms, ...state.rooms]
        : action.payload.rooms;
      const uniqueRooms = rooms.filter(
        (room, index, self) =>
          index === self.findIndex((t) => t.room_id === room.room_id)
      );
      return {
        ...state,
        isLoading: false,
        isMoreLoading: false,
        highlightedString: '',
        rooms: uniqueRooms,
        meta: action.payload.meta,
      };
    case optionTypes.REMOVE_ARCHIVED_ROOMS:
      return {
        ...state,
        rooms: state.rooms.filter(
          (room) => !action.payload.includes(room.room_id)
        ),
        selectedRoomsIds: state.selectedRoomsIds.filter(
          (selectedRoomId) => !action.payload.includes(selectedRoomId)
        ),
      };
    case getRoomTypes.CLEAR_ROOM:
      if (state.mode === InboxMode.unread) {
        return {
          ...state,
          rooms: state.rooms
            .filter(
              (room) =>
                action.payload.room_id === room.room_id ||
                (action.payload.room_id !== room.room_id &&
                  (room.need_to_read || room.pendingMessages > 0))
            )
            .map((room) => {
              if (action.payload.room_id === room.room_id) {
                return {
                  ...room,
                  need_to_read: false,
                  pendingMessages: 0,
                };
              }
              return room;
            }),
          selectedRoomsIds: [],
        };
      }
      return {
        ...state,
        rooms: state.rooms.map((room) => {
          if (action.payload.room_id === room.room_id) {
            return {
              ...room,
              need_to_read: false,
              pendingMessages: 0,
            };
          }
          return room;
        }),
      };
    case typingTypes.TYPING:
      return {
        ...state,
        rooms: state.rooms.map((room) => {
          if (action.payload === room.room_id) {
            return {
              ...room,
              typing: true,
            };
          }
          return room;
        }),
      };
    case typingTypes.STOP_TYPING:
      return {
        ...state,
        rooms: state.rooms.map((room) => {
          if (action.payload === room.room_id) {
            return {
              ...room,
              typing: false,
            };
          }
          return room;
        }),
      };
    case contactTypes.CONTACT_NAME_UPDATED:
      return {
        ...state,
        rooms: state.rooms.map((room) => {
          if (action.payload.room_id === room.room_id) {
            return {
              ...room,
              title: action.payload.name,
            };
          }
          return room;
        }),
      };
    case optionTypes.MARK_ROOMS_AS_READ:
      return {
        ...state,
        rooms: state.rooms
          .filter(
            (room) =>
              state.mode !== InboxMode.unread ||
              !action.payload.includes(room.room_id)
          )
          .map((room) => {
            if (action.payload.includes(room.room_id)) {
              return {
                ...room,
                need_to_read: false,
              };
            }
            return room;
          }),
        selectedRoomsIds: state.selectedRoomsIds.filter(
          (selectedRoomId) => !action.payload.includes(selectedRoomId)
        ),
      };
    case optionTypes.PIN_ROOM:
      return {
        ...state,
        rooms: state.rooms.map((room) => {
          if (action.payload.roomId === room.room_id) {
            return {
              ...room,
              pinned_at: action.payload.pinned_at,
            };
          }
          return room;
        }),
      };
    case optionTypes.UNPIN_ROOM:
      return {
        ...state,
        rooms: state.rooms.map((room) => {
          if (action.payload.roomId === room.room_id) {
            return {
              ...room,
              pinned_at: null,
            };
          }
          return room;
        }),
      };
    case optionTypes.MARK_ROOMS_AS_UNREAD:
      return {
        ...state,
        rooms: state.rooms.map((room) => {
          if (action.payload.includes(room.room_id)) {
            return {
              ...room,
              need_to_read: true,
            };
          }
          return room;
        }),
      };
    case optionTypes.TRACKING_MESSAGE_SCHEDULED:
      return {
        ...state,
        rooms: state.rooms.map((room) => {
          if (action.payload.includes(room.room_id)) {
            return {
              ...room,
              total_tracking_messages: room.total_tracking_messages + 1,
            };
          }
          return room;
        }),
      };
    case medicalHistoryTypes.TRACKING_DELETED:
      return {
        ...state,
        rooms: state.rooms.map((room) => {
          if (action.payload === room.room_id) {
            return {
              ...room,
              total_tracking_messages: room.total_tracking_messages - 1,
            };
          }
          return room;
        }),
      };
    case MESSAGE_SENT:
    case actionTypes.MESSAGE_RECEIVED: {
      return {
        ...state,
        rooms: state.rooms.map((room) => {
          if (action.payload.roomId === room.room_id) {
            const isDuplicatedMessage =
              room?.last_message?.messageId === action.payload?.messageId;

            if (isDuplicatedMessage) {
              return room;
            }

            return {
              ...room,
              last_message: {
                ...action.payload,
                auto: 0,
                createdAt: action.payload.time,
              },
              total_tracking_messages:
                action.payload.auto === true
                  ? room.total_tracking_messages - 1
                  : room.total_tracking_messages,
              need_to_read:
                action.payload.fromUserHash !== room.meta?.hash
                  ? false
                  : room.need_to_read,
              pendingMessages:
                action.payload.fromUserHash === room.meta?.hash
                  ? room.pendingMessages + 1
                  : 0,
            };
          }
          return room;
        }),
      };
    }
    case messageTypes.MESSAGE_STATUS_CHANGED:
      return {
        ...state,
        rooms: state.rooms.map((room) => {
          if (
            action.payload.room_id === room.room_id &&
            action.payload.message_id === room.last_message.messageId
          ) {
            return {
              ...room,
              last_message: {
                ...room.last_message,
                status: action.payload.status,
              },
            };
          }
          return room;
        }),
      };
    case roomListTypes.CHECK_ROOM:
      return {
        ...state,
        selectedRoomsIds: [...state.selectedRoomsIds, action.payload],
      };
    case roomListTypes.UNCHECK_ROOM:
      return {
        ...state,
        selectedRoomsIds: state.selectedRoomsIds.filter(
          (roomId) => roomId !== action.payload
        ),
      };
    case roomListTypes.CHECK_ALL:
    case roomListTypes.CHECK_MULTIPLE_ROOMS:
      return {
        ...state,
        selectedRoomsIds: action.payload.map((room) => room.room_id),
      };
    case roomListTypes.UNCHECK_ALL:
      return {
        ...state,
        selectedRoomsIds: [],
      };
    case deleteMessageTypes.DELETE_MESSAGE:
      return {
        ...state,
        rooms: state.rooms.map((room) => {
          if (room.last_message?.messageId === action.payload.message_id) {
            if (room.last_message.deletedAt) return room;
            return {
              ...room,
              last_message: {
                ...room.last_message,
                type: 'string',
                string: '',
                deletedAt: new Date(),
              },
            };
          } else {
            return room;
          }
        }),
      };
    default:
      return state;
  }
};

export default reducer;
