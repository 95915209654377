import InfiniteScroll from 'react-infinite-scroll-component';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadedReversedMessages,
  LOADED_MESSAGES_CHUNK_SIZE,
} from 'containers/Room/reducers/console.reducer';
import { loadMoreMessages } from 'containers/Room/actions/GetMessages.actions';
import uuid from 'uuid/v4';

export const MESSAGES_SOURCE = {
  store: Symbol(),
  room: Symbol(),
};

const MessagesInfiniteScroll = ({
  room,
  messagesSource = MESSAGES_SOURCE.room,
  children,
}) => {
  const { scrollableId, messages, totalMessages, hasMore, loadMore } =
    useScrollMessages({
      messagesSource,
      room,
    });

  if (!scrollableId) {
    return <Wrapper />;
  }

  return (
    <Wrapper id={scrollableId}>
      <InfiniteScroll
        dataLength={messages?.length}
        next={loadMore}
        className="flex flex-col-reverse h-full px-4"
        inverse={true}
        hasMore={hasMore}
        pullDownToRefresh={false}
        scrollableTarget={scrollableId}
      >
        {children({ messages, totalMessages })}
      </InfiniteScroll>
    </Wrapper>
  );
};

const Wrapper = ({ id, children }) => (
  <div
    id={id}
    className="flex flex-col-reverse flex-grow max-h-full overflow-x-hidden overflow-y-scroll bg-background"
  >
    {children}
  </div>
);

const useScrollMessages = ({ messagesSource, room }) => {
  const scrollMessagesRoom = useScrollMessagesRoom({ room });
  const scrollMessagesStore = useScrollMessagesStore();

  switch (messagesSource) {
    case MESSAGES_SOURCE.room:
      return scrollMessagesRoom;
    case MESSAGES_SOURCE.store:
      return scrollMessagesStore;
    default:
      return scrollMessagesRoom;
  }
};

const useScrollMessagesStore = () => {
  const dispatch = useDispatch();
  const scrollableId = useUniqueId();
  const { messages, hasMore, totalMessages } = useSelector(
    loadedReversedMessages
  );

  const loadMore = () => {
    dispatch(loadMoreMessages());
  };

  return { scrollableId, messages, totalMessages, hasMore, loadMore };
};

const useScrollMessagesRoom = ({ room }) => {
  const scrollableId = useUniqueId();
  const [state, setState] = useState({
    messages: [],
    lastLoadedMessageIndex: 0,
    hasMore: false,
    totalMessages: [],
  });

  useEffect(() => {
    const lastLoadedMessageIndex = LOADED_MESSAGES_CHUNK_SIZE;
    const reversedMessages = room?.messages ? [...room.messages].reverse() : [];
    const messages = reversedMessages.slice(0, lastLoadedMessageIndex);
    const hasMore = messages.length < reversedMessages.length;
    setState({
      messages,
      lastLoadedMessageIndex,
      hasMore,
      totalMessages: reversedMessages,
    });
  }, [room]);

  const loadMore = () => {
    const lastLoadedMessageIndex =
      state.lastLoadedMessageIndex + LOADED_MESSAGES_CHUNK_SIZE;
    const messages = state.totalMessages.slice(0, lastLoadedMessageIndex);
    const hasMore = messages.length < state.totalMessages.length;
    setState((state) => ({
      ...state,
      messages,
      hasMore,
      lastLoadedMessageIndex,
    }));
  };

  return { ...state, scrollableId, loadMore };
};

const useUniqueId = () => {
  const [id, setId] = useState();

  useEffect(() => {
    setId(uuid());
  }, []);

  return id;
};

export default MessagesInfiniteScroll;
