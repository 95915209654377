import Button from 'components/Button';
import FlagSolid from 'components/icons/solid/FlagSolid';
import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';
export interface Props {
  handleConfirm: () => void;
  handleClose: () => void;
}

const NotPresentModal = ({ handleClose, handleConfirm }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={handleClose} width="narrow" className="rounded-xl">
      <>
        <Modal.Body>
          <div className="py-10 flex flex-col justify-center">
            <div className="flex flex-col justify-center m-auto w-full overflow-hidden rounded-lg max-w-sm">
              <div className="flex center items-center text-center p-3 rounded-[34px] bg-negative drop-shadow-lg w-20 h-20 my-2 m-auto">
                <FlagSolid className="text-white m-auto" />
              </div>
              <div className="relative w-full">
                <p className="text-primary text-2xl font-medium center text-center my-4 tracking-wide">
                  {t('appointments__modal_not_present_title')}
                </p>
                <p
                  className="center text-center"
                  dangerouslySetInnerHTML={{
                    __html: t('appointments__modal_not_present_text'),
                  }}
                />
              </div>
            </div>
            <div className="mx-auto max-w-xs flex flex-col items-center">
              <Button
                className="flex w-full mt-6 uppercase text-negative bg-negative-light hover:bg-negative hover:text-white rounded-xl py-4 px-6"
                onClick={handleConfirm}
              >
                {t('appointments__modal_not_present_button_confirm')}
              </Button>
              <Button
                variant="plain-primary"
                className="mt-2 text-primary uppercase flex w-full py-4 px-6 rounded-xl"
                onClick={handleClose}
              >
                {t('appointments__modal_not_present_button_cancel')}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </>
    </Modal>
  );
};

export default NotPresentModal;
