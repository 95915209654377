import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader } from 'containers/Shared/components/Modal/Modal';
import Trash from 'components/icons/Trash';
import ReactTooltip from 'react-tooltip';
import Api from 'api/index';
import { sentenceDeleted } from 'containers/Inbox/actions/Session.actions';
import useLazyQuery from 'utils/useLazyQuery';
import classnames from 'classnames';
import AddIcon from 'containers/Room/icons/AddIcon';
import CreateSentence from 'containers/Room/components/RoomInput/CreateSentence';
import PencilIcon from 'containers/Room/icons/PencilIcon';
import EditSentence from 'containers/Room/components/RoomInput/EditSentence';
import { toast } from 'react-toastify';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import Tracker from 'utils/Tracking';
import CRMTracker from 'utils/CRMTracker';
import { useTranslation } from 'react-i18next';

const SentencesPicker = ({ onClose, onSelect }) => {
  const { t } = useTranslation();
  const { sentences } = useSelector((state) => state.session);
  const [showCreate, setShowCreate] = useState(false);
  const [sentenceToEdit, setSentenceToEdit] = useState(null);
  const dispatch = useDispatch();
  const [ApiDeleteSentence, isDeleting] = useLazyQuery(Api.deleteSentence);

  useEffect(() => {
    Tracker.pageView('sentence list view');
  }, []);

  const closeAndSelect = (sentence) => {
    Tracker.event('sentence list selection', {
      event_message_content: sentence.string,
    });
    CRMTracker.event('PredefinedPhraseSelected');
    onSelect(sentence.string);
    onClose();
  };

  const deleteSentence = (sentence) =>
    ApiDeleteSentence(sentence.id)
      .then(() => dispatch(sentenceDeleted(sentence)))
      .catch(() =>
        toast.error(
          <ErrorToast>{t('sentence__list_toast_delete_error')}</ErrorToast>
        )
      );

  if (showCreate) {
    return (
      <CreateSentence
        onClose={() => setShowCreate(false)}
        onCloseWithSend={closeAndSelect}
      />
    );
  }

  if (sentenceToEdit) {
    return (
      <EditSentence
        sentenceToEdit={sentenceToEdit}
        onClose={() => setSentenceToEdit(null)}
      />
    );
  }

  const dismissModal = () => {
    Tracker.event('sentence list dismiss');
    onClose();
  };

  return (
    <Modal onClose={dismissModal}>
      <ModalHeader onClose={dismissModal}>
        <div className="flex items-center justify-between w-full ">
          <span>{t('sentence__list_title')}</span>
          <button
            onClick={() => {
              Tracker.event('sentence list create');
              setShowCreate(true);
            }}
            className="flex items-center px-2 py-1 font-medium bg-white border rounded-lg shadow-sm text-md border-primary text-primary hover:text-primary-dark hover:bg-background focus:outline-none focus:border-primary-dark"
          >
            <AddIcon className="mr-2" />
            {t('sentence__list_add_new')}
          </button>
        </div>
        <div className="w-px h-6 mx-3 rounded-lg bg-separators"></div>
      </ModalHeader>
      <div className="py-6 bg-white">
        <div className="px-6 mb-5 text-xs text-primary">
          {t('sentence__list_subtitle')}
        </div>
        <div className="mb-4 overflow-y-scroll max-h-[360px]">
          {sentences.map((sentence) => (
            <Sentence
              disabled={isDeleting}
              key={sentence.id}
              sentence={sentence}
              onSelect={() => closeAndSelect(sentence)}
              onDelete={() => deleteSentence(sentence)}
              onEdit={() => setSentenceToEdit(sentence)}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

const Sentence = ({ disabled, sentence, onSelect, onDelete, onEdit }) => {
  const { t } = useTranslation();
  const [mouseOver, setMouseOver] = useState(false);
  useTooltip({ open: mouseOver });

  const isBlocked = !!sentence?.is_blocked;
  const isLarge = sentence?.string?.length > 50;
  const disabledActions = disabled || isBlocked;
  const blockedLabel = t('sentence__list_option_delete_error');

  const handleOnEdit = (event) => {
    Tracker.event('sentence list edit');
    isValidAction(event) && onEdit && onEdit();
  };

  const handleOnDelete = (event) => {
    if (
      isValidAction(event) &&
      window.confirm(t('sentence__list_option_delete_confirm'))
    ) {
      Tracker.event('sentence list delete');
      onDelete && onDelete();
    }
  };

  const isValidAction = (event) => {
    event.stopPropagation();
    return !disabledActions;
  };

  return (
    <div
      key={sentence.id}
      role="button"
      onClick={() => !disabled && onSelect && onSelect()}
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      className="relative flex items-center w-full px-6 py-4 text-left hover:bg-gray-light focus:outline-none"
    >
      <div className="font-normal">{sentence.string}</div>
      {mouseOver && (
        <div
          className={`${classnames({
            'top-6': isLarge,
          })} absolute right-6 flex justify-center items-center bg-white text-gray-medium rounded-md shadow-md`}
        >
          <Button
            disabled={disabledActions}
            label={isBlocked ? blockedLabel : t('sentence__list_option_edit')}
            icon={<PencilIcon width="19" height="19" />}
            onClick={handleOnEdit}
          />
          <div className="w-px h-6 rounded-lg bg-separators" />
          <Button
            disabled={disabledActions}
            label={isBlocked ? blockedLabel : t('sentence__list_option_delete')}
            icon={<Trash width="16" height="19" />}
            onClick={handleOnDelete}
            rigth
          />
        </div>
      )}
    </div>
  );
};

const useTooltip = ({ open }) => {
  useEffect(() => {
    return () => ReactTooltip.hide();
  }, []);

  useEffect(() => {
    if (open) {
      ReactTooltip.rebuild();
    }
  }, [open]);
};

const Button = ({ disabled, label, icon, onClick, rigth }) => (
  <button
    data-tip={label}
    onClick={onClick}
    className={`${classnames({
      'rounded-tr-md rounded-br-md': rigth,
      'rounded-tl-md rounded-bl-md': !rigth,
      'cursor-not-allowed': disabled,
    })} w-8 h-8 flex justify-center items-center hover:bg-gray-dark hover:text-white cursor-pointer focus:outline-none`}
  >
    {icon}
  </button>
);

export default SentencesPicker;
