import { addMessage, removeMessage } from 'state/notification.slice';

export const onCallRequested = (dispatch: any) => {
  return (data: any) => {
    dispatch(addMessage({ id: data.call.uuid, content: data }));
  };
};

export const onCallRejected = (dispatch: any) => {
  return (data: any) => {
    dispatch(removeMessage(data.call.uuid));
  };
};

export const onCallPickedUp = (dispatch: any) => {
  return (data: any) => {
    dispatch(removeMessage(data.call.uuid));
  };
};
