import CaretIcon from 'components/icons/outline/Caret';
import { forwardRef, useState } from 'react';
import classnames from 'classnames';
import Form from 'components/Form';
import useQuery from 'utils/useQuery';
import api from 'api';
import { useTranslation } from 'react-i18next';

export type TimezoneExpandProps = JSX.IntrinsicElements['select'] & {
  displayValue: string;
};

const TimezoneExpand = forwardRef<any, TimezoneExpandProps>(
  ({ displayValue, ...props }, ref) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const {
      data: timezones,
      isLoading: isLoadingTimezones,
      error: errorTimezones,
    }: any = useQuery(api.getTimezones);

    const handleExpand = () => {
      setExpanded((prev) => !prev);
    };

    return (
      <>
        <button
          type="button"
          className="flex items-center justify-between px-1.5 py-2 w-full hover:bg-[#F8F7FA] transition-colors duration-300 rounded-lg text-[#6D658B]"
          onClick={handleExpand}
        >
          <span className="text-xs">
            {t('appointments__payment_form_your_timezone')} - {displayValue}
          </span>
          <CaretIcon
            className={classnames('h-3 w-3', expanded && 'rotate-180')}
          />
        </button>

        {!isLoadingTimezones && (
          <Form.Field
            ref={ref}
            className={classnames('mt-2', !expanded && 'hidden')}
            type="select"
            label={t(
              'profile__profile_form_professional_information_time_zone_label'
            )}
            options={timezones?.map(({ key, name }: any) => ({
              key,
              name,
            }))}
            errors={errorTimezones}
            {...props}
          />
        )}
      </>
    );
  }
);

export default TimezoneExpand;
