import { FC, useState } from 'react';
import classnames from 'classnames';

export interface Props {
  isLoading?: boolean;
  src: string;
  alt: string;
  badge?: 'online' | 'offline';
  classNameBorderBackground?: string;
  className?: string;
  classNameSize?: string;
  classNameRounded?: string;
  isSidebarOpen?: boolean;
}

type State = 'loading' | 'success' | 'error';

const ProfileImage: FC<Props> = ({
  src,
  alt,
  badge,
  isLoading,
  classNameBorderBackground = 'border-white',
  className = '',
  classNameSize = 'w-8 h-8',
  classNameRounded = 'rounded-full',
  isSidebarOpen = true,
}) => {
  const [state, setState] = useState<State>(src ? 'loading' : 'error');

  return (
    <>
      <div
        title={alt}
        className={classnames(
          'relative flex-shrink-0 flex items-center justify-center',
          {
            'animate-pulse': state === 'loading' || isLoading,
            'bg-gray-light': state !== 'error',
            'bg-blue-light': state === 'error',
          },
          classNameSize,
          classNameRounded
        )}
      >
        <img
          alt={alt}
          src={src}
          className={classnames(
            'w-full h-full object-cover transition-opacity shrink-0',
            {
              'sr-only': state === 'error',
              'opacity-0': state === 'loading' || isLoading,
              'opacity-100': state === 'success',
            },
            className,
            classNameRounded
          )}
          onLoad={() => setState('success')}
          onError={() => setState('error')}
        />
        {state === 'error' && alt && (
          <span className="font-medium text-primary">{alt.charAt(0)}</span>
        )}
      </div>
      {badge && (
        <span
          className={classnames(
            'absolute z-10 w-[7.5px] h-[7.5px] border box-content -translate-y-3 translate-x-3 inline-flex rounded-full',
            classNameBorderBackground,
            {
              'bg-online': badge === 'online',
              'bg-negative': badge === 'offline',
              'left-3': isSidebarOpen,
              'left-1.5': !isSidebarOpen,
            }
          )}
        />
      )}
    </>
  );
};

export default ProfileImage;
