import { config } from 'config';
import io from 'socket.io-client';

export let socket = undefined;

export const disconnectSocket = () => {
  socket?.disconnect();
  socket = undefined;
};

export const connectSocket = () => {
  const jwt = localStorage.getItem('jwtToken');

  if (!jwt) {
    return;
  }

  if (socket !== undefined) {
    return;
  }

  socket = io(`${config.services.mediquo.chat_server_url}`, {
    query: `jwt=${jwt}`,
    reconnection: true,
    reconnectionDelay: 5000,
    reconnectionDelayMax: 5000,
    transports: ['websocket'],
    upgrade: false,
    secure: true,
    forceNew: true,
  });
};
