import IndeterminateIcon from 'containers/Inbox/icons/indeterminate.svg';
import CheckboxIcon from 'containers/Inbox/icons/checkbox.svg';
import classNames from 'classnames';

const Checkbox = ({
  isChecked,
  isIndeterminate,
  onChange,
  className,
  isBlocked,
}) => {
  return (
    <button
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        onChange();
      }}
      className={classNames(
        `w-4 h-4 flex flex-shrink-0 items-center justify-center border border-gray-medium rounded focus:outline-none  ${
          isBlocked
            ? 'bg-gray-medium'
            : isChecked || isIndeterminate
            ? 'bg-primary border-none'
            : 'bg-white'
        } ${className}`
      )}
      disabled={isBlocked}
    >
      {isChecked && <img className="w-3 ml-px" src={CheckboxIcon} />}
      {isIndeterminate && !isChecked && (
        <img className="w-3" src={IndeterminateIcon} />
      )}
    </button>
  );
};

export default Checkbox;
