import api from 'api';
import { config } from 'config';

class ChatApi {
  constructor(client, url) {
    this.url = url; 
    this.mq_url = config.services.mediquo.api_url;
    this.client = client;
  }

  getProfile(hash) {
    return Promise.all([
      this.client.get(`professionals/v1/patient/${hash}/profile`),
      api.patients.profile.get({hash})
    ]).then(([
        {data: {data: chatResponse}},
        {data: {data: coreResponse}}
      ]) => ({
        ...chatResponse,
        billing: coreResponse.billing,
        dni: coreResponse.dni,
      })
    ).catch(async () => {
      const profile = await this.client.get(`professionals/v1/patient/${hash}/profile`);
      return profile.data.data;
    });
  }

  fetchRoom(roomId, signal) {
    return this.client
      .get(`professionals/v1/rooms/${roomId}`, { signal })
      .then((res) => res.data);
  }

  fetchRoomMessages(roomId) {
    return this.client
      .get(`api/v1/rooms/${roomId}/messages`)
      .then((res) => res.data);
  }

  async fetchRoomLastMessage(roomId) {
    // TODO: Change this implementation
    const res = await this.fetchRoomMessages(roomId);
    return res.data.at(-1);
  }

  unreadRoom(roomId) {
    return this.client
      .put(`api/v1/rooms/${roomId}/read`, { status: 1 })
      .then((res) => res.data);
  }

  acceptRoom(roomId) {
    return this.client
      .post(`professionals/v1/rooms/${roomId}/accept`)
      .then((res) => res.data);
  }

  rejectRoom(roomId, reason) {
    return this.client
      .post(`professionals/v1/rooms/${roomId}/reject`, { reason })
      .then((res) => res.data);
  }

  readMessagesOfRoom(roomId) {
    return this.client
      .post(`professionals/v1/rooms/${roomId}/messages/read`)
      .then((res) => res.data);
  }

  pickup(uuid) {
    return this.client.post(`professionals/v1/calls/${uuid}/pick-up`);
  }

  hangUpCall(uuid) {
    return this.client.post(`professionals/v1/calls/${uuid}/hang-up`);
  }

  rejectCall({ uuid, audio, video }) {
    return this.client.post(`professionals/v1/calls/${uuid}/reject`, {
      permissions: { audio, video },
    });
  }

  call(roomId) {
    return this.client.post(`professionals/v1/rooms/${roomId}/call`);
  }

  createPrescription(hash, request) {
    return this.client.post(
      `professionals/v1/patients/${hash}/prescriptions`,
      request
    );
  }

  downloadPrescription(uuid) {
    return this.client.get(`professionals/v1/prescriptions/${uuid}/download`, {
      responseType: 'blob',
    });
  }

  createPrescriptionProvider(request) {
    return this.client.post(`professionals/v1/prescription-provider`, request);
  }

  videoCall(roomId) {
    return this.client.post(`professionals/v1/rooms/${roomId}/video-call`);
  }
}

export default ChatApi;
