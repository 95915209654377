import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  noteCreated,
  noteDeleted,
} from 'containers/DetailBar/PatientBar/actions/MedicalHistory.actions';
import ConsultationsApiClient from 'legacy/Services/Consultations';

export const useNotes = (hash) => {
  const dispatch = useDispatch();
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    ConsultationsApiClient.notes
      .index({ customer_hash: hash })
      .then(({ data }) => setNotes(data));
  }, [hash]);

  const destroy = (id) => {
    return ConsultationsApiClient.notes.delete(id).then(() => {
      setNotes(notes.filter((n) => n.id !== id));
      dispatch(noteDeleted());
    });
  };

  const store = (message, hash) => {
    return ConsultationsApiClient.notes
      .store({ message, customer_hash: hash })
      .then(({ data }) => {
        setNotes([...notes, { ...data }]);
        dispatch(noteCreated());
      });
  };

  return [notes, store, destroy];
};
