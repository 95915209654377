import {
  useDropdown,
  DropdownMenu,
  DropdownHeader,
} from 'containers/Shared/components/Dropdown';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import Bell from 'components/icons/outline/Bell';
import BellCircle from 'components/icons/outline/BellCircle';
import api from 'api';
import Loading from 'components/Loading';
import { useHistory } from 'react-router';
import { timeAgoUnits } from 'utils/date';
import ProfileImage from 'components/ProfileImage';
import {
  readNotification,
  useGetAllNotificationsQuery,
} from 'services/notifications';
import { Notification } from 'api/Consultations';
import { useDispatch } from 'react-redux';
import Tracker from 'utils/Tracking';
import classNames from 'classnames';

const NotificationsButton: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const dropdown = useDropdown();
  const {
    data: notifications,
    isLoading,
    isError,
    refetch,
  } = useGetAllNotificationsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const toggleDropdown = () => {
    if (!dropdown.isOpen) {
      Tracker.event('notification bell button click');
      refetch();
    }

    dropdown.isOpen ? dropdown.close() : dropdown.open();
  };

  const handleOnClickNotification = (notification: Notification) => {
    Tracker.event('notification bell notification button click');

    dispatch(readNotification({ id: notification.id }));
    api.readNotification({ id: notification.id });

    dropdown.close();

    history.push(notification.deeplink_web);
  };

  const hasPending = notifications?.some(({ read_at }) => !read_at);
  const status = isLoading ? 'loading' : isError ? 'error' : 'success';

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className={classnames(
          'rounded-lg flex h-8 w-8 justify-center items-center bg-blue-light text-blue',
          { 'z-30': dropdown.isOpen }
        )}
      >
        {hasPending ? (
          <BellCircle className="w-6 h-6" />
        ) : (
          <Bell className="w-6 h-6" />
        )}
      </button>
      <DropdownMenu
        open={dropdown.isOpen}
        onClose={() => dropdown.close()}
        className={classNames('right-0 w-96', {
          'min-h-[100px]': notifications?.length,
        })}
      >
        <DropdownHeader>{t('header__notifications_title')}</DropdownHeader>
        {
          {
            loading: (
              <div className="absolute w-full top-1/2">
                <Loading />
              </div>
            ),
            error: (
              <div className="w-full px-4 py-2 text-left text-md text-negative">
                {t('shared__errors_generic_message')}
              </div>
            ),
            success: !notifications?.length ? (
              <div className="w-full px-4 py-2 text-md text-dark focus:outline-none">
                {t('header__notifications_empty')}
              </div>
            ) : (
              <div
                className="overflow-auto"
                style={{ maxHeight: 'calc(100vh - 120px)' }}
              >
                {notifications.map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    onClick={() => handleOnClickNotification(notification)}
                  />
                ))}
              </div>
            ),
          }[status]
        }
      </DropdownMenu>
    </div>
  );
};

const NotificationItem: FC<{
  notification: Notification;
  onClick: () => void;
}> = ({ notification: { read_at, created_at, icon_src, text }, onClick }) => {
  const parseDate = (date: string) => {
    const ago = timeAgoUnits(date, { unit: 'second', filter: 'min' }) as {
      value: number;
      unit: 'minute' | 'hour' | 'day' | 'month' | 'year' | 'second';
    };

    const unit = {
      second: 's',
      minute: 'min',
      hour: 'h',
      day: 'd',
      month: 'm',
      year: 'y',
    }[ago.unit];

    return `${ago!.value}${unit}`;
  };

  const isPending = !read_at;

  return (
    <button
      className={classnames(
        'px-4 min-h-min py-3 w-full text-left text-md text-dark focus:outline-none',
        'hover:bg-gradient-to-r hover:from-black/5 hover:to-black/5',
        { 'bg-blue-light bg-opacity-50': isPending }
      )}
      onClick={onClick}
    >
      <div className="flex gap-2 font-normal">
        <div className="grid flex-shrink-0 w-10 h-10 rounded-full place-items-center bg-blue-light">
          <ProfileImage src={icon_src} alt={text} />
        </div>
        <div>
          <p className="text-sm" dangerouslySetInnerHTML={{ __html: text }} />
          <span className="text-xs text-gray-dark">
            {parseDate(created_at)}
          </span>
        </div>
      </div>
    </button>
  );
};

export default NotificationsButton;
