import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  allergyCreated,
  allergyDeleted,
  unknownAllergyDeleted,
  unknownAllergyCreated,
} from 'containers/DetailBar/PatientBar/actions/MedicalHistory.actions';
import ConsultationsApiClient from 'legacy/Services/Consultations';
import CRMTracker from 'utils/CRMTracker';

export const useAllergies = (hash) => {
  const dispatch = useDispatch();
  const [allergies, setAllergies] = useState([]);

  useEffect(() => {
    ConsultationsApiClient.allergies
      .index({ customer_hash: hash })
      .then(({ data }) => setAllergies(data));
  }, [hash]);

  const destroy = (id) => {
    return ConsultationsApiClient.allergies.delete(id).then(() => {
      setAllergies(allergies.filter((n) => n.id !== id));
      dispatch(allergyDeleted());
    });
  };

  const store = (allergy, hash) => {
    if (allergies.length === 0) {
      removeUnknown(hash);
    }

    return ConsultationsApiClient.allergies
      .store({ ...allergy, customer_hash: hash })
      .then(({ data }) => {
        setAllergies([...allergies, { ...data }]);
        dispatch(allergyCreated());
        CRMTracker.event('AllergyAdded');
      });
  };

  const markAsUnknown = (hash) => {
    return ConsultationsApiClient.unknownMedicalHistory
      .createUnknownAllergy(hash)
      .then(() => dispatch(unknownAllergyCreated()));
  };

  const removeUnknown = (hash) => {
    return ConsultationsApiClient.unknownMedicalHistory
      .destroyUnknownAllergy(hash)
      .then(() => dispatch(unknownAllergyDeleted()));
  };

  return [allergies, store, destroy, markAsUnknown, removeUnknown];
};
