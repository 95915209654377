import classnames from 'classnames';

type ChipProps = JSX.IntrinsicElements['button'];

const Chip = ({ children, className, ...props }: ChipProps) => (
  <button
    className={classnames(
      'px-2.5 py-1 text-xs font-light bg-background rounded-lg inline-flex items-center gap-1',
      !props.disabled && 'hover:bg-blue-light hover:text-primary',
      className
    )}
    {...props}
  >
    {children}
  </button>
);

export default Chip;
