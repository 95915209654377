import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  diseaseCreated,
  diseaseDeleted,
  unknownDiseaseDeleted,
  unknownDiseaseCreated,
} from 'containers/DetailBar/PatientBar/actions/MedicalHistory.actions';
import ConsultationsApiClient from 'legacy/Services/Consultations';
import CRMTracker from 'utils/CRMTracker';

export const useDiseases = (hash) => {
  const dispatch = useDispatch();
  const [diseases, setDiseases] = useState([]);

  useEffect(() => {
    ConsultationsApiClient.diseases
      .index({ customer_hash: hash })
      .then(({ data }) => setDiseases(data));
  }, [hash]);

  const destroy = (id) => {
    return ConsultationsApiClient.diseases.delete(id).then(() => {
      setDiseases(diseases.filter((n) => n.id !== id));
      dispatch(diseaseDeleted());
    });
  };

  const store = (disease, hash) => {
    if (diseases.length === 0) {
      removeUnknown(hash);
    }

    return ConsultationsApiClient.diseases
      .store({ ...disease, customer_hash: hash })
      .then(({ data }) => {
        setDiseases([...diseases, { ...data }]);
        dispatch(diseaseCreated());
        CRMTracker.event('DiseaseAdded');
      });
  };

  const markAsUnknown = (hash) => {
    return ConsultationsApiClient.unknownMedicalHistory
      .createUnknownDisease(hash)
      .then(() => dispatch(unknownDiseaseCreated()));
  };

  const removeUnknown = (hash) => {
    return ConsultationsApiClient.unknownMedicalHistory
      .destroyUnknownDisease(hash)
      .then(() => dispatch(unknownDiseaseDeleted()));
  };

  return [diseases, store, destroy, markAsUnknown, removeUnknown];
};
