import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Chip from 'containers/DetailBar/PatientBar/components/PatientProfile/Chip';
import BookmarkIcon from 'containers/DetailBar/PatientBar/components/PatientProfile/icons/BookmarkIcon';
import { setCurrentOrganization } from 'state/organizationGuides.slice';
import useMedicalReport from 'containers/MedicalReport/useMedicalReport';
import { useGetOrganizationGuidesQuery } from 'services/organizationGuides';

interface Organization {
  id: string;
  name: string;
}

export interface OrganizationsProps {
  organizations: Organization[];
}

const OrganizationChip = ({ organization }: { organization: Organization }) => {
  const { handleChangeScreen } = useMedicalReport();
  const { data } = useGetOrganizationGuidesQuery({
    organization_id: organization.id,
  });
  const dispatch = useDispatch();
  const handleGoToOrganization = () => {
    dispatch(setCurrentOrganization(data));
    handleChangeScreen('organizationDetails');
  };

  const hasGuides = data?.service_guide !== null;

  return (
    <Chip
      disabled={!hasGuides}
      onClick={() => hasGuides && handleGoToOrganization()}
    >
      <BookmarkIcon className="w-4 h-4" />
      <span>{organization.name}</span>
    </Chip>
  );
};

const Organizations = ({ organizations }: OrganizationsProps) => {
  const { t } = useTranslation();

  return (
    <div className="mt-4">
      <div className="text-gray-dark font-medium uppercase text-[10px]">
        {t('sidebar__patient_profile_organizations')}
      </div>
      <div className="capitalize break-words flex flex-wrap mt-2 gap-1">
        {organizations.map((organization) => (
          <OrganizationChip key={organization.id} organization={organization} />
        ))}
      </div>
    </div>
  );
};

export default Organizations;
