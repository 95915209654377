import { useTranslation } from 'react-i18next';
import ExclamationCircle from 'components/icons/outline/ExclamationCircle';

const PatientBlockedModal = ({ show, onClose, room }) => {
  const { t } = useTranslation();

  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-x-0 bottom-0 z-10 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
      <div className="fixed inset-0 transition-opacity">
        <div
          onClick={onClose}
          className="absolute inset-0 opacity-75 bg-gray-medium"
        ></div>
      </div>

      <div className="max-w-xl p-10 overflow-hidden transition-all transform bg-white shadow-xl text-dark rounded-24 sm:w-full">
        <div className="flex flex-col items-center text-center">
          <div className="flex items-center justify-center w-20 h-20 mb-8 rounded-lg bg-negative-light text-negative">
            <ExclamationCircle className="text-negative" />
          </div>
          <div className="mb-6 text-2xl font-medium text-primary">
            {t('room__patient_blocked_modal_title')}
          </div>
          <div className="mb-12">{t('room__patient_blocked_modal_text')}</div>
          <a
            href={`mailto:soporte@mediquo.com?subject=Paciente%20bloqueado&body=Hola,%0D%0AEl paciente ${room.title} de la room ${room.room_id}, me aparece bloqueado.`}
            className="flex items-center h-12 px-6 font-medium text-white uppercase border rounded-lg bg-primary border-primary focus:outline-none hover:bg-primary-dark"
          >
            {t('room__patient_blocked_modal_link')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default PatientBlockedModal;
