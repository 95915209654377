import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

const API = 'professionals/v1';
const BASE_URL = config.services.mediquo.chat_url;

interface Diagnostic {
  term: string;
  lang?: string;
  page?: number;
}

const get = ({ term, lang, page }: Diagnostic) =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/${API}/diagnostics?term=${term}&lang=${lang}&page=${page}`,
    headers: getHeaders(),
  });

export default {
  get,
};
