import React from 'react';

export default ({ size = 19, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 19 19" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4077 2.9975C13.2082 2.9975 13.0116 3.0727 12.8593 3.22501L12.0179 4.06644L14.9347 6.98322L15.7761 6.14179C15.9284 5.98948 16.0036 5.79286 16.0036 5.59343C16.0036 5.39401 15.9284 5.19739 15.7761 5.04508L13.956 3.22501C13.8037 3.0727 13.6071 2.9975 13.4077 2.9975ZM16.3489 8.39743L17.1903 7.556C17.7325 7.0138 18.0036 6.30197 18.0036 5.59343C18.0036 4.8849 17.7325 4.17306 17.1903 3.63087L15.3702 1.81079C14.828 1.26859 14.1162 0.997498 13.4077 0.997498C12.6991 0.997498 11.9873 1.26859 11.4451 1.81079L10.6037 2.65222L10.5903 2.63886L9.18279 4.07309L8.60749 4.6484L8.6129 4.65381L0 13.5497V18.9999H6L14.3524 10.3933L16.3489 8.39743ZM10.0272 6.06808L12.9382 8.97913L5 17H2.0011V14.2466L10.0272 6.06808Z"
      fill="currentColor"
    />
  </svg>
);
