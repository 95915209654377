import PencilIcon from 'components/icons/Pencil';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Button from 'components/Button';

export type DraftItemProps = JSX.IntrinsicElements['div'] & {
  briefing?: string;
  onEdit?: () => void;
};

const DraftItem = ({
  briefing,
  onEdit,
  className,
  ...props
}: DraftItemProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classnames('bg-gray-lighter p-4 rounded-xl', className)}
      {...props}
    >
      <div className="flex justify-between items-center gap-2">
        <div className="overflow-hidden">
          <h3 className="text-[#6D658B] text-sm font-medium">
            {t('sidebar__patient_clinical_courses_draft')}
          </h3>
          <p className="text-[#6D658B] text-sm truncate">{briefing}</p>
        </div>
        <Button
          variant="primary"
          size="custom"
          className="h-10 w-10 shrink-0 rounded-xl"
          onClick={onEdit}
        >
          <PencilIcon className="w-4 h-4 text-white" />
        </Button>
      </div>
    </div>
  );
};

export default DraftItem;
