import React from 'react';

import Trash from 'components/icons/Trash';

const AllergyItem = ({ allergy, onDelete }) => (
  <div className="flex p-4 w-full">
    <div className="leading-4">
      <div className="font-medium text-md">{allergy.name}</div>
      <div className="text-xs text-gray-medium">{allergy.description}</div>
    </div>
    <button
      onClick={onDelete}
      className="w-4 h-4 cursor-pointer focus:outline-none flex items-center justify-center ml-auto"
    >
      <Trash className="text-gray-medium" />
    </button>
  </div>
);

export default AllergyItem;
