import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PatientProfile from 'containers/DetailBar/PatientBar/components/PatientProfile';
import { getPatientProfile } from 'containers/DetailBar/PatientBar/actions/GetMedicalHistory.actions';
import MedicalHistory from 'containers/DetailBar/PatientBar/components/MedicalHistory';
import { getPrescriptions } from 'containers/Prescription/actions/Prescription.actions';
import { useTranslation } from 'react-i18next';
import Api from 'api';
import classnames from 'classnames';
import Cross from 'components/icons/outline/Cross';
import { Report } from 'containers/Appointments/Meet';
import useMedicalReport from 'containers/MedicalReport/useMedicalReport';
import OrganizationDetailsScreen from 'containers/DetailBar/PatientBar/components/PatientProfile/OrganizationDetails';

const PatientBar = ({
  room: initialRoom = undefined,
  roomId,
  onToggle,
  border = false,
  showHeader = true,
  width = 'medium',
  consultationType = null,
  consultationId = null,
  setTab,
  setIsReportCancelled = () => {},
  isReportCancelled = false,
}) => {
  const [room, setRoom] = useState(initialRoom);
  const dispatch = useDispatch();
  const {
    patient,
    isLoading: isLoadingPatient,
    screen,
  } = useSelector((state) => state.patientBar);
  const detailBarOpen = useSelector((state) => state.layout.detailBarOpen);
  const { handleChangeScreen } = useMedicalReport();

  const isLoading = isLoadingPatient || !room;

  useEffect(() => {
    setRoom(initialRoom);
  }, [initialRoom]);

  useEffect(() => {
    const fetchRoomWithMessages = async ({ roomId }) => {
      Promise.all([
        Api.getRoom({ roomId }).then(({ data: { data: room } }) => room),
        Api.getMessages({ roomId }).then(
          ({ data: { data: messages } }) => messages
        ),
      ]).then(([room, messages]) => {
        room.messages = messages;
        setRoom(room);
      });
    };
    roomId && fetchRoomWithMessages({ roomId });
  }, [roomId]);

  useEffect(() => {
    if (room) {
      dispatch(getPatientProfile(room.meta?.hash));
      dispatch(getPrescriptions({ roomId: room.room_id }));
      handleChangeScreen('profile');
    }
  }, [room?.meta?.hash]);

  if (initialRoom && !detailBarOpen) {
    return null;
  }

  return (
    <div
      className={classnames(
        'flex flex-col h-full max-h-screen overflow-y-hidden bg-white text-dark',
        {
          'w-[300px] min-w-[300px] lg:w-[380px] lg:min-w-[380px]':
            width === 'medium',
          'w-full': width === 'full',
          'border-l border-separators': border,
        }
      )}
    >
      {showHeader && ['profile'].includes(screen) && (
        <PatientBarHeader onClick={onToggle} />
      )}
      <div style={{ overflow: 'overlay' }} className="h-full overflow-y-scroll">
        {
          {
            profile: (
              <div>
                <PatientProfile
                  patient={patient}
                  isLoading={isLoading}
                  room={room}
                />
                <MedicalHistory
                  patient={patient}
                  isLoading={isLoading}
                  room={room}
                  consultationType={consultationType}
                  consultationId={consultationId}
                  setTab={setTab}
                  setIsReportCancelled={setIsReportCancelled}
                  isReportCancelled={isReportCancelled}
                />
              </div>
            ),
            report: (
              <Report
                room={room}
                consultationType={consultationType}
                consultationId={consultationId}
                setTab={setTab}
                patient={patient}
              />
            ),
            organizationDetails: <OrganizationDetailsScreen />,
          }[screen]
        }
      </div>
    </div>
  );
};

const PatientBarHeader = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between h-[60px] p-4 border-b border-separators">
      <div>{t('sidebar__patient_header_title')}</div>
      <button
        onClick={onClick}
        className="flex items-center justify-center w-7 h-7 rounded-lg cursor-pointer hover:text-gray-dark hover:bg-gray-light focus:outline-none"
      >
        <Cross className="text-gray-medium w-3 h-3" />
      </button>
    </div>
  );
};

export default PatientBar;
