import Api from 'api';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { reportsApi } from 'services/reports';
import {
  addAutomaticReportDraft,
  addMedicalReport,
  updateAutomaticReport,
} from 'state/medicalReports/index.slice';

const useAutomaticReport = ({
  id,
  type,
  status,
}: {
  id: string;
  type: string;
  status: string;
}) => {
  const [report, setReport] = useState([]);
  const dispatch = useDispatch();

  const getAutomaticReport = () => {
    if (!id || !type || ['idle', 'error', 'cancelled'].includes(status)) return;
    Api.documentation
      .getAutomaticReport({ id, type })
      .then((data) => {
        const report = data.data.data;
        if (report) {
          setReport(report);
          dispatch(reportsApi.util.invalidateTags(['Reports']));
          dispatch(updateAutomaticReport('finished'));
          dispatch(addMedicalReport(report));
          dispatch(addAutomaticReportDraft(report));
        }
      })
      .catch((error) => {
        const { status } = error.response;
        if (status === 404) {
          return;
        }
        dispatch(updateAutomaticReport('error'));
        console.error(error);
      });
  };

  useEffect(() => {
    getAutomaticReport();
  }, [id, type, status]);

  return {
    getAutomaticReport,
    report,
  };
};

export default useAutomaticReport;
