import { Trans, useTranslation } from 'react-i18next';
import AlertIcon from 'containers/Login/icons/AlertIcon';
import Header from 'containers/Prescription/components/Header';
import {
  Paragraph,
  Title,
} from 'containers/Prescription/components/ValidationFailedView';
import { MEDIQUO_PRO_SUPPORT_EMAIL } from 'config/constants';

export interface RempeUnknownErrorProps {
  onClose: () => void;
}

const UnknownErrorView = ({ onClose }: RempeUnknownErrorProps) => {
  const { t } = useTranslation();

  return (
    <div className="h-full flex flex-col gap-5 overflow-y-auto pb-10">
      <Header
        title={t('prescription__registration_modal_title')}
        onClose={onClose}
      />

      <div className="flex-1 flex flex-col justify-center">
        <div className="container mx-auto max-w-[440px] space-y-6">
          <AlertIcon className="mx-auto w-11 h-11 text-negative" />
          <Title className="mt-1 text-center">
            {t('presription__rempe_unknown_error__title')}
          </Title>

          <div className="space-y-6">
            <Paragraph>
              <Trans
                i18nKey="prescription__rempe_unknown_error__content.0"
                components={[<strong />]}
                values={{ email: MEDIQUO_PRO_SUPPORT_EMAIL }}
              />
            </Paragraph>
            <Paragraph>
              <Trans
                i18nKey="prescription__rempe_unknown_error__content.1"
                components={[<strong />]}
              />
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnknownErrorView;
