import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

const API = 'professionals/v1';
const BASE_URL = config.services.mediquo.chat_url;

const archive = ({ roomIds }) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/rooms/archive`,
    headers: getHeaders(),
    data: {
      room_ids: roomIds,
    },
  });

const reopen = ({ roomIds }) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/rooms/reopen`,
    headers: getHeaders(),
    data: {
      room_ids: roomIds,
    },
  });

const unread = ({ roomIds }) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/rooms/unread`,
    headers: getHeaders(),
    data: {
      room_ids: roomIds,
    },
  });

const messages = {
  get: ({ roomId }) =>
    axios({
      method: 'GET',
      url: `${BASE_URL}/api/v1/rooms/${roomId}/messages`,
      headers: getHeaders(),
    }),
};

const prescriptions = {
  setup: ({ roomId }) =>
    axios({
      method: 'GET',
      url: `${BASE_URL}/professionals/v1/rooms/${roomId}/prescriptions/setup`,
      headers: getHeaders(),
    }),
};

const read = ({ roomId, roomIds }) => {
  if (roomId) {
    return axios({
      method: 'PUT',
      url: `${BASE_URL}/${API}/rooms/${roomId}/read`,
      headers: getHeaders(),
    });
  }

  return axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/rooms/read`,
    headers: getHeaders(),
    data: {
      room_ids: roomIds,
    },
  });
};

const pin = ({ roomId }) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/rooms/${roomId}/pin`,
    headers: getHeaders(),
  });

const unpin = ({ roomId }) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/rooms/${roomId}/unpin`,
    headers: getHeaders(),
  });

const deleteMessage = (messageId) =>
  axios({
    method: 'DELETE',
    url: `${BASE_URL}/${API}/messages/${messageId}`,
    headers: getHeaders(),
  });

export default {
  pin,
  unpin,
  archive,
  reopen,
  read,
  unread,
  messages,
  prescriptions,
  deleteMessage,
};
