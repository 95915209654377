import { createSlice } from '@reduxjs/toolkit';

const BarType = {
  filters: 'filters',
  detail: 'detail',
};

const initialState = {
  tutorialStep: 0,
  filtersBarOpen: true,
  detailBarOpen: false,
  lastOpenBar: BarType.filters,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    initTutorial(state) {
      state.tutorialStep = 1;
    },
    nextStepTutorial(state) {
      state.tutorialStep += 1;
    },
    finishTutorial(state) {
      state.tutorialStep = 0;
    },
    toggleFiltersBar(state) {
      if (!state.filtersBarOpen) {
        state.lastOpenBar = BarType.filters;
      }
      state.filtersBarOpen = !state.filtersBarOpen;
    },
    toggleDetailBar(state) {
      if (!state.detailBarOpen) {
        state.lastOpenBar = BarType.detail;
      }
      state.detailBarOpen = !state.detailBarOpen;
    },
    smallLayout(state) {
      state.filtersBarOpen = false;
    },
    smallRoom(state) {
      if (state.lastOpenBar !== BarType.detail && state.detailBarOpen) {
        state.detailBarOpen = false;
        return;
      }
      if (state.lastOpenBar !== BarType.filters && state.filtersBarOpen) {
        state.filtersBarOpen = false;
        return;
      }
    },
  },
});

export const {
  initTutorial,
  nextStepTutorial,
  finishTutorial,
  toggleFiltersBar,
  toggleDetailBar,
  smallRoom,
  smallLayout,
} = layoutSlice.actions;
export default layoutSlice.reducer;
