const Arrow = (props: any) => (
  <svg fill="none" viewBox="0 0 17 16" {...props}>
    <path
      fill="currentColor"
      d="M5.913 6.193L8.5 8.78l2.587-2.587a.664.664 0 11.94.94l-3.06 3.06c-.26.26-.68.26-.94 0l-3.06-3.06a.664.664 0 010-.94.678.678 0 01.946 0z"
    ></path>
  </svg>
);

export default Arrow;
