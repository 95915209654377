import ConsultationsApiClient from 'legacy/Services/Consultations';
import CRMTracker from 'utils/CRMTracker';
import Api from 'api';
import Tracker from 'utils/Tracking';
import api from 'api';

const PRESCRIPTIONS_FETCHED = 'PRESCRIPTION/PRESCRIPTIONS_FETCHED';
const PRESCRIPTION_CREATED = 'PRESCRIPTION/PRESCRIPTION_CREATED';
const PRESCRIPTION_ADDED = 'PRESCRIPTION/PRESCRIPTION_ADDED';

export const actionTypes = {
  PRESCRIPTIONS_FETCHED,
  PRESCRIPTION_CREATED,
  PRESCRIPTION_ADDED,
};

export const getPrescriptions = ({ roomId }) => {
  return (dispatch) => {
    return api.prescriptions.list({ roomId }).then(({ data }) => {
      dispatch(prescriptionsFetched(data.data));
    });
  };
};

export const create = ({
  hash,
  roomId,
  data,
  provider,
  consultationType,
  consultationId,
}) => {
  return (dispatch) => {
    return Api.prescriptions
      .create({
        contactId: hash,
        roomId,
        data,
        provider,
        consultationType,
        consultationId,
      })
      .then(() => {
        dispatch(prescriptionAdded());
        roomId &&
          Tracker.event('prescription create success', {
            event_room_id: roomId,
          });
        CRMTracker.event('PrescriptionSend');
      });
  };
};

export const download = (uuid) => {
  return ConsultationsApiClient.chat
    .downloadPrescription(uuid)
    .then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', uuid + '.pdf');
      document.body.appendChild(link);
      link.click();
    });
};

export const prescriptionsFetched = (prescriptions) => ({
  type: PRESCRIPTIONS_FETCHED,
  payload: prescriptions,
});

export const prescriptionCreated = (prescription) => ({
  type: PRESCRIPTION_CREATED,
  payload: prescription,
});

export const prescriptionAdded = () => ({
  type: PRESCRIPTION_ADDED,
});
