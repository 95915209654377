import { config } from 'config';
import i18n from 'i18n/index';

export default () => ({
  Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
  'Accept-Language': i18n.language,
  'X-Mdq-Origin':
    config.app.environment === 'production' ? 'mdq-console' : 'mdq-console-dev',
  'X-Mdq-Trace-Id': localStorage.getItem('sessionId'),
});
