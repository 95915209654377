import { useTranslation } from 'react-i18next';
import Trash from 'components/icons/outline/Trash';
import { useDispatch } from 'react-redux';
import { deleteOwnMessage } from 'containers/Room/actions/DeleteMessage.actions';

type Props = {
  show?: boolean;
  onClose: () => void;
  messageId: string;
};

const DeleteMessageModal = ({ show, onClose, messageId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-x-0 bottom-0 z-10 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
      <div className="fixed inset-0 transition-opacity">
        <div
          onClick={onClose}
          className="absolute inset-0 opacity-75 bg-gray-medium"
        ></div>
      </div>

      <div className="max-w-xl p-10 overflow-hidden transition-all transform bg-white shadow-xl text-dark rounded-24 sm:w-full">
        <div className="flex flex-col items-center text-center">
          <div className="flex items-center justify-center w-20 h-20 mb-6 rounded-full bg-negative-light text-negative mt-8">
            <Trash className="text-negative w-8" />
          </div>
          <div className="mb-6 text-2xl font-medium text-primary">
            {t('room__message_options_delete_modal_title')}
          </div>
          <div className="mt-12 flex flex-row">
            <button
              className="flex items-center tracking-[.2em] h-14 px-8 font-semibold text-primary uppercase border rounded-lg bg-secundary border-secundary focus:outline-none hover:bg-primary hover:text-white"
              onClick={onClose}
            >
              {t('room__message_options_delete_modal_cancel')}
            </button>
            <button
              className="flex items-center tracking-[.2em] h-14 px-8 font-semibold text-white uppercase border rounded-lg bg-primary border-primary focus:outline-none hover:bg-primary-dark ml-4"
              onClick={() => {
                dispatch(deleteOwnMessage(messageId));
                onClose();
              }}
            >
              {t('room__message_options_delete_modal_submit')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteMessageModal;
