import api from 'api';

const useUploadFile = () => {
  const uploadAttachment = async (attachment: any) => {
    if (!attachment) {
      return undefined;
    }
    const {
      data: {
        data: { presigned_storage_url, file_path },
      },
    } = await api.diagnosticTestPrescriptions.storage();

    await api.tracking.attachments.store(presigned_storage_url, attachment);

    return file_path;
  };

  const uploadedAttachment = async ({ attachment }: any) => {
    const attached_file_url = await uploadAttachment(attachment);

    return attached_file_url;
  };

  return uploadedAttachment;
};

export default useUploadFile;
