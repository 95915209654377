const LogoAppIcon = (props: any) => (
  <svg width="68" height="68" viewBox="0 0 68 68" fill="none" {...props}>
    <g filter="url(#filter0_d)">
      <path
        d="M44.5946 10.2676H23.4054C16.0018 10.2676 10 16.2359 10 23.5983V44.6693C10 52.0316 16.0018 58 23.4054 58H44.5946C51.9982 58 58 52.0316 58 44.6693V23.5983C58 16.2359 51.9982 10.2676 44.5946 10.2676Z"
        fill="white"
      />
    </g>
    <path
      d="M46.4201 48.8871C46.2124 48.8871 46.0308 48.8349 45.875 48.7958L39.7885 47.1923L39.5679 47.2966C37.6472 48.2222 35.5967 48.6915 33.5073 48.6915C32.5729 48.6915 31.6126 48.6003 30.6522 48.4178C26.9276 47.7007 23.5794 45.4062 21.477 42.1209C19.3876 38.8357 18.6868 34.8333 19.5952 31.1309C21.1655 24.5994 26.7459 20.1538 33.4813 20.0756C34.4936 20.1017 35.3891 20.193 36.2067 20.3494C40.7229 21.275 44.4215 24.1953 46.3552 28.354C48.211 32.3563 48.1461 36.9062 46.1605 40.8564L46.0437 41.078L46.1086 41.3257C46.6277 43.1769 47.1339 45.0673 47.653 46.9837C47.7698 47.4009 47.8347 47.8963 47.4843 48.3656C47.2377 48.7046 46.8484 48.8871 46.4201 48.8871ZM33.4684 22.6439C27.9399 22.7351 23.3588 26.3855 22.074 31.7306C21.3342 34.7551 21.9053 38.0404 23.6313 40.726C25.3573 43.4246 28.0956 45.3019 31.1324 45.8886C31.924 46.045 32.7286 46.1233 33.4943 46.1233C35.3501 46.1233 37.1411 45.68 38.8282 44.7935C39.1526 44.6371 39.4121 44.5849 39.6977 44.5849C39.8404 44.5849 39.9832 44.598 40.1259 44.624L44.6941 45.8234L44.3956 44.7414C44.0971 43.6723 43.8116 42.6163 43.5131 41.5604C43.3703 40.9346 43.4352 40.4522 43.7078 39.9829C45.4857 36.6715 45.6025 32.8257 44.0192 29.423C42.4359 26.0074 39.3992 23.6086 35.7005 22.8525C35.0387 22.7221 34.273 22.6439 33.4813 22.6308H33.4684V22.6439Z"
      fill="#4A1EA6"
      stroke="#4A1EA6"
      strokeWidth="0.97"
    />
    <path
      d="M37.1886 35.6916H34.6378V38.3109C34.6378 39.0351 34.0664 39.6205 33.3624 39.6205C32.6571 39.6205 32.087 39.0351 32.087 38.3109V35.6916H29.5361C28.8308 35.6916 28.2607 35.1062 28.2607 34.382C28.2607 33.6591 28.8308 33.0724 29.5361 33.0724H32.087V30.4532C32.087 29.7303 32.6571 29.1436 33.3624 29.1436C34.0664 29.1436 34.6378 29.7303 34.6378 30.4532V33.0724H37.1886C37.8927 33.0724 38.4641 33.6591 38.4641 34.382C38.4641 35.1062 37.8927 35.6916 37.1886 35.6916Z"
      fill="#4A1EA6"
      stroke="#4A1EA6"
      strokeWidth="0.97"
    />
    <path
      d="M23.6339 12.3432C23.6339 15.5252 26.2134 18.1047 29.3954 18.1047H37.7728C40.9548 18.1047 43.5343 15.5252 43.5343 12.3432V10H33.5841H23.6339V12.3432Z"
      fill="#2E087D"
    />
    <path
      d="M28.2455 15.2333C28.2455 15.328 28.2771 15.4016 28.3402 15.4647C28.4034 15.5278 28.477 15.5541 28.5717 15.5541C28.6611 15.5541 28.7347 15.5278 28.7979 15.4647C28.861 15.4016 28.8926 15.328 28.8926 15.2333V14.3443H29.6237C30.0446 14.3443 30.397 14.2338 30.6758 14.0129C30.9546 13.792 31.0966 13.4869 31.0966 13.0871V13.0766C31.0966 12.7083 30.9704 12.4085 30.7179 12.1823C30.4654 11.9614 30.1235 11.8457 29.6974 11.8457H28.5717C28.477 11.8457 28.4034 11.8772 28.3402 11.9403C28.2771 12.0035 28.2455 12.0824 28.2455 12.1718V15.2333ZM28.8926 13.7604V12.4348H29.6448C29.8868 12.4348 30.0814 12.4927 30.2234 12.6084C30.3654 12.7241 30.4391 12.8819 30.4391 13.0923V13.1029C30.4391 13.2975 30.3654 13.4553 30.2234 13.5763C30.0761 13.7025 29.8868 13.7604 29.6448 13.7604H28.8926Z"
      fill="white"
    />
    <path
      d="M31.7592 15.2333C31.7592 15.328 31.7908 15.4016 31.8539 15.4647C31.9171 15.5278 31.9907 15.5541 32.0854 15.5541C32.1748 15.5541 32.2484 15.5278 32.3116 15.4647C32.3747 15.4016 32.4063 15.328 32.4063 15.2333V14.2443H33.2216L34.1158 15.3858C34.2 15.5015 34.3052 15.5541 34.4315 15.5541C34.5104 15.5541 34.584 15.5278 34.6471 15.47C34.7103 15.4174 34.7418 15.3437 34.7418 15.2543C34.7418 15.1807 34.705 15.0965 34.6366 15.0071L33.9317 14.1181C34.1895 14.0392 34.3946 13.9077 34.5419 13.7236C34.6892 13.5395 34.7681 13.3028 34.7681 13.0187V13.0082C34.7681 12.6926 34.6629 12.4243 34.463 12.2139C34.2105 11.9719 33.8528 11.8457 33.4004 11.8457H32.0854C31.9907 11.8457 31.9171 11.8772 31.8539 11.9403C31.7908 12.0035 31.7592 12.0824 31.7592 12.1718V15.2333ZM32.4063 13.671V12.4348H33.3478C33.5898 12.4348 33.7739 12.4874 33.9107 12.5926C34.0422 12.6978 34.1106 12.8504 34.1106 13.045V13.0555C34.1106 13.2449 34.0422 13.3922 33.9054 13.5026C33.7687 13.6184 33.5846 13.671 33.3531 13.671H32.4063Z"
      fill="white"
    />
    <path
      d="M37.3175 15.591C37.8751 15.591 38.3328 15.4069 38.701 15.0334C39.064 14.6652 39.2481 14.2128 39.2481 13.6868V13.6762C39.2481 13.1502 39.064 12.7031 38.7063 12.3349C38.3433 11.9666 37.8857 11.7825 37.3281 11.7825C36.7705 11.7825 36.3076 11.9719 35.9446 12.3401C35.5764 12.7136 35.3975 13.1607 35.3975 13.6868V13.6973C35.3975 14.2233 35.5764 14.6704 35.9394 15.0386C36.2971 15.4069 36.76 15.591 37.3175 15.591ZM37.3281 14.9913C36.9651 14.9913 36.6653 14.8651 36.4286 14.6126C36.1918 14.3601 36.0761 14.055 36.0761 13.6868V13.6762C36.0761 13.3133 36.1918 13.0082 36.4233 12.7557C36.6547 12.5032 36.9546 12.3769 37.3175 12.3769C37.6752 12.3769 37.9751 12.5084 38.2118 12.7609C38.4485 13.0134 38.5695 13.3238 38.5695 13.6868V13.6973C38.5695 14.0655 38.4485 14.3706 38.2171 14.6178C37.9856 14.8703 37.6858 14.9913 37.3281 14.9913Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0.267578"
        width="68"
        height="67.7324"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default LogoAppIcon;
