const Warning = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
  >
    <path
      d="M35.6694 10.5C37.5939 7.16667 42.4052 7.16667 44.3297 10.5L75.6694 64.782C77.5939 68.1154 75.1883 72.282 71.3393 72.282H8.6598C4.8108 72.282 2.40518 68.1154 4.32968 64.782L35.6694 10.5Z"
      fill="#FFEBCE"
    />
    <path
      d="M40.0023 37C41.1015 37 42.0008 37.8993 42.0008 38.9984V46.9922C42.0008 48.0913 41.1015 48.9906 40.0023 48.9906C38.9032 48.9906 38.0039 48.0913 38.0039 46.9922V38.9984C38.0039 37.8993 38.9032 37 40.0023 37ZM42.0008 56.9844H38.0039V52.9875H42.0008V56.9844Z"
      fill="#FF8A01"
    />
  </svg>
);

export default Warning;
