import { config } from 'config';

const GTM_ID = config.services.google.gtm_code;
const PRODUCTION = config.app.environment === 'production';

function gtag() {
  window.dataLayer?.push(arguments);
}

const loadGoogleTagManger = () =>
  new Promise((resolve) => {
    if (!PRODUCTION) {
      return resolve();
    }

    const scriptId = 'googleTagManager';
    if (document.getElementById(scriptId)) {
      return resolve();
    }

    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GTM_ID}`;
    document.body.appendChild(script);

    script.onload = () => resolve();
  });

const init = () => {
  loadGoogleTagManger().then(() => {
    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    gtag('config', GTM_ID);
  });
};

const event = ({ action, category }) =>
  gtag('event', action, {
    event_category: category,
  });

const conversion = (tag, url) =>
  url
    ? gtag('event', 'conversion', {
        send_to: `${GTM_ID}/${tag}`,
        event_callback: () => (document.location = url),
      })
    : gtag('event', 'conversion', {
        send_to: `${GTM_ID}/${tag}`,
      });

export default {
  init,
  event,
  conversion,
};
