import { useCallback, useEffect, useState } from 'react';

export interface Props {
  onIntersect: () => void;
  isActive?: boolean;
  rootMargin?: string;
}

function useInfiniteScroll({ onIntersect, isActive = true }: Props) {
  const [observer, setObserver] = useState<IntersectionObserver>();

  const callback = useCallback(
    (entries) => {
      !!entries.length &&
        entries[0].isIntersecting &&
        isActive &&
        onIntersect();
    },
    [onIntersect, isActive]
  );

  const infiniteScrollRef = useCallback(
    (node) => {
      if (!node) {
        return;
      }

      const newObserver = new IntersectionObserver(callback);
      newObserver.observe(node);

      setObserver(newObserver);
    },
    [callback]
  );

  useEffect(() => {
    return () => observer?.disconnect();
  }, [observer]);

  return infiniteScrollRef;
}

export default useInfiniteScroll;
