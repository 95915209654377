const EmptyCalendar = (props: any) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.6923 1.33331C12.0308 1.33331 12.3077 1.60673 12.3077 1.94091V2.5485L12.419 2.5521C13.3017 2.6094 14 3.34356 14 4.24081V11.3333C14 12.8061 12.8061 14 11.3333 14H4.66667C3.19391 14 2 12.8061 2 11.3333V4.24081C2 3.30617 2.75767 2.5485 3.69231 2.5485V1.94091C3.69231 1.60673 3.96923 1.33331 4.30769 1.33331C4.64615 1.33331 4.92308 1.60673 4.92308 1.94091V2.5485H11.0769V1.94091C11.0769 1.60673 11.3538 1.33331 11.6923 1.33331ZM12.6667 4.66665H3.33333V11.3333C3.33333 12.0697 3.93029 12.6666 4.66667 12.6666H11.3333C12.0697 12.6666 12.6667 12.0697 12.6667 11.3333V4.66665Z"
      fill="currentColor"
    />
  </svg>
);

export default EmptyCalendar;
