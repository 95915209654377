export default (props) => (
  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" {...props}>
    <path
      opacity="0.05"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.75 0C43.0439 0 48.75 6.58154 48.75 24C48.75 41.4184 43.0439 48 24.75 48C6.45602 48 0.75 41.4184 0.75 24C0.75 6.58154 6.45602 0 24.75 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.25 20.1332V19.9798C15.25 19.9552 15.2503 19.9307 15.251 19.9062C15.2687 19.241 15.5264 18.6046 15.9763 18.1196C16.4427 17.6168 17.0804 17.3148 17.7585 17.2757L17.9091 17.2715H30.5909C31.2701 17.2714 31.9236 17.5361 32.4173 18.0112C32.911 18.4863 33.2075 19.1358 33.2459 19.8265L33.25 19.9798V20.1332L24.25 24.8998L15.25 20.1332ZM23.9669 26.2206L15.25 21.6031V28.7915L15.2541 28.9448C15.2925 29.6355 15.589 30.285 16.0827 30.7601C16.5764 31.2352 17.2299 31.4999 17.9091 31.4998H30.5909L30.7415 31.4956C31.4196 31.4565 32.0573 31.1545 32.5237 30.6517C32.9902 30.1488 33.25 29.4833 33.25 28.7915V21.604L24.5331 26.2206C24.4457 26.2669 24.3486 26.2911 24.25 26.2911C24.1514 26.2911 24.0543 26.2669 23.9669 26.2206ZM34.75 28.7914C34.75 28.7914 34.75 28.7913 34.75 28.7914C34.75 29.8579 34.3496 30.8889 33.6234 31.6718C32.8964 32.4555 31.897 32.9314 30.8279 32.9931L30.8055 32.9944L30.6117 32.9998H17.9092C17.9092 32.9998 17.9092 32.9998 17.9092 32.9998C16.8383 32.9999 15.813 32.5823 15.0426 31.8409C14.273 31.1003 13.8157 30.0932 13.7564 29.0282L13.7552 29.0065L13.75 28.8115V19.9799V19.1111L13.8308 19.1539C13.9817 18.3909 14.3403 17.6776 14.8766 17.0995C15.6036 16.3158 16.603 15.8399 17.6721 15.7781L17.6945 15.7768L17.8883 15.7715H30.5908C30.5908 15.7715 30.5908 15.7715 30.5908 15.7715C31.6617 15.7714 32.687 16.189 33.4574 16.9304C34.0835 17.5329 34.5029 18.3118 34.6695 19.1548L34.75 19.1122V28.7914Z"
      fill="currentColor"
    />
  </svg>
);
