import { useState } from 'react';
import CreateContact from 'containers/Invitations/CreateContact';
import CreateVithasContact from 'containers/Invitations/Vithas/CreateContact';
import { usePortal } from 'containers/Shared/components/Portal/index';
import CreateContactPreview from 'containers/Invitations/CreateContactPreview';
import { TargetType, SendType } from 'containers/Invitations/Invitations';
import { isVithas } from 'utils/isVithas';
import { useSelector } from 'react-redux';
import Invite from 'containers/Invitations/Invite';
import { useGetAllNotificationsQuery } from 'services/notifications';

type screen = 'target' | 'create';

type State = {
  screen: screen;
  target: TargetType;
  send?: SendType;
};

const useInvitationModal = () => {
  const portal = usePortal();
  const { refetch } = useGetAllNotificationsQuery();

  return {
    open: ({
      screen = 'target',
      target,
    }: {
      screen?: screen;
      target: TargetType;
    }) =>
      portal.open(
        <Invitations
          screen={screen}
          target={target}
          onClose={() => {
            refetch();
            portal.close('invitations');
          }}
        />,
        'invitations'
      ),
  };
};

const Invitations = ({
  screen,
  target,
  onClose,
}: {
  screen: screen;
  onClose: () => void;
  target: TargetType;
}) => {
  const [state, setState] = useState<State>({ screen, target });
  const { organization } = useSelector((state: any) => state.session.profile);

  if (isVithas(organization)) {
    return {
      target:
        state.target === 'patient' ? (
          <CreateVithasContact onClose={onClose} />
        ) : (
          <CreateContactPreview
            target={state.target}
            onClose={onClose}
            onConfirm={(send) =>
              setState((state) => ({ ...state, screen: 'create', send }))
            }
          />
        ),
      create: (
        <CreateContact
          onClose={onClose}
          send={state.send!}
          target={state.target!}
        />
      ),
    }[state.screen];
  }

  return <Invite target={target} onClose={onClose} />;
};

export default useInvitationModal;
