import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

export const BASE_URL = config.services.mediquo.chat_url;
const CONTACTS_URL = `${BASE_URL}/professionals/v1/contacts`;

export type PaginatedResult<D> = {
  data: D;
  meta: {
    current_page: number;
    to: number;
    total: number;
    hasMore: boolean;
  };
};

export type Contact = {
  id: string;
  name: string;
  avatar: string;
  features: {
    can_be_appointed: boolean;
  };
  user_hash: string | null;
};

type SearchParams = { term?: string; page?: number; per_page?: number, with_accepted_invitation?: boolean };

export const contactsApi = createApi({
  reducerPath: 'contactsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CONTACTS_URL,
    prepareHeaders: (headers) => {
      Object.entries(getHeaders()).forEach(([key, value]) =>
        headers.set(key, value!)
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllContacts: builder.query<PaginatedResult<Contact[]>, SearchParams>({
      query: (params) => ({ url: '', params }),
      transformResponse: (response: PaginatedResult<Contact[]>) => ({
        ...response,
        meta: {
          ...response.meta,
          hasMore: response.meta.to !== response.meta.total,
        },
      }),
    }),
  }),
});

export const isPendingToAccept = (contact?: Contact) =>
  contact && !contact.user_hash;

export const { useGetAllContactsQuery } = contactsApi;
