import Arrow from 'components/icons/outline/Arrow';

export interface Props {
  onNext: () => void;
  onPrev: () => void;
}

const NavigationButtons = ({ onPrev, onNext }: Props) => (
  <div className="flex items-center gap-2">
    <button
      onClick={onPrev}
      className="text-primary w-8 h-8 flex items-center justify-center border border-separators hover:border-primary rounded-lg bg-white hover:bg-secundary"
    >
      <Arrow className="w-4 h-4 rotate-180" />
    </button>
    <button
      onClick={onNext}
      className="text-primary w-8 h-8 flex items-center justify-center border border-separators hover:border-primary rounded-lg bg-white hover:bg-secundary"
    >
      <Arrow className="w-4 h-4" />
    </button>
  </div>
);

export default NavigationButtons;
