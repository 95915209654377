import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ModalBody,
  ModalFooter,
} from 'containers/Shared/components/Modal/Modal';
import Button from 'components/Button';

export interface Props {
  name: string;
  onConfirm: () => void;
}

const InvitationSent: FC<Props> = ({ name, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalBody>
        <p className="my-4 text-primary">
          {t('invite__vithas_contact_created_success_title')}
        </p>
        <p>{t('invite__vithas_contact_sms_created_success_text', { name })}</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onConfirm}>
          {t('invite__vithas_contact_created_success_button')}
        </Button>
      </ModalFooter>
    </>
  );
};

export default InvitationSent;
