const Trash = (props: any) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33464 12.6666C3.33464 13.4 3.93464 14 4.66797 14H11.3346C12.068 14 12.668 13.4 12.668 12.6666V3.33331C13.0346 3.33331 13.3346 3.03331 13.3346 2.66665C13.3346 2.29998 13.0346 1.99998 12.668 1.99998H11.3346H11.0013L10.528 1.52665C10.408 1.40665 10.2346 1.33331 10.0613 1.33331H5.9413C5.76797 1.33331 5.59464 1.40665 5.47464 1.52665L5.0013 1.99998H4.66797H3.33464C2.96797 1.99998 2.66797 2.29998 2.66797 2.66665C2.66797 3.03331 2.96797 3.33331 3.33464 3.33331V12.6666ZM5.33464 3.33331H10.668C11.0346 3.33331 11.3346 3.63331 11.3346 3.99998V12C11.3346 12.3666 11.0346 12.6666 10.668 12.6666H5.33464C4.96797 12.6666 4.66797 12.3666 4.66797 12V3.99998C4.66797 3.63331 4.96797 3.33331 5.33464 3.33331ZM6.0013 5.33331C6.0013 4.96512 6.29978 4.66665 6.66797 4.66665C7.03616 4.66665 7.33464 4.96512 7.33464 5.33331V10.6666C7.33464 11.0348 7.03616 11.3333 6.66797 11.3333C6.29978 11.3333 6.0013 11.0348 6.0013 10.6666V5.33331ZM9.33464 4.66665C8.96645 4.66665 8.66797 4.96512 8.66797 5.33331V10.6666C8.66797 11.0348 8.96645 11.3333 9.33464 11.3333C9.70282 11.3333 10.0013 11.0348 10.0013 10.6666V5.33331C10.0013 4.96512 9.70282 4.66665 9.33464 4.66665Z"
      fill="currentColor"
    />
  </svg>
);

export default Trash;
