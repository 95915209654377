import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { isOnboardingCompleted as sessionIsOnboardingCompleted } from 'containers/Inbox/reducers/session.reducer';

const NoAuthenticatedRoute = (props) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isOnboardingCompleted = useSelector(sessionIsOnboardingCompleted);

  if (isAuthenticated) {
    return <Redirect to={!isOnboardingCompleted ? '/onboarding' : '/chat'} />;
  }

  return <Route {...props} />;
};

export default NoAuthenticatedRoute;
