export function sizeForHumans(bytes) {
  let thresh = 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  let units = ['kB', 'MB'];
  let u = -1;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);

  return bytes.toFixed(1) + ' ' + units[u];
}
