import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ConsultationsApiClient from 'legacy/Services/Consultations';
import { trackingDeleted } from 'containers/DetailBar/PatientBar/actions/MedicalHistory.actions';
import { getSummaryRoomsList } from 'containers/Inbox/actions/GetRoomList.actions';

export const useTrackings = (room) => {
  const dispatch = useDispatch();
  const [trackings, setTrackings] = useState([]);

  const loadTrackings = () =>
    ConsultationsApiClient.automaticTrackings
      .index({ room_id: room.room_id })
      .then(({ data }) => setTrackings(data));

  useEffect(() => {
    loadTrackings();
  }, [room.total_tracking_messages]);

  const load = () => loadTrackings();

  const destroy = (id, roomId) => {
    return ConsultationsApiClient.automaticTrackings.delete(id).then(() => {
      setTrackings(trackings.filter((t) => t.id !== id));
      dispatch(trackingDeleted(roomId));
      dispatch(getSummaryRoomsList());
    });
  };

  return [trackings, destroy, load];
};
