import ConsultationsApiClient from 'legacy/Services/Consultations';

const FETCHING_PATIENT_PROFILE = 'PATIENT_BAR/FETCHING_PATIENT_PROFILE';
const PATIENT_PROFILE_FETCHED = 'PATIENT_BAR/PATIENT_PROFILE_FETCHED';
const FETCHING_MEDICAL_HISTORY = 'PATIENT_BAR/FETCHING_MEDICAL_HISTORY';
const MEDICAL_HISTORY_FETCHED = 'PATIENT_BAR/MEDICAL_HISTORY_FETCHED';

export const actionTypes = {
  FETCHING_PATIENT_PROFILE,
  PATIENT_PROFILE_FETCHED,
  FETCHING_MEDICAL_HISTORY,
  MEDICAL_HISTORY_FETCHED,
};

export const getPatientProfile = (hash) => {
  return (dispatch) => {
    dispatch(fetchingPatientProfile());
    return ConsultationsApiClient.chat.getProfile(hash).then((data) => {
      dispatch(patientProfileFetched(data));
      dispatch(getMedicalHistorySummary(hash));
    });
  };
};

export const getMedicalHistorySummary = (hash) => {
  return (dispatch) => {
    dispatch(fetchingMedicalHistory());
    return ConsultationsApiClient.medicalSummary
      .index({ customer_hash: hash })
      .then(({ data }) => {
        dispatch(medicalHistoryFetched(data));
      });
  };
};

const fetchingPatientProfile = () => {
  return {
    type: FETCHING_PATIENT_PROFILE,
  };
};

const patientProfileFetched = (data) => {
  return {
    type: PATIENT_PROFILE_FETCHED,
    payload: data,
  };
};

const fetchingMedicalHistory = () => {
  return {
    type: FETCHING_MEDICAL_HISTORY,
  };
};

const medicalHistoryFetched = (data) => {
  return {
    type: MEDICAL_HISTORY_FETCHED,
    payload: data,
  };
};
