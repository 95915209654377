import { FC } from 'react';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import { Label } from 'components/Form';

const Dropdown: FC<any> = ({
  isLoading,
  name,
  label,
  placeholder,
  options,
  control,
  value,
  defaultValue,
  disabled,
  errors,
  readOnly,
  required,
}) => (
  <div className="relative w-full mb-2 font-normal">
    <Label hidden={!value && !defaultValue}>{label}</Label>
    {isLoading ? (
      <div className="flex items-center w-full px-4 mb-6 border-none rounded-lg text-gray-medium min-h-input focus:ring-2 bg-background focus:outline-none animate animate-pulse">
        Loading
      </div>
    ) : (
      <Controller
        rules={{ required }}
        name={name}
        control={control}
        render={({ onChange, value, name, ref }) => (
          <ReactSelect
            isDisabled={disabled || readOnly}
            className={classnames('Dropdown', {
              'Dropdown--error': errors,
            })}
            isSearchable={true}
            classNamePrefix="ReactDropdown"
            placeholder={placeholder}
            options={options}
            inputRef={ref}
            value={options.find((o: any) => o.value === value)}
            name={name}
            defaultValue={options.find((o: any) => o.value === defaultValue)}
            onChange={(selectedOption: any) => onChange(selectedOption.value)}
          />
        )}
      />
    )}
    {errors && (
      <small className="absolute bottom-0 right-0 text-negative">
        {errors.message}
      </small>
    )}
  </div>
);

export default Dropdown;
