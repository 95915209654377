import React from 'react';
import Trash from 'components/icons/Trash';
import { formatDate } from 'utils/dateFormatter';

const DiseaseItem = ({ disease, onDelete }) => (
  <div className="flex p-4 w-full">
    <div className="leading-4">
      <div className="font-medium text-md">{disease.name}</div>
      {disease.diagnosis_date && (
        <div className="text-xs font-medium text-gray-medium">
          {`${formatDate(disease.diagnosis_date)}`}
          {disease.resolution_date && (
            <span>{`- ${formatDate(disease.resolution_date)}`}</span>
          )}
        </div>
      )}
      <div className="text-xs text-gray-medium">{disease.description}</div>
    </div>
    <button
      onClick={onDelete}
      className="w-4 h-4 cursor-pointer focus:outline-none flex items-center justify-center ml-auto"
    >
      <Trash className="text-gray-medium" />
    </button>
  </div>
);

export default DiseaseItem;
