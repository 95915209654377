export interface Props {
  priceInCents: string;
  fees: any;
}

const calculateTotalPrice = ({ priceInCents, fees }: Props) => {
  const price = parseFloat(priceInCents);
  const fee = fees?.fee + fees?.tpv_fee;

  return Number(((price + fee) / 100)?.toFixed(2));
};

export default calculateTotalPrice;
