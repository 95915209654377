import IconBoxArrowUp from 'containers/Inbox/icons/IconBoxArrowUp';
import { Trans } from 'react-i18next';

const UnArchiveRoom = ({ rooms }) => (
  <div className="flex items-center justify-center">
    <IconBoxArrowUp />
    <div className="ml-3">
      <Trans i18nKey="toast__unarchive_text" count={rooms.length}>
        Has desarchivado
        <span className="font-medium lowercase">
          {` ${rooms.length} consultas.`}
        </span>
      </Trans>
    </div>
  </div>
);

export default UnArchiveRoom;
