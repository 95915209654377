import React, { useState } from 'react';
import { NimblePicker } from 'emoji-mart';
import data from 'emoji-mart/data/google.json';
import useOutside from 'utils/useOutside';
import EmojiIcon from 'containers/Room/icons/EmojiIcon';
import classnames from 'classnames';

const EmojiPicker = ({ disabled = false, onSelect }) => {
  const [show, setShow] = useState(false);

  const pickerRef = useOutside(() => setShow(false));

  const handleSelect = (emoji) => {
    onSelect(emoji);
    setShow(false);
  };

  return (
    <>
      <Button
        onClick={() => setShow(!show)}
        disabled={disabled}
        activated={show}
      >
        <EmojiIcon />
      </Button>
      {show && (
        <div
          ref={pickerRef}
          className="absolute right-0 z-30 transform -translate-y-full -top-4"
        >
          <NimblePicker
            data={data}
            color="#4A1EA7"
            set="google"
            sheetSize={32}
            showPreview={false}
            onSelect={(emoji) => handleSelect(emoji.native)}
          />
        </div>
      )}
    </>
  );
};

const Button = ({ activated, disabled, children, onClick }) => (
  <button
    disabled={disabled}
    onClick={onClick}
    className={classnames(
      'mx-2 w-8 h-8 relative rounded-lg flex items-center justify-center',
      {
        'hover:bg-gray-light hover:text-gray-dark focus:outline-none': !disabled,
      },
      activated
        ? 'bg-gray-light text-gray-dark z-30'
        : 'bg-white text-gray-medium'
    )}
  >
    {children}
  </button>
);

export default React.memo(EmojiPicker);
