import ConsultationsApiClient from 'legacy/Services/Consultations';
import { socket } from 'socket';

const MESSAGE_STATUS_CHANGED = 'ROOM/MESSAGE_STATUS_CHANGED';
const FETCHING_MESSAGES = 'ROOM/FETCHING_MESSAGES';
const MESSAGES_FETCHED = 'ROOM/MESSAGES_FETCHED';
const LOAD_MORE_MESSAGES = 'ROOM/LOAD_MORE_MESSAGES';

export const actionTypes = {
  MESSAGE_STATUS_CHANGED,
  FETCHING_MESSAGES,
  MESSAGES_FETCHED,
  LOAD_MORE_MESSAGES,
};

const markMessagesAsRead = ({ roomId, messages, profile }) => {
  messages
    .filter((message) => message.fromUserHash !== profile.hash)
    .filter((message) => message.status !== 3)
    .forEach((message) =>
      socket.emit('messageStatus', message.messageId, roomId, 3)
    );
};

export const getRoomMessages = (roomId) => {
  return (dispatch, getState) => {
    dispatch(fetchingMessages(roomId));

    return ConsultationsApiClient.chat
      .fetchRoomMessages(roomId)
      .then(({ data: messages }) => {
        const {
          session: { profile },
        } = getState();
        markMessagesAsRead({ roomId, messages, profile });
        dispatch(messagesFetched(roomId, messages));
      });
  };
};

export const fetchingMessages = (roomId) => {
  return {
    type: FETCHING_MESSAGES,
    payload: roomId,
  };
};

export const messagesFetched = (roomId, messages) => {
  return {
    type: MESSAGES_FETCHED,
    payload: { messages: messages, roomId: roomId },
  };
};

export const loadMoreMessages = () => {
  return {
    type: LOAD_MORE_MESSAGES,
  };
};

export const messageStatusChanged = (message) => {
  return {
    type: MESSAGE_STATUS_CHANGED,
    payload: message,
  };
};
