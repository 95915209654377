import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import find from 'utils/find';
import getHeaders from 'utils/getHeaders';
import Api from 'api';
import { Notification } from 'api/Consultations';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: Api.NOTIFICATIONS_URL,
    prepareHeaders: (headers) => {
      Object.entries(getHeaders()).forEach(([key, value]) =>
        headers.set(key, value!)
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllNotifications: builder.query<Notification[], void>({
      query: () => ({ url: Api.NOTIFICATIONS_URL }),
      transformResponse: (response: { data: Notification[] }) => response.data,
    }),
  }),
});

export const readNotification = ({ id }: { id: string }) =>
  notificationsApi.util.updateQueryData(
    'getAllNotifications',
    undefined,
    (draftNotifications: Notification[]) => {
      const notification = find({ items: draftNotifications, id });
      draftNotifications[notification.index].read_at = Date.now().toString();
    }
  );

export const { useGetAllNotificationsQuery } = notificationsApi;
