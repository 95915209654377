import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { sendMessage } from 'containers/Room/actions/SendMessage.actions';
import { useEffect } from 'react';
import classnames from 'classnames';
import PaperPlane from 'components/icons/outline/PaperPlane';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import PauseCircle from 'components/icons/outline/PauseCircle';
import Trash from 'containers/Appointments/icons/Trash';
import dayjs from 'dayjs';
import { Message } from 'containers/Room/components/RoomBody/Message';
import Microphone from 'components/icons/outline/Microphone';

interface VoiceNoteProps extends File {
  base64?: string;
  audioType?: string;
}

const VoiceNote = ({
  room,
  profile,
  emptyMessage,
  isAudioRecording,
  setIsAudioRecording,
  message,
  setMessage,
}: {
  room: any;
  profile: any;
  emptyMessage: (room: any, profile: any) => Message;
  isAudioRecording: boolean;
  setIsAudioRecording: (isAudioRecording: boolean) => void;
  message: Message;
  setMessage: (message: Message) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );

  useEffect(() => {
    if (isAudioRecording) {
      recorderControls.startRecording();
    }
  }, [isAudioRecording]);

  const sendAudioElement = (blob: any) => {
    const outputBlob: VoiceNoteProps = new File([blob], 'voice_note', {
      type: 'audio/mp3',
    });
    const reader = new FileReader();
    let file = outputBlob;

    const type = 'file';
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (!reader.result) {
        return;
      }
      file.base64 = reader.result.toString().split('base64,')[1];
      file.audioType = 'voice_note';

      dispatch(
        sendMessage(
          {
            ...message,
            attachment: file,
            base64: file.base64,
            type: type,
          },
          room
        )
      );

      setMessage(emptyMessage(room, profile));
      setIsAudioRecording(false);
    };
  };

  return (
    <div
      className={classnames(
        'flex flex-row items-center relative pt-3 pb-3.5 px-4 bg-white border-t border-separators'
      )}
    >
      {isAudioRecording && (
        <div className="flex flex-row items-center justify-center ">
          <button
            onClick={() => {
              recorderControls.stopRecording();
              setIsAudioRecording(false);
            }}
            className={classnames(
              'px-2 m-1 rounded-full flex items-center justify-center focus:outline-none text-negative'
            )}
          >
            <Trash className="w-4" />
          </button>
          <div
            className={`h-1.5 w-1.5 m-1 bg-${
              !recorderControls.isPaused ? 'negative' : 'gray-medium'
            } rounded-full`}
          />
          <div className="text-gray-dark text-sm m-1 font-medium">
            {dayjs(recorderControls?.recordingTime * 1000).format('mm:ss')}
          </div>
        </div>
      )}
      <div className="flex justify-center items-center rounded-24 bg-background relative h-6 w-full text-xs text-gray font-medium">
        <div>
          {isAudioRecording && !recorderControls?.isPaused
            ? t('room__input_audio_recording')
            : t('room__input_audio_paused')}
        </div>
      </div>
      <div className="hidden">
        <AudioRecorder
          onRecordingComplete={(blob) => {
            sendAudioElement(blob);
          }}
          recorderControls={recorderControls}
        />
      </div>

      {isAudioRecording && (
        <button
          onClick={() => {
            recorderControls.togglePauseResume();
          }}
          className={classnames(
            'w-9 h-8 min-w-9 mx-1 rounded-full flex items-center justify-center focus:outline-none text-blue'
          )}
        >
          {!recorderControls?.isPaused ? (
            <PauseCircle />
          ) : (
            <Microphone className="h-5 text-blue" />
          )}
        </button>
      )}
      <button
        onClick={() => {
          if (isAudioRecording) {
            recorderControls.stopRecording();
          } else {
            recorderControls.startRecording();
            setIsAudioRecording(true);
          }
        }}
        className={classnames(
          'w-9 h-8 min-w-9 m-1 rounded-full flex items-center justify-center focus:outline-none bg-blue text-white'
        )}
      >
        {!isAudioRecording ? (
          <Microphone className="h-5 text-white" />
        ) : (
          <PaperPlane className="h-6 mx-1" />
        )}
      </button>
    </div>
  );
};

export default VoiceNote;
