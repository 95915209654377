import React from 'react';
import IconCamera from 'containers/VideoCall/icons/IconCamera';
import IconMic from 'containers/VideoCall/icons/IconMic';
import IconBlocked from 'containers/VideoCall/icons/IconBlocked';
import { Trans, useTranslation } from 'react-i18next';

const PermissionsModal = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <div className="fixed inset-x-0 bottom-0 z-10 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
      <div className="fixed inset-0 transition-opacity">
        <div
          onClick={onClose}
          className="absolute inset-0 opacity-75 bg-gray-medium"
        />
      </div>
      <div className="max-w-xl p-10 overflow-hidden transition-all transform bg-white shadow-xl text-dark rounded-24 sm:w-full">
        <div className="flex flex-col items-center text-center">
          <div className="flex gap-2">
            <div className="flex items-center justify-center w-20 h-20 mb-8 bg-positive-light rounded-2xl">
              <IconMic />
            </div>
            <div className="flex items-center justify-center w-20 h-20 mb-8 bg-positive-light rounded-2xl">
              <IconCamera />
            </div>
            <div className="flex items-center justify-center w-20 h-20 mb-8 bg-positive-light rounded-2xl">
              <IconBlocked />
            </div>
          </div>
          <div className="mb-6 text-2xl font-medium text-primary">
            {t('videocall__permission_modal_title')}
          </div>
          <div className="mb-12">
            <Trans i18nKey="videocall__permission_modal_text">
              Recuerda activar los permisos de vídeo, micrófono y notificaciones
              en la <strong>Configuración de sitios web</strong>, dentro del
              apartado de <strong>Permisos</strong> en
              <strong>Configuración del Navegador</strong>.
            </Trans>
          </div>
          <button
            onClick={onClose}
            className="flex items-center h-12 px-6 font-medium text-white uppercase border rounded-lg bg-primary border-primary focus:outline-none hover:bg-primary-dark"
          >
            {t('videocall__permission_modal_button')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PermissionsModal;
