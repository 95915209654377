import AccordeonHandler from 'containers/Shared/components/Accordeon/AccordeonHandler';
import {
  AccordeonButton,
  AccordeonHeader,
} from 'containers/Shared/components/Accordeon/AccordeonPanel';
import { useTranslation } from 'react-i18next';
import { usePortal } from 'containers/Shared/components/Portal';
import BottomDrawer from 'components/Drawer/BottomDrawer';
import classnames from 'classnames';
import useDiagnosticTest from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/useDiagnosticTest';
import { useEffect, useState } from 'react';
import Check from 'components/icons/outline/Check';
import CheckCircle from 'components/icons/solid/CheckCircle';
import ReviewContainer from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/ReviewContainer';
import SearchContainer from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/SearchContainer';
import { useSelector } from 'react-redux';
import Download from 'components/Download';
import dayjs from 'dayjs';
import Dropper from 'components/icons/outline/Dropper';
import Loading from 'components/Loading';
import useDownloadDocument from 'containers/AppointmentMeet/Documentation/useDownloadDocument';
import SquareGrid from 'components/icons/solid/SquareGrid';
import TemplateContainer from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/Templates/TemplatesContainer';

export const DiagnosticTestsDrawer = ({
  onClose,
  consultationId,
  consultationType,
  isTemplates = false,
}) => {
  const { categories } = useDiagnosticTest();
  const [currentStatus, setCurrentStatus] = useState('search');

  useEffect(() => {
    isTemplates && setCurrentStatus('templates');
  }, []);

  const getContent = () => {
    switch (currentStatus) {
      case 'templates':
        return (
          <TemplateContainer
            setCurrentStatus={setCurrentStatus}
            isTemplates={isTemplates}
          />
        );
      case 'review':
        return (
          <ReviewContainer
            setCurrentStatus={setCurrentStatus}
            onClose={onClose}
            consultationType={consultationType}
            consultationId={consultationId}
          />
        );
      case 'search':
      default:
        return <SearchContainer setCurrentStatus={setCurrentStatus} />;
    }
  };

  return (
    <BottomDrawer onClose={onClose} isTransparent>
      <div className={classnames('flex w-full max-h-screen')}>
        <div className={classnames('flex w-full')}>
          {categories && (
            <div className="flex flex-col w-full h-full bg-white rounded-t-xl justify-between">
              {getContent()}
            </div>
          )}
        </div>
      </div>
    </BottomDrawer>
  );
};

const DiagnosticTestsContainer = ({
  badge,
  onClose,
  roomId,
  patient,
  consultationType,
  consultationId,
}) => {
  const { t } = useTranslation();
  const portal = usePortal();
  const { handleOnClose, fetchPrescriptions } = useDiagnosticTest();
  const { prescriptions, isLoading } = useSelector(
    (state) => state.diagnosticTests
  );
  const state =
    isLoading || !prescriptions
      ? 'loading'
      : !prescriptions?.length
      ? 'empty'
      : 'loaded';

  const openCreateModal = (status) => {
    portal.open(
      <DiagnosticTestsDrawer
        onClose={handleOnClose}
        consultationId={consultationId}
        consultationType={consultationType}
        isTemplates={status === 'templates'}
      />,
      'diagnostic_tests_drawer'
    );
  };

  useEffect(() => {
    fetchPrescriptions();
  }, []);

  return (
    <div>
      <div className="p-4">
        <AccordeonHeader onClose={onClose} badge={badge}>
          {t('sidebar__patient_diagnostic_tests_title')}
        </AccordeonHeader>
        <div className="flex flex-col gap-2">
          <AccordeonButton
            onClick={() => {
              openCreateModal('search');
            }}
          >
            {t('sidebar__patient_diagnostic_tests_add')}
          </AccordeonButton>
          <AccordeonButton
            onClick={() => {
              openCreateModal('templates');
            }}
          >
            <div className="flex items-center">
              <SquareGrid className="w-4 mr-2" />
              {t('sidebar__patient_diagnostic_tests_template_button')}
            </div>
          </AccordeonButton>
        </div>
      </div>
      <div className="pb-2.5 px-2.5">
        {
          {
            loading: <Loading />,
            empty: <div />,
            loaded: (
              <ul className="flex flex-col gap-4">
                {prescriptions &&
                  [...prescriptions]
                    .sort((a, b) => {
                      return new Date(b.created_at) - new Date(a.created_at);
                    })
                    .map((prescription) => (
                      <Item key={prescription.id} prescription={prescription} />
                    ))}
              </ul>
            ),
          }[state]
        }
      </div>
      <div className="border-b border-separators" />
    </div>
  );
};

const DiagnosticTestPrescriptionsPanel = ({
  badge,
  open,
  onOpen,
  onClose,
  room,
  consultationType,
  consultationId,
}) => {
  const { t } = useTranslation();

  const patient = {
    name: room.title,
  };

  if (!open) {
    return (
      <AccordeonHandler onOpen={onOpen} badge={badge}>
        {t('sidebar__patient_diagnostic_tests_title')}
      </AccordeonHandler>
    );
  }

  return (
    <DiagnosticTestsContainer
      badge={badge}
      onClose={onClose}
      roomId={room.room_id}
      patient={patient}
      consultationType={consultationType}
      consultationId={consultationId}
    />
  );
};

export const DiagnosticTestMenuCategory = ({
  category,
  isActive,
  onClick,
  petitionName,
  isPetition = false,
  savedPetitions,
}) => {
  return (
    <div
      className={classnames(
        'py-4 px-4 cursor-pointer flex items-center justify-between rounded-r-lg mr-2 text-sm',
        {
          'bg-background text-dark font-medium border-l-4 border-primary ':
            isActive,
        }
      )}
      onClick={() =>
        !isPetition ? onClick(category.id) : onClick(petitionName)
      }
    >
      {isPetition ? petitionName : category.value.name}
      {isPetition && (
        <div>
          {!savedPetitions.includes(petitionName) ? (
            <Check className="w-4 h-4 border border-gray-medium/70 text-gray-medium/70 rounded-full" />
          ) : (
            <CheckCircle className="w-4 h-4 text-positive-dark" />
          )}
        </div>
      )}
    </div>
  );
};

export const SidebarTitle = ({ children }) => {
  return (
    <div className="text-lg font-medium tracking-[0.01em] text-dark mt-5 mb-4 ml-6">
      {children}
    </div>
  );
};

export const ContentTitle = ({ children }) => {
  return <div className="text-xl font-medium text-dark mb-6">{children}</div>;
};

const Item = ({ prescription }) => {
  const date = dayjs(prescription.created_at).format('DD/MM/YY');
  const { professional, name, pdf_url } = prescription;
  const { download } = useDownloadDocument({
    url: pdf_url,
    name,
  });
  const { t } = useTranslation();

  return (
    <li className="flex items-center gap-2 text-xs">
      <div className="grid flex-shrink-0 w-10 h-10 rounded-full bg-blue-light place-items-center">
        <Dropper className="w-4 h-4 text-primary" />
      </div>
      <div className="flex flex-col gap-1">
        <p className="font-medium normal-case">
          {t('prescription_modal__item_list_title')}
          <span className="lowercase">{`${name} ${date}`}</span>
        </p>
        <p className="font-medium leading-4 text-gray-medium capitalize">
          {professional?.name}
        </p>
      </div>

      {pdf_url && (
        <Download className="w-10 h-10 ml-auto" onClick={() => download()} />
      )}
    </li>
  );
};

export default DiagnosticTestPrescriptionsPanel;
