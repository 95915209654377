import { useState, useEffect } from 'react';

export default () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(true);
  const [cookies, setCookies] = useState();

  useEffect(() => {
    const localStorageCookies = localStorage.getItem('cookies');
    if (!localStorageCookies) {
      setCookiesAccepted(false);
      return;
    }

    setCookies(JSON.parse(localStorageCookies));
  }, []);

  function acceptCookies(cookies) {
    localStorage.setItem('cookies', JSON.stringify(cookies));
    setCookiesAccepted(true);
    setCookies(cookies);
  }

  return { cookiesAccepted, cookies, acceptCookies };
};
