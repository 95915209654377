const Trash = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="40"
      fill="none"
      viewBox="0 0 37 40"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.6"
        d="M22.5 16l-.6 14m-6.8 0l-.6-14m-8-8l1.876 28.133A2 2 0 0010.37 38H26.63a2 2 0 001.995-1.867L30.5 8m-24 0h6m-6 0h-4m28 0h4m-4 0h-6m0 0V4a2 2 0 00-2-2h-8a2 2 0 00-2 2v4m12 0h-12"
      ></path>
    </svg>
  );
};

export default Trash;
