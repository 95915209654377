const KeyboardUpDown = () => (
  <svg
    width="40"
    height="26"
    viewBox="0 0 40 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="14.2281"
      y="14.1471"
      width="11.543"
      height="11.4706"
      rx="1.91176"
      fill="#90A4AE"
    />
    <path
      d="M19.9626 19.9102L20.8165 19.0564L20.9517 19.1916L20.8165 19.0564C20.8578 19.0151 20.9069 18.9823 20.9609 18.9599C21.015 18.9375 21.0729 18.926 21.1314 18.926C21.1898 18.926 21.2477 18.9375 21.3018 18.9599C21.3558 18.9823 21.4049 19.0151 21.4462 19.0564L21.3112 19.1914L21.4462 19.0564C21.4876 19.0978 21.5204 19.1469 21.5428 19.2009C21.5652 19.2549 21.5767 19.3128 21.5767 19.3713C21.5767 19.4298 21.5652 19.4877 21.5428 19.5417C21.5204 19.5957 21.4876 19.6448 21.4462 19.6862L21.3111 19.551L21.4462 19.6862L20.2764 20.856C20.2764 20.8561 20.2763 20.8561 20.2763 20.8561L20.2762 20.8562L19.9626 19.9102ZM19.9626 19.9102L19.1088 19.0564C18.9326 18.8802 18.6497 18.8876 18.4782 19.0547L18.4782 19.0547L18.4766 19.0563C18.4352 19.0976 18.4023 19.1467 18.3799 19.2008C18.3575 19.2548 18.3459 19.3128 18.3459 19.3713C18.3459 19.4298 18.3575 19.4878 18.3799 19.5418C18.4023 19.5958 18.4351 19.6449 18.4765 19.6862C18.4765 19.6862 18.4765 19.6862 18.4765 19.6862C18.4765 19.6863 18.4766 19.6863 18.4766 19.6863L19.6463 20.856C19.6464 20.8561 19.6464 20.8561 19.6464 20.8561L19.6465 20.8562L19.7817 20.721L19.9626 19.9102ZM20.1319 20.9528L20.0586 20.7762H19.8641L19.7908 20.9528C19.8449 20.9752 19.9028 20.9867 19.9614 20.9867C20.0199 20.9867 20.0778 20.9752 20.1319 20.9528Z"
      fill="white"
      stroke="white"
      strokeWidth="0.382353"
    />
    <rect
      x="14.2281"
      y="14.1471"
      width="11.543"
      height="11.4706"
      rx="1.91176"
      stroke="#546E7A"
      strokeWidth="0.764706"
    />
    <rect
      x="25.7719"
      y="11.8529"
      width="11.543"
      height="11.4706"
      rx="1.91176"
      transform="rotate(-180 25.7719 11.8529)"
      fill="#90A4AE"
    />
    <path
      d="M20.0374 6.08975L19.1835 6.94359L19.0483 6.80841L19.1835 6.94359C19.1422 6.98494 19.0931 7.01774 19.0391 7.04012C18.985 7.0625 18.9271 7.07402 18.8686 7.07402C18.8102 7.07402 18.7523 7.0625 18.6982 7.04012C18.6442 7.01774 18.5951 6.98494 18.5538 6.94359L18.6888 6.80859L18.5538 6.94359C18.5124 6.90224 18.4796 6.85314 18.4572 6.79912C18.4348 6.74509 18.4233 6.68718 18.4233 6.6287C18.4233 6.57022 18.4348 6.51231 18.4572 6.45828C18.4796 6.40426 18.5124 6.35516 18.5538 6.31381L18.6889 6.44899L18.5538 6.31381L19.7236 5.14395C19.7236 5.14393 19.7237 5.14391 19.7237 5.14389L19.7238 5.14381L20.0374 6.08975ZM20.0374 6.08975L20.8912 6.94359C21.0674 7.11983 21.3503 7.11235 21.5218 6.94533L21.5218 6.94534L21.5234 6.94373C21.5648 6.90239 21.5977 6.85328 21.6201 6.79923C21.6425 6.74517 21.6541 6.68722 21.6541 6.6287C21.6541 6.57018 21.6425 6.51223 21.6201 6.45817C21.5977 6.40418 21.5649 6.35512 21.5235 6.31381C21.5235 6.3138 21.5235 6.31379 21.5235 6.31378C21.5235 6.31374 21.5234 6.31371 21.5234 6.31367L20.3537 5.14395C20.3536 5.14393 20.3536 5.14391 20.3536 5.14389L20.3535 5.14381L20.2183 5.27899L20.0374 6.08975ZM19.8681 5.04724L19.9414 5.22383L20.1359 5.22383L20.2092 5.04724C20.1551 5.02482 20.0972 5.01327 20.0386 5.01327C19.9801 5.01327 19.9222 5.02482 19.8681 5.04724Z"
      fill="white"
      stroke="white"
      strokeWidth="0.382353"
    />
    <rect
      x="25.7719"
      y="11.8529"
      width="11.543"
      height="11.4706"
      rx="1.91176"
      transform="rotate(-180 25.7719 11.8529)"
      stroke="#546E7A"
      strokeWidth="0.764706"
    />
    <g opacity="0.5">
      <rect
        x="28.0747"
        y="25.6176"
        width="11.4706"
        height="11.543"
        rx="1.91176"
        transform="rotate(-90 28.0747 25.6176)"
        fill="#90A4AE"
      />
      <path
        d="M33.837 19.8823L32.9832 19.0285L33.1184 18.8933L32.9832 19.0285C32.9418 18.9871 32.909 18.9381 32.8867 18.884C32.8643 18.83 32.8528 18.7721 32.8528 18.7136C32.8528 18.6551 32.8643 18.5972 32.8867 18.5432C32.909 18.4892 32.9418 18.4401 32.9832 18.3987L33.1182 18.5337L32.9832 18.3987C33.0246 18.3574 33.0736 18.3246 33.1277 18.3022C33.1817 18.2798 33.2396 18.2683 33.2981 18.2683C33.3566 18.2683 33.4145 18.2798 33.4685 18.3022C33.5225 18.3246 33.5716 18.3574 33.613 18.3987L33.4778 18.5339L33.613 18.3987L34.7828 19.5686C34.7829 19.5686 34.7829 19.5686 34.7829 19.5686L34.783 19.5687L33.837 19.8823ZM33.837 19.8823L32.9832 20.7362C32.807 20.9124 32.8144 21.1953 32.9815 21.3667L32.9814 21.3667L32.9831 21.3684C33.0244 21.4098 33.0735 21.4426 33.1276 21.4651C33.1816 21.4875 33.2396 21.499 33.2981 21.499C33.3566 21.499 33.4146 21.4875 33.4686 21.4651C33.5226 21.4427 33.5717 21.4099 33.613 21.3685C33.613 21.3685 33.613 21.3685 33.613 21.3685C33.613 21.3684 33.6131 21.3684 33.6131 21.3684L34.7828 20.1986C34.7829 20.1986 34.7829 20.1986 34.7829 20.1986L34.783 20.1985L34.6478 20.0633L33.837 19.8823ZM34.8795 19.7131L34.703 19.7863L34.703 19.9809L34.8795 20.0541C34.902 20.0001 34.9135 19.9421 34.9135 19.8836C34.9135 19.8251 34.902 19.7671 34.8795 19.7131Z"
        fill="white"
        stroke="white"
        strokeWidth="0.382353"
      />
      <rect
        x="28.0747"
        y="25.6176"
        width="11.4706"
        height="11.543"
        rx="1.91176"
        transform="rotate(-90 28.0747 25.6176)"
        stroke="#546E7A"
        strokeWidth="0.764706"
      />
    </g>
    <g opacity="0.5">
      <rect
        x="11.9253"
        y="14.1471"
        width="11.4706"
        height="11.543"
        rx="1.91176"
        transform="rotate(90 11.9253 14.1471)"
        fill="#90A4AE"
      />
      <path
        d="M6.16296 19.8824L7.0168 20.7362L6.88162 20.8714L7.0168 20.7362C7.05815 20.7776 7.09095 20.8267 7.11333 20.8807C7.13571 20.9347 7.14723 20.9926 7.14723 21.0511C7.14723 21.1096 7.13571 21.1675 7.11333 21.2215C7.09095 21.2756 7.05815 21.3247 7.0168 21.366L6.8818 21.231L7.0168 21.366C6.97545 21.4074 6.92636 21.4402 6.87233 21.4625C6.8183 21.4849 6.76039 21.4964 6.70191 21.4964C6.64343 21.4964 6.58552 21.4849 6.5315 21.4625C6.47747 21.4402 6.42838 21.4074 6.38702 21.366L6.52221 21.2308L6.38702 21.366L5.21716 20.1962C5.21714 20.1961 5.21712 20.1961 5.2171 20.1961L5.21702 20.196L6.16296 19.8824ZM6.16296 19.8824L7.0168 19.0286C7.19304 18.8523 7.18556 18.5694 7.01854 18.398L7.01855 18.398L7.01694 18.3964C6.9756 18.355 6.9265 18.3221 6.87244 18.2997C6.81838 18.2772 6.76043 18.2657 6.70191 18.2657C6.64339 18.2657 6.58544 18.2772 6.53138 18.2997C6.47739 18.3221 6.42833 18.3549 6.38702 18.3962C6.38701 18.3963 6.387 18.3963 6.38699 18.3963C6.38695 18.3963 6.38692 18.3963 6.38689 18.3964L5.21716 19.5661C5.21714 19.5661 5.21712 19.5661 5.2171 19.5662L5.21702 19.5662L5.35221 19.7014L6.16296 19.8824ZM5.12045 20.0517L5.29704 19.9784L5.29704 19.7839L5.12045 19.7106C5.09803 19.7647 5.08649 19.8226 5.08649 19.8811C5.08649 19.9397 5.09803 19.9976 5.12045 20.0517Z"
        fill="white"
        stroke="white"
        strokeWidth="0.382353"
      />
      <rect
        x="11.9253"
        y="14.1471"
        width="11.4706"
        height="11.543"
        rx="1.91176"
        transform="rotate(90 11.9253 14.1471)"
        stroke="#546E7A"
        strokeWidth="0.764706"
      />
    </g>
  </svg>
);

export default KeyboardUpDown;
