const Skeleton = () => (
  <div className="flex items-center px-4 py-6 animate-pulse">
    <div className="w-full">
      <div className="h-4 mb-2 rounded-lg w-140 bg-gray-light"></div>
      <div className="w-2/3 h-3 mb-4 rounded-lg bg-gray-light"></div>
      <div className="w-1/2 h-3 mb-2 rounded-lg bg-gray-light"></div>
      <div className="w-3/4 h-3 mb-2 rounded-lg bg-gray-light"></div>
      <div className="w-1/3 h-3 mb-2 rounded-lg bg-gray-light"></div>
    </div>
  </div>
);

export default Skeleton;
