const FolderPlus = (props: any) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.666016 5.43978C0.666016 4.09643 0.666016 3.42476 0.917426 2.90737C1.16232 2.40339 1.56941 1.9963 2.07338 1.75141C2.59078 1.5 3.26245 1.5 4.60579 1.5H5.16536C5.2948 1.5 5.35952 1.5 5.42118 1.50305C6.09372 1.53628 6.72441 1.83941 7.1705 2.34381C7.21139 2.39006 7.25182 2.44059 7.33268 2.54167V2.54167C7.41354 2.64274 7.45397 2.69328 7.49487 2.73952C7.94095 3.24392 8.57165 3.54705 9.24419 3.58029C9.30584 3.58333 9.37056 3.58333 9.5 3.58333H11.3327C13.8325 3.58333 15.0824 3.58333 15.9586 4.21994C16.2416 4.42554 16.4905 4.6744 16.6961 4.95738C17.3327 5.8336 17.3327 7.08351 17.3327 9.58333V10.5C17.3327 12.9998 17.3327 14.2497 16.6961 15.126C16.4905 15.4089 16.2416 15.6578 15.9586 15.8634C15.0824 16.5 13.8325 16.5 11.3327 16.5H6.66602C4.16619 16.5 2.91628 16.5 2.04006 15.8634C1.75708 15.6578 1.50822 15.4089 1.30263 15.126C0.666016 14.2497 0.666016 12.9998 0.666016 10.5V5.43978Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.3"
    />
    <path
      d="M8.7 12.625C8.7 12.8321 8.86789 13 9.075 13C9.28211 13 9.45 12.8321 9.45 12.625V10.45H11.625C11.8321 10.45 12 10.2821 12 10.075C12 9.86789 11.8321 9.7 11.625 9.7H9.45V7.375C9.45 7.16789 9.28211 7 9.075 7C8.86789 7 8.7 7.16789 8.7 7.375V9.7H6.375C6.16789 9.7 6 9.86789 6 10.075C6 10.2821 6.16789 10.45 6.375 10.45H8.7V12.625Z"
      fill="white"
      stroke="#ECEEFD"
      strokeWidth="0.9"
      strokeLinecap="round"
    />
  </svg>
);

export default FolderPlus;
