const ProfileContact = () => {
  return (
    <svg width="80" height="80" fill="none" viewBox="0 0 80 80">
      <mask
        id="mask0"
        width="80"
        height="80"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M40 80c22.091 0 40-17.909 40-40S62.091 0 40 0 0 17.909 0 40s17.909 40 40 40z"
        ></path>
      </mask>
      <g mask="url(#mask0)">
        <path
          fill="#3C50EC"
          fillOpacity="0.1"
          d="M40 80c22.091 0 40-17.909 40-40S62.091 0 40 0 0 17.909 0 40s17.909 40 40 40z"
        ></path>
        <path
          fill="#A58FD3"
          fillRule="evenodd"
          d="M38.386 63.535c-.091-.182-.136-.318-.136-.318-.728 1-3.367-4.427-3.367-4.427s.154-.578-2.339-3.353c.591.046 0 0 0 0v-.09a11.341 11.341 0 01-2.272-.364c-.682-.228-1.318-.682-1.864-1.318-6.09 2.727-10.59 9-12.59 18.09A46.494 46.494 0 0015 77.072c.59 4.137 3.818 8.318 9.636 11.682.227.136 4.159 1.572 4.386 1.709 7.357-15.525 9.364-26.928 9.364-26.928z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#A58FD3"
          fillRule="evenodd"
          d="M48.255 51.824c-1.269-.439-2.105-.439-2.51 0l2.418 3.412c-.637 5.954-12.815 4.302-12.906 4.302l-.213 2.762c-1.727 4.272-6.022 27.98-6.022 28.163 10.954 3.545 25.69 2.562 33.87-2.165 5.364-3.09 5.455-7.409 4.637-11.772-.521-5.765-4.843-21.565-19.274-24.702z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#000"
          fillOpacity="0.1"
          fillRule="evenodd"
          d="M31.638 54.602s-2.47 4.217.93 6.634c3.402 2.417 12.226 1.966 15.596-.81 5.11-4.21.09-7.975.09-7.975l-10.797 3.813-5.82-1.662h.001z"
          clipRule="evenodd"
        ></path>
        <mask
          id="mask1"
          width="17"
          height="16"
          x="32"
          y="45"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M32.499 55.392v.318c0 3.1 1.242 4.015 1.986 4.31.234.092 1.644.465 1.894.485 12.789 1.033 12.174-4.756 12.174-6.157v-.227l-.14-2.017-.049-6.874c-3.317 7.09-10.502 10.48-15.865 10.162z"
          ></path>
        </mask>
        <g mask="url(#mask1)">
          <path
            fill="#F4EFFF"
            d="M32.499 55.392v.318c0 3.1 1.242 4.015 1.986 4.31.234.092 1.644.465 1.894.485 12.789 1.033 12.174-4.756 12.174-6.157v-.227l-.14-2.017-.049-6.874c-3.317 7.09-10.502 10.48-15.865 10.162z"
          ></path>
          <path
            fill="#EBE0FF"
            fillRule="evenodd"
            d="M46.373 48.06c-.837 6.692-2.3 10.666-4.393 11.921-2.09 1.255-4.183 2.092-6.274 2.51l-5.647-6.902 2.51-5.02 13.804-2.51v.001z"
            clipRule="evenodd"
          ></path>
        </g>
        <path
          fill="#311371"
          fillRule="evenodd"
          d="M41.59 26.484c3.227-1.363 8.544-3.772 11.68-4.363-5 1.5-8.272 4.682-9.908 5.409 1.682 1.272 2.773 6.408 2.455 8.272-.319 1.864.181 4.863 1.636 5.318 1-3.364 5.363-5.863 7.045-3.364.363.955.454 1.773.409 2.546 0 0 10.317-14.772.59-19.045.729-3.408-1.18-9.499-12.543-9.59-6.909.273-6.682 2.182-9.59 1.182-1.046-.955-5.773-2.59-8.954 1.409-3.182 4-1.182 9.318-.5 11.09 4.5 2.318 14.408 2.545 17.68 1.136z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#F4EFFF"
          fillRule="evenodd"
          d="M30.272 55.028a11.34 11.34 0 002.273.364c5.363.318 13-2.682 16.362-9.727.045-.136.136-.273.182-.364 1.545.137 5.59-1.408 5.863-5 .046-.772-.045-1.59-.409-2.544-1.68-2.5-6.045 0-7.045 3.363-1.454-.455-1.954-3.454-1.636-5.318.318-1.864-.773-7-2.455-8.272 1.637-.682 4.91-3.864 9.91-5.41-3.137.592-8.455 3.001-11.682 4.365-3.227 1.362-13.18 1.181-17.635-1.182 0 0-.364 6.409.363 9.408-.318 2.41.455 14.727 4.09 18.954.5.681 1.092 1.136 1.82 1.363z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default ProfileContact;
