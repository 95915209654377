import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  ModalBody,
  ModalFooter,
} from 'containers/Shared/components/Modal/Modal';
import Button from 'components/Button';
import Form from 'components/Form';
import useQuery from 'utils/useQuery';
import api from 'api';

export type Fields = {
  name: string;
  first_last_name: string;
  second_last_name: string;
  phone: string;
  email: string;
  document_type: string;
  document_id: string;
  birthdate: string;
  nhc: string;
  center_code: string;
};

export interface Props {
  onSubmit: (fields: Fields) => void;
  contactDetails: any;
}
export const SearchForm: FC<Props> = ({ onSubmit, contactDetails }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    errors,
    formState: { dirtyFields },
  } = useForm();

  const { data, isLoading }: any = useQuery(
    api.license.organization.centers.get
  );

  const documentTypes = [
    { key: 'dni', name: 'DNI' },
    { key: 'nie', name: 'NIE' },
    { key: 'passport', name: 'Pasaporte' },
  ];

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <div className="flex flex-col items-center">
          <h2 className="my-6 text-sm">
            {t('invite__vithas_contact_form_description_not_found')}
          </h2>
          <Form.Field
            type="text"
            name="name"
            label={t('invite__vithas_contact_form_name_label')}
            placeholder={t('invite__vithas_contact_form_name_placeholder')}
            errors={errors.name}
            isDirty={dirtyFields.name}
            register={register({ required: true })}
          />
          <Form.Group>
            <Form.Field
              type="text"
              name="first_last_name"
              label={t('invite__vithas_contact_form_first_last_name_label')}
              placeholder={t(
                'invite__vithas_contact_form_first_last_name_placeholder'
              )}
              errors={errors.first_last_name}
              isDirty={dirtyFields.first_last_name}
              register={register({ required: true })}
            />
            <Form.Field
              type="text"
              name="second_last_name"
              label={t('invite__vithas_contact_form_second_last_name_label')}
              placeholder={t(
                'invite__vithas_contact_form_second_last_name_placeholder'
              )}
              errors={errors.second_last_name}
              isDirty={dirtyFields.second_last_name}
              register={register()}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              type="text"
              name="phone"
              label={t('invite__vithas_contact_form_phone_label')}
              placeholder={t('invite__vithas_contact_form_phone_placeholder')}
              errors={errors.phone}
              isDirty={dirtyFields.phone}
              register={register({ required: true })}
            />
            <Form.Field
              type="email"
              name="email"
              label={t('invite__vithas_contact_form_email_label')}
              placeholder={t('invite__vithas_contact_form_email_placeholder')}
              errors={errors.email}
              isDirty={dirtyFields.email}
              register={register({ required: true })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              type="select"
              name="document_type"
              label={t('invite__vithas_contact_form_document_type_label')}
              placeholder={t(
                'invite__vithas_contact_form_document_type_placeholder'
              )}
              options={documentTypes}
              errors={errors.document_type}
              defaultValue={documentTypes[0].key}
              isDirty={dirtyFields.document_type}
              register={register({ required: true })}
            />
            <Form.Field
              type="text"
              name="document_id"
              label={t('invite__vithas_contact_form_document_id_label')}
              placeholder={t(
                'invite__vithas_contact_form_document_id_placeholder'
              )}
              errors={errors.document_id}
              isDirty={dirtyFields.document_id}
              register={register({ required: true })}
            />
          </Form.Group>
          <Form.Date
            label={t('invite__vithas_contact_form_birthdate_label')}
            name="birthdate"
            errors={errors.birthdate}
            isDirty={dirtyFields.birthdate}
            register={register({ required: true })}
          />
          <Form.Group>
            <Form.Field
              type="text"
              name="nhc"
              label={t('invite__vithas_contact_form_nhc_label')}
              placeholder={t('invite__vithas_contact_form_nhc_placeholder')}
              errors={errors.nhc}
              defaultValue={contactDetails?.nhc}
              isDirty={dirtyFields.nhc}
              register={register({ required: true })}
            />
            <Form.Field
              type="select"
              name="center_code"
              label={t('invite__vithas_contact_form_center_code_label')}
              placeholder={t(
                'invite__vithas_contact_form_center_code_placeholder'
              )}
              isLoading={isLoading}
              options={data?.data?.data
                ?.filter((center: any) => center.external_code)
                .map((center: any) => ({
                  key: center.external_code,
                  name: center.name,
                }))}
              errors={errors.center_code}
              defaultValue={contactDetails?.center_code}
              isDirty={dirtyFields.center_code}
              register={register({ required: true })}
            />
          </Form.Group>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="submit">
          {t('invite__vithas_contact_form_button_invite')}
        </Button>
      </ModalFooter>
    </Form>
  );
};

export default SearchForm;
