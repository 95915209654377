import axios from 'axios';
import getHeaders from 'utils/getHeaders';
import { browserName } from 'react-device-detect';
import { config } from 'config';

const BASE_URL = config.services.mediquo.api_url;

const register = ({ sessionId }: any) =>
  axios({
    method: 'POST',
    url: `${BASE_URL}api/installations`,
    headers: getHeaders(),
    data: {
      installation_guid: sessionId,
      language_code: navigator.language,
      os_name: navigator.platform,
      os_version: navigator.appVersion,
      app_version: 'console',
      device_model: browserName,
      device_id: sessionId,
      brand: navigator.vendor,
    },
  });

export default {
  register,
};
