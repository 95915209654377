const Bell = (props: any) => (
  <svg fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M20.088 14.926c-.685-.655-1.387-1.333-1.387-4.533A6.424 6.424 0 0017.11 6.18a6.76 6.76 0 00-4.012-2.218l-.04-.005a1.228 1.228 0 00-.397-1.778 1.313 1.313 0 00-1.33.002c-.2.119-.363.288-.471.49a1.221 1.221 0 00.082 1.29l-.003-.004c-3.216.519-5.637 3.202-5.642 6.436 0 3.2-.704 3.88-1.387 4.534-.41.351-.7.815-.83 1.33a2.545 2.545 0 00.093 1.554c.193.496.537.924.986 1.226a2.74 2.74 0 001.527.463h3.742c0 .663.271 1.299.753 1.768A2.608 2.608 0 0012 22c.682 0 1.335-.263 1.817-.732a2.466 2.466 0 00.753-1.768h3.745c.545 0 1.078-.162 1.526-.463.45-.302.793-.729.986-1.225a2.545 2.545 0 00.095-1.552 2.601 2.601 0 00-.828-1.33l-.003-.003h-.002zm-8.09 5.511a.98.98 0 01-.68-.275.927.927 0 01-.283-.662h1.929a.927.927 0 01-.283.663.98.98 0 01-.682.274zm6.307-2.813H5.687a.774.774 0 01-.419-.126.743.743 0 01-.278-.33.72.72 0 01.162-.802C6.3 15.25 7.224 14.19 7.224 10.391a4.58 4.58 0 011.398-3.283 4.844 4.844 0 013.376-1.36c1.266 0 2.48.489 3.376 1.36a4.58 4.58 0 011.398 3.283c0 3.815.934 4.867 2.072 5.975a.72.72 0 01.162.802.743.743 0 01-.278.33.774.774 0 01-.42.126h-.003z"
    ></path>
  </svg>
);

export default Bell;
