import { createSelector } from 'reselect';

export const getProfile = (state) => state.session.profile;

export const getPrescriptionValidationStatus = (state) =>
  state.session.profile.prescription_validation_status;

export const getWorPracticeCountry = (state) =>
  state.session.profile.workPracticeCountry;

export const getKYCStatus = (state) => state.session.profile.kyc_status;

export const getHash = (state) => state.session.profile.hash;

export const getIsPaymentValidated = createSelector(
  getWorPracticeCountry,
  getKYCStatus,
  (country, status) => country === 'es' || status === 'success'
);

export const getHasToRegister = createSelector(
  getWorPracticeCountry,
  getPrescriptionValidationStatus,
  (country, status) => country?.toLowerCase() === 'es' && status !== 'validated'
);
