class RestApi {
  constructor(client, url) {
    this.url = url;
    this.client = client;
  }

  index(params) {
    return this.client
      .get(this.url, { params })
      .then((response) => response.data);
  }

  show(id) {
    return this.client
      .get(`${this.url}/${id}`)
      .then((response) => response.data);
  }

  store(data) {
    return this.client.post(this.url, data).then((response) => response.data);
  }

  update(id, data) {
    return this.client
      .put(`${this.url}/${id}`, data)
      .then((response) => response.data);
  }

  delete(id) {
    return this.client
      .delete(`${this.url}/${id}`)
      .then((response) => response.data);
  }
}

export default RestApi;
