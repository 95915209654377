import diagnostics from 'api/diagnostics';
import Modal from 'components/Modal';
import SearchInput from 'components/SearchInput';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import Spinner from 'components/Spinner';
import classNames from 'classnames';
import useInfiniteScroll from 'utils/useInfiniteScroll';

export interface Props {
  onClose: () => void;
  onSetDiagnostic: (diagnostic: any) => void;
}

type SearchResultProps = {
  state: {
    term: string;
    lang: string;
    page: number;
    data?: any;
    total?: number;
    isLoading: boolean;
  };
  onClose: () => void;
  onSetDiagnostic: (diagnostic: any) => void;
  onMore: () => void;
};

const AnalysisModal = ({ onClose, onSetDiagnostic }: Props) => {
  const [state, setState] = useState<{
    term: string;
    lang: string;
    page: number;
    data?: any;
    total?: number;
    isLoading: boolean;
  }>({
    term: '',
    lang: 'es',
    page: 1,
    data: [],
    total: 0,
    isLoading: false,
  });
  const lang = i18n.language.toString().slice(0, 2);

  const { t } = useTranslation();

  const searchByTerm = (term: string) => {
    setState({
      ...state,
      isLoading: true,
    });
    if (term.length >= 3) {
      diagnostics
        .get({ term, lang: lang, page: state.page })
        .then((res: any) => {
          const data = res.data;
          setState({
            ...state,
            term,
            data:
              state.term === term && data.data
                ? [...state.data, ...data.data]
                : data.data,
            page: state.term === term ? state.page : 1,
            total: data.meta.total,
            isLoading: false,
          });
        });
    } else {
      setState({
        ...state,
        term,
        lang: lang,
        page: 1,
        data: [],
        isLoading: false,
      });
    }
  };

  const more = () =>
    state.data.length !== state.total &&
    setState((state) => ({ ...state, page: state.page + 1 }));

  useEffect(() => {
    searchByTerm(state.term);
  }, [state.page]);

  return (
    <Modal
      onClose={onClose}
      isTransparent={true}
      className=""
      position="top"
      backdropDark
    >
      <SearchInput
        placeholder={t('report__modal_form_field_analysis_label')}
        onSearch={searchByTerm}
        onReset={() => searchByTerm('')}
        divClassName=""
        inputClassName="py-7 text-lg font-light"
      />
      <SearchResult
        state={state}
        onClose={onClose}
        onSetDiagnostic={onSetDiagnostic}
        onMore={more}
      />
    </Modal>
  );
};

const isLast = (array: any[], index: number) => index === array?.length - 1;

const SearchResult = ({
  state,
  onClose,
  onMore,
  onSetDiagnostic,
}: SearchResultProps) => {
  const { t } = useTranslation();
  const { term, data, total, isLoading } = state;

  const infiniteScrollRef = useInfiniteScroll({
    onIntersect: onMore,
    isActive: !isLoading,
  });
  return (
    <div className="mt-2 rounded-lg text-gray-dark font-light">
      {term.length < 3 ? (
        <div className="bg-background rounded-t-lg p-4">
          {t('report__modal_form_field_analysis_search_empty')}
        </div>
      ) : (
        <div className=" ">
          {data?.length ? (
            <div className="pt-2 rounded-t-lg">
              <div className="max-h-[545px] pt-2 rounded-t-lg bg-white overflow-y-scroll">
                {data.map((diagnostic: any, diagnosticIndex: number) => {
                  return (
                    <button
                      key={diagnostic.code}
                      className={classNames(
                        'px-4 py-2 w-full text-left text-md text-dark hover:bg-background focus:outline-none whitespace-nowrap overflow-hidden overflow-ellipsis',
                        isLoading ? 'text-gray-medium' : 'text-dark'
                      )}
                      onClick={() => {
                        onSetDiagnostic?.(diagnostic);
                        onClose();
                      }}
                      ref={
                        isLast(data, diagnosticIndex)
                          ? infiniteScrollRef
                          : undefined
                      }
                    >
                      {diagnostic.text}
                      <span
                        className={classNames(
                          'uppercase ml-2',
                          isLoading ? 'text-gray-dark' : 'text-primary'
                        )}
                      >
                        {diagnostic.code}
                      </span>
                    </button>
                  );
                })}
              </div>
              <div className="bg-background flex justify-end text-sm px-4 py-2">
                {isLoading ? (
                  <div className="flex flex-row items-center">
                    <Spinner className="animate-spin h-3 w-3 mr-2 text-white" />
                    {t(
                      'report__modal_form_field_analysis_search_loading_results'
                    )}
                  </div>
                ) : (
                  t('report__modal_form_field_analysis_search_total_results', {
                    total,
                  })
                )}
              </div>
            </div>
          ) : (
            <div className="bg-background rounded-t-lg p-4">
              {t('report__modal_form_field_analysis_search_no_results')}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AnalysisModal;
