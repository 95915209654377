import { applyMiddleware, compose, createStore } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from 'legacy/index.Dux';
import thunk from 'redux-thunk';
import { invitationsApi } from 'services/invitations'
import { contactsApi } from 'services/contacts'
import { legacyReportsApi} from 'services/reports.legacy'
import { reportsApi } from 'services/reports'
import {schedulesApi } from 'services/schedules'
import { notificationsApi } from 'services/notifications'
import { organizationGuidesApi } from 'services/organizationGuides';

// create middlewares
let middleware = [];
let composeEnhancers = compose;
let reducer = rootReducer;

middleware = [thunk, ...middleware];

// add persistor
let persistConfig = {
  key: 'root',
  storage,
  whitelist: ['session', 'auth', 'console', 'notification', "consultations", "pending", 'medicalReports'],
};
// if (import.meta.env.NODE_ENV !== 'production') persistConfig = {key: 'root', storage, blacklist: ['']}; // TODO: find a better way and decide what to persist
reducer = persistReducer(persistConfig, reducer);

if (import.meta.env.NODE_ENV !== 'production') {
  const devMiddleware = [reduxImmutableStateInvariant()];
  middleware = [
    ...middleware,
    ...devMiddleware,
    invitationsApi.middleware,
    contactsApi.middleware,
    legacyReportsApi.middleware,
    reportsApi.middleware,
    schedulesApi.middleware,
    organizationGuidesApi.middleware,
    notificationsApi.middleware
  ];
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
}

let store = createStore(
  reducer,
  {},
  composeEnhancers(
    applyMiddleware(...middleware)
  )
);

export default store;
