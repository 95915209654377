import { useTranslation } from 'react-i18next';

import {
  AccordeonHeader,
  AccordeonBody,
} from 'containers/Shared/components/Accordeon/AccordeonPanel';
import AccordeonHandler from 'containers/Shared/components/Accordeon/AccordeonHandler';
import AttachmentItem from 'containers/DetailBar/PatientBar/components/Attachments/AttachmentItem';

const AttachmentsPanel = ({ room, open, onOpen, onClose }) => {
  const attachments = room.messages.filter((m) =>
    ['image', 'file'].includes(m.type)
  );
  const badge = attachments.length;
  const { t } = useTranslation();

  if (!open) {
    return (
      <AccordeonHandler onOpen={onOpen} badge={badge}>
        {t('sidebar__patient_attachments_title')}
      </AccordeonHandler>
    );
  }

  return (
    <div>
      <div className="p-4">
        <AccordeonHeader onClose={onClose} badge={badge}>
          {t('sidebar__patient_attachments_title')}
        </AccordeonHeader>
        <AccordeonBody show={badge === 0}>
          {t('sidebar__patient_attachments_empty_text')}
        </AccordeonBody>
      </div>
      <div className="flex flex-col border-b border-separators">
        <div className="max-w-full px-4 overflow-x-hidden">
          {attachments.reverse().map((att) => (
            <AttachmentItem key={att.messageId} attachment={att} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AttachmentsPanel;
