import EmptyFilter from 'components/images/EmptyFilter';
import { useTranslation } from 'react-i18next';

const ClinicalCourseNotFound = () => {
  const { t } = useTranslation();

  return (
    <article className="p-6 text-dark flex flex-col w-full">
      <div className="flex justify-center center text-center align-middle items-center flex-col mt-[100px]">
        <EmptyFilter />
        <h2 className="text-[#90A4AE] text-lg font-medium leading-6 w-60 mt-9">
          {t('clinical__course_detail_no_results_found')}
        </h2>
      </div>
    </article>
  );
};

export default ClinicalCourseNotFound;
