const VideoCamera = (props: any) => (
  <svg fill="none" viewBox="0 0 18 16" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10 2.333a2 2 0 012 2v7.334a2 2 0 01-2 2H2.5a2 2 0 01-2-2V4.333a2 2 0 012-2H10zM3.5 4.5A.5.5 0 014 4h2a.5.5 0 010 1H4a.5.5 0 01-.5-.5z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M13 10l1.456 1.909c.26.272.626.424 1.006.424.748 0 1.371-.583 1.371-1.326V4.993c0-.387-.174-.751-.472-1.002l-.09-.07a1.406 1.406 0 00-1.815.17L13 6v4z"
    ></path>
  </svg>
);

export default VideoCamera;
