const CheckCircle = (props: any) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="5" cy="5" r="5" fill="currentColor" />
    <path
      d="M7.31491 3.09634L4.04703 6.36421L2.896 5.21649C2.767 5.08749 2.55863 5.08749 2.42963 5.21649C2.30064 5.34548 2.30064 5.55386 2.42963 5.68286L3.8122 7.06542C3.94119 7.19441 4.14957 7.19441 4.27856 7.06542L7.78128 3.56601C7.91027 3.43702 7.91027 3.22864 7.78128 3.09964H7.77797C7.65228 2.96734 7.44391 2.96734 7.31491 3.09634Z"
      fill="white"
    />
  </svg>
);
export default CheckCircle;
