import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

const BASE_URL = config.services.mediquo.chat_url;

const prescriptionBaseUrl = (roomId?: number | string) =>
  roomId !== undefined
    ? `${BASE_URL}/professionals/v1/rooms/${roomId}/prescriptions`
    : `${BASE_URL}/professionals/v1/prescriptions`;

type SetupProps = {
  contactId?: string;
  patientHash?: string;
};

export const enum SetupErrorCode {
  Unauthorized = 'unauthorized',
  ProfessionalNotFound = 'professional_not_found',
  PatientFieldsError = 'patient_fields_error',
  GeneralError = 'general_error',
}

const setup = ([setup]: [setup: SetupProps]) => {
  const { contactId, patientHash } = setup;

  return axios({
    method: 'GET',
    url: `${BASE_URL}/professionals/v2/prescriptions/setup`,
    params: {
      contact_id: contactId,
      patient_hash: patientHash,
    },
    headers: getHeaders(),
  });
};

const list = ({ roomId }: { roomId: string | number }) =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/professionals/v1/rooms/${roomId}/prescriptions`,
    headers: getHeaders(),
  });

const create = ({
  contactId,
  roomId,
  data,
  provider,
  consultationType,
  consultationId,
}: {
  contactId: number;
  roomId?: number;
  data: any;
  provider: any;
  consultationType?: string;
  consultationId?: string;
}) =>
  axios({
    method: 'POST',
    url: prescriptionBaseUrl(roomId),
    headers: getHeaders(),
    data: {
      contact_id: roomId ? undefined : contactId,
      data,
      provider,
      consultation_type: consultationType,
      consultation_id: consultationId,
    },
  });

const updateRempe = ({
  contactId,
  consultationType,
  consultationId,
}: {
  contactId: string;
  consultationType?: string;
  consultationId?: string;
}) =>
  axios({
    method: 'POST',
    url: `${prescriptionBaseUrl()}/rempe`,
    headers: getHeaders(),
    data: {
      contact_id: contactId,
      consultation_type: consultationType,
      consultation_id: consultationId,
    },
  });

export default {
  setup,
  create,
  list,
  updateRempe,
};
