import { Trans } from 'react-i18next';
import IconChatChecked from 'containers/Inbox/icons/IconChatChecked';

const MarkRoomAsRead = ({ rooms }) => (
  <div className="flex items-center justify-center text-positive">
    <IconChatChecked />
    <div className="ml-3">
      <Trans i18nKey="toast__mark_as_read_text" count={rooms.length}>
        Has marcado como leído
        <span className="font-medium lowercase">
          {` ${rooms.length} consultas.`}
        </span>
      </Trans>
    </div>
  </div>
);

export default MarkRoomAsRead;
