import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import Api from 'api/index';
import {
  getRoomsList,
  getSummaryRoomsList,
} from 'containers/Inbox/actions/GetRoomList.actions';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ContactForm from 'containers/Invitations/ContactForm';
import InvitationSent from 'containers/Invitations/InvitationSent';
import Tracker from 'utils/Tracking';
import CRMTracker from 'utils/CRMTracker';
import GoogleTagManager from 'utils/GoogleTagManager';
import { SendType, TargetType } from 'containers/Invitations/Invitations';
import { addInvitation } from 'services/invitations';

export interface Props {
  onClose: () => void;
  target: TargetType;
  send: SendType;
}

const CreateContact: FC<Props> = ({ onClose, target, send }) => {
  const { t } = useTranslation();
  const [roomInvited, setRoomInvited] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const sendInvitation = ({ name, lastName, phone, email, tag }: any) => {
    Tracker.event('contact create save');
    CRMTracker.event('InviteSms');
    const fullName = `${name} ${lastName}`.trim();
    Api.invite({
      name: fullName,
      phone,
      target,
      email,
      tag,
    }).then(
      ({
        data: {
          data: { room_id, id },
        },
      }) => {
        const created = new Date().toISOString();
        dispatch(
          addInvitation({
            id,
            phone,
            email,
            target,
            name: fullName,
            created_at: created,
            updated_at: created,
            last_sent_at: created,
            status: 'pending',
            total_invitation_attempts: 1,
          })
        );
        setRoomInvited(room_id);
      }
    );
  };

  const viewChat = () => {
    Tracker.event('invite success button');
    CRMTracker.event('InviteSuccess');
    GoogleTagManager.conversion('dsXbCKWw6r8CEPKinbUC');
    handleOnClose();
    history.push(`/chat/${roomInvited}`);
  };

  const handleOnClose = () => {
    dispatch(getRoomsList());
    dispatch(getSummaryRoomsList());
    onClose && onClose();
  };

  return (
    <Modal onClose={handleOnClose} width="narrow">
      <Modal.Header onClose={handleOnClose}>
        {t('invite__contact_form_title')}
      </Modal.Header>
      {!roomInvited ? (
        <ContactForm send={send} target={target} onSubmit={sendInvitation} />
      ) : (
        <InvitationSent send={send} onConfirm={viewChat} />
      )}
    </Modal>
  );
};

export default CreateContact;
