export default (str) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(str);
    return;
  }

  navigator.clipboard.writeText(str).then(
    () => {},
    (error) => console.error('Async: Could not copy text: ', error)
  );
};

const fallbackCopyTextToClipboard = (text) => {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};
