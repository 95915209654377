import { useTranslation } from 'react-i18next';
import useDiagnosticTest from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/useDiagnosticTest';
import Button from 'components/Button';
import { ContentTitle } from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/DiagnosticTestPrescriptionsPanel';
import BottomDrawer from 'components/Drawer/BottomDrawer';
import classnames from 'classnames';
import DiagnosticTestBody from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/DiagnosticTestBody';
import { useEffect, useState } from 'react';

type EditDrawerProps = {
  onClose: () => void;
  petition: any;
  setCurrentStatus: (status: string) => void;
  deletePetition: (petition: any) => void;
};

type EditPetitionProps = {
  onClose: () => void;
  petition: any;
  categories?: any;
  activeNavId?: any;
  activeRootCategoryId?: any;
  items?: any;
  selected_items?: any;
  handleOnCancel?: () => void;
  setCurrentStatus: (status: string) => void;
  previousSelectedItems: any[];
  deletePetition: (petition: any) => void;
};

const EditPetitionDrawer = ({
  onClose,
  petition,
  setCurrentStatus,
  deletePetition,
}: EditDrawerProps) => {
  const {
    selected_items,
    categories,
    fetchCategories,
    setRootCategory,
    setActiveNavId,
    activeRootCategoryId,
    activeNavId,
    items,
    fetchItems,
    handleSetSelectedItems,
  } = useDiagnosticTest();
  const [previousSelectedItems, setPreviousSelectedItems] = useState<any>([]);

  useEffect(() => {
    if (!categories.length) fetchCategories();
    setPreviousSelectedItems(selected_items);
  }, []);

  useEffect(() => {
    if (selected_items.length && categories.length) {
      const item = selected_items.find(
        (item: any) => item.meta.component_name === petition.name
      );
      const selectedCategoryTree = categories?.find((category) => {
        return category.find(item?.category_id);
      });

      setRootCategory(
        selectedCategoryTree?.root.id,
        selectedCategoryTree?.root.value.name
      );
      fetchItems({ categoryId: selectedCategoryTree?.root.id });
      const nav = selectedCategoryTree?.findNav(item?.category_id);
      setActiveNavId(nav?.value.id);
    }
  }, [categories]);

  const handleOnCancel = () => {
    handleSetSelectedItems(previousSelectedItems);
    onClose();
  };

  return (
    <BottomDrawer onClose={handleOnCancel} isTransparent>
      <div className={classnames('flex w-full max-h-screen')}>
        <div className={classnames('flex w-full')}>
          {categories && (
            <div className="flex flex-col w-full h-full bg-white rounded-t-xl justify-between">
              <EditContainer
                onClose={onClose}
                petition={petition}
                categories={categories}
                activeNavId={activeNavId}
                activeRootCategoryId={activeRootCategoryId}
                items={items}
                selected_items={selected_items}
                handleOnCancel={handleOnCancel}
                setCurrentStatus={setCurrentStatus}
                previousSelectedItems={previousSelectedItems}
                deletePetition={deletePetition}
              />
            </div>
          )}
        </div>
      </div>
    </BottomDrawer>
  );
};

const EditContainer = ({
  onClose,
  petition,
  categories,
  activeNavId,
  activeRootCategoryId,
  items,
  handleOnCancel,
  selected_items,
  setCurrentStatus,
  previousSelectedItems,
  deletePetition,
}: EditPetitionProps) => {
  const { t } = useTranslation();

  const handleOnSave = () => {
    if (!selected_items.length) {
      setCurrentStatus('search');
    }
    if (selected_items.length < previousSelectedItems.length) {
      const isIncluded = selected_items.some((item: any) => {
        return item.meta.component_name === petition.name;
      });
      if (!isIncluded) {
        deletePetition(petition);
      }
    }
    onClose();
  };

  return (
    <div className="h-full">
      <div className="flex flex-row w-full h-[78vh] max-h-[78vh]">
        <div className="w-full px-8 pt-10">
          <div className="flex flex-row justify-between">
            <ContentTitle>
              {t('prescription_modal__edit_petition_modal_title')}
              <span className="lowercase">{petition.name}</span>
            </ContentTitle>
          </div>
          <div className="h-5/6 overflow-y-auto">
            <DiagnosticTestBody
              categories={categories}
              activeNavId={activeNavId}
              activeRootCategoryId={activeRootCategoryId}
              items={items}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-row bottom-0 justify-between items-center px-6 h-20 max-h-20 border-t border-blue-light shrink-0">
        <Button onClick={handleOnCancel} variant="secondary">
          {t('prescription_modal__edit_petition_modal_cancel_button')}
        </Button>
        <Button onClick={handleOnSave}>
          {t('prescription_modal__edit_petition_modal_save_button')}
        </Button>
      </div>
    </div>
  );
};

export default EditPetitionDrawer;
