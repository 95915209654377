const DownloadBox = (props: any) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.4708 4.19466L12.4715 1.52866C12.4097 1.46663 12.3362 1.41744 12.2553 1.38392C12.1744 1.3504 12.0877 1.3332 12.0002 1.33333H4.00016C3.9126 1.3332 3.82588 1.3504 3.74499 1.38392C3.6641 1.41744 3.59063 1.46663 3.52883 1.52866L1.5295 4.19466C1.46721 4.25645 1.41781 4.32998 1.38416 4.41101C1.35052 4.49203 1.3333 4.57893 1.3335 4.66666V12.6667C1.3335 13.402 1.9315 14 2.66683 14H13.3335C14.0688 14 14.6668 13.402 14.6668 12.6667V4.66666C14.667 4.57893 14.6498 4.49203 14.6162 4.41101C14.5825 4.32998 14.5331 4.25645 14.4708 4.19466ZM4.66683 2.66666H11.3335L12.3908 4H3.6095L4.66683 2.66666ZM2.66683 12.6667V5.33333H13.3335L13.3348 12.6667H2.66683Z"
      fill="currentColor"
    />
    <path
      d="M8.6665 7.33334C8.6665 6.96515 8.36803 6.66667 7.99984 6.66667C7.63165 6.66667 7.33317 6.96515 7.33317 7.33334V9.33334C6.44894 9.33334 6.05835 10.4468 6.74882 10.9992L7.68749 11.7501C7.8701 11.8962 8.12958 11.8962 8.31218 11.7501L9.25085 10.9992C9.94132 10.4468 9.55073 9.33334 8.6665 9.33334V7.33334Z"
      fill="currentColor"
    />
  </svg>
);
export default DownloadBox;
