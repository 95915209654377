import api from 'api';
import { getRoom } from 'containers/Inbox/actions/GetRoomList.actions';
import { joinRoom } from 'containers/Room/actions/JoinRoom.actions';

const CONTACT_NAME_UPDATED = 'CONTACT_NAME_UPDATED';

export const actionTypes = {
  CONTACT_NAME_UPDATED,
};

export const updateContactName = (updatedRoom) => {
  const { contact_id, name, external_id, email, room_id } = updatedRoom;
  return (dispatch) => {
    api.contacts
      .update({ contact_id, name, external_id, email })
      .then(() => dispatch(getRoom({ roomId: room_id })))
      .then(() => dispatch(joinRoom(room_id)))
      .then(() => dispatch(contactNameUpdated(updatedRoom)));
  };
};

export const contactNameUpdated = (room) => {
  return {
    type: CONTACT_NAME_UPDATED,
    payload: room,
  };
};
