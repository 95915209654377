import Button from 'components/Button';
import Modal from 'components/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AllergyModal = ({ onClose, onSubmit }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { t } = useTranslation();

  const submit = () => onSubmit({ name, description, severity: 1 });

  return (
    <Modal width="medium" onClose={onClose}>
      <Modal.Header onClose={onClose}>
        {t('sidebar__patient_add_allergy_title')}
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2">
          <label className="pb-2 font-medium">
            {t('sidebar__patient_add_allergy_name')}
          </label>
          <input
            autoFocus
            placeholder={t('sidebar__patient_add_allergy_name_placeholder')}
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
            className="w-full p-2 border rounded-md border-separators"
          />
        </div>
        <div>
          <label className="pb-2 font-medium">
            {t('sidebar__patient_add_allergy_description')}
          </label>
          <textarea
            placeholder={t(
              'sidebar__patient_add_allergy_description_placeholder'
            )}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            className="w-full p-2 border rounded-md border-separators"
            rows="3"
          ></textarea>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={!name} onClick={submit}>
          {t('sidebar__patient_add_allergy_accept')}
        </Button>
        <Button variant="reverse" onClick={onClose}>
          {t('sidebar__patient_add_allergy_cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AllergyModal;
