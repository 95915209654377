import { CallState } from 'containers/VideoCall/index';
import { useEffect, useRef } from 'react';

export default ({ timeout, callState, onTimeout }) => {
  const timeoutCallRef = useRef();

  useEffect(() => {
    if (callState === CallState.DIALING && !timeoutCallRef.current) {
      timeoutCallRef.current = setTimeout(
        () => onTimeout && onTimeout(),
        timeout
      );
    }
    return () => {
      timeoutCallRef.current && clearTimeout(timeoutCallRef.current);
    };
  }, [callState]);
};
