import Pencil from 'components/icons/Pencil';
import Button from 'components/Button';
import BillingForm from 'containers/DetailBar/PatientBar/components/Billing/BillingForm';
import AccordeonHandler from 'containers/Shared/components/Accordeon/AccordeonHandler';
import {
  AccordeonHeader,
  AccordeonBody,
} from 'containers/Shared/components/Accordeon/AccordeonPanel';
import { usePortal } from 'containers/Shared/components/Portal';
import { useTranslation } from 'react-i18next';
import { getCountryName } from 'utils/countries';
import { useDispatch } from 'react-redux';
import { updateBillingProfile } from 'containers/DetailBar/PatientBar/actions/UpdateBillingInformation';

const BillingPanel = ({ open, onOpen, onClose, patient, hash }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open: openModal, close } = usePortal();

  const onBillingSave = (data) => dispatch(updateBillingProfile(hash, data));

  const onFormClick = () =>
    openModal(
      <BillingForm
        onClose={close}
        onSave={onBillingSave}
        billingProfile={patient?.billing}
      />
    );

  if (!open) {
    return (
      <AccordeonHandler onOpen={onOpen}>
        {t('sidebar__patient_billing_title')}
      </AccordeonHandler>
    );
  }

  return (
    <div className="p-4 border-b border-separators">
      <AccordeonHeader onClose={onClose} empty={false}>
        {t('sidebar__patient_billing_title')}
      </AccordeonHeader>
      <AccordeonBody show={!patient?.billing?.name}>
        <p className="mb-4">{t('sidebar__patient_billing_empty')}</p>
        <Button
          size="small"
          variant="reverse"
          onClick={onFormClick}
          className="text-2xs"
        >
          {t('sidebar__patient_billing_empty_button')}
        </Button>
      </AccordeonBody>
      <AccordeonBody show={patient?.billing?.name}>
        <div className="flex items-start gap-2">
          <div className="text-xs text-gray-medium">
            <p className="font-medium text-dark">{patient?.billing?.name}</p>
            <p>{patient?.billing?.tax_id}</p>
            <p>{patient?.billing?.address}</p>
            <p>{patient?.billing?.postal_code}</p>
            <p>{patient?.billing?.region}</p>
            <p>{getCountryName({ code: patient?.billing?.country_code })}</p>
          </div>
          <button
            onClick={onFormClick}
            className="flex items-center justify-center w-8 h-8 ml-auto rounded-lg text-gray-medium hover:bg-gray-light hover:text-gray-dark"
          >
            <Pencil />
          </button>
        </div>
      </AccordeonBody>
    </div>
  );
};

export default BillingPanel;
