import { useState, useEffect } from 'react';
import Drawer from 'components/Drawer';
import Api from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import useLazyQuery from 'utils/useLazyQuery';
import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import { toast } from 'react-toastify';
import PaymentAlert from 'containers/Appointments/icons/PaymentAlert';
import Payment from 'containers/Appointments/icons/Payment';
import Lottie from 'lottie-react';
import CheckAnimation from 'containers/Appointments/animations/check.json';
import Button from 'components/Button';
import Profile from 'containers/Appointments/components/Profile';
import { appointmentCreated } from 'state/appointments.slice';
import getSymbolFromCurrency from 'currency-symbol-map';
import ContactSelector from 'containers/Contacts/ContactSelector';
import classNames from 'classnames';
import WarningModal from 'components/Modal/Warning';
import Warning from 'components/icons/Warning';
import { usePortal } from 'containers/Shared/components/Portal';
import { isPendingToAccept } from 'services/contacts';
import { TermsAndConditions } from 'containers/Appointments/TermsAndConditions';
import { FieldPrice } from 'containers/Appointments/FieldPrice';

const Screen = {
  SUCCESSFUL_PAYMENT: 'successful-payment',
  CREATE_PAYMENT: 'create-payment',
  CONTACT_SELECTOR: 'contact-selector',
};

const CreatePaymentForm = ({
  onClose,
  user = undefined,
  roomId = undefined,
}) => {
  const portal = usePortal();
  const { t } = useTranslation();
  const [screen, setScreen] = useState(Screen.CREATE_PAYMENT);
  const [userSelected, setUserSelected] = useState();

  const handleFinishAppointment = () => setScreen(Screen.SUCCESSFUL_PAYMENT);

  const handleOnSelect = (contact) => {
    if (!contact) {
      switchToCreatePayment();
      return;
    }

    if (contact?.features?.payments.can_be_charged === false) {
      return portal.open(
        <WarningModal
          icon={<Warning />}
          title={t('appointments__no_access_modal_title')}
          text={t('appointments__no_access_modal_text')}
          button={t('appointments__no_access_modal_button')}
          onClose={() => portal.close('alert')}
        />,
        'alert'
      );
    }

    setUserSelected(contact);
    switchToCreatePayment();
  };

  const switchToCreatePayment = () => setScreen(Screen.CREATE_PAYMENT);

  return (
    <Drawer onClose={onClose}>
      {({ close }) => {
        return screen === Screen.SUCCESSFUL_PAYMENT ? (
          <SuccessfulPayment onClose={close} />
        ) : (
          <>
            <Wrapper show={screen === Screen.CREATE_PAYMENT}>
              <Form
                onClose={close}
                onFinish={() => handleFinishAppointment()}
                user={user}
                userSelected={userSelected}
                roomId={roomId}
                onSelectUser={() => setScreen(Screen.CONTACT_SELECTOR)}
              />
            </Wrapper>
            <Wrapper show={screen === Screen.CONTACT_SELECTOR}>
              <ContactSelector
                enabled={screen === Screen.CONTACT_SELECTOR}
                with_accepted_invitation={true}
                onSelected={handleOnSelect}
                onBack={switchToCreatePayment}
              />
            </Wrapper>
          </>
        );
      }}
    </Drawer>
  );
};

const Wrapper = ({ children, show }) => (
  <div
    className={classNames('flex flex-col h-full', {
      hidden: !show,
    })}
  >
    {children}
  </div>
);

const Form = ({
  onClose,
  user = undefined,
  userSelected: contactSelected = undefined,
  roomId = undefined,
  onFinish,
  onSelectUser,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.session.profile?.currency);
  const [CreatePayment, isCreatingPayment] = useLazyQuery(Api.payments.create);
  const isCreating = isCreatingPayment;

  const {
    register,
    formState: { isValid },
    trigger,
    errors,
    handleSubmit,
    watch,
  } = useForm({ mode: 'onChange' });

  const price = watch('price');

  useEffect(() => {
    trigger();
  }, [contactSelected]);

  const onSubmit = async ({ price, date, time }) => {
    const userHash = user?.hash ?? contactSelected?.user_hash;
    const paymentData = {
      roomId,
      contactId: contactSelected?.id,
      userHash,
      price,
      date,
      time,
    };

    try {
      const {
        data: { data: appointment },
      } = await CreatePayment(paymentData);
      dispatch(appointmentCreated(appointment));
      onFinish();
    } catch (error) {
      toast.error(
        <ErrorToast>
          {t('appointments__payment_form_submit_error', {
            price: `${price}${getSymbolFromCurrency(currency)}`,
          })}
        </ErrorToast>
      );
      Sentry.captureException(error, { extra: paymentData });
    }
  };

  const selectUser = (event) => {
    event.preventDefault();
    onSelectUser();
  };

  const isPending =
    isPendingToAccept(contactSelected) || (roomId && !user?.hash);

  return (
    <>
      <Drawer.Header
        title={t('appointments__payment_form_title')}
        onClose={onClose}
      />
      <form
        className="flex flex-col h-full overflow-auto"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <div className="px-4 py-6">
          <input
            type="hidden"
            name="name"
            defaultValue={user?.name ?? contactSelected?.name}
            ref={register({ required: true })}
          />
          <Profile
            avatarURL={user?.avatarURL ?? contactSelected?.avatar}
            name={user?.name ?? contactSelected?.name}
            pending={isPending}
            disabled={!!user}
            onClick={selectUser}
          />
        </div>
        <div className="bg-separators h-[1px] mx-6 flex-shrink-0" />
        <div className="flex flex-col flex-grow h-full p-6">
          <p className="mb-4 text-lg font-medium text-dark">
            {t('appointments__payment_form_appointment_price')}
          </p>
          <FieldPrice
            name="price"
            errors={errors}
            value={price ?? 0}
            type="number"
            step="0.01"
            placeholder="0"
            currency={currency}
            register={register({
              required: true,
              validate: {
                number: (value) =>
                  (!isNaN(value) && /^\d+(\.\d{1,2})?$/.exec(value)) ||
                  t('appointments__payment_form_price_error_number'),
                nonZero: (value) =>
                  parseFloat(value) !== 0 ||
                  false ||
                  t('appointments__payment_form_price_error_zero'),
                moreThanZero: (value) =>
                  parseFloat(value) >= 0 ||
                  t('appointments__payment_form_price_error_zero'),
              },
            })}
            disabled={isCreating}
          />
          <div
            className={classNames(
              'px-3 py-2 mt-auto text-xs text-center rounded-lg',
              isPending && parseInt(price) !== 0
                ? 'bg-orange-light text-orange'
                : 'text-blue bg-blue-light'
            )}
          >
            {isPending
              ? t('appointments__payment_form_payment_info_pending')
              : t('appointments__payment_form_payment_info')}
          </div>
        </div>
        <footer className="px-6 py-5 border-t border-separators">
          <div className="space-y-2 text-center">
            <Button
              type="submit"
              disabled={!isValid || isCreating}
              className="w-full"
              active={isValid}
            >
              {isCreating
                ? t('appointments__payment_form_payment_button_submit_sending')
                : t('appointments__payment_form_payment_button_submit_send')}
            </Button>
            <TermsAndConditions />
          </div>
        </footer>
      </form>
    </>
  );
};

const SuccessfulPayment = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex-grow p-6 overflow-auto">
        <div className="flex flex-col items-center py-14">
          <Lottie animationData={CheckAnimation} className="w-160px" />
          <p className="text-primary text-2xl py-8 font-medium text-center w-[240px]">
            {t('appointments__payment_finished_title')}
          </p>
        </div>
        <p className="pb-2 text-xs font-medium border-b text-dark border-separators">
          {t('appointments__payment_finished_header')}
        </p>
        <div className="text-dark grid grid-cols-[1fr,auto] items-center py-8 gap-y-10 gap-x-4">
          <PaymentAlert className="self-start" />
          <p className="text-sm font-medium">
            {t('appointments__payment_finished_item_alert_title')}
          </p>
          <Payment className="self-start" />
          <div>
            <p className="pb-2 text-sm font-medium">
              {t('appointments__payment_finished_item_payment_title')}
            </p>
            <p className="text-xs">
              {t('appointments__payment_finished_item_payment_description')}
            </p>
          </div>
        </div>
      </div>
      <Drawer.Footer>
        <Button className="w-full" onClick={onClose}>
          {t('appointments__payment_finished_button_confirm')}
        </Button>
      </Drawer.Footer>
    </>
  );
};

export default CreatePaymentForm;
