import { useDispatch, useSelector } from 'react-redux';
import {
  useDropdown,
  DropdownMenu,
  DropdownHeader,
  DropdownOption,
} from 'containers/Shared/components/Dropdown';
import {
  changeChatStatus,
  changeVideoCallStatus,
} from 'containers/Inbox/actions/Session.actions';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Chat from 'components/icons/Chat';
import Video from 'components/icons/outline/Video';
import FeatureFlag from 'containers/Shared/components/FeatureFlag';

const StatusToggle = () => {
  const profileIsLoading = useSelector((state: any) => state.session.isLoading);
  const profile = useSelector((state: any) => state.session.profile);

  const isLoading = profileIsLoading && !profile;

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <div className="flex flex-row gap-1 ">
      <FeatureFlag
        enabled={
          profile?.features?.consultations
            ?.is_immediate_video_call_sdk_consultant &&
          profile?.immediate_video_call?.schedules?.length
        }
      >
        <VideoCallStatusToggle profile={profile} />
      </FeatureFlag>
      <ChatStatusToggle profile={profile} />
    </div>
  );
};

const ChatStatusToggle = ({ profile }: { profile: any }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropdown = useDropdown();

  const status = profile?.status === '1' ? 'online' : 'offline';

  const handleStatusChange = (status: string) => {
    dispatch(changeChatStatus(status));
    dropdown.close();
  };

  return (
    <div className="relative">
      <button
        onClick={() => dropdown.toggle()}
        className={classnames(
          'rounded-lg py-2 px-2 bg-opacity-10 flex justify-center min-w-[160px] space-x-1.5 items-center text-white border-none focus:outline-none',
          { 'z-30': dropdown.isOpen },
          {
            online: 'bg-positive text-positive-dark',
            offline: 'bg-separators text-[#90A4AE]',
          }[status]
        )}
      >
        <Chat
          className={classnames(
            'w-4 h-4',
            {
              online: 'text-positive-dark',
              offline: 'text-[#90A4AE]',
            }[status]
          )}
        />
        <div className="text-xs normal-case">
          {t(
            status === 'online'
              ? 'header__status_chat_online'
              : 'header__status_chat_offline'
          )}
        </div>
      </button>
      <DropdownMenu
        open={dropdown.isOpen}
        onClose={() => dropdown.close()}
        className="right-0 w-44"
      >
        <DropdownHeader>{t(`header__status_chat_header`)}</DropdownHeader>

        <DropdownOption
          onClick={() => handleStatusChange('1')}
          className="font-normal text-xs"
        >
          {t('header__status_chat_online')}
        </DropdownOption>
        <DropdownOption
          onClick={() => handleStatusChange('0')}
          className="font-normal text-xs"
        >
          {t('header__status_chat_offline')}
        </DropdownOption>
      </DropdownMenu>
    </div>
  );
};

const Skeleton = () => <div className="w-20 h-8 rounded-lg bg-gray-light" />;

const VideoCallStatusToggle = ({ profile }: { profile: any }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropdown = useDropdown();

  const status =
    profile?.immediate_video_call?.status === 'online'
      ? 'online'
      : profile?.immediate_video_call?.should_be_conected
      ? 'inactive'
      : 'offline';

  const schedules = profile?.immediate_video_call?.schedules;

  const handleStatusChange = (status: string) => {
    const toggleVideocallStatus = status === 'online' ? 'offline' : 'online';
    dispatch(changeVideoCallStatus(toggleVideocallStatus));
    dropdown.close();
  };

  return (
    <div className="relative">
      {status === 'inactive' && (
        <div className="flex absolute -right-0.5 -translate-y-0.5 bg-negative w-2 h-2 rounded-full" />
      )}
      <button
        onClick={() => dropdown.toggle()}
        className={classnames(
          'rounded-lg py-1.5 px-2 bg-opacity-10 flex justify-center space-x-1 items-center text-white border-none focus:outline-none',
          { 'z-30': dropdown.isOpen },
          {
            online: 'bg-positive text-positive-dark',
            offline: 'bg-separators text-[#90A4AE]',
            inactive: 'bg-separators text-[#90A4AE]',
          }[status]
        )}
      >
        <Video
          className={classnames(
            'w-5 h-5 m-0',
            {
              online: 'text-positive-dark',
              offline: 'text-[#90A4AE]',
              inactive: 'text-[#90A4AE]',
            }[status]
          )}
        />
        <div className="text-xs normal-case">
          {t(
            status === 'online'
              ? 'header__status_videocall_online'
              : 'header__status_videocall_offline'
          )}
        </div>
      </button>
      <DropdownMenu
        open={dropdown.isOpen}
        onClose={() => dropdown.close()}
        className="right-0 w-[228px]"
      >
        <DropdownHeader>
          {t(`header__status_videocall_header_${status}`)}
        </DropdownHeader>
        {status === 'inactive' && (
          <>
            <div className="font-medium text-sm mx-4">
              {t(`header__status_videocall_subtitle_inactive`)}
            </div>
            <div className="mx-4 text-sm mb-2">
              {t('header__status_videocall_description_inactive')}
            </div>
            <div className="flex mx-3 m-auto mb-2 border-b border-separators" />
          </>
        )}
        {schedules?.length > 0 &&
          schedules.map((schedule: any, index: number) => (
            <div key={index}>
              <div className="font-medium text-sm mx-4">{schedule?.name}</div>
              <div className="mx-4 text-sm mb-2">{schedule?.description}</div>
            </div>
          ))}
        <div className="flex px-2 m-auto w-full">
          <button
            onClick={() => handleStatusChange(status)}
            className="flex p-2 w-full bg-primary uppercase tracking-widest rounded-lg text-white text-xs w-40 justify-center"
          >
            {t(
              `header__status_videocall_button_${
                status === 'online' ? 'offline' : 'online'
              }`
            )}
          </button>
        </div>
      </DropdownMenu>
    </div>
  );
};

export default StatusToggle;
