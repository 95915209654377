export default (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 3.33334C2 2.80291 2.21071 2.2942 2.58579 1.91913C2.96086 1.54406 3.46957 1.33334 4 1.33334H12C12.5304 1.33334 13.0391 1.54406 13.4142 1.91913C13.7893 2.2942 14 2.80291 14 3.33334V12.6667C14 13.1971 13.7893 13.7058 13.4142 14.0809C13.0391 14.456 12.5304 14.6667 12 14.6667H4C3.46957 14.6667 2.96086 14.456 2.58579 14.0809C2.21071 13.7058 2 13.1971 2 12.6667V3.33334ZM5.33333 3.33334V8.00001L6.862 6.47134C6.98702 6.34636 7.15656 6.27615 7.33333 6.27615C7.51011 6.27615 7.67965 6.34636 7.80467 6.47134L9.33333 8.00001V3.33334C9.33333 3.15653 9.2631 2.98696 9.13807 2.86194C9.01305 2.73691 8.84348 2.66668 8.66667 2.66668H6C5.82319 2.66668 5.65362 2.73691 5.5286 2.86194C5.40357 2.98696 5.33333 3.15653 5.33333 3.33334Z"
        fill="#4A1EA7"
      />
    </svg>
  );
};
