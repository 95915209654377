import { cloneElement, useState } from 'react';

const ImageState = {
  loading: Symbol(),
  loaded: Symbol(),
  error: Symbol(),
};

const Image = ({ src, alt, className, placeholder }) => {
  const [state, setState] = useState(ImageState.loading);

  const showPlaceholder = state !== ImageState.loaded;
  const showImage = state !== ImageState.error;
  const hideImage = state === ImageState.loading;

  return (
    <>
      {showImage ? (
        <img
          alt={alt}
          src={src}
          className={`${hideImage ? 'invisible' : ''} ${className}`}
          onLoad={() => setState(ImageState.loaded)}
          onError={() => setState(ImageState.error)}
        />
      ) : (
        showPlaceholder &&
        placeholder &&
        cloneElement(placeholder, { className })
      )}
    </>
  );
};

export default Image;
