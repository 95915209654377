import { useRef, useLayoutEffect, useState, useCallback } from 'react';

export default ({ ref, onResize = undefined }) => {
  const [rect, setRect] = useState();
  const animationFrameId = useRef(null);

  const handleResize = useCallback(
    (entries) => {
      animationFrameId.current = window.requestAnimationFrame(() => {
        if (!Array.isArray(entries)) {
          return;
        }

        const [entry] = entries;

        setRect(entry.contentRect);
        onResize && onResize(entry.contentRect);
      });
    },
    [onResize]
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(handleResize);

    resizeObserver.observe(ref.current);

    return () => {
      animationFrameId.current &&
        window.cancelAnimationFrame(animationFrameId.current);
      resizeObserver.disconnect();
    };
  }, [ref]);

  return { width: rect?.width, height: rect?.height };
};
