import { CallState } from 'containers/VideoCall/index';
import { useEffect, useState, useRef } from 'react';

export default ({ callState }) => {
  const intervalTimerRef = useRef();
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (callState === CallState.ON_CALL && !intervalTimerRef?.current) {
      intervalTimerRef.current = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    }
    return () => {
      intervalTimerRef.current && clearInterval(intervalTimerRef.current);
    };
  }, [callState]);

  return seconds;
};
