const DoubleCheck = (props) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5331 4.19986C11.2731 3.93986 10.8531 3.93986 10.5931 4.19986L6.83312 7.95986L7.77312 8.89986L11.5331 5.1332C11.7865 4.87986 11.7865 4.4532 11.5331 4.19986ZM14.3598 4.1932L7.77312 10.7799L5.45312 8.46653C5.19312 8.20653 4.77312 8.20653 4.51312 8.46653C4.25312 8.72653 4.25312 9.14653 4.51312 9.40653L7.29979 12.1932C7.55979 12.4532 7.97979 12.4532 8.23979 12.1932L15.2998 5.13986C15.5598 4.87986 15.5598 4.45986 15.2998 4.19986H15.2931C15.0398 3.9332 14.6198 3.9332 14.3598 4.1932ZM0.746453 9.4132L3.53312 12.1999C3.79312 12.4599 4.21312 12.4599 4.47312 12.1999L4.93979 11.7332L1.68645 8.46653C1.42645 8.20653 1.00645 8.20653 0.746453 8.46653C0.486453 8.72653 0.486453 9.1532 0.746453 9.4132Z"
      fill="currentColor"
    />
  </svg>
);

export default DoubleCheck;
