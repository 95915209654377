import { useEffect, useState } from 'react';

export default () => {
  const [isEvent, setIsEvent] = useState<boolean>(false);
  const [rempeEvent, setRempeEvent] = useState<any>(null);

  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        if (
          event.data.code !== undefined &&
          event.data.code !== null &&
          event.data.code === 'REMPE_PRESCRIPTION_UPDATE'
        ) {
          setRempeEvent(event.data?.code);
          setIsEvent(true);
        }

        setRempeEvent(null);
      },
      false
    );
  }, []);

  return {
    isEvent,
    setIsEvent,
    rempeEvent,
  };
};
