import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  imcCreated,
  imcDeleted,
} from 'containers/DetailBar/PatientBar/actions/MedicalHistory.actions';
import ConsultationsApiClient from 'legacy/Services/Consultations';

export const useIMCs = (hash) => {
  const dispatch = useDispatch();
  const [imcs, setIMCs] = useState([]);

  useEffect(() => {
    ConsultationsApiClient.imc
      .index({ customer_hash: hash })
      .then(({ data }) => setIMCs(data));
  }, [hash]);

  const destroy = (id) => {
    return ConsultationsApiClient.imc.delete(id).then(() => {
      setIMCs(imcs.filter((n) => n.id !== id));
      dispatch(imcDeleted());
    });
  };

  const store = (imc, hash) => {
    return ConsultationsApiClient.imc
      .store({ ...imc, customer_hash: hash })
      .then(({ data }) => {
        setIMCs([...imcs, { ...data }]);
        dispatch(imcCreated());
      });
  };

  return [imcs, store, destroy];
};
