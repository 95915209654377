import React from 'react';

function Payment(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      fill="none"
      viewBox="0 0 56 56"
      {...props}
    >
      <mask
        id="mask0"
        width="56"
        height="56"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <rect width="56" height="56" fill="#F7F8FA" rx="28"></rect>
      </mask>
      <g mask="url(#mask0)">
        <rect width="56" height="56" fill="#ECEEFD" rx="28"></rect>
        <rect width="48" height="25" x="4" y="31" fill="#201552" rx="8"></rect>
        <rect
          width="32"
          height="7"
          x="12"
          y="33"
          fill="#4A1EA7"
          rx="3.5"
        ></rect>
        <rect
          width="26"
          height="26"
          x="15"
          y="10"
          fill="#42CECE"
          rx="13"
        ></rect>
        <mask
          id="mask1"
          width="26"
          height="26"
          x="15"
          y="10"
          maskUnits="userSpaceOnUse"
        >
          <rect
            width="26"
            height="26"
            x="15"
            y="10"
            fill="#42CECE"
            rx="13"
          ></rect>
        </mask>
        <g mask="url(#mask1)">
          <path
            fill="#319B9B"
            d="M12.925 29.816H43.91V35.221H12.925z"
            transform="rotate(-15 12.925 29.816)"
          ></path>
          <path
            fill="#fff"
            fillOpacity="0.3"
            d="M12.214 19.489H41.703V23.203H12.214z"
            transform="rotate(-15 12.214 19.49)"
          ></path>
        </g>
        <g filter="url(#filter0_d)">
          <circle cx="28" cy="23" r="9.286" fill="#fff"></circle>
        </g>
        <g filter="url(#filter1_d)">
          <path
            fill="#42CECE"
            d="M28.701 28.815v-.331c.479-.083 2.781-.544 2.781-2.966 0-1.289-.561-2.413-2.772-3.177-1.639-.543-2.431-.875-2.431-1.75 0-.94 1.022-1.28 1.667-1.28.755 0 1.215.36 1.473.71a.805.805 0 00.94.257c.479-.202.644-.792.331-1.197a3.12 3.12 0 00-1.989-1.197v-.341a.812.812 0 00-1.62 0v.331c-2.404.516-2.414 2.634-2.414 2.726 0 2.1 2.072 2.69 3.085 3.049 1.456.525 2.1.985 2.1 1.87 0 1.04-.967 1.482-1.823 1.482-1.078 0-1.658-.7-1.953-1.262-.193-.359-.617-.515-.994-.359l-.065.028c-.423.175-.626.681-.414 1.086.7 1.373 1.897 1.796 2.477 1.934v.387a.812.812 0 001.621 0z"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d"
          width="19.5"
          height="18.571"
          x="18.714"
          y="13.714"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.929"></feOffset>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d"
          width="7.429"
          height="12.893"
          x="24.518"
          y="16.732"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.464"></feOffset>
          <feColorMatrix values="0 0 0 0 0.192157 0 0 0 0 0.607843 0 0 0 0 0.607843 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Payment;
