const BellCircle = (props: any) => (
  <svg fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.877 11.557c-.545 3.154.031 3.942.595 4.704h.002l.002.004c.343.416.549.922.59 1.452a2.545 2.545 0 01-.358 1.513 2.624 2.624 0 01-1.18 1.04 2.74 2.74 0 01-1.583.195l-3.69-.637a2.466 2.466 0 01-1.043 1.614 2.607 2.607 0 01-1.916.412 2.607 2.607 0 01-1.666-1.031 2.466 2.466 0 01-.441-1.87l-3.687-.637a2.74 2.74 0 01-1.427-.717 2.625 2.625 0 01-.762-1.376 2.545 2.545 0 01.172-1.547 2.602 2.602 0 011.045-1.169c.784-.528 1.594-1.077 2.139-4.23v-.002c.555-3.185 3.397-5.417 6.654-5.381a1.227 1.227 0 01.69-1.683 1.313 1.313 0 011.31.224c.178.15.311.344.385.56a1.222 1.222 0 01-.295 1.26l.038.011c.556.192 1.08.453 1.561.774A3.982 3.982 0 0015.5 7v.022a4.873 4.873 0 00-2.437-1.183 4.844 4.844 0 00-3.559.765 4.58 4.58 0 00-1.937 2.998c-.646 3.743-1.737 4.63-3.059 5.535a.72.72 0 00-.296.763c.034.141.11.271.218.372a.774.774 0 00.392.195l12.434 2.148.003.001a.774.774 0 00.435-.052.744.744 0 00.33-.278.72.72 0 00-.023-.818c-.934-1.286-1.674-2.481-1.024-6.24a4.52 4.52 0 00.055-1.08 3.98 3.98 0 001.918.815c-.015.198-.04.397-.073.594zm-8.939 8.37a.98.98 0 001.343.232.926.926 0 00.392-.604l-1.9-.329a.927.927 0 00.165.701z"
      clipRule="evenodd"
    ></path>
    <path fill="#42CECE" d="M21.5 7a2 2 0 11-4 0 2 2 0 014 0z"></path>
  </svg>
);

export default BellCircle;
