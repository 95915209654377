import { useState, useEffect } from 'react';
import Drawer from 'components/Drawer';
import Api from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import useLazyQuery from 'utils/useLazyQuery';
import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import Profile from 'containers/Appointments/components/Profile';
import { appointmentCreated } from 'state/appointments.slice';
import getSymbolFromCurrency from 'currency-symbol-map';
import ContactSelector from 'containers/Contacts/ContactSelector';
import classNames from 'classnames';
import WarningModal from 'components/Modal/Warning';
import Warning from 'components/icons/Warning';
import { usePortal } from 'containers/Shared/components/Portal';
import { isPendingToAccept } from 'services/contacts';
import { TermsAndConditions } from 'containers/Appointments/TermsAndConditions';
import { FieldPrice } from 'containers/Appointments/FieldPrice';
import Banner from 'components/Banner';
import BannerType from 'components/Banner/BannerType';
import dayjs from 'dayjs';
import VideoCamera from 'components/icons/solid/VideoCamera';
import { getIsPaymentValidated } from 'state/profile/index.selectors';
import { canChargeConsultations } from 'containers/Inbox/reducers/session.reducer';
import TimezoneExpand from 'containers/Appointments/components/TimezoneExpand';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const Screen = {
  CREATE_PAYMENT: 'create-payment',
  CONTACT_SELECTOR: 'contact-selector',
};

const CreateAppointmentForm = ({
  onClose,
  user = undefined,
  roomId = undefined,
}) => {
  const portal = usePortal();
  const { t } = useTranslation();
  const [screen, setScreen] = useState(Screen.CREATE_PAYMENT);
  const [userSelected, setUserSelected] = useState();

  const handleOnSelect = (contact) => {
    if (!contact) {
      switchToCreatePayment();
      return;
    }

    if (contact?.features?.can_be_appointed === false) {
      return portal.open(
        <WarningModal
          icon={<Warning />}
          title={t('appointments__no_access_modal_title')}
          text={t('appointments__no_access_modal_text')}
          button={t('appointments__no_access_modal_button')}
          onClose={() => portal.close('alert')}
        />,
        'alert'
      );
    }

    setUserSelected(contact);
    switchToCreatePayment();
  };

  const switchToCreatePayment = () => setScreen(Screen.CREATE_PAYMENT);

  return (
    <Drawer onClose={onClose}>
      {({ close }) => (
        <>
          <Wrapper show={screen === Screen.CREATE_PAYMENT}>
            <Form
              onClose={close}
              onFinish={close}
              user={user}
              userSelected={userSelected}
              roomId={roomId}
              onSelectUser={() => setScreen(Screen.CONTACT_SELECTOR)}
              //canBeCharged={contact?.features?.payments?.can_be_charged}
            />
          </Wrapper>
          <Wrapper show={screen === Screen.CONTACT_SELECTOR}>
            <ContactSelector
              enabled={screen === Screen.CONTACT_SELECTOR}
              with_accepted_invitation={true}
              onSelected={handleOnSelect}
              onBack={switchToCreatePayment}
            />
          </Wrapper>
        </>
      )}
    </Drawer>
  );
};

const Wrapper = ({ children, show }) => (
  <div
    className={classNames('flex flex-col h-full', {
      hidden: !show,
    })}
  >
    {children}
  </div>
);

const Form = ({
  onClose,
  user = undefined,
  userSelected: contactSelected = undefined,
  roomId = undefined,
  onFinish,
  onSelectUser,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.session);
  const [CreateAppointment, isCreatingAppointment] = useLazyQuery(
    Api.appointments.create
  );
  const isPaymentValidated = useSelector(getIsPaymentValidated);
  const professionalCanCharge = useSelector(canChargeConsultations);
  const canBeCharged =
    user?.permissions?.payments?.can_be_charged ??
    contactSelected?.features?.payments?.can_be_charged;

  const isCreating = isCreatingAppointment;

  const {
    register,
    formState: { isValid },
    trigger,
    errors,
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: { timezone: dayjs.tz.guess() },
  });

  const price = watch('price');
  const timezone = watch('timezone');

  useEffect(() => {
    trigger();
  }, [contactSelected]);

  const onSubmit = async ({
    price,
    date,
    time,
    with_consultation_url,
    timezone,
  }) => {
    const userHash = user?.hash ?? contactSelected?.user_hash;
    const startsAt = dayjs.tz(`${date} ${time}`, timezone).unix();

    const paymentData = {
      roomId,
      contactId: contactSelected?.id,
      userHash,
      price: price || 0,
      date,
      time,
      startsAt,
      withConsultationUrl: with_consultation_url,
    };

    try {
      const {
        data: { data: appointment },
      } = await CreateAppointment(paymentData);
      dispatch(appointmentCreated(appointment));
      onFinish();
    } catch (error) {
      toast.error(
        <ErrorToast>
          {t('appointments__payment_form_submit_error', {
            price: `${price}${getSymbolFromCurrency(profile.currency)}`,
          })}
        </ErrorToast>
      );
      Sentry.captureException(error, { extra: paymentData });
    }
  };

  const selectUser = (event) => {
    event.preventDefault();
    onSelectUser();
  };

  const isPending =
    isPendingToAccept(contactSelected) || (roomId && !user?.hash);

  return (
    <>
      <Drawer.Header
        title={t('appointments__appointment_form_title')}
        onClose={onClose}
      />
      <form
        className="flex flex-col h-full overflow-auto"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <div className="px-4 py-6 cursor-pointer">
          <p className="mb-3 font-medium text-dark">
            {t('appointments__appointment_form_patient')}
          </p>
          <input
            type="hidden"
            name="name"
            defaultValue={user?.name ?? contactSelected?.name}
            ref={register({ required: true })}
          />
          <Profile
            name={user?.name ?? contactSelected?.name}
            pending={isPending}
            disabled={!!user}
            onClick={selectUser}
          />
        </div>
        <div className="bg-separators h-[1px] mx-6 flex-shrink-0" />
        <div className="flex flex-col flex-grow h-full p-6">
          <div>
            <p className="mb-4 font-medium text-dark">
              {t('appointments__payment_form_appointment')}
            </p>
            <div className="flex justify-between space-x-4 w-full">
              <div className="flex flex-grow relative">
                <span className="text-primary text-sm px-4 pt-2 absolute">
                  {t('appointments__payment_form_appointment_date')}
                </span>
                <input
                  name="date"
                  min={dayjs().toISOString().slice(0, 10)}
                  defaultValue={dayjs().format('YYYY-MM-DD')}
                  ref={register({ required: true })}
                  type="date"
                  className="w-full px-4 pt-7 pb-2 rounded-lg bg-background max-h-6 m-0 text-dark focus:outline-none focus-visible:ring-1 focus-visible:ring-primary"
                />
              </div>
              <div className="mr-auto relative">
                <span className="text-primary text-sm px-4 pt-2 absolute">
                  {t('appointments__payment_form_appointment_hour')}
                </span>
                <input
                  name="time"
                  ref={register({ required: true })}
                  type="time"
                  defaultValue={dayjs().format('HH:mm')}
                  className="w-full px-4 pt-7 pb-2 rounded-lg bg-background max-h-6 m-0 text-dark focus:outline-none focus-visible:ring-1 focus-visible:ring-primary"
                />
              </div>
            </div>
          </div>

          <fieldset className="mt-2 mb-4">
            <TimezoneExpand
              name="timezone"
              register={register()}
              displayValue={timezone}
            />
          </fieldset>

          <div className="flex flex-col mb-4">
            <FieldPrice
              name="price"
              errors={errors}
              value={price ?? 0}
              type="number"
              step="0.01"
              placeholder="0"
              currency={profile.currency}
              register={register({
                required: false,
                validate: {
                  number: (value) => {
                    (!isNaN(value) && /^\d+(\.\d{1,2})?$/.exec(value)) ||
                      t('appointments__payment_form_price_error_number');
                  },
                },
              })}
              disabled={
                isCreating ||
                !isPaymentValidated ||
                !professionalCanCharge ||
                !canBeCharged
              }
            />
            {(!canBeCharged ||
              !isPaymentValidated ||
              !professionalCanCharge) && (
              <span className="text-gray-medium text-sm ml-auto mt-1">
                {!canBeCharged
                  ? t('appointments__payment_form_client_payment_not_available')
                  : t('appointments__payment_form_payment_not_available')}
              </span>
            )}
          </div>
          {profile.is_immediate_consultant && (
            <div className="w-full bg-background rounded-lg py-[18px] px-4 mt-4 flex flex-row text-primary gap-2 items-center justify-between">
              <VideoCamera className="w-5 h-5" />
              <span className="flex text-primary mr-auto">
                {t('appointments__payment_form_appointment_link')}
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  name="with_consultation_url"
                  type="checkbox"
                  ref={register({ required: false })}
                  className="sr-only peer"
                  defaultChecked
                />
                <div className="w-11 h-6 bg-white peer-focus:outline-none peer-focus:ring-1 shadow peer-focus:ring-gray-light rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:bg-primary after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-light after:border after:rounded-full after:h-5 after:w-5 after:transition-all "></div>
              </label>
            </div>
          )}
        </div>

        {canBeCharged && (
          <Banner
            text={
              !isPaymentValidated
                ? t('appointments__payment_form_info_payment_not_available')
                : parseInt(price) === 0
                ? t('appointments__payment_form_appointment_priceless_info')
                : isPending
                ? t('appointments__payment_form_appointment_info_pending')
                : t('appointments__payment_form_appointment_info')
            }
            type={
              isPending && parseInt(price) !== 0
                ? BannerType.warning
                : BannerType.info
            }
          />
        )}

        <footer className="px-6 py-5 border-t border-separators">
          <div className="space-y-2 text-center">
            <Button type="submit" className="w-full" active={isValid}>
              {isCreating
                ? t(
                    'appointments__payment_form_appointment_button_submit_sending'
                  )
                : parseInt(price) === 0
                ? t(
                    'appointments__payment_form_appointment_priceless_button_submit_send'
                  )
                : t(
                    'appointments__payment_form_appointment_button_submit_send'
                  )}
            </Button>
            <TermsAndConditions />
          </div>
        </footer>
      </form>
    </>
  );
};

export default CreateAppointmentForm;
