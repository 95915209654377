const TYPING = 'ROOM/TYPING';
const STOP_TYPING = 'ROOM/STOP_TYPING';

export const actionTypes = {
  TYPING,
  STOP_TYPING,
};

export function typing(roomId) {
  return {
    type: TYPING,
    payload: roomId,
  };
}

export function stopTyping(roomId) {
  return {
    type: STOP_TYPING,
    payload: roomId,
  };
}
