import { AccordeonBadge } from 'containers/Shared/components/Accordeon/AccordeonHandler';
import Button from 'components/Button';
import classNames from 'classnames';
import Caret from 'components/icons/outline/Caret';

export const AccordeonHeader = ({
  children,
  onClose,
  badge = 0,
  empty = false,
  icon = null,
}) => (
  <div
    onClick={onClose}
    className="flex items-center justify-between mb-4 cursor-pointer"
  >
    <div className="font-medium text-md">{children}</div>
    <div className="flex items-center">
      {icon ? icon : <AccordeonBadge badge={badge} empty={empty} />}
      <button onClick={onClose} className="w-3 h-3 focus:outline-none">
        <Caret className="rotate-180 text-gray-medium w-[10px]" />
      </button>
    </div>
  </div>
);

export const AccordeonBody = ({ show, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="mb-4 text-xs leading-4 text-gray-medium">{children}</div>
  );
};

export const AccordeonButton = ({ children, onClick, className = '' }) => (
  <Button
    size="small"
    variant="reverse"
    className={classNames('w-full', className)}
    onClick={onClick}
  >
    {children}
  </Button>
);
