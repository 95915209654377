import { Invitation } from 'api/invitations.d';
import { addOrUpdateInvitation } from 'services/invitations';
import { invitationAccepted } from 'state/appointments.slice';

export const onInvitationAccepted =
  (dispatch: any) => (invitation: Partial<Invitation>) => {
    dispatch(addOrUpdateInvitation({ status: 'accepted', ...invitation }));
    dispatch(invitationAccepted({ status: 'accepted', ...invitation }));
  };

export const onInvitationSmsFailed =
  (dispatch: any) =>
  ({ id }: Partial<Invitation>) =>
    dispatch(
      addOrUpdateInvitation({
        id,
        delivery_status: 'failed',
        sms_has_failed: true,
      })
    );

export const onInvitationEmailFailed =
  (dispatch: any) =>
  ({ id }: Partial<Invitation>) =>
    dispatch(
      addOrUpdateInvitation({
        id,
        delivery_status: 'failed',
        email_has_failed: true,
      })
    );
