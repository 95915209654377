import { cn } from 'components/utils';
import useAnimation from 'utils/useAnimation';
import useOutside from 'utils/useOutside';

const DropdownMenu = ({
  children,
  open,
  onClose,
  className = '',
  position = 'absolute',
}) => {
  if (!open) {
    return null;
  }

  return (
    <Menu className={className} onClose={onClose} position={position}>
      {children}
    </Menu>
  );
};

const Menu = ({ children, className, onClose, position }) => {
  const { isOpen, close: closeAnimation } = useAnimation({
    onCloseFinish: onClose,
  });
  const contentRef = useOutside(closeAnimation);

  return (
    <div
      ref={contentRef}
      className={cn(
        'z-20 bg-white shadow-lg transform transition-all duration-75 rounded-lg py-2 mt-1',
        isOpen ? 'translate-y-0 opacity-100' : 'translate-y-1 opacity-0',
        position,
        className
      )}
    >
      {children}
    </div>
  );
};

export default DropdownMenu;
