import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messages: [],
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addMessage(state, action) {
      state.messages.push(action.payload);
    },
    removeMessage(state, action) {
      state.messages = state.messages.filter(({ id }) => id !== action.payload);
    },
  },
});

export const { addMessage, removeMessage } = notificationSlice.actions;
export default notificationSlice.reducer;
