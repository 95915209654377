const SuitecasePlus = (props: any) => (
  <svg fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.881 1.215a.55.55 0 00-.548-.548.55.55 0 00-.549.548v.903h-.903a.55.55 0 00-.548.549.55.55 0 00.548.548h.903v.903a.55.55 0 00.549.549.55.55 0 00.548-.549v-.903h.903a.55.55 0 00.549-.548.55.55 0 00-.549-.549h-.903v-.903zM4.167 6.5a1 1 0 00-1 1v5a1 1 0 001 1h7.666a1 1 0 001-1v-5a1 1 0 00-1-1H4.167zm1.362-1H4.167a2 2 0 00-2 2v5a2 2 0 002 2h7.666a2 2 0 002-2v-5a2 2 0 00-2-2h-1.362a2.376 2.376 0 00-2.346-2h-.25a2.376 2.376 0 00-2.346 2zm1.023 0h2.896c-.163-.577-.694-1-1.323-1h-.25c-.63 0-1.16.423-1.323 1zM7.333 8h1.334v1.333H10v1.334H8.667V12H7.333v-1.333H6V9.333h1.333V8z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SuitecasePlus;
