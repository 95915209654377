import { FC } from 'react';
import Layout from 'components/Layout';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { refreshProfile } from 'containers/Inbox/actions/Session.actions';
import StatusToggle from 'pages/main/StatusToggle';
import classNames from 'classnames';
import NotificationsButton from 'pages/main/NotificationsButton';
import ActionsButton from 'pages/main/ActionsButton';

const Header: FC = ({ children }) => {
  const dispatch = useDispatch();
  const tutorialStep = useSelector((state: any) => state.layout.tutorialStep);
  const isEnabledOnTutorial = tutorialStep === 1;

  useEffect(() => {
    dispatch(refreshProfile());
  }, []);

  return (
    <Layout.Content.Header>
      <Layout.Content.Header.Main>{children}</Layout.Content.Header.Main>
      <Layout.Content.Header.Actions>
        <StatusToggle />
        <NotificationsButton />
        <ActionsButton
          className={classNames({ 'z-50': isEnabledOnTutorial })}
        />
      </Layout.Content.Header.Actions>
    </Layout.Content.Header>
  );
};

export const Title: FC = ({ children }) => (
  <h1 className="text-lg font-medium text-primary capitalize-first">
    {children}
  </h1>
);

export default Header;
