import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import find from 'utils/find';
import getHeaders from 'utils/getHeaders';
import Api from 'api';
import { Report } from 'api/reports.d';

export const legacyReportsApi = createApi({
  reducerPath: 'legacyReportsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: Api.reports.BASE_URL,
    prepareHeaders: (headers) => {
      Object.entries(getHeaders()).forEach(([key, value]) =>
        headers.set(key, value!)
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    legacyGetAllReports: builder.query<
      Partial<Report>[],
      { patient_hash: string }
    >({
      query: ({ patient_hash }: { patient_hash: string }) =>
        `${patient_hash}/reports`,
      transformResponse: (response: { data: Report[] }) => response.data,
    }),
  }),
});

export const lastSent = (a: Partial<Report>, b: Partial<Report>) => {
  const aDate = a?.created_at;
  const bDate = b?.created_at;
  if (!bDate) return 1;
  if (!aDate) return -1;
  return new Date(bDate).getTime() - new Date(aDate).getTime();
};

export const addReport = ({
  patient_hash,
  report,
}: {
  patient_hash: string;
  report: Partial<Report>;
}) =>
  legacyReportsApi.util.updateQueryData(
    'legacyGetAllReports',
    { patient_hash },
    (draftReports) => {
      if (!report.uuid) {
        return;
      }
      const reportDuplicated = find({
        items: draftReports,
        id: report.uuid,
        idAttribute: 'uuid',
      });
      if (!reportDuplicated.found) {
        draftReports.push(report);
      }
    }
  );

export const { useLegacyGetAllReportsQuery } = legacyReportsApi;
