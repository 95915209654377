import { useState, useEffect, useRef } from 'react';

export default ({ delay = 200, onDebounce, skipFirst = false }) => {
  const [value, setValue] = useState('');
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      if (skipFirst) {
        return;
      }
    }

    const handler = setTimeout(() => {
      onDebounce?.(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return setValue;
};
