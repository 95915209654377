import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

const API = 'phone-calls/v1';
const BASE_URL = config.services.mediquo.chat_url;

const getToken = () =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/${API}/token`,
    headers: getHeaders(),
  });

const callSetup = ({ userHash }: { userHash: string }) =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/${API}/patients/${userHash}`,
    headers: getHeaders(),
  });

export default {
  getToken,
  callSetup,
};
