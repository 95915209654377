import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ModalBody,
  ModalFooter,
} from 'containers/Shared/components/Modal/Modal';
import Tracker from 'utils/Tracking';
import Button from 'components/Button';

export interface Props {
  send: 'sms' | 'email' | 'all';
  onConfirm: () => void;
}

const InvitationSent: FC<Props> = ({ send, onConfirm }) => {
  const { t } = useTranslation();

  useEffect(() => {
    Tracker.pageView('invite success view');
  }, []);

  return (
    <>
      <ModalBody>
        <p className="my-4 text-primary">
          {t('invite__contact_created_success_title')}
        </p>
        <p>
          {t(
            {
              sms: 'invite__contact_sms_created_success_text',
              email: 'invite__contact_email_created_success_text',
              all: 'invite__contact_all_created_success_text',
            }[send]
          )}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onConfirm}>
          {t('invite__contact_created_success_button')}
        </Button>
      </ModalFooter>
    </>
  );
};

export default InvitationSent;
