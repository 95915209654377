import { TreeNode } from 'components/Tree/TreeNode';

export class Tree {
  constructor(id, value = id) {
    this.root = new TreeNode(id, value);
  }

  *preOrderTraversal(node = this.root) {
    yield node;
    if (node.children.length) {
      for (let child of node.children) {
        yield* this.preOrderTraversal(child);
      }
    }
  }

  *postOrderTraversal(node = this.root) {
    if (node.children.length) {
      for (let child of node.children) {
        yield* this.postOrderTraversal(child);
      }
    }
    yield node;
  }

  insert(parentNodeId, id, value = id) {
    for (let node of this.preOrderTraversal()) {
      if (node.id === parentNodeId) {
        node.children.push(new TreeNode(id, value, node));
        return true;
      }
    }
    return false;
  }

  find(id) {
    for (let node of this.preOrderTraversal()) {
      if (node.id === id) return node;
    }
    return undefined;
  }

  findNav(id) {
    for (let node of this.preOrderTraversal()) {
      if (node.id === id) {
        if (node.parent.value.component === 'nav') {
          return node.parent;
        }
        if (node.value.component === 'nav') {
          return node;
        }
        return this.findNav(node.parent.id);
      }
    }
    return undefined;
  }
}
