import axios from 'axios';
import { config } from 'config';
import dayjs from 'dayjs';
import getHeaders from 'utils/getHeaders';

const BASE_URL = config.services.mediquo.chat_url;

const list = ({ roomId }: any) =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/professionals/v1/rooms/${roomId}/clinical-courses`,
    headers: getHeaders(),
  });

const create = (data: any) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/professionals/v1/clinical-courses/${data.id}`,
    headers: getHeaders(),
    data: {
      room_id: data.room_id,
      status: data.isDraft ? 'draft' : 'published',
      reason: data.reason || undefined,
      consultation_type: data.consultation_type,
      consultation_id: data.consultation_id,
      briefing: data.briefing,
      dated_at: dayjs(`${data.date} ${data.time}`).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
    },
  });

const remove = ({ id }: any) =>
  axios({
    method: 'DELETE',
    url: `${BASE_URL}/professionals/v1/clinical-courses/${id}`,
    headers: getHeaders(),
  });

export default {
  list,
  remove,
  create,
};
