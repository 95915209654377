import Axios from 'axios';
import uuid from 'uuid/v4';
import { browserName } from 'react-device-detect';
import * as Sentry from '@sentry/browser';
import { config } from 'config';

class Tracking {
  constructor() {
    this.deviceOs = navigator.platform;
    this.deviceModel = browserName;
    this.userLanguage = navigator.language;
    this.deviceId = MediaDeviceInfo.deviceId;
    this.notificationPermission = window.Notification?.permission;
    this.environment = config.app.environment;
    this.url = config.services.mediquo.tracking_url;
  }

  setUser({ userHash, professionalStatus }) {
    this.userHash = userHash;
    this.userValidationStatus = professionalStatus;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new Tracking();
    }
    return this.instance;
  }

  debug(eventName, payload, actionType = 'debug') {
    this.sendRequest({
      ...payload,
      event_type: eventName,
      action_type: actionType,
    });
  }

  event(eventName, payload) {
    this.sendRequest({
      ...payload,
      event_type: eventName,
      action_type: 'event',
    });
  }

  pageView(page, payload) {
    this.sendRequest({
      ...payload,
      event_type: page,
      action_type: 'pageview',
    });
  }

  sendRequest(payload) {
    const body = {
      ...payload,
      user_hash: this.userHash,
      unique_id: uuid(),
      installation_id: localStorage.getItem('sessionId'),
      device_os: this.deviceOs,
      device_model: this.deviceModel,
      device_id: this.deviceId,
      app_notifications: this.notificationPermission,
      user_language: this.userLanguage,
      user_status: this.userValidationStatus,
      platform: 'web',
      env: this.environment,
    };
    Axios.post(this.url, body).catch((error) =>
      Sentry.captureException(error, { extra: body })
    );
  }
}

const Tracker = Tracking.getInstance();
export default Tracker;
