import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getHeaders from 'utils/getHeaders';
import { Report } from 'api/reports.d';
import { config } from 'config';

export type UpsertReportRequest = Partial<{
  customer_hash: string;
  call_uuid: string;
  objective_data: string;
  diagnostic_id: string;
  subjective_data: string;
  is_automatic: boolean;
  analysis: string;
  plan: string;
  consultation_type: string;
  consultation_id: string;
  status: string;
}>;

const REPORTS_BASE_URL = `${config.services.mediquo.chat_url}/reports/v1`;

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: REPORTS_BASE_URL,
    prepareHeaders: (headers) => {
      Object.entries(getHeaders()).forEach(([key, value]) =>
        headers.set(key, value!)
      );
      return headers;
    },
  }),
  tagTypes: ['Reports'],
  endpoints: (builder) => ({
    getAllReports: builder.query<
      { draftReport?: Report; publishedReports: Report[] },
      { patient_hash: string }
    >({
      query: ({ patient_hash }) => ({
        url: `/`,
        params: { patient_hash },
      }),
      transformResponse: (response: { data: Report[] }) => {
        const draftReport =
          response.data.find(
            (report) => report.status === 'draft' && report.is_automatic
          ) ?? response.data.find((report) => report.status === 'draft');
        const publishedReports = response.data.filter(
          (report) => report.status === 'published'
        );

        return {
          draftReport,
          publishedReports,
        };
      },
      providesTags: ['Reports'],
    }),
    deleteReport: builder.mutation<Report, { report_id: string }>({
      query: ({ report_id }) => ({
        url: `/${report_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Reports'],
    }),
    upsertDraftReport: builder.mutation<
      void,
      { report_id: string; data: Omit<UpsertReportRequest, 'status'> }
    >({
      query: ({ report_id, data }) => ({
        url: `/${report_id}`,
        method: 'PUT',
        body: {
          ...data,
          status: 'draft',
        },
      }),
      invalidatesTags: ['Reports'],
    }),
  }),
});

export const {
  useGetAllReportsQuery,
  useDeleteReportMutation,
  useUpsertDraftReportMutation,
} = reportsApi;
