import {
  AccordeonHeader,
  AccordeonBody,
} from 'containers/Shared/components/Accordeon/AccordeonPanel';
import AccordeonHandler from 'containers/Shared/components/Accordeon/AccordeonHandler';
import ChatRecordItem from 'containers/DetailBar/PatientBar/components/ChatRecords/ChatRecordItem';
import { useChatRecords } from 'containers/DetailBar/PatientBar/hooks/chatRecords.hook';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ChatRecordsPanel = ({ open, onOpen, onClose, hash }) => {
  const { profile } = useSelector((state) => state.session);
  const chatRecords = useChatRecords(hash);
  const badge = chatRecords.filter(
    (record) => record.professional.hash !== profile.hash
  ).length;
  const { t } = useTranslation();

  if (!open) {
    return (
      <AccordeonHandler onOpen={onOpen} badge={badge}>
        {t('sidebar__patient_chat_records_title')}
      </AccordeonHandler>
    );
  }

  return (
    <div>
      <div className="p-4 pb-0">
        <AccordeonHeader onClose={onClose} badge={badge}>
          {t('sidebar__patient_chat_records_title')}
        </AccordeonHeader>
        <AccordeonBody show={badge === 0}>
          {t('sidebar__patient_chat_records_empty_text')}
        </AccordeonBody>
      </div>
      <div className="border-b border-separators flex flex-col">
        <div className="max-w-full overflow-x-hidden">
          {chatRecords
            .filter((record) => record.professional.hash !== profile.hash)
            .map((record) => (
              <ChatRecordItem key={record.room_id} record={record} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ChatRecordsPanel;
