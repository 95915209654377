import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const MessageGroupDateSeparator = ({ date }) => {
  const { t } = useTranslation();

  const sentAt = dayjs(date);
  const today = dayjs();
  const yesterday = dayjs().subtract(1, 'day');

  if (sentAt.isSame(today, 'day')) {
    return t('shared__dates_today');
  }

  if (sentAt.isSame(yesterday, 'day')) {
    return t('shared__dates_yesterday');
  }

  return sentAt.format('DD/MM/YY');
};

export default MessageGroupDateSeparator;
