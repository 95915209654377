export default (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0368 0.000307565L9.96782 0L9.96506 1.52154e-05C5.27426 0.0727531 1.36533 3.17697 0.268928 7.70753C-0.359737 10.2851 0.126292 13.073 1.59606 15.3595C3.06402 17.6451 5.39624 19.2439 8.0048 19.7449C8.67477 19.8736 9.34742 19.9387 10.0068 19.9387C11.5907 19.9387 13.1239 19.5601 14.556 18.8179C14.6122 18.7936 14.6521 18.7854 14.6977 18.7854C14.7315 18.7854 14.7699 18.7888 14.8177 18.7973L18.2 19.6845C19.0347 19.9028 19.7928 19.1358 19.5649 18.3036L18.9994 16.2463C18.8888 15.8479 18.7806 15.4611 18.6736 15.0825C18.6567 15.0038 18.6535 14.9491 18.6596 14.9027C18.6656 14.8564 18.6819 14.8093 18.7168 14.7476L18.718 14.7454C20.2443 11.9262 20.3413 8.65655 18.9939 5.76748C17.643 2.87041 15.0533 0.83537 11.9042 0.193809L11.9025 0.19349C11.3308 0.0843339 10.694 0.0193101 10.0368 0.000307565ZM2.44784 8.23672C3.28284 4.78624 6.18387 2.4146 9.73728 2.2501L10.0044 2.2422C10.3516 2.25264 10.6997 2.2802 11.0229 2.32247C11.1785 2.34282 11.3283 2.36658 11.4694 2.39348C13.9115 2.89135 15.9121 4.46395 16.9617 6.71515C17.9672 8.87089 17.9385 11.281 16.8915 13.3982L16.8118 13.5522L16.7546 13.6626C16.4172 14.2609 16.3361 14.9073 16.5 15.6309L16.8874 17.0238L15.309 16.6106L15.3004 16.609C15.0513 16.5604 14.8754 16.5432 14.6977 16.5432C14.3132 16.5432 13.9569 16.6271 13.5768 16.8012L13.5709 16.8043C12.4102 17.4051 11.2294 17.6965 10.0068 17.6965C9.49108 17.6965 8.95996 17.6451 8.42766 17.5429C6.42857 17.159 4.6224 15.9223 3.48245 14.1474C2.34079 12.3713 1.96593 10.2124 2.44784 8.23672Z"
      fill="currentColor"
    />
  </svg>
);
