import Button, { Props as ButtonProps } from 'components/Button';
import classnames from 'classnames';
import PlusIcon from 'components/icons/Plus';
import { useTranslation } from 'react-i18next';

export type Props = ButtonProps;

const NewReportButton = ({ className, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-lighter py-3 px-2 rounded-2xl">
      <div
        className={classnames(
          'flex items-center justify-between gap-2',
          className
        )}
      >
        <div>
          <p className="text-primary font-medium text-sm">
            {t('reports__new_report_button_title')}
          </p>
          <p className="text-[#6D658B] text-sm">
            {t('reports__new_report_button_subtitle')}
          </p>
        </div>
        <Button
          size="custom"
          className="h-10 w-10 shrink-0 rounded-xl"
          {...props}
        >
          <PlusIcon className="h-3 w-3 text-white" />
        </Button>
      </div>
    </div>
  );
};

export default NewReportButton;
