import Api from 'api/index';
import { Filters, FilterTypes } from 'containers/Shared/filters';
import { InboxMode } from 'containers/Inbox/reducers/inbox.reducer';
import Tracker from 'utils/Tracking';
import { appointmentsPartialListFetched } from 'state/appointments.slice';
import { socket } from 'socket';

const FETCHING_ROOMS_LIST = 'INBOX/FETCHING_ROOMS_LIST';
const FETCHING_MORE_ROOMS_LIST = 'INBOX/FETCHING_MORE_ROOMS_LIST';
const FETCHING_SUMMARY_ROOMS_LIST = 'INBOX/FETCHING_SUMMARY_ROOMS_LIST';
const FETCHING_ARCHIVED_ROOMS_LIST = 'INBOX/FETCHING_ARCHIVED_ROOMS_LIST';
const FETCHING_UNREAD_ROOMS_LIST = 'INBOX/FETCHING_UNREAD_ROOMS_LIST';
const FETCHING_UNTRACKED_ROOMS_LIST = 'INBOX/FETCHING_UNTRACKED_ROOMS_LIST';
const ROOM_ADDED = 'INBOX/ROOM_ADDED';
const ROOMS_LIST_FETCHED = 'INBOX/ROOMS_LIST_FETCHED';
const ROOMS_LIST_SEARCHED = 'INBOX/ROOMS_LIST_SEARCHED';
const ROOMS_LIST_FILTERED = 'INBOX/ROOMS_LIST_FILTERED';
const ROOMS_LIST_SUMMARY_FETCHED = 'INBOX/ROOMS_LIST_SUMMARY_FETCHED';

export const actionTypes = {
  FETCHING_ROOMS_LIST,
  FETCHING_MORE_ROOMS_LIST,
  FETCHING_SUMMARY_ROOMS_LIST,
  FETCHING_ARCHIVED_ROOMS_LIST,
  FETCHING_UNREAD_ROOMS_LIST,
  ROOM_ADDED,
  ROOMS_LIST_FETCHED,
  ROOMS_LIST_SEARCHED,
  ROOMS_LIST_FILTERED,
  ROOMS_LIST_SUMMARY_FETCHED,
  FETCHING_UNTRACKED_ROOMS_LIST,
};

export const getRoom = ({ roomId }) => {
  return (dispatch) => {
    Api.getRoom({ roomId }).then(({ data: { data: room } }) => {
      socketJoin(room);
      dispatch(roomAdded({ room }));
    });
  };
};

export const getRoomsList = (
  { filterType } = { filterType: FilterTypes.open }
) => {
  return (dispatch) => {
    dispatch(fetchingRoomsList({ mode: filterType }));
    Api.getRooms({
      filter: Filters[filterType].api.rooms,
    }).then(({ data: { data: rooms, meta } }) => {
      dispatch(
        appointmentsPartialListFetched(
          rooms.map(({ room_id, current_appointment }) => ({
            ...current_appointment,
            room_id,
          }))
        )
      );
      dispatch(roomsListFiltered({ rooms, meta, mode: filterType }));
    });
  };
};

export const searchRoomsList = ({ term }) => {
  return (dispatch) => {
    dispatch(fetchingRoomsList({ mode: InboxMode.searched, term }));
    return Api.searchRooms({ term }).then(({ data: { data: rooms, meta } }) => {
      dispatch(roomsListSearched({ term, rooms, meta }));
      if (rooms.length === 0) {
        Tracker.pageView('search results empty', { event_query: term });
      }
    });
  };
};

export const moreRoomsList = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(fetchingMoreRoomsList());
    return fetchMoreRooms(state).then(({ data: { data: rooms, meta } }) => {
      dispatch(roomsListFiltered({ rooms, meta }));
    });
  };
};

export const getSummaryRoomsList = () => {
  return (dispatch) => {
    return Api.roomsSummary().then(({ data: { data: summary } }) =>
      dispatch(summaryRoomsListFetched({ summary }))
    );
  };
};

const fetchMoreRooms = ({
  inbox: {
    mode,
    meta: { current_page },
    highlightedString,
  },
}) =>
  mode === InboxMode.searched
    ? Api.searchRooms({ term: highlightedString, page: current_page + 1 })
    : Api.getRooms({ filter: Filters[mode].api.rooms, page: current_page + 1 });

const socketJoin = (room) => socket.emit('joinRoom', room.room_id);

export const fetchingRoomsList = (
  { mode, term = '' } = { mode: InboxMode.open, term: '' }
) => {
  return { type: FETCHING_ROOMS_LIST, payload: { mode, term } };
};

export const fetchingMoreRoomsList = () => {
  return { type: FETCHING_MORE_ROOMS_LIST };
};

export const fetchingSummaryRoomsList = () => {
  return { type: FETCHING_SUMMARY_ROOMS_LIST };
};

export const fetchingArchivedRoomsList = () => {
  return { type: FETCHING_ARCHIVED_ROOMS_LIST };
};

export const fetchingUnreadRoomsList = () => {
  return { type: FETCHING_UNREAD_ROOMS_LIST };
};

export const fetchingUntrackedRoomsList = () => {
  return { type: FETCHING_UNTRACKED_ROOMS_LIST };
};

export const roomsListFetched = ({ rooms, meta }) => {
  return {
    type: ROOMS_LIST_FETCHED,
    payload: {
      rooms,
      meta,
    },
  };
};

export const roomsListSearched = ({ term, rooms, meta }) => {
  return {
    type: ROOMS_LIST_SEARCHED,
    payload: {
      term,
      rooms,
      meta,
    },
  };
};

export const roomAdded = ({ room }) => {
  return {
    type: ROOM_ADDED,
    payload: {
      room,
    },
  };
};

export const roomsListFiltered = ({ rooms, meta, mode }) => {
  return {
    type: ROOMS_LIST_FILTERED,
    payload: {
      rooms,
      meta,
      mode,
    },
  };
};

export const summaryRoomsListFetched = ({ summary }) => {
  return {
    type: ROOMS_LIST_SUMMARY_FETCHED,
    payload: { summary },
  };
};
