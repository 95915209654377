import classNames from 'classnames';
import ExclamationCircle from 'components/icons/outline/ExclamationCircle';
import Pencil from 'components/icons/outline/Pencil';
import ProfilePlus from 'components/icons/outline/ProfilePlus';
import ProfileImage from 'containers/Appointments/components/ProfileImage';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
  avatarURL?: string;
  name?: string;
  disabled?: boolean;
  pending?: boolean;
  onClick?: () => void;
}

const Profile: FC<Props> = ({
  avatarURL,
  name,
  disabled,
  pending,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <button
      disabled={disabled}
      className={classNames(
        'flex w-full gap-4 rounded-lg p-3.5 text-left items-center',
        {
          'hover:bg-gray-light': !disabled,
          'bg-secundary text-center text-primary text-center justify-center':
            !name,
          'bg-background text-dark': name && !disabled,
          'bg-background text-gray-dark': name && disabled,
        }
      )}
      onClick={onClick}
    >
      {avatarURL && <ProfileImage src={avatarURL} alt={name} />}
      {!name ? (
        <p className="self-center font-medium">
          {t('appointments__payment_form_pacient_select')}
        </p>
      ) : (
        <div className="flex-grow flex flex-col gap-[6px]">
          <p>{name}</p>
          {pending && (
            <div className="flex gap-1 text-orange">
              <p className="text-xs">
                {t('appointments__payment_form_pacient_pending')}
              </p>
              <ExclamationCircle className="w-4 h-4 shrink-0" />
            </div>
          )}
        </div>
      )}
      {name ? (
        !disabled && (
          <Pencil
            className={classNames(
              'w-5 h-5 text-gray-dark bg-white rounded p-0.5 border border-gray-light',
              !name ? 'self-center' : 'self-end'
            )}
          />
        )
      ) : (
        <ProfilePlus className={classNames('w-6 h-6')} />
      )}
    </button>
  );
};

export default Profile;
