import { useNotes } from 'containers/DetailBar/PatientBar/hooks/notes.hook';
import AccordeonHandler from 'containers/Shared/components/Accordeon/AccordeonHandler';
import {
  AccordeonHeader,
  AccordeonBody,
  AccordeonButton,
} from 'containers/Shared/components/Accordeon/AccordeonPanel';
import NoteModal from 'containers/DetailBar/PatientBar/components/Notes/NoteModal';
import NoteItem from 'containers/DetailBar/PatientBar/components/Notes/NoteItem';
import { useTranslation } from 'react-i18next';
import { usePortal } from 'containers/Shared/components/Portal';

const NotesContainer = ({ badge, onClose, hash }) => {
  const [notes, store, destroy] = useNotes(hash);
  const { t } = useTranslation();
  const portal = usePortal();

  const openNoteModal = () =>
    portal.open(
      <NoteModal
        onClose={() => portal.close('note')}
        onSubmit={(message) => {
          store(message, hash);
          portal.close('note');
        }}
      />,
      'note'
    );

  return (
    <div>
      <div className="p-4">
        <AccordeonHeader onClose={onClose} badge={badge}>
          {t('sidebar__patient_notes_title')}
        </AccordeonHeader>
        <AccordeonBody show={badge === 0}>
          {t('sidebar__patient_notes_empty_text')}
        </AccordeonBody>
        <AccordeonButton onClick={openNoteModal}>
          {t('sidebar__patient_notes_add')}
        </AccordeonButton>
      </div>
      <div className="border-b border-separators">
        {notes.map((note) => (
          <NoteItem
            key={note.id}
            note={note}
            onDelete={() => destroy(note.id)}
          />
        ))}
      </div>
    </div>
  );
};

const NotesPanel = ({ badge, open, onOpen, onClose, hash }) => {
  const { t } = useTranslation();
  if (!open) {
    return (
      <AccordeonHandler onOpen={onOpen} badge={badge}>
        {t('sidebar__patient_notes_title')}
      </AccordeonHandler>
    );
  }

  return <NotesContainer badge={badge} onClose={onClose} hash={hash} />;
};

export default NotesPanel;
