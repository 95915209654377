import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

const API = 'pro/v1/push-tokens';
const BASE_URL = config.services.mediquo.api_url;

const register = ({ token, platform }) =>
  axios({
    method: 'POST',
    url: `${BASE_URL}${API}/register`,
    headers: getHeaders(),
    data: { token, os: platform },
  });

const remove = ({ token }) =>
  axios({
    method: 'POST',
    url: `${BASE_URL}${API}/${token}/unregister`,
    headers: getHeaders(),
  });

export default {
  register,
  remove,
};
