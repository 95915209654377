import { formatDate } from 'utils/dateFormatter';
import { capitalize } from 'utils/stringFormatter';
import CloudDownload from 'components/icons/outline/CloudDownload';
import MedicalPaper from 'components/icons/outline/MedicalPaper';

const PrescriptionItem = ({ prescription, onDownload }) => {
  return (
    <div className="flex w-full p-4">
      <div className="mr-2">
        <div className="flex items-center justify-center w-8 h-8 rounded-full bg-secundary">
          <MedicalPaper className="text-primary" />
        </div>
      </div>

      <div className="mr-2">
        <div className="font-medium text-md">
          {capitalize(prescription.active_substances?.join(','))}
        </div>
        <div className="text-xs font-medium text-gray-medium">
          {`${formatDate(prescription.prescribed_at)}`}
        </div>
        <div className="text-xs text-gray-medium">
          {prescription.professional.name}
        </div>
      </div>
      <button
        onClick={onDownload}
        className="flex items-center justify-center w-4 h-4 ml-auto cursor-pointer focus:outline-none shrink-0"
      >
        <CloudDownload className="text-gray-medium" />
      </button>
    </div>
  );
};

export default PrescriptionItem;
