import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { socket } from 'socket';
import {
  onAppointmentAccepted,
  onAppointmentDeclined,
  onAppointmentExpired,
  onAppointmentFinished,
  onAppointmentOwed,
} from 'socket/listeners/onAppointment';
import {
  onCallPickedUp,
  onCallRejected,
  onCallRequested,
} from 'socket/listeners/onCall';
import {
  onInvitationAccepted,
  onInvitationEmailFailed,
  onInvitationSmsFailed,
} from 'socket/listeners/onInvitation';
import {
  onMessageReceived,
  onMessageStatusChanged,
  onMessageDeleted,
} from 'socket/listeners/onMessage';
import {
  onPrescriptionValidationUpdated,
  onProfessionalWentOffline,
  onProfessionalWentOnline,
  onProfessionalVideoCallWentOffline,
  onProfessionalVideoCallWentOnline,
  onReconnect,
  onUpdateAvailable,
} from 'socket/listeners/onSession';
import { onStopTyping, onTyping } from 'socket/listeners/onTyping';
import { getProfile } from 'state/profile/index.selectors';
import {
  onJoin,
  onJoinerSetAsReady,
  onJoinerSetAsUnReady,
  onLeave,
} from 'socket/listeners/onWaitingRoom';

const useSocketSubscriber = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);

  useEffect(() => {
    socket?.on('typing', onTyping(dispatch, profile));
    socket?.on('stopTyping', onStopTyping(dispatch, profile));

    socket?.on('message', onMessageReceived(dispatch));
    socket?.on('messageStatus', onMessageStatusChanged(dispatch));

    socket?.on('appointment_finished', onAppointmentFinished(dispatch));
    socket?.on('appointment_accepted', onAppointmentAccepted(dispatch));
    socket?.on('appointment_declined', onAppointmentDeclined(dispatch));
    socket?.on('appointment_expired', onAppointmentExpired(dispatch));
    socket?.on('appointment_owed', onAppointmentOwed(dispatch));

    socket?.on('professional_went_online', onProfessionalWentOnline(dispatch));
    socket?.on(
      'professional_went_offline',
      onProfessionalWentOffline(dispatch)
    );

    socket?.on(
      'professional_went_immediate_video_call_online',
      onProfessionalVideoCallWentOnline(dispatch)
    );
    socket?.on(
      'professional_went_immediate_video_call_offline',
      onProfessionalVideoCallWentOffline(dispatch)
    );

    socket?.on('invitation_accepted', onInvitationAccepted(dispatch));
    socket?.on('invitation_sms_failed', onInvitationSmsFailed(dispatch));
    socket?.on('invitation_email_failed', onInvitationEmailFailed(dispatch));

    socket?.on(
      'prescription_validation_updated',
      onPrescriptionValidationUpdated(dispatch)
    );

    socket?.on('reconnect', onReconnect(dispatch));
    socket?.on('console_deployed', onUpdateAvailable(dispatch));

    socket?.on('call_requested', onCallRequested(dispatch));
    socket?.on('call_rejected', onCallRejected(dispatch));
    socket?.on('call_picked_up', onCallPickedUp(dispatch));

    socket?.on('joinWaitingRoom', onJoin(dispatch));
    socket?.on('leave_waiting_room', onLeave(dispatch));
    socket?.on('joiner_set_as_ready', onJoinerSetAsReady(dispatch));
    socket?.on('joiner_set_as_unready', onJoinerSetAsUnReady(dispatch));

    socket?.on('message_deleted', onMessageDeleted(dispatch));
    return () => {
      socket?.disconnect();
    };
  }, []);
};

export default useSocketSubscriber;
