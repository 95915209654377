import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messages: [],
} as { messages: any };

const pendingSlice = createSlice({
  name: 'pending',
  initialState,
  reducers: {
    saveDraft(state, action: any) {
      const roomDraftIndex = state.messages?.length
        ? state.messages
            .map((object: any) => object.roomId)
            .indexOf(action.payload.roomId)
        : -1;
      const draftMessages = state.messages;
      return {
        ...state,
        messages:
          roomDraftIndex !== -1
            ? draftMessages.map((message: any) => {
                if (message.roomId === action.payload.roomId) {
                  return action.payload;
                }
                return message;
              })
            : [...state.messages, action.payload],
      };
    },
    clearDraft(state, action: any) {
      const roomclearDraftIndex =
        state.messages && state.messages.length
          ? state.messages
              .map((object: any) => object.roomId)
              .indexOf(action.payload)
          : -1;
      const clearDraftMessages = state.messages;
      return {
        ...state,
        messages:
          roomclearDraftIndex !== -1
            ? clearDraftMessages.filter(
                (message: any) => message.roomId !== action.payload
              )
            : state.messages,
      };
    },
  },
});

export const { saveDraft, clearDraft } = pendingSlice.actions;
export default pendingSlice.reducer;
