const Pencil = (props: any) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.8196 6.11667L14.5363 3.83334C14.2383 3.55342 13.8478 3.3928 13.4391 3.38205C13.0304 3.37129 12.6319 3.51114 12.3196 3.775L4.81962 11.275C4.55026 11.5466 4.38254 11.9027 4.34462 12.2833L3.98629 15.7583C3.97506 15.8804 3.9909 16.0034 4.03267 16.1187C4.07445 16.2339 4.14113 16.3385 4.22796 16.425C4.30582 16.5022 4.39817 16.5633 4.4997 16.6048C4.60123 16.6463 4.70995 16.6673 4.81962 16.6667H4.89462L8.36962 16.35C8.75029 16.3121 9.10632 16.1444 9.37796 15.875L16.878 8.375C17.1691 8.06748 17.3264 7.65709 17.3154 7.23378C17.3045 6.81047 17.1262 6.40876 16.8196 6.11667ZM8.21962 14.6833L5.71962 14.9167L5.94462 12.4167L10.653 7.76667L12.903 10.0167L8.21962 14.6833ZM13.9863 8.9L11.753 6.66667L13.378 5L15.653 7.275L13.9863 8.9Z"
      fill="currentColor"
    />
  </svg>
);
export default Pencil;
