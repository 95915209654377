import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type SessionSelectProps = {
  handleSelectSession: (sessionNumber: number) => void;
  sessionNumber: number;
  hasSessions: boolean;
};

type SessionOptionProps = {
  option: number;
  handleSelectSession: (sessionNumber: number) => void;
  sessionNumber: number;
};

const SessionSelect = ({
  handleSelectSession,
  sessionNumber,
  hasSessions,
}: SessionSelectProps) => {
  const { t } = useTranslation();
  const [defaultSession, setDefaultSession] = useState<number>(10);

  useEffect(() => {
    if (hasSessions) {
      if (sessionNumber === 0) {
        handleSelectSession(10);
        setDefaultSession(sessionNumber);
      } else if (sessionNumber) {
        setDefaultSession(sessionNumber);
      } else if (defaultSession === 0) {
        handleSelectSession(10);
      }
    }
  }, [hasSessions, sessionNumber, defaultSession]);

  const handleChangeSession = (sessionNumber: number) => {
    handleSelectSession(sessionNumber);
    setDefaultSession(sessionNumber);
  };

  return (
    <div className="pb-2">
      <div className="text-gray-dark uppercase text-xs font-medium py-2">
        {t('prescription_modal__session_number_label')}
      </div>
      <div className="flex flex-row py-2 gap-2">
        {[...Array(4).keys()]
          .map((x) => (x + 1) * 5)
          .map((item, index) => (
            <SessionOption
              key={index}
              option={item}
              handleSelectSession={handleChangeSession}
              sessionNumber={sessionNumber}
            />
          ))}
      </div>
    </div>
  );
};

const SessionOption = ({
  option,
  handleSelectSession,
  sessionNumber,
}: SessionOptionProps) => {
  return (
    <div
      className={classNames(
        `flex flex-row justify-center items-center border border-separators rounded-md cursor-pointer hover:bg-gray-100 w-14 h-12`,
        {
          'bg-blue-light border-primary text-primary': option === sessionNumber,
        }
      )}
      onClick={() => handleSelectSession(option)}
    >
      <div>{option}</div>
    </div>
  );
};

export default SessionSelect;
