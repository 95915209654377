import firebase from 'firebase/app';
import 'firebase/messaging';
import {config} from "config/index"


const firebaseConfig = {
  apiKey: config.services.firebase.api_key,
  projectId: config.services.firebase.project_id,
  messagingSenderId: config.services.firebase.sender_id,
  appId: config.services.firebase.app_id,
};

firebase.initializeApp(firebaseConfig);

let messaging;
try {
  messaging = firebase.messaging();
} catch (error) {
  console.error(error);
}

export default messaging;
