const Info = ({ className = '' }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      fill="currentColor"
      d="M9 13.5a.903.903 0 01-.9-.9V9c0-.495.405-.9.9-.9s.9.405.9.9v3.6c0 .495-.405.9-.9.9zm.009 4.5C13.977 18 18 13.968 18 9s-4.023-9-8.991-9C4.032 0 0 4.032 0 9s4.032 9 9.009 9zM9 1.8c3.978 0 7.2 3.222 7.2 7.2s-3.222 7.2-7.2 7.2A7.198 7.198 0 011.8 9c0-3.978 3.222-7.2 7.2-7.2zm-.9 2.7h1.8v1.8H8.1V4.5z"
    ></path>
  </svg>
);

export default Info;
