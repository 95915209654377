import AccordeonHandler from 'containers/Shared/components/Accordeon/AccordeonHandler';
import {
  AccordeonHeader,
  AccordeonBody,
  AccordeonButton,
} from 'containers/Shared/components/Accordeon/AccordeonPanel';
import Trash from 'components/icons/Trash';
import { useMedications } from 'containers/DetailBar/PatientBar/hooks/medications.hook';
import MedicationItem from 'containers/DetailBar/PatientBar/components/Medications/MedicationItem';
import MedicationModal from 'containers/DetailBar/PatientBar/components/Medications/MedicationModal';
import { useTranslation } from 'react-i18next';
import { usePortal } from 'containers/Shared/components/Portal';

const MedicationContainer = ({
  badge,
  onClose,
  unknown_medications_at,
  hash,
}) => {
  const [
    medications,
    store,
    destroy,
    markAsUnknown,
    removeUnknown,
  ] = useMedications(hash);
  const { t } = useTranslation();
  const portal = usePortal();

  const openMedicationModal = () =>
    portal.open(
      <MedicationModal
        onClose={() => portal.close('medication')}
        onSubmit={(allergy) => {
          store(allergy, hash);
          portal.close('medication');
        }}
      />,
      'medication'
    );

  return (
    <div>
      <div className="p-4">
        <AccordeonHeader
          onClose={onClose}
          badge={badge}
          empty={unknown_medications_at !== null}
        >
          {t('sidebar__patient_medications_title')}
        </AccordeonHeader>
        <AccordeonBody show={badge === 0 && unknown_medications_at === null}>
          {t('sidebar__patient_medications_empty_text')}
        </AccordeonBody>
        <AccordeonBody show={unknown_medications_at !== null}>
          <div className="flex items-center justify-between">
            <div className="text-dark">
              {t('sidebar__patient_medications_unknown_text')}
            </div>
            <button
              onClick={() => removeUnknown(hash)}
              className="cursor-pointer focus:outline-none"
            >
              <Trash />
            </button>
          </div>
        </AccordeonBody>
        <AccordeonButton onClick={openMedicationModal}>
          {t('sidebar__patient_medications_add')}
        </AccordeonButton>
        {badge === 0 && unknown_medications_at === null && (
          <AccordeonButton className="mt-1" onClick={() => markAsUnknown(hash)}>
            {t('sidebar__patient_medications_mark_unknown')}
          </AccordeonButton>
        )}
      </div>
      <div className="border-b border-separators">
        {medications.map((medication) => (
          <MedicationItem
            key={medication.id}
            medication={medication}
            onDelete={() => destroy(medication.id)}
          />
        ))}
      </div>
    </div>
  );
};
const MedicationsPanel = ({ summary, open, onOpen, onClose, hash }) => {
  const { medications_count: badge, unknown_medications_at } = summary;
  const { t } = useTranslation();

  if (!open) {
    return (
      <AccordeonHandler
        onOpen={onOpen}
        badge={badge}
        empty={unknown_medications_at !== null}
      >
        {t('sidebar__patient_medications_title')}
      </AccordeonHandler>
    );
  }

  return (
    <MedicationContainer
      badge={badge}
      onClose={onClose}
      hash={hash}
      unknown_medications_at={unknown_medications_at}
    />
  );
};

export default MedicationsPanel;
