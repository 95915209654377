const Video = (props: any) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.0016 5.00012C15.6584 5.00012 17.0016 6.34327 17.0016 8.00012V9.13212L18.4354 7.63695C19.1509 6.89042 20.3242 6.7933 21.1574 7.38228L21.293 7.48681C21.7406 7.86239 22.0016 8.40935 22.0016 8.98927V15.011C22.0016 16.125 21.0662 17.0001 19.9444 17.0001C19.3742 17.0001 18.8265 16.7713 18.4354 16.3633L17.0016 14.8671V16.0001C17.0016 17.657 15.6584 19.0001 14.0016 19.0001H5.00159C3.34473 19.0001 2.00159 17.657 2.00159 16.0001V8.00012C2.00159 6.34327 3.34473 5.00012 5.00159 5.00012H14.0016ZM14.0016 7.00012H5.00159C4.48875 7.00012 4.06608 7.38616 4.00831 7.8835L4.00159 8.00012V16.0001C4.00159 16.513 4.38763 16.9356 4.88497 16.9934L5.00159 17.0001H14.0016C14.5144 17.0001 14.9371 16.6141 14.9949 16.1167L15.0016 16.0001V8.00012C15.0016 7.48729 14.6155 7.06461 14.1182 7.00685L14.0016 7.00012ZM19.9772 9.0042C19.9423 8.99496 19.8976 9.00177 19.8793 9.02083L17.0236 12.0001L19.8793 14.9794C19.886 14.9864 19.8992 14.9932 19.9162 14.9971L19.9444 15.0001C19.9903 15.0001 20.0016 14.9896 20.0016 15.011V9.01612L19.9772 9.0042Z"
      fill="currentColor"
    />
  </svg>
);

export default Video;
