import MainHeader, { Title } from 'pages/main/Header';
import AppointmentMeet from 'containers/AppointmentMeet';

const AppointmentMeets = () => {
  return (
    <>
      <MainHeader>
        <Title>Consulta agendada</Title>
      </MainHeader>
      <main className="flex flex-grow max-h-full overflow-y-hidden w-full min-w-0 bg-white">
        <AppointmentMeet/>
      </main>
    </>
  );
};

export default AppointmentMeets;
