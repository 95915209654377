const User = (props: any) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.16667 9C12.6111 9 14.8333 10.7188 14.8333 14.1562C14.8333 14.5913 14.5037 14.9522 14.0849 14.9956L14 15H2.33333C1.875 15 1.5 14.6203 1.5 14.1562C1.5 10.7801 3.64357 9.06193 7.9307 9.00164L8.16667 9ZM8.16667 10.3333C4.69044 10.3333 3.09184 11.3589 2.86246 13.5668L2.85333 13.6667H13.4793L13.4709 13.5668C13.2464 11.4059 11.7103 10.3775 8.38601 10.3347L8.16667 10.3333ZM8.16667 1C10.0083 1 11.5 2.49167 11.5 4.33333C11.5 6.12654 10.0858 7.58792 8.31131 7.66359L8.16667 7.66667C6.325 7.66667 4.83333 6.175 4.83333 4.33333C4.83333 2.54013 6.24752 1.07875 8.02202 1.00308L8.16667 1ZM8.16667 2.33333C7.06138 2.33333 6.16667 3.22805 6.16667 4.33333C6.16667 5.43862 7.06138 6.33333 8.16667 6.33333C9.27195 6.33333 10.1667 5.43862 10.1667 4.33333C10.1667 3.22805 9.27195 2.33333 8.16667 2.33333Z"
      fill="currentColor"
    />
  </svg>
);

export default User;
