import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from 'react-autosize-textarea';
import uuid from 'uuid/v4';
import Dropzone from 'react-dropzone';
import EmojiPicker from 'containers/Room/components/RoomInput/EmojiPicker';
import { sendMessage } from 'containers/Room/actions/SendMessage.actions';
import AddMorePicker from 'containers/Room/components/RoomInput/AddMorePicker';
import { useEffect } from 'react';
import UnlockRoomModal from 'containers/Room/components/RoomBody/UnlockRoomModal';
import useBlockModal from 'containers/Shared/components/Patient/blockModal.hook';
import SuggestedSentences from 'containers/Room/components/RoomInput/SuggestedSentences';
import classnames from 'classnames';
import Clip from 'components/icons/outline/Clip';
import PaperPlane from 'components/icons/outline/PaperPlane';
import { saveDraft, clearDraft } from 'state/pending.slice';
import debounce from 'lodash.debounce';
import VoiceNote from 'containers/Room/components/RoomInput/VoiceNote';
import Microphone from 'components/icons/outline/Microphone';
import { socket } from 'socket';
import { onMessageSent } from 'socket/listeners/onMessage';

const emptyMessage = (room, profile) => {
  return {
    string: '',
    attachment: undefined,
    type: 'string',
    messageId: uuid(),
    auto: false,
    roomId: room?.room_id,
    status: 0,
    fromUserHash: profile.hash,
  };
};

const RoomInput = ({
  room,
  disabled = false,
  consultationType = null,
  consultationId = null,
}) => {
  const { t } = useTranslation();
  const [isOpenSuggestions, setIsOpenSuggestions] = useState(false);
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.session);
  const { isLoading } = useSelector((state) => state.console);
  const [message, setMessage] = useState(emptyMessage(room, profile));
  const roomBlocked = useBlockModal();
  const messageRef = useRef();
  const pending = useSelector((state) => state.pending);
  const [isAudioRecording, setIsAudioRecording] = useState(false);

  useEffect(() => {
    let draftedMessage = getDraftMessage();
    if (draftedMessage) {
      setMessage(draftedMessage);
    } else {
      setMessage(emptyMessage(room, profile));
    }
  }, [room?.room_id, isLoading]);

  useEffect(() => {
    if (disabled) {
      setMessage((message) => ({ ...message, string: '' }));
    }
  }, [disabled]);

  const debouncedInputChange = debounce((inputDraft) => {
    const currentMessage = { ...message };

    currentMessage.string = inputDraft;

    inputDraft.length > 0
      ? dispatch(saveDraft(currentMessage))
      : dispatch(clearDraft(room?.room_id));
  }, 300);

  const getDraftMessage = () => {
    let draftedMessage = pending?.messages?.filter(
      (message) => message.roomId === room?.room_id
    )[0];
    return draftedMessage;
  };

  useEffect(() => {
    // TODO: have to do this bc I don't have room_id in websocket event
    // When this comes from backend we can move it to the socket event listeners
    socket?.on('message-sent', (data) => {
      onMessageSent(dispatch)({ ...data, roomId: room?.room_id });
    });

    return () => {
      socket?.off('message-sent');
    };
  }, [room?.room_id]);

  const handleKeyDown = (event) => {
    if ('Enter' === event.key && event.shiftKey === false) {
      event.preventDefault();
      handleSendMessage();
      return;
    }

    if ('Escape' === event.key) {
      return;
    }
    setIsOpenSuggestions(true);
  };

  const closeSuggestions = () => setIsOpenSuggestions(false);
  const setSuggestionMessage = (string) =>
    string && setMessage({ ...message, string });

  const onDrop = (acceptedFiles) => {
    const reader = new FileReader();
    let file = acceptedFiles[0];
    const type = file.type.includes('image') ? 'image' : 'file';
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (!reader.result) {
        return;
      }

      file.base64 = reader.result.split('base64,')[1];
      dispatch(
        sendMessage(
          {
            ...message,
            attachment: file,
            base64: file.base64,
            type: type,
          },
          room
        )
      );

      setMessage(emptyMessage(room, profile));
    };
  };

  const handleSendMessage = () => {
    if (room?.status === 'blocked') {
      roomBlocked.show();
      return;
    }

    if (message?.string === '' && !message?.attachment) {
      return;
    }

    setTimeout(() => {
      dispatch(clearDraft(room?.room_id));
    }, 400);

    setMessage(emptyMessage(room, profile));
    dispatch(sendMessage(message, room));
  };

  return (
    <>
      {!isAudioRecording ? (
        <div
          className={classnames(
            'relative p-4 bg-white border-t border-separators',
            { 'opacity-50': disabled }
          )}
        >
          <div
            className={classnames(
              'rounded-24 border relative flex items-center',
              message?.string ? 'border-primary' : 'border-separators'
            )}
          >
            <AddMorePicker
              disabled={disabled}
              onSelect={(sentence) =>
                setMessage({ ...message, string: sentence })
              }
              room={room}
              consultationType={consultationType}
              consultationId={consultationId}
            />
            <div className="w-px h-6 mx-1 bg-separators"></div>
            {disabled ? (
              <div className="w-full text-gray-medium">
                {t('room__input_placeholder')}
              </div>
            ) : (
              <TextareaAutosize
                disabled={isLoading}
                autoFocus
                ref={messageRef}
                onKeyDown={(e) => handleKeyDown(e)}
                value={message?.string}
                onChange={(e) => {
                  setMessage({ ...message, string: e.target.value });
                  debouncedInputChange(e.target.value);
                }}
                className="flex-grow min-h-0 px-1 border-none text-dark focus:outline-none"
                placeholder={t('room__input_placeholder')}
                maxRows={5}
              />
            )}
            <SuggestedSentences
              inputRef={messageRef}
              open={isOpenSuggestions}
              term={message?.string}
              onClose={closeSuggestions}
              onSelected={(string) => {
                setSuggestionMessage(string);
                closeSuggestions();
              }}
            />
            {room?.status !== 'blocked' && (
              <>
                <Dropzone disabled={disabled} onDrop={onDrop} style={{}}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <button
                        disabled={disabled}
                        className={classnames(
                          'flex items-center justify-center w-8 h-8 bg-white rounded-lg text-gray-medium',
                          {
                            'hover:bg-gray-light hover:text-gray-dark focus:outline-none':
                              !disabled,
                          }
                        )}
                      >
                        <Clip className="h-6" />
                      </button>
                    </div>
                  )}
                </Dropzone>

                <EmojiPicker
                  disabled={disabled}
                  onSelect={(emoji) =>
                    setMessage({ ...message, string: message?.string + emoji })
                  }
                />
              </>
            )}
            <button
              type="submit"
              disabled={disabled}
              onClick={() => {
                if (message?.string) {
                  handleSendMessage();
                } else {
                  setIsAudioRecording(true);
                }
              }}
              className={classnames(
                'w-8 h-8 m-1 rounded-full flex items-center justify-center focus:outline-none',
                { 'hover:text-white hover:bg-blue': !disabled },
                'bg-blue text-white'
              )}
            >
              {!message?.string && !isAudioRecording ? (
                <Microphone className="h-5 text-white" />
              ) : (
                <PaperPlane className="h-6" />
              )}
            </button>
          </div>

          <UnlockRoomModal
            room={room}
            show={roomBlocked.isOpen}
            onClose={roomBlocked.close}
          />
        </div>
      ) : (
        <VoiceNote
          room={room}
          profile={profile}
          emptyMessage={emptyMessage}
          message={message}
          setMessage={setMessage}
          setIsAudioRecording={setIsAudioRecording}
          isAudioRecording={isAudioRecording}
        />
      )}
    </>
  );
};

export default RoomInput;
