import { Trans, useTranslation } from 'react-i18next';
import Header from 'containers/Prescription/components/Header';
import { MEDIQUO_PRO_SUPPORT_EMAIL } from 'config/constants';

export interface NotValidatedViewProps {
  onClose: () => void;
}

const DocumentIcon = () => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5 0C42.7939 0 48.5 6.58154 48.5 24C48.5 41.4184 42.7939 48 24.5 48C6.20602 48 0.5 41.4184 0.5 24C0.5 6.58154 6.20602 0 24.5 0Z"
      fill="#ECEEFD"
    />
    <path
      opacity="0.12"
      d="M31.6213 19.1214L27.3787 14.8788C27.1222 14.6222 26.824 14.417 26.5 14.2695V18.4001C26.5 18.9601 26.5 19.2401 26.609 19.4541C26.7049 19.6422 26.8578 19.7952 27.046 19.8911C27.2599 20.0001 27.5399 20.0001 28.1 20.0001H32.2305C32.0831 19.6761 31.8778 19.3779 31.6213 19.1214Z"
      fill="#0A0A22"
    />
    <path
      d="M26.5 14.2695V18.4001C26.5 18.9601 26.5 19.2401 26.609 19.4541C26.7049 19.6422 26.8578 19.7952 27.046 19.8911C27.2599 20.0001 27.5399 20.0001 28.1 20.0001H32.2305M26.5 29H20.5M28.5 25H20.5M32.5 21.9882V29.2C32.5 30.8802 32.5 31.7202 32.173 32.362C31.8854 32.9265 31.4265 33.3854 30.862 33.673C30.2202 34 29.3802 34 27.7 34H21.3C19.6198 34 18.7798 34 18.138 33.673C17.5735 33.3854 17.1146 32.9265 16.827 32.362C16.5 31.7202 16.5 30.8802 16.5 29.2V18.8C16.5 17.1198 16.5 16.2798 16.827 15.638C17.1146 15.0735 17.5735 14.6146 18.138 14.327C18.7798 14 19.6198 14 21.3 14H24.5118C25.2455 14 25.6124 14 25.9577 14.0829C26.2638 14.1564 26.5564 14.2776 26.8249 14.4421C27.1276 14.6276 27.387 14.887 27.9059 15.4059L31.0941 18.5941C31.613 19.113 31.8724 19.3724 32.0579 19.6751C32.2224 19.9436 32.3436 20.2362 32.4171 20.5423C32.5 20.8876 32.5 21.2545 32.5 21.9882Z"
      stroke="#4A1EA7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const NotValidatedView = ({ onClose }: NotValidatedViewProps) => {
  const { t } = useTranslation();

  return (
    <div className="h-full flex flex-col gap-5 overflow-y-auto pb-10">
      <Header
        title={t('prescription__rempe_validation_needed__title')}
        onClose={onClose}
      />

      <div className="flex-1 flex flex-col justify-center">
        <div className="container mx-auto max-w-[520px] space-y-6">
          <DocumentIcon />

          <h1 className="text-primary text-2xl font-medium">
            {t('prescription__validation_needed__title')}
          </h1>

          <div>
            <p className="text-primary-darker text-sm">
              <Trans
                i18nKey="prescription__validation_needed__content.0"
                values={{ email: MEDIQUO_PRO_SUPPORT_EMAIL }}
                components={[<strong />]}
              />
            </p>
            <p className="mt-4 text-primary-darker text-sm">
              {t('prescription__validation_needed__content.1')}
            </p>
          </div>
        </div>
      </div>

      <footer>
        <p className="text-xs text-gray-dark text-center font-light">
          {t('prescription__rempe_validation_needed__footer')}
        </p>
      </footer>
    </div>
  );
};

export default NotValidatedView;
