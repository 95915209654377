import { createSlice } from '@reduxjs/toolkit';

export interface AudioState {
  messageId?: string;
  isPlaying: boolean;
  currentTime: number;
  speed: number;
}

const initialState: AudioState = {
  messageId: undefined,
  isPlaying: false,
  currentTime: 0,
  speed: 1,
};

const audioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    setIsPlaying(state, action) {
      state.isPlaying = action.payload;
    },
    setCurrentTime(state, action) {
      if (state.messageId !== action.payload.messageId) {
        state.messageId = action.payload.messageId;
        state.isPlaying = false;
        state.speed = 1;
      }
      state.currentTime = action.payload.currentTime;
    },
    setMessageId(state, action) {
      state.messageId = action.payload;
    },

    setSpeed(state, action) {
      if (state.messageId !== action.payload.messageId) {
        state.messageId = action.payload.messageId;
        state.currentTime = 0;
        state.isPlaying = false;
      }
      state.speed = action.payload.speed;
    },
    playAudio(state, action) {
      if (state.messageId !== action.payload) {
        state.messageId = action.payload;
        state.currentTime = 0;
        state.speed = 1;
      }
      state.isPlaying = true;
    },
    pauseAudio: (state) => {
      state.isPlaying = false;
    },
  },
});

export const { setIsPlaying, setCurrentTime, setSpeed, playAudio, pauseAudio } =
  audioSlice.actions;
export default audioSlice.reducer;
