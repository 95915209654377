import {getAllWaitingRoom} from "containers/WaitingRoom/actions/GetWaitingRoom.actions";

export const onJoin = (dispatch) => {
  return () => dispatch(getAllWaitingRoom('socket'))
};

export const onLeave = (dispatch) => {
  return () => dispatch(getAllWaitingRoom('socket'))
};

export const onJoinerSetAsReady = (dispatch) => {
  return () => dispatch(getAllWaitingRoom('socket'))
};

export const onJoinerSetAsUnReady = (dispatch) => {
  return () => dispatch(getAllWaitingRoom('socket'))
};
