import * as Consultations from 'api/Consultations';
import * as Professionals from 'api/Professionals';
import appointments from 'api/appointments';
import calls from 'api/calls';
import payments from 'api/payments';
import profile from 'api/profile';
import license from 'api/license';
import invitations from 'api/invitations';
import notifications from 'api/notifications';
import rooms from 'api/rooms';
import installations from 'api/installations';
import reports from 'api/reports';
import patients from 'api/patients';
import prescriptions from 'api/prescriptions';
import clinicalCourse from 'api/clinicalCourse';
import contacts from 'api/contacts';
import tracking from 'api/trackings';
import waitingRoom from 'api/waitingRoom';
import consultations from 'api/consultations/index';
import documentation from 'api/documentation';
import diagnosticTestPrescriptions from 'api/diagnosticTestPrescriptions';
import diagnostics from 'api/diagnostics';
import chat from 'api/chat';
import phoneCalls from 'api/phoneCalls';

export default {
  calls,
  chat,
  consultations,
  prescriptions,
  diagnostics,
  diagnosticTestPrescriptions,
  clinicalCourse,
  patients,
  rooms,
  installations,
  notifications,
  invitations,
  appointments,
  license,
  payments,
  phoneCalls,
  profile,
  reports,
  contacts,
  tracking,
  waitingRoom,
  documentation,
  ...Consultations,
  ...Professionals,
};
